import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import weekday from 'dayjs/plugin/weekday'
import localeData from 'dayjs/plugin/localeData'
import utc from 'dayjs/plugin/utc'
import relativeTime from 'dayjs/plugin/relativeTime'
import objectSupport from 'dayjs/plugin/objectSupport'
import isBetween from 'dayjs/plugin/isBetween'
import isoWeek from 'dayjs/plugin/isoWeek'

dayjs.extend(localizedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(utc)
dayjs.extend(relativeTime)
dayjs.extend(objectSupport)
dayjs.extend(isBetween)
dayjs.extend(isoWeek)

export type Dayjs = dayjs.Dayjs
export default dayjs
