import { useEffect } from 'react'
import { Button, Card, Col, Form, FormInstance, Row, Typography } from 'antd'

import { SelectInputsModal } from './context-inputs-modal'
import { ContextInputComponent } from './context-input-component'
import { useContextDetailContext } from '../../context'
import type { InvestigationInputBaseObject } from 'bff/moons/generated/investigation-runner'

const { Title } = Typography

export default function ContextInputs({ form }: { form: FormInstance<any> }) {
    const {
        inputs,
        inputsModal,
        selectedInputs,
        setSelectedInputs,
        resetInputsToDefault,
        inputValues,
        setInputValues,
    } = useContextDetailContext()

    const handleSubmit = (selectedInputsFromModal: string[]) => {
        setSelectedInputs(selectedInputsFromModal)
        if (localStorage) {
            localStorage.setItem('selectedInputs', JSON.stringify(selectedInputsFromModal))
        }
        inputsModal.close()
    }

    const onValuesChange = (targetInput: string, value: string | undefined) => {
        setInputValues((prev: any) => {
            if (!value || value === '') {
                const { [targetInput]: _, ...rest } = prev
                return { ...rest }
            }
            return { ...prev, [targetInput]: value }
        })
        form.setFieldValue(targetInput, value)
    }

    useEffect(() => {
        form.setFieldsValue(inputValues)
    }, [inputValues, form])

    return (
        <>
            <Card data-testid="context-inputs-card">
                <Title level={3} style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>Inputs</div>
                    <div>
                        <Button
                            onClick={() => resetInputsToDefault()}
                            type="default"
                            style={{ marginRight: 10 }}
                        >
                            Reset to default
                        </Button>
                        <Button onClick={() => inputsModal.open()} type="primary">
                            Select Inputs
                        </Button>
                    </div>
                </Title>
                <Row
                    data-testid="input-list"
                    gutter={[
                        { xs: 8, sm: 16, md: 24, lg: 32 },
                        { xs: 4, sm: 4, md: 6, lg: 16 },
                    ]}
                >
                    {inputs &&
                        Object.values(inputs).map((input: InvestigationInputBaseObject) => {
                            if (!selectedInputs.includes(input.id)) {
                                return null
                            }

                            return (
                                <Col
                                    sm={{ flex: '100%' }}
                                    md={{ flex: '50%' }}
                                    lg={{ flex: '33%' }}
                                    xl={{ flex: '25%' }}
                                    key={input.id}
                                >
                                    <Form.Item
                                        name={input.id}
                                        label={renderInputLabel(input)}
                                        rules={[
                                            {
                                                required: input.required,
                                                message: `Please enter a valid value for ${input.label}`,
                                            },
                                            {
                                                validator: (_, value) => {
                                                    if (value === '' || value === undefined) {
                                                        return Promise.resolve()
                                                    }
                                                    if (form.getFieldError(input.id).length > 0) {
                                                        return Promise.reject(
                                                            'Please enter a valid value'
                                                        )
                                                    }

                                                    return Promise.resolve()
                                                },
                                            },
                                        ]}
                                    >
                                        <ContextInputComponent
                                            inputs={inputs}
                                            input={input.id}
                                            defaultValue={inputValues[input.id]}
                                            onChange={onValuesChange}
                                        />
                                    </Form.Item>
                                </Col>
                            )
                        })}
                </Row>
            </Card>
            {inputsModal.isOpen && inputs && (
                <SelectInputsModal
                    inputs={inputs}
                    onCancel={() => inputsModal.close()}
                    onOk={handleSubmit}
                />
            )}
        </>
    )
}

const renderInputLabel = (input: InvestigationInputBaseObject) => {
    if (input.required) {
        return (
            <div style={{ display: 'flex', gap: 5 }}>
                <span>{input.label} </span>
            </div>
        )
    }
    return input.label
}
