import { Typography } from 'antd'
import type { Note } from './types'
import { NoteItem as StyledNoteItem } from './styles'
import { formatTimestamp } from 'packages/dates/dates'

interface NoteItemProps {
    note: Note
}

export const NoteItem = ({ note }: NoteItemProps) => (
    <StyledNoteItem>
        <Typography.Text strong style={{ fontSize: '0.75rem' }}>
            {note.author}
        </Typography.Text>
        <Typography.Text type="secondary" style={{ fontSize: '0.75rem' }}>
            {formatTimestamp(note.createdAt)}
        </Typography.Text>
        <Typography.Text>{note.text}</Typography.Text>
    </StyledNoteItem>
)
