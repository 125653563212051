import { useState } from 'react'

import { PageHeader } from '@ant-design/pro-layout'
import { Button, Card, Layout, Pagination, Table, Tag, Typography, message } from 'antd'
import { PageContentLayout } from 'components/layout-containers'
import { formatDate } from 'packages/dates/dates'

import { bff } from '../bff'

const { Text } = Typography

const LIMIT = 10

export const PermissionRequests = () => {
    const [offset, setOffset] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const {
        data: permissionChangeRequests,
        error: permissionChangeRequestsError,
        isLoading: permissionChangeRequestsLoading,
    } = bff.permissions.listPermissionsChangeRequests.useQuery(
        { params: { status: ['PENDING', 'APPROVED', 'DENIED'], limit: LIMIT, offset } },
        { refetchOnWindowFocus: false }
    )

    const { data: oktaPermissions } = bff.permissions.listAllPermissions.useQuery(
        { params: { include_deleted: false } },
        { refetchOnWindowFocus: false }
    )

    const { mutate: approveRequest } = bff.permissions.approveRequest.useMutation({
        onSuccess: () => {
            message.success('Request approved')
        },
        onError: () => {
            message.error('Failed to approve request')
        },
    })
    const { mutate: denyRequest } = bff.permissions.denyRequest.useMutation({
        onSuccess: () => {
            message.success('Request denied')
        },
        onError: () => {
            message.error('Failed to deny request')
        },
    })
    const { mutate: applyRequest } = bff.permissions.applyRequest.useMutation({
        onSuccess: () => {
            message.success('Request applied')
        },
        onError: () => {
            message.error('Failed to apply request')
        },
    })

    const columns = [
        {
            title: 'Requester',
            dataIndex: 'createdBy',
            key: 'createdBy',
            render: (createdBy: string) => <Text copyable>{createdBy}</Text>,
        },
        {
            title: 'Request ID',
            dataIndex: 'id',
            key: 'id',
            render: (id: string) => {
                return <Text copyable>{id}</Text>
            },
        },
        {
            title: 'IDs to Add',
            dataIndex: 'idsToAdd',
            key: 'idsToAdd',
            render: (text: string[]) => {
                return text.map((id) => {
                    const permission = oktaPermissions?.find((perm) => perm.id === id)
                    return `${permission?.tag} - (${permission?.description})`
                })
            },
        },
        {
            title: 'IDs to Remove',
            dataIndex: 'idsToRemove',
            key: 'idsToRemove',
            render: (ids: string[]) => {
                if (!ids || ids.length === 0) {
                    return '-'
                }
                return <Text copyable>{ids.join(', ')}</Text>
            },
        },
        {
            title: 'Reason',
            dataIndex: 'reason',
            key: 'reason',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text: string) => {
                if (text === 'PENDING') {
                    return <Tag color="blue">{text.toLocaleLowerCase()}</Tag>
                } else if (text === 'APPROVED') {
                    return <Tag color="green">{text.toLocaleLowerCase()}</Tag>
                } else if (text === 'DENIED') {
                    return <Tag color="red">{text.toLocaleLowerCase()}</Tag>
                }
            },
        },
        {
            title: 'Updated At',
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            render: (text: string) => formatDate(text),
        },
        {
            title: 'Action',
            key: 'action',
            dataIndex: 'id',
            render: (_: any, record: any) => {
                if (record.status === 'PENDING') {
                    return (
                        <div>
                            <Button
                                onClick={() => {
                                    approveRequest({ requestId: record.id })
                                }}
                                style={{ marginRight: '1rem' }}
                            >
                                Approve
                            </Button>
                            <Button
                                onClick={() => {
                                    denyRequest({ requestId: record.id })
                                }}
                            >
                                Deny
                            </Button>
                        </div>
                    )
                } else if (record.status === 'APPROVED') {
                    return (
                        <Button
                            onClick={() => {
                                applyRequest({ requestId: record.id })
                            }}
                        >
                            Apply
                        </Button>
                    )
                }
            },
        },
    ]

    if (permissionChangeRequestsError) {
        return <div>Error: {permissionChangeRequestsError.message}</div>
    }

    return (
        <PageContentLayout>
            <PageHeader title="Manage permission requests" />
            <Layout>
                <Layout.Content>
                    <Card
                        style={{ marginBottom: '1rem' }}
                        type="inner"
                        title="Permission Requests"
                        loading={permissionChangeRequestsLoading}
                    >
                        <Table
                            dataSource={permissionChangeRequests?.data}
                            columns={columns}
                            pagination={false}
                        />
                        <Pagination
                            style={{ marginTop: '1rem', textAlign: 'center' }}
                            defaultCurrent={1}
                            total={
                                permissionChangeRequests?.totalCount
                                    ? Number(permissionChangeRequests.totalCount)
                                    : 0
                            }
                            pageSize={permissionChangeRequests?.limit}
                            onChange={(page) => {
                                setCurrentPage(page)
                                setOffset((page - 1) * LIMIT)
                            }}
                            current={currentPage}
                        />
                    </Card>
                </Layout.Content>
            </Layout>
        </PageContentLayout>
    )
}
