import { Alert, Modal, Space, Tooltip } from 'antd'
import { Form, SubmitButton, Select, Switch } from 'formik-antd'
import dayjs from 'dayjs'
import { Formik } from 'formik'
import styled from 'styled-components'
import * as yup from 'yup'
import { UnderwritingType } from 'bff/moons/generated/kale'
import { useState } from 'react'

const validationSchema = yup.object().shape({
    type: yup.mixed<UnderwritingType>().oneOf(Object.values(UnderwritingType)).required(),
    eligible: yup.bool().required(),
})

const nameMap = {
    [UnderwritingType.HIGHER_LIMIT_APPLICATION]: 'Higher limit',
    [UnderwritingType.OVERDRAFT_APPLICATION]: 'Overdraft application',
}

interface Props {
    open: boolean
    eligibility: { [key in UnderwritingType]?: boolean }
    onSubmit: (values: { type: UnderwritingType; eligible: boolean }) => void
    onCancel: () => void
}

export default function EligibilityChangeModal({ open, eligibility, onSubmit, onCancel }: Props) {
    const now = dayjs().utc()
    const nextDay = now.add(1, 'day').startOf('day').utc()

    const remaining = nextDay.diff(now, 'hours')

    const [type, setType] = useState<UnderwritingType>(UnderwritingType.OVERDRAFT_APPLICATION)

    const initialValues = {
        type: type,
        eligible: eligibility[type] || false,
    }

    return (
        <Modal
            title="Update underwriting eligibility"
            onCancel={onCancel}
            open={open}
            footer={null}
        >
            <StyledModalContent>
                <Alert
                    type="info"
                    message="Notice"
                    description={
                        <span>
                            Customer will have to submit an application within{' '}
                            <Tooltip title={nextDay.toString()}>{remaining.toString()}</Tooltip>{' '}
                            hours before it is overridden by our automatic process.
                        </span>
                    }
                    showIcon
                />
                <Formik
                    enableReinitialize
                    validationSchema={validationSchema}
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                >
                    {({ isValid }) => (
                        <Form>
                            <Form.Item name="type">
                                <Select name="type" placeholder="Type" onChange={(e) => setType(e)}>
                                    {Object.values(UnderwritingType).map((v) => (
                                        <Select.Option key={v} value={v}>
                                            {nameMap[v]}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item name="eligible">
                                <Space>
                                    <Switch name="eligible" />
                                    Eligible
                                </Space>
                            </Form.Item>
                            <SubmitButton disabled={!isValid} type="primary">
                                Update
                            </SubmitButton>
                        </Form>
                    )}
                </Formik>
            </StyledModalContent>
        </Modal>
    )
}

const StyledModalContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`
