import { Button, Card, Modal, Space, Typography, notification } from 'antd'
import { bff } from '../bff-hooks'
import { useKycDetailContext } from '../context'
import { useState } from 'react'

export const RequestInformation = () => {
    const { caseId } = useKycDetailContext()
    const [isRequestModalVisible, setIsRequestModalVisible] = useState(false)
    const { data, isLoading } = bff.kycInformationRequests.getCase.useQuery({
        caseId,
    })

    const disabled = !data?.isEditable || isLoading

    return (
        <>
            <Card size="small" title="Request Information" style={{ marginBottom: '1rem' }}>
                <Space direction="vertical" style={{ width: '100%' }}>
                    <Button
                        disabled={disabled}
                        block
                        onClick={() => setIsRequestModalVisible(true)}
                        data-testid="request-information-button"
                    >
                        Request Information
                    </Button>
                </Space>
            </Card>
            <ConfirmSendRequestModal
                open={isRequestModalVisible}
                onClose={() => setIsRequestModalVisible(false)}
            />
        </>
    )
}

const ConfirmSendRequestModal = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
    const { caseId } = useKycDetailContext()
    const { data, isLoading } = bff.kycInformationRequests.getCompanyId.useQuery({ caseId })
    const { mutateAsync: sendInformationRequest, isLoading: isSendingInformationRequest } =
        bff.kycInformationRequests.sendKycInformationRequest.useMutation()

    const errorNotification = () => {
        return notification.error({
            message: 'Failed to send request',
        })
    }

    const successNotification = () => {
        return notification.success({
            message: 'Successfully sent request',
        })
    }

    const handleOk = async () => {
        if (!data?.companyId) {
            errorNotification()
            return
        }
        try {
            await sendInformationRequest({ companyId: data?.companyId, isRecurring: false })
            successNotification()
            onClose()
        } catch (e) {
            errorNotification()
        }
    }

    return (
        <Modal
            title="Request Information"
            onOk={handleOk}
            okText="Send email"
            onCancel={onClose}
            okButtonProps={{ loading: isSendingInformationRequest }}
            open={open}
            loading={isLoading}
        >
            <Typography.Text>
                This will send an email to the admin of {data?.companyName} asking them to provide
                extra information.
            </Typography.Text>
        </Modal>
    )
}
