export function getMaxEligibilityApplicationLimit(currency: string): number {
    switch (currency) {
        case 'EUR':
        case 'GBP':
            return 500_000
        case 'DKK':
        case 'SEK':
            return 5_000_000
        default:
            return 0
    }
}
