import { useState, useCallback } from 'react'
import { Modal, Select, Space, Typography } from 'antd'
import {
    WAITING_FOR_CUSTOMER_REASON_OPTIONS,
    WAITING_FOR_CUSTOMER_SUB_REASON_OPTIONS,
} from '../types'
import type {
    WaitingForCustomerReason,
    WaitingForCustomerSubReason,
} from 'bff/moons/generated/cdd-case-manager'

interface WaitingForCustomerTransitionModalProps {
    onSubmit: (
        reason: WaitingForCustomerReason,
        subReason: WaitingForCustomerSubReason
    ) => Promise<void>
    open: boolean
    onClose: () => void
}

export const WaitingForCustomerTransitionModal = ({
    onSubmit,
    open,
    onClose,
}: WaitingForCustomerTransitionModalProps) => {
    const [reason, setReason] = useState<WaitingForCustomerReason | null>(null)
    const [subReason, setSubReason] = useState<WaitingForCustomerSubReason | null>(null)
    const [isSubmitting, setIsSubmitting] = useState(false)

    const handleReasonChange = useCallback((value: WaitingForCustomerReason) => {
        setReason(value)
        setSubReason(null)
    }, [])

    const handleSubReasonChange = useCallback((value: WaitingForCustomerSubReason) => {
        setSubReason(value)
    }, [])

    const handleSubmit = useCallback(async () => {
        if (!reason || !subReason) return

        try {
            setIsSubmitting(true)
            await onSubmit(reason, subReason)
            onClose()
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error('Failed to update status:', error)
        } finally {
            setIsSubmitting(false)
        }
    }, [reason, subReason, onSubmit, onClose])

    const handleClose = useCallback(() => {
        setReason(null)
        setSubReason(null)
        onClose()
    }, [onClose])

    const isSubmitDisabled = !reason || !subReason || isSubmitting

    return (
        <Modal
            title="Mark as Waiting for Customer"
            open={open}
            onOk={handleSubmit}
            okText="Mark as Waiting for Customer"
            onCancel={handleClose}
            okButtonProps={{
                disabled: isSubmitDisabled,
                loading: isSubmitting,
            }}
            destroyOnClose
        >
            <Space direction="vertical" style={{ width: '100%' }} size="middle">
                <div>
                    <Typography.Text strong>Reason</Typography.Text>
                    <Select<WaitingForCustomerReason>
                        placeholder="Select a reason"
                        onChange={handleReasonChange}
                        style={{ width: '100%', marginTop: 8 }}
                        options={WAITING_FOR_CUSTOMER_REASON_OPTIONS}
                        value={reason}
                        disabled={isSubmitting}
                        data-testid="waiting-for-customer-reason"
                    />
                </div>
                <div>
                    <Typography.Text strong>Sub-reason</Typography.Text>
                    <Select<WaitingForCustomerSubReason>
                        placeholder="Select a sub reason"
                        onChange={handleSubReasonChange}
                        style={{ width: '100%', marginTop: 8 }}
                        options={reason ? WAITING_FOR_CUSTOMER_SUB_REASON_OPTIONS[reason] : []}
                        disabled={!reason || isSubmitting}
                        value={subReason}
                        data-testid="waiting-for-customer-sub-reason"
                    />
                </div>
            </Space>
        </Modal>
    )
}
