import { Button, Col, Flex, message, Modal, Row, Select, Table, Typography } from 'antd'
import { ActionsContainer, BreakText } from 'components/layout-containers'
import { useState } from 'react'
import { bff } from '../bff'
import { ExclamationCircleOutlined, LoadingOutlined, UserAddOutlined } from '@ant-design/icons'

const { Text, Link } = Typography
const { confirm } = Modal

export const MembersCell = ({
    partnerId,
    team,
}: {
    partnerId: string
    team: {
        id: string
        name: string
        userIds: string[]
    }
}) => {
    const [showMembers, setShowMembers] = useState(false)

    const { data: members, isLoading } = bff.partnerTeamMembers.getTeamMembers.useQuery(
        {
            partnerId,
            teamId: team.id,
        },
        {
            enabled: showMembers,
        }
    )

    const addableEmployees = members?.addableEmployees
    const teamMembers = members?.members

    return (
        <ActionsContainer>
            <Button type="link" onClick={() => setShowMembers(true)}>
                {team.userIds.length === 0 ? 'None' : `${team.userIds.length} members`}
            </Button>

            <Modal
                title={
                    <Row justify="space-between">
                        <Col span={16}>
                            <Text ellipsis>Members for {team.name}</Text>
                        </Col>
                        <Col pull={1}>
                            <AddTeamMember
                                teamId={team.id}
                                addableEmployees={addableEmployees}
                                isLoading={isLoading}
                            />
                        </Col>
                    </Row>
                }
                open={showMembers}
                onCancel={() => setShowMembers(false)}
                footer={null}
                width={1200}
                centered
                destroyOnClose
            >
                <MembersTable teamId={team.id} members={teamMembers} isLoading={isLoading} />
            </Modal>
        </ActionsContainer>
    )
}

const MembersTable = ({
    teamId,
    members,
    isLoading,
}: {
    teamId: string
    members: { userId: string; name: string; email: string }[] | undefined
    isLoading: boolean
}) => {
    return (
        <Table
            loading={isLoading}
            dataSource={members}
            columns={[
                {
                    title: 'User ID',
                    dataIndex: 'userId',
                    render: (userId) => <Text copyable>{userId}</Text>,
                },
                {
                    title: 'Name',
                    dataIndex: 'name',
                    render: (name, { userId }) => (
                        <Link href={`/customer-success/users/${userId}`}>{name}</Link>
                    ),
                },
                {
                    title: 'Email',
                    dataIndex: 'email',
                    render: (email) => (
                        <Text copyable={{ text: email }}>
                            <BreakText>{email}</BreakText>
                        </Text>
                    ),
                },
                {
                    title: 'Remove',
                    render: (_, member) => <RemoveTeamMember member={member} teamId={teamId} />,
                },
            ]}
        />
    )
}

const AddTeamMember = ({
    teamId,
    addableEmployees,
    isLoading,
}: {
    teamId: string
    addableEmployees: { userId: string; name: string }[] | undefined
    isLoading: boolean
}) => {
    const [userId, setUserId] = useState<string | undefined>()

    const { mutateAsync: addMember, isLoading: isAdding } =
        bff.partnerTeamMembers.addMember.useMutation({
            onSuccess({ data: { userId: addedUserId } }) {
                setUserId(undefined)
                const { name } =
                    addableEmployees?.find((employee) => employee.userId === addedUserId) ?? {}
                message.success(`Employee "${name}" added to team`)
            },
        })

    return (
        <Flex align={'middle'} gap={8}>
            <Select
                allowClear
                showSearch
                aria-label="Search member to add"
                style={{ width: 220 }}
                placeholder="Search members"
                notFoundContent="No un-added member found. Try another search"
                optionFilterProp="label"
                filterOption={(input, option) =>
                    (option?.label?.toLowerCase() ?? '').includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '')
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? '').toLowerCase())
                }
                loading={isLoading}
                options={addableEmployees?.map((employee) => ({
                    label: employee.name,
                    value: employee.userId,
                }))}
                onClear={() => setUserId(undefined)}
                onSelect={(value) => setUserId(value)}
            />
            <Button
                aria-label="Add member"
                type="primary"
                disabled={!userId}
                loading={isAdding}
                onClick={async () => {
                    if (!userId) return
                    await addMember({ teamId, userId })
                }}
                icon={isAdding ? <LoadingOutlined /> : <UserAddOutlined />}
            >
                Add
            </Button>
        </Flex>
    )
}

const RemoveTeamMember = ({
    member,
    teamId,
}: {
    member: { userId: string; name: string }
    teamId: string
}) => {
    const { mutateAsync: removeMember } = bff.partnerTeamMembers.removeMember.useMutation({
        onSuccess() {
            message.success(`Employee "${member.name}" removed from team`)
        },
    })

    return (
        <ActionsContainer>
            <Button
                type="link"
                danger
                aria-label={`Remove ${member.name}`}
                onClick={() => {
                    confirm({
                        icon: <ExclamationCircleOutlined />,
                        title: `Are you sure you want to remove "${member.name}" from this team?`,
                        content:
                            "They will lose access to all clients that are assigned to the team, unless they're also a member of another team that has access to the same clients.",
                        okText: 'Remove member',
                        okType: 'danger',
                        async onOk() {
                            await removeMember({ teamId, userId: member.userId })
                        },
                        width: 500,
                    })
                }}
            >
                Remove
            </Button>
        </ActionsContainer>
    )
}
