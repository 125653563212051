import Loading from './loading-result/loading-result'
import { Tabs } from 'antd'
import { ContentContainer } from 'components/layout-containers'
import type { FormValuesExtended } from './generate-demo-form'
import { GenerateExtendedDemoForm, GenerateProfileDemoForm } from './generate-demo-form'

const { TabPane } = Tabs

interface Props {
    loading: boolean
    onSubmitExtended: (values: FormValuesExtended) => Promise<void>
}

const GenerateDemo = ({ loading, onSubmitExtended }: Props) => {
    if (loading) {
        return <Loading />
    }

    return (
        <ContentContainer>
            <Tabs>
                <TabPane tab="Extended" key="extended">
                    <GenerateExtendedDemoForm onSubmit={onSubmitExtended} />
                </TabPane>
                <TabPane tab="Advanced (profile)" key="profile">
                    <GenerateProfileDemoForm />
                </TabPane>
            </Tabs>
        </ContentContainer>
    )
}

export default GenerateDemo
