import { DatePicker, Input, InputNumber, Select } from 'antd'
import type { RangePickerProps } from 'antd/lib/date-picker'

import type { InvestigationContextInputs } from 'bff/moons/generated/investigation-runner'

import { mapInputTypesToAntdTypes } from './utils'
import dayjs from 'dayjs'

const { RangePicker } = DatePicker

type ContextInputType = {
    inputs: InvestigationContextInputs
    input: string
    defaultValue: string | undefined
    onChange: (target: string, value: string | undefined) => void
}

export const ContextInputComponent = ({
    inputs,
    input,
    defaultValue,
    onChange,
}: ContextInputType) => {
    const inputType = inputs[input].type

    switch (mapInputTypesToAntdTypes[inputType]) {
        case 'Input':
            return (
                <Input
                    key={input}
                    name={input}
                    placeholder="Please enter a string value"
                    onChange={(e) => onChange(input, e.target.value)}
                    value={defaultValue}
                    allowClear
                />
            )
        case 'InputNumber':
            return (
                <InputNumber
                    key={input}
                    name={input}
                    placeholder="Please enter a number value"
                    onChange={(value) => onChange(input, value?.toString())}
                    value={defaultValue}
                />
            )
        case 'Select': {
            const possibleValues = inputs[input].possibleValues as unknown as string[]
            return (
                <Select
                    key={input}
                    placeholder="Please select an option"
                    onChange={(value) => onChange(input, value)}
                    value={defaultValue}
                    allowClear
                >
                    {possibleValues.map((value: string) => (
                        <Select.Option key={value} value={value}>
                            {value}
                        </Select.Option>
                    ))}
                </Select>
            )
        }
        case 'RangePicker':
            return (
                <RangePicker
                    key={input}
                    onChange={(
                        values: RangePickerProps['value'],
                        formatString: [string, string]
                    ) => {
                        if (!values) {
                            onChange(input, undefined)
                            return
                        }
                        onChange(input, formatString.join('..'))
                    }}
                    value={
                        defaultValue?.split('..').map((date) => dayjs.utc(date)) as [
                            dayjs.Dayjs,
                            dayjs.Dayjs,
                        ]
                    }
                    allowClear
                />
            )

        case 'DatePicker':
            return (
                <DatePicker
                    key={input}
                    onChange={(value) => onChange(input, value.format('YYYY-MM-DD'))}
                    value={defaultValue ? dayjs(defaultValue) : undefined}
                    allowClear
                />
            )
        case 'DateTime':
            return (
                <DatePicker
                    key={input}
                    showTime
                    onChange={(value) => onChange(input, value.format('YYYY-MM-DD'))}
                    value={defaultValue ? dayjs(defaultValue) : undefined}
                    allowClear
                />
            )
        case 'Checkbox':
            return (
                <Input
                    key={input}
                    name={input}
                    type="checkbox"
                    onChange={(e) => onChange(input, e.target.value)}
                    value={defaultValue}
                />
            )
        default:
            return null
    }
}
