import { Select } from 'antd'
import { getUrlMethods, useFilterState } from 'packages/filters/use-filter-state'
import { bff } from '../bff-hooks'
import { useState } from 'react'

const defaultValue = null
const paramName = 'assignee'
const { getUrlValue, setUrlValue } = getUrlMethods(paramName, defaultValue)

export function useAssigneeFilterState() {
    return useFilterState(getUrlValue, setUrlValue, defaultValue)
}
type AssigneeFilterProps = {
    filter: ReturnType<typeof useAssigneeFilterState>
    onChange: () => void
}
export function AssigneeFilter({ filter, onChange }: AssigneeFilterProps) {
    const [adminQuery, setAdminQuery] = useState('')
    const { data, isLoading } = bff.assigneeFilter.queryKerberosAdmins.useQuery({
        query: adminQuery,
    })

    const handleSelect = (userOption: { value: string }) => {
        filter.directApply(userOption.value)
        onChange()
    }

    return (
        <Select
            showSearch
            labelInValue
            filterOption={false}
            allowClear
            placeholder="Assignee"
            style={{ width: '100%' }}
            options={data?.map((user) => ({
                label: user.email,
                value: user.id,
            }))}
            value={filter.appliedValue ? { value: filter.appliedValue } : undefined}
            onSearch={setAdminQuery}
            onSelect={handleSelect}
            onClear={() => filter.directApply(null)}
            loading={isLoading}
        />
    )
}
