import type { FC, PropsWithChildren } from 'react'

import { ContentContainer, PageContentLayout } from 'components/layout-containers'
import { useEmployeeBankTransfer } from 'services/eirene/bank-transfers'
import {
    BackOfficeReimbursementResponseModel,
    ScreeningStatusResponseModel,
    BankTransferStatusModel,
    ReviewReimbursementScreeningRequestModelBankReviewResult,
    ReviewReimbursementScreeningRequestModelEmployeeReviewResult,
    ReviewReimbursementScreeningRequestModel,
} from 'bff/moons/generated/eirene-v1'

import { getEmojiFlag } from 'countries-list'
import { useParams } from 'react-router-dom'
import { PageHeader } from '@ant-design/pro-layout'
import { Button, Card, Collapse, Descriptions, Typography } from 'antd'
import * as Yup from 'yup'

import { Form, Input, Select, SelectProps } from 'formik-antd'
import formatCurrency from 'utils/format-currency'
import { DetailsContainer } from '../shared/styled'
import styled from 'styled-components'
import { spacing } from 'theme/tokens'
import dayjs from 'packages/dayjs'
import { Formik } from 'formik'
import { buildReimbursementsMetabaseLink } from './helpers'
import { ArrowLeftOutlined } from '@ant-design/icons'

const { Text, Link: AntLink } = Typography

const validationSchema = Yup.object()
    .shape({
        note: Yup.string().required("Note can't be empty"),
        bankReviewResult: Yup.string()
            .required('Please select a value')
            .nullable()
            .oneOf([
                ...Object.values(ReviewReimbursementScreeningRequestModelBankReviewResult),
                null,
            ]),
        employeeReviewResult: Yup.string()
            .required('Please select a value')
            .nullable()
            .oneOf([
                ...Object.values(ReviewReimbursementScreeningRequestModelEmployeeReviewResult),
                null,
            ]),
    })
    .required()

interface Props {
    transfer: BackOfficeReimbursementResponseModel
    onSubmit: (payload: ReviewReimbursementScreeningRequestModel) => Promise<void>
}

interface ReviewStatusSelectProps {
    label: string
    name: string
    isDisabled?: boolean
    isReviewable: boolean
    defaultValue: ReviewReimbursementScreeningRequestModelBankReviewResult | null
}

const ReviewStatusSelect = ({
    label,
    name,
    defaultValue,
    isReviewable,
    isDisabled = false,
    onChange,
    ...rest
}: ReviewStatusSelectProps & SelectProps) => {
    const reviewOptions = !isDisabled
        ? [
              ReviewReimbursementScreeningRequestModelBankReviewResult.FALSE_POSITIVE,
              ReviewReimbursementScreeningRequestModelBankReviewResult.FAILURE_CONFIRMED,
          ]
        : []
    if (!isReviewable) {
        return null
    } else {
        return (
            <Form.Item label={label} name={name}>
                <Select
                    {...rest}
                    placeholder=""
                    name={name}
                    disabled={isDisabled}
                    defaultValue={defaultValue}
                    onChange={onChange}
                >
                    {reviewOptions.map((key) => (
                        <Select.Option value={key} key={key}>
                            {key}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>
        )
    }
}

export const BankTransfer: FC<Props> = ({ transfer, onSubmit }) => {
    const {
        id,
        reimbursementType,
        status,
        screeningStatus,
        amount,
        bankDetails,
        employeeDetails,
        company,
        requestedAt,
    } = transfer
    const isReviewable = status === BankTransferStatusModel.PENDING_SCREENING_REVIEW

    const initialBankReviewResult =
        screeningStatus.bank === ScreeningStatusResponseModel.PASSED
            ? ReviewReimbursementScreeningRequestModelBankReviewResult.PASS
            : null
    const initialEmployeeReviewResult =
        screeningStatus.employee === ScreeningStatusResponseModel.PASSED
            ? ReviewReimbursementScreeningRequestModelEmployeeReviewResult.PASS
            : null

    const initialNote = screeningStatus.reviewNote || ''

    const pageTitle = `${employeeDetails.employeeName} - ${bankDetails.name} (${getEmojiFlag(
        bankDetails.countryCode
    )} ${bankDetails.countryCode})`
    return (
        <>
            <PageHeader
                ghost={false}
                onBack={() => window.history.back()}
                backIcon={<ArrowLeftOutlined />}
                title={pageTitle}
            >
                <DetailsContainer>
                    <Descriptions column={1} size="small">
                        <Descriptions.Item label="Reimbursement ID">
                            <Text copyable>{id}</Text>
                        </Descriptions.Item>
                        <Descriptions.Item label="Reimbursement Type">
                            <Text>{reimbursementType}</Text>
                        </Descriptions.Item>
                        <Descriptions.Item label="Status">
                            <Text mark>{status}</Text>
                        </Descriptions.Item>
                        <Descriptions.Item label="Requested">
                            <Text>{dayjs(requestedAt).format('lll')}</Text>
                        </Descriptions.Item>
                        <Descriptions.Item label="Amount">
                            {formatCurrency(amount.value, amount.currency, {
                                currencyDisplay: 'code',
                            })}
                        </Descriptions.Item>
                        <Descriptions.Item label="Company">
                            <AntLink href={`/compliance/companies/${company.id}`}>
                                {company.name}
                            </AntLink>
                        </Descriptions.Item>
                    </Descriptions>
                    <Descriptions column={1} size="small">
                        <Descriptions.Item label="Metabase">
                            <AntLink
                                href={`https://metabase.pleo.io/dashboard/3?company_id=${company.id}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Company details
                            </AntLink>
                        </Descriptions.Item>
                        <Descriptions.Item label="Metabase">
                            <AntLink
                                href={buildReimbursementsMetabaseLink(employeeDetails.employeeId)}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Employee bank reimbursements
                            </AntLink>
                        </Descriptions.Item>
                    </Descriptions>
                </DetailsContainer>
            </PageHeader>
            <ContentContainer>
                <Formik<Yup.InferType<typeof validationSchema>>
                    initialValues={{
                        bankReviewResult: initialBankReviewResult,
                        employeeReviewResult: initialEmployeeReviewResult,
                        note: initialNote,
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        onSubmit({
                            ...values,
                            employeeReviewResult: values.employeeReviewResult!,
                            bankReviewResult: values.bankReviewResult!,
                        })
                    }}
                >
                    {({ submitForm, isValid, values }) => (
                        <Form layout="horizontal">
                            <WidgetSection>
                                <Card>
                                    <Collapse defaultActiveKey={'bank-status'}>
                                        <Collapse.Panel
                                            header={`Bank Screening Status - ${screeningStatus.bank}`}
                                            key="bank-status"
                                        >
                                            <Descriptions column={1} size="small">
                                                <Descriptions.Item label="Bank name">
                                                    <Text>{bankDetails.name}</Text>
                                                </Descriptions.Item>
                                                <Descriptions.Item label="Bank Country">
                                                    <Text>
                                                        {getEmojiFlag(bankDetails.countryCode)}{' '}
                                                        {bankDetails.countryCode}
                                                    </Text>
                                                </Descriptions.Item>
                                            </Descriptions>
                                            <ReviewStatusSelect
                                                data-testid="bankReviewResult"
                                                label="Manual Review Status"
                                                name="bankReviewResult"
                                                isReviewable={isReviewable}
                                                isDisabled={
                                                    screeningStatus.bank ===
                                                        ScreeningStatusResponseModel.PASSED ||
                                                    !isReviewable
                                                }
                                                defaultValue={values.bankReviewResult}
                                            />
                                        </Collapse.Panel>
                                    </Collapse>
                                </Card>
                                <Card>
                                    <Collapse defaultActiveKey={'account-status'}>
                                        <Collapse.Panel
                                            header={`Employee Screening Status - ${screeningStatus.employee}`}
                                            key="account-status"
                                        >
                                            <Descriptions column={1} size="small">
                                                <Descriptions.Item label="Employee ID">
                                                    <Text copyable>
                                                        {employeeDetails.employeeId}
                                                    </Text>
                                                </Descriptions.Item>
                                                <Descriptions.Item label="Employee Name">
                                                    <Text>{employeeDetails.employeeName}</Text>
                                                </Descriptions.Item>
                                                {employeeDetails.employeeDateOfBirth && (
                                                    <Descriptions.Item label="Employee DOB">
                                                        <Text>
                                                            {employeeDetails.employeeDateOfBirth}
                                                        </Text>
                                                    </Descriptions.Item>
                                                )}
                                                {employeeDetails.employeeAddress && (
                                                    <Descriptions.Item label="Employee Address">
                                                        <Text>
                                                            {`
                                                            ${employeeDetails.employeeAddress.address1},
                                                            ${employeeDetails.employeeAddress.city},
                                                            ${employeeDetails.employeeAddress.countryCode},
                                                            ${employeeDetails.employeeAddress.zipCode}
                                                        `}
                                                        </Text>
                                                    </Descriptions.Item>
                                                )}
                                            </Descriptions>
                                            <ReviewStatusSelect
                                                data-testid="employeeReviewResult"
                                                label="Manual Review Status"
                                                name="employeeReviewResult"
                                                isReviewable={isReviewable}
                                                isDisabled={
                                                    screeningStatus.employee ===
                                                        ScreeningStatusResponseModel.PASSED ||
                                                    !isReviewable
                                                }
                                                defaultValue={values.employeeReviewResult}
                                            />
                                        </Collapse.Panel>
                                    </Collapse>
                                </Card>
                            </WidgetSection>
                            <SubmitCard>
                                <SubmitControlsSection>
                                    <Form.Item label="Note" name="note">
                                        <Input.TextArea
                                            name="note"
                                            data-testid="noteInput"
                                            placeholder="A note around status review"
                                            defaultValue={values.note || ''}
                                            disabled={!isReviewable}
                                        />
                                    </Form.Item>

                                    <Button
                                        onClick={submitForm}
                                        type="primary"
                                        data-testid="saveStatusButton"
                                        disabled={
                                            (isValid && !isReviewable) ||
                                            !(
                                                values.employeeReviewResult &&
                                                values.bankReviewResult
                                            )
                                        }
                                    >
                                        Save review status
                                    </Button>
                                </SubmitControlsSection>
                            </SubmitCard>
                        </Form>
                    )}
                </Formik>
            </ContentContainer>
        </>
    )
}

const BankTransferContainer: FC<PropsWithChildren<unknown>> = () => {
    const { id } = useParams()
    const {
        data: bankTransfer,
        isValidating,
        mutations: { updateScreeningReview },
    } = useEmployeeBankTransfer(id)
    const isLoading = isValidating || !bankTransfer

    return (
        <PageContentLayout>
            {isLoading ? null : (
                <BankTransfer transfer={bankTransfer} onSubmit={updateScreeningReview} />
            )}
        </PageContentLayout>
    )
}

export default BankTransferContainer

const WidgetSection = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: auto;
    grid-gap: ${spacing.space24};
`

const SubmitCard = styled(Card)`
    margin-top: ${spacing.space24};
`

const SubmitControlsSection = styled.div`
    display: grid;
    grid-template-columns: 1fr auto;
    grid-auto-rows: auto;
    grid-gap: ${spacing.space24};
`
