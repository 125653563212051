import { Card } from 'antd'
import type { FC } from 'react'
import styled from 'styled-components'
import { spacing } from 'theme/tokens'
import { DemoAccountExpiryInput } from './components/demo-account-expiry-input'
import { DemoAccountSubscriptionDropdown } from './components/demo-account-subscription-dropdown'
import { DemoAccountCreateExpenseDropdown } from './components/demo-account-create-expense-dropdown'
import { DemoAccountQuickLinksDropdown } from './components/demo-account-quick-links-dropdown'
import { DemoAccountMiscellaneousActionsDropdown } from './components/demo-account-miscellaneous-actions-dropdown'
import type { DemoAccount } from '../bff/index.bff'
import type { Company } from 'bff/moons/generated/dionysus'
import { DemoAccountOrgDropdown } from './components/demo-account-org-dropdown'

interface Props {
    demoAccount: DemoAccount
    selectedCompany: Company
    revalidateDemoAccount?: () => void
}

export const SingleDemoAccountCompanyActionsCard: FC<Props> = ({
    demoAccount,
    selectedCompany,
    revalidateDemoAccount,
}: Props) => {
    return (
        <>
            <Card
                data-testid="single-demo-account-company-actions-card"
                title={
                    <DemoAccountOrgDropdown
                        selectedCompany={selectedCompany}
                        demoAccount={demoAccount}
                    />
                }
                style={{ marginBottom: spacing.space16 }}
                extra={<DemoAccountQuickLinksDropdown companyId={selectedCompany.id} />}
            >
                <RowSpaceBetween>
                    <Row>
                        <Column>
                            <DemoAccountCreateExpenseDropdown
                                selectedCompany={selectedCompany}
                                revalidateDemoAccount={revalidateDemoAccount}
                            />
                        </Column>
                        <Column>
                            <DemoAccountSubscriptionDropdown
                                companyId={selectedCompany.id}
                                revalidateDemoAccount={revalidateDemoAccount}
                            />
                        </Column>
                        <Column>
                            <DemoAccountMiscellaneousActionsDropdown
                                demoAccount={demoAccount}
                                selectedCompany={selectedCompany}
                            />
                        </Column>
                    </Row>
                    <Column>
                        <DemoAccountExpiryInput
                            demoAccount={demoAccount}
                            revalidateDemoAccount={revalidateDemoAccount}
                        />
                    </Column>
                </RowSpaceBetween>
            </Card>
        </>
    )
}

const Row = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: ${spacing.space8};
`

const Column = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${spacing.space8};
`

const RowSpaceBetween = styled.div`
    display: flex;
    justify-content: space-between;
`
