import { Route } from 'react-router-dom'
import { KycProcessingHub } from './screens/kyc-processing-hub'
import { KycDetailsPage } from './screens/kyc-details-page'

export const kycProcessingHubRoutes = (
    <Route>
        <Route index element={<KycProcessingHub />} />
        <Route path=":pathTab" element={<KycProcessingHub />} />
        <Route path="case/:caseId" element={<KycDetailsPage />} />
    </Route>
)
