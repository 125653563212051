import { ExclamationCircleOutlined } from '@ant-design/icons'
import {
    Alert,
    Button,
    Input,
    Modal,
    Result,
    Select,
    Space,
    Spin,
    Table,
    Tag,
    Typography,
    message,
} from 'antd'
import type { ColumnProps } from 'antd/es/table'
import type { SortOrder } from 'antd/lib/table/interface'
import { ActionsContainer, BreakText, ContentContainer } from 'components/layout-containers'
import React, { ChangeEvent, FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { inputWidth } from 'theme/tokens'

import type { PartnerEmployee } from 'components/index.bff'
import { DeactivatedPartnerEmployees } from 'components/deactivated-partner-employees/deactivated-partner-employees'
import { useHasPermissions } from 'components/permission-guard/permission-guard'
import { updatePartnerEmployeeRole } from 'services/kerberos/permissions'
import { PartnerDisplayRole, PartnerRole } from 'types/employee'
import type { Partner } from 'types/partner-portal'
import {
    filterPartnerEmployeesByNameOrEmail,
    getPartnerEmployeeName,
    getUserDisplayRoleByUserRole,
} from 'utils/partner-employees'
import type { PartnerCompany } from '../../pages/customer-success/partner/index.bff'
import InvitePartnerEmployeeForm from './invite-employee-form/invite-partner-employee-form'

import { bff } from 'components/bff-hooks'
import dayjs from 'dayjs'
import { camelCaseToSentenceCase } from 'utils/strings'

const { confirm } = Modal
const { Option } = Select
const { Text } = Typography

const PartnerClients: FC<
    React.PropsWithChildren<{
        companies: PartnerCompany[]
        onCompanyClick: (companyId: string) => void
    }>
> = ({ companies, onCompanyClick }) => {
    const companyColumns: ColumnProps<PartnerCompany>[] = [
        {
            title: 'Company ID',
            dataIndex: 'id',
            render: (id) => <Text copyable>{id}</Text>,
            width: '30%',
        },
        {
            title: 'Company name',
            dataIndex: 'name',
            render: (_, company) => (
                <Button
                    onClick={() => onCompanyClick(company.id)}
                    type="link"
                    style={{ padding: 0 }}
                >
                    {company.name}
                </Button>
            ),
            width: '40%',
        },
        {
            title: 'FDD',
            dataIndex: 'fdd',
            render: (fdd) => (fdd ? dayjs(fdd).format('D MMM, YYYY') : 'No'),
            width: '18%',
        },
        {
            title: 'Access level',
            dataIndex: 'accessLevel',
            width: '12%',
        },
    ]
    return (
        <Table loading={!companies} rowKey="id" dataSource={companies} columns={companyColumns} />
    )
}

interface Props {
    partnerId: string
    canChangeRole: boolean
    employees: PartnerEmployee[]
    filter: string
    onChangeRole: (role: PartnerRole, employee: PartnerEmployee) => void
    onConfirmDeactivate: (employee: PartnerEmployee) => void
    onEmployeeClick: (employee: PartnerEmployee) => void
}

export const PartnerEmployees = ({
    partnerId,
    canChangeRole,
    employees,
    filter,
    onChangeRole,
    onConfirmDeactivate,
    onEmployeeClick,
}: Props) => {
    const navigate = useNavigate()
    const { data: partnerCompanies = [] } =
        bff.components.partnerEmployees.getPartnerClients.useQuery({ partnerId })
    const [clientsManagedModal, setClientsManagedModal] = useState<{
        visible: boolean
        employeeName: string
        companies: PartnerCompany[]
    }>({
        visible: false,
        employeeName: '',
        companies: [],
    })
    const [deactivatedEmployeeVisible, setDeactivatedEmployeeVisible] = useState(false)
    const filteredEmployees = filterPartnerEmployeesByNameOrEmail(employees, filter)

    const onCompanyClick = (companyId: string) => {
        navigate(`/customer-success/companies/${companyId}`)
    }

    const columns: ColumnProps<PartnerEmployee>[] = [
        {
            title: 'Name',
            dataIndex: 'firstName',
            key: 'id',
            render: (_, employee: PartnerEmployee) => (
                <Space>
                    <Button
                        onClick={() => onEmployeeClick(employee)}
                        type="link"
                        style={{ padding: 0 }}
                    >
                        {getPartnerEmployeeName(employee)}
                    </Button>
                    {employee.userPersona && (
                        <Tag color="yellow" data-testid="user-persona">
                            {camelCaseToSentenceCase(employee.userPersona)}
                        </Tag>
                    )}
                </Space>
            ),
            sorter: (a: PartnerEmployee, b: PartnerEmployee) =>
                getPartnerEmployeeName(a).localeCompare(getPartnerEmployeeName(b)),
            defaultSortOrder: 'ascend' as SortOrder,
        },
        {
            title: 'Role',
            dataIndex: 'role',
            filters: Object.values(PartnerDisplayRole).map((value) => ({
                value,
                text: value,
            })),
            onFilter: (value, employee) => getUserDisplayRoleByUserRole(employee.role) === value,
            render: (role: PartnerRole, employee) =>
                canChangeRole ? (
                    <Select
                        data-testid="change-role"
                        onChange={(value) => onChangeRole(value, employee)}
                        style={{ width: `${inputWidth.small}` }}
                        value={role}
                    >
                        <Option value="owner">{getUserDisplayRoleByUserRole('owner')}</Option>
                        <Option value="member">{getUserDisplayRoleByUserRole('member')}</Option>
                    </Select>
                ) : (
                    getUserDisplayRoleByUserRole(role)
                ),
        },
        {
            title: 'Email',
            dataIndex: 'email',
            render: (email) => <BreakText>{email}</BreakText>,
        },
        {
            title: 'Clients managed',
            dataIndex: 'clientCompanyIds',
            key: 'id',
            render: (_, employee: PartnerEmployee) => (
                <ActionsContainer>
                    <Button
                        type="link"
                        disabled={!employee?.clientCompanyIds?.length}
                        onClick={() => {
                            const employeeClients: PartnerCompany[] = partnerCompanies.filter((c) =>
                                employee.clientCompanyIds.includes(c.id)
                            )
                            setClientsManagedModal({
                                visible: true,
                                employeeName: getPartnerEmployeeName(employee),
                                companies: employeeClients,
                            })
                        }}
                    >
                        {employee?.clientCompanyIds?.length
                            ? `${employee.clientCompanyIds.length} Clients`
                            : 'None'}
                    </Button>
                </ActionsContainer>
            ),
        },
        {
            title: 'Action',
            dataIndex: 'id',
            render: (_, employee: PartnerEmployee) => (
                <ActionsContainer>
                    <Button
                        type="link"
                        danger
                        data-testid="deactivate"
                        disabled={!canChangeRole}
                        onClick={() => onConfirmDeactivate(employee)}
                    >
                        Deactivate
                    </Button>
                </ActionsContainer>
            ),
        },
    ]
    return (
        <ContentContainer>
            <Table dataSource={filteredEmployees} columns={columns} rowKey="id" />
            <Space>
                <Button onClick={() => setDeactivatedEmployeeVisible(true)}>
                    Deactivated employees
                </Button>
            </Space>
            <Modal
                title="Deactivated employees"
                open={deactivatedEmployeeVisible}
                onCancel={() => setDeactivatedEmployeeVisible(false)}
                footer={null}
                width={800}
                centered
                destroyOnClose
            >
                <DeactivatedPartnerEmployees partnerId={partnerId} />
            </Modal>
            <Modal
                title={`${clientsManagedModal.employeeName} clients`}
                open={clientsManagedModal.visible}
                onCancel={() =>
                    setClientsManagedModal({ visible: false, employeeName: '', companies: [] })
                }
                footer={null}
                width={1200}
                centered
                destroyOnClose
            >
                <PartnerClients
                    companies={clientsManagedModal.companies}
                    onCompanyClick={onCompanyClick}
                />
            </Modal>
        </ContentContainer>
    )
}

interface PartnerEmployeesContainerProps {
    partner: Partner
}

const PartnerEmployeesContainer = ({ partner }: PartnerEmployeesContainerProps) => {
    const navigate = useNavigate()
    const [filter, setFilter] = useState('')
    const [visible, setVisible] = useState(false)
    const canManagePartners = useHasPermissions(['partner-manager']) // only enforced in frontend
    const canChangeRoles = useHasPermissions(['company-role', 'styx']) // only 'company-role' enforced in backend
    const canChangePartnerEmployeeRole = canManagePartners || canChangeRoles
    const {
        data: employees,
        isLoading: isLoadingEmployees,
        error: employeesError,
        refetch: refetchEmployees,
    } = bff.components.partnerEmployees.getPartnerEmployees.useQuery({ partnerId: partner.id })
    const { mutateAsync: invitePartnerEmployee } =
        bff.components.partnerEmployees.invitePartnerEmployee.useMutation({
            onSuccess({ email }) {
                message.success(`${email} has been invited to join ${partner.name}`, 3)
            },
            onError(error) {
                message.error(`Error: ${error.message}`, 8)
            },
            onSettled() {
                setVisible(false)
            },
        })
    const { mutateAsync: deletePartnerEmployee } =
        bff.components.partnerEmployees.deletePartnerEmployee.useMutation({
            onSuccess: () => {
                message.success(`Done! The employee has been deactivated`)
            },
            onError: (error) => {
                message.error(`Error: ${error.message}`, 8)
            },
        })
    const handleFilter = (event: ChangeEvent<HTMLInputElement>) => setFilter(event.target.value)

    const onEmployeeClick = (employee: PartnerEmployee) =>
        navigate(`/customer-success/users/${employee.userId}`)

    const onChangeRole = (role: PartnerRole, employee: PartnerEmployee) => {
        confirm({
            icon: <ExclamationCircleOutlined />,
            title: 'Are you sure?',
            content: `${employee.firstName} ${
                employee.lastName
            } will now be a Partner ${getUserDisplayRoleByUserRole(role)}`,
            okText: 'Yes',
            async onOk() {
                const {
                    error,
                    message: msg,
                    success,
                } = await updatePartnerEmployeeRole(partner.id, employee.userId || '', role)
                if (success) {
                    refetchEmployees()
                    message.success(
                        `Done! ${employee.firstName} ${
                            employee.lastName
                        } is now a Partner ${getUserDisplayRoleByUserRole(role)}`
                    )
                } else if (error && msg) {
                    message.error(`Error: ${msg}`, 8)
                } else {
                    message.error('Something is not working. Please contact Team Zeus', 8)
                }
            },
        })
    }

    const onConfirmDeactivate = (employee: PartnerEmployee) => {
        const remainingAdmins = employees?.filter(
            (e) => !(e.id === employee.id || e.role !== 'owner')
        ).length
        if (!remainingAdmins) {
            return message.warning("Dude! You can't deactivate the last remaining Admin", 3)
        }

        confirm({
            icon: <ExclamationCircleOutlined />,
            title: 'Are you sure?',
            content: `This action will deactivate ${employee.firstName} ${employee.lastName}`,
            okText: 'Deactivate',
            okType: 'danger',
            async onOk() {
                await deletePartnerEmployee({
                    partnerId: partner.id,
                    partnerEmployeeId: employee.id,
                })
            },
            width: 500,
        })
    }

    return (
        <ContentContainer>
            <Row>
                <Input
                    placeholder="Filter by employee name"
                    onChange={handleFilter}
                    style={{ width: inputWidth.medium }}
                />
                <Button onClick={() => setVisible(true)}>Invite partner employee</Button>
            </Row>
            {isLoadingEmployees && !employees && <Spin />}
            {employees && !employeesError && (
                <PartnerEmployees
                    partnerId={partner.id}
                    canChangeRole={canChangePartnerEmployeeRole}
                    employees={employees}
                    filter={filter}
                    onChangeRole={onChangeRole}
                    onConfirmDeactivate={onConfirmDeactivate}
                    onEmployeeClick={onEmployeeClick}
                />
            )}
            {employeesError && (
                <Alert
                    message="Something went wrong whilst fetching partner employees. Please try again or if the problem persists report to #team-business-operations-enablement"
                    type="error"
                />
            )}
            {!employees && !isLoadingEmployees && !employeesError && (
                <Result title={`No employees found for ${partner.name}`} />
            )}
            <Modal
                destroyOnClose
                title="Invite a Partner Employee"
                open={visible}
                onCancel={() => setVisible(false)}
                footer={null}
                width={400}
            >
                <InvitePartnerEmployeeForm
                    onSubmit={async ({ email, firstName }) => {
                        await invitePartnerEmployee({ partnerId: partner.id, firstName, email })
                    }}
                />
            </Modal>
        </ContentContainer>
    )
}

const Row = styled.div`
    display: flex;
    justify-content: space-between;
`

export default PartnerEmployeesContainer
