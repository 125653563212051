import dayjs, { Dayjs } from 'packages/dayjs'

export const formattedDateOrNull = (date: Dayjs | null) => {
    if (!dayjs(date).isValid()) {
        return null
    }
    return dayjs(date).toString()
}

export const removeUrlPaginationParams = (url: string) => {
    const urlSearchParams = new URLSearchParams(url)
    urlSearchParams.delete('page')
    urlSearchParams.delete('pageSize')
    return urlSearchParams.toString()
}
