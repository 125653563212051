import { Button, List, Modal, Space } from 'antd'
import type {
    CommentDto,
    DocumentFullDto,
    StateChangeFullDto,
    UnderwritingApplicationFullDto,
} from 'bff/moons/generated/kale'
import styled from 'styled-components'
import OverdraftApplicationStateTag from './overdraft-application-state-tag'
import DocumentsList from './documents-list'
import { EyeOutlined } from '@ant-design/icons'
import { useState } from 'react'
import dayjs from 'dayjs'
import { isInTwoMinutesWindow } from '../../helpers'
import { creditBff } from '../../bff'
import { useNavigate } from 'react-router-dom'
import { getEmployeeName } from 'utils/employees'
import type { EmployeeResponseModel } from 'bff/moons/generated/oberon-v1'
import type { Employee } from 'types/employee'

interface Props {
    application: UnderwritingApplicationFullDto
    companyId: string
}

export default function OverdraftApplicationStateTrail({ application, companyId }: Props) {
    const [infoComment, setInfoComment] = useState<CommentDto | null>(null)

    const submittedDocumentsByDate = application.stateTrail.reduce<
        Record<string, DocumentFullDto[]>
    >((acc, stateChange) => {
        if (stateChange.state === 'PENDING') {
            acc[stateChange.createdAt] = []
        }
        return acc
    }, {})

    application.documents.forEach((document) => {
        const createdAt = document.createdAt
        const submissionDates = Object.keys(submittedDocumentsByDate)
        submissionDates.forEach((submissionDate) => {
            // The document creation is very close to the state change, so we check a 2 minutes window. We can't be sure if they are before of after each other.
            if (isInTwoMinutesWindow(createdAt, submissionDate)) {
                submittedDocumentsByDate[submissionDate].push(document)
            }
        })
    })

    const handleCommentClick = (stateId: string) => {
        const infoRequestStates = application.stateTrail.filter(
            ({ state }) => state === 'INFO_REQUIRED'
        )
        const currentIndex = infoRequestStates.findIndex(({ id }) => id === stateId)

        const currentComment = application.comments[currentIndex]

        setInfoComment(currentComment)
    }

    return (
        <div data-testid="state-trail">
            <List
                itemLayout="horizontal"
                dataSource={[...application.stateTrail].reverse()}
                renderItem={(stateChange) => (
                    <List.Item>
                        <StateItem
                            stateChange={stateChange}
                            stateDocuments={submittedDocumentsByDate[stateChange.createdAt]}
                            companyId={companyId}
                            onCommentClick={handleCommentClick}
                        />
                    </List.Item>
                )}
            />
            <InformationRequestDetailsModal
                comment={infoComment}
                onCancel={() => setInfoComment(null)}
            />
        </div>
    )
}

interface StateItemProps {
    stateChange: StateChangeFullDto
    stateDocuments: DocumentFullDto[] | undefined
    companyId: string
    onCommentClick: (id: string) => void
}

function StateItem({ stateChange, stateDocuments, companyId, onCommentClick }: StateItemProps) {
    const { data } = creditBff.getUnderwritingStateItem.useQuery(
        { companyId, userId: stateChange.userId },
        {
            enabled: stateChange.isCustomer,
        }
    )

    const bankStatements = stateDocuments?.filter(({ type }) => type === 'BANK_STATEMENT')
    const financialStatements = stateDocuments?.filter(({ type }) => type === 'FINANCIAL_STATEMENT')

    return (
        <StyledSpace direction="vertical" size="small" data-testid="application-state-item">
            <StyledTitleContainer>
                <OverdraftApplicationStateTag state={stateChange.state} />
                <div>
                    <span>{dayjs(stateChange.createdAt).format('DD-MM-YYYY HH:mm')}</span>

                    {stateChange.isCustomer &&
                        data !== undefined &&
                        data.employee !== undefined && (
                            <>
                                {' by '}
                                <EmployeeLink employee={data.employee} />
                            </>
                        )}
                </div>
                {stateChange.state === 'INFO_REQUIRED' && (
                    <Button
                        size="small"
                        type="link"
                        icon={<EyeOutlined />}
                        onClick={() => onCommentClick(stateChange.id)}
                    >
                        See comment
                    </Button>
                )}
            </StyledTitleContainer>
            {bankStatements && bankStatements.length > 0 && (
                <DocumentsList
                    companyId={companyId}
                    title="Bank statements"
                    documents={bankStatements}
                />
            )}
            {financialStatements && financialStatements.length > 0 && (
                <DocumentsList
                    companyId={companyId}
                    title="Financial statements"
                    documents={financialStatements}
                />
            )}
        </StyledSpace>
    )
}

interface InformationRequestDetailsModalProps {
    comment: CommentDto | null
    onCancel: () => void
}

function InformationRequestDetailsModal({
    comment,
    onCancel,
}: InformationRequestDetailsModalProps) {
    return (
        <Modal open={Boolean(comment)} onCancel={onCancel} footer={null} centered title="Comment">
            <StyledContent>{comment?.content}</StyledContent>
        </Modal>
    )
}

function EmployeeLink({ employee }: { employee: EmployeeResponseModel }) {
    const navigate = useNavigate()

    return (
        <Button
            onClick={() => navigate(`/customer-success/users/${employee.userId}`)}
            type="link"
            style={{ padding: 0 }}
        >
            {getEmployeeName(employee as Employee)} ({employee.role})
        </Button>
    )
}

const StyledSpace = styled(Space)`
    width: 50%;
    min-width: 350px;
`

const StyledTitleContainer = styled.div`
    font-size: 16px;
    display: flex;
    align-items: center;
`

const StyledContent = styled.p`
    white-space: pre-wrap;
`
