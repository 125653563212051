import { Table, Card, TablePaginationConfig } from 'antd'
import { convertToAntdTableData } from './utils'
import { useContextDetailContext } from '../../context'
import type { FilterValue, SorterResult } from 'antd/lib/table/interface'
import type { SortingSchemaType } from '../../types'
import {
    type SortingMetadataColumnId,
    SortingMetadataDirection,
} from 'bff/moons/generated/investigation-runner'

export default function ContextDataTable() {
    const {
        investigationOutput: data,
        investigationOutputLoading,
        runInvestigation,
        pagination,
        setPagination,
    } = useContextDetailContext()

    const { columns, dataSource } = convertToAntdTableData(data)

    const handleTableChange = (
        newPagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<Record<PropertyKey, any>> | SorterResult<Record<PropertyKey, any>>[]
    ) => {
        const sorters = Array.isArray(sorter) ? sorter : [sorter]

        setPagination({
            current: newPagination?.current || pagination.current,
            pageSize: newPagination?.pageSize || pagination.pageSize,
            total: newPagination?.total || pagination.total,
        })

        const sortedColumns: SortingSchemaType[] = sorters
            .filter((obj) => obj.columnKey !== null && obj.columnKey !== undefined)
            .map((entry) => ({
                columnId: entry.columnKey as SortingMetadataColumnId,
                direction:
                    entry.order === 'ascend'
                        ? SortingMetadataDirection.ASC
                        : SortingMetadataDirection.DESC,
            })) as SortingSchemaType[]

        runInvestigation(
            {
                current: newPagination?.current || pagination.current,
                pageSize: newPagination?.pageSize || pagination.pageSize,
            },
            sortedColumns
        )
    }

    return (
        <Card
            title="Investigation Results"
            style={{
                marginTop: 24,
            }}
        >
            <Table
                columns={columns}
                dataSource={dataSource}
                pagination={{
                    ...pagination,
                    showSizeChanger: true,
                    total: pagination.total,
                }}
                loading={investigationOutputLoading}
                onChange={handleTableChange}
                scroll={{ x: true }}
            />
        </Card>
    )
}
