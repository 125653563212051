import { Col, Checkbox } from 'antd'
import type { InvestigationDesiredOutputColumn } from 'bff/moons/generated/investigation-runner'

interface OutputCheckboxProps {
    output: InvestigationDesiredOutputColumn
    checked: boolean
    onChange: (key: string, checked: boolean) => void
}

export default function OutputCheckbox({ output, checked, onChange }: OutputCheckboxProps) {
    return (
        <Col
            key={output.id}
            xs={{ flex: '100%' }}
            sm={{ flex: '50%' }}
            md={{ flex: '40%' }}
            lg={{ flex: '30%' }}
            xl={{ flex: '20%' }}
        >
            <Checkbox
                checked={checked}
                name={output.id}
                onChange={(e) => onChange(output.id, e.target.checked)}
            >
                {output.label}
            </Checkbox>
        </Col>
    )
}
