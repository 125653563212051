import type { ReactElement } from 'react'
import type { BulkUpdatePlanParams, PlanResponse } from 'bff/moons/generated/janus-v1'
import useGetDifferencesForPlans from '../hooks/use-get-differences-for-plans'
import { Collapse, Row, Spin } from 'antd'
import { ViewType } from '../../enums'
import { Off, On } from '../cells/feature-cell.styles'
import { Changes } from './plan-changes.styles'
import { addLabelToAddon, addLabelToFeature } from './helpers'

const { Panel } = Collapse

type SummaryProps = {
    basePlans: PlanResponse[]
    updatedPlans: BulkUpdatePlanParams[]
    currentView: ViewType
}

const PlanChanges = ({ basePlans, updatedPlans, currentView }: SummaryProps): ReactElement => {
    const { planDifferences, isLoading } = useGetDifferencesForPlans(updatedPlans, basePlans)
    const header =
        !isLoading && !planDifferences.length
            ? 'No changes'
            : currentView === ViewType.Market
              ? `Changes per plan for ${basePlans[0].country}`
              : `Changes per market for ${basePlans[0].type}`

    if (isLoading)
        return (
            <Row justify="center">
                <Spin />
            </Row>
        )

    return (
        <>
            <span style={{ fontWeight: 'bolder' }}>{header}</span>
            <Collapse ghost>
                {planDifferences?.map(
                    ({
                        name,
                        country,
                        featuresAdded,
                        featuresRemoved,
                        addonsAdded,
                        addonsRemoved,
                        limits,
                        priceGeneration,
                    }) => {
                        const key =
                            currentView === ViewType.Market ? name : `${country}_${priceGeneration}`
                        const addedEntitlements = [
                            ...featuresAdded.map(addLabelToFeature),
                            ...addonsAdded.map(addLabelToAddon),
                        ]
                        const removedEntitlements = [
                            ...featuresRemoved.map(addLabelToFeature),
                            ...addonsRemoved.map(addLabelToAddon),
                        ]
                        return (
                            <Panel key={key} header={key}>
                                {addedEntitlements.length > 0 && (
                                    <Changes>
                                        <On /> {addedEntitlements.join(', ')} <br />
                                    </Changes>
                                )}
                                {removedEntitlements.length > 0 && (
                                    <Changes>
                                        <Off /> {removedEntitlements.join(', ')}
                                    </Changes>
                                )}
                                {limits.length > 0 && <Changes>{limits}</Changes>}
                            </Panel>
                        )
                    }
                )}
            </Collapse>
        </>
    )
}

export default PlanChanges
