import type { FC } from 'react'
import { Button, Dropdown, MenuProps, message } from 'antd'
import { PayCircleOutlined } from '@ant-design/icons'
import { demoAccountsBff } from '../../bff/demo-accounts-bff'
import type { Company } from 'bff/moons/generated/dionysus'

interface Props {
    selectedCompany: Company
    revalidateDemoAccount?: () => void
}

enum ExpenseType {
    CARD = 'Card',
    MILEAGE = 'Mileage',
    POCKET = 'Pocket',
    PER_DIEM = 'Per diem',
}

const countryRestrictions = {
    [ExpenseType.PER_DIEM]: ['SE', 'DE'],
    [ExpenseType.CARD]: null,
    [ExpenseType.MILEAGE]: null,
    [ExpenseType.POCKET]: null,
}

export const DemoAccountCreateExpenseDropdown: FC<Props> = ({ selectedCompany }) => {
    const { mutateAsync: createExpense, isLoading } =
        demoAccountsBff.singleAccount.createExpense.useMutation()

    const items: MenuProps['items'] = Object.values(ExpenseType)
        .map((type) => {
            const restrictedCountries = countryRestrictions[type]

            if (restrictedCountries && !restrictedCountries.includes(selectedCompany.country)) {
                return null
            }

            return {
                key: type,
                label: type,
                onClick: async () => {
                    const count = Number(prompt('How many expenses do you want to create?'))

                    if (Number.isNaN(count) || count <= 0) {
                        return
                    }

                    try {
                        const result = await createExpense({
                            type,
                            count,
                            companyId: selectedCompany.id,
                        })

                        if (result.success) {
                            message.success(`Created ${count} ${type} expenses`)
                        } else {
                            message.error(`Failed to create ${type} expenses: ${result.error}`)
                        }
                    } catch (e) {
                        message.error(`Failed to create ${type} expenses: ${e}`)
                    }
                },
            }
        })
        .filter(Boolean)

    return (
        <Dropdown menu={{ items }} trigger={['hover']} disabled={isLoading}>
            <Button disabled={isLoading} loading={isLoading} icon={<PayCircleOutlined />}>
                Create expenses
            </Button>
        </Dropdown>
    )
}
