import { Form } from 'antd'

import { ContextInputs } from '../context-inputs'
import { ContextOutputs } from '../context-outputs'
import { ContextSubmit } from '../context-submit/context-submit'
import { useContextDetailContext } from '../../context'

export const ContextForm = () => {
    const { selectedOutputs, runInvestigation, setShowTooManyColumnsWarning } =
        useContextDetailContext()

    const handleRunInvestigation = () => {
        const hasTooManyColumns = selectedOutputs.length > 10

        if (hasTooManyColumns) {
            setShowTooManyColumnsWarning(true)
        } else {
            runInvestigation()
        }
    }

    const [form] = Form.useForm()

    return (
        <Form
            layout="vertical"
            data-testid="context-inputs-form"
            onFinish={handleRunInvestigation}
            colon={false}
            form={form}
        >
            <ContextInputs form={form} />
            <ContextOutputs />
            <ContextSubmit />
        </Form>
    )
}
