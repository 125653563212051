import * as Yup from 'yup'
import { Button, Flex, message, Modal, Row, Table, Typography } from 'antd'
import Spinner from 'components/content-spinner'
import { ActionsContainer, ContentContainer } from 'components/layout-containers'
import { useParams } from 'react-router-dom'
import { bff } from '../bff'
import { useState } from 'react'
import { Formik } from 'formik'
import { Form, Input } from 'formik-antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { MembersCell } from './members'
import { ClientsCell } from './clients'
const { Text } = Typography
const { confirm } = Modal

export const PartnerTeams = () => {
    const { id: partnerId } = useParams()
    const [createTeamModalVisible, setCreateTeamModalVisible] = useState(false)

    const { data = [], isLoading } = bff.partnerTeams.getTeams.useQuery({
        partnerId: partnerId,
    })

    return (
        <ContentContainer>
            <Row justify="end">
                <Button onClick={() => setCreateTeamModalVisible(true)}>Create team</Button>
                <CreateTeamModal
                    open={createTeamModalVisible}
                    partnerId={partnerId}
                    onDismiss={() => setCreateTeamModalVisible(false)}
                />
            </Row>
            {isLoading ? <Spinner /> : <TeamsTable partnerId={partnerId} teams={data} />}
        </ContentContainer>
    )
}

const TeamsTable = ({
    partnerId,
    teams,
}: {
    partnerId: string
    teams: {
        id: string
        name: string
        description: string
        companyIds: string[]
        userIds: string[]
    }[]
}) => {
    const { mutateAsync: deleteTeam } = bff.partnerTeams.deleteTeam.useMutation({
        onSuccess() {
            message.success('Team deleted')
        },
        onError(error) {
            message.error(`Failed to delete team: ${error.message}`)
        },
    })

    return (
        <Table
            loading={false}
            rowKey="id"
            dataSource={teams}
            columns={[
                {
                    title: 'Team ID',
                    dataIndex: 'id',
                    render: (id: string) => <Text copyable>{id}</Text>,
                    width: '20%',
                },
                {
                    title: 'Name',
                    dataIndex: 'name',
                    render: (name: string[]) => <Text>{name}</Text>,
                },
                {
                    title: 'Description',
                    dataIndex: 'description',
                    render: (description: string) => <Text>{description}</Text>,
                },
                {
                    title: 'Members',
                    dataIndex: 'userIds',
                    render: (_, team) => <MembersCell partnerId={partnerId} team={team} />,
                },
                {
                    title: 'Clients',
                    dataIndex: 'companyIds',
                    render: (_, team) => <ClientsCell partnerId={partnerId} team={team} />,
                },
                {
                    title: 'Action',
                    dataIndex: 'id',
                    render: (teamId: string) => (
                        <ActionsContainer>
                            <Button
                                type="link"
                                danger
                                onClick={() => {
                                    confirm({
                                        icon: <ExclamationCircleOutlined />,
                                        title: 'Are you sure you want to delete this team?',
                                        content:
                                            "All members that only have bookkeeper access via this team will lose access to the team's clients. This action cannot be undone.",
                                        okText: 'Delete team',
                                        okType: 'danger',
                                        async onOk() {
                                            await deleteTeam({ teamId })
                                        },
                                        width: 500,
                                    })
                                }}
                            >
                                Delete
                            </Button>
                        </ActionsContainer>
                    ),
                },
            ]}
        />
    )
}

const CreateTeamModal = ({
    open,
    partnerId,
    onDismiss,
}: {
    open: boolean
    partnerId: string
    onDismiss: () => void
}) => {
    const { mutateAsync: createTeam } = bff.partnerTeams.createTeam.useMutation({
        onSuccess({ data }) {
            onDismiss()
            message.success(`Team "${data.name}" created`)
        },
        onError(error) {
            message.error(`Failed to create team: ${error.message}`)
        },
    })

    return (
        <Formik
            validationSchema={Yup.object().shape({
                name: Yup.string().required(),
                description: Yup.string().optional(),
            })}
            initialValues={{ name: '', description: '' }}
            onSubmit={async (values) => {
                await createTeam({ partnerId, ...values })
            }}
        >
            {({ isValid, isSubmitting, submitForm }) => {
                return (
                    <Modal
                        title="Create Team"
                        open={open}
                        onCancel={onDismiss}
                        footer={[
                            <Button
                                key="submit"
                                type="primary"
                                disabled={!isValid}
                                loading={isSubmitting}
                                onClick={submitForm}
                            >
                                Create
                            </Button>,
                        ]}
                    >
                        <Form>
                            <Flex vertical gap={16}>
                                <Input aria-label="Name" name="name" placeholder="Name" />
                                <Input
                                    aria-label="Description"
                                    name="description"
                                    placeholder="Description"
                                />
                            </Flex>
                        </Form>
                    </Modal>
                )
            }}
        </Formik>
    )
}
