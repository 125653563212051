import { useEffect } from 'react'
import { faro } from '@grafana/faro-react'

import { useUser } from './user-context'

export function useEnhancedGrafanaFaro() {
    const userId = useUser().id

    useEffect(() => {
        if (userId) {
            faro.api.setUser({ id: userId })
        } else {
            faro.api.resetUser()
        }
    }, [userId])
}
