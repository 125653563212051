import { type FC, useState } from 'react'

import {
    Select,
    Table,
    Typography,
    Button,
    Popover,
    Result,
    notification,
    Space,
    Tooltip,
} from 'antd'
import type { ColumnProps } from 'antd/lib/table'

import {
    CardOrderStatus,
    CardOrderTableData,
    ShippingAddress,
    displayDeliveryStatus,
    Manufacturer,
    DeliveryType,
    OrderFilters,
    ProviderName,
} from 'store/modules/support/card-orders/types'

import { ActionsContainer } from 'components/layout-containers'
import { useNavigate } from 'react-router-dom'
import InfoElemHorizontal from 'components/info-elem/info-elem-horizontal'
import dayjs from 'packages/dayjs'
import { useOrders } from 'services/deimos/card-orders'
import {
    canUpdateStatus,
    displayDeliveryType,
    displayManufacturer,
    hasFailedProcessing,
    toTableFormat,
    TrackingLinkResolver,
} from 'pages/customer-success/card-orders/utils'
import type { KycProperties } from 'types/deimos-company'
import CardOrderOverview from './card-order-overview'
import { TrackingLink } from 'types/card-orders'
import styled from 'styled-components'
import { InfoCircleOutlined } from '@ant-design/icons'
import { color } from 'theme/tokens'

const { Link: AntLink } = Typography

const { Option } = Select
const { Text } = Typography
function getTrackingCodeLink(cardOrder: CardOrderTableData) {
    const trackingLink = TrackingLinkResolver[cardOrder.companyCountry]
    return `${trackingLink ?? TrackingLink.AFTERSHIP_EN}${cardOrder.trackingId}`
}

const CardOrderDeliveryDetails: FC<React.PropsWithChildren<{ cardOrder: CardOrderTableData }>> = ({
    cardOrder,
}) => {
    const isMegaCardOrIdemia =
        cardOrder.manufacturer === Manufacturer.MEGACARD ||
        cardOrder.manufacturer === Manufacturer.IDEMIA

    const noInformation = !cardOrder?.manufacturer && !cardOrder?.trackingId

    const isLink = (trackingId: string) => {
        const pattern = new RegExp('^(https?://)?[0-9a-z]+.[-_0-9a-z]+.[0-9a-z]')
        return pattern.test(trackingId)
    }

    return (
        <Popover
            content={
                <StatusContainer>
                    {!!cardOrder?.manufacturer && (
                        <>
                            <Text>{displayManufacturer(cardOrder.manufacturer)} status</Text>
                            <Text type="secondary">
                                {displayDeliveryStatus(cardOrder.deliveryStatus)}
                            </Text>
                        </>
                    )}
                    {!!cardOrder?.trackingId && (
                        <>
                            <Text>Tracking code</Text>
                            {isMegaCardOrIdemia ? (
                                <a
                                    href={getTrackingCodeLink(cardOrder)}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Link to track-and-trace
                                </a>
                            ) : isLink(cardOrder.trackingId) ? (
                                <a
                                    href={cardOrder.trackingId}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Tracking link
                                </a>
                            ) : (
                                <Text>{cardOrder.trackingId}</Text>
                            )}
                        </>
                    )}
                    {noInformation && <Text>No status</Text>}
                </StatusContainer>
            }
            placement="top"
            trigger="click"
        >
            <div>
                <Text type="warning">Details</Text>
            </div>
        </Popover>
    )
}

const StatusContainer = styled.div`
    display: grid;
    grid-template-rows: 1fr 1fr;
`

const FormattedDate: FC<React.PropsWithChildren<{ date: string }>> = ({ date }) => (
    <CardOrderCell>
        <Text>{dayjs(date).format('DD-MM-YY')},</Text>
        <Text>{dayjs(date).format('hh:mm')}</Text>
    </CardOrderCell>
)

const CardOrderCell = styled.div`
    display: grid;
    grid-template-rows: auto auto auto;
    vertical-align: top;
`

const CardOrdersTable: FC<
    React.PropsWithChildren<{
        filters: OrderFilters
        setFilters: React.Dispatch<React.SetStateAction<OrderFilters>>
    }>
> = ({ filters, setFilters }) => {
    const navigate = useNavigate()
    const [overviewModalActive, setOverviewModalActive] = useState(false)
    const [overviewCardOrderId, setOverviewCardOrderId] = useState('')
    const {
        data: orders,
        mutations: { updateCardOrderStatus, setLimit, setPage },
        error,
    } = useOrders(filters)

    if (error) {
        return (
            <Result
                status="error"
                title="Something went wrong"
                subTitle="Please contact Team Backoffice Support Experience"
            />
        )
    }

    const onOverview = (id: string) => {
        setOverviewCardOrderId(id)
        setOverviewModalActive(true)
    }

    const handleStatusChange = (cardOrderId: string) => async (status: CardOrderStatus) => {
        try {
            await updateCardOrderStatus(cardOrderId, status)
            notification.info({
                message: `Order Status was updated`,
                description: `Status: ${status}`,
            })
        } catch (e) {
            notification.error({
                message: `Something went wrong while updating the order status`,
                description: (e as Error).message,
            })
        }
    }

    const onCompanyClick = (gotToCompanyId: string) =>
        navigate(`/customer-success/companies/${gotToCompanyId}`)

    const lastUpdatedTitle = (
        <Space size="small">
            <Text>Updated</Text>
            <Tooltip title="An update could be a change of status, an added tracking code, etc.">
                <InfoCircleOutlined style={{ color: color.gray }} />
            </Tooltip>
        </Space>
    )

    const columns: ColumnProps<CardOrderTableData>[] = [
        {
            title: 'Created',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (date: string) => <FormattedDate date={date} />,
            sorter: (a: CardOrderTableData, b: CardOrderTableData) =>
                Number(new Date(a.createdAt)) - Number(new Date(b.createdAt)),
        },
        {
            title: lastUpdatedTitle,
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            render: (date: string) => <FormattedDate date={date} />,
            sorter: (a: CardOrderTableData, b: CardOrderTableData) =>
                Number(new Date(a.updatedAt)) - Number(new Date(b.updatedAt)),
            showSorterTooltip: false,
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            render: (amount) => (
                <CardOrderCell>
                    <Text>{amount}</Text>
                </CardOrderCell>
            ),
            sorter: (a: CardOrderTableData, b: CardOrderTableData) => a.amount - b.amount,
        },
        {
            title: 'Attention',
            dataIndex: 'address',
            key: 'address',
            width: '18%',
            render: ({
                attention,
                addressLine1,
                addressLine2 = '',
                locality,
                region = '',
                postalCode,
                country,
            }: ShippingAddress) => (
                <Space direction="vertical">
                    <Text>
                        <Text strong>Att: </Text>
                        {attention}
                    </Text>
                    <Text>
                        {addressLine1}
                        {addressLine2 ? `, ${addressLine2}` : ''}
                    </Text>
                    <Text>
                        {postalCode}
                        {locality ? `, ${locality}` : ''}
                        {region ? `, ${region}` : ''}
                        {country ? `, ${country}` : ''}
                    </Text>
                </Space>
            ),
        },
        {
            title: 'Company details',
            dataIndex: 'companyName',
            key: 'companyName',
            width: '15%',
            render: (company: string, record: CardOrderTableData) => (
                <CardOrderCell>
                    <AntLink onClick={() => onCompanyClick(record.companyId)}>{company}</AntLink>
                    <InfoElemHorizontal label="Velocity" value={record.kycStatus?.toUpperCase()} />
                    <InfoElemHorizontal
                        label="Provider"
                        value={record.providerName?.toUpperCase()}
                    />
                </CardOrderCell>
            ),
        },
        {
            title: 'Supplier ID',
            dataIndex: 'supplierId',
            key: 'supplierId',
            render: (supplierId: string) => (
                <SupplierIDContainer>
                    <Text copyable>{supplierId}</Text>
                </SupplierIDContainer>
            ),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            filters: Object.values(CardOrderStatus).map((status: string) => ({
                value: status,
                text: status,
            })),
            defaultFilteredValue: filters.status,
            onFilter: (value, record) => record.status.includes(value.toString()),
            render: (_: unknown, cardOrder: CardOrderTableData) => {
                let Status = () => <Text>{cardOrder.status}</Text>
                if (canUpdateStatus(cardOrder)) {
                    // eslint-disable-next-line react/display-name
                    Status = () => (
                        <Select
                            key={cardOrder.status}
                            defaultValue={cardOrder.status}
                            size="small"
                            onChange={handleStatusChange(cardOrder.id)}
                        >
                            <Option value={CardOrderStatus.SENT}>{CardOrderStatus.SENT}</Option>
                            <Option value={CardOrderStatus.RECEIVED}>
                                {CardOrderStatus.RECEIVED}
                            </Option>
                            <Option value={CardOrderStatus.UNDELIVERED}>
                                {CardOrderStatus.UNDELIVERED}
                            </Option>
                        </Select>
                    )
                }

                if (hasFailedProcessing(cardOrder)) {
                    // eslint-disable-next-line react/display-name
                    Status = () => (
                        <Select
                            key={cardOrder.status}
                            defaultValue={CardOrderStatus.PROCESSING}
                            size="small"
                            onChange={handleStatusChange(cardOrder.id)}
                        >
                            <Option value={CardOrderStatus.PROCESSING}>
                                <Text type="danger">FAILED</Text>
                            </Option>
                            <Option value={CardOrderStatus.UNDELIVERED}>
                                {CardOrderStatus.UNDELIVERED}
                            </Option>
                        </Select>
                    )
                }
                return (
                    <CardOrderCell>
                        <Status />
                        <CardOrderDeliveryDetails cardOrder={cardOrder} />
                    </CardOrderCell>
                )
            },
        },
        {
            title: 'Manufacturer',
            dataIndex: 'manufacturer',
            key: 'manufacturer',
            filters: [Manufacturer.MEGACARD, Manufacturer.IDEMIA, Manufacturer.NITECREST, '-'].map(
                (status: string) => ({
                    value: status,
                    text: status,
                })
            ),
            onFilter: (value, record) => {
                if (value === '-') {
                    return !record.manufacturer
                }
                return record.manufacturer?.includes(value.toString())
            },
            render: (manufacturer?: Manufacturer) => (
                <CardOrderCell>
                    <Text>{displayManufacturer(manufacturer)}</Text>
                </CardOrderCell>
            ),
        },
        {
            title: 'Delivery',
            dataIndex: 'deliveryType',
            key: 'deliveryType',
            filters: [DeliveryType.STANDARD, DeliveryType.EXPRESS, '-'].map((status: string) => ({
                value: status,
                text: status,
            })),
            onFilter: (value, record) => {
                if (value === '-') {
                    return !record.deliveryType
                }
                return Boolean(record.deliveryType?.includes(value.toString()))
            },
            render: (deliveryType?: DeliveryType) => (
                <CardOrderCell>
                    <Text>{displayDeliveryType(deliveryType)}</Text>
                </CardOrderCell>
            ),
        },
        {
            title: 'Action',
            fixed: 'right',
            dataIndex: 'action',
            render: (_: unknown, { id }: CardOrderTableData) => (
                <ActionsContainer>
                    <Button type="link" onClick={() => onOverview(id)}>
                        Overview
                    </Button>
                </ActionsContainer>
            ),
        },
    ]
    return (
        <>
            <CardOrderOverview
                modalActive={overviewModalActive}
                cardOrderId={overviewCardOrderId}
                closeModal={() => setOverviewModalActive(false)}
            />
            <Table
                dataSource={toTableFormat(orders?.cardOrders)}
                columns={columns}
                rowKey="id"
                scroll={{ x: 'max-content' }}
                loading={!orders}
                pagination={{
                    onChange: (page, pageSize) => {
                        if (pageSize) {
                            setLimit(pageSize)
                            setPage(page)
                        }
                    },
                    total: orders?.total,
                }}
                onChange={(_, { status, kycStatus, hasWalletLoad, providerName }) => {
                    setFilters({
                        ...filters,
                        status: status as CardOrderStatus[],
                        kycStatus: kycStatus as KycProperties[],
                        hasWalletLoad: hasWalletLoad as string[],
                        providerName: providerName as ProviderName[],
                    })
                }}
            />
        </>
    )
}

const SupplierIDContainer = styled.div`
    max-width: 150px;
`

export default CardOrdersTable
