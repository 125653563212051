import {
    WaitingForCustomerReason,
    WaitingForCustomerSubReason,
} from 'bff/moons/generated/cdd-case-manager'

export interface SelectOption<T> {
    value: T
    label: string
}

export const WAITING_FOR_CUSTOMER_REASON_OPTIONS: SelectOption<WaitingForCustomerReason>[] = [
    {
        label: 'Company Information and Structure',
        value: WaitingForCustomerReason.COMPANY_INFORMATION_AND_STRUCTURE,
    },
    {
        label: 'Proof of Business Activity and Licenses',
        value: WaitingForCustomerReason.PROOF_OF_BUSINESS_ACTIVITY_AND_LICENSES,
    },
    {
        label: 'Identity and Personal Information',
        value: WaitingForCustomerReason.IDENTITY_AND_PERSONAL_INFORMATION,
    },
    {
        label: 'Miscellaneous and Other Requests',
        value: WaitingForCustomerReason.MISCELLANEOUS_AND_OTHER_REQUESTS,
    },
]

export const WAITING_FOR_CUSTOMER_SUB_REASON_OPTIONS: Record<
    WaitingForCustomerReason,
    SelectOption<WaitingForCustomerSubReason>[]
> = {
    [WaitingForCustomerReason.COMPANY_INFORMATION_AND_STRUCTURE]: [
        {
            label: 'Company Registration Certificate',
            value: WaitingForCustomerSubReason.COMPANY_REGISTRATION_CERTIFICATE,
        },
        {
            label: 'Business Registry Extract',
            value: WaitingForCustomerSubReason.BUSINESS_REGISTRY_EXTRACT,
        },
        {
            label: 'Articles of Association',
            value: WaitingForCustomerSubReason.ARTICLES_OF_ASSOCIATION,
        },
        { label: 'Deed of Foundation', value: WaitingForCustomerSubReason.DEED_OF_FOUNDATION },
        {
            label: 'Incorporation Documents',
            value: WaitingForCustomerSubReason.INCORPORATION_DOCUMENTS,
        },
        {
            label: 'Ownership Information',
            value: WaitingForCustomerSubReason.OWNERSHIP_INFORMATION,
        },
        { label: 'Shareholder Register', value: WaitingForCustomerSubReason.SHAREHOLDER_REGISTER },
        { label: 'List of Trustees', value: WaitingForCustomerSubReason.LIST_OF_TRUSTEES },
        { label: 'List of Directors', value: WaitingForCustomerSubReason.LIST_OF_DIRECTORS },
        { label: 'Board Members', value: WaitingForCustomerSubReason.BOARD_MEMBERS },
        { label: 'Executive Team', value: WaitingForCustomerSubReason.EXECUTIVE_TEAM },
        {
            label: 'Board Meeting Minutes',
            value: WaitingForCustomerSubReason.BOARD_MEETING_MINUTES,
        },
        {
            label: 'Partnership Agreement',
            value: WaitingForCustomerSubReason.PARTNERSHIP_AGREEMENT,
        },
        { label: 'Other', value: WaitingForCustomerSubReason.OTHER },
    ],
    [WaitingForCustomerReason.PROOF_OF_BUSINESS_ACTIVITY_AND_LICENSES]: [
        { label: 'Proof of Business', value: WaitingForCustomerSubReason.PROOF_OF_BUSINESS },
        { label: 'Business Explanation', value: WaitingForCustomerSubReason.BUSINESS_EXPLANATION },
        {
            label: 'Proof of Trading Address',
            value: WaitingForCustomerSubReason.PROOF_OF_TRADING_ADDRESS,
        },
        { label: 'CBD License', value: WaitingForCustomerSubReason.CBD_LICENSE },
        {
            label: 'Certificate to Operate Precious Stones',
            value: WaitingForCustomerSubReason.CERTIFICATE_TO_OPERATE_PRECIOUS_STONES,
        },
        {
            label: 'General Certificate to Operate',
            value: WaitingForCustomerSubReason.GENERAL_CERTIFICATE_TO_OPERATE,
        },
        {
            label: 'License to Operate Gambling & Betting',
            value: WaitingForCustomerSubReason.LICENSE_TO_OPERATE_GAMBLING_AND_BETTING,
        },
        {
            label: 'License to Operate Mining & Private Security',
            value: WaitingForCustomerSubReason.LICENSE_TO_OPERATE_MINING_AND_PRIVATE_SECURITY,
        },
        {
            label: 'License to Operate Quarrying & Extraction',
            value: WaitingForCustomerSubReason.LICENSE_TO_OPERATE_QUARRYING_AND_EXTRACTION,
        },
        { label: 'AML Policy', value: WaitingForCustomerSubReason.AML_POLICY },
        { label: 'Source of Funds', value: WaitingForCustomerSubReason.SOURCE_OF_FUNDS },
        { label: 'Financial Statements', value: WaitingForCustomerSubReason.FINANCIAL_STATEMENTS },
        { label: 'Trust Deed', value: WaitingForCustomerSubReason.TRUST_DEED },
        { label: 'Bank Statement', value: WaitingForCustomerSubReason.BANK_STATEMENT },
        { label: 'Other', value: WaitingForCustomerSubReason.OTHER },
    ],
    [WaitingForCustomerReason.IDENTITY_AND_PERSONAL_INFORMATION]: [
        { label: 'Proof of ID', value: WaitingForCustomerSubReason.PROOF_OF_ID },
        { label: 'National ID', value: WaitingForCustomerSubReason.NATIONAL_ID },
        { label: 'Nationality', value: WaitingForCustomerSubReason.NATIONALITY },
        {
            label: 'Verified Administrator',
            value: WaitingForCustomerSubReason.VERIFIED_ADMINISTRATOR,
        },
        { label: 'Proof of Address', value: WaitingForCustomerSubReason.PROOF_OF_ADDRESS },
        {
            label: 'Residential Address of Person',
            value: WaitingForCustomerSubReason.RESIDENTIAL_ADDRESS_OF_PERSON,
        },
        { label: 'Country of Residence', value: WaitingForCustomerSubReason.COUNTRY_OF_RESIDENCE },
        { label: 'Date of Birth', value: WaitingForCustomerSubReason.DATE_OF_BIRTH },
        { label: 'Citizenship', value: WaitingForCustomerSubReason.CITIZENSHIP },
        {
            label: 'Marriage or Name Change Certification',
            value: WaitingForCustomerSubReason.MARRIAGE_OR_NAME_CHANGE_CERTIFICATION,
        },
        {
            label: 'Marriage or Divorce Certificate',
            value: WaitingForCustomerSubReason.MARRIAGE_OR_DIVORCE_CERTIFICATE,
        },
        { label: 'Other', value: WaitingForCustomerSubReason.OTHER },
    ],
    [WaitingForCustomerReason.MISCELLANEOUS_AND_OTHER_REQUESTS]: [
        { label: 'Model 200', value: WaitingForCustomerSubReason.MODEL_200 },
        { label: 'KBIS Document', value: WaitingForCustomerSubReason.KBIS_DOCUMENT },
        { label: 'Other', value: WaitingForCustomerSubReason.OTHER },
    ],
}
