import { Select } from 'antd'
import { SearchCDDCasesRequestCompanyCountriesItem } from 'bff/moons/generated/cdd-case-manager'
import { countries } from 'countries-list'
import { getUrlMethods, useFilterState } from 'packages/filters/use-filter-state'

const defaultValue = null
const paramName = 'countries'
const { getUrlValue, setUrlValue } = getUrlMethods(paramName, defaultValue)

export function useCountryFilterState() {
    return useFilterState(getUrlValue, setUrlValue, defaultValue)
}
type CountryFilterProps = {
    filter: ReturnType<typeof useCountryFilterState>
    onChange: () => void
}

type CountryOption = {
    label: string
    value: string
}

const allSearchableCountryCodes = Object.values(SearchCDDCasesRequestCompanyCountriesItem)

const UK_AND_IRELAND = ['GB', 'IE']
const CENTRAL_AND_SOUTHERN_EUROPE = ['DE', 'ES', 'AT', 'FR', 'IT', 'PT', 'BE', 'NL', 'LU']

const NORDIC_AND_BALTIC_BASE = ['DK', 'SE', 'FI', 'NO', 'EE']
const excludedCountries = [...UK_AND_IRELAND, ...CENTRAL_AND_SOUTHERN_EUROPE]
const remainingCountries = allSearchableCountryCodes.filter(
    (country) => !excludedCountries.includes(country) && !NORDIC_AND_BALTIC_BASE.includes(country)
)
const NORDIC_AND_BALTIC_AND_ROW = [...NORDIC_AND_BALTIC_BASE, ...remainingCountries]

const REGION_OPTIONS: CountryOption[] = [
    {
        label: 'UK & Ireland',
        value: UK_AND_IRELAND.join(', '),
    },
    {
        label: 'Nordics & Baltics (inc. RoW)',
        value: NORDIC_AND_BALTIC_AND_ROW.join(', '),
    },
    {
        label: 'Central & Southern Europe',
        value: CENTRAL_AND_SOUTHERN_EUROPE.join(', '),
    },
]

const COUNTRY_OPTIONS: CountryOption[] = Object.entries(countries).map(([code, country]) => ({
    label: `${country.emoji} ${country.name}`,
    value: code,
}))

export function CountryFilter({ filter, onChange }: CountryFilterProps) {
    const handleChange = (value: CountryOption['value']) => {
        filter.directApply(value)
        onChange()
    }
    return (
        <Select
            style={{ width: '100%' }}
            options={[
                {
                    label: 'Regions',
                    options: REGION_OPTIONS,
                },
                {
                    label: 'Countries',
                    options: COUNTRY_OPTIONS,
                },
            ]}
            placeholder="Country"
            value={filter.interimValue}
            onChange={handleChange}
            allowClear
            showSearch
            optionFilterProp="label"
        />
    )
}
