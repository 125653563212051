import { Button, Card } from 'antd'
import OutputList from './output-list'

import { useContextDetailContext } from '../../context'

export default function ContextOutputs() {
    const { outputs, resetOutputsToDefault, selectedOutputs, setSelectedOutputs } =
        useContextDetailContext()

    const saveSelectedOutputs = (selectedOutputsParam: string[]) => {
        setSelectedOutputs(selectedOutputsParam)
    }

    return (
        <Card
            title="Columns to display"
            extra={<Button onClick={resetOutputsToDefault}>Reset to default</Button>}
            style={{
                marginTop: 24,
            }}
            loading={!outputs}
            data-testid="context-outputs-card"
        >
            <OutputList
                outputs={outputs}
                selectedOutputs={selectedOutputs}
                saveSelectedOutputs={saveSelectedOutputs}
                data-testid="output-list"
            />
        </Card>
    )
}
