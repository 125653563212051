export interface SwitchPermission {
    description: string
    permissions: string[]
}

export type SwitchPermissionGroup = SwitchPermission[]

export const genericPermissions: SwitchPermissionGroup = [
    {
        description: 'Access Back Office',
        permissions: ['admin'],
    },
    {
        description: 'Administrate admins and permissions',
        permissions: ['super'],
    },
]

export const customerSuccessPermissions: SwitchPermissionGroup = [
    {
        description: 'Reactivate employees',
        permissions: ['company-role'],
    },
    {
        description: 'Manage wallets/cards',
        permissions: ['phobos', 'card-management'],
    },
    {
        description: 'Copy full card PAN',
        permissions: ['card:pan'],
    },
    {
        description: 'Manage invoices',
        permissions: ['hydra', 'beyond'],
    },
    {
        description: 'Manage ToDo List',
        permissions: ['taskmanager'],
    },
    {
        description: 'Manage Export Page Lanes Expenses Selections',
        permissions: ['exportlanetracker'],
    },
    {
        description: 'View wallet loads',
        permissions: ['cupid'],
    },
    {
        description: 'Offboarding: Initiate',
        permissions: ['initiate-offboarding'],
    },
    {
        description: 'Offbording: Complete',
        permissions: ['offboarding-tools'],
    },
    {
        description: 'Export expenses',
        permissions: ['admin-expenses'],
    },
    {
        description: 'Express card orders',
        permissions: ['deimos:express-card-orders'],
    },
    {
        description: 'Manage partners and partner employees',
        permissions: ['partner-manager'],
    },
    {
        description: 'HubSpot: Allowlist users',
        permissions: ['auth:whitelist'],
    },
    {
        description: 'HubSpot: Blocklist users',
        permissions: ['auth:blacklist'],
    },
    {
        description: 'Employee 360',
        permissions: ['employee-360'],
    },
    {
        description: 'Login events',
        permissions: ['login-events'],
    },
    {
        description: 'Remove hubspot connection',
        permissions: ['remove-hubspot-connection'],
    },
    {
        description: 'Bulk update employee emails for a given company',
        permissions: ['bulk-email-update'],
    },
    {
        description: 'Manage file exports and reports',
        permissions: ['brokkr'],
    },
    {
        description: 'Allow entitlements override',
        permissions: ['entitlements-override'],
    },
    {
        description: 'View company balance',
        permissions: ['funds-mgmt-support'],
    },
]

export const compliancePermissions: SwitchPermissionGroup = [
    {
        description: 'Manage multi-entity',
        permissions: ['oberon:organization-requests'],
    },
    {
        description: 'Manage chargebacks',
        permissions: ['phobos', 'endor'],
    },
    {
        description: 'Consult KYC missing information and management',
        permissions: ['kyc:read'],
    },
    {
        description: 'Access basic compliance tools',
        permissions: ['company-role', 'metis:invoices', 'styx'],
    },
    {
        description: 'CDD officer tools',
        permissions: [
            'company-role',
            'cupid',
            'cupid:card-payments',
            'metis:invoices',
            'phobos',
            'styx',
            'telesto:card-payments',
        ],
    },
    {
        description: 'Change KYC status',
        permissions: ['phobos'],
    },
    {
        description: 'Manage merchants',
        permissions: ['ariel'],
    },
    {
        description: 'Monitor money laundering',
        permissions: ['ariel:aml'],
    },
    {
        description: 'Monitor fraud',
        permissions: ['ariel:card'],
    },
    {
        description: 'View/copy full card PAN',
        permissions: ['card:pan'],
    },
    {
        description: 'Manage wallet unloads',
        permissions: ['cupid'],
    },
    {
        description: 'Manage card top-ups (markets)',
        permissions: ['cupid:card-payments'],
    },
    {
        description: 'Manage card top-ups (all except markets)',
        permissions: ['telesto:card-payments'],
    },
    {
        description: 'Manage suppliers',
        permissions: ['metis:invoices'],
    },
    {
        description: 'Initiate offboarding',
        permissions: ['initiate-offboarding'],
    },
    {
        description: 'Manage customer risk scores and weights',
        permissions: ['manage-risk-factors'],
    },
    {
        description: 'Get Ariel rules report for AML, Invoices and Pocket',
        permissions: ['get-ariel-aml-rules-report'],
    },
    {
        description: 'Get Ariel rules report for Fraud',
        permissions: ['get-ariel-fraud-rules-report'],
    },
    {
        description: 'Download balance statement',
        permissions: ['download-balance-statement'],
    },
    {
        description: 'Company notes and documents',
        permissions: ['aml'],
    },
    {
        description: '3d party transaction monitoring',
        permissions: ['transaction-monitoring'],
    },
]

export const financePermissions: SwitchPermissionGroup = [
    {
        description: 'Manage Mastercard advisements',
        permissions: ['phobos', 'endor', 'hades:autosettlement'],
    },
    {
        description: 'Export transactions',
        permissions: ['endor'],
    },
    {
        description: 'Credit Note Requests Permissions - Level 1',
        permissions: ['hydra:credit-note-request-level:1'],
    },
    {
        description: 'Credit Note Requests Permissions - Level 3',
        permissions: ['hydra:credit-note-request-level:3'],
    },
    {
        description: 'Credit Note Requests Permissions - Level 5',
        permissions: ['hydra:credit-note-request-level:5'],
    },
    {
        description: 'Allow Updating Nordic API Gateway token',
        permissions: ['telesto:nag'],
    },
]

export const flagPermissions: SwitchPermissionGroup = [
    {
        description: 'Refund a proof of funds transaction',
        permissions: ['proof-of-funds-refund'],
    },
]

export const partnershipsPermissions: SwitchPermissionGroup = [
    {
        description: 'Manage app marketplace',
        permissions: ['app-marketplace', 'endymion'],
    },
]
