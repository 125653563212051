import dayjs from 'packages/dayjs'
import {
    RiskAssessmentV2,
    RiskCheckDataV2,
    RiskCheckScoreV2,
    RiskCheckV2,
    RiskTypesV2,
} from 'types/styx'

const parentChildRelationshipMap: Record<RiskTypesV2, RiskTypesV2[]> = {
    [RiskTypesV2.PARENT_PERSON_PEP_CHECK]: [
        RiskTypesV2.PERSON_PEP_CORPORATE_CHECK,
        RiskTypesV2.PERSON_PEP_POSITION_CHECK,
        RiskTypesV2.PERSON_PEP_GEOGRAPHY_CHECK,
    ],
    [RiskTypesV2.ADVERSE_MEDIA_COMPANY_KYC_CHECKS]: [],
    [RiskTypesV2.COMPANY_LEGAL_FORM]: [],
    [RiskTypesV2.COMPANY_LEGAL_NAME_CHANGE]: [],
    [RiskTypesV2.COMPANY_SANCTIONS]: [],
    [RiskTypesV2.COMPLEX_OWNERSHIP_AND_CONTROL_STRUCTURE]: [],
    [RiskTypesV2.COUNTRY_COMPANY_INCORPORATION]: [],
    [RiskTypesV2.COUNTRY_RELATED_PERSONS_ADDRESS]: [],
    [RiskTypesV2.DATE_COMPANY_INCORPORATION]: [],
    [RiskTypesV2.INDUSTRY_CODE_COMPANY_INDUSTRIES]: [],
    [RiskTypesV2.PERSON_PEP_CHECK]: [],
    [RiskTypesV2.PO_HIGHER_RISK_COUNTRY]: [],
    [RiskTypesV2.UBO_ADDRESS]: [],
    [RiskTypesV2.PERSON_PEP_CORPORATE_CHECK]: [],
    [RiskTypesV2.PERSON_PEP_POSITION_CHECK]: [],
    [RiskTypesV2.PERSON_PEP_GEOGRAPHY_CHECK]: [],
    [RiskTypesV2.PERSON_PEP_GEOGRAPHY_CHECK]: [],
    [RiskTypesV2.CONTINUOUS_RISK_MONITORING]: [],
}

export const CHILDREN_TYPES = Object.values(parentChildRelationshipMap).flat()

export const riskScoreOptions = (riskCheckDataV2: RiskCheckDataV2) => {
    if (
        [RiskTypesV2.PERSON_PEP_CORPORATE_CHECK, RiskTypesV2.PERSON_PEP_POSITION_CHECK].includes(
            riskCheckDataV2.riskType
        )
    ) {
        return [RiskCheckScoreV2.LOW, RiskCheckScoreV2.HIGH]
    } else if ([RiskTypesV2.CONTINUOUS_RISK_MONITORING].includes(riskCheckDataV2.riskType)) {
        return [RiskCheckScoreV2.NOT_APPLICABLE, RiskCheckScoreV2.MEDIUM, RiskCheckScoreV2.HIGH]
    } else {
        return Object.keys(RiskCheckScoreV2).filter(
            (score) =>
                score !== RiskCheckScoreV2.NOT_ASSIGNED && score !== RiskCheckScoreV2.NOT_APPLICABLE
        )
    }
}

const calculateIsBeingUpdated = (check: RiskCheckV2) => {
    if (!check.suggestedScore && !check.score) return true
    if (check.suggestedScore && !check.score) return true
    return false
}

const calculateScore = (check: RiskCheckV2): RiskCheckScoreV2 => {
    if (
        check.riskType === RiskTypesV2.CONTINUOUS_RISK_MONITORING &&
        check.score === RiskCheckScoreV2.LOW
    ) {
        return RiskCheckScoreV2.NOT_APPLICABLE
    }
    return check.score ?? check.suggestedScore ?? RiskCheckScoreV2.NOT_ASSIGNED
}

const calculateSuggestedScore = (check: RiskCheckV2): RiskCheckScoreV2 => {
    return check.suggestedScore ?? RiskCheckScoreV2.NOT_ASSIGNED
}

const translateToRiskCheckData = (
    riskCheck: RiskCheckV2,
    childFactors: RiskCheckV2[]
): RiskCheckDataV2 => ({
    riskType: riskCheck.riskType,
    score: calculateScore(riskCheck),
    suggestedScore: calculateSuggestedScore(riskCheck),
    hasApprovedScore: riskCheck.score !== undefined,
    notes: riskCheck?.notes ?? '',
    ownerName: riskCheck?.ownerName ?? '',
    id: riskCheck.id,
    riskTypeId: riskCheck.riskTypeId,
    isBeingUpdated: calculateIsBeingUpdated(riskCheck),
    checkDate: dayjs(riskCheck.checkDate ?? undefined).format('lll'),
    childFactors: childFactors.map((childType) => translateToRiskCheckData(childType, [])),
})

export const getRiskCheckDataSource = (riskAssessmentV2: RiskAssessmentV2): RiskCheckDataV2[] => {
    const parentChecks = riskAssessmentV2.checks.filter(
        (check) => !CHILDREN_TYPES.includes(check.riskType)
    )

    return parentChecks.map((riskCheck) => {
        const childFactors = parentChildRelationshipMap[riskCheck.riskType].map((childType) =>
            riskAssessmentV2.checks.find((check) => check.riskType === childType)
        )

        return translateToRiskCheckData(riskCheck, childFactors.filter(Boolean) as RiskCheckV2[])
    })
}
