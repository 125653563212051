import React, { FC, useRef, useState } from 'react'
import { Empty, Button, message, Space, Modal, Input, Typography } from 'antd'
import type * as styx from 'types/styx'
import { RiskAssessmentVersion } from 'types/styx'
import RiskCheckFormV2 from 'pages/compliance/company/risk-assessment/risk-check-form-v2/risk-check-form-v2'
import AssessmentResultV2 from 'pages/compliance/shared/risk-assessment/assessment-result/assessment-result-v2'
import AssessmentSnapshot from 'pages/compliance/shared/risk-assessment/assessment-snapshot/assessment-snapshot'
import { useRiskAssessment, useKycCheckpoints } from 'services/deimos/styx-company/styx-company'
import { ContentContainer } from 'components/layout-containers'
import Skeleton from 'components/content-skeleton'
import RiskCheckForm from './risk-check-form/risk-check-form'
import { RedoOutlined } from '@ant-design/icons'
import SnapshotTabs from '../shared/snapshot-tabs/snapshot-tabs'
import { useCompanyRiskAssessment } from 'services/styx/risk-assessment'
import { useCompanyDocumentsWithDateSnapshot } from 'services/styx/company'
import { useParams } from 'react-router-dom'

interface Props {
    loading: boolean
    riskAssessment?: styx.RiskAssessment
    createRiskAssessmentV2: () => void
}

const { Text } = Typography

export const RiskAssessment: FC<React.PropsWithChildren<Props>> = ({
    riskAssessment,
    createRiskAssessmentV2,
    loading,
}) => {
    const isNotRiskAssessmentV1 = riskAssessment?.version != RiskAssessmentVersion.V1

    if (loading) {
        return <Skeleton data-testid="skeleton" />
    }

    if (!riskAssessment) {
        return (
            <Empty description="There is currently no risk assessment for this company.">
                <Button data-testid="create-new" onClick={createRiskAssessmentV2}>
                    Create new risk assessment
                </Button>
            </Empty>
        )
    }

    let container
    if (isNotRiskAssessmentV1) {
        container = (
            <>
                <AssessmentResultV2 />
                <RiskCheckFormV2 />
            </>
        )
    } else {
        container = <RiskCheckForm />
    }

    return <ContentContainer>{container}</ContentContainer>
}

const RiskAssessmentContainer: FC<React.PropsWithChildren<unknown>> = () => {
    const [snapshot, setSnapshot] = useState<string | undefined>()
    const [loading, setLoading] = useState(false)
    const { riskAssessment, mutations } = useRiskAssessment()
    const { data: snapshotAssessment, error } = useCompanyRiskAssessment(snapshot, true)
    const kycCheckpoints = useKycCheckpoints()
    const { id: companyId } = useParams()
    const { documents } = useCompanyDocumentsWithDateSnapshot(companyId, snapshot)

    const onCreateRiskAssessmentV2 = async () => {
        try {
            setLoading(true)
            await mutations.createRiskAssessmentV2()
        } catch (e) {
            message.error(`Failed to create risk assessment: ${(e as Error).message}`)
        } finally {
            setLoading(false)
        }
    }

    const onFetchSnapshot = (snapshotDate?: string) => {
        setSnapshot(snapshotDate)
    }

    const kycSnapshotTitleRef = useRef('')

    const onOkResetRiskAssessment = async () => {
        try {
            setLoading(true)
            await mutations.resetRiskAssessment(kycSnapshotTitleRef.current)
        } catch (e) {
            message.error(`Failed to reset risk assessment: ${(e as Error).message}`)
        } finally {
            setLoading(false)
        }
    }

    const confirmCreateNewRiskAssessment = () => {
        Modal.confirm({
            title: 'Create a new risk assessment',
            okCancel: true,
            content: (
                <>
                    <Text>Are you sure you want to create a new risk assessment?</Text>
                    <br />
                    <br />
                    <Text>Provide a KYC checkpoint title (optional):</Text>
                    <Input
                        data-testid="snapshot-title-input"
                        placeholder="Type here..."
                        onBlur={(value) => (kycSnapshotTitleRef.current = value.target.value)}
                    />
                </>
            ),
            okButtonProps: { danger: true },
            okText: 'Confirm',
            onOk: onOkResetRiskAssessment,
            onCancel: () => (kycSnapshotTitleRef.current = ''),
        })
    }

    if (loading) {
        return <Skeleton data-testid="skeleton" />
    }

    return (
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
            {riskAssessment && (
                <Button
                    loading={loading}
                    type="primary"
                    icon={<RedoOutlined />}
                    onClick={confirmCreateNewRiskAssessment}
                    data-testid="create-risk-assessment-button"
                >
                    Create new risk assessment
                </Button>
            )}
            <SnapshotTabs
                error={error}
                kycCheckpoints={kycCheckpoints}
                onFetchSnapshot={onFetchSnapshot}
                riskAssessment={riskAssessment}
                readonlyChildren={() => (
                    <AssessmentSnapshot riskAssessment={snapshotAssessment} documents={documents} />
                )}
            >
                <RiskAssessment
                    loading={loading}
                    riskAssessment={riskAssessment}
                    createRiskAssessmentV2={onCreateRiskAssessmentV2}
                />
            </SnapshotTabs>
        </Space>
    )
}

export default RiskAssessmentContainer
