import React, { FC, useCallback } from 'react'
import { Card, Popover, Space, Switch, Tag, Typography, notification } from 'antd'
import { InfoCircleOutlined, WarningOutlined } from '@ant-design/icons'

import styled from 'styled-components'
import { spacing } from 'theme/tokens'
import { useDeimosStorage } from 'services/deimos/storage'
import { useReimbursementsEnablement } from './reimbursements/hooks/use-reimbursements-enablement'
import { usePleoReserveEligibility } from 'services/deimos/pleo-reserve'
import type { ProductEligibilityProductState } from 'bff/moons/generated/narvi-v1'
import { useIsOverdraftEnabled, useOverdraftEligibility } from 'services/deimos/overdraft'
import { useEntitlementsOverride } from './entitlements/hooks/use-entitlements-override'
import { useHasPermissions } from '../../../../../components/permission-guard/permission-guard'
import { useCashManagementEnablement } from 'pages/customer-success/company/overview/settings/cash-management/hooks/use-cash-management-enablement'

const { Paragraph } = Typography

interface OptionProps {
    optionName: string
    optionTooltip?: string
    isEnabled: boolean
    onChange: (isEnabled: boolean) => void
    disabled?: boolean
    disabledTooltip?: string
}

export const SettingOption: FC<React.PropsWithChildren<OptionProps>> = ({
    optionName,
    optionTooltip,
    isEnabled,
    disabled,
    disabledTooltip,
    onChange,
}) => {
    const switchComponent = (
        <Switch
            size="small"
            disabled={disabled}
            checked={isEnabled}
            onChange={onChange}
            title={optionName}
        />
    )
    return (
        <SettingOptionContainer>
            {disabledTooltip ? (
                <Popover
                    content={
                        <PopoverContent>
                            <Paragraph>{disabledTooltip}</Paragraph>
                        </PopoverContent>
                    }
                >
                    {switchComponent}
                </Popover>
            ) : (
                switchComponent
            )}{' '}
            {optionName}
            {optionTooltip ? (
                <Popover
                    content={
                        <PopoverContent>
                            <Paragraph>{optionTooltip}</Paragraph>
                        </PopoverContent>
                    }
                >
                    <InfoCircleOutlined />
                </Popover>
            ) : null}
        </SettingOptionContainer>
    )
}

interface StorageSettingOptionProps extends Omit<OptionProps, 'onChange' | 'isEnabled'> {
    companyId: string
    storageKey: string
}

export const StorageSettingOption: FC<React.PropsWithChildren<StorageSettingOptionProps>> = ({
    companyId,
    storageKey,
    ...props
}) => {
    const {
        data,
        mutations: { deleteItem, setItem },
    } = useDeimosStorage({ companyId, key: storageKey })

    const isEnabled = data?.value === 'true'
    const handleOnChange = useCallback(
        (enabled: boolean) => {
            if (enabled) {
                setItem('true')
            } else {
                deleteItem()
            }
        },
        [setItem, deleteItem]
    )

    return <SettingOption {...props} isEnabled={isEnabled} onChange={handleOnChange} />
}

type PocketEnabledOptionProps = Omit<OptionProps, 'onChange' | 'isEnabled'> & { companyId: string }

export const PocketEnabledOption: FC<React.PropsWithChildren<PocketEnabledOptionProps>> = ({
    companyId,
    ...props
}) => {
    const {
        pocketEnabled,
        mutations: { enableReimbursements, disableReimbursements },
    } = useReimbursementsEnablement(companyId)

    const onChange = () => {
        if (pocketEnabled) {
            alert(
                'Please check company plan. Contact team Theia to enable ATM withdrawal fees if the company plan has ATM fees'
            )
            disableReimbursements({ companyId })
        } else {
            enableReimbursements({ companyId })
        }
    }

    return <SettingOption {...props} isEnabled={pocketEnabled} onChange={onChange} />
}

type AdvancedEntitlementOverrideOptionProps = Omit<OptionProps, 'onChange' | 'isEnabled'> & {
    companyId: string
    countryCode: string
}

export const AdvancedEntitlementOverrideOption: FC<
    React.PropsWithChildren<AdvancedEntitlementOverrideOptionProps>
> = ({ companyId, countryCode, ...props }) => {
    const {
        advancedEntitlementsOverride,
        mutations: { addAdvancedOverrides, deleteAllOverrides },
    } = useEntitlementsOverride(companyId, countryCode)
    const isDisabled = !useHasPermissions(['entitlements-override'])
    const onChange = () => {
        if (advancedEntitlementsOverride) {
            deleteAllOverrides({ companyId })
        } else {
            addAdvancedOverrides({ companyId, countryCode })
        }
    }

    return (
        <SettingOption
            {...props}
            isEnabled={advancedEntitlementsOverride}
            onChange={onChange}
            disabled={isDisabled}
        />
    )
}

interface OverdraftWarningTagProps {
    showError: boolean
    productState?: ProductEligibilityProductState
}

const PleoReserveWarningTag = ({ productState, showError }: OverdraftWarningTagProps) => {
    if (showError) {
        return (
            <Popover
                content={
                    <PopoverContent>
                        <Paragraph>Failed to fetch info about Pleo Reserve.</Paragraph>
                    </PopoverContent>
                }
            >
                <Tag color="orange">
                    <WarningOutlined />
                </Tag>
            </Popover>
        )
    }
    if (productState === 'OVERDUE' || productState === 'COLLECTIONS') {
        const popoverText = {
            OVERDUE:
                'The most recent auto top-up failed, but not the ones before the most recent one.',
            COLLECTIONS: 'At least two consecutive auto top-ups failed.',
        }[productState]

        const tagColor = {
            OVERDUE: 'orange',
            COLLECTIONS: 'red',
        }[productState]

        return (
            <Popover
                content={
                    <PopoverContent>
                        <Paragraph>{popoverText}</Paragraph>
                    </PopoverContent>
                }
            >
                <Tag color={tagColor}>{productState}</Tag>
            </Popover>
        )
    }
    return null
}

export const PleoReserveOption: FC<Props> = ({ companyId }) => {
    const pleoReserve = usePleoReserveEligibility(companyId)
    const { enabled, productState } = pleoReserve.data ?? {}

    // Needs to be implemented first on the Backend
    const canUserEditReserve = false

    const disabledTooltip = canUserEditReserve
        ? undefined
        : `You don't have permissions to change Pleo Reserve status.`

    return (
        <Space>
            <SettingOption
                disabled={!canUserEditReserve}
                disabledTooltip={disabledTooltip}
                isEnabled={Boolean(enabled)}
                onChange={() => {
                    alert(`Changing Pleo Reserve status hasn't been implemented yet.`)
                }}
                optionName="Reserve"
                optionTooltip="When enabled, company is able to spend into negative balance on their wallet."
            />
            <Tag color={pleoReserve.data?.eligibility === 'ELIGIBLE' ? 'green' : 'default'}>
                {pleoReserve.data?.eligibility ?? '-'}
            </Tag>
            <PleoReserveWarningTag
                showError={Boolean(pleoReserve.error)}
                productState={productState}
            />
        </Space>
    )
}

export const OverdraftOption: FC<Props> = ({ companyId }) => {
    const { data: overdraftEligibility } = useOverdraftEligibility(companyId)
    const { isOverdraftEnabled } = useIsOverdraftEnabled(companyId)

    return (
        <Space>
            <SettingOption
                disabled
                disabledTooltip="You don't have permissions to change Overdraft status."
                isEnabled={Boolean(isOverdraftEnabled)}
                onChange={() => {
                    alert(`Changing Overdraft status hasn't been implemented yet.`)
                }}
                optionName="Overdraft"
                optionTooltip="When enabled, company is able to spend into negative balance on their wallet."
            />
            <Tag color={overdraftEligibility?.eligibility?.eligible ? 'green' : 'default'}>
                {overdraftEligibility?.eligibility?.eligible === undefined
                    ? '-'
                    : overdraftEligibility.eligibility.eligible
                      ? 'ELIGIBLE'
                      : 'INELIGIBLE'}
            </Tag>
        </Space>
    )
}

export const CashManagementOption: FC<Props> = ({ companyId }) => {
    const {
        cashManagementEnabled,
        isLoading,
        mutations: { enableCashManagement, disableCashManagement },
    } = useCashManagementEnablement(companyId)

    const hasCreditOpsPermission = useHasPermissions(['credit-ops'])
    const disabledReason =
        isLoading ||
        (!hasCreditOpsPermission &&
            "You don't have permissions to change Cash Management status.") ||
        (process.env.NODE_ENV !== 'production' &&
            'This option can only be changed in production. It is always enabled in dev and staging.')

    const handleChangeError = (e: any) => {
        notification.error({
            message: 'Failed to toggle cash management',
            description: e.message,
        })
    }

    const handleChange = () => {
        if (cashManagementEnabled) {
            disableCashManagement({ companyId }, { onError: handleChangeError })
        } else {
            enableCashManagement({ companyId }, { onError: handleChangeError })
        }
    }

    return (
        <Space>
            <SettingOption
                disabled={disabledReason !== false}
                disabledTooltip={typeof disabledReason === 'string' ? disabledReason : undefined}
                isEnabled={cashManagementEnabled}
                onChange={handleChange}
                optionName="Cash Management"
                optionTooltip="When enabled, company can experience the new cash management page."
            />
        </Space>
    )
}

interface Props {
    companyId: string
}

interface PropsWithCountry {
    companyId: string
    countryCode: string
}

export const Settings: FC<React.PropsWithChildren<PropsWithCountry>> = ({
    companyId,
    countryCode,
}) => (
    <Card title="Settings">
        <SettingsListContainer>
            <PocketEnabledOption
                companyId={companyId}
                optionName="Pocket"
                optionTooltip="Controls whether employees can add pocket expenses"
            />
            <AdvancedEntitlementOverrideOption
                companyId={companyId}
                countryCode={countryCode}
                optionName="Override Advanced Entitlements"
                optionTooltip="It allows to override company entitlements to grant access to advanced features"
            />
            <StorageSettingOption
                companyId={companyId}
                optionName="PDF Receipts"
                optionTooltip="When enabled it will upon export convert all receipts to PDF, instead of using original file format for receipts"
                storageKey="company.settings.accounting.pdfReceipts"
            />
            <PleoReserveOption companyId={companyId} />
            <OverdraftOption companyId={companyId} />
            <CashManagementOption companyId={companyId} />
        </SettingsListContainer>
    </Card>
)

const PopoverContent = styled.div`
    max-width: 400px;
    display: flex;
    flex-direction: column;
`

const SettingsListContainer = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: ${spacing.space8} ${spacing.space24};
`

const SettingOptionContainer = styled.div`
    display: flex;
    align-items: center;
    gap: ${spacing.space8};
`
