import dayjs from 'packages/dayjs'

export function formatRejectionReason(upperSnakeCase: string): string {
    const acronyms = ['CRC', 'UBO', 'PEP', 'SOHO', 'EDD', 'IDV']

    return upperSnakeCase
        .split('_')
        .map((word) =>
            acronyms.includes(word) ? word : word[0].toUpperCase() + word.slice(1).toLowerCase()
        )
        .join(' ')
}

export function calculateWeekdayHours(waitingSince?: string): string {
    if (!waitingSince) {
        return 'Unknown'
    }

    let start = dayjs(waitingSince)
    const now = dayjs()

    // If the waiting time is in the future, return 0 hours.
    if (start.isAfter(now)) {
        return '0 hours'
    }

    let totalHours = 0

    // Loop until we've reached the current time
    while (start.isBefore(now)) {
        const dayOfWeek = start.day()

        // dayjs.day() returns 0 for Sunday and 6 for Saturday.
        // If current day is weekend, skip ahead to Monday.
        if (dayOfWeek === 6 || dayOfWeek === 0) {
            // If Saturday (6), add two days; if Sunday (0), add one day.
            const daysToAdd = dayOfWeek === 6 ? 2 : 1
            start = start.add(daysToAdd, 'day').startOf('day')
            continue
        }

        // Determine the end of the current day
        const endOfDay = start.endOf('day')
        // Use the lesser of now and the end of the day to determine the current block
        const blockEnd = now.isBefore(endOfDay) ? now : endOfDay

        // Calculate the difference in hours (as a decimal, if needed)
        const diffHours = blockEnd.diff(start, 'hour', true)
        totalHours += diffHours

        // Move start to the next moment after blockEnd
        start = blockEnd.add(1, 'millisecond')
    }

    return `${totalHours.toFixed(0)} hours`
}
