import { Breadcrumb, Col, Row } from 'antd'
import { Link } from 'react-router-dom'

import { PageContentLayout } from 'components/layout-containers'

import { Header } from 'features/kyc-processing-hub/header/header'
import { CheckList } from 'features/kyc-processing-hub/checks/checks'
import { Evidence } from 'features/kyc-processing-hub/evidence/evidence'
import { KycDetailProvider } from 'features/kyc-processing-hub/context'
import { FinaliseModal } from 'features/kyc-processing-hub/finalise-modal/finalise-modal'
import { RequestInformation } from 'features/kyc-processing-hub/request-information/request-information'
import { HistoryModal } from 'features/kyc-processing-hub/history/history-modal'
import { Notes } from 'features/kyc-processing-hub/notes/notes'
import { CannotProceed } from 'features/kyc-processing-hub/cannot-proceed/cannot-proceed'

export const KycDetailsPage = () => {
    return (
        <KycDetailProvider>
            <PageContentLayout>
                <Breadcrumbs />
                <Header />
                <Row gutter={[16, 16]}>
                    <Col span={6}>
                        <CheckList />
                        <RequestInformation />
                        <CannotProceed />
                        <Notes />
                    </Col>
                    <Col span={18}>
                        <Evidence />
                    </Col>
                </Row>
            </PageContentLayout>
            <FinaliseModal />
            <HistoryModal />
        </KycDetailProvider>
    )
}

const Breadcrumbs = () => {
    return (
        <Breadcrumb>
            <Breadcrumb.Item>
                <Link to={'/compliance'}>Compliance</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
                <Link to={'/compliance/kyc-processing-hub/ready-for-fdd'}>KYC Processing Hub</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
                <Link to={''}>Case</Link>
            </Breadcrumb.Item>
        </Breadcrumb>
    )
}
