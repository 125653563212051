import type { FC } from 'react'
import { Card, Descriptions, Empty, Table, Typography } from 'antd'
import RiskCheckTip from 'pages/compliance/company/risk-assessment/risk-check-tip/risk-check-tip'
import { deSnakify } from 'utils/strings'
import { sortRiskCheckForm } from '../utils'
import Spinner from 'components/content-spinner'
import { useStyxCompany } from 'services/deimos/styx-company/styx-company'
import { riskFactorNamesMap } from 'pages/compliance/company/risk-assessment/risk-check-form-v2/columns'
import {
    type RiskAssessment,
    type RiskCheck,
    RiskScore,
    RiskTypesV2,
    type Document,
    ResultScore,
} from 'types/styx'

const { Text } = Typography

interface Props {
    riskAssessment?: RiskAssessment | null
    isValidating?: boolean
    documents?: Document[] | null
}

const RiskCheckSnapshot: FC<React.PropsWithChildren<Props>> = ({
    riskAssessment,
    isValidating,
    documents,
}) => {
    const loading = !riskAssessment
    const { company } = useStyxCompany()

    const isOnboardedViaB4B = company?.onboardedVia === 'B4B'
    const isRiskAssessmentPassed = riskAssessment?.result === ResultScore.PASS
    const hasB4BRiskAssessmentDocument =
        documents?.some(
            (document) =>
                document.category === 'EXTERNAL_RISK_ASSESSMENT' && document.files?.length > 0
        ) ?? false
    // This date is when the B4B risk assessment was added in backoffice
    // https://github.com/pleo-io/backoffice/pull/2507
    const startDateB4BRiskAssessment = new Date('2023-10-24T00:00:00Z')
    // This date is when B4B risk assessment is deprecated
    const endDateB4BRiskAssessment = new Date('2025-03-19T00:00:00Z')
    const riskAssessmentDate = riskAssessment ? new Date(riskAssessment.assessmentDate) : null
    const isRiskAssessmentWhileB4BWasActive = riskAssessmentDate
        ? riskAssessmentDate >= startDateB4BRiskAssessment &&
          riskAssessmentDate <= endDateB4BRiskAssessment
        : false

    if (isValidating) return <Spinner />

    if (!riskAssessment?.riskChecks) return <Empty description="No Assessment for Snapshot" />

    /**
     * KYC-304: On this issue we have deprecated the B4B risk assessments for GB companies.
     * Forthcoming changes of a company risk assessment will be done following the Pleo latest
     * RA, but we need to display some help to the analysts when the companies used
     * the B4B RA.
     */
    const isRiskAssessmentPassedAsB4B =
        isOnboardedViaB4B &&
        isRiskAssessmentPassed &&
        hasB4BRiskAssessmentDocument &&
        isRiskAssessmentWhileB4BWasActive

    const snapshotInfoHeader = () => {
        if (!riskAssessment?.score) return <Text strong>No Assessment for Snapshot</Text>

        return (
            <Descriptions>
                <Descriptions.Item label="Score">{riskAssessment?.score}</Descriptions.Item>
                <Descriptions.Item label="Result">{riskAssessment?.result}</Descriptions.Item>
                <Descriptions.Item label="Owner">{riskAssessment?.ownerName}</Descriptions.Item>
                <Descriptions.Item label="Notes">{riskAssessment?.notes}</Descriptions.Item>
            </Descriptions>
        )
    }

    const filteredRiskChecks = riskAssessment.riskChecks.filter(
        (check) =>
            (check.riskType as unknown as RiskTypesV2) !== RiskTypesV2.CONTINUOUS_RISK_MONITORING
    )

    const postFddRiskCheck: RiskCheck | undefined = riskAssessment.riskChecks.find((check) => {
        if ((check.riskType as unknown as RiskTypesV2) === RiskTypesV2.CONTINUOUS_RISK_MONITORING) {
            if (check.score === RiskScore.LOW) {
                check.score = RiskScore.NOT_APPLICABLE
            }
            return true
        }
        return false
    })

    const renderRiskTypeColumn = (type: keyof typeof riskFactorNamesMap) => (
        <>
            <Text strong>{riskFactorNamesMap[type] || deSnakify(type)}</Text>{' '}
            <RiskCheckTip riskType={type} />
        </>
    )
    const riskCheckColumns = [
        {
            title: 'Risk type',
            dataIndex: 'riskType',
            render: renderRiskTypeColumn,
            width: 200,
        },
        {
            title: 'Factor ID',
            dataIndex: 'riskTypeId',
            key: 'id',
        },
        {
            title: 'Level of risk',
            dataIndex: 'score',
            key: 'id',
        },
        {
            title: 'Notes',
            dataIndex: 'notes',
            key: 'id',
        },
        {
            title: 'Owner',
            dataIndex: 'ownerName',
        },
    ]

    const renderRiskCheckTable = (dataSource: RiskCheck[] | undefined, isLoading: boolean) => {
        const flatDataSource = Array.isArray(dataSource)
            ? dataSource
            : dataSource
              ? [dataSource]
              : []
        return (
            <Table
                loading={isLoading}
                dataSource={sortRiskCheckForm<RiskCheck>(flatDataSource)}
                pagination={false}
                rowKey="riskType"
                columns={riskCheckColumns}
            />
        )
    }

    return (
        <Card>
            {isRiskAssessmentPassedAsB4B && (
                <Card style={{ border: 'none' }}>
                    <h2 style={{ color: 'red' }}>
                        This risk assessment was likely completed through the external B4B
                        evaluation. Please refer to the external risk assessment document to review
                        the risk ratings.
                    </h2>
                </Card>
            )}
            {postFddRiskCheck && (
                <Card extra={snapshotInfoHeader()}>
                    {renderRiskCheckTable([postFddRiskCheck], loading)}
                </Card>
            )}

            <Card extra={!postFddRiskCheck ? snapshotInfoHeader() : undefined}>
                {renderRiskCheckTable(filteredRiskChecks, loading)}
            </Card>
        </Card>
    )
}

export default RiskCheckSnapshot
