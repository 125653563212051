import { Button, Input, Modal, Select, Typography, Form, notification } from 'antd'

import { useKycDetailContext } from '../context'
import { bff } from '../bff-hooks'
import { FinaliseFormData, FDD_STATUS_OPTIONS, REJECTION_REASON_OPTIONS } from './types'

export const FinaliseModal = () => {
    const {
        caseId,
        finaliseModal: { isOpen, close },
    } = useKycDetailContext()
    const [form] = Form.useForm<FinaliseFormData>()
    const { data } = bff.finaliseModal.getCddCase.useQuery({ caseId })
    const { mutateAsync: finaliseCase, isLoading } = bff.finaliseModal.finaliseCddCase.useMutation()

    const handleSubmit = async (values: FinaliseFormData) => {
        try {
            await finaliseCase({
                caseId,
                outcome: values.fddStatus!,
                rejectionReason: values.rejectionReason,
                caseNote: values.comment!,
            })
            close()
        } catch (error) {
            notification.error({
                message: 'Failed to finalise case',
                description: error instanceof Error ? error.message : 'Unknown error occurred',
            })
        }
    }

    const fddStatus = Form.useWatch('fddStatus', form)

    return (
        <Modal
            open={isOpen}
            onCancel={close}
            title="Finalise this CDD case?"
            footer={null}
            destroyOnClose
        >
            <Form form={form} onFinish={handleSubmit} layout="vertical" requiredMark={false}>
                <Form.Item
                    name="fddStatus"
                    label={<Typography.Text strong>FDD Status</Typography.Text>}
                    rules={[
                        { required: true, message: 'Please select an FDD status' },
                        {
                            validator: (_, value) => {
                                if (value === 'approved' && !data?.canApprove) {
                                    return Promise.reject(
                                        'All checks must be completed before approving'
                                    )
                                }
                                return Promise.resolve()
                            },
                        },
                    ]}
                >
                    <Select placeholder="Select an FDD status" options={FDD_STATUS_OPTIONS} />
                </Form.Item>
                <Form.Item
                    name="rejectionReason"
                    label={<Typography.Text strong>Rejection reason</Typography.Text>}
                    rules={[
                        {
                            required: fddStatus === 'rejected',
                            message: 'Please select a rejection reason',
                        },
                    ]}
                >
                    <Select
                        placeholder="Select a reason"
                        options={REJECTION_REASON_OPTIONS}
                        disabled={fddStatus !== 'rejected'}
                    />
                </Form.Item>

                <Form.Item
                    name="comment"
                    label={<Typography.Text strong>Case notes</Typography.Text>}
                    rules={[{ required: true, message: 'Please enter case notes' }]}
                >
                    <Input.TextArea placeholder="Case notes" rows={6} showCount />
                </Form.Item>
                <Form.Item style={{ marginBottom: 0, textAlign: 'right' }}>
                    <Button type="primary" htmlType="submit" loading={isLoading}>
                        Finalise
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    )
}
