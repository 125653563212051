import type { FC } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useInvite } from 'services/kerberos/invites'
import { PageHeader } from '@ant-design/pro-layout'
import { Button, Descriptions, Result, Typography } from 'antd'
import Spinner from 'components/content-spinner'
import styled from 'styled-components'
import { spacing } from 'theme/tokens'
import { RemoveInvite } from './components/removeInvite'
import { ArrowLeftOutlined } from '@ant-design/icons'

const { Text, Paragraph } = Typography

const InvitePage = () => {
    const navigate = useNavigate()
    const goBack = () => navigate(-1)
    const { id: inviteId } = useParams()
    const { data: invite, error } = useInvite(inviteId)

    if (!invite && !error) {
        return <Spinner />
    }

    if (error) {
        return (
            <Result
                status="500"
                title="Something went wrong"
                subTitle="We had trouble fetching the invite"
                extra={
                    <Button type="primary" onClick={goBack}>
                        Go back
                    </Button>
                }
            />
        )
    }

    return (
        <PageHeader
            ghost={false}
            onBack={goBack}
            backIcon={<ArrowLeftOutlined />}
            title={<PageTitle id={invite?.id} />}
            extra={<RemoveInvite inviteId={inviteId} />}
        >
            <DetailsContainer>
                <Descriptions column={1} size="small">
                    <Descriptions.Item label="Invite ID">
                        <CopyableUUID id={invite?.id} />
                    </Descriptions.Item>
                    <Descriptions.Item label="Type">
                        <Text>{invite?.type ?? '-'}</Text>
                    </Descriptions.Item>
                </Descriptions>
                <Descriptions column={1} size="small">
                    <Descriptions.Item label="Resource">
                        <Text>{invite?.resource ?? '-'}</Text>
                    </Descriptions.Item>
                    <Descriptions.Item label="Resource ID">
                        <CopyableUUID id={invite?.resourceId} />
                    </Descriptions.Item>
                    {invite?.parentResource && (
                        <Descriptions.Item label="Parent Resource">
                            <Text>{invite?.parentResource}</Text>
                        </Descriptions.Item>
                    )}
                    {invite?.parentResourceId && (
                        <Descriptions.Item label="Parent Resource ID">
                            <CopyableUUID id={invite?.parentResourceId} />
                        </Descriptions.Item>
                    )}
                </Descriptions>
                <Descriptions column={1} size="small">
                    {invite?.requesterUserId && (
                        <Descriptions.Item label="User who requested invite (ID)">
                            <CopyableUUID id={invite?.requesterUserId} />
                        </Descriptions.Item>
                    )}
                    {invite?.acceptorUserId && (
                        <Descriptions.Item label="User who accepted invite (ID)">
                            <CopyableUUID id={invite?.acceptorUserId} />
                        </Descriptions.Item>
                    )}
                    {invite?.targetUserId && (
                        <Descriptions.Item label="Target user ID">
                            <CopyableUUID id={invite?.targetUserId} />
                        </Descriptions.Item>
                    )}
                </Descriptions>
            </DetailsContainer>
        </PageHeader>
    )
}

const PageTitle: FC<{ id?: string }> = ({ id }) => {
    return (
        <Paragraph
            copyable
            ellipsis={{ rows: 1, expandable: true, symbol: 'more' }}
            style={{ margin: 0, maxWidth: 440 }}
        >
            {id}
        </Paragraph>
    )
}

const CopyableUUID: FC<{ id?: string }> = ({ id }) => {
    return id ? (
        <Paragraph
            copyable
            ellipsis={{ rows: 1, expandable: true, symbol: 'more' }}
            style={{ margin: 0, maxWidth: 160 }}
        >
            {id}
        </Paragraph>
    ) : (
        <Text>{'-'}</Text>
    )
}

const DetailsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: ${spacing.space24};

    .ant-descriptions * {
        width: auto;
    }
`

export default InvitePage
