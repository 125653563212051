import ContextCard from './context-card'
import { Card, Col, Row } from 'antd'

import type { InvestigationContext } from 'bff/moons/generated/investigation-runner'
interface ContextSectionProps {
    title: string
    contexts: InvestigationContext[]
    isFavorite?: boolean
    removeFavorite: (id: string | undefined) => void
    addFavorite: (id: string | undefined) => void
}

export default function ContextSection({
    title,
    contexts,
    isFavorite,
    removeFavorite,
    addFavorite,
}: ContextSectionProps) {
    return (
        <Card title={title} style={{ marginBottom: 24 }}>
            <Col span={24}>
                <Row justify={'start'} gutter={[24, 24]}>
                    {contexts.map((context, index) => (
                        <ContextCard
                            context={context}
                            key={index}
                            isFavorite={isFavorite}
                            removeFavorite={removeFavorite}
                            addFavorite={addFavorite}
                        />
                    ))}
                </Row>
            </Col>
        </Card>
    )
}
