import type { FC } from 'react'
import { Button, Dropdown, MenuProps, message } from 'antd'
import { TeamOutlined } from '@ant-design/icons'
import { demoAccountsBff } from '../../bff/demo-accounts-bff'
import type { Company } from 'bff/moons/generated/dionysus'

interface Props {
    selectedCompany: Company
    revalidateDemoAccount?: () => void
}

enum CreateEmployeeOptions {
    WITH_CARDS = 'With virtual cards',
    WITHOUT_CARDS = 'Without cards',
}

export const DemoAccountCreateEmployeesDropdown: FC<Props> = ({ selectedCompany }) => {
    const { mutateAsync: createEmployees, isLoading } =
        demoAccountsBff.singleAccount.createEmployees.useMutation()

    const items: MenuProps['items'] = Object.values(CreateEmployeeOptions)
        .map((type) => {
            return {
                key: type,
                label: type,
                onClick: async () => {
                    const count = Number(prompt('How many employees do you want to create?'))

                    if (Number.isNaN(count) || count <= 0) {
                        return
                    }

                    try {
                        const result = await createEmployees({
                            withCards: type === CreateEmployeeOptions.WITH_CARDS,
                            count,
                            companyId: selectedCompany.id,
                        })

                        if (result.success) {
                            message.success(`Created ${count} employees ${type}`)
                        } else {
                            message.error(
                                `Failed to create ${count} employees ${type}: ${result.error}`
                            )
                        }
                    } catch (e) {
                        message.error(`Failed to create ${count} employees ${type}: ${e}`)
                    }
                },
            }
        })
        .filter(Boolean)

    return (
        <Dropdown menu={{ items }} trigger={['hover']} disabled={isLoading}>
            <Button disabled={isLoading} loading={isLoading} icon={<TeamOutlined />}>
                Create employees
            </Button>
        </Dropdown>
    )
}
