import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import type {
    InvestigationContextInputs,
    InvestigationContextOutputs,
    InvestigationOutput,
} from 'bff/moons/generated/investigation-runner'

import { bff } from './bff'
import { message } from 'antd'
import { EXCLUDED_OUPTUT_COLUMNS } from './constants'
import type { SortingSchemaType } from './types'

interface ModalMetadata {
    isOpen: boolean
    open: () => void
    close: () => void
}

interface ContextDetailContext {
    contextId: string
    inputsModal: ModalMetadata
    selectedInputs: string[]
    setSelectedInputs: (inputsSelected: string[]) => void
    resetInputsToDefault: () => void
    selectedOutputs: string[]
    setSelectedOutputs: (outputsSelected: string[]) => void
    resetOutputsToDefault: () => void
    inputValues: Record<string, any>
    setInputValues: (inputValues: Record<string, any>) => void
    inputs: InvestigationContextInputs
    outputs: InvestigationContextOutputs
    runInvestigation: (
        pagination?: { current: number; pageSize: number },
        sorting?: SortingSchemaType[]
    ) => void
    investigationOutput: InvestigationOutput | null
    investigationOutputLoading: boolean
    pagination: { current: number; pageSize: number; total: number }
    setPagination: (pagination: { current: number; pageSize: number; total: number }) => void
    showTooManyColumnsWarning: boolean
    setShowTooManyColumnsWarning: (show: boolean) => void
}

const ContextDetailContext = createContext<ContextDetailContext | undefined>(undefined)

const getLocalStorageOrDefaultInputs = (
    inputs: InvestigationContextInputs | undefined,
    contextId: string
) => {
    if (!inputs) {
        return []
    }

    const defaultSelectedInputs = Object.keys(inputs).filter((input) => inputs[input].isDefault)
    const localStorageSelection = localStorage.getItem(`${contextId}-selectedInputs`)
        ? localStorage.getItem(`${contextId}-selectedInputs`)
        : JSON.stringify(defaultSelectedInputs)

    return localStorageSelection ? JSON.parse(localStorageSelection) : defaultSelectedInputs
}

const getLocalStorageOrDefaultOutputs = (
    outputs: InvestigationContextOutputs | undefined,
    contextId: string
) => {
    if (!outputs) {
        return []
    }

    const defaultSelectedOutputs = Object.keys(outputs).filter(
        (output) => outputs[output].isDefault
    )
    const localStorageSelection = localStorage.getItem(`${contextId}-selectedOutputs`)
        ? localStorage.getItem(`${contextId}-selectedOutputs`)
        : JSON.stringify(defaultSelectedOutputs)

    return localStorageSelection ? JSON.parse(localStorageSelection) : defaultSelectedOutputs
}

const getLocalStorageOrDefaultInputValues = (
    inputs: InvestigationContextInputs | undefined,
    contextId: string
) => {
    if (!inputs) {
        return {}
    }

    const defaultInputValues = Object.keys(inputs).reduce(
        (acc, input) => {
            acc[input] = inputs[input].defaultValue
            return acc
        },
        {} as Record<string, any>
    )

    const localStorageInputValues =
        localStorage.getItem(`${contextId}-inputValues`) &&
        localStorage.getItem(`${contextId}-inputValues`) !== '{}'
            ? localStorage.getItem(`${contextId}-inputValues`)
            : JSON.stringify(defaultInputValues)

    return localStorageInputValues ? JSON.parse(localStorageInputValues) : defaultInputValues
}

const resetLocalStorageInputs = (inputs: InvestigationContextInputs, contextId: string) => {
    const defaultSelectedInputs = Object.keys(inputs).filter((input) => inputs[input].isDefault)

    localStorage.setItem(`${contextId}-selectedInputs`, JSON.stringify(defaultSelectedInputs))
}

const resetLocalStorageOutputs = (outputs: InvestigationContextOutputs, contextId: string) => {
    const defaultSelectedOutputs = Object.keys(outputs).filter(
        (output) => outputs[output].isDefault
    )

    localStorage.setItem(`${contextId}-selectedOutputs`, JSON.stringify(defaultSelectedOutputs))
}

const getDefaultInputs = (inputs: InvestigationContextInputs | undefined) => {
    if (!inputs) {
        return []
    }

    const defaultSelectedInputs = Object.keys(inputs).filter((input) => inputs[input].isDefault)

    return defaultSelectedInputs
}

const getDefaultOutputs = (outputs: InvestigationContextOutputs | undefined) => {
    if (!outputs) {
        return []
    }

    const defaultSelectedOutputs = Object.keys(outputs).filter(
        (output) => outputs[output].isDefault
    )

    return defaultSelectedOutputs
}

const setAllLocalStorage = (
    selectedInputs: string[],
    selectedOutputs: string[],
    inputValues: Record<string, any>,
    contextId: string
) => {
    localStorage.setItem(`${contextId}-selectedInputs`, JSON.stringify(selectedInputs))
    localStorage.setItem(`${contextId}-selectedOutputs`, JSON.stringify(selectedOutputs))
    localStorage.setItem(`${contextId}-inputValues`, JSON.stringify(inputValues))
}

export const useContextDetailContext = () => {
    const context = useContext(ContextDetailContext)
    if (!context) {
        throw new Error('useContextDetailContext must be used within a ContextDetailContext')
    }
    return context
}

const checkMissingRequiredInputs = (
    inputs: InvestigationContextInputs,
    inputValues: Record<string, any>
) => {
    const requiredInputs = Object.values(inputs).filter((input) => input.required)
    const missingRequiredInputs = requiredInputs.filter((input) => !inputValues[input.id])
    return missingRequiredInputs.map((input) => input.label)
}

export const ContextDetailProvider = ({ children }: { children: ReactNode }) => {
    const params = useParams()
    const { data } = bff.investigationContexts.getContexts.useQuery()
    const { mutateAsync: runInvestigationMutation, isLoading: investigationOutputLoading } =
        bff.investigationContexts.runInvestigation.useMutation()

    const [investigationOutput, setInvestigationOutput] = useState<InvestigationOutput | null>(null)

    const currentContext = data?.data.find((context) => context.id === params.id)

    const inputs = currentContext?.inputs as InvestigationContextInputs
    const outputs = currentContext?.outputs as InvestigationContextOutputs

    const urlParams = new URLSearchParams(window.location.search)
    const base64DetailState = urlParams.get('base64DetailState')

    const hasBase64DetailState = !!base64DetailState

    const [isSelectInputsModalOpen, setSelectInputsModalOpen] = useState(false)
    const [selectedInputs, setSelectedInputs] = useState<string[]>(
        getLocalStorageOrDefaultInputs(inputs, params.id)
    )
    const [selectedOutputs, setSelectedOutputs] = useState<string[]>(
        getLocalStorageOrDefaultOutputs(outputs, params.id)
    )
    const [inputValues, setInputValues] = useState<Record<string, any>>(
        getLocalStorageOrDefaultInputValues(inputs, params.id)
    )

    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0,
    })

    const [showTooManyColumnsWarning, setShowTooManyColumnsWarning] = useState(false)

    const resetInputsToDefault = () => {
        resetLocalStorageInputs(inputs, params.id)
        setSelectedInputs(getDefaultInputs(inputs))
    }

    const resetOutputsToDefault = () => {
        resetLocalStorageOutputs(outputs, params.id)
        setSelectedOutputs(getDefaultOutputs(outputs))
    }

    const inputsModal = {
        isOpen: isSelectInputsModalOpen,
        open: () => setSelectInputsModalOpen(true),
        close: () => setSelectInputsModalOpen(false),
    }

    const updateUrl = useCallback(() => {
        const contextDetailState = {
            selectedInputs,
            selectedOutputs,
            inputValues,
            pagination,
        }
        const contextDetailStateString = btoa(JSON.stringify(contextDetailState))

        window.history.replaceState({}, '', `?base64DetailState=${contextDetailStateString}`)
    }, [selectedInputs, selectedOutputs, inputValues, pagination])

    useEffect(() => {
        if (!inputs || !outputs) {
            return
        }

        if (hasBase64DetailState) {
            const decodedState = JSON.parse(atob(base64DetailState))

            setSelectedInputs(decodedState.selectedInputs)
            setSelectedOutputs(decodedState.selectedOutputs)
            setInputValues(decodedState.inputValues)
            setAllLocalStorage(
                decodedState.selectedInputs,
                decodedState.selectedOutputs,
                decodedState.inputValues,
                params.id
            )
        } else {
            const tempSelectedInputs = getLocalStorageOrDefaultInputs(inputs, params.id)
            const tempSelectedOutputs = getLocalStorageOrDefaultOutputs(outputs, params.id)
            const tempInputValues = getLocalStorageOrDefaultInputValues(inputs, params.id)

            setSelectedInputs(tempSelectedInputs)
            setSelectedOutputs(tempSelectedOutputs)
            setInputValues(tempInputValues)
            setAllLocalStorage(tempSelectedInputs, tempSelectedOutputs, tempInputValues, params.id)
            updateUrl()
        }
    }, [inputs, outputs]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (
            selectedInputs &&
            selectedInputs.length &&
            selectedOutputs &&
            selectedOutputs.length &&
            inputValues &&
            Object.keys(inputValues).length
        ) {
            setAllLocalStorage(selectedInputs, selectedOutputs, inputValues, params.id)
            updateUrl()
        }
    }, [selectedInputs, selectedOutputs, inputValues, updateUrl, params.id])

    const runInvestigation = async (
        pageInfo?: { current: number; pageSize: number },
        sortedColumns?: SortingSchemaType[]
    ) => {
        setInvestigationOutput(null)

        const currentPagination = pageInfo || pagination

        const currentSorting = sortedColumns || []

        const missingRequiredInputs = checkMissingRequiredInputs(inputs, inputValues)

        if (missingRequiredInputs.length > 0) {
            message.error(
                `The following required inputs are missing values: ${missingRequiredInputs.join(
                    ', '
                )}`,
                8
            )
            return
        }

        const inputsToRun = selectedInputs
            .map((inputId) => ({
                inputId,
                value: inputValues[inputId],
            }))
            .filter((input) => input.value !== undefined)

        const outputsToRun = selectedOutputs.filter(
            (output) => EXCLUDED_OUPTUT_COLUMNS.indexOf(output) === -1
        )

        if (outputsToRun.length === 0) {
            message.error('Please select at least one output column', 8)
            return
        }

        try {
            const response = await runInvestigationMutation({
                contextId: params.id,
                inputs: inputsToRun,
                outputs: outputsToRun,
                sorting: currentSorting,
                limit: currentPagination.pageSize || 10,
                offset: (currentPagination.current - 1) * currentPagination.pageSize,
            })

            setInvestigationOutput(response as InvestigationOutput)

            if (response?.pagination) {
                setPagination({
                    current: currentPagination.current,
                    pageSize: currentPagination.pageSize,
                    total: response.pagination.totalRows,
                })
            }
        } catch (error) {
            message.error(`Error: ${error}`, 8)
        }
    }

    return (
        <ContextDetailContext.Provider
            value={{
                contextId: params.id,
                inputsModal,
                selectedInputs,
                setSelectedInputs,
                resetInputsToDefault,
                selectedOutputs,
                setSelectedOutputs,
                resetOutputsToDefault,
                inputValues,
                setInputValues,
                inputs,
                outputs,
                runInvestigation,
                investigationOutput,
                investigationOutputLoading,
                pagination,
                setPagination,
                showTooManyColumnsWarning,
                setShowTooManyColumnsWarning,
            }}
        >
            {children}
        </ContextDetailContext.Provider>
    )
}
