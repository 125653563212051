import { getEmojiFlag } from 'countries-list'
import { TreeSelect } from 'antd'
import type { FC } from 'react'
import type { DemoAccount } from '../../bff/index.bff'
import type { Company } from '../../../../../bff/moons/generated/dionysus'
import { useNavigate } from 'react-router-dom'

interface Props {
    demoAccount: DemoAccount
    selectedCompany: Company
    onChangeEntity?: (companyId: string) => void
}

export const DemoAccountOrgDropdown: FC<Props> = ({ demoAccount, selectedCompany }) => {
    const companies = [demoAccount.company].concat(demoAccount.subEntities)
    const navigate = useNavigate()

    return (
        <TreeSelect
            style={{ minWidth: 250, maxWidth: '100%' }}
            treeLine={{ showLeafIcon: false }}
            size={'middle'}
            treeDefaultExpandAll
            onChange={(entity) => {
                navigate(`/product/demo-accounts/${demoAccount.id}/companies/${entity}`)
            }}
            value={selectedCompany.id}
            treeData={[
                {
                    title: `Organization`,
                    value: 'root',
                    disabled: true,
                    children: companies.map((company) => ({
                        title: `${getEmojiFlag(company.country)} ${company.name}`,
                        value: company.id,
                    })),
                },
            ]}
        />
    )
}
