import { Breadcrumb, Card, Pagination } from 'antd'
import { Link } from 'react-router-dom'

import { PageContentLayout } from 'components/layout-containers'

import { OddTable } from 'features/odd/table'
import { FilterBar } from 'features/odd/filters'
import { CaseCountsBar } from 'features/odd/case-counts'

import { useFilters, DEFAULT_PAGE_SIZE } from '../utils'
import { oddListTabToLabelMapper } from '../types'

import { OddListProvider } from 'features/odd/context-list'
import { CompanyOffboardedModal, CompanyOffboardedInitiatedModal } from 'features/odd/modals'
import type { OddListTab } from 'features/odd/types'

export const OddListPage = () => {
    const {
        filters,
        data,
        isFetching,
        activeTab,
        updateActiveTab,
        updateUrl,
        clearParams,
        updateSearchParams,
    } = useFilters()

    return (
        <OddListProvider>
            <PageContentLayout>
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to={'/compliance'}>Compliance</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to={'/compliance/odd'}>Ongoing Due Diligence</Link>
                    </Breadcrumb.Item>
                </Breadcrumb>
                <Card
                    title="Ongoing Due Diligence (ODD)"
                    tabList={oddListTabToLabelMapper}
                    onTabChange={(key) => updateActiveTab(key as OddListTab)}
                    activeTabKey={activeTab}
                    bordered={false}
                    styles={{ body: { padding: 0 } }}
                >
                    <CaseCountsBar activeTab={activeTab as OddListTab} />
                    <FilterBar
                        activeTab={activeTab}
                        updateUrl={updateUrl}
                        filters={filters}
                        updateSearchParams={updateSearchParams}
                        clearParams={clearParams}
                    />
                    <OddTable data={data?.data} tab={activeTab} isFetching={isFetching} />
                    <Pagination
                        style={{ marginTop: '20px', textAlign: 'right' }}
                        defaultCurrent={1}
                        current={
                            (data?.pagination?.currentRequestPagination?.offset || 0) /
                                DEFAULT_PAGE_SIZE +
                            1
                        }
                        total={data?.pagination?.total || 0}
                        pageSize={DEFAULT_PAGE_SIZE}
                        showSizeChanger={false}
                        onChange={(page) =>
                            updateSearchParams({ offset: (page - 1) * DEFAULT_PAGE_SIZE })
                        }
                    />
                </Card>
            </PageContentLayout>
            <CompanyOffboardedModal />
            <CompanyOffboardedInitiatedModal />
        </OddListProvider>
    )
}
