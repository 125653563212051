import { useState, type FC, type MouseEvent, useMemo } from 'react'
import styled from 'styled-components'
import { color, spacing } from 'theme/tokens'
import {
    CheckCircleTwoTone,
    CheckSquareTwoTone,
    MinusCircleTwoTone,
    WarningTwoTone,
} from '@ant-design/icons'
import { Button, message, Tag, Tooltip } from 'antd'

import type { Person } from 'types/styx'
import { Role, VerificationResult } from 'types/styx'
import { getPersonName, getRoleNames } from 'pages/compliance/shared/utils'
import {
    getHasPassedRequiredKycChecks,
    hasMissingKycInfo,
    hasMissingPepInfo,
} from 'pages/compliance/company/people/helpers'
import { useManagement } from 'services/styx/management'
import { useMissingInformation } from 'services/deimos/kyc'
import { SearchSamePersonModal } from './components/search-same-person-modal'
import { formatTimestamp } from 'packages/dates/dates'

interface StatusIconProps {
    isMissingKycDetails: boolean
    hasPassedRequiredKycChecks: boolean
    isInScope: boolean
}

export const StatusIcon: FC<React.PropsWithChildren<StatusIconProps>> = ({
    isMissingKycDetails,
    hasPassedRequiredKycChecks,
    isInScope,
}) => {
    if (!isInScope) {
        return <MinusCircleTwoTone twoToneColor={color.gray} />
    } else if (isMissingKycDetails && hasPassedRequiredKycChecks) {
        return <CheckSquareTwoTone twoToneColor={color.cyan} />
    } else if (hasPassedRequiredKycChecks) {
        return <CheckCircleTwoTone twoToneColor={color.green} />
    } else {
        return <WarningTwoTone twoToneColor={color.gold} />
    }
}

interface Props {
    person: Person
    addPersonToScope: () => void
    removePersonFromScope: () => void
    isVerified: boolean
}

export function getLatestManuallyApprovedPepCheck(person: Person):
    | {
          providerId?: string
          updatedAt?: string
      }
    | undefined {
    if (!person.enhancedPepChecks || person.enhancedPepChecks.length === 0) {
        return undefined
    }

    // Filter for manually approved checks with valid updatedAt
    const manuallyApprovedChecks = person.enhancedPepChecks.filter(
        (check) => check.result === 'MANUALLY_APPROVED' && check.updatedAt
    )

    // Sort by updatedAt in descending order
    manuallyApprovedChecks.sort(
        (a, b) => new Date(b.updatedAt!).getTime() - new Date(a.updatedAt!).getTime()
    )

    // Return the latest check or undefined if none exist
    const latestCheck = manuallyApprovedChecks[0]
    return latestCheck
        ? { providerId: latestCheck.providerId, updatedAt: latestCheck.updatedAt }
        : undefined
}

export const KycStatus: FC<React.PropsWithChildren<Props>> = ({
    person,
    removePersonFromScope,
    addPersonToScope,
    children,
    isVerified,
}) => {
    const [isSearchSamePersonModalOpen, setIsSearchSamePersonModalOpen] = useState(false)

    const name = getPersonName(person.name)
    const roles = getRoleNames(person.roles)
    const isInScope = person.kycPerson
    const isAdmin = person.roles?.includes(Role.ADMIN)
    const ownershipPercentage = person.ownershipPercentage

    const toggleScope = (event: MouseEvent) => {
        // prevents panel from opening
        event.stopPropagation()

        if (isInScope) {
            removePersonFromScope()
        } else {
            addPersonToScope()
        }
    }

    const searchSimilarPeople = (event: MouseEvent) => {
        // prevents panel from opening
        event.stopPropagation()

        setIsSearchSamePersonModalOpen(true)
    }

    const latestManuallyApprovedPepCheck = useMemo(
        () => getLatestManuallyApprovedPepCheck(person),
        [person]
    )

    return (
        <>
            <PanelHeader>
                {children}
                <span data-testid="name-and-title">
                    <strong>{name}</strong>: {roles}
                    {isAdmin ? (isVerified ? ' (Verified)' : ' (Not verified)') : null}
                    {ownershipPercentage ? ' (ownership: ' + ownershipPercentage + '%)' : ''}
                    {hasMissingPepInfo(person) ? (
                        <MissingKycInfoTag>
                            <Tag color="orange">Missing Pep Info</Tag>
                        </MissingKycInfoTag>
                    ) : null}
                    {latestManuallyApprovedPepCheck ? (
                        <Tooltip
                            title={`Manually approved PEP check from ${latestManuallyApprovedPepCheck.providerId} on ${formatTimestamp(latestManuallyApprovedPepCheck.updatedAt)}`}
                        >
                            <MissingKycInfoTag>
                                <Tag color="green">PEP</Tag>
                            </MissingKycInfoTag>
                        </Tooltip>
                    ) : null}
                </span>
                <GroupedActions>
                    <Button data-testid="search-person" size="small" onClick={searchSimilarPeople}>
                        Search Person
                    </Button>
                    <Button
                        data-testid="toggle-scope"
                        size="small"
                        danger={isInScope}
                        onClick={toggleScope}
                    >
                        {isInScope ? 'Remove from scope' : 'Add to scope'}
                    </Button>
                </GroupedActions>
            </PanelHeader>
            <PanelHeader onClick={(event: MouseEvent) => event.stopPropagation()}>
                <SearchSamePersonModal
                    person={person}
                    open={isSearchSamePersonModalOpen}
                    onCancel={() => setIsSearchSamePersonModalOpen(false)}
                />
            </PanelHeader>
        </>
    )
}

const MissingKycInfoTag = styled.div`
    display: inline-block;
    margin-left: ${spacing.space8};
`

const PanelHeader = styled.div`
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    gap: ${spacing.space8};
`

const GroupedActions = styled('div')`
    display: flex;
    align-items: center;
    vertical-align: middle;
    gap: 0.375rem;
`

const KycStatusContainer: FC<
    React.PropsWithChildren<{ person: Person; refetchPeople: () => void }>
> = ({ person, refetchPeople }) => {
    const { mutations } = useManagement()
    const { data: missingKycInformation } = useMissingInformation()
    const isVerified: boolean = person.verification?.result === VerificationResult.PASS
    const hasPassedRequiredKycChecks = getHasPassedRequiredKycChecks(
        {
            pepChecks: person.pepChecks,
            sisChecks: person.sisChecks,
            idvChecks: person.idvChecks,
            ekycChecks: person.ekycChecks,
        },
        isVerified
    )

    const addPersonToScope = async () => {
        try {
            await mutations.addToScope(person.id)
            refetchPeople()
        } catch (e) {
            message.error(`Failed to add to scope: ${(e as Error).message}`)
        }
    }

    const removePersonFromScope = async () => {
        try {
            await mutations.removeFromScope(person.id)
            refetchPeople()
        } catch (e) {
            message.error(`Failed to add to scope: ${(e as Error).message}`)
        }
    }

    return (
        <KycStatus
            person={person}
            addPersonToScope={addPersonToScope}
            removePersonFromScope={removePersonFromScope}
            isVerified={isVerified}
        >
            <StatusIcon
                isInScope={!!person.kycPerson}
                isMissingKycDetails={hasMissingKycInfo(person.id, missingKycInformation)}
                hasPassedRequiredKycChecks={hasPassedRequiredKycChecks}
            />
        </KycStatus>
    )
}

export default KycStatusContainer
