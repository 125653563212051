import {
    CheckCircleOutlined,
    CloseCircleOutlined,
    FileExclamationOutlined,
    HourglassOutlined,
    InboxOutlined,
    InfoCircleOutlined,
    SolutionOutlined,
} from '@ant-design/icons'
import type {
    CaseEventType,
    WaitingForCustomerReason,
    WaitingForCustomerSubReason,
} from 'bff/moons/generated/cdd-case-manager'
import type { ReactNode } from 'react'
import {
    WAITING_FOR_CUSTOMER_REASON_OPTIONS,
    WAITING_FOR_CUSTOMER_SUB_REASON_OPTIONS,
} from '../header/types'

export const mapEventType = (
    eventType: CaseEventType
): { color: string; dot?: React.ReactNode } => {
    const mapper: Record<CaseEventType, { color: string; dot?: ReactNode }> = {
        CASE_APPROVED: { color: 'green', dot: <CheckCircleOutlined /> },
        CASE_ASSIGNED_TO_ANALYST: { color: 'blue' },
        CASE_CREATED: { color: 'blue', dot: <InboxOutlined /> },
        CASE_MARKED_AS_IN_PROGRESS: { color: 'blue', dot: <SolutionOutlined /> },
        CASE_MARKED_AS_READY_FOR_FDD: { color: 'orange', dot: <FileExclamationOutlined /> },
        CASE_MARKED_AS_WAITING_FOR_CUSTOMER: { color: 'orange', dot: <HourglassOutlined /> },
        CASE_REJECTED: { color: 'red', dot: <CloseCircleOutlined /> },
        CHECK_COMPLETED: { color: 'green', dot: <CheckCircleOutlined /> },
        CHECK_REMOVED: { color: 'red' },
        CASE_MARKED_AS_INFORMATION_RECEIVED: { color: 'blue', dot: <InfoCircleOutlined /> },
        CASE_MARKED_AS_BLOCKED: { color: 'orange' },
        CASE_NOTE_UPDATED: { color: 'blue' },
    }

    return mapper[eventType]
}

export const prettifyEventType = (eventType: CaseEventType): string => {
    const mapper: Record<CaseEventType, string> = {
        CASE_APPROVED: 'Case approved',
        CASE_ASSIGNED_TO_ANALYST: 'Case assigned to analyst',
        CASE_CREATED: 'Case created',
        CASE_MARKED_AS_IN_PROGRESS: 'Case marked as In Progress',
        CASE_MARKED_AS_READY_FOR_FDD: 'Case marked as Ready for FDD',
        CASE_MARKED_AS_WAITING_FOR_CUSTOMER: 'Case marked as Waiting for Customer',
        CASE_REJECTED: 'Case rejected',
        CHECK_COMPLETED: 'Check completed',
        CHECK_REMOVED: 'Check removed',
        CASE_MARKED_AS_INFORMATION_RECEIVED: 'Case marked as Information Received',
        CASE_MARKED_AS_BLOCKED: 'Case in progress but cannot proceed',
        CASE_NOTE_UPDATED: 'Case Note updated',
    }

    return mapper[eventType]
}

export function getReasonLabel(reason: WaitingForCustomerReason): string {
    const reasonOption = WAITING_FOR_CUSTOMER_REASON_OPTIONS.find((r) => r.value === reason)
    if (!reasonOption) {
        return ''
    }
    return reasonOption.label
}

export function getSubReasonLabel(subReason: WaitingForCustomerSubReason): string {
    const subReasons = Object.values(WAITING_FOR_CUSTOMER_SUB_REASON_OPTIONS).flat()
    const reason = subReasons.find((r) => r.value === subReason)
    if (!reason) {
        return ''
    }

    return reason.label
}
