import { Collapse, Descriptions, List, Space, Tag, Typography } from 'antd'
import { formatDate } from 'packages/dates/dates'
import { ShopOutlined, UserOutlined } from '@ant-design/icons'

import { bff } from '../bff-hooks'
import { useKycDetailContext } from '../context'
import { Link } from 'react-router-dom'

const { Text } = Typography

export const Evidence = () => {
    const { caseId } = useKycDetailContext()
    const { data } = bff.kycHubEvidence.getCompany.useQuery({ caseId })
    const evidencePanels = getEvidencePanels(caseId, data?.companyId)
    return (
        <Collapse defaultActiveKey={'case-overview'} size="small" accordion>
            {evidencePanels.map((panel) => (
                <Collapse.Panel
                    header={
                        <Text
                            style={{
                                display: 'inline-block',
                                fontWeight: 600,
                                width: '100%',
                            }}
                        >
                            {panel.header}
                        </Text>
                    }
                    key={panel.key}
                    showArrow={false}
                    collapsible={panel.render ? 'header' : 'disabled'}
                >
                    {panel.render && panel.render()}
                </Collapse.Panel>
            ))}
        </Collapse>
    )
}

const getEvidencePanels = (caseId?: string, companyId?: string) => [
    {
        header: 'Case Overview',
        key: 'case-overview',
        render: () => {
            const { data } = bff.kycHubEvidence.getCaseOverview.useQuery({ caseId })
            return (
                <Space direction="vertical" style={{ width: '100%' }}>
                    <List
                        dataSource={data}
                        style={{ width: '100%' }}
                        header={
                            <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Text style={{ marginRight: '0.5rem', width: '30%' }} strong>
                                    Missing KYC Information
                                </Text>
                            </Space>
                        }
                        renderItem={(item) => (
                            <List.Item
                                style={{
                                    width: '100%',
                                }}
                            >
                                <div style={{ display: 'flex', width: '100%' }}>
                                    <Text style={{ marginRight: '0.5rem', width: '30%' }}>
                                        {item?.id === 'company' ? (
                                            <ShopOutlined style={{ color: '#fadb14' }} />
                                        ) : (
                                            <UserOutlined style={{ color: '#1677ff' }} />
                                        )}{' '}
                                        {item?.label}
                                    </Text>
                                    <Space
                                        style={{
                                            width: '70%',
                                            display: 'flex',
                                            flexFlow: 'row wrap',
                                        }}
                                    >
                                        {item?.missingInformation.map((info) => (
                                            <Tag key={info} color="orange">
                                                {info}
                                            </Tag>
                                        ))}
                                    </Space>
                                </div>
                            </List.Item>
                        )}
                    />
                </Space>
            )
        },
    },
    {
        header: 'Company',
        key: 'company',
        render: () => {
            const { data } = bff.kycHubEvidence.getCompany.useQuery({ caseId })
            return (
                <Descriptions column={2}>
                    <Descriptions.Item label="Registration Number">
                        {data?.registrationNumber}
                    </Descriptions.Item>
                    <Descriptions.Item label="Trading Name">{data?.tradingName}</Descriptions.Item>
                    <Descriptions.Item label="Domain">{data?.domainName}</Descriptions.Item>
                    <Descriptions.Item label="CACP">{data?.cacp}</Descriptions.Item>
                    <Descriptions.Item label="NACE">{data?.nace}</Descriptions.Item>
                    <Descriptions.Item label="Company Size">{data?.companySize}</Descriptions.Item>
                    <Descriptions.Item label="Incorporation Date">
                        {formatDate(data?.incorporationDate)}
                    </Descriptions.Item>
                    <Descriptions.Item label="Incorporation Type">
                        {data?.incorporationType}
                    </Descriptions.Item>
                </Descriptions>
            )
        },
    },
    {
        header: (
            <Link
                target="_blank"
                to={`/compliance/companies/${companyId}/stp-check`}
                style={{ width: '100%', display: 'inline-block' }}
            >
                STP Checks
            </Link>
        ),
        key: 'stp-checks',
    },
    {
        header: (
            <Link
                target="_blank"
                to={`/compliance/companies/${companyId}/compliance-info`}
                style={{ width: '100%', display: 'inline-block' }}
            >
                Compliance Information
            </Link>
        ),
        key: 'compliance-information',
    },
    {
        header: (
            <Link
                target="_blank"
                to={`/compliance/companies/${companyId}/documents`}
                style={{ width: '100%', display: 'inline-block' }}
            >
                Documents
            </Link>
        ),
        key: 'documents',
    },
    {
        header: (
            <Link
                target="_blank"
                to={`/compliance/companies/${companyId}/people`}
                style={{ width: '100%', display: 'inline-block' }}
            >
                People
            </Link>
        ),
        key: 'people',
    },
    {
        header: (
            <Link
                target="_blank"
                to={`/compliance/companies/${companyId}/people`}
                style={{ width: '100%', display: 'inline-block' }}
            >
                People
            </Link>
        ),
        key: 'people',
    },
    {
        header: (
            <Link
                target="_blank"
                to={`/compliance/companies/${companyId}/risk-assessment`}
                style={{ width: '100%', display: 'inline-block' }}
            >
                Risk Assessment
            </Link>
        ),
        key: 'risk-assessment',
    },
    {
        header: (
            <Link
                target="_blank"
                to={`/compliance/companies/${companyId}/ownership`}
                style={{ width: '100%', display: 'inline-block' }}
            >
                Ownership Structure
            </Link>
        ),
        key: 'ownership-structure',
    },
    {
        header: (
            <Link
                target="_blank"
                to={`/compliance/companies/${companyId}/source-of-funds`}
                style={{ width: '100%', display: 'inline-block' }}
            >
                Sources of Funds
            </Link>
        ),
        key: 'source-of-funds',
    },
    {
        header: (
            <Link
                target="_blank"
                to={`/compliance/companies/${companyId}/entitlements`}
                style={{ width: '100%', display: 'inline-block' }}
            >
                Entitlements
            </Link>
        ),
        key: 'entitlements',
    },
]
