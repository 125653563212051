import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Button, Modal, Row, Space, Table, Typography, message } from 'antd'
import Spinner from 'components/content-spinner'
import { ActionsContainer, ContentContainer } from 'components/layout-containers'
import dayjs from 'packages/dayjs'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { spacing } from 'theme/tokens'
import { bff } from '../bff'
import { AddReferral } from './add-referral'
const { Link, Text } = Typography
const { confirm } = Modal

export const PartnerReferrals = () => {
    const navigate = useNavigate()
    const { id } = useParams()
    const { data, isLoading } = bff.partnerReferrals.getPartnerReferrals.useQuery({ partnerId: id })
    const { mutateAsync: deletePartnerReferral } =
        bff.partnerReferrals.deletePartnerReferral.useMutation({
            onSuccess: () => {
                message.success(`Partner referral has been successfully deleted.`)
            },
            onError: (error) => {
                message.error(`Error: ${error.message}`)
            },
        })

    const goToCompany = (companyId: string) => navigate(`/customer-success/companies/${companyId}`)

    return (
        <ContentContainer>
            <Space direction="vertical" size="middle">
                <Row justify="end">
                    <AddReferral />
                </Row>
                {isLoading ? (
                    <Spinner />
                ) : (
                    <Table
                        loading={false}
                        rowKey="id"
                        dataSource={data?.partnerReferrals || []}
                        columns={[
                            {
                                title: 'Status',
                                dataIndex: 'status',
                                render: (status) => <Text>{getStatusName(status)}</Text>,
                            },
                            {
                                title: 'Company ID',
                                dataIndex: 'companyId',
                                render: (companyId) => (
                                    <Text copyable={!!companyId}>{companyId || '-'}</Text>
                                ),
                            },
                            {
                                title: 'Company name',
                                dataIndex: 'companyName',
                                render: (companyName, { companyId }) =>
                                    companyId ? (
                                        <Link onClick={() => goToCompany(companyId)}>
                                            {companyName}
                                        </Link>
                                    ) : (
                                        <Text>{companyName}</Text>
                                    ),
                            },
                            {
                                title: 'Referral Date',
                                dataIndex: 'referredAt',
                                render: (referredAt) => (
                                    <Text>
                                        {referredAt
                                            ? dayjs(referredAt).format('MMMM DD, YYYY')
                                            : '-'}
                                    </Text>
                                ),
                            },
                            {
                                title: 'FDD Date',
                                dataIndex: 'fddDate',
                                render: (fddDate) => (
                                    <Text>
                                        {fddDate ? dayjs(fddDate).format('MMMM DD, YYYY') : '-'}
                                    </Text>
                                ),
                            },
                            {
                                title: 'Notes',
                                dataIndex: 'notes',
                                render: (notes) => (
                                    <Notes>
                                        {notes.map((note: string) => (
                                            <Text key={note}>{note}</Text>
                                        ))}
                                    </Notes>
                                ),
                            },
                            {
                                title: 'Action',
                                dataIndex: 'companyId',
                                render: (companyId, { companyName }) => (
                                    <ActionsContainer>
                                        <Button
                                            type="link"
                                            danger
                                            data-testid="disconnect"
                                            onClick={() => {
                                                confirm({
                                                    icon: <ExclamationCircleOutlined />,
                                                    title: 'Are you sure?',
                                                    content: `This action will remove ${companyName} from the partner referrals.`,
                                                    okText: 'Delete referral',
                                                    okType: 'danger',
                                                    async onOk() {
                                                        await deletePartnerReferral({
                                                            partnerId: id,
                                                            companyId,
                                                        })
                                                    },
                                                    width: 500,
                                                })
                                            }}
                                        >
                                            Delete
                                        </Button>
                                    </ActionsContainer>
                                ),
                            },
                        ]}
                    />
                )}
            </Space>
        </ContentContainer>
    )
}

function getStatusName(status: string) {
    switch (status) {
        case 'draft':
            return 'Draft'
        case 'pending':
            return 'Pending'
        case 'active':
            return 'Active'
        case 'expired':
            return 'Expired'
        default:
            return 'Unknown'
    }
}

const Notes = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${spacing.space8};
`
