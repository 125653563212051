/**
 * Generated by orval v6.15.0 🍺
 * Do not edit manually.
 * Kale API
 * Kale OpenAPI definitions
 * OpenAPI spec version: 17.4.0
 */
import { moonRequest as _moonRequest } from '@pleo-io/bff-moon-clients'

const moonRequest = _moonRequest('kale')

export type GetReserveApplicationsParams = {
    state?: ApplicationState
}

export type GetOverdraftApplicationsParams = {
    state?: ApplicationState
}

export type ReprocessReserveApplicationsParams = {
    dry_run?: boolean
}

export type ReprocessOverdraftApplicationsParams = {
    dry_run?: boolean
}

export type FullCheckParams = {
    company_id?: string
}

export type ValidationResultOutcome =
    (typeof ValidationResultOutcome)[keyof typeof ValidationResultOutcome]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ValidationResultOutcome = {
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
} as const

export type ValidationResultMetadata = { [key: string]: string }

export type ValidationResultErrorTypesItem =
    (typeof ValidationResultErrorTypesItem)[keyof typeof ValidationResultErrorTypesItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ValidationResultErrorTypesItem = {
    INELIGIBLE: 'INELIGIBLE',
    RESERVE_ACTIVE: 'RESERVE_ACTIVE',
    OVERDRAFT_ACTIVE: 'OVERDRAFT_ACTIVE',
    DIRECT_DEBIT_AGREEMENT_MISSING: 'DIRECT_DEBIT_AGREEMENT_MISSING',
    DIRECT_DEBIT_AGREEMENT_NOT_ACTIVE: 'DIRECT_DEBIT_AGREEMENT_NOT_ACTIVE',
    REGION_MISSING: 'REGION_MISSING',
    INVALID_AMOUNT_REQUESTED: 'INVALID_AMOUNT_REQUESTED',
    NEGATIVE_BALANCE: 'NEGATIVE_BALANCE',
} as const

export interface ValidationResult {
    errorTypes: ValidationResultErrorTypesItem[]
    isError: boolean
    messages: string[]
    metadata: ValidationResultMetadata
    outcome: ValidationResultOutcome
    time: string
}

export type UnderwritingType = (typeof UnderwritingType)[keyof typeof UnderwritingType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UnderwritingType = {
    OVERDRAFT_APPLICATION: 'OVERDRAFT_APPLICATION',
    HIGHER_LIMIT_APPLICATION: 'HIGHER_LIMIT_APPLICATION',
} as const

export interface UnderwritingInfoRequiredRequest {
    comment: string
}

export interface UnderwritingApprovalRequest {
    approvedLimit: number
}

export type UnderwritingApplicationFullDtoProductName =
    (typeof UnderwritingApplicationFullDtoProductName)[keyof typeof UnderwritingApplicationFullDtoProductName]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UnderwritingApplicationFullDtoProductName = {
    RESERVE: 'RESERVE',
    OVERDRAFT: 'OVERDRAFT',
} as const

export type UnderwritingApplicationDtoProductName =
    (typeof UnderwritingApplicationDtoProductName)[keyof typeof UnderwritingApplicationDtoProductName]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UnderwritingApplicationDtoProductName = {
    RESERVE: 'RESERVE',
    OVERDRAFT: 'OVERDRAFT',
} as const

export interface UnderwritingApplicationDto {
    approvedLimit?: Amount
    companyId: string
    createdAt: string
    currentComment?: CommentDto
    currentState: ApplicationState
    expirationDate?: string
    id: string
    productName: UnderwritingApplicationDtoProductName
    requestedLimit: Amount
    type: UnderwritingType
    updatedAt: string
}

export type UnderwritingApplicationDocumentType =
    (typeof UnderwritingApplicationDocumentType)[keyof typeof UnderwritingApplicationDocumentType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UnderwritingApplicationDocumentType = {
    BANK_STATEMENT: 'BANK_STATEMENT',
    FINANCIAL_STATEMENT: 'FINANCIAL_STATEMENT',
} as const

export interface SubmitUnderwritingApplicationRequest {
    amount: Amount
    type: UnderwritingType
}

export interface StateChangeFullDto {
    createdAt: string
    id: string
    isCustomer: boolean
    state: ApplicationState
    userId: string
}

export interface UnderwritingApplicationFullDto {
    approvedBy?: string
    approvedLimit?: Amount
    comments: CommentFullDto[]
    companyId: string
    createdAt: string
    currentState: ApplicationState
    documents: DocumentFullDto[]
    expirationDate?: string
    id: string
    productName: UnderwritingApplicationFullDtoProductName
    requestedLimit: Amount
    stateTrail: StateChangeFullDto[]
    type: UnderwritingType
    updatedAt: string
}

export type ReserveReprocessResponseObjResults = { [key: string]: ValidationResult }

export interface ReserveReprocessResponseObj {
    changesApplied: boolean
    results: ReserveReprocessResponseObjResults
}

export interface ReserveReprocessRequestObj {
    companyIds?: string[]
}

export interface RejectApplicationRequest {
    rejectReason: string
}

export interface ProductLimit {
    currency: string
    limit: number
}

export interface PleoReserveDeactivationRequest {
    reason: string
}

export interface PleoReserveApplicationStatusResponse {
    status: ApplicationState
}

export interface PleoReserveApplicationRequest {
    autoTopUpAmount: number
    autoTopUpThreshold: number
    requestedAmount?: number
}

export interface PleoReserveActivationRequest {
    reason: string
}

export interface OverrideProductAmountRequest {
    amount: number
    updateReason: string
}

export interface OverdraftWaitlistResponse {
    id: string
    position: number
}

export type OverdraftReprocessResponseObjResults = { [key: string]: ValidationResult }

export interface OverdraftReprocessResponseObj {
    changesApplied: boolean
    results: OverdraftReprocessResponseObjResults
}

export interface OverdraftReprocessRequestObj {
    companyIds?: string[]
}

export interface OverdraftRenewalApplicationRequest {
    principalAmount: Amount
}

export interface OverdraftApplicationRequest {
    autoRepayment: boolean
    principalAmount: number
}

export type OverdraftApplicationValidationOutcome =
    (typeof OverdraftApplicationValidationOutcome)[keyof typeof OverdraftApplicationValidationOutcome]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OverdraftApplicationValidationOutcome = {
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
} as const

export type OverdraftApplicationBillingType =
    (typeof OverdraftApplicationBillingType)[keyof typeof OverdraftApplicationBillingType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OverdraftApplicationBillingType = {
    END_OF_MONTH: 'END_OF_MONTH',
    CUSTOM: 'CUSTOM',
} as const

export interface OverdraftApplication {
    activationFeePercent: number
    amountRequested: number
    autoRepayment: boolean
    billingDay?: number
    billingType: OverdraftApplicationBillingType
    companyId: string
    createdAt: string
    createdBy: string
    currency: string
    customerCreated: boolean
    id: string
    interestRate: number
    region: string
    status: ApplicationState
    updatedAt: string
    validationOutcome?: OverdraftApplicationValidationOutcome
}

export interface LimitChangeApplicationRequest {
    accountId: string
    amount: Amount
}

export type LimitChangeApplicationDtoProductName =
    (typeof LimitChangeApplicationDtoProductName)[keyof typeof LimitChangeApplicationDtoProductName]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LimitChangeApplicationDtoProductName = {
    RESERVE: 'RESERVE',
    OVERDRAFT: 'OVERDRAFT',
} as const

export interface LimitChangeApplicationDto {
    accountId: string
    id: string
    productName: LimitChangeApplicationDtoProductName
    requestedAmount: number
    status: ApplicationState
}

export interface KaleApiResponseReserveReprocessResponseObj {
    data: ReserveReprocessResponseObj
}

export interface KaleApiResponseOverdraftReprocessResponseObj {
    data: OverdraftReprocessResponseObj
}

export interface KaleApiResponseApplicationResponse {
    data: ApplicationResponse
}

export interface HigherLimitInfoRequiredRequest {
    comment: string
}

export interface HigherLimitApprovalRequest {
    approvedLimit: number
}

export interface HigherLimitApplicationRequest {
    amount: Amount
}

export type HealthcheckResultErrorStackTraceItem = {
    classLoaderName?: string
    className?: string
    fileName?: string
    isNativeMethod?: boolean
    lineNumber?: number
    methodName?: string
    moduleName?: string
    moduleVersion?: string
}

export type HealthcheckResultError = {
    localizedMessage?: string
    message?: string
    stackTrace?: HealthcheckResultErrorStackTraceItem[]
}

export type HealthcheckResultDetails = { [key: string]: { [key: string]: any } }

export interface HealthcheckResult {
    details?: HealthcheckResultDetails
    error?: HealthcheckResultError
    healthy: boolean
    message?: string
    time: string
}

export interface DocumentWithLinkDto {
    createdAt: string
    documentKey: string
    fileExt: string
    fileName: string
    id: string
    s3Url: string
    type: UnderwritingApplicationDocumentType
}

export interface DocumentFullDto {
    createdAt: string
    fileExt: string
    fileName: string
    id: string
    type: UnderwritingApplicationDocumentType
}

export interface DocumentCreationRequestDto {
    documentKey: string
    fileExt: string
    fileName: string
    type: UnderwritingApplicationDocumentType
}

export interface DataResponseUnderwritingApplicationDto {
    data: UnderwritingApplicationDto
}

export interface DataResponseListUnderwritingApplicationFullDto {
    data: UnderwritingApplicationFullDto[]
}

export interface DataResponseListDocumentWithLinkDto {
    data: DocumentWithLinkDto[]
}

export interface DataResponseDocumentWithLinkDto {
    data: DocumentWithLinkDto
}

export interface CommentFullDto {
    content: string
    createdAt: string
    id: string
    isCustomer: boolean
    userId: string
}

export interface CommentDto {
    content: string
    createdAt: string
    id: string
    isCustomer: boolean
}

export interface AtuValidationRequest {
    autoTopUpAmount: number
    autoTopUpThreshold: number
    requestedAmount?: number
}

export type ApplicationState = (typeof ApplicationState)[keyof typeof ApplicationState]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ApplicationState = {
    REJECTED: 'REJECTED',
    ERROR: 'ERROR',
    APPROVED: 'APPROVED',
    APPROVED_PENDING_ELIGIBILITY: 'APPROVED_PENDING_ELIGIBILITY',
    PENDING: 'PENDING',
    PROCESSING: 'PROCESSING',
    INFO_REQUIRED: 'INFO_REQUIRED',
} as const

export type ApplicationResponseErrorTypesItem =
    (typeof ApplicationResponseErrorTypesItem)[keyof typeof ApplicationResponseErrorTypesItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ApplicationResponseErrorTypesItem = {
    INELIGIBLE: 'INELIGIBLE',
    RESERVE_ACTIVE: 'RESERVE_ACTIVE',
    OVERDRAFT_ACTIVE: 'OVERDRAFT_ACTIVE',
    DIRECT_DEBIT_AGREEMENT_MISSING: 'DIRECT_DEBIT_AGREEMENT_MISSING',
    INVALID_AMOUNT_REQUESTED: 'INVALID_AMOUNT_REQUESTED',
    INSUFFICIENT_FUNDS: 'INSUFFICIENT_FUNDS',
    OTHER: 'OTHER',
} as const

export interface ApplicationResponse {
    errorTypes: ApplicationResponseErrorTypesItem[]
    id: string
    status: ApplicationState
}

export type ApplicationValidationOutcome =
    (typeof ApplicationValidationOutcome)[keyof typeof ApplicationValidationOutcome]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ApplicationValidationOutcome = {
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
} as const

export interface Application {
    amountRequested: number
    companyId: string
    createdAt: string
    createdBy: string
    currency: string
    id: string
    region: string
    status: ApplicationState
    validationMessages: string
    validationOutcome: ApplicationValidationOutcome
}

export interface Amount {
    currency: string
    value: number
}

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
    config: any,
    args: infer P
) => any
    ? P
    : never

/**
 * @summary Returns the health of the service
 */
export const check = (options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<HealthcheckResult>({ url: `/health`, method: 'get' }, options)
}

/**
 * @summary Returns the health of the service
 */
export const fullCheck = (
    params: FullCheckParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<HealthcheckResult>({ url: `/health/full`, method: 'get', params }, options)
}

/**
 * @summary Reject application
 */
export const rejectApplication = (
    applicationId: string,
    rejectApplicationRequest: RejectApplicationRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        {
            url: `/v1/applications/${applicationId}/reject`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: rejectApplicationRequest,
        },
        options
    )
}

/**
 * @summary Product application
 */
export const submitApplication = (
    companyId: string,
    pleoReserveApplicationRequest: PleoReserveApplicationRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<ApplicationResponse>(
        {
            url: `/v1/companies/${companyId}/apply`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: pleoReserveApplicationRequest,
        },
        options
    )
}

/**
 * @summary Latest product application status
 */
export const getLatestApplicationStatus = (
    companyId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<PleoReserveApplicationStatusResponse>(
        { url: `/v1/companies/${companyId}/apply/status`, method: 'get' },
        options
    )
}

/**
 * @summary Product application without atu changes
 */
export const applyWithoutAtuChanges = (
    companyId: string,
    pleoReserveActivationRequest: PleoReserveActivationRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<KaleApiResponseApplicationResponse>(
        {
            url: `/v1/companies/${companyId}/apply/without-atu-changes`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: pleoReserveActivationRequest,
        },
        options
    )
}

/**
 * @summary Deactivate product
 */
export const deactivate = (
    companyId: string,
    pleoReserveDeactivationRequest: PleoReserveDeactivationRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        {
            url: `/v1/companies/${companyId}/deactivate`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: pleoReserveDeactivationRequest,
        },
        options
    )
}

/**
 * @summary Pre validate product deactivation
 */
export const validate = (companyId: string, options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<ValidationResult>(
        { url: `/v1/companies/${companyId}/deactivate/validate`, method: 'post' },
        options
    )
}

/**
 * @summary Override product amount
 */
export const overrideProductAmount = (
    companyId: string,
    overrideProductAmountRequest: OverrideProductAmountRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        {
            url: `/v1/companies/${companyId}/override-product-amount`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: overrideProductAmountRequest,
        },
        options
    )
}

/**
 * @summary Pre validate product application
 */
export const validate1 = (
    companyId: string,
    atuValidationRequest: AtuValidationRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<ValidationResult>(
        {
            url: `/v1/companies/${companyId}/validate`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: atuValidationRequest,
        },
        options
    )
}

/**
 * @summary Pre validate product application
 */
export const validateAtuAmounts = (
    companyId: string,
    atuValidationRequest: AtuValidationRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<ValidationResult>(
        {
            url: `/v1/companies/${companyId}/validate/atu-amounts`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: atuValidationRequest,
        },
        options
    )
}

/**
 * @summary Pre validate product application
 */
export const runApplicationValidations = (
    companyId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<ValidationResult>(
        { url: `/v1/companies/${companyId}/validate/conditions`, method: 'get' },
        options
    )
}

/**
 * @summary Pre validate product application for minimum requirements
 */
export const runApplicationMinimumValidations = (
    companyId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<ValidationResult>(
        { url: `/v1/companies/${companyId}/validate/conditions/minimum`, method: 'get' },
        options
    )
}

/**
 * @summary Pre validate product application for minimum requirements
 */
export const validateMinimum = (
    companyId: string,
    atuValidationRequest: AtuValidationRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<ValidationResult>(
        {
            url: `/v1/companies/${companyId}/validate/minimum`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: atuValidationRequest,
        },
        options
    )
}

/**
 * @summary Get waitlist status
 */
export const getInterest = (companyId: string, options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<OverdraftWaitlistResponse>(
        { url: `/v1/companies/${companyId}/waitlists/overdraft`, method: 'get' },
        options
    )
}

/**
 * @summary Register waitlist interest
 */
export const registerInterest = (
    companyId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<OverdraftWaitlistResponse>(
        { url: `/v1/companies/${companyId}/waitlists/overdraft`, method: 'post' },
        options
    )
}

/**
 * @summary Manually approve overdraft application
 */
export const manuallyApproveOverdraftApplication = (
    companyId: string,
    applicationId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        {
            url: `/v1/internal/companies/${companyId}/overdraft/${applicationId}:approve`,
            method: 'post',
        },
        options
    )
}

/**
 * @summary Reprocess messages from dlq
 */
export const reProcessMessagesInDlq = (
    topic: 'CUPID_PAYER' | 'CUPID_WALLET_SETTINGS',
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        { url: `/v1/internal/dlq-reprocess/${topic}`, method: 'post' },
        options
    )
}

/**
 * @summary Manually publish entity to Kafka
 */
export const manuallyPublishEntity = (
    entityType: string,
    id: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        { url: `/v1/internal/kafka/${entityType}/${id}:publish`, method: 'post' },
        options
    )
}

/**
 * @summary Reject PENDING overdraft application
 */
export const rejectPendingOverdraftApplication = (
    applicationId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        {
            url: `/v1/internal/overdraft-applications/${applicationId}/:reject-pending`,
            method: 'post',
        },
        options
    )
}

/**
 * @summary Reject processing overdraft application
 */
export const rejectProcessingOverdraftApplication = (
    applicationId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        {
            url: `/v1/internal/overdraft-applications/${applicationId}/:reject-processing`,
            method: 'post',
        },
        options
    )
}

/**
 * @summary Reprocess Overdraft applications
 */
export const reprocessOverdraftApplications = (
    overdraftReprocessRequestObj: OverdraftReprocessRequestObj,
    params: ReprocessOverdraftApplicationsParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<KaleApiResponseOverdraftReprocessResponseObj>(
        {
            url: `/v1/internal/reprocess/overdraft/applications`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: overdraftReprocessRequestObj,
            params,
        },
        options
    )
}

/**
 * @summary Reprocess Reserve applications
 */
export const reprocessReserveApplications = (
    reserveReprocessRequestObj: ReserveReprocessRequestObj,
    params: ReprocessReserveApplicationsParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<KaleApiResponseReserveReprocessResponseObj>(
        {
            url: `/v1/internal/reprocess/reserve/applications`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: reserveReprocessRequestObj,
            params,
        },
        options
    )
}

/**
 * @summary Update product limit
 */
export const updateProductLimit = (
    productId: string,
    productLimit: ProductLimit,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<ProductLimit>(
        {
            url: `/v1/product/${productId}/limit`,
            method: 'put',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: productLimit,
        },
        options
    )
}

/**
 * @summary Submit application to change limit
 */
export const submitLimitChangeApplication = (
    companyId: string,
    limitChangeApplicationRequest: LimitChangeApplicationRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<LimitChangeApplicationDto>(
        {
            url: `/v2/companies/${companyId}/:change-limit`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: limitChangeApplicationRequest,
        },
        options
    )
}

/**
 * @summary Submit application to renew
 */
export const submitOverdraftRenewalApplication = (
    companyId: string,
    overdraftRenewalApplicationRequest: OverdraftRenewalApplicationRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<ApplicationResponse>(
        {
            url: `/v2/companies/${companyId}/:renew-overdraft`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: overdraftRenewalApplicationRequest,
        },
        options
    )
}

/**
 * @deprecated
 * @summary Get higher limit applications for company
 */
export const getFullHigherLimitApplications = (
    companyId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DataResponseListUnderwritingApplicationFullDto>(
        { url: `/v2/companies/${companyId}/higher-limit`, method: 'get' },
        options
    )
}

/**
 * @deprecated
 * @summary Submit application for higher limits
 */
export const submitHigherLimitApplication = (
    companyId: string,
    higherLimitApplicationRequest: HigherLimitApplicationRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DataResponseUnderwritingApplicationDto>(
        {
            url: `/v2/companies/${companyId}/higher-limit`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: higherLimitApplicationRequest,
        },
        options
    )
}

/**
 * @deprecated
 * @summary Get higher limit application document with download URL by document id
 */
export const getDocumentUrlById = (
    companyId: string,
    documentId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DataResponseDocumentWithLinkDto>(
        {
            url: `/v2/companies/${companyId}/higher-limit/documents/${documentId}:download`,
            method: 'post',
        },
        options
    )
}

/**
 * @deprecated
 * @summary Get latest higher limit application for company
 */
export const getLatestHigherLimitApplication = (
    companyId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DataResponseUnderwritingApplicationDto>(
        { url: `/v2/companies/${companyId}/higher-limit/latest`, method: 'get' },
        options
    )
}

/**
 * @deprecated
 * @summary Create new documents for higher limit application
 */
export const createDocumentsForApplication = (
    companyId: string,
    applicationId: string,
    documentCreationRequestDto: DocumentCreationRequestDto[],
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DataResponseListDocumentWithLinkDto>(
        {
            url: `/v2/companies/${companyId}/higher-limit/${applicationId}/documents`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: documentCreationRequestDto,
        },
        options
    )
}

/**
 * @deprecated
 * @summary Approve a higher limit application
 */
export const approveHigherLimitApplication = (
    companyId: string,
    applicationId: string,
    higherLimitApprovalRequest: HigherLimitApprovalRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DataResponseUnderwritingApplicationDto>(
        {
            url: `/v2/companies/${companyId}/higher-limit/${applicationId}:approve`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: higherLimitApprovalRequest,
        },
        options
    )
}

/**
 * @deprecated
 * @summary Set higher limit application to info-required
 */
export const infoRequiredHigherLimitApplication = (
    companyId: string,
    applicationId: string,
    higherLimitInfoRequiredRequest: HigherLimitInfoRequiredRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DataResponseUnderwritingApplicationDto>(
        {
            url: `/v2/companies/${companyId}/higher-limit/${applicationId}:info-required`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: higherLimitInfoRequiredRequest,
        },
        options
    )
}

/**
 * @deprecated
 * @summary Move higher limit application to pending
 */
export const moveHigherLimitApplicationToPending = (
    companyId: string,
    applicationId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DataResponseUnderwritingApplicationDto>(
        { url: `/v2/companies/${companyId}/higher-limit/${applicationId}:pending`, method: 'post' },
        options
    )
}

/**
 * @deprecated
 * @summary Reject a higher limit application
 */
export const rejectHigherLimitApplication = (
    companyId: string,
    applicationId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DataResponseUnderwritingApplicationDto>(
        { url: `/v2/companies/${companyId}/higher-limit/${applicationId}:reject`, method: 'post' },
        options
    )
}

/**
 * @summary Get overdraft applications
 */
export const getOverdraftApplications = (
    companyId: string,
    params: GetOverdraftApplicationsParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<OverdraftApplication[]>(
        { url: `/v2/companies/${companyId}/overdraft`, method: 'get', params },
        options
    )
}

/**
 * @summary Submit overdraft application
 */
export const submitOverdraftApplication = (
    companyId: string,
    overdraftApplicationRequest: OverdraftApplicationRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<ApplicationResponse>(
        {
            url: `/v2/companies/${companyId}/overdraft`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: overdraftApplicationRequest,
        },
        options
    )
}

/**
 * @summary Get reserve applications
 */
export const getReserveApplications = (
    companyId: string,
    params: GetReserveApplicationsParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<Application[]>(
        { url: `/v2/companies/${companyId}/reserve`, method: 'get', params },
        options
    )
}

/**
 * @summary Get underwriting applications for company
 */
export const getUnderwritingApplications = (
    companyId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DataResponseListUnderwritingApplicationFullDto>(
        { url: `/v2/companies/${companyId}/underwriting`, method: 'get' },
        options
    )
}

/**
 * @summary Submit application for underwriting
 */
export const submitUnderwritingApplication = (
    companyId: string,
    submitUnderwritingApplicationRequest: SubmitUnderwritingApplicationRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DataResponseUnderwritingApplicationDto>(
        {
            url: `/v2/companies/${companyId}/underwriting`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: submitUnderwritingApplicationRequest,
        },
        options
    )
}

/**
 * @summary Get underwriting application document with download URL by document id
 */
export const getUnderwritingDocumentUrlById = (
    companyId: string,
    documentId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DataResponseDocumentWithLinkDto>(
        {
            url: `/v2/companies/${companyId}/underwriting/documents/${documentId}:download`,
            method: 'post',
        },
        options
    )
}

/**
 * @summary Get latest underwriting application for company
 */
export const getLatestUnderwritingApplication = (
    companyId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DataResponseUnderwritingApplicationDto>(
        { url: `/v2/companies/${companyId}/underwriting/latest`, method: 'get' },
        options
    )
}

/**
 * @summary Create new documents for underwriting application
 */
export const createUnderwritingDocumentsForApplication = (
    companyId: string,
    applicationId: string,
    documentCreationRequestDto: DocumentCreationRequestDto[],
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DataResponseListDocumentWithLinkDto>(
        {
            url: `/v2/companies/${companyId}/underwriting/${applicationId}/documents`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: documentCreationRequestDto,
        },
        options
    )
}

/**
 * @summary Approve a underwriting application
 */
export const approveUnderwritingApplication = (
    companyId: string,
    applicationId: string,
    underwritingApprovalRequest: UnderwritingApprovalRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DataResponseUnderwritingApplicationDto>(
        {
            url: `/v2/companies/${companyId}/underwriting/${applicationId}:approve`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: underwritingApprovalRequest,
        },
        options
    )
}

/**
 * @summary Set underwriting application to info-required
 */
export const infoRequiredUnderwritingApplication = (
    companyId: string,
    applicationId: string,
    underwritingInfoRequiredRequest: UnderwritingInfoRequiredRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DataResponseUnderwritingApplicationDto>(
        {
            url: `/v2/companies/${companyId}/underwriting/${applicationId}:info-required`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: underwritingInfoRequiredRequest,
        },
        options
    )
}

/**
 * @summary Move underwriting application to pending
 */
export const moveUnderwritingApplicationToPending = (
    companyId: string,
    applicationId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DataResponseUnderwritingApplicationDto>(
        { url: `/v2/companies/${companyId}/underwriting/${applicationId}:pending`, method: 'post' },
        options
    )
}

/**
 * @summary Reject a underwriting application
 */
export const rejectUnderwritingApplication = (
    companyId: string,
    applicationId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DataResponseUnderwritingApplicationDto>(
        { url: `/v2/companies/${companyId}/underwriting/${applicationId}:reject`, method: 'post' },
        options
    )
}

export type CheckResult = NonNullable<Awaited<ReturnType<typeof check>>>
export type FullCheckResult = NonNullable<Awaited<ReturnType<typeof fullCheck>>>
export type RejectApplicationResult = NonNullable<Awaited<ReturnType<typeof rejectApplication>>>
export type SubmitApplicationResult = NonNullable<Awaited<ReturnType<typeof submitApplication>>>
export type GetLatestApplicationStatusResult = NonNullable<
    Awaited<ReturnType<typeof getLatestApplicationStatus>>
>
export type ApplyWithoutAtuChangesResult = NonNullable<
    Awaited<ReturnType<typeof applyWithoutAtuChanges>>
>
export type DeactivateResult = NonNullable<Awaited<ReturnType<typeof deactivate>>>
export type ValidateResult = NonNullable<Awaited<ReturnType<typeof validate>>>
export type OverrideProductAmountResult = NonNullable<
    Awaited<ReturnType<typeof overrideProductAmount>>
>
export type Validate1Result = NonNullable<Awaited<ReturnType<typeof validate1>>>
export type ValidateAtuAmountsResult = NonNullable<Awaited<ReturnType<typeof validateAtuAmounts>>>
export type RunApplicationValidationsResult = NonNullable<
    Awaited<ReturnType<typeof runApplicationValidations>>
>
export type RunApplicationMinimumValidationsResult = NonNullable<
    Awaited<ReturnType<typeof runApplicationMinimumValidations>>
>
export type ValidateMinimumResult = NonNullable<Awaited<ReturnType<typeof validateMinimum>>>
export type GetInterestResult = NonNullable<Awaited<ReturnType<typeof getInterest>>>
export type RegisterInterestResult = NonNullable<Awaited<ReturnType<typeof registerInterest>>>
export type ManuallyApproveOverdraftApplicationResult = NonNullable<
    Awaited<ReturnType<typeof manuallyApproveOverdraftApplication>>
>
export type ReProcessMessagesInDlqResult = NonNullable<
    Awaited<ReturnType<typeof reProcessMessagesInDlq>>
>
export type ManuallyPublishEntityResult = NonNullable<
    Awaited<ReturnType<typeof manuallyPublishEntity>>
>
export type RejectPendingOverdraftApplicationResult = NonNullable<
    Awaited<ReturnType<typeof rejectPendingOverdraftApplication>>
>
export type RejectProcessingOverdraftApplicationResult = NonNullable<
    Awaited<ReturnType<typeof rejectProcessingOverdraftApplication>>
>
export type ReprocessOverdraftApplicationsResult = NonNullable<
    Awaited<ReturnType<typeof reprocessOverdraftApplications>>
>
export type ReprocessReserveApplicationsResult = NonNullable<
    Awaited<ReturnType<typeof reprocessReserveApplications>>
>
export type UpdateProductLimitResult = NonNullable<Awaited<ReturnType<typeof updateProductLimit>>>
export type SubmitLimitChangeApplicationResult = NonNullable<
    Awaited<ReturnType<typeof submitLimitChangeApplication>>
>
export type SubmitOverdraftRenewalApplicationResult = NonNullable<
    Awaited<ReturnType<typeof submitOverdraftRenewalApplication>>
>
export type GetFullHigherLimitApplicationsResult = NonNullable<
    Awaited<ReturnType<typeof getFullHigherLimitApplications>>
>
export type SubmitHigherLimitApplicationResult = NonNullable<
    Awaited<ReturnType<typeof submitHigherLimitApplication>>
>
export type GetDocumentUrlByIdResult = NonNullable<Awaited<ReturnType<typeof getDocumentUrlById>>>
export type GetLatestHigherLimitApplicationResult = NonNullable<
    Awaited<ReturnType<typeof getLatestHigherLimitApplication>>
>
export type CreateDocumentsForApplicationResult = NonNullable<
    Awaited<ReturnType<typeof createDocumentsForApplication>>
>
export type ApproveHigherLimitApplicationResult = NonNullable<
    Awaited<ReturnType<typeof approveHigherLimitApplication>>
>
export type InfoRequiredHigherLimitApplicationResult = NonNullable<
    Awaited<ReturnType<typeof infoRequiredHigherLimitApplication>>
>
export type MoveHigherLimitApplicationToPendingResult = NonNullable<
    Awaited<ReturnType<typeof moveHigherLimitApplicationToPending>>
>
export type RejectHigherLimitApplicationResult = NonNullable<
    Awaited<ReturnType<typeof rejectHigherLimitApplication>>
>
export type GetOverdraftApplicationsResult = NonNullable<
    Awaited<ReturnType<typeof getOverdraftApplications>>
>
export type SubmitOverdraftApplicationResult = NonNullable<
    Awaited<ReturnType<typeof submitOverdraftApplication>>
>
export type GetReserveApplicationsResult = NonNullable<
    Awaited<ReturnType<typeof getReserveApplications>>
>
export type GetUnderwritingApplicationsResult = NonNullable<
    Awaited<ReturnType<typeof getUnderwritingApplications>>
>
export type SubmitUnderwritingApplicationResult = NonNullable<
    Awaited<ReturnType<typeof submitUnderwritingApplication>>
>
export type GetUnderwritingDocumentUrlByIdResult = NonNullable<
    Awaited<ReturnType<typeof getUnderwritingDocumentUrlById>>
>
export type GetLatestUnderwritingApplicationResult = NonNullable<
    Awaited<ReturnType<typeof getLatestUnderwritingApplication>>
>
export type CreateUnderwritingDocumentsForApplicationResult = NonNullable<
    Awaited<ReturnType<typeof createUnderwritingDocumentsForApplication>>
>
export type ApproveUnderwritingApplicationResult = NonNullable<
    Awaited<ReturnType<typeof approveUnderwritingApplication>>
>
export type InfoRequiredUnderwritingApplicationResult = NonNullable<
    Awaited<ReturnType<typeof infoRequiredUnderwritingApplication>>
>
export type MoveUnderwritingApplicationToPendingResult = NonNullable<
    Awaited<ReturnType<typeof moveUnderwritingApplicationToPending>>
>
export type RejectUnderwritingApplicationResult = NonNullable<
    Awaited<ReturnType<typeof rejectUnderwritingApplication>>
>
