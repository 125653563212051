import type { InvestigationDesiredOutputColumn } from 'bff/moons/generated/investigation-runner'

const SELECTED_OUTPUTS_KEY = 'selectedOutputs'

export interface SelectedOutputs {
    key: string
    checked: boolean
}

export function isInvestigationDesiredOutputColumn(
    output: any
): output is InvestigationDesiredOutputColumn {
    return (output as InvestigationDesiredOutputColumn).id !== undefined
}

export function saveSelectedOutputsToLocal(selectedOutputsParam: string[]): void {
    localStorage.setItem(SELECTED_OUTPUTS_KEY, JSON.stringify(selectedOutputsParam))
}
