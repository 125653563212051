import { CaseDtoState } from 'bff/moons/generated/cdd-case-manager'

export const INITIAL_OPTIONS: { label: string; value: CaseDtoState }[] = [
    { label: 'Select state', value: CaseDtoState.ReadyForFdd },
]

export const SELECTABLE_OPTIONS: { label: string; value: CaseDtoState }[] = [
    { label: 'In Progress', value: CaseDtoState.InProgress },
    { label: 'Waiting for Customer', value: CaseDtoState.WaitingForCustomer },
    { label: 'Information Received', value: CaseDtoState.InformationReceived },
]

export const FIXED_OPTIONS: { label: string; value: CaseDtoState }[] = [
    { label: 'Sign Up in Progress', value: CaseDtoState.New },
    { label: 'FDD Approved', value: CaseDtoState.FddApproved },
    { label: 'FDD Rejected', value: CaseDtoState.FddRejected },
]

export const ALL_OPTIONS = [...INITIAL_OPTIONS, ...SELECTABLE_OPTIONS, ...FIXED_OPTIONS]
