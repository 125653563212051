import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import 'utils/yup'
// Used by react-pdf@9
import 'core-js/proposals/promise-with-resolvers'

import { SessionProvider } from './providers/auth-context'
import { UserProvider } from 'providers/user-context'
import { store } from './store/store'
import { ErrorHandlerProvider } from './providers/error-handler'
import { useEnhancedGrafanaFaro } from 'providers/grafana'

import Router from './router/router'

import './index.css'
import { ThemeDecorator } from 'utils/decorators/theme-decorator'
import { StrictMode } from 'react'
import { BffProvider } from 'providers/bff-provider'
import config from './config'
import { initGrafanaFaro } from '@shared/core/grafana'

initGrafanaFaro({
    name: 'backoffice',
    url: config.grafana.url,
    release: config.version,
    environment: config.environment,
})

function LibrarySetup() {
    useEnhancedGrafanaFaro()
    return null
}

const App = () => (
    <StrictMode>
        <ErrorHandlerProvider requiresPlaceholderPage={true}>
            <SessionProvider>
                <UserProvider>
                    <BffProvider>
                        <LibrarySetup />
                        <Provider store={store}>
                            <ThemeDecorator>
                                <Router />
                            </ThemeDecorator>
                        </Provider>
                    </BffProvider>
                </UserProvider>
            </SessionProvider>
        </ErrorHandlerProvider>
    </StrictMode>
)

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(<App />)
