import { countries } from 'countries-list'
import { Select, SelectProps } from 'formik-antd'
import type { Alpha2Code, SupportedCountryResponse } from 'types/countries'
import { Space, Skeleton } from 'antd'
import { useSupportedCountries } from 'services/dionysus/supported-countries'

const { Option } = Select

const getCountry = (alpha2code: Alpha2Code) => countries[alpha2code as Alpha2Code]

type Props = SelectProps & {
    disabledCountries?: Alpha2Code[]
}

const SupportedCountrySelect = (props: Props) => {
    const { data: supportedCountries, isValidating } = useSupportedCountries()

    if (isValidating) {
        return <Skeleton />
    }

    const filteredCountries = supportedCountries?.filter(
        ({ countryCode }) => !props.disabledCountries?.includes(countryCode)
    )

    return (
        <Select {...props} optionFilterProp="label">
            {filteredCountries?.map(({ countryCode }: SupportedCountryResponse) => (
                <Option key={countryCode} value={countryCode} label={getCountry(countryCode).name}>
                    <Space>
                        {getCountry(countryCode).emoji}
                        {getCountry(countryCode).name}
                    </Space>
                </Option>
            ))}
        </Select>
    )
}

export default SupportedCountrySelect
