import { getUrlMethods, useFilterState } from 'packages/filters/use-filter-state'
import { Input } from 'antd'

const defaultValue = null
const paramName = 'companyId'
const { getUrlValue, setUrlValue } = getUrlMethods(paramName, defaultValue)

export function useCompanyIdFilterState() {
    return useFilterState(getUrlValue, setUrlValue, defaultValue)
}
type CompanyIdFilterProps = {
    filter: ReturnType<typeof useCompanyIdFilterState>
    onChange: () => void
}

export function CompanyIdFilter({ filter, onChange }: CompanyIdFilterProps) {
    const handleChange = (e: any) => {
        filter.directApply(e.target.value)
        onChange()
    }

    return (
        <Input
            style={{ width: '100%' }}
            placeholder="Paste full Company ID"
            value={filter.interimValue || undefined}
            onChange={handleChange}
            allowClear
        />
    )
}
