import { useState } from 'react'
import { Card, Spin } from 'antd'
import type { InvestigationContext } from 'bff/moons/generated/investigation-runner'

import ContextSection from './context-section'
import { bff } from './../bff'

const Home = () => {
    const { data, error, isLoading } = bff.investigationContexts.getContexts.useQuery()

    const getFavoritesFromLocal = () => localStorage.getItem('investigationContextFavorites')

    const [favoriteContextIds, setFavoriteContextIds] = useState<string[]>(
        getFavoritesFromLocal() ? JSON.parse(getFavoritesFromLocal() || '[]') : []
    )

    if (isLoading) {
        return <Spin size="large" style={{ width: '100%', paddingTop: '25%' }} />
    }

    if (error || !data) {
        return <div>Error loading contexts</div>
    }

    const hasFavorites = favoriteContextIds.length > 0

    const getFavorites = () => {
        return data.data.filter((context) => favoriteContextIds.includes(context.id))
    }

    function removeFavorite(id: string | undefined) {
        if (getFavoritesFromLocal() && id) {
            const favorites = JSON.parse(getFavoritesFromLocal() || '[]')
            const newFavorites = favorites.filter(
                (favorite: InvestigationContext) => favorite.id === id
            )
            localStorage.setItem('investigationContextFavorites', JSON.stringify(newFavorites))
            setFavoriteContextIds(newFavorites)
        }
    }

    function addFavorite(id: string | undefined) {
        if (getFavoritesFromLocal() && id) {
            const favorites = JSON.parse(getFavoritesFromLocal() || '[]')
            const newFavorites = favorites.concat(id)
            localStorage.setItem('investigationContextFavorites', JSON.stringify(newFavorites))
            setFavoriteContextIds(newFavorites)
        } else if (id) {
            localStorage.setItem('investigationContextFavorites', JSON.stringify([id]))
            setFavoriteContextIds([id])
        }
    }

    return (
        <>
            <Card>
                {hasFavorites && (
                    <ContextSection
                        title="Favorite Contexts"
                        contexts={getFavorites()}
                        isFavorite
                        removeFavorite={removeFavorite}
                        addFavorite={addFavorite}
                    />
                )}
                <ContextSection
                    title="Default Contexts"
                    contexts={data.data}
                    removeFavorite={removeFavorite}
                    addFavorite={addFavorite}
                />
            </Card>
        </>
    )
}

export default Home
