import { InfoCircleOutlined } from '@ant-design/icons'
import { Card, Tooltip, Typography } from 'antd'

import styled from 'styled-components'

import type { FC } from 'react'
import { bff } from '../bff'

const { Text } = Typography

const StatusWrapper = styled.div`
    width: 100%;
    padding: 16px 24px;
`

const Title = styled.div`
    font-size: 14px;
    /* stylelint-disable-next-line declaration-property-value-allowed-list */
    font-weight: 600;
`

const Aligned = styled.div`
    justify-self: 'flex-start';
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 6px;
`

export const CardLimitPermissionsStatus: FC<{ companyId: string; organizationId?: string }> = ({
    companyId,
}) => {
    const { data, isLoading } =
        bff.cardLimitPermissionsRouter.getCardLimitPermissionStatus.useQuery({
            companyId,
        })
    return (
        <Card
            type="inner"
            title={
                <Tooltip title="When enabled, both reviewers and admins can set and change card limits (reviewers only for their team). When disabled, only admins can set and change card limits.">
                    <Aligned>
                        <Title>Team Reviewer Permissions</Title>
                        <InfoCircleOutlined />
                    </Aligned>
                </Tooltip>
            }
            loading={isLoading}
            bodyStyle={{ padding: 0 }}
            style={{ marginBottom: '24px' }}
        >
            <StatusWrapper>
                <Text strong>Change limits: </Text>

                <Text>{data ? 'Enabled 🟢' : 'Disabled 🔴'}</Text>
            </StatusWrapper>
        </Card>
    )
}
