import { DownOutlined, HistoryOutlined } from '@ant-design/icons'
import { Button, Dropdown, Space } from 'antd'
import { bff } from '../../bff-hooks'
import { useKycDetailContext } from '../../context'
import { CaseStateSelect } from './case-state-select'

export const ActionBar = () => {
    return (
        <Space.Compact>
            <CaseStateSelect />
            <AssigneeSelect />
            <HistoryButton />
        </Space.Compact>
    )
}

const AssigneeSelect = () => {
    const { caseId } = useKycDetailContext()
    const { data, isLoading } = bff.actionBar.getCaseState.useQuery({ caseId })
    const { mutateAsync: assignToCurrentUser } = bff.actionBar.assignCaseToCurrentUser.useMutation()

    const disabled = data?.isEditable === false || isLoading

    return (
        <Dropdown
            menu={{
                items: [
                    {
                        key: 'assign',
                        label: 'Assign to me',
                        onClick: async () => {
                            await assignToCurrentUser({ caseId })
                        },
                    },
                ],
            }}
            disabled={disabled}
        >
            <Button>
                <Space>
                    Assign
                    <DownOutlined />
                </Space>
            </Button>
        </Dropdown>
    )
}

const HistoryButton = () => {
    const {
        historyModal: { open },
    } = useKycDetailContext()
    return (
        <Button onClick={open} icon={<HistoryOutlined />}>
            History
        </Button>
    )
}
