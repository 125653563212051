import { Button, Card, Checkbox, Space, Typography } from 'antd'
import dayjs from 'dayjs'
import { useOddDetailContext } from './context-detail'
import { bff } from './bff-hooks'
import type { CheckboxChangeEvent } from 'antd/es/checkbox'
import { EditOutlined } from '@ant-design/icons'

const { Text } = Typography

const detectUrl = (notes: string) => {
    if (notes && notes.includes('https')) {
        if (notes.split(' ').length === 1) {
            return <a href={notes}>{notes}</a>
        } else {
            const wordArray = notes.split(' ')

            const finalComponent: any = []

            wordArray.map((word: string, index: number) => {
                if (word.includes('https')) {
                    finalComponent.push(
                        <>
                            <a key={index} href={word} rel="noreferrer noopener" target="_blank">
                                {word}
                            </a>{' '}
                        </>
                    )
                } else {
                    finalComponent.push(
                        <>
                            <Text key={index}>{word} </Text>{' '}
                        </>
                    )
                }
            })

            return <div>{finalComponent}</div>
        }
    } else return <Text>{notes}</Text>
}

const Notes = () => {
    const { caseId, notesModal, isNotesDisabled } = useOddDetailContext()
    const { data, isLoading } = bff.notes.getNotesData.useQuery({ caseId })
    const { data: notesTimeline } = bff.notes.getNotesTimeline.useQuery({ caseId })

    const { mutateAsync: updateCaseBlockedStatus } = bff.notes.updateCaseBlockedStatus.useMutation()

    const handleBlock = async (e: CheckboxChangeEvent) => {
        await updateCaseBlockedStatus({ caseId, isBlocked: e.target.checked })
    }

    return (
        <Card
            title="Notes"
            style={{ position: 'sticky', top: 16 }}
            styles={{ body: { padding: '8px 24px' } }}
            extra={[
                <Button onClick={notesModal.open} key="add-edit-notes" disabled={isNotesDisabled}>
                    {!data?.notes ? 'Add' : <EditOutlined />}
                </Button>,
            ]}
            loading={isLoading}
            actions={[
                <Checkbox
                    key={'blocked-checkbox'}
                    onChange={handleBlock}
                    checked={data?.isBlocked}
                    style={{ width: '100%', margin: '0 24px' }}
                >
                    Case cannot proceed
                </Checkbox>,
            ]}
        >
            <Space direction="vertical">
                {data?.notes && <div>{detectUrl(data?.notes)}</div>}
                {notesTimeline?.length && notesTimeline[0] ? (
                    <div key={notesTimeline[0].id}>
                        <blockquote style={{ fontSize: 12, color: 'darkgrey' }}>
                            {`${notesTimeline[0].by.firstName} ${notesTimeline[0].by.lastName}, ${dayjs(notesTimeline[0].by.at).format('lll')}`}
                        </blockquote>
                    </div>
                ) : null}
            </Space>
        </Card>
    )
}

export default Notes
