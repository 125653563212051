import { useState } from 'react'
// import { useCards } from 'services/deimos/cards'
import type { FormValues as StatusFormValues } from 'components/card-status-form'
import { CurrentCardInfo, Status } from 'types/card'
import { notifyCardAccess, notifyStatusChange } from './utils'
import { setCardAccess } from '../../../services/deimos/employees'
import { Modal } from 'antd'
import type { EmployeeCards } from '../../index.bff'
import { bff } from '../../bff-hooks'

export const useCardsUtils = (companyId?: string | null, employeeId?: string) => {
    // TODO (SUBS-951): Implement the following functions inside bff  when the endpoints are ready.
    //  Cards endpoint is not allowing non-admin users to update card status and there is no employee endpoint to update card access
    // const {
    //     mutations: { putCard },
    // } = useCards(companyId, employeeId)
    const { mutateAsync: updateStatus } =
        bff.components.employeeCards.updateCardStatus.useMutation()

    const [changeStatusModalActive, setChangeStatusModalActive] = useState(false)
    const [changeVelocityModalActive, setChangeVelocityModalActive] = useState(false)
    const [currentCardInfo, setCurrentCardInfo] = useState<CurrentCardInfo>({
        id: '',
        status: Status.LIVE,
    })

    const onChangeStatus = (card: EmployeeCards) => {
        const cardInfo: CurrentCardInfo = {
            id: card.id,
            status: card.status,
        }
        setCurrentCardInfo(cardInfo)
        setChangeStatusModalActive(true)
    }

    const onChangeStatusSubmit = async (values: StatusFormValues) => {
        const params = {
            cardId: currentCardInfo.id,
            status: values.status as Status,
        }

        try {
            await updateStatus(params)
            notifyStatusChange('status', true, values.status)
        } catch (e) {
            notifyStatusChange('status', false, (e as Error).message)
        }
        setChangeStatusModalActive(false)
    }

    const onChangeVelocity = (card: EmployeeCards) => {
        const cardInfo: CurrentCardInfo = {
            id: card.id,
            status: card.status,
        }
        setCurrentCardInfo(cardInfo)
        setChangeVelocityModalActive(true)
    }

    const onCardAccess = () => {
        Modal.confirm({
            onOk: async () => {
                try {
                    await setCardAccess(employeeId)
                    notifyCardAccess(true)
                } catch (e) {
                    notifyCardAccess(false, (e as Error).message)
                }
            },
            centered: true,
            title: 'Are you sure you want to enable card access for this user?',
            okButtonProps: {
                disabled: false,
            },
            okText: 'Yes',
        })
    }

    return {
        changeStatusModalActive,
        changeVelocityModalActive,
        currentCardInfo,
        setChangeStatusModalActive,
        setChangeVelocityModalActive,
        onChangeStatus,
        onChangeStatusSubmit,
        onChangeVelocity,
        onCardAccess,
    }
}
