import { Card, Col, Row, Typography } from 'antd'
import { CountryFilter, useCountryFilterState } from './country-filter'
import { AssigneeFilter, useAssigneeFilterState } from './assignee-filter'
import { CreatedAtFrom, useCreatedAtFromState } from './created-at-from-filter'
import { useCreatedAtToState } from './created-at-to'
import { UpdatedAtTo, useUpdatedAtToState } from './updated-at-to'
import { UpdatedAtFrom, useUpdatedAtFromState } from './updated-at-from'
import { usePagination } from '../data-table/use-pagination'
import { CompanyIdFilter, useCompanyIdFilterState } from './company-id-filter'

export const useFilters = () => {
    const { clear: clearPagination } = usePagination()

    const handleFilterChange = () => {
        clearPagination()
    }

    const filters = {
        country: useCountryFilterState(),
        assignee: useAssigneeFilterState(),
        companyId: useCompanyIdFilterState(),
        createdAtFrom: useCreatedAtFromState(),
        createdAtTo: useCreatedAtToState(),
        updatedAtFrom: useUpdatedAtFromState(),
        updatedAtTo: useUpdatedAtToState(),
    }

    return { filters, onFilterChange: handleFilterChange }
}

export const Filters = () => {
    const { filters, onFilterChange } = useFilters()

    return (
        <Card styles={{ body: { padding: 0 } }}>
            <Row gutter={[16, 16]} style={{ padding: '16px 24px' }}>
                <Col span={6}>
                    <Typography.Text style={{ fontSize: '0.75rem' }} strong>
                        Country
                    </Typography.Text>
                    <CountryFilter filter={filters.country} onChange={onFilterChange} />
                </Col>
                <Col span={6}>
                    <Typography.Text style={{ fontSize: '0.75rem' }} strong>
                        Assignee
                    </Typography.Text>
                    <AssigneeFilter filter={filters.assignee} onChange={onFilterChange} />
                </Col>
                <Col span={3}>
                    <Typography.Text style={{ fontSize: '0.75rem' }} strong>
                        Created after
                    </Typography.Text>
                    <CreatedAtFrom filter={filters.createdAtFrom} onChange={onFilterChange} />
                </Col>
                <Col span={3}>
                    <Typography.Text style={{ fontSize: '0.75rem' }} strong>
                        Created before
                    </Typography.Text>
                    <CreatedAtFrom filter={filters.createdAtTo} onChange={onFilterChange} />
                </Col>
                <Col span={3}>
                    <Typography.Text style={{ fontSize: '0.75rem' }} strong>
                        Updated after
                    </Typography.Text>
                    <UpdatedAtFrom filter={filters.updatedAtFrom} onChange={onFilterChange} />
                </Col>
                <Col span={3}>
                    <Typography.Text style={{ fontSize: '0.75rem' }} strong>
                        Updated before
                    </Typography.Text>
                    <UpdatedAtTo filter={filters.updatedAtTo} onChange={onFilterChange} />
                </Col>
                <Col span={6}>
                    <Typography.Text style={{ fontSize: '0.75rem' }} strong>
                        Company ID
                    </Typography.Text>
                    <CompanyIdFilter filter={filters.companyId} onChange={onFilterChange} />
                </Col>
            </Row>
        </Card>
    )
}
