import { bff } from '../backend/bff'

export const useCashManagementEnablement = (companyId: string) => {
    const { mutate: enableCashManagement, isLoading: isEnableCashManagementLoading } =
        bff.cashManagementEnablement.enableCashManagement.useMutation()
    const { mutate: disableCashManagement, isLoading: isDisableCashManagementLoading } =
        bff.cashManagementEnablement.disableCashManagement.useMutation()
    const { data: cashManagementEnabled } =
        bff.cashManagementEnablement.getIsCashManagementEnabled.useQuery({ companyId })
    return {
        cashManagementEnabled: Boolean(cashManagementEnabled),
        isLoading: isEnableCashManagementLoading || isDisableCashManagementLoading,
        mutations: {
            enableCashManagement,
            disableCashManagement,
        },
    }
}
