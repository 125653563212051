import { Modal, Spin, Timeline, Typography } from 'antd'
import { bff } from '../bff-hooks'
import { useKycDetailContext } from '../context'
import { getReasonLabel, getSubReasonLabel, mapEventType, prettifyEventType } from './utils'
import { formatTimestamp } from 'packages/dates/dates'
import { formatRejectionReason } from '../utils'

const { Text } = Typography

export const HistoryModal = () => {
    const {
        caseId,
        historyModal: { isOpen, close },
    } = useKycDetailContext()
    const { data, isLoading } = bff.kycHistoryModal.getHistory.useQuery({ caseId })

    const timelineItems = data?.map((event) => ({
        ...mapEventType(event.eventType),
        children: (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Text style={{ fontSize: '0.875em' }} type="secondary">
                    {formatTimestamp(event.at)}
                    {event.analyst && <Text type="secondary"> - {event.analyst}</Text>}
                </Text>
                <Text strong>{prettifyEventType(event.eventType)}</Text>
                {event.reason && (
                    <Text>
                        <Text>Comment: </Text>
                        <Text strong>{formatRejectionReason(event.reason)}</Text>
                    </Text>
                )}
                {event.caseNote && (
                    <Text>
                        <Text>Case note: </Text>
                        <Text>{event.caseNote}</Text>
                    </Text>
                )}
                {event.waitingForCustomerReason && (
                    <>
                        <Text>
                            <Text>Reason: </Text>
                            <Text>{getReasonLabel(event.waitingForCustomerReason)}</Text>
                        </Text>
                        {event.waitingForCustomerSubReason && (
                            <Text>
                                <Text>Subreason: </Text>
                                <Text>{getSubReasonLabel(event.waitingForCustomerSubReason)}</Text>
                            </Text>
                        )}
                    </>
                )}
                {event.checkName && <Text>{event.checkName}</Text>}
            </div>
        ),
    }))

    return (
        <Modal open={isOpen} onCancel={close} title="History" footer={null} destroyOnClose>
            <Spin spinning={isLoading}>
                <Timeline items={timelineItems} style={{ marginTop: '16px' }} />
                {data?.length === 0 && <Text>No history available</Text>}
            </Spin>
        </Modal>
    )
}
