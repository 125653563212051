/**
 * Generated by orval v6.15.0 🍺
 * Do not edit manually.
 * Styx Client v2
 * OpenAPI spec version: 1.0.0
 */
import { moonRequest as _moonRequest } from '@pleo-io/bff-moon-clients'

const moonRequest = _moonRequest('styxV1')

export type RfiUploadPersonDocumentFileBodyFile = { [key: string]: any }

export type RfiUploadPersonDocumentFileBody = {
    file: RfiUploadPersonDocumentFileBodyFile
}

export type GetRequestsForInformationStatusesItem =
    (typeof GetRequestsForInformationStatusesItem)[keyof typeof GetRequestsForInformationStatusesItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetRequestsForInformationStatusesItem = {
    CREATED: 'CREATED',
    STARTED: 'STARTED',
    COMPLETED: 'COMPLETED',
} as const

export type GetRequestsForInformationParams = {
    statuses?: GetRequestsForInformationStatusesItem[]
}

export type SendRequestKycInformationParams = {
    isRecurring?: boolean
}

export type GetRequiredKycInformationRequiredFor =
    (typeof GetRequiredKycInformationRequiredFor)[keyof typeof GetRequiredKycInformationRequiredFor]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetRequiredKycInformationRequiredFor = {
    SDD: 'SDD',
    FDD: 'FDD',
    WALLET_LOAD: 'WALLET_LOAD',
} as const

export type GetRequiredKycInformationSubject =
    (typeof GetRequiredKycInformationSubject)[keyof typeof GetRequiredKycInformationSubject]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetRequiredKycInformationSubject = {
    COMPANY: 'COMPANY',
    PERSON: 'PERSON',
} as const

export type GetRequiredKycInformationType =
    (typeof GetRequiredKycInformationType)[keyof typeof GetRequiredKycInformationType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetRequiredKycInformationType = {
    DOCUMENT: 'DOCUMENT',
    DATA: 'DATA',
} as const

export type GetRequiredKycInformationName =
    (typeof GetRequiredKycInformationName)[keyof typeof GetRequiredKycInformationName]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetRequiredKycInformationName = {
    BANK_VERIFICATION_DETAILS: 'BANK_VERIFICATION_DETAILS',
    AML_ANSWERS: 'AML_ANSWERS',
    ADDRESS: 'ADDRESS',
    PERSONS_IN_SCOPE: 'PERSONS_IN_SCOPE',
    STRUCTURE: 'STRUCTURE',
    NATIONAL_ID: 'NATIONAL_ID',
    BIRTH_DATE: 'BIRTH_DATE',
    PROOF_OF_ADDRESS: 'PROOF_OF_ADDRESS',
    PROOF_OF_ID: 'PROOF_OF_ID',
    COMPANY_REGISTRATION_CERTIFICATE: 'COMPANY_REGISTRATION_CERTIFICATE',
    FINANCIAL_STATEMENT: 'FINANCIAL_STATEMENT',
    OWNERSHIP: 'OWNERSHIP',
    PROOF_OF_BUSINESS: 'PROOF_OF_BUSINESS',
    SOURCE_OF_FUNDS: 'SOURCE_OF_FUNDS',
    ARTICLES_OF_ASSOCIATION: 'ARTICLES_OF_ASSOCIATION',
    PROOF_OF_DIRECTORS: 'PROOF_OF_DIRECTORS',
    OTHER_DOCUMENT: 'OTHER_DOCUMENT',
    BUSINESS_EXPLANATION: 'BUSINESS_EXPLANATION',
    VERIFIED_ADMIN: 'VERIFIED_ADMIN',
    DEED_OF_FOUNDATION: 'DEED_OF_FOUNDATION',
    COUNTRY_OF_RESIDENCE: 'COUNTRY_OF_RESIDENCE',
    CASH_FLOW_STATEMENT: 'CASH_FLOW_STATEMENT',
    CBD_LICENCE: 'CBD_LICENCE',
    CERTIFICATE_TO_OPERATE: 'CERTIFICATE_TO_OPERATE',
    AML_POLICY: 'AML_POLICY',
    CERTIFICATE_TO_OPERATE_PRECIOUS_STONES: 'CERTIFICATE_TO_OPERATE_PRECIOUS_STONES',
    LICENCE_TO_OPERATE_GAMBLING_AND_BETTING: 'LICENCE_TO_OPERATE_GAMBLING_AND_BETTING',
    LICENCE_TO_OPERATE_MINING_AND_PRIVATE_SECURITY:
        'LICENCE_TO_OPERATE_MINING_AND_PRIVATE_SECURITY',
    LICENCE_TO_OPERATE_QUARRYING_AND_EXTRACTION: 'LICENCE_TO_OPERATE_QUARRYING_AND_EXTRACTION',
    SHAREHOLDER_REGISTER: 'SHAREHOLDER_REGISTER',
    TRUST_DEED: 'TRUST_DEED',
    MANUFACTURING_COUNTRIES: 'MANUFACTURING_COUNTRIES',
    NATIONALITY: 'NATIONALITY',
    COMPANY_REVENUE: 'COMPANY_REVENUE',
    TRADING_NAME: 'TRADING_NAME',
    TRADING_ADDRESS: 'TRADING_ADDRESS',
    UBO_CONFIRMATION: 'UBO_CONFIRMATION',
    DIRECTOR_CONFIRMATION: 'DIRECTOR_CONFIRMATION',
    INDUSTRY_CODE: 'INDUSTRY_CODE',
    PEP_FORM: 'PEP_FORM',
    WEBSITE: 'WEBSITE',
    CITIZENSHIP: 'CITIZENSHIP',
    COUNTRIES_OF_OPERATION: 'COUNTRIES_OF_OPERATION',
    EXTERNAL_HIGH_RISK_APPROVAL: 'EXTERNAL_HIGH_RISK_APPROVAL',
    EXTERNAL_RISK_ASSESSMENT: 'EXTERNAL_RISK_ASSESSMENT',
    SOW_SOF_ASSESSMENT: 'SOW_SOF_ASSESSMENT',
    BUSINESS_REGISTRY_EXTRACT: 'BUSINESS_REGISTRY_EXTRACT',
    AML_ANSWERS_CONTINENTS: 'AML_ANSWERS_CONTINENTS',
    AML_ANSWERS_CATEGORIES: 'AML_ANSWERS_CATEGORIES',
    AML_ANSWERS_AMOUNT_RANGE: 'AML_ANSWERS_AMOUNT_RANGE',
    AML_ANSWERS_FREQUENTLY_USED_IN_COUNTRIES: 'AML_ANSWERS_FREQUENTLY_USED_IN_COUNTRIES',
    AML_ANSWERS_AVERAGE_AMOUNT_RANGE: 'AML_ANSWERS_AVERAGE_AMOUNT_RANGE',
} as const

export type GetRequiredKycInformationParams = {
    name?: GetRequiredKycInformationName
    type?: GetRequiredKycInformationType
    subject?: GetRequiredKycInformationSubject
    subjectId?: string
    requiredFor?: GetRequiredKycInformationRequiredFor
}

export type GetMissingKycInformationRequiredFor =
    (typeof GetMissingKycInformationRequiredFor)[keyof typeof GetMissingKycInformationRequiredFor]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetMissingKycInformationRequiredFor = {
    SDD: 'SDD',
    FDD: 'FDD',
    WALLET_LOAD: 'WALLET_LOAD',
} as const

export type GetMissingKycInformationSubject =
    (typeof GetMissingKycInformationSubject)[keyof typeof GetMissingKycInformationSubject]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetMissingKycInformationSubject = {
    COMPANY: 'COMPANY',
    PERSON: 'PERSON',
} as const

export type GetMissingKycInformationType =
    (typeof GetMissingKycInformationType)[keyof typeof GetMissingKycInformationType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetMissingKycInformationType = {
    DOCUMENT: 'DOCUMENT',
    DATA: 'DATA',
} as const

export type GetMissingKycInformationName =
    (typeof GetMissingKycInformationName)[keyof typeof GetMissingKycInformationName]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetMissingKycInformationName = {
    BANK_VERIFICATION_DETAILS: 'BANK_VERIFICATION_DETAILS',
    AML_ANSWERS: 'AML_ANSWERS',
    ADDRESS: 'ADDRESS',
    PERSONS_IN_SCOPE: 'PERSONS_IN_SCOPE',
    STRUCTURE: 'STRUCTURE',
    NATIONAL_ID: 'NATIONAL_ID',
    BIRTH_DATE: 'BIRTH_DATE',
    PROOF_OF_ADDRESS: 'PROOF_OF_ADDRESS',
    PROOF_OF_ID: 'PROOF_OF_ID',
    COMPANY_REGISTRATION_CERTIFICATE: 'COMPANY_REGISTRATION_CERTIFICATE',
    FINANCIAL_STATEMENT: 'FINANCIAL_STATEMENT',
    OWNERSHIP: 'OWNERSHIP',
    PROOF_OF_BUSINESS: 'PROOF_OF_BUSINESS',
    SOURCE_OF_FUNDS: 'SOURCE_OF_FUNDS',
    ARTICLES_OF_ASSOCIATION: 'ARTICLES_OF_ASSOCIATION',
    PROOF_OF_DIRECTORS: 'PROOF_OF_DIRECTORS',
    OTHER_DOCUMENT: 'OTHER_DOCUMENT',
    BUSINESS_EXPLANATION: 'BUSINESS_EXPLANATION',
    VERIFIED_ADMIN: 'VERIFIED_ADMIN',
    DEED_OF_FOUNDATION: 'DEED_OF_FOUNDATION',
    COUNTRY_OF_RESIDENCE: 'COUNTRY_OF_RESIDENCE',
    CASH_FLOW_STATEMENT: 'CASH_FLOW_STATEMENT',
    CBD_LICENCE: 'CBD_LICENCE',
    CERTIFICATE_TO_OPERATE: 'CERTIFICATE_TO_OPERATE',
    AML_POLICY: 'AML_POLICY',
    CERTIFICATE_TO_OPERATE_PRECIOUS_STONES: 'CERTIFICATE_TO_OPERATE_PRECIOUS_STONES',
    LICENCE_TO_OPERATE_GAMBLING_AND_BETTING: 'LICENCE_TO_OPERATE_GAMBLING_AND_BETTING',
    LICENCE_TO_OPERATE_MINING_AND_PRIVATE_SECURITY:
        'LICENCE_TO_OPERATE_MINING_AND_PRIVATE_SECURITY',
    LICENCE_TO_OPERATE_QUARRYING_AND_EXTRACTION: 'LICENCE_TO_OPERATE_QUARRYING_AND_EXTRACTION',
    SHAREHOLDER_REGISTER: 'SHAREHOLDER_REGISTER',
    TRUST_DEED: 'TRUST_DEED',
    MANUFACTURING_COUNTRIES: 'MANUFACTURING_COUNTRIES',
    NATIONALITY: 'NATIONALITY',
    COMPANY_REVENUE: 'COMPANY_REVENUE',
    TRADING_NAME: 'TRADING_NAME',
    TRADING_ADDRESS: 'TRADING_ADDRESS',
    UBO_CONFIRMATION: 'UBO_CONFIRMATION',
    DIRECTOR_CONFIRMATION: 'DIRECTOR_CONFIRMATION',
    INDUSTRY_CODE: 'INDUSTRY_CODE',
    PEP_FORM: 'PEP_FORM',
    WEBSITE: 'WEBSITE',
    CITIZENSHIP: 'CITIZENSHIP',
    COUNTRIES_OF_OPERATION: 'COUNTRIES_OF_OPERATION',
    EXTERNAL_HIGH_RISK_APPROVAL: 'EXTERNAL_HIGH_RISK_APPROVAL',
    EXTERNAL_RISK_ASSESSMENT: 'EXTERNAL_RISK_ASSESSMENT',
    SOW_SOF_ASSESSMENT: 'SOW_SOF_ASSESSMENT',
    BUSINESS_REGISTRY_EXTRACT: 'BUSINESS_REGISTRY_EXTRACT',
    AML_ANSWERS_CONTINENTS: 'AML_ANSWERS_CONTINENTS',
    AML_ANSWERS_CATEGORIES: 'AML_ANSWERS_CATEGORIES',
    AML_ANSWERS_AMOUNT_RANGE: 'AML_ANSWERS_AMOUNT_RANGE',
    AML_ANSWERS_FREQUENTLY_USED_IN_COUNTRIES: 'AML_ANSWERS_FREQUENTLY_USED_IN_COUNTRIES',
    AML_ANSWERS_AVERAGE_AMOUNT_RANGE: 'AML_ANSWERS_AVERAGE_AMOUNT_RANGE',
} as const

export type GetMissingKycInformationParams = {
    name?: GetMissingKycInformationName
    type?: GetMissingKycInformationType
    subject?: GetMissingKycInformationSubject
    subjectId?: string
    requiredFor?: GetMissingKycInformationRequiredFor
}

export type GetAllDocuments1Params = {
    /**
     * the ISO DateTime of the desired snapshot
     */
    snapshot?: string
}

export type GetRevenueRangesCountry =
    (typeof GetRevenueRangesCountry)[keyof typeof GetRevenueRangesCountry]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetRevenueRangesCountry = {
    UNDEFINED: 'UNDEFINED',
    AC: 'AC',
    AD: 'AD',
    AE: 'AE',
    AF: 'AF',
    AG: 'AG',
    AI: 'AI',
    AL: 'AL',
    AM: 'AM',
    AN: 'AN',
    AO: 'AO',
    AQ: 'AQ',
    AR: 'AR',
    AS: 'AS',
    AT: 'AT',
    AU: 'AU',
    AW: 'AW',
    AX: 'AX',
    AZ: 'AZ',
    BA: 'BA',
    BB: 'BB',
    BD: 'BD',
    BE: 'BE',
    BF: 'BF',
    BG: 'BG',
    BH: 'BH',
    BI: 'BI',
    BJ: 'BJ',
    BL: 'BL',
    BM: 'BM',
    BN: 'BN',
    BO: 'BO',
    BQ: 'BQ',
    BR: 'BR',
    BS: 'BS',
    BT: 'BT',
    BU: 'BU',
    BV: 'BV',
    BW: 'BW',
    BY: 'BY',
    BZ: 'BZ',
    CA: 'CA',
    CC: 'CC',
    CD: 'CD',
    CF: 'CF',
    CG: 'CG',
    CH: 'CH',
    CI: 'CI',
    CK: 'CK',
    CL: 'CL',
    CM: 'CM',
    CN: 'CN',
    CO: 'CO',
    CP: 'CP',
    CR: 'CR',
    CS: 'CS',
    CU: 'CU',
    CV: 'CV',
    CW: 'CW',
    CX: 'CX',
    CY: 'CY',
    CZ: 'CZ',
    DE: 'DE',
    DG: 'DG',
    DJ: 'DJ',
    DK: 'DK',
    DM: 'DM',
    DO: 'DO',
    DZ: 'DZ',
    EA: 'EA',
    EC: 'EC',
    EE: 'EE',
    EG: 'EG',
    EH: 'EH',
    ER: 'ER',
    ES: 'ES',
    ET: 'ET',
    EU: 'EU',
    EZ: 'EZ',
    FI: 'FI',
    FJ: 'FJ',
    FK: 'FK',
    FM: 'FM',
    FO: 'FO',
    FR: 'FR',
    FX: 'FX',
    GA: 'GA',
    GB: 'GB',
    GD: 'GD',
    GE: 'GE',
    GF: 'GF',
    GG: 'GG',
    GH: 'GH',
    GI: 'GI',
    GL: 'GL',
    GM: 'GM',
    GN: 'GN',
    GP: 'GP',
    GQ: 'GQ',
    GR: 'GR',
    GS: 'GS',
    GT: 'GT',
    GU: 'GU',
    GW: 'GW',
    GY: 'GY',
    HK: 'HK',
    HM: 'HM',
    HN: 'HN',
    HR: 'HR',
    HT: 'HT',
    HU: 'HU',
    IC: 'IC',
    ID: 'ID',
    IE: 'IE',
    IL: 'IL',
    IM: 'IM',
    IN: 'IN',
    IO: 'IO',
    IQ: 'IQ',
    IR: 'IR',
    IS: 'IS',
    IT: 'IT',
    JE: 'JE',
    JM: 'JM',
    JO: 'JO',
    JP: 'JP',
    KE: 'KE',
    KG: 'KG',
    KH: 'KH',
    KI: 'KI',
    KM: 'KM',
    KN: 'KN',
    KP: 'KP',
    KR: 'KR',
    KW: 'KW',
    KY: 'KY',
    KZ: 'KZ',
    LA: 'LA',
    LB: 'LB',
    LC: 'LC',
    LI: 'LI',
    LK: 'LK',
    LR: 'LR',
    LS: 'LS',
    LT: 'LT',
    LU: 'LU',
    LV: 'LV',
    LY: 'LY',
    MA: 'MA',
    MC: 'MC',
    MD: 'MD',
    ME: 'ME',
    MF: 'MF',
    MG: 'MG',
    MH: 'MH',
    MK: 'MK',
    ML: 'ML',
    MM: 'MM',
    MN: 'MN',
    MO: 'MO',
    MP: 'MP',
    MQ: 'MQ',
    MR: 'MR',
    MS: 'MS',
    MT: 'MT',
    MU: 'MU',
    MV: 'MV',
    MW: 'MW',
    MX: 'MX',
    MY: 'MY',
    MZ: 'MZ',
    NA: 'NA',
    NC: 'NC',
    NE: 'NE',
    NF: 'NF',
    NG: 'NG',
    NI: 'NI',
    NL: 'NL',
    NO: 'NO',
    NP: 'NP',
    NR: 'NR',
    NT: 'NT',
    NU: 'NU',
    NZ: 'NZ',
    OM: 'OM',
    PA: 'PA',
    PE: 'PE',
    PF: 'PF',
    PG: 'PG',
    PH: 'PH',
    PK: 'PK',
    PL: 'PL',
    PM: 'PM',
    PN: 'PN',
    PR: 'PR',
    PS: 'PS',
    PT: 'PT',
    PW: 'PW',
    PY: 'PY',
    QA: 'QA',
    RE: 'RE',
    RO: 'RO',
    RS: 'RS',
    RU: 'RU',
    RW: 'RW',
    SA: 'SA',
    SB: 'SB',
    SC: 'SC',
    SD: 'SD',
    SE: 'SE',
    SF: 'SF',
    SG: 'SG',
    SH: 'SH',
    SI: 'SI',
    SJ: 'SJ',
    SK: 'SK',
    SL: 'SL',
    SM: 'SM',
    SN: 'SN',
    SO: 'SO',
    SR: 'SR',
    SS: 'SS',
    ST: 'ST',
    SU: 'SU',
    SV: 'SV',
    SX: 'SX',
    SY: 'SY',
    SZ: 'SZ',
    TA: 'TA',
    TC: 'TC',
    TD: 'TD',
    TF: 'TF',
    TG: 'TG',
    TH: 'TH',
    TJ: 'TJ',
    TK: 'TK',
    TL: 'TL',
    TM: 'TM',
    TN: 'TN',
    TO: 'TO',
    TP: 'TP',
    TR: 'TR',
    TT: 'TT',
    TV: 'TV',
    TW: 'TW',
    TZ: 'TZ',
    UA: 'UA',
    UG: 'UG',
    UK: 'UK',
    UM: 'UM',
    US: 'US',
    UY: 'UY',
    UZ: 'UZ',
    VA: 'VA',
    VC: 'VC',
    VE: 'VE',
    VG: 'VG',
    VI: 'VI',
    VN: 'VN',
    VU: 'VU',
    WF: 'WF',
    WS: 'WS',
    XI: 'XI',
    XU: 'XU',
    XK: 'XK',
    YE: 'YE',
    YT: 'YT',
    YU: 'YU',
    ZA: 'ZA',
    ZM: 'ZM',
    ZR: 'ZR',
    ZW: 'ZW',
} as const

export type GetRevenueRangesParams = {
    country?: GetRevenueRangesCountry
}

export type DetermineSourceApprovalParams = {
    senderBank?: string
    senderInformation?: string
}

export type GetLoadLimitForSourceParams = {
    senderBank?: string
    senderInformation?: string
}

export type GetActiveBlockedSourcesParams = {
    /**
     * Page limit
     */
    limit?: number
    /**
     * Page number
     */
    page?: number
}

export type GetAllBlockedSourcesParams = {
    /**
     * Page limit
     */
    limit?: number
    /**
     * Page number
     */
    page?: number
}

export type GetProofOfFundsByStatusRefundStatus =
    (typeof GetProofOfFundsByStatusRefundStatus)[keyof typeof GetProofOfFundsByStatusRefundStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetProofOfFundsByStatusRefundStatus = {
    INITIATING: 'INITIATING',
    INITIATED: 'INITIATED',
    RECEIVED: 'RECEIVED',
    FAILED: 'FAILED',
} as const

export type GetProofOfFundsByStatusStatus =
    (typeof GetProofOfFundsByStatusStatus)[keyof typeof GetProofOfFundsByStatusStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetProofOfFundsByStatusStatus = {
    PENDING: 'PENDING',
    INVESTIGATING: 'INVESTIGATING',
    PRE_APPROVED: 'PRE_APPROVED',
    PRE_APPROVED_LEGAL_NAME_CHECK: 'PRE_APPROVED_LEGAL_NAME_CHECK',
    APPROVED: 'APPROVED',
    REJECTED: 'REJECTED',
    REJECTED_REFUND: 'REJECTED_REFUND',
} as const

export type GetProofOfFundsByStatusParams = {
    status: GetProofOfFundsByStatusStatus
    refundStatus?: GetProofOfFundsByStatusRefundStatus
    /**
     * Page limit
     */
    limit?: number
    /**
     * Page number
     */
    page?: number
}

export type SetMaximumAttemptsParams = {
    checkType?: string
}

export type GetMaximumAttemptsParams = {
    checkType?: string
}

export type GetAttemptsParams = {
    checkType?: string
}

export type GetPersons1Params = {
    /**
     * Person's name
     */
    firstName?: string
    /**
     * Person's name
     */
    middleName?: string
    /**
     * Person's name
     */
    lastName?: string
    /**
     * Person's year of birth
     */
    yearOfBirth?: number
    /**
     * Person's month of birth
     */
    monthOfBirth?: number
    /**
     * Person's date of birth
     */
    dateOfBirth?: number
    /**
     * Person is in scope
     */
    inScope?: boolean
    before?: string
    after?: string
    offset?: number
    limit?: number
    orderBy?: string
}

export type GetPersonVerificationParams = {
    /**
     * the ISO DateTime of the desired snapshot
     */
    snapshot?: string
}

export type GetCompanyOffboardingStatusParams = {
    /**
     * the ISO DateTime of the desired snapshot
     */
    snapshot?: string
}

export type GetClosingCompanyOffboardingStatusesParams = {
    /**
     * Page number
     */
    page?: number
    /**
     * Page limit
     */
    limit?: number
    'initiated-by'?: string
    'company-name'?: string
    'self-offboarding-only'?: boolean
}

export type GetLicensingAuthoritiesParams = {
    /**
     * The postal code to filter by to get the licensing authorities. Only used for UK which has different licensing authorities for different regions. If no postal code is provided or the region cannot be extracted from the postalCode all licensing authorities for the country will be returned.
     */
    postal_code?: string
}

export type GetKycInformationNameSourceKycInformationName =
    (typeof GetKycInformationNameSourceKycInformationName)[keyof typeof GetKycInformationNameSourceKycInformationName]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetKycInformationNameSourceKycInformationName = {
    BANK_VERIFICATION_DETAILS: 'BANK_VERIFICATION_DETAILS',
    AML_ANSWERS: 'AML_ANSWERS',
    ADDRESS: 'ADDRESS',
    PERSONS_IN_SCOPE: 'PERSONS_IN_SCOPE',
    STRUCTURE: 'STRUCTURE',
    NATIONAL_ID: 'NATIONAL_ID',
    BIRTH_DATE: 'BIRTH_DATE',
    PROOF_OF_ADDRESS: 'PROOF_OF_ADDRESS',
    PROOF_OF_ID: 'PROOF_OF_ID',
    COMPANY_REGISTRATION_CERTIFICATE: 'COMPANY_REGISTRATION_CERTIFICATE',
    FINANCIAL_STATEMENT: 'FINANCIAL_STATEMENT',
    OWNERSHIP: 'OWNERSHIP',
    PROOF_OF_BUSINESS: 'PROOF_OF_BUSINESS',
    SOURCE_OF_FUNDS: 'SOURCE_OF_FUNDS',
    ARTICLES_OF_ASSOCIATION: 'ARTICLES_OF_ASSOCIATION',
    PROOF_OF_DIRECTORS: 'PROOF_OF_DIRECTORS',
    OTHER_DOCUMENT: 'OTHER_DOCUMENT',
    BUSINESS_EXPLANATION: 'BUSINESS_EXPLANATION',
    VERIFIED_ADMIN: 'VERIFIED_ADMIN',
    DEED_OF_FOUNDATION: 'DEED_OF_FOUNDATION',
    COUNTRY_OF_RESIDENCE: 'COUNTRY_OF_RESIDENCE',
    CASH_FLOW_STATEMENT: 'CASH_FLOW_STATEMENT',
    CBD_LICENCE: 'CBD_LICENCE',
    CERTIFICATE_TO_OPERATE: 'CERTIFICATE_TO_OPERATE',
    AML_POLICY: 'AML_POLICY',
    CERTIFICATE_TO_OPERATE_PRECIOUS_STONES: 'CERTIFICATE_TO_OPERATE_PRECIOUS_STONES',
    LICENCE_TO_OPERATE_GAMBLING_AND_BETTING: 'LICENCE_TO_OPERATE_GAMBLING_AND_BETTING',
    LICENCE_TO_OPERATE_MINING_AND_PRIVATE_SECURITY:
        'LICENCE_TO_OPERATE_MINING_AND_PRIVATE_SECURITY',
    LICENCE_TO_OPERATE_QUARRYING_AND_EXTRACTION: 'LICENCE_TO_OPERATE_QUARRYING_AND_EXTRACTION',
    SHAREHOLDER_REGISTER: 'SHAREHOLDER_REGISTER',
    TRUST_DEED: 'TRUST_DEED',
    MANUFACTURING_COUNTRIES: 'MANUFACTURING_COUNTRIES',
    NATIONALITY: 'NATIONALITY',
    COMPANY_REVENUE: 'COMPANY_REVENUE',
    TRADING_NAME: 'TRADING_NAME',
    TRADING_ADDRESS: 'TRADING_ADDRESS',
    UBO_CONFIRMATION: 'UBO_CONFIRMATION',
    DIRECTOR_CONFIRMATION: 'DIRECTOR_CONFIRMATION',
    INDUSTRY_CODE: 'INDUSTRY_CODE',
    PEP_FORM: 'PEP_FORM',
    WEBSITE: 'WEBSITE',
    CITIZENSHIP: 'CITIZENSHIP',
    COUNTRIES_OF_OPERATION: 'COUNTRIES_OF_OPERATION',
    EXTERNAL_HIGH_RISK_APPROVAL: 'EXTERNAL_HIGH_RISK_APPROVAL',
    EXTERNAL_RISK_ASSESSMENT: 'EXTERNAL_RISK_ASSESSMENT',
    SOW_SOF_ASSESSMENT: 'SOW_SOF_ASSESSMENT',
    BUSINESS_REGISTRY_EXTRACT: 'BUSINESS_REGISTRY_EXTRACT',
    AML_ANSWERS_CONTINENTS: 'AML_ANSWERS_CONTINENTS',
    AML_ANSWERS_CATEGORIES: 'AML_ANSWERS_CATEGORIES',
    AML_ANSWERS_AMOUNT_RANGE: 'AML_ANSWERS_AMOUNT_RANGE',
    AML_ANSWERS_FREQUENTLY_USED_IN_COUNTRIES: 'AML_ANSWERS_FREQUENTLY_USED_IN_COUNTRIES',
    AML_ANSWERS_AVERAGE_AMOUNT_RANGE: 'AML_ANSWERS_AVERAGE_AMOUNT_RANGE',
} as const

export type GetKycInformationNameSourceParams = {
    kycInformationName?: GetKycInformationNameSourceKycInformationName
}

export type GetPepChecks1 = { [key: string]: KycCheck[] }

export type GetPepChecks1Params = {
    personId?: string[]
}

export type SetMultiEntityCompanyBillingStorageValue =
    (typeof SetMultiEntityCompanyBillingStorageValue)[keyof typeof SetMultiEntityCompanyBillingStorageValue]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SetMultiEntityCompanyBillingStorageValue = {
    true: 'true',
    false: 'false',
} as const

export type SetMultiEntityCompanyBillingParams = {
    storageValue?: SetMultiEntityCompanyBillingStorageValue
}

export type GetAllB4BDocumentsParams = {
    /**
     * the ISO DateTime of the desired snapshot
     */
    snapshot?: string
}

export type GetAllB4BCompaniesParams = {
    /**
     * company's legal name
     */
    legalName?: string
    /**
     * company's registry id
     */
    registryId?: string
    /**
     * company onboarded via
     */
    onboardedVia?: string
    /**
     * company's kyc level
     */
    kycLevel?: string
    before?: string
    after?: string
    offset?: number
    limit?: number
    orderBy?: string
}

export type GetRiskScoreParams = {
    /**
     * the ISO DateTime of the desired snapshot
     */
    snapshot?: string
}

export type GetClosedCompanyRiskAssessmentParams = {
    /**
     * the ISO DateTime of the desired snapshot
     */
    snapshot?: string
    includeRiskChecks?: boolean
}

export type GetRiskChecks1Params = {
    /**
     * the ISO DateTime of the desired snapshot
     */
    snapshot?: string
}

export type GetRiskAssessment1Params = {
    /**
     * the ISO DateTime of the desired snapshot
     */
    snapshot?: string
    includeRiskChecks?: boolean
}

export type GetPersonShareholdersParams = {
    sharePercentageThreshold?: number
    includeFileCreationDate?: boolean
}

export type GetManagementType = (typeof GetManagementType)[keyof typeof GetManagementType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetManagementType = {
    UBO: 'UBO',
    DIRECTOR: 'DIRECTOR',
    ADMIN: 'ADMIN',
    SHAREHOLDER: 'SHAREHOLDER',
} as const

export type GetManagementParams = {
    includeFileCreationDate?: boolean
    type?: GetManagementType
}

export type GetCompanyIndustryCodesSourcesItem =
    (typeof GetCompanyIndustryCodesSourcesItem)[keyof typeof GetCompanyIndustryCodesSourcesItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCompanyIndustryCodesSourcesItem = {
    COMPANIES_HOUSE: 'COMPANIES_HOUSE',
    CREDITSAFE_GLOBAL: 'CREDITSAFE_GLOBAL',
    CREDITSAFE_SWEDEN: 'CREDITSAFE_SWEDEN',
    VIRK: 'VIRK',
    VEMBI: 'VEMBI',
    BVD: 'BVD',
    USER_INPUT: 'USER_INPUT',
    DUE_DILIGENCE_AGENT_INPUT: 'DUE_DILIGENCE_AGENT_INPUT',
    null: null,
} as const

export type GetCompanyIndustryCodesParams = {
    /**
     * Filter industry codes by the source
     */
    sources?: GetCompanyIndustryCodesSourcesItem[] | null
}

export type GetDocumentFileMetadata2Params = {
    /**
     * the ISO DateTime of the desired snapshot
     */
    snapshot?: string
}

export type GetDocumentFileLink2Params = {
    /**
     * the ISO DateTime of the desired snapshot
     */
    snapshot?: string
}

export type GetDocumentFile2Params = {
    /**
     * the ISO DateTime of the desired snapshot
     */
    snapshot?: string
}

export type CreateDocumentFile2BodyFile = { [key: string]: any }

export type CreateDocumentFile2Body = {
    file: CreateDocumentFile2BodyFile
}

export type GetDocumentFiles2Params = {
    /**
     * the ISO DateTime of the desired snapshot
     */
    snapshot?: string
}

export type GetDocument2Params = {
    /**
     * the ISO DateTime of the desired snapshot
     */
    snapshot?: string
}

export type CreateDocument2Params = {
    /**
     * If autoRetrieve param is present and true, it triggers the retrieve of the file if the document category is supported for the company
     */
    autoRetrieve?: boolean
}

export type GetDocuments2Params = {
    /**
     * the ISO DateTime of the desired snapshot
     */
    snapshot?: string
    includeFileCreationDate?: boolean
}

export type GetCompanyShareholdersParams = {
    sharePercentageThreshold?: number
}

export type GetClosedCompanyDocumentsParams = {
    /**
     * the ISO DateTime of the desired snapshot
     */
    snapshot?: string
    includeFileCreationDate?: boolean
}

export type GetDeletedCompany1Params = {
    /**
     * the ISO DateTime of the desired snapshot
     */
    snapshot?: string
}

export type GetCompanyAdminVerificationRequestParams = {
    /**
     * the ISO DateTime of the desired snapshot
     */
    snapshot?: InstantParam
}

export type SearchCompanies1Params = {
    /**
     * company's legal name
     */
    legalName?: string
    /**
     * company's registry id
     */
    registryId?: string
    /**
     * company onboarded via
     */
    onboardedVia?: string
    /**
     * company's kyc level
     */
    kycLevel?: string
    before?: string
    after?: string
    offset?: number
    limit?: number
    orderBy?: string
}

export type GetRootCompaniesParams = {
    email?: string
    /**
     * Page number
     */
    page?: number
    /**
     * Page limit
     */
    limit?: number
}

export type GetDeletedCompanyByRegistryIdParams = {
    registryId?: string
}

export type CreateAmlDocumentFileBodyFile = { [key: string]: any }

export type CreateAmlDocumentFileBody = {
    file: CreateAmlDocumentFileBodyFile
}

export type GetAverageAmountRangesCountry =
    (typeof GetAverageAmountRangesCountry)[keyof typeof GetAverageAmountRangesCountry]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetAverageAmountRangesCountry = {
    UNDEFINED: 'UNDEFINED',
    AC: 'AC',
    AD: 'AD',
    AE: 'AE',
    AF: 'AF',
    AG: 'AG',
    AI: 'AI',
    AL: 'AL',
    AM: 'AM',
    AN: 'AN',
    AO: 'AO',
    AQ: 'AQ',
    AR: 'AR',
    AS: 'AS',
    AT: 'AT',
    AU: 'AU',
    AW: 'AW',
    AX: 'AX',
    AZ: 'AZ',
    BA: 'BA',
    BB: 'BB',
    BD: 'BD',
    BE: 'BE',
    BF: 'BF',
    BG: 'BG',
    BH: 'BH',
    BI: 'BI',
    BJ: 'BJ',
    BL: 'BL',
    BM: 'BM',
    BN: 'BN',
    BO: 'BO',
    BQ: 'BQ',
    BR: 'BR',
    BS: 'BS',
    BT: 'BT',
    BU: 'BU',
    BV: 'BV',
    BW: 'BW',
    BY: 'BY',
    BZ: 'BZ',
    CA: 'CA',
    CC: 'CC',
    CD: 'CD',
    CF: 'CF',
    CG: 'CG',
    CH: 'CH',
    CI: 'CI',
    CK: 'CK',
    CL: 'CL',
    CM: 'CM',
    CN: 'CN',
    CO: 'CO',
    CP: 'CP',
    CR: 'CR',
    CS: 'CS',
    CU: 'CU',
    CV: 'CV',
    CW: 'CW',
    CX: 'CX',
    CY: 'CY',
    CZ: 'CZ',
    DE: 'DE',
    DG: 'DG',
    DJ: 'DJ',
    DK: 'DK',
    DM: 'DM',
    DO: 'DO',
    DZ: 'DZ',
    EA: 'EA',
    EC: 'EC',
    EE: 'EE',
    EG: 'EG',
    EH: 'EH',
    ER: 'ER',
    ES: 'ES',
    ET: 'ET',
    EU: 'EU',
    EZ: 'EZ',
    FI: 'FI',
    FJ: 'FJ',
    FK: 'FK',
    FM: 'FM',
    FO: 'FO',
    FR: 'FR',
    FX: 'FX',
    GA: 'GA',
    GB: 'GB',
    GD: 'GD',
    GE: 'GE',
    GF: 'GF',
    GG: 'GG',
    GH: 'GH',
    GI: 'GI',
    GL: 'GL',
    GM: 'GM',
    GN: 'GN',
    GP: 'GP',
    GQ: 'GQ',
    GR: 'GR',
    GS: 'GS',
    GT: 'GT',
    GU: 'GU',
    GW: 'GW',
    GY: 'GY',
    HK: 'HK',
    HM: 'HM',
    HN: 'HN',
    HR: 'HR',
    HT: 'HT',
    HU: 'HU',
    IC: 'IC',
    ID: 'ID',
    IE: 'IE',
    IL: 'IL',
    IM: 'IM',
    IN: 'IN',
    IO: 'IO',
    IQ: 'IQ',
    IR: 'IR',
    IS: 'IS',
    IT: 'IT',
    JE: 'JE',
    JM: 'JM',
    JO: 'JO',
    JP: 'JP',
    KE: 'KE',
    KG: 'KG',
    KH: 'KH',
    KI: 'KI',
    KM: 'KM',
    KN: 'KN',
    KP: 'KP',
    KR: 'KR',
    KW: 'KW',
    KY: 'KY',
    KZ: 'KZ',
    LA: 'LA',
    LB: 'LB',
    LC: 'LC',
    LI: 'LI',
    LK: 'LK',
    LR: 'LR',
    LS: 'LS',
    LT: 'LT',
    LU: 'LU',
    LV: 'LV',
    LY: 'LY',
    MA: 'MA',
    MC: 'MC',
    MD: 'MD',
    ME: 'ME',
    MF: 'MF',
    MG: 'MG',
    MH: 'MH',
    MK: 'MK',
    ML: 'ML',
    MM: 'MM',
    MN: 'MN',
    MO: 'MO',
    MP: 'MP',
    MQ: 'MQ',
    MR: 'MR',
    MS: 'MS',
    MT: 'MT',
    MU: 'MU',
    MV: 'MV',
    MW: 'MW',
    MX: 'MX',
    MY: 'MY',
    MZ: 'MZ',
    NA: 'NA',
    NC: 'NC',
    NE: 'NE',
    NF: 'NF',
    NG: 'NG',
    NI: 'NI',
    NL: 'NL',
    NO: 'NO',
    NP: 'NP',
    NR: 'NR',
    NT: 'NT',
    NU: 'NU',
    NZ: 'NZ',
    OM: 'OM',
    PA: 'PA',
    PE: 'PE',
    PF: 'PF',
    PG: 'PG',
    PH: 'PH',
    PK: 'PK',
    PL: 'PL',
    PM: 'PM',
    PN: 'PN',
    PR: 'PR',
    PS: 'PS',
    PT: 'PT',
    PW: 'PW',
    PY: 'PY',
    QA: 'QA',
    RE: 'RE',
    RO: 'RO',
    RS: 'RS',
    RU: 'RU',
    RW: 'RW',
    SA: 'SA',
    SB: 'SB',
    SC: 'SC',
    SD: 'SD',
    SE: 'SE',
    SF: 'SF',
    SG: 'SG',
    SH: 'SH',
    SI: 'SI',
    SJ: 'SJ',
    SK: 'SK',
    SL: 'SL',
    SM: 'SM',
    SN: 'SN',
    SO: 'SO',
    SR: 'SR',
    SS: 'SS',
    ST: 'ST',
    SU: 'SU',
    SV: 'SV',
    SX: 'SX',
    SY: 'SY',
    SZ: 'SZ',
    TA: 'TA',
    TC: 'TC',
    TD: 'TD',
    TF: 'TF',
    TG: 'TG',
    TH: 'TH',
    TJ: 'TJ',
    TK: 'TK',
    TL: 'TL',
    TM: 'TM',
    TN: 'TN',
    TO: 'TO',
    TP: 'TP',
    TR: 'TR',
    TT: 'TT',
    TV: 'TV',
    TW: 'TW',
    TZ: 'TZ',
    UA: 'UA',
    UG: 'UG',
    UK: 'UK',
    UM: 'UM',
    US: 'US',
    UY: 'UY',
    UZ: 'UZ',
    VA: 'VA',
    VC: 'VC',
    VE: 'VE',
    VG: 'VG',
    VI: 'VI',
    VN: 'VN',
    VU: 'VU',
    WF: 'WF',
    WS: 'WS',
    XI: 'XI',
    XU: 'XU',
    XK: 'XK',
    YE: 'YE',
    YT: 'YT',
    YU: 'YU',
    ZA: 'ZA',
    ZM: 'ZM',
    ZR: 'ZR',
    ZW: 'ZW',
} as const

export type GetAverageAmountRangesParams = {
    country?: GetAverageAmountRangesCountry
}

export type GetAmountRangesCountry =
    (typeof GetAmountRangesCountry)[keyof typeof GetAmountRangesCountry]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetAmountRangesCountry = {
    UNDEFINED: 'UNDEFINED',
    AC: 'AC',
    AD: 'AD',
    AE: 'AE',
    AF: 'AF',
    AG: 'AG',
    AI: 'AI',
    AL: 'AL',
    AM: 'AM',
    AN: 'AN',
    AO: 'AO',
    AQ: 'AQ',
    AR: 'AR',
    AS: 'AS',
    AT: 'AT',
    AU: 'AU',
    AW: 'AW',
    AX: 'AX',
    AZ: 'AZ',
    BA: 'BA',
    BB: 'BB',
    BD: 'BD',
    BE: 'BE',
    BF: 'BF',
    BG: 'BG',
    BH: 'BH',
    BI: 'BI',
    BJ: 'BJ',
    BL: 'BL',
    BM: 'BM',
    BN: 'BN',
    BO: 'BO',
    BQ: 'BQ',
    BR: 'BR',
    BS: 'BS',
    BT: 'BT',
    BU: 'BU',
    BV: 'BV',
    BW: 'BW',
    BY: 'BY',
    BZ: 'BZ',
    CA: 'CA',
    CC: 'CC',
    CD: 'CD',
    CF: 'CF',
    CG: 'CG',
    CH: 'CH',
    CI: 'CI',
    CK: 'CK',
    CL: 'CL',
    CM: 'CM',
    CN: 'CN',
    CO: 'CO',
    CP: 'CP',
    CR: 'CR',
    CS: 'CS',
    CU: 'CU',
    CV: 'CV',
    CW: 'CW',
    CX: 'CX',
    CY: 'CY',
    CZ: 'CZ',
    DE: 'DE',
    DG: 'DG',
    DJ: 'DJ',
    DK: 'DK',
    DM: 'DM',
    DO: 'DO',
    DZ: 'DZ',
    EA: 'EA',
    EC: 'EC',
    EE: 'EE',
    EG: 'EG',
    EH: 'EH',
    ER: 'ER',
    ES: 'ES',
    ET: 'ET',
    EU: 'EU',
    EZ: 'EZ',
    FI: 'FI',
    FJ: 'FJ',
    FK: 'FK',
    FM: 'FM',
    FO: 'FO',
    FR: 'FR',
    FX: 'FX',
    GA: 'GA',
    GB: 'GB',
    GD: 'GD',
    GE: 'GE',
    GF: 'GF',
    GG: 'GG',
    GH: 'GH',
    GI: 'GI',
    GL: 'GL',
    GM: 'GM',
    GN: 'GN',
    GP: 'GP',
    GQ: 'GQ',
    GR: 'GR',
    GS: 'GS',
    GT: 'GT',
    GU: 'GU',
    GW: 'GW',
    GY: 'GY',
    HK: 'HK',
    HM: 'HM',
    HN: 'HN',
    HR: 'HR',
    HT: 'HT',
    HU: 'HU',
    IC: 'IC',
    ID: 'ID',
    IE: 'IE',
    IL: 'IL',
    IM: 'IM',
    IN: 'IN',
    IO: 'IO',
    IQ: 'IQ',
    IR: 'IR',
    IS: 'IS',
    IT: 'IT',
    JE: 'JE',
    JM: 'JM',
    JO: 'JO',
    JP: 'JP',
    KE: 'KE',
    KG: 'KG',
    KH: 'KH',
    KI: 'KI',
    KM: 'KM',
    KN: 'KN',
    KP: 'KP',
    KR: 'KR',
    KW: 'KW',
    KY: 'KY',
    KZ: 'KZ',
    LA: 'LA',
    LB: 'LB',
    LC: 'LC',
    LI: 'LI',
    LK: 'LK',
    LR: 'LR',
    LS: 'LS',
    LT: 'LT',
    LU: 'LU',
    LV: 'LV',
    LY: 'LY',
    MA: 'MA',
    MC: 'MC',
    MD: 'MD',
    ME: 'ME',
    MF: 'MF',
    MG: 'MG',
    MH: 'MH',
    MK: 'MK',
    ML: 'ML',
    MM: 'MM',
    MN: 'MN',
    MO: 'MO',
    MP: 'MP',
    MQ: 'MQ',
    MR: 'MR',
    MS: 'MS',
    MT: 'MT',
    MU: 'MU',
    MV: 'MV',
    MW: 'MW',
    MX: 'MX',
    MY: 'MY',
    MZ: 'MZ',
    NA: 'NA',
    NC: 'NC',
    NE: 'NE',
    NF: 'NF',
    NG: 'NG',
    NI: 'NI',
    NL: 'NL',
    NO: 'NO',
    NP: 'NP',
    NR: 'NR',
    NT: 'NT',
    NU: 'NU',
    NZ: 'NZ',
    OM: 'OM',
    PA: 'PA',
    PE: 'PE',
    PF: 'PF',
    PG: 'PG',
    PH: 'PH',
    PK: 'PK',
    PL: 'PL',
    PM: 'PM',
    PN: 'PN',
    PR: 'PR',
    PS: 'PS',
    PT: 'PT',
    PW: 'PW',
    PY: 'PY',
    QA: 'QA',
    RE: 'RE',
    RO: 'RO',
    RS: 'RS',
    RU: 'RU',
    RW: 'RW',
    SA: 'SA',
    SB: 'SB',
    SC: 'SC',
    SD: 'SD',
    SE: 'SE',
    SF: 'SF',
    SG: 'SG',
    SH: 'SH',
    SI: 'SI',
    SJ: 'SJ',
    SK: 'SK',
    SL: 'SL',
    SM: 'SM',
    SN: 'SN',
    SO: 'SO',
    SR: 'SR',
    SS: 'SS',
    ST: 'ST',
    SU: 'SU',
    SV: 'SV',
    SX: 'SX',
    SY: 'SY',
    SZ: 'SZ',
    TA: 'TA',
    TC: 'TC',
    TD: 'TD',
    TF: 'TF',
    TG: 'TG',
    TH: 'TH',
    TJ: 'TJ',
    TK: 'TK',
    TL: 'TL',
    TM: 'TM',
    TN: 'TN',
    TO: 'TO',
    TP: 'TP',
    TR: 'TR',
    TT: 'TT',
    TV: 'TV',
    TW: 'TW',
    TZ: 'TZ',
    UA: 'UA',
    UG: 'UG',
    UK: 'UK',
    UM: 'UM',
    US: 'US',
    UY: 'UY',
    UZ: 'UZ',
    VA: 'VA',
    VC: 'VC',
    VE: 'VE',
    VG: 'VG',
    VI: 'VI',
    VN: 'VN',
    VU: 'VU',
    WF: 'WF',
    WS: 'WS',
    XI: 'XI',
    XU: 'XU',
    XK: 'XK',
    YE: 'YE',
    YT: 'YT',
    YU: 'YU',
    ZA: 'ZA',
    ZM: 'ZM',
    ZR: 'ZR',
    ZW: 'ZW',
} as const

export type GetAmountRangesParams = {
    country?: GetAmountRangesCountry
}

export type GetUnknownParams = {
    /**
     * the ISO DateTime of the desired snapshot
     */
    snapshot?: string
}

export type GetAllDocumentsParams = {
    /**
     * the ISO DateTime of the desired snapshot
     */
    snapshot?: string
}

export type GetSisCheckParams = {
    /**
     * the ISO DateTime of the desired snapshot
     */
    snapshot?: string
}

export type GetSisChecksParams = {
    /**
     * the ISO DateTime of the desired snapshot
     */
    snapshot?: string
}

export type GetPepCheckParams = {
    /**
     * the ISO DateTime of the desired snapshot
     */
    snapshot?: string
}

export type GetPepChecksParams = {
    /**
     * the ISO DateTime of the desired snapshot
     */
    snapshot?: string
}

export type GetIdvCheckParams = {
    /**
     * the ISO DateTime of the desired snapshot
     */
    snapshot?: string
}

export type GetIdvChecksParams = {
    /**
     * the ISO DateTime of the desired snapshot
     */
    snapshot?: string
}

export type GetEkycCheckParams = {
    /**
     * the ISO DateTime of the desired snapshot
     */
    snapshot?: string
}

export type GetEkycChecksParams = {
    /**
     * the ISO DateTime of the desired snapshot
     */
    snapshot?: string
}

export type GetDocumentFileMetadata1Params = {
    /**
     * the ISO DateTime of the desired snapshot
     */
    snapshot?: string
}

export type GetDocumentFileLink1Params = {
    /**
     * the ISO DateTime of the desired snapshot
     */
    snapshot?: string
}

export type GetDocumentFile1Params = {
    /**
     * the ISO DateTime of the desired snapshot
     */
    snapshot?: string
}

export type CreateDocumentFile1BodyFile = { [key: string]: any }

export type CreateDocumentFile1Body = {
    file: CreateDocumentFile1BodyFile
}

export type GetDocumentFiles1Params = {
    /**
     * the ISO DateTime of the desired snapshot
     */
    snapshot?: string
}

export type GetDocument1Params = {
    /**
     * the ISO DateTime of the desired snapshot
     */
    snapshot?: string
}

export type GetDocuments1Params = {
    /**
     * the ISO DateTime of the desired snapshot
     */
    snapshot?: string
}

export type GetPersonParams = {
    /**
     * the ISO DateTime of the desired snapshot
     */
    snapshot?: string
}

export type GetPersonsParams = {
    /**
     * The identifier of the company or the person (e.g. DK36538686).
     */
    registryId?: string
    /**
     * Person's name
     */
    firstName?: string
    /**
     * Person's name
     */
    middleName?: string
    /**
     * Person's name
     */
    lastName?: string
    /**
     * Page number
     */
    page?: number
    /**
     * Page limit
     */
    limit?: number
}

export type GetPeopleStakeholdersParams = {
    /**
     * Filter to only retrieve entities that are not assigned as directors or shareholders
     */
    orphan?: boolean
}

export type GetCompanyStakeholdersParams = {
    orphan?: boolean
}

export type GetRiskCheckParams = {
    /**
     * the ISO DateTime of the desired snapshot
     */
    snapshot?: string
}

export type GetRiskChecksParams = {
    /**
     * the ISO DateTime of the desired snapshot
     */
    snapshot?: string
}

export type GetRiskAssessmentParams = {
    /**
     * the ISO DateTime of the desired snapshot
     */
    snapshot?: string
}

export type GetKycCheckpointsParams = {
    /**
     * the ISO DateTime of the desired snapshot
     */
    snapshot?: string
}

export type GetAdminsParams = {
    /**
     * the ISO DateTime of the desired snapshot
     */
    snapshot?: string
}

export type GetUbosParams = {
    /**
     * the ISO DateTime of the desired snapshot
     */
    snapshot?: string
    percentage?: number
}

export type GetDocumentFileMetadataParams = {
    /**
     * the ISO DateTime of the desired snapshot
     */
    snapshot?: string
}

export type GetDocumentFileLinkParams = {
    /**
     * the ISO DateTime of the desired snapshot
     */
    snapshot?: string
}

export type GetDocumentFileParams = {
    /**
     * the ISO DateTime of the desired snapshot
     */
    snapshot?: string
}

export type CreateDocumentFileBodyFile = { [key: string]: any }

export type CreateDocumentFileBody = {
    file: CreateDocumentFileBodyFile
}

export type GetDocumentFilesParams = {
    /**
     * the ISO DateTime of the desired snapshot
     */
    snapshot?: string
}

export type GetDocumentParams = {
    /**
     * the ISO DateTime of the desired snapshot
     */
    snapshot?: string
}

export type CreateDocumentParams = {
    /**
     * If autoRetrieve param is present and true, it triggers the retrieve of the file if the document category is supported for the company
     */
    autoRetrieve?: boolean
}

export type GetDocumentsParams = {
    /**
     * the ISO DateTime of the desired snapshot
     */
    snapshot?: string
}

export type GetCompany1Params = {
    /**
     * the ISO DateTime of the desired snapshot
     */
    snapshot?: string
}

export type GetCompaniesParams = {
    /**
     * Company's registration country
     */
    country?: string
    /**
     * Filter company results
     */
    q?: string
    /**
     * Page number
     */
    page?: number
    /**
     * Page limit
     */
    limit?: number
}

export type SupportedCountriesItem =
    (typeof SupportedCountriesItem)[keyof typeof SupportedCountriesItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SupportedCountriesItem = {
    UNDEFINED: 'UNDEFINED',
    AC: 'AC',
    AD: 'AD',
    AE: 'AE',
    AF: 'AF',
    AG: 'AG',
    AI: 'AI',
    AL: 'AL',
    AM: 'AM',
    AN: 'AN',
    AO: 'AO',
    AQ: 'AQ',
    AR: 'AR',
    AS: 'AS',
    AT: 'AT',
    AU: 'AU',
    AW: 'AW',
    AX: 'AX',
    AZ: 'AZ',
    BA: 'BA',
    BB: 'BB',
    BD: 'BD',
    BE: 'BE',
    BF: 'BF',
    BG: 'BG',
    BH: 'BH',
    BI: 'BI',
    BJ: 'BJ',
    BL: 'BL',
    BM: 'BM',
    BN: 'BN',
    BO: 'BO',
    BQ: 'BQ',
    BR: 'BR',
    BS: 'BS',
    BT: 'BT',
    BU: 'BU',
    BV: 'BV',
    BW: 'BW',
    BY: 'BY',
    BZ: 'BZ',
    CA: 'CA',
    CC: 'CC',
    CD: 'CD',
    CF: 'CF',
    CG: 'CG',
    CH: 'CH',
    CI: 'CI',
    CK: 'CK',
    CL: 'CL',
    CM: 'CM',
    CN: 'CN',
    CO: 'CO',
    CP: 'CP',
    CR: 'CR',
    CS: 'CS',
    CU: 'CU',
    CV: 'CV',
    CW: 'CW',
    CX: 'CX',
    CY: 'CY',
    CZ: 'CZ',
    DE: 'DE',
    DG: 'DG',
    DJ: 'DJ',
    DK: 'DK',
    DM: 'DM',
    DO: 'DO',
    DZ: 'DZ',
    EA: 'EA',
    EC: 'EC',
    EE: 'EE',
    EG: 'EG',
    EH: 'EH',
    ER: 'ER',
    ES: 'ES',
    ET: 'ET',
    EU: 'EU',
    EZ: 'EZ',
    FI: 'FI',
    FJ: 'FJ',
    FK: 'FK',
    FM: 'FM',
    FO: 'FO',
    FR: 'FR',
    FX: 'FX',
    GA: 'GA',
    GB: 'GB',
    GD: 'GD',
    GE: 'GE',
    GF: 'GF',
    GG: 'GG',
    GH: 'GH',
    GI: 'GI',
    GL: 'GL',
    GM: 'GM',
    GN: 'GN',
    GP: 'GP',
    GQ: 'GQ',
    GR: 'GR',
    GS: 'GS',
    GT: 'GT',
    GU: 'GU',
    GW: 'GW',
    GY: 'GY',
    HK: 'HK',
    HM: 'HM',
    HN: 'HN',
    HR: 'HR',
    HT: 'HT',
    HU: 'HU',
    IC: 'IC',
    ID: 'ID',
    IE: 'IE',
    IL: 'IL',
    IM: 'IM',
    IN: 'IN',
    IO: 'IO',
    IQ: 'IQ',
    IR: 'IR',
    IS: 'IS',
    IT: 'IT',
    JE: 'JE',
    JM: 'JM',
    JO: 'JO',
    JP: 'JP',
    KE: 'KE',
    KG: 'KG',
    KH: 'KH',
    KI: 'KI',
    KM: 'KM',
    KN: 'KN',
    KP: 'KP',
    KR: 'KR',
    KW: 'KW',
    KY: 'KY',
    KZ: 'KZ',
    LA: 'LA',
    LB: 'LB',
    LC: 'LC',
    LI: 'LI',
    LK: 'LK',
    LR: 'LR',
    LS: 'LS',
    LT: 'LT',
    LU: 'LU',
    LV: 'LV',
    LY: 'LY',
    MA: 'MA',
    MC: 'MC',
    MD: 'MD',
    ME: 'ME',
    MF: 'MF',
    MG: 'MG',
    MH: 'MH',
    MK: 'MK',
    ML: 'ML',
    MM: 'MM',
    MN: 'MN',
    MO: 'MO',
    MP: 'MP',
    MQ: 'MQ',
    MR: 'MR',
    MS: 'MS',
    MT: 'MT',
    MU: 'MU',
    MV: 'MV',
    MW: 'MW',
    MX: 'MX',
    MY: 'MY',
    MZ: 'MZ',
    NA: 'NA',
    NC: 'NC',
    NE: 'NE',
    NF: 'NF',
    NG: 'NG',
    NI: 'NI',
    NL: 'NL',
    NO: 'NO',
    NP: 'NP',
    NR: 'NR',
    NT: 'NT',
    NU: 'NU',
    NZ: 'NZ',
    OM: 'OM',
    PA: 'PA',
    PE: 'PE',
    PF: 'PF',
    PG: 'PG',
    PH: 'PH',
    PK: 'PK',
    PL: 'PL',
    PM: 'PM',
    PN: 'PN',
    PR: 'PR',
    PS: 'PS',
    PT: 'PT',
    PW: 'PW',
    PY: 'PY',
    QA: 'QA',
    RE: 'RE',
    RO: 'RO',
    RS: 'RS',
    RU: 'RU',
    RW: 'RW',
    SA: 'SA',
    SB: 'SB',
    SC: 'SC',
    SD: 'SD',
    SE: 'SE',
    SF: 'SF',
    SG: 'SG',
    SH: 'SH',
    SI: 'SI',
    SJ: 'SJ',
    SK: 'SK',
    SL: 'SL',
    SM: 'SM',
    SN: 'SN',
    SO: 'SO',
    SR: 'SR',
    SS: 'SS',
    ST: 'ST',
    SU: 'SU',
    SV: 'SV',
    SX: 'SX',
    SY: 'SY',
    SZ: 'SZ',
    TA: 'TA',
    TC: 'TC',
    TD: 'TD',
    TF: 'TF',
    TG: 'TG',
    TH: 'TH',
    TJ: 'TJ',
    TK: 'TK',
    TL: 'TL',
    TM: 'TM',
    TN: 'TN',
    TO: 'TO',
    TP: 'TP',
    TR: 'TR',
    TT: 'TT',
    TV: 'TV',
    TW: 'TW',
    TZ: 'TZ',
    UA: 'UA',
    UG: 'UG',
    UK: 'UK',
    UM: 'UM',
    US: 'US',
    UY: 'UY',
    UZ: 'UZ',
    VA: 'VA',
    VC: 'VC',
    VE: 'VE',
    VG: 'VG',
    VI: 'VI',
    VN: 'VN',
    VU: 'VU',
    WF: 'WF',
    WS: 'WS',
    XI: 'XI',
    XU: 'XU',
    XK: 'XK',
    YE: 'YE',
    YT: 'YT',
    YU: 'YU',
    ZA: 'ZA',
    ZM: 'ZM',
    ZR: 'ZR',
    ZW: 'ZW',
} as const

export type SupportedCountriesParams = {
    /**
     * List companies that do not support registration number lookup
     */
    filterNoRegistrationLookUp?: boolean
}

export type GetAndPersistCompanyOnboardedVia =
    (typeof GetAndPersistCompanyOnboardedVia)[keyof typeof GetAndPersistCompanyOnboardedVia]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetAndPersistCompanyOnboardedVia = {
    PLEO: 'PLEO',
    B4B: 'B4B',
} as const

export type GetAndPersistCompanyParams = {
    /**
     * The company's global ID
     */
    companyId: string
    /**
     * The source of the onboarding of a company (e.g. 'PLEO', 'B4B')
     */
    onboardedVia?: GetAndPersistCompanyOnboardedVia
}

export type SearchCompaniesParams = {
    /**
     * Company's registration country
     */
    country: string
    /**
     * Filter company results
     */
    q: string
    /**
     * Page number
     */
    page?: number
    /**
     * Page limit
     */
    limit?: number
}

export interface YearMonthDayResponse {
    day?: number
    month?: number
    year?: number
}

export interface YearMonthDay {
    day?: number
    month?: number
    year?: number
}

export interface UserProfile {
    firstName?: string
    jobTitle?: string
    lastName?: string
}

export type UserAuthenticationAllOf = {
    user?: User
}

export type UserAuthentication = Authentication & UserAuthenticationAllOf

export type UserScopesItem = (typeof UserScopesItem)[keyof typeof UserScopesItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserScopesItem = {
    RESOURCE_BASIC: 'RESOURCE_BASIC',
    RESOURCE_CARD: 'RESOURCE_CARD',
    SYSTEM_ADMIN: 'SYSTEM_ADMIN',
    CARD_DETAILS: 'CARD_DETAILS',
    CARD: 'CARD',
    HUBSPOT_ADMIN: 'HUBSPOT_ADMIN',
    ELEVATED: 'ELEVATED',
    PERMISSIONS: 'PERMISSIONS',
    EXPENSE_APPROVAL: 'EXPENSE_APPROVAL',
    PERMISSION: 'PERMISSION',
} as const

export type UserPayload = { [key: string]: string }

export type UserLanguage = (typeof UserLanguage)[keyof typeof UserLanguage]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserLanguage = {
    'undefined-UNDEFINED': 'undefined-UNDEFINED',
    ar: 'ar',
    'ar-AE': 'ar-AE',
    'ar-BH': 'ar-BH',
    'ar-DZ': 'ar-DZ',
    'ar-EG': 'ar-EG',
    'ar-IQ': 'ar-IQ',
    'ar-JO': 'ar-JO',
    'ar-KW': 'ar-KW',
    'ar-LB': 'ar-LB',
    'ar-LY': 'ar-LY',
    'ar-MA': 'ar-MA',
    'ar-OM': 'ar-OM',
    'ar-QA': 'ar-QA',
    'ar-SA': 'ar-SA',
    'ar-SD': 'ar-SD',
    'ar-SY': 'ar-SY',
    'ar-TN': 'ar-TN',
    'ar-YE': 'ar-YE',
    be: 'be',
    'be-BY': 'be-BY',
    bg: 'bg',
    'bg-BG': 'bg-BG',
    ca: 'ca',
    'ca-ES': 'ca-ES',
    cs: 'cs',
    'cs-CZ': 'cs-CZ',
    da: 'da',
    'da-DK': 'da-DK',
    de: 'de',
    'de-AT': 'de-AT',
    'de-CH': 'de-CH',
    'de-DE': 'de-DE',
    'de-LU': 'de-LU',
    el: 'el',
    'el-CY': 'el-CY',
    'el-GR': 'el-GR',
    en: 'en',
    'en-AU': 'en-AU',
    'en-CA': 'en-CA',
    'en-GB': 'en-GB',
    'en-HK': 'en-HK',
    'en-IE': 'en-IE',
    'en-IN': 'en-IN',
    'en-MT': 'en-MT',
    'en-NZ': 'en-NZ',
    'en-PH': 'en-PH',
    'en-SG': 'en-SG',
    'en-US': 'en-US',
    'en-ZA': 'en-ZA',
    es: 'es',
    'es-AR': 'es-AR',
    'es-BO': 'es-BO',
    'es-CL': 'es-CL',
    'es-CO': 'es-CO',
    'es-CR': 'es-CR',
    'es-DO': 'es-DO',
    'es-EC': 'es-EC',
    'es-ES': 'es-ES',
    'es-GT': 'es-GT',
    'es-HN': 'es-HN',
    'es-MX': 'es-MX',
    'es-NI': 'es-NI',
    'es-PA': 'es-PA',
    'es-PE': 'es-PE',
    'es-PR': 'es-PR',
    'es-PY': 'es-PY',
    'es-SV': 'es-SV',
    'es-US': 'es-US',
    'es-UY': 'es-UY',
    'es-VE': 'es-VE',
    et: 'et',
    'et-EE': 'et-EE',
    fa: 'fa',
    'fa-IR': 'fa-IR',
    fi: 'fi',
    'fi-FI': 'fi-FI',
    fr: 'fr',
    'fr-BE': 'fr-BE',
    'fr-CA': 'fr-CA',
    'fr-CH': 'fr-CH',
    'fr-FR': 'fr-FR',
    'fr-LU': 'fr-LU',
    ga: 'ga',
    'ga-IE': 'ga-IE',
    he: 'he',
    'he-IL': 'he-IL',
    'hi-IN': 'hi-IN',
    hr: 'hr',
    'hr-HR': 'hr-HR',
    hu: 'hu',
    'hu-HU': 'hu-HU',
    id: 'id',
    'id-ID': 'id-ID',
    is: 'is',
    'is-IS': 'is-IS',
    it: 'it',
    'it-CH': 'it-CH',
    'it-IT': 'it-IT',
    ja: 'ja',
    'ja-JP': 'ja-JP',
    'kk-KZ': 'kk-KZ',
    ko: 'ko',
    'ko-KR': 'ko-KR',
    lt: 'lt',
    'lt-LT': 'lt-LT',
    lv: 'lv',
    'lv-LV': 'lv-LV',
    mk: 'mk',
    'mk-MK': 'mk-MK',
    ms: 'ms',
    'ms-MY': 'ms-MY',
    mt: 'mt',
    'mt-MT': 'mt-MT',
    nb: 'nb',
    'nb-NO': 'nb-NO',
    nl: 'nl',
    'nl-BE': 'nl-BE',
    'nl-NL': 'nl-NL',
    'nn-NO': 'nn-NO',
    no: 'no',
    'no-NO': 'no-NO',
    pl: 'pl',
    'pl-PL': 'pl-PL',
    pt: 'pt',
    'pt-BR': 'pt-BR',
    'pt-PT': 'pt-PT',
    ro: 'ro',
    'ro-MD': 'ro-MD',
    'ro-RO': 'ro-RO',
    ru: 'ru',
    'ru-KZ': 'ru-KZ',
    'ru-RU': 'ru-RU',
    se: 'se',
    'se-NO': 'se-NO',
    sk: 'sk',
    'sk-SK': 'sk-SK',
    sl: 'sl',
    'sl-SI': 'sl-SI',
    sq: 'sq',
    'sq-AL': 'sq-AL',
    sr: 'sr',
    'sr-BA': 'sr-BA',
    'sr-CS': 'sr-CS',
    'sr-ME': 'sr-ME',
    'sr-RS': 'sr-RS',
    sv: 'sv',
    'sv-SE': 'sv-SE',
    th: 'th',
    'th-TH': 'th-TH',
    tr: 'tr',
    'tr-TR': 'tr-TR',
    uk: 'uk',
    'uk-UA': 'uk-UA',
    vi: 'vi',
    'vi-VN': 'vi-VN',
    zh: 'zh',
    'zh-CN': 'zh-CN',
    'zh-HK': 'zh-HK',
    'zh-SG': 'zh-SG',
    'zh-TW': 'zh-TW',
} as const

export interface User {
    acceptedTerms?: boolean
    active?: boolean
    authType?: string
    companyId?: string
    companyIds?: string[]
    createdAt?: string
    email?: string
    hasPin?: boolean
    id?: string
    language?: UserLanguage
    name: string
    payload: UserPayload
    permissions: Permission[]
    phone?: string
    phoneVerified?: boolean
    profile?: UserProfile
    scaEnabled?: boolean
    scopes: UserScopesItem[]
    token?: string
    verified?: boolean
}

export interface UploadedFileResponseV2 {
    amlDocumentId?: string
    companyDocumentId?: string
    description?: string
    downloadUri?: string
    fileName?: string
    id: string
    personalDocumentId?: string
    providerId?: string
    title?: string
}

export interface UploadedFileResponse {
    companyDocumentId?: string
    createdAt?: string
    description?: string
    downloadUri?: string
    fileName?: string
    id?: string
    personalDocumentId?: string
    providerId?: string
    title?: string
}

export interface UploadedFileRequest {
    description?: string
    title?: string
}

export interface UpdateResultRequest {
    state: string
}

export interface UpdatePartnerRequest {
    address: PostalAddressModel
    /** Legal name of the partner. Updating will re-trigger screening. */
    legalName: string
    /** Licensing authority of the partner */
    licensingAuthority?: string
    /** Reason for not having a licensing authority */
    noLicensingAuthorityReason?: string
    /** Registry ID of the partner */
    registryId: string
    tradingAddress?: PostalAddressModel
    /** Trading name of the partner. Usually the same as legal name. Updating will re-trigger screening. */
    tradingName?: string
}

export interface UpdateAuthenticatedPersonRequest {
    dateOfBirth?: YearMonthDay
    nationalId?: string
}

export interface UnknownLegalPersonResponse {
    address?: string
    id: string
    name?: string
    registryId: string
}

export interface Unit {
    [key: string]: any
}

export interface UboResponse {
    excluded?: boolean
    foreign?: boolean
    id?: string
    missing?: boolean
    name?: PersonNameModel
    ownershipPercentage?: number
    ownershipPercentageRange?: RangeBigDecimal
}

/**
 * Response for company submitted verification as a DataResponse
 */
export interface SubmittedVerificationDataResponse {
    data: SubmittedVerificationResponse
}

export interface SubmittedVerification {
    createdAt: string
    globalId: string
    hasSubmittedVerification: boolean
    id: string
}

export interface SubmittedVerificationResponse {
    submittedVerification: SubmittedVerification[]
}

export interface SubmittedKycInformationResponse {
    createdAt: string
    documentId?: string
    fileId?: string
    id: string
    kycInformationName: string
    personId: string
    providedBy?: string
    requestForInformationId: string
}

export type SubjectURNType = (typeof SubjectURNType)[keyof typeof SubjectURNType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SubjectURNType = {
    company: 'company',
    organization: 'organization',
    client: 'client',
    partner: 'partner',
} as const

export interface SubjectURN {
    id: string
    type: SubjectURNType
}

export type StaticFundsLimitResponseYearly = { [key: string]: number }

export type StaticFundsLimitResponseDaily = { [key: string]: number }

export interface StaticFundsLimitResponse {
    daily: StaticFundsLimitResponseDaily
    yearly: StaticFundsLimitResponseYearly
}

export interface SourceApprovalDecisionResponse {
    approved: boolean
    decision: string
}

export interface SimplifiedLegalEntity {
    auditingTime?: string
    id: string
}

export type ShareholderResponseType =
    (typeof ShareholderResponseType)[keyof typeof ShareholderResponseType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ShareholderResponseType = {
    COMPANY: 'COMPANY',
    PERSON: 'PERSON',
    UNKNOWN: 'UNKNOWN',
} as const

export type ShareholderResponseShareCurrency =
    (typeof ShareholderResponseShareCurrency)[keyof typeof ShareholderResponseShareCurrency]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ShareholderResponseShareCurrency = {
    UNDEFINED: 'UNDEFINED',
    AED: 'AED',
    AFN: 'AFN',
    ALL: 'ALL',
    AMD: 'AMD',
    ANG: 'ANG',
    AOA: 'AOA',
    ARS: 'ARS',
    AUD: 'AUD',
    AWG: 'AWG',
    AZN: 'AZN',
    BAM: 'BAM',
    BBD: 'BBD',
    BDT: 'BDT',
    BGN: 'BGN',
    BHD: 'BHD',
    BIF: 'BIF',
    BMD: 'BMD',
    BND: 'BND',
    BOB: 'BOB',
    BOV: 'BOV',
    BRL: 'BRL',
    BSD: 'BSD',
    BTN: 'BTN',
    BWP: 'BWP',
    BYN: 'BYN',
    BYR: 'BYR',
    BZD: 'BZD',
    CAD: 'CAD',
    CDF: 'CDF',
    CHE: 'CHE',
    CHF: 'CHF',
    CHW: 'CHW',
    CLF: 'CLF',
    CLP: 'CLP',
    CNY: 'CNY',
    COP: 'COP',
    COU: 'COU',
    CRC: 'CRC',
    CUC: 'CUC',
    CUP: 'CUP',
    CVE: 'CVE',
    CZK: 'CZK',
    DJF: 'DJF',
    DKK: 'DKK',
    DOP: 'DOP',
    DZD: 'DZD',
    EGP: 'EGP',
    ERN: 'ERN',
    ETB: 'ETB',
    EUR: 'EUR',
    FJD: 'FJD',
    FKP: 'FKP',
    GBP: 'GBP',
    GEL: 'GEL',
    GHS: 'GHS',
    GIP: 'GIP',
    GMD: 'GMD',
    GNF: 'GNF',
    GTQ: 'GTQ',
    GYD: 'GYD',
    HKD: 'HKD',
    HNL: 'HNL',
    HRK: 'HRK',
    HTG: 'HTG',
    HUF: 'HUF',
    IDR: 'IDR',
    ILS: 'ILS',
    INR: 'INR',
    IQD: 'IQD',
    IRR: 'IRR',
    ISK: 'ISK',
    JMD: 'JMD',
    JOD: 'JOD',
    JPY: 'JPY',
    KES: 'KES',
    KGS: 'KGS',
    KHR: 'KHR',
    KMF: 'KMF',
    KPW: 'KPW',
    KRW: 'KRW',
    KWD: 'KWD',
    KYD: 'KYD',
    KZT: 'KZT',
    LAK: 'LAK',
    LBP: 'LBP',
    LKR: 'LKR',
    LRD: 'LRD',
    LSL: 'LSL',
    LTL: 'LTL',
    LYD: 'LYD',
    MAD: 'MAD',
    MDL: 'MDL',
    MGA: 'MGA',
    MKD: 'MKD',
    MMK: 'MMK',
    MNT: 'MNT',
    MOP: 'MOP',
    MRO: 'MRO',
    MRU: 'MRU',
    MUR: 'MUR',
    MVR: 'MVR',
    MWK: 'MWK',
    MXN: 'MXN',
    MXV: 'MXV',
    MYR: 'MYR',
    MZN: 'MZN',
    NAD: 'NAD',
    NGN: 'NGN',
    NIO: 'NIO',
    NOK: 'NOK',
    NPR: 'NPR',
    NZD: 'NZD',
    OMR: 'OMR',
    PAB: 'PAB',
    PEN: 'PEN',
    PGK: 'PGK',
    PHP: 'PHP',
    PKR: 'PKR',
    PLN: 'PLN',
    PYG: 'PYG',
    QAR: 'QAR',
    RON: 'RON',
    RSD: 'RSD',
    RUB: 'RUB',
    RUR: 'RUR',
    RWF: 'RWF',
    SAR: 'SAR',
    SBD: 'SBD',
    SCR: 'SCR',
    SDG: 'SDG',
    SEK: 'SEK',
    SGD: 'SGD',
    SHP: 'SHP',
    SLL: 'SLL',
    SOS: 'SOS',
    SRD: 'SRD',
    SSP: 'SSP',
    STD: 'STD',
    STN: 'STN',
    SVC: 'SVC',
    SYP: 'SYP',
    SZL: 'SZL',
    THB: 'THB',
    TJS: 'TJS',
    TMT: 'TMT',
    TND: 'TND',
    TOP: 'TOP',
    TRY: 'TRY',
    TTD: 'TTD',
    TWD: 'TWD',
    TZS: 'TZS',
    UAH: 'UAH',
    UGX: 'UGX',
    USD: 'USD',
    USN: 'USN',
    USS: 'USS',
    UYI: 'UYI',
    UYU: 'UYU',
    UZS: 'UZS',
    VEF: 'VEF',
    VES: 'VES',
    VND: 'VND',
    VUV: 'VUV',
    WST: 'WST',
    XAF: 'XAF',
    XAG: 'XAG',
    XAU: 'XAU',
    XBA: 'XBA',
    XBB: 'XBB',
    XBC: 'XBC',
    XBD: 'XBD',
    XCD: 'XCD',
    XDR: 'XDR',
    XOF: 'XOF',
    XPD: 'XPD',
    XPF: 'XPF',
    XPT: 'XPT',
    XSU: 'XSU',
    XTS: 'XTS',
    XUA: 'XUA',
    XXX: 'XXX',
    YER: 'YER',
    ZAR: 'ZAR',
    ZMW: 'ZMW',
    ZWL: 'ZWL',
} as const

export interface ShareholderResponse {
    category?: string
    legalPersonId?: string
    shareCurrency?: ShareholderResponseShareCurrency
    shareNumber?: number
    sharePercentage?: number
    sharePercentageRange?: RangeBigDecimal
    shareValue?: number
    shareholderId?: string
    type?: ShareholderResponseType
    votingRights?: number
    votingRightsRange?: RangeBigDecimal
}

export type ShareholderRequestType =
    (typeof ShareholderRequestType)[keyof typeof ShareholderRequestType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ShareholderRequestType = {
    COMPANY: 'COMPANY',
    PERSON: 'PERSON',
    UNKNOWN: 'UNKNOWN',
} as const

export type ShareholderRequestShareCurrency =
    (typeof ShareholderRequestShareCurrency)[keyof typeof ShareholderRequestShareCurrency]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ShareholderRequestShareCurrency = {
    UNDEFINED: 'UNDEFINED',
    AED: 'AED',
    AFN: 'AFN',
    ALL: 'ALL',
    AMD: 'AMD',
    ANG: 'ANG',
    AOA: 'AOA',
    ARS: 'ARS',
    AUD: 'AUD',
    AWG: 'AWG',
    AZN: 'AZN',
    BAM: 'BAM',
    BBD: 'BBD',
    BDT: 'BDT',
    BGN: 'BGN',
    BHD: 'BHD',
    BIF: 'BIF',
    BMD: 'BMD',
    BND: 'BND',
    BOB: 'BOB',
    BOV: 'BOV',
    BRL: 'BRL',
    BSD: 'BSD',
    BTN: 'BTN',
    BWP: 'BWP',
    BYN: 'BYN',
    BYR: 'BYR',
    BZD: 'BZD',
    CAD: 'CAD',
    CDF: 'CDF',
    CHE: 'CHE',
    CHF: 'CHF',
    CHW: 'CHW',
    CLF: 'CLF',
    CLP: 'CLP',
    CNY: 'CNY',
    COP: 'COP',
    COU: 'COU',
    CRC: 'CRC',
    CUC: 'CUC',
    CUP: 'CUP',
    CVE: 'CVE',
    CZK: 'CZK',
    DJF: 'DJF',
    DKK: 'DKK',
    DOP: 'DOP',
    DZD: 'DZD',
    EGP: 'EGP',
    ERN: 'ERN',
    ETB: 'ETB',
    EUR: 'EUR',
    FJD: 'FJD',
    FKP: 'FKP',
    GBP: 'GBP',
    GEL: 'GEL',
    GHS: 'GHS',
    GIP: 'GIP',
    GMD: 'GMD',
    GNF: 'GNF',
    GTQ: 'GTQ',
    GYD: 'GYD',
    HKD: 'HKD',
    HNL: 'HNL',
    HRK: 'HRK',
    HTG: 'HTG',
    HUF: 'HUF',
    IDR: 'IDR',
    ILS: 'ILS',
    INR: 'INR',
    IQD: 'IQD',
    IRR: 'IRR',
    ISK: 'ISK',
    JMD: 'JMD',
    JOD: 'JOD',
    JPY: 'JPY',
    KES: 'KES',
    KGS: 'KGS',
    KHR: 'KHR',
    KMF: 'KMF',
    KPW: 'KPW',
    KRW: 'KRW',
    KWD: 'KWD',
    KYD: 'KYD',
    KZT: 'KZT',
    LAK: 'LAK',
    LBP: 'LBP',
    LKR: 'LKR',
    LRD: 'LRD',
    LSL: 'LSL',
    LTL: 'LTL',
    LYD: 'LYD',
    MAD: 'MAD',
    MDL: 'MDL',
    MGA: 'MGA',
    MKD: 'MKD',
    MMK: 'MMK',
    MNT: 'MNT',
    MOP: 'MOP',
    MRO: 'MRO',
    MRU: 'MRU',
    MUR: 'MUR',
    MVR: 'MVR',
    MWK: 'MWK',
    MXN: 'MXN',
    MXV: 'MXV',
    MYR: 'MYR',
    MZN: 'MZN',
    NAD: 'NAD',
    NGN: 'NGN',
    NIO: 'NIO',
    NOK: 'NOK',
    NPR: 'NPR',
    NZD: 'NZD',
    OMR: 'OMR',
    PAB: 'PAB',
    PEN: 'PEN',
    PGK: 'PGK',
    PHP: 'PHP',
    PKR: 'PKR',
    PLN: 'PLN',
    PYG: 'PYG',
    QAR: 'QAR',
    RON: 'RON',
    RSD: 'RSD',
    RUB: 'RUB',
    RUR: 'RUR',
    RWF: 'RWF',
    SAR: 'SAR',
    SBD: 'SBD',
    SCR: 'SCR',
    SDG: 'SDG',
    SEK: 'SEK',
    SGD: 'SGD',
    SHP: 'SHP',
    SLL: 'SLL',
    SOS: 'SOS',
    SRD: 'SRD',
    SSP: 'SSP',
    STD: 'STD',
    STN: 'STN',
    SVC: 'SVC',
    SYP: 'SYP',
    SZL: 'SZL',
    THB: 'THB',
    TJS: 'TJS',
    TMT: 'TMT',
    TND: 'TND',
    TOP: 'TOP',
    TRY: 'TRY',
    TTD: 'TTD',
    TWD: 'TWD',
    TZS: 'TZS',
    UAH: 'UAH',
    UGX: 'UGX',
    USD: 'USD',
    USN: 'USN',
    USS: 'USS',
    UYI: 'UYI',
    UYU: 'UYU',
    UZS: 'UZS',
    VEF: 'VEF',
    VES: 'VES',
    VND: 'VND',
    VUV: 'VUV',
    WST: 'WST',
    XAF: 'XAF',
    XAG: 'XAG',
    XAU: 'XAU',
    XBA: 'XBA',
    XBB: 'XBB',
    XBC: 'XBC',
    XBD: 'XBD',
    XCD: 'XCD',
    XDR: 'XDR',
    XOF: 'XOF',
    XPD: 'XPD',
    XPF: 'XPF',
    XPT: 'XPT',
    XSU: 'XSU',
    XTS: 'XTS',
    XUA: 'XUA',
    XXX: 'XXX',
    YER: 'YER',
    ZAR: 'ZAR',
    ZMW: 'ZMW',
    ZWL: 'ZWL',
} as const

export interface ShareholderRequest {
    category?: string
    legalPersonId: string
    shareCurrency?: ShareholderRequestShareCurrency
    shareNumber?: number
    sharePercentage?: number
    sharePercentageRange?: RangeBigDecimal
    shareValue?: number
    type?: ShareholderRequestType
    votingRights?: number
    votingRightsRange?: RangeBigDecimal
}

export type ShareholderNodeLegalType =
    (typeof ShareholderNodeLegalType)[keyof typeof ShareholderNodeLegalType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ShareholderNodeLegalType = {
    COMPANY: 'COMPANY',
    PERSON: 'PERSON',
    UNKNOWN: 'UNKNOWN',
} as const

export interface ShareholderNode {
    auditingTime?: string
    entity: SimplifiedLegalEntity
    legalType: ShareholderNodeLegalType
    minSharePercentage?: number
    minVotingRights?: number
    parentEntityId?: string
    relativeOwnershipDepth?: number
    relativeSharePercentage?: number
    sharePercentage?: number
    shareholderCategory?: string
    shareholderId?: string
    source?: string
    votingRights?: number
}

export type ShareholderAuditEventUserEmail = { [key: string]: any }

export type ShareholderAuditEventActionTime = { [key: string]: any }

export type ShareholderAuditEventAction =
    (typeof ShareholderAuditEventAction)[keyof typeof ShareholderAuditEventAction]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ShareholderAuditEventAction = {
    CREATE: 'CREATE',
    UPDATE: 'UPDATE',
    DELETE: 'DELETE',
} as const

export interface ShareholderAuditEvent {
    action?: ShareholderAuditEventAction
    actionTime?: ShareholderAuditEventActionTime
    node: ShareholderNode
    parentEntityName?: string
    userEmail?: ShareholderAuditEventUserEmail
}

export interface SearchAddressFixRequest {
    countryCode: string
}

export interface RootCompaniesResponseV2 {
    data: CompanyResponseV2[]
}

export interface RiskScoreResponse {
    companyId?: string
    id: string
    result?: string
    riskAssessment?: string
}

export interface RiskCheckResponse {
    checkDate?: string
    companyId?: string
    id?: string
    notes?: string
    ownerId?: string
    ownerName?: string
    riskType?: string
    riskTypeId?: string
    score?: string
    suggestedScore?: string
    version?: string
}

export type RiskCheckRequestType = (typeof RiskCheckRequestType)[keyof typeof RiskCheckRequestType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RiskCheckRequestType = {
    OWNERSHIP_STRUCTURE: 'OWNERSHIP_STRUCTURE',
    PEP_AND_SANCTIONS: 'PEP_AND_SANCTIONS',
    INCORPORATED_DATE: 'INCORPORATED_DATE',
    CASH_FLOW: 'CASH_FLOW',
    COMPANY_JURISDICTION: 'COMPANY_JURISDICTION',
    END_USER_VERIFICATION: 'END_USER_VERIFICATION',
    BUSINESS_PRODUCTS_OR_SERVICES: 'BUSINESS_PRODUCTS_OR_SERVICES',
    FUNDING_SOURCES: 'FUNDING_SOURCES',
    SALES_CHANNEL: 'SALES_CHANNEL',
    SUPPORT_CHANNEL: 'SUPPORT_CHANNEL',
    UBOS_AND_DIRECTOR_ORIGIN_COUNTRY: 'UBOS_AND_DIRECTOR_ORIGIN_COUNTRY',
} as const

export type RiskCheckRequestScore =
    (typeof RiskCheckRequestScore)[keyof typeof RiskCheckRequestScore]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RiskCheckRequestScore = {
    LOW: 'LOW',
    MEDIUM: 'MEDIUM',
    HIGH: 'HIGH',
    RESTRICTED: 'RESTRICTED',
    PENDING: 'PENDING',
    PASS: 'PASS',
    FAIL: 'FAIL',
    NOT_ASSIGNED: 'NOT_ASSIGNED',
    MEDIUM_HIGH: 'MEDIUM_HIGH',
    MEDIUM_LOW: 'MEDIUM_LOW',
} as const

export interface RiskCheckRequest {
    checkDate?: string
    notes?: string
    ownerId?: string
    ownerName?: string
    score?: RiskCheckRequestScore
    type?: RiskCheckRequestType
    version?: string
}

export interface RiskAssessmentResponse {
    assessmentDate?: string
    checkIds?: string[]
    companyId?: string
    customerRiskRating?: CustomerRiskRating
    notes?: string
    ownerId?: string
    ownerName?: string
    result?: string
    riskAssessmentId?: string
    riskChecks?: RiskCheckResponse[]
    score?: string
    version?: string
}

export type RiskAssessmentRequestScore =
    (typeof RiskAssessmentRequestScore)[keyof typeof RiskAssessmentRequestScore]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RiskAssessmentRequestScore = {
    LOW: 'LOW',
    MEDIUM: 'MEDIUM',
    HIGH: 'HIGH',
    RESTRICTED: 'RESTRICTED',
    PENDING: 'PENDING',
    PASS: 'PASS',
    FAIL: 'FAIL',
    NOT_ASSIGNED: 'NOT_ASSIGNED',
    MEDIUM_HIGH: 'MEDIUM_HIGH',
    MEDIUM_LOW: 'MEDIUM_LOW',
} as const

export type RiskAssessmentRequestResult =
    (typeof RiskAssessmentRequestResult)[keyof typeof RiskAssessmentRequestResult]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RiskAssessmentRequestResult = {
    PASS: 'PASS',
    FAIL: 'FAIL',
} as const

export interface RiskAssessmentRequest {
    assessmentDate?: string
    notes?: string
    ownerId?: string
    ownerName?: string
    result?: RiskAssessmentRequestResult
    score?: RiskAssessmentRequestScore
}

export type RiskAssessmentNotFoundExceptionSuppressedItemStackTraceItem = {
    classLoaderName?: string
    className?: string
    fileName?: string
    lineNumber?: number
    methodName?: string
    moduleName?: string
    moduleVersion?: string
    nativeMethod?: boolean
}

export type RiskAssessmentNotFoundExceptionSuppressedItem = {
    localizedMessage?: string
    message?: string
    stackTrace?: RiskAssessmentNotFoundExceptionSuppressedItemStackTraceItem[]
}

export type RiskAssessmentNotFoundExceptionStackTraceItem = {
    classLoaderName?: string
    className?: string
    fileName?: string
    lineNumber?: number
    methodName?: string
    moduleName?: string
    moduleVersion?: string
    nativeMethod?: boolean
}

export type RiskAssessmentNotFoundExceptionCauseStackTraceItem = {
    classLoaderName?: string
    className?: string
    fileName?: string
    lineNumber?: number
    methodName?: string
    moduleName?: string
    moduleVersion?: string
    nativeMethod?: boolean
}

export type RiskAssessmentNotFoundExceptionCause = {
    localizedMessage?: string
    message?: string
    stackTrace?: RiskAssessmentNotFoundExceptionCauseStackTraceItem[]
}

export interface RiskAssessmentNotFoundException {
    cause?: RiskAssessmentNotFoundExceptionCause
    localizedMessage?: string
    message?: string
    stackTrace?: RiskAssessmentNotFoundExceptionStackTraceItem[]
    suppressed?: RiskAssessmentNotFoundExceptionSuppressedItem[]
    type?: string
}

/**
 * Response for a risk assessment requests as a DataResponse
 */
export interface RiskAssessmentDataResponse {
    data: RiskAssessmentResponse
}

export interface ResetRiskAssessmentRequest {
    kycCheckpointNote?: string
}

export interface RequestedCompanyDocumentResponse {
    category: string
    companyId: string
    documentId: string
    id: string
    provider: string
    providerId: string
    status: string
}

export interface RequestForInformationResponse {
    cancelledAt?: string
    createdAt: string
    createdBy: string
    expirationAt: string
    id: string
    isExpired: boolean
    personId: string
    personName: PersonName
    recipient: string
    status: string
}

export interface RequestForInformationRequest {
    recipient: string
}

export interface RequestForInformationPersonResponse {
    companyName: string
    createdAt: string
    createdBy: string
    expirationAt: string
    id: string
    personId: string
    personName: PersonName
    personRole: string
    status: string
}

export interface RequestForInformationCancelResponse {
    cancelledAt?: string
    id: string
    recipient: string
    status: string
}

export type RegistryCompanyResponseType =
    (typeof RegistryCompanyResponseType)[keyof typeof RegistryCompanyResponseType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RegistryCompanyResponseType = {
    COMPANY: 'COMPANY',
    PERSON: 'PERSON',
    UNKNOWN: 'UNKNOWN',
} as const

export type RegistryCompanyResponseShareCurrency =
    (typeof RegistryCompanyResponseShareCurrency)[keyof typeof RegistryCompanyResponseShareCurrency]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RegistryCompanyResponseShareCurrency = {
    UNDEFINED: 'UNDEFINED',
    AED: 'AED',
    AFN: 'AFN',
    ALL: 'ALL',
    AMD: 'AMD',
    ANG: 'ANG',
    AOA: 'AOA',
    ARS: 'ARS',
    AUD: 'AUD',
    AWG: 'AWG',
    AZN: 'AZN',
    BAM: 'BAM',
    BBD: 'BBD',
    BDT: 'BDT',
    BGN: 'BGN',
    BHD: 'BHD',
    BIF: 'BIF',
    BMD: 'BMD',
    BND: 'BND',
    BOB: 'BOB',
    BOV: 'BOV',
    BRL: 'BRL',
    BSD: 'BSD',
    BTN: 'BTN',
    BWP: 'BWP',
    BYN: 'BYN',
    BYR: 'BYR',
    BZD: 'BZD',
    CAD: 'CAD',
    CDF: 'CDF',
    CHE: 'CHE',
    CHF: 'CHF',
    CHW: 'CHW',
    CLF: 'CLF',
    CLP: 'CLP',
    CNY: 'CNY',
    COP: 'COP',
    COU: 'COU',
    CRC: 'CRC',
    CUC: 'CUC',
    CUP: 'CUP',
    CVE: 'CVE',
    CZK: 'CZK',
    DJF: 'DJF',
    DKK: 'DKK',
    DOP: 'DOP',
    DZD: 'DZD',
    EGP: 'EGP',
    ERN: 'ERN',
    ETB: 'ETB',
    EUR: 'EUR',
    FJD: 'FJD',
    FKP: 'FKP',
    GBP: 'GBP',
    GEL: 'GEL',
    GHS: 'GHS',
    GIP: 'GIP',
    GMD: 'GMD',
    GNF: 'GNF',
    GTQ: 'GTQ',
    GYD: 'GYD',
    HKD: 'HKD',
    HNL: 'HNL',
    HRK: 'HRK',
    HTG: 'HTG',
    HUF: 'HUF',
    IDR: 'IDR',
    ILS: 'ILS',
    INR: 'INR',
    IQD: 'IQD',
    IRR: 'IRR',
    ISK: 'ISK',
    JMD: 'JMD',
    JOD: 'JOD',
    JPY: 'JPY',
    KES: 'KES',
    KGS: 'KGS',
    KHR: 'KHR',
    KMF: 'KMF',
    KPW: 'KPW',
    KRW: 'KRW',
    KWD: 'KWD',
    KYD: 'KYD',
    KZT: 'KZT',
    LAK: 'LAK',
    LBP: 'LBP',
    LKR: 'LKR',
    LRD: 'LRD',
    LSL: 'LSL',
    LTL: 'LTL',
    LYD: 'LYD',
    MAD: 'MAD',
    MDL: 'MDL',
    MGA: 'MGA',
    MKD: 'MKD',
    MMK: 'MMK',
    MNT: 'MNT',
    MOP: 'MOP',
    MRO: 'MRO',
    MRU: 'MRU',
    MUR: 'MUR',
    MVR: 'MVR',
    MWK: 'MWK',
    MXN: 'MXN',
    MXV: 'MXV',
    MYR: 'MYR',
    MZN: 'MZN',
    NAD: 'NAD',
    NGN: 'NGN',
    NIO: 'NIO',
    NOK: 'NOK',
    NPR: 'NPR',
    NZD: 'NZD',
    OMR: 'OMR',
    PAB: 'PAB',
    PEN: 'PEN',
    PGK: 'PGK',
    PHP: 'PHP',
    PKR: 'PKR',
    PLN: 'PLN',
    PYG: 'PYG',
    QAR: 'QAR',
    RON: 'RON',
    RSD: 'RSD',
    RUB: 'RUB',
    RUR: 'RUR',
    RWF: 'RWF',
    SAR: 'SAR',
    SBD: 'SBD',
    SCR: 'SCR',
    SDG: 'SDG',
    SEK: 'SEK',
    SGD: 'SGD',
    SHP: 'SHP',
    SLL: 'SLL',
    SOS: 'SOS',
    SRD: 'SRD',
    SSP: 'SSP',
    STD: 'STD',
    STN: 'STN',
    SVC: 'SVC',
    SYP: 'SYP',
    SZL: 'SZL',
    THB: 'THB',
    TJS: 'TJS',
    TMT: 'TMT',
    TND: 'TND',
    TOP: 'TOP',
    TRY: 'TRY',
    TTD: 'TTD',
    TWD: 'TWD',
    TZS: 'TZS',
    UAH: 'UAH',
    UGX: 'UGX',
    USD: 'USD',
    USN: 'USN',
    USS: 'USS',
    UYI: 'UYI',
    UYU: 'UYU',
    UZS: 'UZS',
    VEF: 'VEF',
    VES: 'VES',
    VND: 'VND',
    VUV: 'VUV',
    WST: 'WST',
    XAF: 'XAF',
    XAG: 'XAG',
    XAU: 'XAU',
    XBA: 'XBA',
    XBB: 'XBB',
    XBC: 'XBC',
    XBD: 'XBD',
    XCD: 'XCD',
    XDR: 'XDR',
    XOF: 'XOF',
    XPD: 'XPD',
    XPF: 'XPF',
    XPT: 'XPT',
    XSU: 'XSU',
    XTS: 'XTS',
    XUA: 'XUA',
    XXX: 'XXX',
    YER: 'YER',
    ZAR: 'ZAR',
    ZMW: 'ZMW',
    ZWL: 'ZWL',
} as const

export interface RangeBigDecimal {
    empty?: boolean
}

export interface RegistryCompanyResponse {
    address?: PostalAddressModel
    aliases?: string[]
    capital?: Money
    id?: string
    incorporationDate?: string
    incorporationType?: string
    legalName?: string
    memorandumDate?: string
    position?: string
    previousName?: string
    previousNameChangeDate?: string
    registrationNumber?: string
    registryId?: string
    registryLinks?: string[]
    shareCurrency?: RegistryCompanyResponseShareCurrency
    shareNumber?: number
    sharePercentage?: number
    sharePercentageRange?: RangeBigDecimal
    shareValue?: number
    shareholderCategory?: string
    status?: string
    type?: RegistryCompanyResponseType
    votingPercentage?: number
    votingPercentageRange?: RangeBigDecimal
}

export interface ProofOfFundsTotalResponse {
    total: number
    transactions: ProofOfFundsResponse[]
}

export type ProofOfFundsResponseRejectionReason =
    (typeof ProofOfFundsResponseRejectionReason)[keyof typeof ProofOfFundsResponseRejectionReason]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProofOfFundsResponseRejectionReason = {
    WRONG_COMPANY: 'WRONG_COMPANY',
    EMONEY_ACCOUNT: 'EMONEY_ACCOUNT',
    PERSONAL_ACCOUNT: 'PERSONAL_ACCOUNT',
    OUTSIDE_ACCEPTANCE_ZONES: 'OUTSIDE_ACCEPTANCE_ZONES',
    FAILED_TO_PROVIDE_INFORMATION: 'FAILED_TO_PROVIDE_INFORMATION',
    OTHER: 'OTHER',
} as const

export type ProofOfFundsResponseRefundStatus =
    (typeof ProofOfFundsResponseRefundStatus)[keyof typeof ProofOfFundsResponseRefundStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProofOfFundsResponseRefundStatus = {
    INITIATING: 'INITIATING',
    INITIATED: 'INITIATED',
    RECEIVED: 'RECEIVED',
    FAILED: 'FAILED',
} as const

export interface ProofOfFundsResponse {
    amount: number
    bankAccountInfo: BankAccountInfo
    companyId: string
    companyName: string
    currency: string
    firstLoad: boolean
    hardBlockMatch?: string
    id: string
    invoiceId?: string
    newSource: boolean
    note?: string
    ownerEmail?: string
    ownerId?: string
    performedAt: string
    refundId?: string
    refundStatus?: ProofOfFundsResponseRefundStatus
    rejectionReason?: ProofOfFundsResponseRejectionReason
    riskAssessment?: string
    senderBank?: string
    senderInformation?: string
    status: string
    transactionId?: string
    updatedAt: string
}

export type ProofOfFundsRequestStatus =
    (typeof ProofOfFundsRequestStatus)[keyof typeof ProofOfFundsRequestStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProofOfFundsRequestStatus = {
    PENDING: 'PENDING',
    INVESTIGATING: 'INVESTIGATING',
    PRE_APPROVED: 'PRE_APPROVED',
    PRE_APPROVED_LEGAL_NAME_CHECK: 'PRE_APPROVED_LEGAL_NAME_CHECK',
    APPROVED: 'APPROVED',
    REJECTED: 'REJECTED',
    REJECTED_REFUND: 'REJECTED_REFUND',
} as const

export type ProofOfFundsRequestRejectionReason =
    (typeof ProofOfFundsRequestRejectionReason)[keyof typeof ProofOfFundsRequestRejectionReason]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProofOfFundsRequestRejectionReason = {
    WRONG_COMPANY: 'WRONG_COMPANY',
    EMONEY_ACCOUNT: 'EMONEY_ACCOUNT',
    PERSONAL_ACCOUNT: 'PERSONAL_ACCOUNT',
    OUTSIDE_ACCEPTANCE_ZONES: 'OUTSIDE_ACCEPTANCE_ZONES',
    FAILED_TO_PROVIDE_INFORMATION: 'FAILED_TO_PROVIDE_INFORMATION',
    OTHER: 'OTHER',
} as const

export interface ProofOfFundsRequest {
    note?: string
    paymentPartyInfo?: PaymentPartyInfo
    rejectionReason?: ProofOfFundsRequestRejectionReason
    status: ProofOfFundsRequestStatus
}

export type ProofOfFundsRejectRefundRequestRejectionReason =
    (typeof ProofOfFundsRejectRefundRequestRejectionReason)[keyof typeof ProofOfFundsRejectRefundRequestRejectionReason]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProofOfFundsRejectRefundRequestRejectionReason = {
    WRONG_COMPANY: 'WRONG_COMPANY',
    EMONEY_ACCOUNT: 'EMONEY_ACCOUNT',
    PERSONAL_ACCOUNT: 'PERSONAL_ACCOUNT',
    OUTSIDE_ACCEPTANCE_ZONES: 'OUTSIDE_ACCEPTANCE_ZONES',
    FAILED_TO_PROVIDE_INFORMATION: 'FAILED_TO_PROVIDE_INFORMATION',
    OTHER: 'OTHER',
} as const

export interface ProofOfFundsRejectRefundRequest {
    bankAccountInfo?: BankAccountInfo
    note?: string
    paymentPartyInfo?: PaymentPartyInfo
    rejectionReason?: ProofOfFundsRejectRefundRequestRejectionReason
}

export interface ProofOfFundsFilterRequest {
    iban?: string
}

export interface ProofOfFundsBasicResponse {
    bankAccountInfo: BankAccountInfo
    bankName?: string
    companyId: string
    currency: string
    id: string
    senderBank?: string
}

export interface PostalAddressResponse {
    addressLine1?: string
    addressLine2?: string
    country?: string
    locality?: string
    postalCode?: string
    region?: string
}

/**
 * The registration address of the partner practice.
 */
export interface PostalAddressModel {
    addressLine1?: string
    addressLine2?: string
    country?: string
    locality?: string
    postalCode?: string
    region?: string
}

export interface PersonalDocumentResponse {
    category?: string
    description?: string
    effectiveDate?: string
    expirationDate?: string
    files?: UploadedFileResponse[]
    hidden?: boolean
    id?: string
    personId?: string
    requestReason?: string
    subCategory?: string
    title?: string
}

export type PersonalDocumentRequestCategory =
    (typeof PersonalDocumentRequestCategory)[keyof typeof PersonalDocumentRequestCategory]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PersonalDocumentRequestCategory = {
    AML_POLICY: 'AML_POLICY',
    ARTICLES_OF_ASSOCIATION: 'ARTICLES_OF_ASSOCIATION',
    CBD_LICENCE: 'CBD_LICENCE',
    CERTIFICATE_TO_OPERATE: 'CERTIFICATE_TO_OPERATE',
    COMPANY_REGISTRATION_CERTIFICATE: 'COMPANY_REGISTRATION_CERTIFICATE',
    DEED_OF_FOUNDATION: 'DEED_OF_FOUNDATION',
    FINANCIAL_STATEMENT: 'FINANCIAL_STATEMENT',
    CERTIFICATE_TO_OPERATE_PRECIOUS_STONES: 'CERTIFICATE_TO_OPERATE_PRECIOUS_STONES',
    LICENCE_TO_OPERATE_GAMBLING_AND_BETTING: 'LICENCE_TO_OPERATE_GAMBLING_AND_BETTING',
    LICENCE_TO_OPERATE_MINING_AND_PRIVATE_SECURITY:
        'LICENCE_TO_OPERATE_MINING_AND_PRIVATE_SECURITY',
    LICENCE_TO_OPERATE_QUARRYING_AND_EXTRACTION: 'LICENCE_TO_OPERATE_QUARRYING_AND_EXTRACTION',
    OTHER: 'OTHER',
    OWNERSHIP: 'OWNERSHIP',
    PEP_FORM: 'PEP_FORM',
    PROOF_OF_ADDRESS: 'PROOF_OF_ADDRESS',
    PROOF_OF_BUSINESS: 'PROOF_OF_BUSINESS',
    PROOF_OF_DIRECTORS: 'PROOF_OF_DIRECTORS',
    PROOF_OF_ID: 'PROOF_OF_ID',
    SHAREHOLDER_REGISTER: 'SHAREHOLDER_REGISTER',
    SOURCE_OF_FUNDS: 'SOURCE_OF_FUNDS',
    TRUST_DEED: 'TRUST_DEED',
    EXTERNAL_HIGH_RISK_APPROVAL: 'EXTERNAL_HIGH_RISK_APPROVAL',
    EXTERNAL_RISK_ASSESSMENT: 'EXTERNAL_RISK_ASSESSMENT',
    SOW_SOF_ASSESSMENT: 'SOW_SOF_ASSESSMENT',
    BUSINESS_REGISTRY_EXTRACT: 'BUSINESS_REGISTRY_EXTRACT',
} as const

export interface PersonalDocumentRequest {
    category: PersonalDocumentRequestCategory
    description?: string
    effectiveDate?: string
    expirationDate?: string
    isHidden: boolean
    requestReason?: string
    subCategory?: string
    title?: string
}

export type PersonalDocumentNotFoundExceptionSuppressedItemStackTraceItem = {
    classLoaderName?: string
    className?: string
    fileName?: string
    lineNumber?: number
    methodName?: string
    moduleName?: string
    moduleVersion?: string
    nativeMethod?: boolean
}

export type PersonalDocumentNotFoundExceptionSuppressedItem = {
    localizedMessage?: string
    message?: string
    stackTrace?: PersonalDocumentNotFoundExceptionSuppressedItemStackTraceItem[]
}

export type PersonalDocumentNotFoundExceptionStackTraceItem = {
    classLoaderName?: string
    className?: string
    fileName?: string
    lineNumber?: number
    methodName?: string
    moduleName?: string
    moduleVersion?: string
    nativeMethod?: boolean
}

export interface PersonalDocumentNotFoundException {
    cause?: PersonalDocumentNotFoundExceptionCause
    entity?: string
    id?: string
    localizedMessage?: string
    message?: string
    stackTrace?: PersonalDocumentNotFoundExceptionStackTraceItem[]
    suppressed?: PersonalDocumentNotFoundExceptionSuppressedItem[]
    type?: string
}

export type PersonalDocumentNotFoundExceptionCauseStackTraceItem = {
    classLoaderName?: string
    className?: string
    fileName?: string
    lineNumber?: number
    methodName?: string
    moduleName?: string
    moduleVersion?: string
    nativeMethod?: boolean
}

export type PersonalDocumentNotFoundExceptionCause = {
    localizedMessage?: string
    message?: string
    stackTrace?: PersonalDocumentNotFoundExceptionCauseStackTraceItem[]
}

export interface PersonVerificationStatusResponse {
    canCreateNewPersonVerification: boolean
}

export interface PersonVerificationResponse {
    createdAt: string
    errors: string[]
    falsePositive: boolean
    id: string
    notes?: string
    personId: string
    provider?: string
    providerId?: string
    providerResponse?: string
    redirectUrl?: string
    result?: string
    updatedAt: string
}

export type PersonVerificationRequestProvider =
    (typeof PersonVerificationRequestProvider)[keyof typeof PersonVerificationRequestProvider]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PersonVerificationRequestProvider = {
    ONFIDO: 'ONFIDO',
    MIT_ID: 'MIT_ID',
    TUPAS: 'TUPAS',
    IDIN: 'IDIN',
    SE_BANK_ID: 'SE_BANK_ID',
    NO_BANK_ID: 'NO_BANK_ID',
    ITS_ME: 'ITS_ME',
} as const

export interface PersonVerificationRequest {
    locale?: string
    provider?: PersonVerificationRequestProvider
    returnUrl: string
}

export interface PersonUpdateRequest {
    address?: DetailedAddressModel
    dateOfBirth?: YearMonthDay
    kycCitizenships?: KycCitizenship[]
    kycNationality?: KycNationality
    nationalId?: string
}

export type PersonRequestDisallowedVerificationProvidersItem =
    (typeof PersonRequestDisallowedVerificationProvidersItem)[keyof typeof PersonRequestDisallowedVerificationProvidersItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PersonRequestDisallowedVerificationProvidersItem = {
    ONFIDO: 'ONFIDO',
    MIT_ID: 'MIT_ID',
    TUPAS: 'TUPAS',
    IDIN: 'IDIN',
    SE_BANK_ID: 'SE_BANK_ID',
    NO_BANK_ID: 'NO_BANK_ID',
    ITS_ME: 'ITS_ME',
} as const

export interface PersonRequest {
    address?: DetailedAddress
    dateOfBirth?: YearMonthDay
    disallowedVerificationProviders?: PersonRequestDisallowedVerificationProvidersItem[]
    gender?: string
    kycCitizenships?: KycCitizenship[]
    kycNationality?: KycNationality
    kycPerson?: boolean
    name?: PersonName
    nationalId?: string
    registryId?: string
    rootCompanyId?: string
}

export interface PersonPepCheck {
    pepAnalysisPerformed: boolean
    personId: string
    personName?: string
}

export type PersonNotFoundExceptionSuppressedItemStackTraceItem = {
    classLoaderName?: string
    className?: string
    fileName?: string
    lineNumber?: number
    methodName?: string
    moduleName?: string
    moduleVersion?: string
    nativeMethod?: boolean
}

export type PersonNotFoundExceptionSuppressedItem = {
    localizedMessage?: string
    message?: string
    stackTrace?: PersonNotFoundExceptionSuppressedItemStackTraceItem[]
}

export type PersonNotFoundExceptionStackTraceItem = {
    classLoaderName?: string
    className?: string
    fileName?: string
    lineNumber?: number
    methodName?: string
    moduleName?: string
    moduleVersion?: string
    nativeMethod?: boolean
}

export interface PersonNotFoundException {
    cause?: PersonNotFoundExceptionCause
    entity?: string
    id?: string
    localizedMessage?: string
    message?: string
    stackTrace?: PersonNotFoundExceptionStackTraceItem[]
    suppressed?: PersonNotFoundExceptionSuppressedItem[]
    type?: string
}

export type PersonNotFoundExceptionCauseStackTraceItem = {
    classLoaderName?: string
    className?: string
    fileName?: string
    lineNumber?: number
    methodName?: string
    moduleName?: string
    moduleVersion?: string
    nativeMethod?: boolean
}

export type PersonNotFoundExceptionCause = {
    localizedMessage?: string
    message?: string
    stackTrace?: PersonNotFoundExceptionCauseStackTraceItem[]
}

export interface PersonNeedsVerificationResponse {
    maximumAttemptsReached: boolean
    needsVerification: boolean
}

export interface PersonNameModel {
    familyName?: string
    givenName?: string
    middleName?: string
    suffix?: string
    title?: string
}

export interface PersonSearchResponse {
    address?: DetailedAddressModel
    company?: CompanyResponseV2
    companyVelocity?: string
    dateOfBirth?: YearMonthDayResponse
    directorOf: string[]
    documents: string[]
    ekycChecks: KycCheckResponse[]
    gender?: string
    id: string
    idvChecks: string[]
    isAdmin?: boolean
    kycPerson: boolean
    name?: PersonNameModel
    nationalId?: string
    pepChecks: KycCheckResponse[]
    registryId?: string
    rootCompanyId?: string
    shareholderOf: string[]
    sisChecks: KycCheckResponse[]
    userId?: string
    verifications: PersonVerificationResponse[]
}

export interface PersonResponseV2 {
    address?: DetailedAddressModel
    dateOfBirth?: YearMonthDayResponse
    directorOf: string[]
    documents: string[]
    ekycChecks: KycCheckResponse[]
    enhancedPepChecks: PepCheckResponse[]
    gender?: string
    id: string
    idvChecks: string[]
    isAdmin?: boolean
    isUbo: boolean
    kycPerson: boolean
    name?: PersonNameModel
    nationalId?: string
    pepChecks: KycCheckResponse[]
    registryId?: string
    rootCompanyId: string
    shareholderOf: string[]
    sisChecks: KycCheckResponse[]
    userId?: string
    verifications: PersonVerificationResponse[]
}

export interface PersonResponseV1 {
    address?: DetailedAddressModel
    dateOfBirth?: YearMonthDayResponse
    directorOf: string[]
    documents: string[]
    ekycChecks: string[]
    gender?: string
    id: string
    idvChecks: string[]
    isAdmin: boolean
    kycCitizenships?: KycCitizenship[]
    kycNationality?: KycNationality
    kycPerson: boolean
    name?: PersonNameModel
    nationalId?: string
    pepChecks?: string[]
    registryId?: string
    rootCompanyId?: string
    shareholderOf: string[]
    sisChecks?: string[]
    userId?: string
    verifications: string[]
}

export interface PersonName {
    familyName?: string
    givenName?: string
    middleName?: string
    suffix?: string
    title?: string
}

export type PermissionType = (typeof PermissionType)[keyof typeof PermissionType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PermissionType = {
    OWNER: 'OWNER',
    MEMBER: 'MEMBER',
    MANAGER: 'MANAGER',
    BOOKKEEPER: 'BOOKKEEPER',
    BOOKKEEPER_BASIC: 'BOOKKEEPER_BASIC',
    BOOKKEEPER_EXTENDED: 'BOOKKEEPER_EXTENDED',
    SIGN_UP_USER: 'SIGN_UP_USER',
} as const

export interface Permission {
    parentResource?: string
    parentResourceId?: string
    resource?: string
    resourceId?: string
    type?: PermissionType
}

export type PepTypePositionCountryCode =
    (typeof PepTypePositionCountryCode)[keyof typeof PepTypePositionCountryCode]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PepTypePositionCountryCode = {
    UNDEFINED: 'UNDEFINED',
    AC: 'AC',
    AD: 'AD',
    AE: 'AE',
    AF: 'AF',
    AG: 'AG',
    AI: 'AI',
    AL: 'AL',
    AM: 'AM',
    AN: 'AN',
    AO: 'AO',
    AQ: 'AQ',
    AR: 'AR',
    AS: 'AS',
    AT: 'AT',
    AU: 'AU',
    AW: 'AW',
    AX: 'AX',
    AZ: 'AZ',
    BA: 'BA',
    BB: 'BB',
    BD: 'BD',
    BE: 'BE',
    BF: 'BF',
    BG: 'BG',
    BH: 'BH',
    BI: 'BI',
    BJ: 'BJ',
    BL: 'BL',
    BM: 'BM',
    BN: 'BN',
    BO: 'BO',
    BQ: 'BQ',
    BR: 'BR',
    BS: 'BS',
    BT: 'BT',
    BU: 'BU',
    BV: 'BV',
    BW: 'BW',
    BY: 'BY',
    BZ: 'BZ',
    CA: 'CA',
    CC: 'CC',
    CD: 'CD',
    CF: 'CF',
    CG: 'CG',
    CH: 'CH',
    CI: 'CI',
    CK: 'CK',
    CL: 'CL',
    CM: 'CM',
    CN: 'CN',
    CO: 'CO',
    CP: 'CP',
    CR: 'CR',
    CS: 'CS',
    CU: 'CU',
    CV: 'CV',
    CW: 'CW',
    CX: 'CX',
    CY: 'CY',
    CZ: 'CZ',
    DE: 'DE',
    DG: 'DG',
    DJ: 'DJ',
    DK: 'DK',
    DM: 'DM',
    DO: 'DO',
    DZ: 'DZ',
    EA: 'EA',
    EC: 'EC',
    EE: 'EE',
    EG: 'EG',
    EH: 'EH',
    ER: 'ER',
    ES: 'ES',
    ET: 'ET',
    EU: 'EU',
    EZ: 'EZ',
    FI: 'FI',
    FJ: 'FJ',
    FK: 'FK',
    FM: 'FM',
    FO: 'FO',
    FR: 'FR',
    FX: 'FX',
    GA: 'GA',
    GB: 'GB',
    GD: 'GD',
    GE: 'GE',
    GF: 'GF',
    GG: 'GG',
    GH: 'GH',
    GI: 'GI',
    GL: 'GL',
    GM: 'GM',
    GN: 'GN',
    GP: 'GP',
    GQ: 'GQ',
    GR: 'GR',
    GS: 'GS',
    GT: 'GT',
    GU: 'GU',
    GW: 'GW',
    GY: 'GY',
    HK: 'HK',
    HM: 'HM',
    HN: 'HN',
    HR: 'HR',
    HT: 'HT',
    HU: 'HU',
    IC: 'IC',
    ID: 'ID',
    IE: 'IE',
    IL: 'IL',
    IM: 'IM',
    IN: 'IN',
    IO: 'IO',
    IQ: 'IQ',
    IR: 'IR',
    IS: 'IS',
    IT: 'IT',
    JE: 'JE',
    JM: 'JM',
    JO: 'JO',
    JP: 'JP',
    KE: 'KE',
    KG: 'KG',
    KH: 'KH',
    KI: 'KI',
    KM: 'KM',
    KN: 'KN',
    KP: 'KP',
    KR: 'KR',
    KW: 'KW',
    KY: 'KY',
    KZ: 'KZ',
    LA: 'LA',
    LB: 'LB',
    LC: 'LC',
    LI: 'LI',
    LK: 'LK',
    LR: 'LR',
    LS: 'LS',
    LT: 'LT',
    LU: 'LU',
    LV: 'LV',
    LY: 'LY',
    MA: 'MA',
    MC: 'MC',
    MD: 'MD',
    ME: 'ME',
    MF: 'MF',
    MG: 'MG',
    MH: 'MH',
    MK: 'MK',
    ML: 'ML',
    MM: 'MM',
    MN: 'MN',
    MO: 'MO',
    MP: 'MP',
    MQ: 'MQ',
    MR: 'MR',
    MS: 'MS',
    MT: 'MT',
    MU: 'MU',
    MV: 'MV',
    MW: 'MW',
    MX: 'MX',
    MY: 'MY',
    MZ: 'MZ',
    NA: 'NA',
    NC: 'NC',
    NE: 'NE',
    NF: 'NF',
    NG: 'NG',
    NI: 'NI',
    NL: 'NL',
    NO: 'NO',
    NP: 'NP',
    NR: 'NR',
    NT: 'NT',
    NU: 'NU',
    NZ: 'NZ',
    OM: 'OM',
    PA: 'PA',
    PE: 'PE',
    PF: 'PF',
    PG: 'PG',
    PH: 'PH',
    PK: 'PK',
    PL: 'PL',
    PM: 'PM',
    PN: 'PN',
    PR: 'PR',
    PS: 'PS',
    PT: 'PT',
    PW: 'PW',
    PY: 'PY',
    QA: 'QA',
    RE: 'RE',
    RO: 'RO',
    RS: 'RS',
    RU: 'RU',
    RW: 'RW',
    SA: 'SA',
    SB: 'SB',
    SC: 'SC',
    SD: 'SD',
    SE: 'SE',
    SF: 'SF',
    SG: 'SG',
    SH: 'SH',
    SI: 'SI',
    SJ: 'SJ',
    SK: 'SK',
    SL: 'SL',
    SM: 'SM',
    SN: 'SN',
    SO: 'SO',
    SR: 'SR',
    SS: 'SS',
    ST: 'ST',
    SU: 'SU',
    SV: 'SV',
    SX: 'SX',
    SY: 'SY',
    SZ: 'SZ',
    TA: 'TA',
    TC: 'TC',
    TD: 'TD',
    TF: 'TF',
    TG: 'TG',
    TH: 'TH',
    TJ: 'TJ',
    TK: 'TK',
    TL: 'TL',
    TM: 'TM',
    TN: 'TN',
    TO: 'TO',
    TP: 'TP',
    TR: 'TR',
    TT: 'TT',
    TV: 'TV',
    TW: 'TW',
    TZ: 'TZ',
    UA: 'UA',
    UG: 'UG',
    UK: 'UK',
    UM: 'UM',
    US: 'US',
    UY: 'UY',
    UZ: 'UZ',
    VA: 'VA',
    VC: 'VC',
    VE: 'VE',
    VG: 'VG',
    VI: 'VI',
    VN: 'VN',
    VU: 'VU',
    WF: 'WF',
    WS: 'WS',
    XI: 'XI',
    XU: 'XU',
    XK: 'XK',
    YE: 'YE',
    YT: 'YT',
    YU: 'YU',
    ZA: 'ZA',
    ZM: 'ZM',
    ZR: 'ZR',
    ZW: 'ZW',
} as const

export interface PepType {
    level?: number
    position?: string
    positionCountryCode?: PepTypePositionCountryCode
    type?: string
}

export interface PepMatchResponse {
    addresses?: string[]
    datesOfBirth?: string[]
    fullName?: string
    matchScore?: number
    pepTypes?: PepType[]
}

export interface PepCheckResponse {
    createdAt: string
    expiresAt?: string
    falsePositive?: boolean
    id: string
    pepMatches?: PepMatchResponse[]
    pepTypes?: PepType[]
    provider?: string
    providerId?: string
    recurringMonitoring: boolean
    response?: string
    result: string
    updatedAt: string
}

export type PaymentPartyInfoCountry =
    (typeof PaymentPartyInfoCountry)[keyof typeof PaymentPartyInfoCountry]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PaymentPartyInfoCountry = {
    UNDEFINED: 'UNDEFINED',
    AC: 'AC',
    AD: 'AD',
    AE: 'AE',
    AF: 'AF',
    AG: 'AG',
    AI: 'AI',
    AL: 'AL',
    AM: 'AM',
    AN: 'AN',
    AO: 'AO',
    AQ: 'AQ',
    AR: 'AR',
    AS: 'AS',
    AT: 'AT',
    AU: 'AU',
    AW: 'AW',
    AX: 'AX',
    AZ: 'AZ',
    BA: 'BA',
    BB: 'BB',
    BD: 'BD',
    BE: 'BE',
    BF: 'BF',
    BG: 'BG',
    BH: 'BH',
    BI: 'BI',
    BJ: 'BJ',
    BL: 'BL',
    BM: 'BM',
    BN: 'BN',
    BO: 'BO',
    BQ: 'BQ',
    BR: 'BR',
    BS: 'BS',
    BT: 'BT',
    BU: 'BU',
    BV: 'BV',
    BW: 'BW',
    BY: 'BY',
    BZ: 'BZ',
    CA: 'CA',
    CC: 'CC',
    CD: 'CD',
    CF: 'CF',
    CG: 'CG',
    CH: 'CH',
    CI: 'CI',
    CK: 'CK',
    CL: 'CL',
    CM: 'CM',
    CN: 'CN',
    CO: 'CO',
    CP: 'CP',
    CR: 'CR',
    CS: 'CS',
    CU: 'CU',
    CV: 'CV',
    CW: 'CW',
    CX: 'CX',
    CY: 'CY',
    CZ: 'CZ',
    DE: 'DE',
    DG: 'DG',
    DJ: 'DJ',
    DK: 'DK',
    DM: 'DM',
    DO: 'DO',
    DZ: 'DZ',
    EA: 'EA',
    EC: 'EC',
    EE: 'EE',
    EG: 'EG',
    EH: 'EH',
    ER: 'ER',
    ES: 'ES',
    ET: 'ET',
    EU: 'EU',
    EZ: 'EZ',
    FI: 'FI',
    FJ: 'FJ',
    FK: 'FK',
    FM: 'FM',
    FO: 'FO',
    FR: 'FR',
    FX: 'FX',
    GA: 'GA',
    GB: 'GB',
    GD: 'GD',
    GE: 'GE',
    GF: 'GF',
    GG: 'GG',
    GH: 'GH',
    GI: 'GI',
    GL: 'GL',
    GM: 'GM',
    GN: 'GN',
    GP: 'GP',
    GQ: 'GQ',
    GR: 'GR',
    GS: 'GS',
    GT: 'GT',
    GU: 'GU',
    GW: 'GW',
    GY: 'GY',
    HK: 'HK',
    HM: 'HM',
    HN: 'HN',
    HR: 'HR',
    HT: 'HT',
    HU: 'HU',
    IC: 'IC',
    ID: 'ID',
    IE: 'IE',
    IL: 'IL',
    IM: 'IM',
    IN: 'IN',
    IO: 'IO',
    IQ: 'IQ',
    IR: 'IR',
    IS: 'IS',
    IT: 'IT',
    JE: 'JE',
    JM: 'JM',
    JO: 'JO',
    JP: 'JP',
    KE: 'KE',
    KG: 'KG',
    KH: 'KH',
    KI: 'KI',
    KM: 'KM',
    KN: 'KN',
    KP: 'KP',
    KR: 'KR',
    KW: 'KW',
    KY: 'KY',
    KZ: 'KZ',
    LA: 'LA',
    LB: 'LB',
    LC: 'LC',
    LI: 'LI',
    LK: 'LK',
    LR: 'LR',
    LS: 'LS',
    LT: 'LT',
    LU: 'LU',
    LV: 'LV',
    LY: 'LY',
    MA: 'MA',
    MC: 'MC',
    MD: 'MD',
    ME: 'ME',
    MF: 'MF',
    MG: 'MG',
    MH: 'MH',
    MK: 'MK',
    ML: 'ML',
    MM: 'MM',
    MN: 'MN',
    MO: 'MO',
    MP: 'MP',
    MQ: 'MQ',
    MR: 'MR',
    MS: 'MS',
    MT: 'MT',
    MU: 'MU',
    MV: 'MV',
    MW: 'MW',
    MX: 'MX',
    MY: 'MY',
    MZ: 'MZ',
    NA: 'NA',
    NC: 'NC',
    NE: 'NE',
    NF: 'NF',
    NG: 'NG',
    NI: 'NI',
    NL: 'NL',
    NO: 'NO',
    NP: 'NP',
    NR: 'NR',
    NT: 'NT',
    NU: 'NU',
    NZ: 'NZ',
    OM: 'OM',
    PA: 'PA',
    PE: 'PE',
    PF: 'PF',
    PG: 'PG',
    PH: 'PH',
    PK: 'PK',
    PL: 'PL',
    PM: 'PM',
    PN: 'PN',
    PR: 'PR',
    PS: 'PS',
    PT: 'PT',
    PW: 'PW',
    PY: 'PY',
    QA: 'QA',
    RE: 'RE',
    RO: 'RO',
    RS: 'RS',
    RU: 'RU',
    RW: 'RW',
    SA: 'SA',
    SB: 'SB',
    SC: 'SC',
    SD: 'SD',
    SE: 'SE',
    SF: 'SF',
    SG: 'SG',
    SH: 'SH',
    SI: 'SI',
    SJ: 'SJ',
    SK: 'SK',
    SL: 'SL',
    SM: 'SM',
    SN: 'SN',
    SO: 'SO',
    SR: 'SR',
    SS: 'SS',
    ST: 'ST',
    SU: 'SU',
    SV: 'SV',
    SX: 'SX',
    SY: 'SY',
    SZ: 'SZ',
    TA: 'TA',
    TC: 'TC',
    TD: 'TD',
    TF: 'TF',
    TG: 'TG',
    TH: 'TH',
    TJ: 'TJ',
    TK: 'TK',
    TL: 'TL',
    TM: 'TM',
    TN: 'TN',
    TO: 'TO',
    TP: 'TP',
    TR: 'TR',
    TT: 'TT',
    TV: 'TV',
    TW: 'TW',
    TZ: 'TZ',
    UA: 'UA',
    UG: 'UG',
    UK: 'UK',
    UM: 'UM',
    US: 'US',
    UY: 'UY',
    UZ: 'UZ',
    VA: 'VA',
    VC: 'VC',
    VE: 'VE',
    VG: 'VG',
    VI: 'VI',
    VN: 'VN',
    VU: 'VU',
    WF: 'WF',
    WS: 'WS',
    XI: 'XI',
    XU: 'XU',
    XK: 'XK',
    YE: 'YE',
    YT: 'YT',
    YU: 'YU',
    ZA: 'ZA',
    ZM: 'ZM',
    ZR: 'ZR',
    ZW: 'ZW',
} as const

export interface PaymentPartyInfo {
    country?: PaymentPartyInfoCountry
}

/**
 * This is the response for the partner details. For partners with own Pleo companies some data comes from the company.
 */
export interface PartnerResponse {
    address: PostalAddressModel
    /** The id of the company that the partner is associated with. Note: this is the internal Styx company ID. */
    companyId: string
    /** The global ID of the partner */
    id: string
    /** The legal name of the partner practice */
    legalName: string
    /** The authority that has issued the license to the partner practice. Typically determined by the country of the partner practice. */
    licensingAuthority?: string
    /** The registry ID of the partner practice */
    registryId?: string
    tradingAddress?: PostalAddressModel
    /** The trading name of the partner practice */
    tradingName?: string
}

export interface NarrativeResponse {
    category: string
    hidden?: boolean
    id: string
    narrative?: string
    person?: PersonResponseV2
}

export interface NarrativeRequest {
    category: string
    hidden: boolean
    narrative: string
    personId?: string
}

export interface NaceDivision {
    code: string
    description: string
    groups: NaceGroup[]
}

export interface NaceSection {
    divisions: NaceDivision[]
    section: string
    title: string
}

export interface NaceClass {
    code: string
    description: string
    requiresMoreDocuments: boolean
}

export interface NaceGroup {
    classes: NaceClass[]
    code: string
    description: string
}

export interface Money {
    currency?: string
    value?: number
}

export interface ModeloVisiblityRequest {
    hide: boolean
}

export interface KypInformationMetadata {
    documentDescription?: string
    documentTitle?: string
    subjectName?: string
}

export type KypInformationType = (typeof KypInformationType)[keyof typeof KypInformationType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const KypInformationType = {
    DOCUMENT: 'DOCUMENT',
    DATA: 'DATA',
} as const

export type KypInformationSubject =
    (typeof KypInformationSubject)[keyof typeof KypInformationSubject]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const KypInformationSubject = {
    PARTNER: 'PARTNER',
} as const

export type KypInformationRequiredFor =
    (typeof KypInformationRequiredFor)[keyof typeof KypInformationRequiredFor]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const KypInformationRequiredFor = {
    FDD: 'FDD',
} as const

export type KypInformationName = (typeof KypInformationName)[keyof typeof KypInformationName]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const KypInformationName = {
    ADDRESS: 'ADDRESS',
    COMPANY_REGISTRATION_CERTIFICATE: 'COMPANY_REGISTRATION_CERTIFICATE',
    TRADING_NAME: 'TRADING_NAME',
    TRADING_ADDRESS: 'TRADING_ADDRESS',
    OTHER_DOCUMENT: 'OTHER_DOCUMENT',
} as const

export type KypInformationActionableBy =
    (typeof KypInformationActionableBy)[keyof typeof KypInformationActionableBy]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const KypInformationActionableBy = {
    PARTNER_ADMIN: 'PARTNER_ADMIN',
    COMPLIANCE_OFFICER: 'COMPLIANCE_OFFICER',
} as const

export interface KypInformation {
    actionableBy: KypInformationActionableBy
    details?: string
    documentId?: string
    metadata?: KypInformationMetadata
    name: KypInformationName
    requiredFor: KypInformationRequiredFor
    subject: KypInformationSubject
    subjectId: string
    type: KypInformationType
}

export interface KycVerificationDetailsResponse {
    adminDetails: KycPersonVerificationDetailsResponse[]
    hasCompanyName: boolean
    hasCompanySourceOfWealthNarrative: boolean
    hasCountriesOfOperation: boolean
    hasIncorporationCountry: boolean
    hasIncorporationDate: boolean
    hasIncorporationType: boolean
    hasMinimumNumberOfDirectorsInScope: boolean
    hasNaceCode: boolean
    hasOrgStructure: boolean
    hasRegisteredAddress: boolean
    hasRegistrationNumber: boolean
    hasSourceOfRevenue: boolean
    hasTradingAddress: boolean
    hasTradingName: boolean
    hasUBOSourceOfWealthNarrative: boolean
    hasWebsite: boolean
    inScopePersonsDetails: KycPersonVerificationDetailsResponse[]
    pepAnalysis: PersonPepCheck[]
}

export interface KycUserResponseResponse {
    hasCompanySubmittedVAT?: boolean
    hasConfirmedDirectors?: boolean
    hasConfirmedKycCompanyDetails?: boolean
    hasConfirmedKycCompanyStructure?: boolean
    hasConfirmedNoVAT?: boolean
    hasCorrectUboList?: boolean
    hasCountriesOfOperation?: boolean
    hasTradingAddress?: boolean
    hasTradingName?: boolean
    hasWebsite?: boolean
    updatedDate: string
}

export interface KycUserResponseRequest {
    hasCompanySubmittedVAT?: boolean
    hasConfirmedDirectors?: boolean
    hasConfirmedKycCompanyDetails?: boolean
    hasConfirmedKycCompanyStructure?: boolean
    hasConfirmedNoVAT?: boolean
    hasCorrectUboList?: boolean
    hasCountriesOfOperation?: boolean
    hasTradingAddress?: boolean
    hasTradingName?: boolean
    hasWebsite?: boolean
}

export interface KycStatusResponse {
    companyId: string
    id: string
    level: string
    nextReview?: string
    notes?: string
    performed: string
    userEmail?: string
    userId?: string
}

export type KycStatusRequestLevel =
    (typeof KycStatusRequestLevel)[keyof typeof KycStatusRequestLevel]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const KycStatusRequestLevel = {
    UNSET: 'UNSET',
    SDD: 'SDD',
    FDD: 'FDD',
    EDD: 'EDD',
    HEDD: 'HEDD',
    HEDD1: 'HEDD1',
} as const

export interface KycStatusRequest {
    ignoreRiskAssessmentStatus?: boolean
    level?: KycStatusRequestLevel
    nextReview?: string
    notes?: string
    performed?: string
    userEmail?: string
    userId?: string
}

export interface KycPersonVerificationDetailsResponse {
    hasAddress: boolean
    hasCitizenship: boolean
    hasDateOfBirth: boolean
    hasName: boolean
    hasNationality: boolean
    personId: string
    personName?: string
}

export type KycNationalitySource = (typeof KycNationalitySource)[keyof typeof KycNationalitySource]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const KycNationalitySource = {
    COMPANIES_HOUSE: 'COMPANIES_HOUSE',
    CREDITSAFE_GLOBAL: 'CREDITSAFE_GLOBAL',
    CREDITSAFE_SWEDEN: 'CREDITSAFE_SWEDEN',
    VIRK: 'VIRK',
    VEMBI: 'VEMBI',
    BVD: 'BVD',
    USER_INPUT: 'USER_INPUT',
    DUE_DILIGENCE_AGENT_INPUT: 'DUE_DILIGENCE_AGENT_INPUT',
} as const

export interface KycNationality {
    nationality: string
    source: KycNationalitySource
}

export interface KycInformationNameSourceResponse {
    kycInformationName: string
    source: string
    updatedAt: string
}

/**
 * Response for KYC information name source data as a DataResponse
 */
export interface KycInformationNameSourceDataResponse {
    data: KycInformationNameSourceResponse
}

export interface KycInformationMetadata {
    documentDescription?: string
    documentTitle?: string
    subjectName?: string
}

export type KycInformationType = (typeof KycInformationType)[keyof typeof KycInformationType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const KycInformationType = {
    DOCUMENT: 'DOCUMENT',
    DATA: 'DATA',
} as const

export type KycInformationSubject =
    (typeof KycInformationSubject)[keyof typeof KycInformationSubject]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const KycInformationSubject = {
    COMPANY: 'COMPANY',
    PERSON: 'PERSON',
} as const

export type KycInformationRequiredFor =
    (typeof KycInformationRequiredFor)[keyof typeof KycInformationRequiredFor]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const KycInformationRequiredFor = {
    SDD: 'SDD',
    FDD: 'FDD',
    WALLET_LOAD: 'WALLET_LOAD',
} as const

export type KycInformationName = (typeof KycInformationName)[keyof typeof KycInformationName]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const KycInformationName = {
    BANK_VERIFICATION_DETAILS: 'BANK_VERIFICATION_DETAILS',
    AML_ANSWERS: 'AML_ANSWERS',
    ADDRESS: 'ADDRESS',
    PERSONS_IN_SCOPE: 'PERSONS_IN_SCOPE',
    STRUCTURE: 'STRUCTURE',
    NATIONAL_ID: 'NATIONAL_ID',
    BIRTH_DATE: 'BIRTH_DATE',
    PROOF_OF_ADDRESS: 'PROOF_OF_ADDRESS',
    PROOF_OF_ID: 'PROOF_OF_ID',
    COMPANY_REGISTRATION_CERTIFICATE: 'COMPANY_REGISTRATION_CERTIFICATE',
    FINANCIAL_STATEMENT: 'FINANCIAL_STATEMENT',
    OWNERSHIP: 'OWNERSHIP',
    PROOF_OF_BUSINESS: 'PROOF_OF_BUSINESS',
    SOURCE_OF_FUNDS: 'SOURCE_OF_FUNDS',
    ARTICLES_OF_ASSOCIATION: 'ARTICLES_OF_ASSOCIATION',
    PROOF_OF_DIRECTORS: 'PROOF_OF_DIRECTORS',
    OTHER_DOCUMENT: 'OTHER_DOCUMENT',
    BUSINESS_EXPLANATION: 'BUSINESS_EXPLANATION',
    VERIFIED_ADMIN: 'VERIFIED_ADMIN',
    DEED_OF_FOUNDATION: 'DEED_OF_FOUNDATION',
    COUNTRY_OF_RESIDENCE: 'COUNTRY_OF_RESIDENCE',
    CASH_FLOW_STATEMENT: 'CASH_FLOW_STATEMENT',
    CBD_LICENCE: 'CBD_LICENCE',
    CERTIFICATE_TO_OPERATE: 'CERTIFICATE_TO_OPERATE',
    AML_POLICY: 'AML_POLICY',
    CERTIFICATE_TO_OPERATE_PRECIOUS_STONES: 'CERTIFICATE_TO_OPERATE_PRECIOUS_STONES',
    LICENCE_TO_OPERATE_GAMBLING_AND_BETTING: 'LICENCE_TO_OPERATE_GAMBLING_AND_BETTING',
    LICENCE_TO_OPERATE_MINING_AND_PRIVATE_SECURITY:
        'LICENCE_TO_OPERATE_MINING_AND_PRIVATE_SECURITY',
    LICENCE_TO_OPERATE_QUARRYING_AND_EXTRACTION: 'LICENCE_TO_OPERATE_QUARRYING_AND_EXTRACTION',
    SHAREHOLDER_REGISTER: 'SHAREHOLDER_REGISTER',
    TRUST_DEED: 'TRUST_DEED',
    MANUFACTURING_COUNTRIES: 'MANUFACTURING_COUNTRIES',
    NATIONALITY: 'NATIONALITY',
    COMPANY_REVENUE: 'COMPANY_REVENUE',
    TRADING_NAME: 'TRADING_NAME',
    TRADING_ADDRESS: 'TRADING_ADDRESS',
    UBO_CONFIRMATION: 'UBO_CONFIRMATION',
    DIRECTOR_CONFIRMATION: 'DIRECTOR_CONFIRMATION',
    INDUSTRY_CODE: 'INDUSTRY_CODE',
    PEP_FORM: 'PEP_FORM',
    WEBSITE: 'WEBSITE',
    CITIZENSHIP: 'CITIZENSHIP',
    COUNTRIES_OF_OPERATION: 'COUNTRIES_OF_OPERATION',
    EXTERNAL_HIGH_RISK_APPROVAL: 'EXTERNAL_HIGH_RISK_APPROVAL',
    EXTERNAL_RISK_ASSESSMENT: 'EXTERNAL_RISK_ASSESSMENT',
    SOW_SOF_ASSESSMENT: 'SOW_SOF_ASSESSMENT',
    BUSINESS_REGISTRY_EXTRACT: 'BUSINESS_REGISTRY_EXTRACT',
    AML_ANSWERS_CONTINENTS: 'AML_ANSWERS_CONTINENTS',
    AML_ANSWERS_CATEGORIES: 'AML_ANSWERS_CATEGORIES',
    AML_ANSWERS_AMOUNT_RANGE: 'AML_ANSWERS_AMOUNT_RANGE',
    AML_ANSWERS_FREQUENTLY_USED_IN_COUNTRIES: 'AML_ANSWERS_FREQUENTLY_USED_IN_COUNTRIES',
    AML_ANSWERS_AVERAGE_AMOUNT_RANGE: 'AML_ANSWERS_AVERAGE_AMOUNT_RANGE',
} as const

export type KycInformationActionableBy =
    (typeof KycInformationActionableBy)[keyof typeof KycInformationActionableBy]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const KycInformationActionableBy = {
    COMPANY_ADMIN: 'COMPANY_ADMIN',
    COMPLIANCE_OFFICER: 'COMPLIANCE_OFFICER',
} as const

export interface KycInformation {
    actionableBy: KycInformationActionableBy
    details?: string
    documentId?: string
    metadata?: KycInformationMetadata
    name: KycInformationName
    requiredFor: KycInformationRequiredFor
    subject: KycInformationSubject
    subjectId: string
    type: KycInformationType
}

export type KycCitizenshipSource = (typeof KycCitizenshipSource)[keyof typeof KycCitizenshipSource]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const KycCitizenshipSource = {
    COMPANIES_HOUSE: 'COMPANIES_HOUSE',
    CREDITSAFE_GLOBAL: 'CREDITSAFE_GLOBAL',
    CREDITSAFE_SWEDEN: 'CREDITSAFE_SWEDEN',
    VIRK: 'VIRK',
    VEMBI: 'VEMBI',
    BVD: 'BVD',
    USER_INPUT: 'USER_INPUT',
    DUE_DILIGENCE_AGENT_INPUT: 'DUE_DILIGENCE_AGENT_INPUT',
} as const

export interface KycCitizenship {
    citizenships: string[]
    source: KycCitizenshipSource
}

export interface KycCheckUpdateRequest {
    falsePositive: boolean
}

export interface KycCheckResponse {
    createdAt: string
    expiresAt?: string
    falsePositive?: boolean
    id: string
    provider?: string
    providerId?: string
    recurringMonitoring: boolean
    response?: string
    result: string
    updatedAt: string
}

export interface KycCheckAttemptsResponse {
    count: number
}

export interface JsonNode {
    [key: string]: any
}

export interface KycCheckRequest {
    providerId?: string
    response?: JsonNode
    result?: string
    transactionMessage?: string
}

export interface KycCheck {
    created?: string
    expiresAt?: string
    falsePositive?: boolean
    id?: string
    processedValue?: string
    provider?: string
    providerId?: string
    recurringMonitoring?: boolean
    request?: string
    response?: JsonNode
    result?: string
    transactionMessage?: string
    updated?: string
}

export type InvalidateCacheRequestCurrencyCode =
    (typeof InvalidateCacheRequestCurrencyCode)[keyof typeof InvalidateCacheRequestCurrencyCode]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InvalidateCacheRequestCurrencyCode = {
    UNDEFINED: 'UNDEFINED',
    AED: 'AED',
    AFN: 'AFN',
    ALL: 'ALL',
    AMD: 'AMD',
    ANG: 'ANG',
    AOA: 'AOA',
    ARS: 'ARS',
    AUD: 'AUD',
    AWG: 'AWG',
    AZN: 'AZN',
    BAM: 'BAM',
    BBD: 'BBD',
    BDT: 'BDT',
    BGN: 'BGN',
    BHD: 'BHD',
    BIF: 'BIF',
    BMD: 'BMD',
    BND: 'BND',
    BOB: 'BOB',
    BOV: 'BOV',
    BRL: 'BRL',
    BSD: 'BSD',
    BTN: 'BTN',
    BWP: 'BWP',
    BYN: 'BYN',
    BYR: 'BYR',
    BZD: 'BZD',
    CAD: 'CAD',
    CDF: 'CDF',
    CHE: 'CHE',
    CHF: 'CHF',
    CHW: 'CHW',
    CLF: 'CLF',
    CLP: 'CLP',
    CNY: 'CNY',
    COP: 'COP',
    COU: 'COU',
    CRC: 'CRC',
    CUC: 'CUC',
    CUP: 'CUP',
    CVE: 'CVE',
    CZK: 'CZK',
    DJF: 'DJF',
    DKK: 'DKK',
    DOP: 'DOP',
    DZD: 'DZD',
    EGP: 'EGP',
    ERN: 'ERN',
    ETB: 'ETB',
    EUR: 'EUR',
    FJD: 'FJD',
    FKP: 'FKP',
    GBP: 'GBP',
    GEL: 'GEL',
    GHS: 'GHS',
    GIP: 'GIP',
    GMD: 'GMD',
    GNF: 'GNF',
    GTQ: 'GTQ',
    GYD: 'GYD',
    HKD: 'HKD',
    HNL: 'HNL',
    HRK: 'HRK',
    HTG: 'HTG',
    HUF: 'HUF',
    IDR: 'IDR',
    ILS: 'ILS',
    INR: 'INR',
    IQD: 'IQD',
    IRR: 'IRR',
    ISK: 'ISK',
    JMD: 'JMD',
    JOD: 'JOD',
    JPY: 'JPY',
    KES: 'KES',
    KGS: 'KGS',
    KHR: 'KHR',
    KMF: 'KMF',
    KPW: 'KPW',
    KRW: 'KRW',
    KWD: 'KWD',
    KYD: 'KYD',
    KZT: 'KZT',
    LAK: 'LAK',
    LBP: 'LBP',
    LKR: 'LKR',
    LRD: 'LRD',
    LSL: 'LSL',
    LTL: 'LTL',
    LYD: 'LYD',
    MAD: 'MAD',
    MDL: 'MDL',
    MGA: 'MGA',
    MKD: 'MKD',
    MMK: 'MMK',
    MNT: 'MNT',
    MOP: 'MOP',
    MRO: 'MRO',
    MRU: 'MRU',
    MUR: 'MUR',
    MVR: 'MVR',
    MWK: 'MWK',
    MXN: 'MXN',
    MXV: 'MXV',
    MYR: 'MYR',
    MZN: 'MZN',
    NAD: 'NAD',
    NGN: 'NGN',
    NIO: 'NIO',
    NOK: 'NOK',
    NPR: 'NPR',
    NZD: 'NZD',
    OMR: 'OMR',
    PAB: 'PAB',
    PEN: 'PEN',
    PGK: 'PGK',
    PHP: 'PHP',
    PKR: 'PKR',
    PLN: 'PLN',
    PYG: 'PYG',
    QAR: 'QAR',
    RON: 'RON',
    RSD: 'RSD',
    RUB: 'RUB',
    RUR: 'RUR',
    RWF: 'RWF',
    SAR: 'SAR',
    SBD: 'SBD',
    SCR: 'SCR',
    SDG: 'SDG',
    SEK: 'SEK',
    SGD: 'SGD',
    SHP: 'SHP',
    SLL: 'SLL',
    SOS: 'SOS',
    SRD: 'SRD',
    SSP: 'SSP',
    STD: 'STD',
    STN: 'STN',
    SVC: 'SVC',
    SYP: 'SYP',
    SZL: 'SZL',
    THB: 'THB',
    TJS: 'TJS',
    TMT: 'TMT',
    TND: 'TND',
    TOP: 'TOP',
    TRY: 'TRY',
    TTD: 'TTD',
    TWD: 'TWD',
    TZS: 'TZS',
    UAH: 'UAH',
    UGX: 'UGX',
    USD: 'USD',
    USN: 'USN',
    USS: 'USS',
    UYI: 'UYI',
    UYU: 'UYU',
    UZS: 'UZS',
    VEF: 'VEF',
    VES: 'VES',
    VND: 'VND',
    VUV: 'VUV',
    WST: 'WST',
    XAF: 'XAF',
    XAG: 'XAG',
    XAU: 'XAU',
    XBA: 'XBA',
    XBB: 'XBB',
    XBC: 'XBC',
    XBD: 'XBD',
    XCD: 'XCD',
    XDR: 'XDR',
    XOF: 'XOF',
    XPD: 'XPD',
    XPF: 'XPF',
    XPT: 'XPT',
    XSU: 'XSU',
    XTS: 'XTS',
    XUA: 'XUA',
    XXX: 'XXX',
    YER: 'YER',
    ZAR: 'ZAR',
    ZMW: 'ZMW',
    ZWL: 'ZWL',
} as const

export interface InvalidateCacheRequest {
    currencyCode: InvalidateCacheRequestCurrencyCode
}

export type InvalidRequestExceptionSuppressedItemStackTraceItem = {
    classLoaderName?: string
    className?: string
    fileName?: string
    lineNumber?: number
    methodName?: string
    moduleName?: string
    moduleVersion?: string
    nativeMethod?: boolean
}

export type InvalidRequestExceptionSuppressedItem = {
    localizedMessage?: string
    message?: string
    stackTrace?: InvalidRequestExceptionSuppressedItemStackTraceItem[]
}

export type InvalidRequestExceptionStackTraceItem = {
    classLoaderName?: string
    className?: string
    fileName?: string
    lineNumber?: number
    methodName?: string
    moduleName?: string
    moduleVersion?: string
    nativeMethod?: boolean
}

export type InvalidRequestExceptionCauseStackTraceItem = {
    classLoaderName?: string
    className?: string
    fileName?: string
    lineNumber?: number
    methodName?: string
    moduleName?: string
    moduleVersion?: string
    nativeMethod?: boolean
}

export type InvalidRequestExceptionCause = {
    localizedMessage?: string
    message?: string
    stackTrace?: InvalidRequestExceptionCauseStackTraceItem[]
}

export interface InvalidRequestException {
    cause?: InvalidRequestExceptionCause
    localizedMessage?: string
    message?: string
    reason: string
    stackTrace?: InvalidRequestExceptionStackTraceItem[]
    suppressed?: InvalidRequestExceptionSuppressedItem[]
    type?: string
}

export type InvalidPageValueExceptionSuppressedItemStackTraceItem = {
    classLoaderName?: string
    className?: string
    fileName?: string
    lineNumber?: number
    methodName?: string
    moduleName?: string
    moduleVersion?: string
    nativeMethod?: boolean
}

export type InvalidPageValueExceptionSuppressedItem = {
    localizedMessage?: string
    message?: string
    stackTrace?: InvalidPageValueExceptionSuppressedItemStackTraceItem[]
}

export type InvalidPageValueExceptionStackTraceItem = {
    classLoaderName?: string
    className?: string
    fileName?: string
    lineNumber?: number
    methodName?: string
    moduleName?: string
    moduleVersion?: string
    nativeMethod?: boolean
}

export type InvalidPageValueExceptionCauseStackTraceItem = {
    classLoaderName?: string
    className?: string
    fileName?: string
    lineNumber?: number
    methodName?: string
    moduleName?: string
    moduleVersion?: string
    nativeMethod?: boolean
}

export type InvalidPageValueExceptionCause = {
    localizedMessage?: string
    message?: string
    stackTrace?: InvalidPageValueExceptionCauseStackTraceItem[]
}

export interface InvalidPageValueException {
    cause?: InvalidPageValueExceptionCause
    localizedMessage?: string
    message?: string
    page?: number
    stackTrace?: InvalidPageValueExceptionStackTraceItem[]
    suppressed?: InvalidPageValueExceptionSuppressedItem[]
    type?: string
}

export type InvalidLimitValueExceptionSuppressedItemStackTraceItem = {
    classLoaderName?: string
    className?: string
    fileName?: string
    lineNumber?: number
    methodName?: string
    moduleName?: string
    moduleVersion?: string
    nativeMethod?: boolean
}

export type InvalidLimitValueExceptionSuppressedItem = {
    localizedMessage?: string
    message?: string
    stackTrace?: InvalidLimitValueExceptionSuppressedItemStackTraceItem[]
}

export type InvalidLimitValueExceptionStackTraceItem = {
    classLoaderName?: string
    className?: string
    fileName?: string
    lineNumber?: number
    methodName?: string
    moduleName?: string
    moduleVersion?: string
    nativeMethod?: boolean
}

export interface InvalidLimitValueException {
    cause?: InvalidLimitValueExceptionCause
    limit?: number
    localizedMessage?: string
    message?: string
    stackTrace?: InvalidLimitValueExceptionStackTraceItem[]
    suppressed?: InvalidLimitValueExceptionSuppressedItem[]
    type?: string
}

export type InvalidLimitValueExceptionCauseStackTraceItem = {
    classLoaderName?: string
    className?: string
    fileName?: string
    lineNumber?: number
    methodName?: string
    moduleName?: string
    moduleVersion?: string
    nativeMethod?: boolean
}

export type InvalidLimitValueExceptionCause = {
    localizedMessage?: string
    message?: string
    stackTrace?: InvalidLimitValueExceptionCauseStackTraceItem[]
}

export interface InstantParam {
    instant: string
}

export type IndustryCodeType = (typeof IndustryCodeType)[keyof typeof IndustryCodeType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const IndustryCodeType = {
    ATECO2007: 'ATECO2007',
    BRANCHE_KODE: 'BRANCHE_KODE',
    CAE: 'CAE',
    CNAE: 'CNAE',
    NACE: 'NACE',
    NACEBEL: 'NACEBEL',
    NACELUX: 'NACELUX',
    NAF: 'NAF',
    ONACE2008: 'ONACE2008',
    SBI: 'SBI',
    SIC: 'SIC',
    SNI: 'SNI',
    WZ: 'WZ',
} as const

export interface IndustryCode {
    code: string
    description?: string
    sequence: number
    source?: string
    type: IndustryCodeType
}

export interface IdvCheckResponse {
    created?: string
    documents?: PersonalDocumentResponse[]
    expiresAt?: string
    id?: string
    personId?: string
    providerId?: string
    request?: string
    response?: string
    result?: string
    transactionMessage?: string
    updated?: string
}

export interface GetUpdatedPersonVerificationRequest {
    locale?: string
    returnUrl: string
}

export type FundsLimitsResponseYearly = { [key: string]: number }

export type FundsLimitsResponseDaily = { [key: string]: number }

export interface FundsLimitsResponse {
    daily: FundsLimitsResponseDaily
    reason?: string
    yearly: FundsLimitsResponseYearly
}

export interface FileMetadataResponse {
    filename?: string
}

export interface FileDownloadLinkResponse {
    url?: string
}

export interface FalsePositiveRequest {
    falsePositive: boolean
}

export interface ExternalClientScope {
    action: string
    resource: string
}

export interface ExternalClient {
    id?: string
    name?: string
    redirectUris: string[]
    resourceId?: string
    scope: ExternalClientScope[]
    subjectURN?: SubjectURN
}

export type ExternalClientAuthenticationAllOf = {
    externalClient?: ExternalClient
}

export type ExternalClientAuthentication = Authentication & ExternalClientAuthenticationAllOf

export interface ExceptionInfo {
    message?: string
    type: string
}

export type DirectorResponseType = (typeof DirectorResponseType)[keyof typeof DirectorResponseType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DirectorResponseType = {
    COMPANY: 'COMPANY',
    PERSON: 'PERSON',
    UNKNOWN: 'UNKNOWN',
} as const

export interface DirectorResponse {
    directorId?: string
    legalPersonId: string
    position?: string
    type: DirectorResponseType
}

export type DirectorRequestType = (typeof DirectorRequestType)[keyof typeof DirectorRequestType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DirectorRequestType = {
    COMPANY: 'COMPANY',
    PERSON: 'PERSON',
    UNKNOWN: 'UNKNOWN',
} as const

export interface DirectorRequest {
    legalPersonId: string
    position?: string
    type: DirectorRequestType
}

export interface DetailedAddressModel {
    country?: string
    floor?: string
    houseNumber?: string
    locality?: string
    postalCode?: string
    region?: string
    street?: string
}

export interface DetailedAddress {
    country?: string
    floor?: string
    freeText?: string
    houseNumber?: string
    locality?: string
    postalCode?: string
    region?: string
    street?: string
}

export interface DataResponseSubmittedVerificationResponse {
    data: SubmittedVerificationResponse
}

export interface DataResponseSetString {
    data: string[]
}

export interface DataResponseSetKypInformation {
    data: KypInformation[]
}

export interface DataResponsePersonResponseV2 {
    data: PersonResponseV2
}

export interface DataResponsePartnerResponse {
    data: PartnerResponse
}

export interface DataResponseKycInformationNameSourceResponse {
    data: KycInformationNameSourceResponse
}

export interface DataResponseCompanyVerificationFlowResponse {
    data: CompanyVerificationFlowResponse
}

export interface CustomerRiskRating {
    factors: number
    factorsProcessed: number
    numericScore: number
    score: string
}

export interface CursorPaginationResponse {
    endCursor?: string
    hasNextPage: boolean
    hasPreviousPage: boolean
    startCursor?: string
}

export interface CursorPaginationDataResponsePersonSearchResponse {
    data: PersonSearchResponse[]
    pagination: CursorPaginationResponse
}

export interface CursorPaginationDataResponseCompanyResponseV2 {
    data: CompanyResponseV2[]
    pagination: CursorPaginationResponse
}

export interface CreateProofOfFundsRequest {
    amount: Money
    companyId: string
    id: string
    invoiceId: string
    performedAt: string
    senderBank?: string
    senderInformation?: string
}

/**
 * Request to create a new partner using the user input. This is for partners that are not in the registry.
 */
export interface CreatePartnerRequest {
    /** The size of the partners company in the Pleo format. E.g. 2-10 */
    companySize: string
    /** The global ID of the partner */
    id: string
    /** The legal name of the partner practice */
    legalName: string
    postalAddressModel: PostalAddressModel
    /** The registration number of the partner practice. */
    registrationNumber: string
}

export type CreateAuthenticatedPersonRequestCountry =
    (typeof CreateAuthenticatedPersonRequestCountry)[keyof typeof CreateAuthenticatedPersonRequestCountry]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateAuthenticatedPersonRequestCountry = {
    UNDEFINED: 'UNDEFINED',
    AC: 'AC',
    AD: 'AD',
    AE: 'AE',
    AF: 'AF',
    AG: 'AG',
    AI: 'AI',
    AL: 'AL',
    AM: 'AM',
    AN: 'AN',
    AO: 'AO',
    AQ: 'AQ',
    AR: 'AR',
    AS: 'AS',
    AT: 'AT',
    AU: 'AU',
    AW: 'AW',
    AX: 'AX',
    AZ: 'AZ',
    BA: 'BA',
    BB: 'BB',
    BD: 'BD',
    BE: 'BE',
    BF: 'BF',
    BG: 'BG',
    BH: 'BH',
    BI: 'BI',
    BJ: 'BJ',
    BL: 'BL',
    BM: 'BM',
    BN: 'BN',
    BO: 'BO',
    BQ: 'BQ',
    BR: 'BR',
    BS: 'BS',
    BT: 'BT',
    BU: 'BU',
    BV: 'BV',
    BW: 'BW',
    BY: 'BY',
    BZ: 'BZ',
    CA: 'CA',
    CC: 'CC',
    CD: 'CD',
    CF: 'CF',
    CG: 'CG',
    CH: 'CH',
    CI: 'CI',
    CK: 'CK',
    CL: 'CL',
    CM: 'CM',
    CN: 'CN',
    CO: 'CO',
    CP: 'CP',
    CR: 'CR',
    CS: 'CS',
    CU: 'CU',
    CV: 'CV',
    CW: 'CW',
    CX: 'CX',
    CY: 'CY',
    CZ: 'CZ',
    DE: 'DE',
    DG: 'DG',
    DJ: 'DJ',
    DK: 'DK',
    DM: 'DM',
    DO: 'DO',
    DZ: 'DZ',
    EA: 'EA',
    EC: 'EC',
    EE: 'EE',
    EG: 'EG',
    EH: 'EH',
    ER: 'ER',
    ES: 'ES',
    ET: 'ET',
    EU: 'EU',
    EZ: 'EZ',
    FI: 'FI',
    FJ: 'FJ',
    FK: 'FK',
    FM: 'FM',
    FO: 'FO',
    FR: 'FR',
    FX: 'FX',
    GA: 'GA',
    GB: 'GB',
    GD: 'GD',
    GE: 'GE',
    GF: 'GF',
    GG: 'GG',
    GH: 'GH',
    GI: 'GI',
    GL: 'GL',
    GM: 'GM',
    GN: 'GN',
    GP: 'GP',
    GQ: 'GQ',
    GR: 'GR',
    GS: 'GS',
    GT: 'GT',
    GU: 'GU',
    GW: 'GW',
    GY: 'GY',
    HK: 'HK',
    HM: 'HM',
    HN: 'HN',
    HR: 'HR',
    HT: 'HT',
    HU: 'HU',
    IC: 'IC',
    ID: 'ID',
    IE: 'IE',
    IL: 'IL',
    IM: 'IM',
    IN: 'IN',
    IO: 'IO',
    IQ: 'IQ',
    IR: 'IR',
    IS: 'IS',
    IT: 'IT',
    JE: 'JE',
    JM: 'JM',
    JO: 'JO',
    JP: 'JP',
    KE: 'KE',
    KG: 'KG',
    KH: 'KH',
    KI: 'KI',
    KM: 'KM',
    KN: 'KN',
    KP: 'KP',
    KR: 'KR',
    KW: 'KW',
    KY: 'KY',
    KZ: 'KZ',
    LA: 'LA',
    LB: 'LB',
    LC: 'LC',
    LI: 'LI',
    LK: 'LK',
    LR: 'LR',
    LS: 'LS',
    LT: 'LT',
    LU: 'LU',
    LV: 'LV',
    LY: 'LY',
    MA: 'MA',
    MC: 'MC',
    MD: 'MD',
    ME: 'ME',
    MF: 'MF',
    MG: 'MG',
    MH: 'MH',
    MK: 'MK',
    ML: 'ML',
    MM: 'MM',
    MN: 'MN',
    MO: 'MO',
    MP: 'MP',
    MQ: 'MQ',
    MR: 'MR',
    MS: 'MS',
    MT: 'MT',
    MU: 'MU',
    MV: 'MV',
    MW: 'MW',
    MX: 'MX',
    MY: 'MY',
    MZ: 'MZ',
    NA: 'NA',
    NC: 'NC',
    NE: 'NE',
    NF: 'NF',
    NG: 'NG',
    NI: 'NI',
    NL: 'NL',
    NO: 'NO',
    NP: 'NP',
    NR: 'NR',
    NT: 'NT',
    NU: 'NU',
    NZ: 'NZ',
    OM: 'OM',
    PA: 'PA',
    PE: 'PE',
    PF: 'PF',
    PG: 'PG',
    PH: 'PH',
    PK: 'PK',
    PL: 'PL',
    PM: 'PM',
    PN: 'PN',
    PR: 'PR',
    PS: 'PS',
    PT: 'PT',
    PW: 'PW',
    PY: 'PY',
    QA: 'QA',
    RE: 'RE',
    RO: 'RO',
    RS: 'RS',
    RU: 'RU',
    RW: 'RW',
    SA: 'SA',
    SB: 'SB',
    SC: 'SC',
    SD: 'SD',
    SE: 'SE',
    SF: 'SF',
    SG: 'SG',
    SH: 'SH',
    SI: 'SI',
    SJ: 'SJ',
    SK: 'SK',
    SL: 'SL',
    SM: 'SM',
    SN: 'SN',
    SO: 'SO',
    SR: 'SR',
    SS: 'SS',
    ST: 'ST',
    SU: 'SU',
    SV: 'SV',
    SX: 'SX',
    SY: 'SY',
    SZ: 'SZ',
    TA: 'TA',
    TC: 'TC',
    TD: 'TD',
    TF: 'TF',
    TG: 'TG',
    TH: 'TH',
    TJ: 'TJ',
    TK: 'TK',
    TL: 'TL',
    TM: 'TM',
    TN: 'TN',
    TO: 'TO',
    TP: 'TP',
    TR: 'TR',
    TT: 'TT',
    TV: 'TV',
    TW: 'TW',
    TZ: 'TZ',
    UA: 'UA',
    UG: 'UG',
    UK: 'UK',
    UM: 'UM',
    US: 'US',
    UY: 'UY',
    UZ: 'UZ',
    VA: 'VA',
    VC: 'VC',
    VE: 'VE',
    VG: 'VG',
    VI: 'VI',
    VN: 'VN',
    VU: 'VU',
    WF: 'WF',
    WS: 'WS',
    XI: 'XI',
    XU: 'XU',
    XK: 'XK',
    YE: 'YE',
    YT: 'YT',
    YU: 'YU',
    ZA: 'ZA',
    ZM: 'ZM',
    ZR: 'ZR',
    ZW: 'ZW',
} as const

export interface CreateAuthenticatedPersonRequest {
    companyId?: string
    country?: CreateAuthenticatedPersonRequestCountry
    dateOfBirth?: YearMonthDay
    name: PersonName
    nationalId?: string
}

export interface CountrySearchDeletionRequest {
    countryCode: string
}

export interface CopyPersonRequest {
    originalCompanyId: string
    personToCopyId: string
    personToCopyIntoId: string
}

/**
 * Response for company verification flow
 */
export interface CompanyVerificationFlowResponse {
    /** is the company verification flow in progress */
    inProgress: boolean
    /** last updated time of the company verification flow */
    updatedAt: string
}

export interface CompanyVerificationFlowRequest {
    inProgress: boolean
}

/**
 * Response for company verification flow data as a DataResponse
 */
export interface CompanyVerificationFlowDataResponse {
    data: CompanyVerificationFlowResponse
}

export type CompanyStakeholderResponseCompanyStakeholderTypeItem =
    (typeof CompanyStakeholderResponseCompanyStakeholderTypeItem)[keyof typeof CompanyStakeholderResponseCompanyStakeholderTypeItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyStakeholderResponseCompanyStakeholderTypeItem = {
    UBO: 'UBO',
    DIRECTOR: 'DIRECTOR',
    ADMIN: 'ADMIN',
    SHAREHOLDER: 'SHAREHOLDER',
} as const

export interface CompanyStakeholderResponse {
    address?: DetailedAddressModel
    adverseMediaChecks: KycCheckResponse[]
    companyDocuments: CompanyDocumentResponse[]
    companyStakeholderType?: CompanyStakeholderResponseCompanyStakeholderTypeItem[]
    dateOfBirth?: YearMonthDayResponse
    directorOf: string[]
    documents: PersonalDocumentResponse[]
    ekycChecks: KycCheckResponse[]
    enhancedPepChecks: PepCheckResponse[]
    entityType: string
    excluded: boolean
    foreign: boolean
    gender?: string
    globalId?: string
    id: string
    idvChecks: KycCheckResponse[]
    isAdmin?: boolean
    isUbo: boolean
    kycCitizenships?: KycCitizenship[]
    kycNationality?: KycNationality
    kycPerson: boolean
    legalName?: string
    missing: boolean
    name?: PersonNameModel
    nationalId?: string
    ownershipPercentage?: number
    ownershipPercentageRange?: RangeBigDecimal
    pepChecks: KycCheckResponse[]
    registryId?: string
    roles: string[]
    rootCompanyId?: string
    sanctionChecks: KycCheckResponse[]
    shareholderOf: string[]
    sisChecks: KycCheckResponse[]
    tradingName?: string
    userId?: string
    verifications: PersonVerificationResponse[]
}

export type CompanyRiskDataPointsKycLevel =
    (typeof CompanyRiskDataPointsKycLevel)[keyof typeof CompanyRiskDataPointsKycLevel]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyRiskDataPointsKycLevel = {
    UNSET: 'UNSET',
    SDD: 'SDD',
    FDD: 'FDD',
    EDD: 'EDD',
    HEDD: 'HEDD',
    HEDD1: 'HEDD1',
} as const

export interface CompanyRiskDataPoints {
    address?: PostalAddressResponse
    adverseMediaChecks: KycCheckResponse[]
    amlAnswers?: AmlAnswersResponse
    domain?: string
    id: string
    incorporationDate?: string
    incorporationType?: string
    industryCodes: IndustryCode[]
    kycLevel?: CompanyRiskDataPointsKycLevel
    legalName: string
    legalNameHistory: string[]
    persons: PersonResponseV2[]
    registryId?: string
    relatedPersons: PersonResponseV2[]
    sanctionChecks: KycCheckResponse[]
    styxId: string
    ubos: PersonResponseV2[]
}

export interface CompanyReviewStatusResponse {
    hasPendingReview: boolean
    readyForReview: boolean
    reviewed: boolean
}

export type CompanyRevenueResponseCurrency =
    (typeof CompanyRevenueResponseCurrency)[keyof typeof CompanyRevenueResponseCurrency]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyRevenueResponseCurrency = {
    UNDEFINED: 'UNDEFINED',
    AED: 'AED',
    AFN: 'AFN',
    ALL: 'ALL',
    AMD: 'AMD',
    ANG: 'ANG',
    AOA: 'AOA',
    ARS: 'ARS',
    AUD: 'AUD',
    AWG: 'AWG',
    AZN: 'AZN',
    BAM: 'BAM',
    BBD: 'BBD',
    BDT: 'BDT',
    BGN: 'BGN',
    BHD: 'BHD',
    BIF: 'BIF',
    BMD: 'BMD',
    BND: 'BND',
    BOB: 'BOB',
    BOV: 'BOV',
    BRL: 'BRL',
    BSD: 'BSD',
    BTN: 'BTN',
    BWP: 'BWP',
    BYN: 'BYN',
    BYR: 'BYR',
    BZD: 'BZD',
    CAD: 'CAD',
    CDF: 'CDF',
    CHE: 'CHE',
    CHF: 'CHF',
    CHW: 'CHW',
    CLF: 'CLF',
    CLP: 'CLP',
    CNY: 'CNY',
    COP: 'COP',
    COU: 'COU',
    CRC: 'CRC',
    CUC: 'CUC',
    CUP: 'CUP',
    CVE: 'CVE',
    CZK: 'CZK',
    DJF: 'DJF',
    DKK: 'DKK',
    DOP: 'DOP',
    DZD: 'DZD',
    EGP: 'EGP',
    ERN: 'ERN',
    ETB: 'ETB',
    EUR: 'EUR',
    FJD: 'FJD',
    FKP: 'FKP',
    GBP: 'GBP',
    GEL: 'GEL',
    GHS: 'GHS',
    GIP: 'GIP',
    GMD: 'GMD',
    GNF: 'GNF',
    GTQ: 'GTQ',
    GYD: 'GYD',
    HKD: 'HKD',
    HNL: 'HNL',
    HRK: 'HRK',
    HTG: 'HTG',
    HUF: 'HUF',
    IDR: 'IDR',
    ILS: 'ILS',
    INR: 'INR',
    IQD: 'IQD',
    IRR: 'IRR',
    ISK: 'ISK',
    JMD: 'JMD',
    JOD: 'JOD',
    JPY: 'JPY',
    KES: 'KES',
    KGS: 'KGS',
    KHR: 'KHR',
    KMF: 'KMF',
    KPW: 'KPW',
    KRW: 'KRW',
    KWD: 'KWD',
    KYD: 'KYD',
    KZT: 'KZT',
    LAK: 'LAK',
    LBP: 'LBP',
    LKR: 'LKR',
    LRD: 'LRD',
    LSL: 'LSL',
    LTL: 'LTL',
    LYD: 'LYD',
    MAD: 'MAD',
    MDL: 'MDL',
    MGA: 'MGA',
    MKD: 'MKD',
    MMK: 'MMK',
    MNT: 'MNT',
    MOP: 'MOP',
    MRO: 'MRO',
    MRU: 'MRU',
    MUR: 'MUR',
    MVR: 'MVR',
    MWK: 'MWK',
    MXN: 'MXN',
    MXV: 'MXV',
    MYR: 'MYR',
    MZN: 'MZN',
    NAD: 'NAD',
    NGN: 'NGN',
    NIO: 'NIO',
    NOK: 'NOK',
    NPR: 'NPR',
    NZD: 'NZD',
    OMR: 'OMR',
    PAB: 'PAB',
    PEN: 'PEN',
    PGK: 'PGK',
    PHP: 'PHP',
    PKR: 'PKR',
    PLN: 'PLN',
    PYG: 'PYG',
    QAR: 'QAR',
    RON: 'RON',
    RSD: 'RSD',
    RUB: 'RUB',
    RUR: 'RUR',
    RWF: 'RWF',
    SAR: 'SAR',
    SBD: 'SBD',
    SCR: 'SCR',
    SDG: 'SDG',
    SEK: 'SEK',
    SGD: 'SGD',
    SHP: 'SHP',
    SLL: 'SLL',
    SOS: 'SOS',
    SRD: 'SRD',
    SSP: 'SSP',
    STD: 'STD',
    STN: 'STN',
    SVC: 'SVC',
    SYP: 'SYP',
    SZL: 'SZL',
    THB: 'THB',
    TJS: 'TJS',
    TMT: 'TMT',
    TND: 'TND',
    TOP: 'TOP',
    TRY: 'TRY',
    TTD: 'TTD',
    TWD: 'TWD',
    TZS: 'TZS',
    UAH: 'UAH',
    UGX: 'UGX',
    USD: 'USD',
    USN: 'USN',
    USS: 'USS',
    UYI: 'UYI',
    UYU: 'UYU',
    UZS: 'UZS',
    VEF: 'VEF',
    VES: 'VES',
    VND: 'VND',
    VUV: 'VUV',
    WST: 'WST',
    XAF: 'XAF',
    XAG: 'XAG',
    XAU: 'XAU',
    XBA: 'XBA',
    XBB: 'XBB',
    XBC: 'XBC',
    XBD: 'XBD',
    XCD: 'XCD',
    XDR: 'XDR',
    XOF: 'XOF',
    XPD: 'XPD',
    XPF: 'XPF',
    XPT: 'XPT',
    XSU: 'XSU',
    XTS: 'XTS',
    XUA: 'XUA',
    XXX: 'XXX',
    YER: 'YER',
    ZAR: 'ZAR',
    ZMW: 'ZMW',
    ZWL: 'ZWL',
} as const

export interface CompanyRevenueResponse {
    currency: CompanyRevenueResponseCurrency
    description?: string
    maximumRevenue?: number
    minimumRevenue?: number
    sourceOfRevenue: string[]
}

export type CompanyRevenueRequestCurrency =
    (typeof CompanyRevenueRequestCurrency)[keyof typeof CompanyRevenueRequestCurrency]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyRevenueRequestCurrency = {
    UNDEFINED: 'UNDEFINED',
    AED: 'AED',
    AFN: 'AFN',
    ALL: 'ALL',
    AMD: 'AMD',
    ANG: 'ANG',
    AOA: 'AOA',
    ARS: 'ARS',
    AUD: 'AUD',
    AWG: 'AWG',
    AZN: 'AZN',
    BAM: 'BAM',
    BBD: 'BBD',
    BDT: 'BDT',
    BGN: 'BGN',
    BHD: 'BHD',
    BIF: 'BIF',
    BMD: 'BMD',
    BND: 'BND',
    BOB: 'BOB',
    BOV: 'BOV',
    BRL: 'BRL',
    BSD: 'BSD',
    BTN: 'BTN',
    BWP: 'BWP',
    BYN: 'BYN',
    BYR: 'BYR',
    BZD: 'BZD',
    CAD: 'CAD',
    CDF: 'CDF',
    CHE: 'CHE',
    CHF: 'CHF',
    CHW: 'CHW',
    CLF: 'CLF',
    CLP: 'CLP',
    CNY: 'CNY',
    COP: 'COP',
    COU: 'COU',
    CRC: 'CRC',
    CUC: 'CUC',
    CUP: 'CUP',
    CVE: 'CVE',
    CZK: 'CZK',
    DJF: 'DJF',
    DKK: 'DKK',
    DOP: 'DOP',
    DZD: 'DZD',
    EGP: 'EGP',
    ERN: 'ERN',
    ETB: 'ETB',
    EUR: 'EUR',
    FJD: 'FJD',
    FKP: 'FKP',
    GBP: 'GBP',
    GEL: 'GEL',
    GHS: 'GHS',
    GIP: 'GIP',
    GMD: 'GMD',
    GNF: 'GNF',
    GTQ: 'GTQ',
    GYD: 'GYD',
    HKD: 'HKD',
    HNL: 'HNL',
    HRK: 'HRK',
    HTG: 'HTG',
    HUF: 'HUF',
    IDR: 'IDR',
    ILS: 'ILS',
    INR: 'INR',
    IQD: 'IQD',
    IRR: 'IRR',
    ISK: 'ISK',
    JMD: 'JMD',
    JOD: 'JOD',
    JPY: 'JPY',
    KES: 'KES',
    KGS: 'KGS',
    KHR: 'KHR',
    KMF: 'KMF',
    KPW: 'KPW',
    KRW: 'KRW',
    KWD: 'KWD',
    KYD: 'KYD',
    KZT: 'KZT',
    LAK: 'LAK',
    LBP: 'LBP',
    LKR: 'LKR',
    LRD: 'LRD',
    LSL: 'LSL',
    LTL: 'LTL',
    LYD: 'LYD',
    MAD: 'MAD',
    MDL: 'MDL',
    MGA: 'MGA',
    MKD: 'MKD',
    MMK: 'MMK',
    MNT: 'MNT',
    MOP: 'MOP',
    MRO: 'MRO',
    MRU: 'MRU',
    MUR: 'MUR',
    MVR: 'MVR',
    MWK: 'MWK',
    MXN: 'MXN',
    MXV: 'MXV',
    MYR: 'MYR',
    MZN: 'MZN',
    NAD: 'NAD',
    NGN: 'NGN',
    NIO: 'NIO',
    NOK: 'NOK',
    NPR: 'NPR',
    NZD: 'NZD',
    OMR: 'OMR',
    PAB: 'PAB',
    PEN: 'PEN',
    PGK: 'PGK',
    PHP: 'PHP',
    PKR: 'PKR',
    PLN: 'PLN',
    PYG: 'PYG',
    QAR: 'QAR',
    RON: 'RON',
    RSD: 'RSD',
    RUB: 'RUB',
    RUR: 'RUR',
    RWF: 'RWF',
    SAR: 'SAR',
    SBD: 'SBD',
    SCR: 'SCR',
    SDG: 'SDG',
    SEK: 'SEK',
    SGD: 'SGD',
    SHP: 'SHP',
    SLL: 'SLL',
    SOS: 'SOS',
    SRD: 'SRD',
    SSP: 'SSP',
    STD: 'STD',
    STN: 'STN',
    SVC: 'SVC',
    SYP: 'SYP',
    SZL: 'SZL',
    THB: 'THB',
    TJS: 'TJS',
    TMT: 'TMT',
    TND: 'TND',
    TOP: 'TOP',
    TRY: 'TRY',
    TTD: 'TTD',
    TWD: 'TWD',
    TZS: 'TZS',
    UAH: 'UAH',
    UGX: 'UGX',
    USD: 'USD',
    USN: 'USN',
    USS: 'USS',
    UYI: 'UYI',
    UYU: 'UYU',
    UZS: 'UZS',
    VEF: 'VEF',
    VES: 'VES',
    VND: 'VND',
    VUV: 'VUV',
    WST: 'WST',
    XAF: 'XAF',
    XAG: 'XAG',
    XAU: 'XAU',
    XBA: 'XBA',
    XBB: 'XBB',
    XBC: 'XBC',
    XBD: 'XBD',
    XCD: 'XCD',
    XDR: 'XDR',
    XOF: 'XOF',
    XPD: 'XPD',
    XPF: 'XPF',
    XPT: 'XPT',
    XSU: 'XSU',
    XTS: 'XTS',
    XUA: 'XUA',
    XXX: 'XXX',
    YER: 'YER',
    ZAR: 'ZAR',
    ZMW: 'ZMW',
    ZWL: 'ZWL',
} as const

export interface CompanyRevenueRequest {
    currency: CompanyRevenueRequestCurrency
    description?: string
    maximumRevenue?: number
    minimumRevenue?: number
    sourceOfRevenue: string[]
}

export type CompanyResponseV2TradingNameSource =
    (typeof CompanyResponseV2TradingNameSource)[keyof typeof CompanyResponseV2TradingNameSource]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyResponseV2TradingNameSource = {
    BVD: 'BVD',
    FAME: 'FAME',
    USER_INPUT: 'USER_INPUT',
    DUE_DILIGENCE_AGENT_INPUT: 'DUE_DILIGENCE_AGENT_INPUT',
} as const

export type CompanyResponseV2TradingAddressSource =
    (typeof CompanyResponseV2TradingAddressSource)[keyof typeof CompanyResponseV2TradingAddressSource]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyResponseV2TradingAddressSource = {
    BVD: 'BVD',
    FAME: 'FAME',
    USER_INPUT: 'USER_INPUT',
    DUE_DILIGENCE_AGENT_INPUT: 'DUE_DILIGENCE_AGENT_INPUT',
} as const

export type CompanyResponseV2OnboardedVia =
    (typeof CompanyResponseV2OnboardedVia)[keyof typeof CompanyResponseV2OnboardedVia]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyResponseV2OnboardedVia = {
    PLEO: 'PLEO',
    B4B: 'B4B',
} as const

export interface CompanyResponseV2 {
    address?: PostalAddressResponse
    cacp?: string
    companySize?: string
    countriesOfOperation?: string[]
    directors?: DirectorResponse[]
    domainName?: string
    id: string
    incorporationDate?: string
    incorporationType?: string
    isManualSignup?: boolean
    kycCheckpoints?: KycStatusResponse[]
    legalName: string
    onboardedVia?: CompanyResponseV2OnboardedVia
    ownerEmail?: string
    ownerId?: string
    registrationNumber?: string
    registryId?: string
    styxId: string
    tradingAddress?: PostalAddressResponse
    tradingAddressSource?: CompanyResponseV2TradingAddressSource
    tradingName?: string
    tradingNameSource?: CompanyResponseV2TradingNameSource
    vatNumber?: string
}

export type CompanyResponseOnboardedVia =
    (typeof CompanyResponseOnboardedVia)[keyof typeof CompanyResponseOnboardedVia]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyResponseOnboardedVia = {
    PLEO: 'PLEO',
    B4B: 'B4B',
} as const

export interface CompanyResponse {
    address?: PostalAddressModel
    aliases?: string[]
    cacp?: string
    capital?: Money
    directorOf?: string[]
    directors?: DirectorResponse[]
    documents?: string[]
    domainName?: string
    employeeCount?: number
    employeeMinCount?: number
    globalId: string
    id: string
    incorporationDate?: string
    incorporationType?: string
    kycCheckpoints?: KycStatusResponse[]
    kycPersons?: string[]
    legalName?: string
    memorandumDate?: string
    offboardingStatus?: CompanyOffboardingStatus
    onboardedVia?: CompanyResponseOnboardedVia
    ownerEmail?: string
    ownerId?: string
    registrationNumber?: string
    registryId?: string
    rootCompanyId: string
    shareholderOf?: string[]
    shareholders?: ShareholderResponse[]
    sic?: string[]
    sourceOfFundsKeywords?: string[]
    vatNumber?: string
}

export type CompanyRequestV2TradingNameSource =
    (typeof CompanyRequestV2TradingNameSource)[keyof typeof CompanyRequestV2TradingNameSource]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyRequestV2TradingNameSource = {
    BVD: 'BVD',
    FAME: 'FAME',
    USER_INPUT: 'USER_INPUT',
    DUE_DILIGENCE_AGENT_INPUT: 'DUE_DILIGENCE_AGENT_INPUT',
} as const

export type CompanyRequestV2TradingAddressSource =
    (typeof CompanyRequestV2TradingAddressSource)[keyof typeof CompanyRequestV2TradingAddressSource]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyRequestV2TradingAddressSource = {
    BVD: 'BVD',
    FAME: 'FAME',
    USER_INPUT: 'USER_INPUT',
    DUE_DILIGENCE_AGENT_INPUT: 'DUE_DILIGENCE_AGENT_INPUT',
} as const

export type CompanyRequestV2OnboardedVia =
    (typeof CompanyRequestV2OnboardedVia)[keyof typeof CompanyRequestV2OnboardedVia]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyRequestV2OnboardedVia = {
    PLEO: 'PLEO',
    B4B: 'B4B',
} as const

export interface CompanyRequestV2 {
    address?: PostalAddressModel
    aliases?: string[]
    cacp?: string
    capital?: Money
    companySize?: string
    countriesOfOperation?: string[]
    domainName?: string
    globalId?: string
    incorporationDate?: string
    incorporationType?: string
    legalName?: string
    memorandumDate?: string
    onboardedVia?: CompanyRequestV2OnboardedVia
    ownerEmail?: string
    ownerId?: string
    registryId?: string
    rootCompanyId?: string
    sic?: string[]
    sourceOfFundsKeywords?: string[]
    tradingAddress?: PostalAddressModel
    tradingAddressSource?: CompanyRequestV2TradingAddressSource
    tradingName?: string
    tradingNameSource?: CompanyRequestV2TradingNameSource
    vatNumber?: string
}

export type CompanyRequestOnboardedVia =
    (typeof CompanyRequestOnboardedVia)[keyof typeof CompanyRequestOnboardedVia]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyRequestOnboardedVia = {
    PLEO: 'PLEO',
    B4B: 'B4B',
} as const

export interface CompanyRequest {
    address?: PostalAddressModel
    aliases?: string[]
    cacp?: string
    capital?: Money
    companySize?: string
    countriesOfOperation?: string[]
    domainName?: string
    globalId?: string
    incorporationDate?: string
    incorporationType?: string
    legalName: string
    memorandumDate?: string
    onboardedVia?: CompanyRequestOnboardedVia
    ownerEmail?: string
    ownerId?: string
    registryId?: string
    rootCompanyId?: string
    sic?: string[]
    sourceOfFundsKeywords?: string[]
    status?: string
    tradingAddress?: PostalAddressModel
    tradingName?: string
    vatNumber?: string
}

export interface CompanyOffboardingSurveyRequest {
    additionalFeedback?: string
    followupQuestion?: string
    languageCode?: string
    longAnswer?: string
    reason: string
    startAgainAnswer?: string
    startAgainLater: boolean
}

export interface CompanyOffboardingStatusesResponse {
    offboardingStatuses: CompanyOffboardingStatusResponse[]
    totalCount: number
}

export type CompanyOffboardingStatusResponseReasonsItem =
    (typeof CompanyOffboardingStatusResponseReasonsItem)[keyof typeof CompanyOffboardingStatusResponseReasonsItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyOffboardingStatusResponseReasonsItem = {
    REJECTED: 'REJECTED',
    DUPLICATE: 'DUPLICATE',
    BANKRUPTCY: 'BANKRUPTCY',
    COMPETITOR: 'COMPETITOR',
    OTHER_BREACH_OF_TERMS_AND_CONDITIONS: 'OTHER_BREACH_OF_TERMS_AND_CONDITIONS',
    AML: 'AML',
    CTF: 'CTF',
    RISK_APPETITE: 'RISK_APPETITE',
    MISSING_FEATURE_ACCOUNTING_INTEGRATION: 'MISSING_FEATURE_ACCOUNTING_INTEGRATION',
    MISSING_FEATURE_CREDIT: 'MISSING_FEATURE_CREDIT',
    MISSING_FEATURE_MULTI_CURRENCY: 'MISSING_FEATURE_MULTI_CURRENCY',
    MISSING_FEATURE_OPEN_API: 'MISSING_FEATURE_OPEN_API',
    MISSING_FEATURE_REPRESENTATION: 'MISSING_FEATURE_REPRESENTATION',
    MISSING_FEATURE_SPLIT_RECEIPT: 'MISSING_FEATURE_SPLIT_RECEIPT',
    MISSING_FEATURE_TEAM_HIERARCHY: 'MISSING_FEATURE_TEAM_HIERARCHY',
    MISSING_FEATURE_AUTO_VAT: 'MISSING_FEATURE_AUTO_VAT',
    MISSING_FEATURE_PDF_RECEIPTS: 'MISSING_FEATURE_PDF_RECEIPTS',
    MISSING_FEATURE_EMAIL_RECEIPTS: 'MISSING_FEATURE_EMAIL_RECEIPTS',
    MISSING_FEATURE_PROJECT_MANAGEMENT: 'MISSING_FEATURE_PROJECT_MANAGEMENT',
    MISSING_FEATURE_BILLABLE_EXPENSES: 'MISSING_FEATURE_BILLABLE_EXPENSES',
    MISSING_FEATURE_INVOICE_MANAGEMENT: 'MISSING_FEATURE_INVOICE_MANAGEMENT',
    MISSING_FEATURE_LOUNGE_PASS: 'MISSING_FEATURE_LOUNGE_PASS',
    MISSING_FEATURE_MULTI_LOGIN: 'MISSING_FEATURE_MULTI_LOGIN',
    MISSING_FEATURE_TEAM_LIMITS: 'MISSING_FEATURE_TEAM_LIMITS',
    MISSING_FEATURE_OTHER: 'MISSING_FEATURE_OTHER',
    PRICING_LACK_FUNCTIONALITY: 'PRICING_LACK_FUNCTIONALITY',
    PRICING_STRUCTURE: 'PRICING_STRUCTURE',
    PRICING_TOO_EXPENSIVE: 'PRICING_TOO_EXPENSIVE',
    PRICING_OTHER: 'PRICING_OTHER',
    ORGANIZATION_CLOSING_COMPANY: 'ORGANIZATION_CLOSING_COMPANY',
    ORGANIZATION_MERGING_ENTITY: 'ORGANIZATION_MERGING_ENTITY',
    ORGANIZATION_OTHER: 'ORGANIZATION_OTHER',
    ORGANIZATION_PLEO_CHAMPION_LEFT: 'ORGANIZATION_PLEO_CHAMPION_LEFT',
    MISUNDERSTOOD_PRODUCT_BANK_ACCOUNT: 'MISUNDERSTOOD_PRODUCT_BANK_ACCOUNT',
    MISUNDERSTOOD_PRODUCT_OTHER: 'MISUNDERSTOOD_PRODUCT_OTHER',
    BAD_TIMING_ONE_MONTH: 'BAD_TIMING_ONE_MONTH',
    BAD_TIMING_THREE_MONTH: 'BAD_TIMING_THREE_MONTH',
    BAD_TIMING_SIX_MONTH: 'BAD_TIMING_SIX_MONTH',
    BAD_TIMING_ONE_YEAR: 'BAD_TIMING_ONE_YEAR',
    BAD_TIMING_UNKNOWN: 'BAD_TIMING_UNKNOWN',
    BAD_TIMING_OTHER: 'BAD_TIMING_OTHER',
    MOVED_TO_COMPETITOR: 'MOVED_TO_COMPETITOR',
    MOVED_TO_COMPETITOR_SPENDESK: 'MOVED_TO_COMPETITOR_SPENDESK',
    MOVED_TO_COMPETITOR_SOLDO: 'MOVED_TO_COMPETITOR_SOLDO',
    MOVED_TO_COMPETITOR_MOSS: 'MOVED_TO_COMPETITOR_MOSS',
    MOVED_TO_COMPETITOR_RAMP: 'MOVED_TO_COMPETITOR_RAMP',
    MOVED_TO_COMPETITOR_OTHER: 'MOVED_TO_COMPETITOR_OTHER',
    COMPLICATED_SETUP_WALLET_LOAD: 'COMPLICATED_SETUP_WALLET_LOAD',
    COMPLICATED_SETUP_CARD_ACTIVATION: 'COMPLICATED_SETUP_CARD_ACTIVATION',
    COMPLICATED_SETUP_ACCOUNTING_INTEGRATION: 'COMPLICATED_SETUP_ACCOUNTING_INTEGRATION',
    COMPLICATED_SETUP_PERSONAL_VERIFICATION: 'COMPLICATED_SETUP_PERSONAL_VERIFICATION',
    COMPLICATED_SETUP_COMPANY_VERIFICATION: 'COMPLICATED_SETUP_COMPANY_VERIFICATION',
    OTHER: 'OTHER',
    OTHER_INVALID_SIGN_UP_FAKE: 'OTHER_INVALID_SIGN_UP_FAKE',
    OTHER_INVALID_SIGN_UP_DUPLICATED: 'OTHER_INVALID_SIGN_UP_DUPLICATED',
    OTHER_LOW_EXPENSE_VOLUME: 'OTHER_LOW_EXPENSE_VOLUME',
    OTHER_FEEDBACK_REFUSED: 'OTHER_FEEDBACK_REFUSED',
    OTHER_FEEDBACK_UNANSWERED: 'OTHER_FEEDBACK_UNANSWERED',
} as const

export type CompanyOffboardingStatusResponseCategory =
    (typeof CompanyOffboardingStatusResponseCategory)[keyof typeof CompanyOffboardingStatusResponseCategory]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyOffboardingStatusResponseCategory = {
    ACTIVE: 'ACTIVE',
    LEGAL: 'LEGAL',
    SILENT: 'SILENT',
    OTHER: 'OTHER',
} as const

export interface CompanyOffboardingStatusResponse {
    category: CompanyOffboardingStatusResponseCategory
    companyId: string
    completedAt?: string
    completedBy?: string
    hasReasons: boolean
    id: string
    initiatedAt: string
    initiatedBy: string
    notes?: string
    reasons: CompanyOffboardingStatusResponseReasonsItem[]
}

export type CompanyOffboardingStatusRequestReasonsItem =
    (typeof CompanyOffboardingStatusRequestReasonsItem)[keyof typeof CompanyOffboardingStatusRequestReasonsItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyOffboardingStatusRequestReasonsItem = {
    REJECTED: 'REJECTED',
    DUPLICATE: 'DUPLICATE',
    BANKRUPTCY: 'BANKRUPTCY',
    COMPETITOR: 'COMPETITOR',
    OTHER_BREACH_OF_TERMS_AND_CONDITIONS: 'OTHER_BREACH_OF_TERMS_AND_CONDITIONS',
    AML: 'AML',
    CTF: 'CTF',
    RISK_APPETITE: 'RISK_APPETITE',
    MISSING_FEATURE_ACCOUNTING_INTEGRATION: 'MISSING_FEATURE_ACCOUNTING_INTEGRATION',
    MISSING_FEATURE_CREDIT: 'MISSING_FEATURE_CREDIT',
    MISSING_FEATURE_MULTI_CURRENCY: 'MISSING_FEATURE_MULTI_CURRENCY',
    MISSING_FEATURE_OPEN_API: 'MISSING_FEATURE_OPEN_API',
    MISSING_FEATURE_REPRESENTATION: 'MISSING_FEATURE_REPRESENTATION',
    MISSING_FEATURE_SPLIT_RECEIPT: 'MISSING_FEATURE_SPLIT_RECEIPT',
    MISSING_FEATURE_TEAM_HIERARCHY: 'MISSING_FEATURE_TEAM_HIERARCHY',
    MISSING_FEATURE_AUTO_VAT: 'MISSING_FEATURE_AUTO_VAT',
    MISSING_FEATURE_PDF_RECEIPTS: 'MISSING_FEATURE_PDF_RECEIPTS',
    MISSING_FEATURE_EMAIL_RECEIPTS: 'MISSING_FEATURE_EMAIL_RECEIPTS',
    MISSING_FEATURE_PROJECT_MANAGEMENT: 'MISSING_FEATURE_PROJECT_MANAGEMENT',
    MISSING_FEATURE_BILLABLE_EXPENSES: 'MISSING_FEATURE_BILLABLE_EXPENSES',
    MISSING_FEATURE_INVOICE_MANAGEMENT: 'MISSING_FEATURE_INVOICE_MANAGEMENT',
    MISSING_FEATURE_LOUNGE_PASS: 'MISSING_FEATURE_LOUNGE_PASS',
    MISSING_FEATURE_MULTI_LOGIN: 'MISSING_FEATURE_MULTI_LOGIN',
    MISSING_FEATURE_TEAM_LIMITS: 'MISSING_FEATURE_TEAM_LIMITS',
    MISSING_FEATURE_OTHER: 'MISSING_FEATURE_OTHER',
    PRICING_LACK_FUNCTIONALITY: 'PRICING_LACK_FUNCTIONALITY',
    PRICING_STRUCTURE: 'PRICING_STRUCTURE',
    PRICING_TOO_EXPENSIVE: 'PRICING_TOO_EXPENSIVE',
    PRICING_OTHER: 'PRICING_OTHER',
    ORGANIZATION_CLOSING_COMPANY: 'ORGANIZATION_CLOSING_COMPANY',
    ORGANIZATION_MERGING_ENTITY: 'ORGANIZATION_MERGING_ENTITY',
    ORGANIZATION_OTHER: 'ORGANIZATION_OTHER',
    ORGANIZATION_PLEO_CHAMPION_LEFT: 'ORGANIZATION_PLEO_CHAMPION_LEFT',
    MISUNDERSTOOD_PRODUCT_BANK_ACCOUNT: 'MISUNDERSTOOD_PRODUCT_BANK_ACCOUNT',
    MISUNDERSTOOD_PRODUCT_OTHER: 'MISUNDERSTOOD_PRODUCT_OTHER',
    BAD_TIMING_ONE_MONTH: 'BAD_TIMING_ONE_MONTH',
    BAD_TIMING_THREE_MONTH: 'BAD_TIMING_THREE_MONTH',
    BAD_TIMING_SIX_MONTH: 'BAD_TIMING_SIX_MONTH',
    BAD_TIMING_ONE_YEAR: 'BAD_TIMING_ONE_YEAR',
    BAD_TIMING_UNKNOWN: 'BAD_TIMING_UNKNOWN',
    BAD_TIMING_OTHER: 'BAD_TIMING_OTHER',
    MOVED_TO_COMPETITOR: 'MOVED_TO_COMPETITOR',
    MOVED_TO_COMPETITOR_SPENDESK: 'MOVED_TO_COMPETITOR_SPENDESK',
    MOVED_TO_COMPETITOR_SOLDO: 'MOVED_TO_COMPETITOR_SOLDO',
    MOVED_TO_COMPETITOR_MOSS: 'MOVED_TO_COMPETITOR_MOSS',
    MOVED_TO_COMPETITOR_RAMP: 'MOVED_TO_COMPETITOR_RAMP',
    MOVED_TO_COMPETITOR_OTHER: 'MOVED_TO_COMPETITOR_OTHER',
    COMPLICATED_SETUP_WALLET_LOAD: 'COMPLICATED_SETUP_WALLET_LOAD',
    COMPLICATED_SETUP_CARD_ACTIVATION: 'COMPLICATED_SETUP_CARD_ACTIVATION',
    COMPLICATED_SETUP_ACCOUNTING_INTEGRATION: 'COMPLICATED_SETUP_ACCOUNTING_INTEGRATION',
    COMPLICATED_SETUP_PERSONAL_VERIFICATION: 'COMPLICATED_SETUP_PERSONAL_VERIFICATION',
    COMPLICATED_SETUP_COMPANY_VERIFICATION: 'COMPLICATED_SETUP_COMPANY_VERIFICATION',
    OTHER: 'OTHER',
    OTHER_INVALID_SIGN_UP_FAKE: 'OTHER_INVALID_SIGN_UP_FAKE',
    OTHER_INVALID_SIGN_UP_DUPLICATED: 'OTHER_INVALID_SIGN_UP_DUPLICATED',
    OTHER_LOW_EXPENSE_VOLUME: 'OTHER_LOW_EXPENSE_VOLUME',
    OTHER_FEEDBACK_REFUSED: 'OTHER_FEEDBACK_REFUSED',
    OTHER_FEEDBACK_UNANSWERED: 'OTHER_FEEDBACK_UNANSWERED',
} as const

export type CompanyOffboardingStatusRequestCategory =
    (typeof CompanyOffboardingStatusRequestCategory)[keyof typeof CompanyOffboardingStatusRequestCategory]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyOffboardingStatusRequestCategory = {
    ACTIVE: 'ACTIVE',
    LEGAL: 'LEGAL',
    SILENT: 'SILENT',
    OTHER: 'OTHER',
} as const

export interface CompanyOffboardingStatusRequest {
    category: CompanyOffboardingStatusRequestCategory
    notes?: string
    reasons: CompanyOffboardingStatusRequestReasonsItem[]
    survey?: CompanyOffboardingSurveyRequest
}

export type CompanyOffboardingStatusReasonsItem =
    (typeof CompanyOffboardingStatusReasonsItem)[keyof typeof CompanyOffboardingStatusReasonsItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyOffboardingStatusReasonsItem = {
    REJECTED: 'REJECTED',
    DUPLICATE: 'DUPLICATE',
    BANKRUPTCY: 'BANKRUPTCY',
    COMPETITOR: 'COMPETITOR',
    OTHER_BREACH_OF_TERMS_AND_CONDITIONS: 'OTHER_BREACH_OF_TERMS_AND_CONDITIONS',
    AML: 'AML',
    CTF: 'CTF',
    RISK_APPETITE: 'RISK_APPETITE',
    MISSING_FEATURE_ACCOUNTING_INTEGRATION: 'MISSING_FEATURE_ACCOUNTING_INTEGRATION',
    MISSING_FEATURE_CREDIT: 'MISSING_FEATURE_CREDIT',
    MISSING_FEATURE_MULTI_CURRENCY: 'MISSING_FEATURE_MULTI_CURRENCY',
    MISSING_FEATURE_OPEN_API: 'MISSING_FEATURE_OPEN_API',
    MISSING_FEATURE_REPRESENTATION: 'MISSING_FEATURE_REPRESENTATION',
    MISSING_FEATURE_SPLIT_RECEIPT: 'MISSING_FEATURE_SPLIT_RECEIPT',
    MISSING_FEATURE_TEAM_HIERARCHY: 'MISSING_FEATURE_TEAM_HIERARCHY',
    MISSING_FEATURE_AUTO_VAT: 'MISSING_FEATURE_AUTO_VAT',
    MISSING_FEATURE_PDF_RECEIPTS: 'MISSING_FEATURE_PDF_RECEIPTS',
    MISSING_FEATURE_EMAIL_RECEIPTS: 'MISSING_FEATURE_EMAIL_RECEIPTS',
    MISSING_FEATURE_PROJECT_MANAGEMENT: 'MISSING_FEATURE_PROJECT_MANAGEMENT',
    MISSING_FEATURE_BILLABLE_EXPENSES: 'MISSING_FEATURE_BILLABLE_EXPENSES',
    MISSING_FEATURE_INVOICE_MANAGEMENT: 'MISSING_FEATURE_INVOICE_MANAGEMENT',
    MISSING_FEATURE_LOUNGE_PASS: 'MISSING_FEATURE_LOUNGE_PASS',
    MISSING_FEATURE_MULTI_LOGIN: 'MISSING_FEATURE_MULTI_LOGIN',
    MISSING_FEATURE_TEAM_LIMITS: 'MISSING_FEATURE_TEAM_LIMITS',
    MISSING_FEATURE_OTHER: 'MISSING_FEATURE_OTHER',
    PRICING_LACK_FUNCTIONALITY: 'PRICING_LACK_FUNCTIONALITY',
    PRICING_STRUCTURE: 'PRICING_STRUCTURE',
    PRICING_TOO_EXPENSIVE: 'PRICING_TOO_EXPENSIVE',
    PRICING_OTHER: 'PRICING_OTHER',
    ORGANIZATION_CLOSING_COMPANY: 'ORGANIZATION_CLOSING_COMPANY',
    ORGANIZATION_MERGING_ENTITY: 'ORGANIZATION_MERGING_ENTITY',
    ORGANIZATION_OTHER: 'ORGANIZATION_OTHER',
    ORGANIZATION_PLEO_CHAMPION_LEFT: 'ORGANIZATION_PLEO_CHAMPION_LEFT',
    MISUNDERSTOOD_PRODUCT_BANK_ACCOUNT: 'MISUNDERSTOOD_PRODUCT_BANK_ACCOUNT',
    MISUNDERSTOOD_PRODUCT_OTHER: 'MISUNDERSTOOD_PRODUCT_OTHER',
    BAD_TIMING_ONE_MONTH: 'BAD_TIMING_ONE_MONTH',
    BAD_TIMING_THREE_MONTH: 'BAD_TIMING_THREE_MONTH',
    BAD_TIMING_SIX_MONTH: 'BAD_TIMING_SIX_MONTH',
    BAD_TIMING_ONE_YEAR: 'BAD_TIMING_ONE_YEAR',
    BAD_TIMING_UNKNOWN: 'BAD_TIMING_UNKNOWN',
    BAD_TIMING_OTHER: 'BAD_TIMING_OTHER',
    MOVED_TO_COMPETITOR: 'MOVED_TO_COMPETITOR',
    MOVED_TO_COMPETITOR_SPENDESK: 'MOVED_TO_COMPETITOR_SPENDESK',
    MOVED_TO_COMPETITOR_SOLDO: 'MOVED_TO_COMPETITOR_SOLDO',
    MOVED_TO_COMPETITOR_MOSS: 'MOVED_TO_COMPETITOR_MOSS',
    MOVED_TO_COMPETITOR_RAMP: 'MOVED_TO_COMPETITOR_RAMP',
    MOVED_TO_COMPETITOR_OTHER: 'MOVED_TO_COMPETITOR_OTHER',
    COMPLICATED_SETUP_WALLET_LOAD: 'COMPLICATED_SETUP_WALLET_LOAD',
    COMPLICATED_SETUP_CARD_ACTIVATION: 'COMPLICATED_SETUP_CARD_ACTIVATION',
    COMPLICATED_SETUP_ACCOUNTING_INTEGRATION: 'COMPLICATED_SETUP_ACCOUNTING_INTEGRATION',
    COMPLICATED_SETUP_PERSONAL_VERIFICATION: 'COMPLICATED_SETUP_PERSONAL_VERIFICATION',
    COMPLICATED_SETUP_COMPANY_VERIFICATION: 'COMPLICATED_SETUP_COMPANY_VERIFICATION',
    OTHER: 'OTHER',
    OTHER_INVALID_SIGN_UP_FAKE: 'OTHER_INVALID_SIGN_UP_FAKE',
    OTHER_INVALID_SIGN_UP_DUPLICATED: 'OTHER_INVALID_SIGN_UP_DUPLICATED',
    OTHER_LOW_EXPENSE_VOLUME: 'OTHER_LOW_EXPENSE_VOLUME',
    OTHER_FEEDBACK_REFUSED: 'OTHER_FEEDBACK_REFUSED',
    OTHER_FEEDBACK_UNANSWERED: 'OTHER_FEEDBACK_UNANSWERED',
} as const

export type CompanyOffboardingStatusCategory =
    (typeof CompanyOffboardingStatusCategory)[keyof typeof CompanyOffboardingStatusCategory]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyOffboardingStatusCategory = {
    ACTIVE: 'ACTIVE',
    LEGAL: 'LEGAL',
    SILENT: 'SILENT',
    OTHER: 'OTHER',
} as const

export interface CompanyOffboardingStatus {
    category: CompanyOffboardingStatusCategory
    completedAt?: string
    completedBy?: string
    hasReasons: boolean
    initiatedAt: string
    initiatedBy: string
    notes?: string
    reasons: CompanyOffboardingStatusReasonsItem[]
}

export type CompanyNotFoundExceptionSuppressedItemStackTraceItem = {
    classLoaderName?: string
    className?: string
    fileName?: string
    lineNumber?: number
    methodName?: string
    moduleName?: string
    moduleVersion?: string
    nativeMethod?: boolean
}

export type CompanyNotFoundExceptionSuppressedItem = {
    localizedMessage?: string
    message?: string
    stackTrace?: CompanyNotFoundExceptionSuppressedItemStackTraceItem[]
}

export type CompanyNotFoundExceptionStackTraceItem = {
    classLoaderName?: string
    className?: string
    fileName?: string
    lineNumber?: number
    methodName?: string
    moduleName?: string
    moduleVersion?: string
    nativeMethod?: boolean
}

export type CompanyNotFoundExceptionCauseStackTraceItem = {
    classLoaderName?: string
    className?: string
    fileName?: string
    lineNumber?: number
    methodName?: string
    moduleName?: string
    moduleVersion?: string
    nativeMethod?: boolean
}

export type CompanyNotFoundExceptionCause = {
    localizedMessage?: string
    message?: string
    stackTrace?: CompanyNotFoundExceptionCauseStackTraceItem[]
}

export interface CompanyNotFoundException {
    cause?: CompanyNotFoundExceptionCause
    entity?: string
    id?: string
    localizedMessage?: string
    message?: string
    stackTrace?: CompanyNotFoundExceptionStackTraceItem[]
    suppressed?: CompanyNotFoundExceptionSuppressedItem[]
    type?: string
}

export interface CompanyNeedsVerificationResponse {
    needsVerification: boolean
}

export interface CompanyManufacturingCountriesResponse {
    countryCodes: string[]
}

export interface CompanyManufacturingCountriesRequest {
    countryCodes: string[]
}

export type CompanyFundsLimitsResponseYearly = { [key: string]: number }

export type CompanyFundsLimitsResponseDaily = { [key: string]: number }

export interface CompanyFundsLimitsResponse {
    daily: CompanyFundsLimitsResponseDaily
    yearly: CompanyFundsLimitsResponseYearly
}

export interface CompanyDocumentResponse {
    category?: string
    companyId?: string
    description?: string
    effectiveDate?: string
    expirationDate?: string
    files: UploadedFileResponse[]
    hidden?: boolean
    id?: string
    requestReason?: string
    subCategory?: string
    title?: string
}

export type CompanyDocumentRequestCategory =
    (typeof CompanyDocumentRequestCategory)[keyof typeof CompanyDocumentRequestCategory]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyDocumentRequestCategory = {
    AML_POLICY: 'AML_POLICY',
    ARTICLES_OF_ASSOCIATION: 'ARTICLES_OF_ASSOCIATION',
    CBD_LICENCE: 'CBD_LICENCE',
    CERTIFICATE_TO_OPERATE: 'CERTIFICATE_TO_OPERATE',
    COMPANY_REGISTRATION_CERTIFICATE: 'COMPANY_REGISTRATION_CERTIFICATE',
    DEED_OF_FOUNDATION: 'DEED_OF_FOUNDATION',
    FINANCIAL_STATEMENT: 'FINANCIAL_STATEMENT',
    CERTIFICATE_TO_OPERATE_PRECIOUS_STONES: 'CERTIFICATE_TO_OPERATE_PRECIOUS_STONES',
    LICENCE_TO_OPERATE_GAMBLING_AND_BETTING: 'LICENCE_TO_OPERATE_GAMBLING_AND_BETTING',
    LICENCE_TO_OPERATE_MINING_AND_PRIVATE_SECURITY:
        'LICENCE_TO_OPERATE_MINING_AND_PRIVATE_SECURITY',
    LICENCE_TO_OPERATE_QUARRYING_AND_EXTRACTION: 'LICENCE_TO_OPERATE_QUARRYING_AND_EXTRACTION',
    OTHER: 'OTHER',
    OWNERSHIP: 'OWNERSHIP',
    PEP_FORM: 'PEP_FORM',
    PROOF_OF_ADDRESS: 'PROOF_OF_ADDRESS',
    PROOF_OF_BUSINESS: 'PROOF_OF_BUSINESS',
    PROOF_OF_DIRECTORS: 'PROOF_OF_DIRECTORS',
    PROOF_OF_ID: 'PROOF_OF_ID',
    SHAREHOLDER_REGISTER: 'SHAREHOLDER_REGISTER',
    SOURCE_OF_FUNDS: 'SOURCE_OF_FUNDS',
    TRUST_DEED: 'TRUST_DEED',
    EXTERNAL_HIGH_RISK_APPROVAL: 'EXTERNAL_HIGH_RISK_APPROVAL',
    EXTERNAL_RISK_ASSESSMENT: 'EXTERNAL_RISK_ASSESSMENT',
    SOW_SOF_ASSESSMENT: 'SOW_SOF_ASSESSMENT',
    BUSINESS_REGISTRY_EXTRACT: 'BUSINESS_REGISTRY_EXTRACT',
} as const

export interface CompanyDocumentRequest {
    autogenerated?: boolean
    category: CompanyDocumentRequestCategory
    description?: string
    effectiveDate?: string
    expirationDate?: string
    hidden?: boolean
    isAutogenerated: boolean
    isHidden: boolean
    requestReason?: string
    subCategory?: string
    title?: string
}

export type CompanyDocumentNotFoundExceptionSuppressedItemStackTraceItem = {
    classLoaderName?: string
    className?: string
    fileName?: string
    lineNumber?: number
    methodName?: string
    moduleName?: string
    moduleVersion?: string
    nativeMethod?: boolean
}

export type CompanyDocumentNotFoundExceptionSuppressedItem = {
    localizedMessage?: string
    message?: string
    stackTrace?: CompanyDocumentNotFoundExceptionSuppressedItemStackTraceItem[]
}

export type CompanyDocumentNotFoundExceptionStackTraceItem = {
    classLoaderName?: string
    className?: string
    fileName?: string
    lineNumber?: number
    methodName?: string
    moduleName?: string
    moduleVersion?: string
    nativeMethod?: boolean
}

export type CompanyDocumentNotFoundExceptionCauseStackTraceItem = {
    classLoaderName?: string
    className?: string
    fileName?: string
    lineNumber?: number
    methodName?: string
    moduleName?: string
    moduleVersion?: string
    nativeMethod?: boolean
}

export type CompanyDocumentNotFoundExceptionCause = {
    localizedMessage?: string
    message?: string
    stackTrace?: CompanyDocumentNotFoundExceptionCauseStackTraceItem[]
}

export interface CompanyDocumentNotFoundException {
    cause?: CompanyDocumentNotFoundExceptionCause
    entity?: string
    id?: string
    localizedMessage?: string
    message?: string
    stackTrace?: CompanyDocumentNotFoundExceptionStackTraceItem[]
    suppressed?: CompanyDocumentNotFoundExceptionSuppressedItem[]
    type?: string
}

export interface CompanyBusinessExplanationResponse {
    companyId: string
    explanation?: string
    hidden: boolean
    id: string
    inputSource: string
}

export interface CompanyBusinessExplanationRequest {
    explanation?: string
    hidden: boolean
}

export interface ClientScope {
    action?: string
    resource: string
    resourceId?: string
}

export interface Client {
    id?: string
    name?: string
    redirectUris: string[]
    scopes: ClientScope[]
}

export type ClientAuthenticationAllOf = {
    client?: Client
}

export type BusinessOfferingSource =
    (typeof BusinessOfferingSource)[keyof typeof BusinessOfferingSource]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BusinessOfferingSource = {
    DUE_DILIGENCE_AGENT_INPUT: 'DUE_DILIGENCE_AGENT_INPUT',
} as const

export type BusinessOfferingName = (typeof BusinessOfferingName)[keyof typeof BusinessOfferingName]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BusinessOfferingName = {
    CRYPTO: 'CRYPTO',
    GAMBLING: 'GAMBLING',
    CHARITIES: 'CHARITIES',
} as const

export interface BusinessOffering {
    name: BusinessOfferingName
    source: BusinessOfferingSource
}

export interface BlockedSourceUpdateRequest {
    notes?: string
    regex?: string
}

export interface BlockedSourceResponse {
    createdAt: string
    deletedAt?: string
    id: string
    notes?: string
    regex?: string
    updatedAt: string
    updatedBy?: string
}

export interface BillsAmlAnswersResponse {
    countriesOutsideUkAndEu?: string
    highestPayment: string
    isRecurring: boolean
    maxSuppliersPerMonth: string
    paymentsOutsideUkAndEu: boolean
    usage: string
}

export interface BillsAmlAnswersRequest {
    countriesOutsideUkAndEu?: string
    highestPayment: string
    isRecurring: boolean
    maxSuppliersPerMonth: string
    paymentsOutsideUkAndEu: boolean
    usage: string
}

export interface BankVerificationDetailsResponse {
    accountHolderName: string
    accountNumber: string
    adminFullName?: string
    bankCode: string
    bankName: string
    companyId: string
    id: string
}

export interface BankVerificationDetailsPostRequest {
    accountHolderName: string
    accountNumber: string
    adminFullName?: string
    bankCode: string
    bankName: string
    companyId: string
}

export interface BankVerificationDetailsPatchRequest {
    accountHolderName?: string
    accountNumber?: string
    adminFullName?: string
    bankCode?: string
    bankName?: string
}

export type BankAccountInfoCurrency =
    (typeof BankAccountInfoCurrency)[keyof typeof BankAccountInfoCurrency]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BankAccountInfoCurrency = {
    UNDEFINED: 'UNDEFINED',
    AED: 'AED',
    AFN: 'AFN',
    ALL: 'ALL',
    AMD: 'AMD',
    ANG: 'ANG',
    AOA: 'AOA',
    ARS: 'ARS',
    AUD: 'AUD',
    AWG: 'AWG',
    AZN: 'AZN',
    BAM: 'BAM',
    BBD: 'BBD',
    BDT: 'BDT',
    BGN: 'BGN',
    BHD: 'BHD',
    BIF: 'BIF',
    BMD: 'BMD',
    BND: 'BND',
    BOB: 'BOB',
    BOV: 'BOV',
    BRL: 'BRL',
    BSD: 'BSD',
    BTN: 'BTN',
    BWP: 'BWP',
    BYN: 'BYN',
    BYR: 'BYR',
    BZD: 'BZD',
    CAD: 'CAD',
    CDF: 'CDF',
    CHE: 'CHE',
    CHF: 'CHF',
    CHW: 'CHW',
    CLF: 'CLF',
    CLP: 'CLP',
    CNY: 'CNY',
    COP: 'COP',
    COU: 'COU',
    CRC: 'CRC',
    CUC: 'CUC',
    CUP: 'CUP',
    CVE: 'CVE',
    CZK: 'CZK',
    DJF: 'DJF',
    DKK: 'DKK',
    DOP: 'DOP',
    DZD: 'DZD',
    EGP: 'EGP',
    ERN: 'ERN',
    ETB: 'ETB',
    EUR: 'EUR',
    FJD: 'FJD',
    FKP: 'FKP',
    GBP: 'GBP',
    GEL: 'GEL',
    GHS: 'GHS',
    GIP: 'GIP',
    GMD: 'GMD',
    GNF: 'GNF',
    GTQ: 'GTQ',
    GYD: 'GYD',
    HKD: 'HKD',
    HNL: 'HNL',
    HRK: 'HRK',
    HTG: 'HTG',
    HUF: 'HUF',
    IDR: 'IDR',
    ILS: 'ILS',
    INR: 'INR',
    IQD: 'IQD',
    IRR: 'IRR',
    ISK: 'ISK',
    JMD: 'JMD',
    JOD: 'JOD',
    JPY: 'JPY',
    KES: 'KES',
    KGS: 'KGS',
    KHR: 'KHR',
    KMF: 'KMF',
    KPW: 'KPW',
    KRW: 'KRW',
    KWD: 'KWD',
    KYD: 'KYD',
    KZT: 'KZT',
    LAK: 'LAK',
    LBP: 'LBP',
    LKR: 'LKR',
    LRD: 'LRD',
    LSL: 'LSL',
    LTL: 'LTL',
    LYD: 'LYD',
    MAD: 'MAD',
    MDL: 'MDL',
    MGA: 'MGA',
    MKD: 'MKD',
    MMK: 'MMK',
    MNT: 'MNT',
    MOP: 'MOP',
    MRO: 'MRO',
    MRU: 'MRU',
    MUR: 'MUR',
    MVR: 'MVR',
    MWK: 'MWK',
    MXN: 'MXN',
    MXV: 'MXV',
    MYR: 'MYR',
    MZN: 'MZN',
    NAD: 'NAD',
    NGN: 'NGN',
    NIO: 'NIO',
    NOK: 'NOK',
    NPR: 'NPR',
    NZD: 'NZD',
    OMR: 'OMR',
    PAB: 'PAB',
    PEN: 'PEN',
    PGK: 'PGK',
    PHP: 'PHP',
    PKR: 'PKR',
    PLN: 'PLN',
    PYG: 'PYG',
    QAR: 'QAR',
    RON: 'RON',
    RSD: 'RSD',
    RUB: 'RUB',
    RUR: 'RUR',
    RWF: 'RWF',
    SAR: 'SAR',
    SBD: 'SBD',
    SCR: 'SCR',
    SDG: 'SDG',
    SEK: 'SEK',
    SGD: 'SGD',
    SHP: 'SHP',
    SLL: 'SLL',
    SOS: 'SOS',
    SRD: 'SRD',
    SSP: 'SSP',
    STD: 'STD',
    STN: 'STN',
    SVC: 'SVC',
    SYP: 'SYP',
    SZL: 'SZL',
    THB: 'THB',
    TJS: 'TJS',
    TMT: 'TMT',
    TND: 'TND',
    TOP: 'TOP',
    TRY: 'TRY',
    TTD: 'TTD',
    TWD: 'TWD',
    TZS: 'TZS',
    UAH: 'UAH',
    UGX: 'UGX',
    USD: 'USD',
    USN: 'USN',
    USS: 'USS',
    UYI: 'UYI',
    UYU: 'UYU',
    UZS: 'UZS',
    VEF: 'VEF',
    VES: 'VES',
    VND: 'VND',
    VUV: 'VUV',
    WST: 'WST',
    XAF: 'XAF',
    XAG: 'XAG',
    XAU: 'XAU',
    XBA: 'XBA',
    XBB: 'XBB',
    XBC: 'XBC',
    XBD: 'XBD',
    XCD: 'XCD',
    XDR: 'XDR',
    XOF: 'XOF',
    XPD: 'XPD',
    XPF: 'XPF',
    XPT: 'XPT',
    XSU: 'XSU',
    XTS: 'XTS',
    XUA: 'XUA',
    XXX: 'XXX',
    YER: 'YER',
    ZAR: 'ZAR',
    ZMW: 'ZMW',
    ZWL: 'ZWL',
} as const

export interface BankAccountInfo {
    accountNumber?: string
    actualAccountNumber?: string
    bankCode?: string
    bic?: string
    currency?: BankAccountInfoCurrency
    iban?: string
    routingNumber?: string
}

export type AvailableProvidersResponseRecommendedItem =
    (typeof AvailableProvidersResponseRecommendedItem)[keyof typeof AvailableProvidersResponseRecommendedItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AvailableProvidersResponseRecommendedItem = {
    ONFIDO: 'ONFIDO',
    MIT_ID: 'MIT_ID',
    TUPAS: 'TUPAS',
    IDIN: 'IDIN',
    SE_BANK_ID: 'SE_BANK_ID',
    NO_BANK_ID: 'NO_BANK_ID',
    ITS_ME: 'ITS_ME',
} as const

export type AvailableProvidersResponseAvailableItem =
    (typeof AvailableProvidersResponseAvailableItem)[keyof typeof AvailableProvidersResponseAvailableItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AvailableProvidersResponseAvailableItem = {
    ONFIDO: 'ONFIDO',
    MIT_ID: 'MIT_ID',
    TUPAS: 'TUPAS',
    IDIN: 'IDIN',
    SE_BANK_ID: 'SE_BANK_ID',
    NO_BANK_ID: 'NO_BANK_ID',
    ITS_ME: 'ITS_ME',
} as const

export interface AvailableProvidersResponse {
    available: AvailableProvidersResponseAvailableItem[]
    recommended: AvailableProvidersResponseRecommendedItem[]
}

export interface Authentication {
    [key: string]: any
}

export type ClientAuthentication = Authentication & ClientAuthenticationAllOf

export type AmountRangeCurrency = (typeof AmountRangeCurrency)[keyof typeof AmountRangeCurrency]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AmountRangeCurrency = {
    UNDEFINED: 'UNDEFINED',
    AED: 'AED',
    AFN: 'AFN',
    ALL: 'ALL',
    AMD: 'AMD',
    ANG: 'ANG',
    AOA: 'AOA',
    ARS: 'ARS',
    AUD: 'AUD',
    AWG: 'AWG',
    AZN: 'AZN',
    BAM: 'BAM',
    BBD: 'BBD',
    BDT: 'BDT',
    BGN: 'BGN',
    BHD: 'BHD',
    BIF: 'BIF',
    BMD: 'BMD',
    BND: 'BND',
    BOB: 'BOB',
    BOV: 'BOV',
    BRL: 'BRL',
    BSD: 'BSD',
    BTN: 'BTN',
    BWP: 'BWP',
    BYN: 'BYN',
    BYR: 'BYR',
    BZD: 'BZD',
    CAD: 'CAD',
    CDF: 'CDF',
    CHE: 'CHE',
    CHF: 'CHF',
    CHW: 'CHW',
    CLF: 'CLF',
    CLP: 'CLP',
    CNY: 'CNY',
    COP: 'COP',
    COU: 'COU',
    CRC: 'CRC',
    CUC: 'CUC',
    CUP: 'CUP',
    CVE: 'CVE',
    CZK: 'CZK',
    DJF: 'DJF',
    DKK: 'DKK',
    DOP: 'DOP',
    DZD: 'DZD',
    EGP: 'EGP',
    ERN: 'ERN',
    ETB: 'ETB',
    EUR: 'EUR',
    FJD: 'FJD',
    FKP: 'FKP',
    GBP: 'GBP',
    GEL: 'GEL',
    GHS: 'GHS',
    GIP: 'GIP',
    GMD: 'GMD',
    GNF: 'GNF',
    GTQ: 'GTQ',
    GYD: 'GYD',
    HKD: 'HKD',
    HNL: 'HNL',
    HRK: 'HRK',
    HTG: 'HTG',
    HUF: 'HUF',
    IDR: 'IDR',
    ILS: 'ILS',
    INR: 'INR',
    IQD: 'IQD',
    IRR: 'IRR',
    ISK: 'ISK',
    JMD: 'JMD',
    JOD: 'JOD',
    JPY: 'JPY',
    KES: 'KES',
    KGS: 'KGS',
    KHR: 'KHR',
    KMF: 'KMF',
    KPW: 'KPW',
    KRW: 'KRW',
    KWD: 'KWD',
    KYD: 'KYD',
    KZT: 'KZT',
    LAK: 'LAK',
    LBP: 'LBP',
    LKR: 'LKR',
    LRD: 'LRD',
    LSL: 'LSL',
    LTL: 'LTL',
    LYD: 'LYD',
    MAD: 'MAD',
    MDL: 'MDL',
    MGA: 'MGA',
    MKD: 'MKD',
    MMK: 'MMK',
    MNT: 'MNT',
    MOP: 'MOP',
    MRO: 'MRO',
    MRU: 'MRU',
    MUR: 'MUR',
    MVR: 'MVR',
    MWK: 'MWK',
    MXN: 'MXN',
    MXV: 'MXV',
    MYR: 'MYR',
    MZN: 'MZN',
    NAD: 'NAD',
    NGN: 'NGN',
    NIO: 'NIO',
    NOK: 'NOK',
    NPR: 'NPR',
    NZD: 'NZD',
    OMR: 'OMR',
    PAB: 'PAB',
    PEN: 'PEN',
    PGK: 'PGK',
    PHP: 'PHP',
    PKR: 'PKR',
    PLN: 'PLN',
    PYG: 'PYG',
    QAR: 'QAR',
    RON: 'RON',
    RSD: 'RSD',
    RUB: 'RUB',
    RUR: 'RUR',
    RWF: 'RWF',
    SAR: 'SAR',
    SBD: 'SBD',
    SCR: 'SCR',
    SDG: 'SDG',
    SEK: 'SEK',
    SGD: 'SGD',
    SHP: 'SHP',
    SLL: 'SLL',
    SOS: 'SOS',
    SRD: 'SRD',
    SSP: 'SSP',
    STD: 'STD',
    STN: 'STN',
    SVC: 'SVC',
    SYP: 'SYP',
    SZL: 'SZL',
    THB: 'THB',
    TJS: 'TJS',
    TMT: 'TMT',
    TND: 'TND',
    TOP: 'TOP',
    TRY: 'TRY',
    TTD: 'TTD',
    TWD: 'TWD',
    TZS: 'TZS',
    UAH: 'UAH',
    UGX: 'UGX',
    USD: 'USD',
    USN: 'USN',
    USS: 'USS',
    UYI: 'UYI',
    UYU: 'UYU',
    UZS: 'UZS',
    VEF: 'VEF',
    VES: 'VES',
    VND: 'VND',
    VUV: 'VUV',
    WST: 'WST',
    XAF: 'XAF',
    XAG: 'XAG',
    XAU: 'XAU',
    XBA: 'XBA',
    XBB: 'XBB',
    XBC: 'XBC',
    XBD: 'XBD',
    XCD: 'XCD',
    XDR: 'XDR',
    XOF: 'XOF',
    XPD: 'XPD',
    XPF: 'XPF',
    XPT: 'XPT',
    XSU: 'XSU',
    XTS: 'XTS',
    XUA: 'XUA',
    XXX: 'XXX',
    YER: 'YER',
    ZAR: 'ZAR',
    ZMW: 'ZMW',
    ZWL: 'ZWL',
} as const

export interface AmountRange {
    currency?: AmountRangeCurrency
    maximum?: number
    minimum?: number
}

export interface AmlNoteResponse {
    author: string
    companyId: string
    createdAt: string
    id: string
    text: string
}

export interface AmlNoteRequest {
    note: string
}

export interface AmlDocumentResponse {
    companyId: string
    description?: string
    files: UploadedFileResponseV2[]
    id: string
    title?: string
}

export interface AmlDocumentRequest {
    description: string
    title: string
}

export interface AmlAnswersResponse {
    amountRange?: AmountRange
    averageAmountRange?: AmountRange
    categories: string[]
    continents: string[]
    frequentlyUsedInCountries: string[]
}

export interface AmlAnswersRequest {
    amountRange?: AmountRange
    averageAmountRange?: AmountRange
    categories: string[]
    continents: string[]
    frequentlyUsedInCountries: string[]
}

export type AdminVerificationRequestResponseStatus =
    (typeof AdminVerificationRequestResponseStatus)[keyof typeof AdminVerificationRequestResponseStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AdminVerificationRequestResponseStatus = {
    REQUESTED: 'REQUESTED',
    CANCELLED: 'CANCELLED',
} as const

export interface AdminVerificationRequestResponse {
    createdAt: string
    status: AdminVerificationRequestResponseStatus
    updatedAt?: string
    updatedBy: string
}

/**
 * Request to add a partner. The partner's data will be fetched from the registry.
 */
export interface AddRegistryPartnerRequest {
    /** The global id of the partner */
    id: string
    /** The global is of the partner's company. If the partner has a Pleo company, it will be linked to the partner. */
    ownCompanyId?: string
}

export type AccessDeniedExceptionSuppressedItemStackTraceItem = {
    classLoaderName?: string
    className?: string
    fileName?: string
    lineNumber?: number
    methodName?: string
    moduleName?: string
    moduleVersion?: string
    nativeMethod?: boolean
}

export type AccessDeniedExceptionSuppressedItem = {
    localizedMessage?: string
    message?: string
    stackTrace?: AccessDeniedExceptionSuppressedItemStackTraceItem[]
}

export type AccessDeniedExceptionStackTraceItem = {
    classLoaderName?: string
    className?: string
    fileName?: string
    lineNumber?: number
    methodName?: string
    moduleName?: string
    moduleVersion?: string
    nativeMethod?: boolean
}

export type AccessDeniedExceptionCauseStackTraceItem = {
    classLoaderName?: string
    className?: string
    fileName?: string
    lineNumber?: number
    methodName?: string
    moduleName?: string
    moduleVersion?: string
    nativeMethod?: boolean
}

export type AccessDeniedExceptionCause = {
    localizedMessage?: string
    message?: string
    stackTrace?: AccessDeniedExceptionCauseStackTraceItem[]
}

export interface AccessDeniedException {
    authentication: Authentication
    cause?: AccessDeniedExceptionCause
    localizedMessage?: string
    message?: string
    path: string
    reason: string
    stackTrace?: AccessDeniedExceptionStackTraceItem[]
    suppressed?: AccessDeniedExceptionSuppressedItem[]
    type?: string
}

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
    config: any,
    args: infer P
) => any
    ? P
    : never

/**
 * @deprecated
 * @summary Search for a company in the registry
 */
export const searchCompanies = (
    params: SearchCompaniesParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<RegistryCompanyResponse[]>(
        { url: `/registry/companies`, method: 'get', params },
        options
    )
}

/**
 * @deprecated
 * @summary Retrieve the details
 */
export const getCompany = (registryId: string, options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<RegistryCompanyResponse>(
        { url: `/registry/companies/${registryId}`, method: 'get' },
        options
    )
}

/**
 * @deprecated
 * @summary Retrieves all of a companies details and persists them.
 */
export const getAndPersistCompany = (
    registryId: string,
    params: GetAndPersistCompanyParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CompanyResponse>(
        { url: `/registry/companies/${registryId}/persist`, method: 'post', params },
        options
    )
}

/**
 * @summary Retrieves a list of supported countries. If filterNoRegistrationNumberLookup is set to true,it will remove countries that do no support search by registration number.
 */
export const supportedCountries = (
    params: SupportedCountriesParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<SupportedCountriesItem[]>(
        { url: `/registry/countries`, method: 'get', params },
        options
    )
}

/**
 * @deprecated
 * @summary Get all Business Offering Types
 */
export const getTypes = (options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<string[]>({ url: `/v1/business-offering`, method: 'get' }, options)
}

/**
 * @deprecated
 * @summary Lists the managed companies filtered by search parameters
 */
export const getCompanies = (
    params: GetCompaniesParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CompanyResponse[]>({ url: `/v1/companies`, method: 'get', params }, options)
}

/**
 * @deprecated
 * @summary Creates a new company
 */
export const createCompany = (
    companyRequest: CompanyRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CompanyResponse>(
        {
            url: `/v1/companies`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: companyRequest,
        },
        options
    )
}

/**
 * While the company will not be listed anymore, old revisions will still be available from the list revisions and company details endpoints.
 * @summary Removes from the managed companies
 */
export const deleteCompany = (id: string, options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<void>({ url: `/v1/companies/${id}`, method: 'delete' }, options)
}

/**
 * @summary Retrieves the details of a company
 */
export const getCompany1 = (
    id: string,
    params: GetCompany1Params,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CompanyResponse>(
        { url: `/v1/companies/${id}`, method: 'get', params },
        options
    )
}

/**
 * @summary Updates the company information
 */
export const updateCompany = (
    id: string,
    companyRequest: CompanyRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CompanyResponse>(
        {
            url: `/v1/companies/${id}`,
            method: 'patch',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: companyRequest,
        },
        options
    )
}

/**
 * @summary Get the company's adverse media checks
 */
export const getCompanyAdverseMedia = (
    id: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<KycCheck[]>(
        { url: `/v1/companies/${id}/adverse-media`, method: 'get' },
        options
    )
}

/**
 * @summary Retrieves the details of a deleted company
 */
export const getDeletedCompany = (id: string, options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<CompanyResponse>(
        { url: `/v1/companies/${id}/deleted`, method: 'get' },
        options
    )
}

/**
 * @summary Get the last offboarding status before company deletion
 */
export const getLastOffboardingStatus = (
    id: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CompanyOffboardingStatusResponse>(
        { url: `/v1/companies/${id}/deleted-offboarding-status`, method: 'get' },
        options
    )
}

/**
 * @summary Adds a director
 */
export const addDirector = (
    id: string,
    directorRequest: DirectorRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CompanyResponse>(
        {
            url: `/v1/companies/${id}/directors`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: directorRequest,
        },
        options
    )
}

/**
 * @summary Removes a director
 */
export const removeDirector = (
    id: string,
    directorId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<void>(
        { url: `/v1/companies/${id}/directors/${directorId}`, method: 'delete' },
        options
    )
}

/**
 * @deprecated
 * @summary Get all the document tied to a company
 */
export const getDocuments = (
    id: string,
    params: GetDocumentsParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CompanyDocumentResponse[]>(
        { url: `/v1/companies/${id}/documents`, method: 'get', params },
        options
    )
}

/**
 * @deprecated
 * @summary Create a company document by providing information about it
 */
export const createDocument = (
    id: string,
    companyDocumentRequest: CompanyDocumentRequest,
    params: CreateDocumentParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CompanyDocumentResponse>(
        {
            url: `/v1/companies/${id}/documents`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: companyDocumentRequest,
            params,
        },
        options
    )
}

/**
 * @deprecated
 * @summary Delete the company document
 */
export const deleteDocument = (
    id: string,
    documentId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<void>(
        { url: `/v1/companies/${id}/documents/${documentId}`, method: 'delete' },
        options
    )
}

/**
 * @deprecated
 * @summary Get the company document
 */
export const getDocument = (
    id: string,
    documentId: string,
    params: GetDocumentParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CompanyDocumentResponse>(
        { url: `/v1/companies/${id}/documents/${documentId}`, method: 'get', params },
        options
    )
}

/**
 * @deprecated
 * @summary Update the information of a document
 */
export const updateDocument = (
    id: string,
    documentId: string,
    companyDocumentRequest: CompanyDocumentRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CompanyDocumentResponse>(
        {
            url: `/v1/companies/${id}/documents/${documentId}`,
            method: 'patch',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: companyDocumentRequest,
        },
        options
    )
}

/**
 * @deprecated
 * @summary Replace the company document details
 */
export const replaceDocumentDetails = (
    id: string,
    documentId: string,
    companyDocumentRequest: CompanyDocumentRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CompanyDocumentResponse>(
        {
            url: `/v1/companies/${id}/documents/${documentId}`,
            method: 'put',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: companyDocumentRequest,
        },
        options
    )
}

/**
 * @deprecated
 * @summary Get the list of files tied to the company document
 */
export const getDocumentFiles = (
    id: string,
    documentId: string,
    params: GetDocumentFilesParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<UploadedFileResponse[]>(
        { url: `/v1/companies/${id}/documents/${documentId}/files`, method: 'get', params },
        options
    )
}

/**
 * @deprecated
 * @summary Upload a file to a company document
 */
export const createDocumentFile = (
    id: string,
    documentId: string,
    createDocumentFileBody: CreateDocumentFileBody,
    options: SecondParameter<typeof moonRequest>
) => {
    const formData = new FormData()
    formData.append('file', JSON.stringify(createDocumentFileBody.file))

    return moonRequest<UploadedFileResponse>(
        {
            url: `/v1/companies/${id}/documents/${documentId}/files`,
            method: 'post',
            headers: { 'Content-Type': 'multipart/form-data' },
            data: formData,
        },
        options
    )
}

/**
 * @deprecated
 * @summary Delete the company document file
 */
export const deleteDocumentFile = (
    id: string,
    documentId: string,
    fileId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        { url: `/v1/companies/${id}/documents/${documentId}/files/${fileId}`, method: 'delete' },
        options
    )
}

/**
 * @deprecated
 * @summary Get one specific file tied to the company document
 */
export const getDocumentFile = (
    id: string,
    documentId: string,
    fileId: string,
    params: GetDocumentFileParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<UploadedFileResponse>(
        {
            url: `/v1/companies/${id}/documents/${documentId}/files/${fileId}`,
            method: 'get',
            params,
        },
        options
    )
}

/**
 * @deprecated
 * @summary Update the information of a specific file tied to the company document
 */
export const updateDocumentFile = (
    id: string,
    documentId: string,
    fileId: string,
    uploadedFileRequest: UploadedFileRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<UploadedFileResponse>(
        {
            url: `/v1/companies/${id}/documents/${documentId}/files/${fileId}`,
            method: 'put',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: uploadedFileRequest,
        },
        options
    )
}

/**
 * @deprecated
 * @summary Get the download link of the document file
 */
export const getDocumentFileLink = (
    id: string,
    documentId: string,
    fileId: string,
    params: GetDocumentFileLinkParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<FileDownloadLinkResponse>(
        {
            url: `/v1/companies/${id}/documents/${documentId}/files/${fileId}/link`,
            method: 'get',
            params,
        },
        options
    )
}

/**
 * @deprecated
 * @summary Get the metadata of the document file
 */
export const getDocumentFileMetadata = (
    id: string,
    documentId: string,
    fileId: string,
    params: GetDocumentFileMetadataParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<FileMetadataResponse>(
        {
            url: `/v1/companies/${id}/documents/${documentId}/files/${fileId}/metadata`,
            method: 'get',
            params,
        },
        options
    )
}

/**
 * @summary Get the last offboarding status before company deletion
 */
export const getPreviousNames = (id: string, options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<string[]>(
        { url: `/v1/companies/${id}/legal-name-history`, method: 'get' },
        options
    )
}

/**
 * @summary Get the company's sanctions checks
 */
export const getCompanySanctions = (id: string, options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<KycCheck[]>({ url: `/v1/companies/${id}/sanctions`, method: 'get' }, options)
}

/**
 * @summary Create a KYC screening on the company entity
 */
export const createCompanyScreening = (
    id: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<KycCheck[]>(
        { url: `/v1/companies/${id}/screenings`, method: 'post' },
        options
    )
}

/**
 * @summary Adds a shareholder
 */
export const addShareholder = (
    id: string,
    shareholderRequest: ShareholderRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CompanyResponse>(
        {
            url: `/v1/companies/${id}/shareholders`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: shareholderRequest,
        },
        options
    )
}

/**
 * @summary Deletes a shareholder
 */
export const removeShareholder = (
    id: string,
    shareholderId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        { url: `/v1/companies/${id}/shareholders/${shareholderId}`, method: 'delete' },
        options
    )
}

/**
 * @summary Retrieves all UBOs for a company
 */
export const getUbos = (
    id: string,
    params: GetUbosParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<UboResponse[]>(
        { url: `/v1/companies/${id}/ubos`, method: 'get', params },
        options
    )
}

/**
 * @summary Get the list of KYC persons for this company
 */
export const getAdmins = (
    rootCompanyId: string,
    params: GetAdminsParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<string[]>(
        { url: `/v1/companies/${rootCompanyId}/admins`, method: 'get', params },
        options
    )
}

/**
 * @summary Get a company's KYC checkpoints
 */
export const getKycCheckpoints = (
    rootCompanyId: string,
    params: GetKycCheckpointsParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<KycStatusResponse[]>(
        { url: `/v1/companies/${rootCompanyId}/kyc/checkpoints`, method: 'get', params },
        options
    )
}

/**
 * @summary Create a kyc checkpoint
 */
export const createKycCheckpoint = (
    rootCompanyId: string,
    kycStatusRequest: KycStatusRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<KycStatusResponse>(
        {
            url: `/v1/companies/${rootCompanyId}/kyc/checkpoints`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: kycStatusRequest,
        },
        options
    )
}

/**
 * @summary Get the list of KYC persons for this company
 */
export const getKycPersons = (
    rootCompanyId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<string[]>(
        { url: `/v1/companies/${rootCompanyId}/kyc/persons`, method: 'get' },
        options
    )
}

/**
 * @deprecated
 * @summary Remove a person from the list of KYC persons for this company
 */
export const removeKycPerson = (
    rootCompanyId: string,
    personId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        { url: `/v1/companies/${rootCompanyId}/kyc/persons/${personId}`, method: 'delete' },
        options
    )
}

/**
 * @deprecated
 * @summary Add a person to the list of KYC persons for this company
 */
export const addKycPerson = (
    rootCompanyId: string,
    personId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<string[]>(
        { url: `/v1/companies/${rootCompanyId}/kyc/persons/${personId}`, method: 'put' },
        options
    )
}

/**
 * @summary Removes the risk assessment for a company
 */
export const deleteRiskAssessment = (
    rootCompanyId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        { url: `/v1/companies/${rootCompanyId}/risk`, method: 'delete' },
        options
    )
}

/**
 * @summary Retrieves the risk assessment for a company
 */
export const getRiskAssessment = (
    rootCompanyId: string,
    params: GetRiskAssessmentParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<RiskAssessmentResponse>(
        { url: `/v1/companies/${rootCompanyId}/risk`, method: 'get', params },
        options
    )
}

/**
 * @summary Update the risk assessment for a company
 */
export const updateRiskAssessment = (
    rootCompanyId: string,
    riskAssessmentRequest: RiskAssessmentRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<RiskAssessmentResponse>(
        {
            url: `/v1/companies/${rootCompanyId}/risk`,
            method: 'patch',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: riskAssessmentRequest,
        },
        options
    )
}

/**
 * @summary Retrieves all risk checks associated with an risk assessment of a company
 */
export const getRiskChecks = (
    rootCompanyId: string,
    params: GetRiskChecksParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<RiskCheckResponse[]>(
        { url: `/v1/companies/${rootCompanyId}/risk/checks`, method: 'get', params },
        options
    )
}

/**
 * @summary Retrieves a risk check for a company
 */
export const getRiskCheck = (
    rootCompanyId: string,
    riskCheckId: string,
    params: GetRiskCheckParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<RiskCheckResponse>(
        { url: `/v1/companies/${rootCompanyId}/risk/checks/${riskCheckId}`, method: 'get', params },
        options
    )
}

/**
 * @summary Retrieves the risk assessment history for a company
 */
export const getRiskAssessmentHistory = (
    rootCompanyId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<RiskAssessmentResponse[]>(
        { url: `/v1/companies/${rootCompanyId}/risk/history`, method: 'get' },
        options
    )
}

/**
 * @summary Retrieves the companies referencing this one as the root company
 */
export const getCompanyStakeholders = (
    rootCompanyId: string,
    params: GetCompanyStakeholdersParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CompanyResponse[]>(
        { url: `/v1/companies/${rootCompanyId}/stakeholders/companies`, method: 'get', params },
        options
    )
}

/**
 * @summary Retrieves the people referencing this company as the root company
 */
export const getPeopleStakeholders = (
    rootCompanyId: string,
    params: GetPeopleStakeholdersParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<PersonResponseV1[]>(
        { url: `/v1/companies/${rootCompanyId}/stakeholders/persons`, method: 'get', params },
        options
    )
}

export const all = (options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<string[]>({ url: `/v1/incorporation-types`, method: 'get' }, options)
}

/**
 * @deprecated
 * @summary Lists the managed people filtered by search parameters
 */
export const getPersons = (
    params: GetPersonsParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<PersonResponseV1[]>({ url: `/v1/persons`, method: 'get', params }, options)
}

/**
 * @summary Creates a new person
 */
export const createPerson = (
    personRequest: PersonRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<PersonResponseV1>(
        {
            url: `/v1/persons`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: personRequest,
        },
        options
    )
}

/**
 * @deprecated
 * @summary Retrieves the person associated to the authenticated user
 */
export const getAuthenticatedPerson = (options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<PersonResponseV1>(
        { url: `/v1/persons/authenticated`, method: 'get' },
        options
    )
}

/**
 * @deprecated
 * @summary Updates the person associated to the authenticated user
 */
export const patchAuthenticatedPerson = (
    updateAuthenticatedPersonRequest: UpdateAuthenticatedPersonRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<PersonResponseV1>(
        {
            url: `/v1/persons/authenticated`,
            method: 'patch',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: updateAuthenticatedPersonRequest,
        },
        options
    )
}

/**
 * @deprecated
 * @summary Creates the person associated to the authenticated user
 */
export const createAuthenticatedPerson = (
    createAuthenticatedPersonRequest: CreateAuthenticatedPersonRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<PersonResponseV1>(
        {
            url: `/v1/persons/authenticated`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: createAuthenticatedPersonRequest,
        },
        options
    )
}

/**
 * @deprecated
 * @summary Returns the available providers for the given person
 */
export const getAuthenticatedPersonAvailableProviders = (
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<AvailableProvidersResponse>(
        { url: `/v1/persons/authenticated/verification-providers`, method: 'get' },
        options
    )
}

/**
 * @deprecated
 * @summary Matches or creates a person with admin attribute associated to the authenticated user
 */
export const getOrCreateAuthenticatedAdmin = (
    createAuthenticatedPersonRequest: CreateAuthenticatedPersonRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<PersonResponseV1>(
        {
            url: `/v1/persons/match-person`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: createAuthenticatedPersonRequest,
        },
        options
    )
}

/**
 * @summary Deletes a person
 */
export const deletePerson = (personId: string, options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<unknown>({ url: `/v1/persons/${personId}`, method: 'delete' }, options)
}

/**
 * @summary Retrieves the details of a person at a given point in time
 */
export const getPerson = (
    personId: string,
    params: GetPersonParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<PersonResponseV1>(
        { url: `/v1/persons/${personId}`, method: 'get', params },
        options
    )
}

/**
 * @summary Updates the details of a person
 */
export const updatePerson = (
    personId: string,
    personRequest: PersonRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<PersonResponseV1>(
        {
            url: `/v1/persons/${personId}`,
            method: 'patch',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: personRequest,
        },
        options
    )
}

/**
 * @summary Replaces the details of a person
 */
export const replacePersonDetails = (
    personId: string,
    personRequest: PersonRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<PersonResponseV1>(
        {
            url: `/v1/persons/${personId}`,
            method: 'put',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: personRequest,
        },
        options
    )
}

/**
 * @summary Get all the documents and their details for a person
 */
export const getDocuments1 = (
    personId: string,
    params: GetDocuments1Params,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<PersonalDocumentResponse[]>(
        { url: `/v1/persons/${personId}/documents`, method: 'get', params },
        options
    )
}

/**
 * @summary Create a personal document by providing information about it
 */
export const createDocument1 = (
    personId: string,
    personalDocumentRequest: PersonalDocumentRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<PersonalDocumentResponse>(
        {
            url: `/v1/persons/${personId}/documents`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: personalDocumentRequest,
        },
        options
    )
}

/**
 * @summary Delete the personal document
 */
export const deleteDocument1 = (
    personId: string,
    documentId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        { url: `/v1/persons/${personId}/documents/${documentId}`, method: 'delete' },
        options
    )
}

/**
 * @summary Get the personal document and all its details
 */
export const getDocument1 = (
    personId: string,
    documentId: string,
    params: GetDocument1Params,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<PersonalDocumentResponse>(
        { url: `/v1/persons/${personId}/documents/${documentId}`, method: 'get', params },
        options
    )
}

/**
 * @summary Update the personal document
 */
export const updateDocument1 = (
    personId: string,
    documentId: string,
    personalDocumentRequest: PersonalDocumentRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<PersonalDocumentResponse>(
        {
            url: `/v1/persons/${personId}/documents/${documentId}`,
            method: 'patch',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: personalDocumentRequest,
        },
        options
    )
}

/**
 * @summary Replace the personal document details
 */
export const replaceDocumentDetails1 = (
    personId: string,
    documentId: string,
    personalDocumentRequest: PersonalDocumentRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<PersonalDocumentResponse>(
        {
            url: `/v1/persons/${personId}/documents/${documentId}`,
            method: 'put',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: personalDocumentRequest,
        },
        options
    )
}

/**
 * @summary Get the list of files tied to the personal document
 */
export const getDocumentFiles1 = (
    personId: string,
    documentId: string,
    params: GetDocumentFiles1Params,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<UploadedFileResponse[]>(
        { url: `/v1/persons/${personId}/documents/${documentId}/files`, method: 'get', params },
        options
    )
}

/**
 * @summary Upload a file to a personal document
 */
export const createDocumentFile1 = (
    personId: string,
    documentId: string,
    createDocumentFile1Body: CreateDocumentFile1Body,
    options: SecondParameter<typeof moonRequest>
) => {
    const formData = new FormData()
    formData.append('file', JSON.stringify(createDocumentFile1Body.file))

    return moonRequest<UploadedFileResponse>(
        {
            url: `/v1/persons/${personId}/documents/${documentId}/files`,
            method: 'post',
            headers: { 'Content-Type': 'multipart/form-data' },
            data: formData,
        },
        options
    )
}

/**
 * @summary Delete the personal document file
 */
export const deleteDocumentFile1 = (
    personId: string,
    documentId: string,
    fileId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        {
            url: `/v1/persons/${personId}/documents/${documentId}/files/${fileId}`,
            method: 'delete',
        },
        options
    )
}

/**
 * @summary Get one specific file tied to the personal document
 */
export const getDocumentFile1 = (
    personId: string,
    documentId: string,
    fileId: string,
    params: GetDocumentFile1Params,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<UploadedFileResponse>(
        {
            url: `/v1/persons/${personId}/documents/${documentId}/files/${fileId}`,
            method: 'get',
            params,
        },
        options
    )
}

/**
 * @summary Update the information of a specific file tied to the personal document
 */
export const updateDocumentFile1 = (
    personId: string,
    documentId: string,
    fileId: string,
    uploadedFileRequest: UploadedFileRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<UploadedFileResponse>(
        {
            url: `/v1/persons/${personId}/documents/${documentId}/files/${fileId}`,
            method: 'put',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: uploadedFileRequest,
        },
        options
    )
}

/**
 * @summary Get the download link
 */
export const getDocumentFileLink1 = (
    personId: string,
    documentId: string,
    fileId: string,
    params: GetDocumentFileLink1Params,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<FileDownloadLinkResponse>(
        {
            url: `/v1/persons/${personId}/documents/${documentId}/files/${fileId}/link`,
            method: 'get',
            params,
        },
        options
    )
}

/**
 * @summary Get the file metadata
 */
export const getDocumentFileMetadata1 = (
    personId: string,
    documentId: string,
    fileId: string,
    params: GetDocumentFileMetadata1Params,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<FileMetadataResponse>(
        {
            url: `/v1/persons/${personId}/documents/${documentId}/files/${fileId}/metadata`,
            method: 'get',
            params,
        },
        options
    )
}

/**
 * @summary Retrieves the eKYC checks of the person
 */
export const getEkycChecks = (
    personId: string,
    params: GetEkycChecksParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<KycCheck[]>(
        { url: `/v1/persons/${personId}/ekyc/checks`, method: 'get', params },
        options
    )
}

/**
 * @summary Performs an eKYC check
 */
export const createEkycCheck = (personId: string, options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<KycCheck>(
        { url: `/v1/persons/${personId}/ekyc/checks`, method: 'post' },
        options
    )
}

/**
 * @summary Retrieves the details of an eKYC check
 */
export const getEkycCheck = (
    personId: string,
    checkId: string,
    params: GetEkycCheckParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<KycCheck>(
        { url: `/v1/persons/${personId}/ekyc/checks/${checkId}`, method: 'get', params },
        options
    )
}

/**
 * @summary Updates an eKYC check
 */
export const updateEkycCheck = (
    personId: string,
    checkId: string,
    kycCheck: KycCheck,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<KycCheck>(
        {
            url: `/v1/persons/${personId}/ekyc/checks/${checkId}`,
            method: 'patch',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: kycCheck,
        },
        options
    )
}

/**
 * @summary Get all the IDV checks for a given person
 */
export const getIdvChecks = (
    personId: string,
    params: GetIdvChecksParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<IdvCheckResponse[]>(
        { url: `/v1/persons/${personId}/idvchecks`, method: 'get', params },
        options
    )
}

/**
 * @summary Perform an IDV check for a given person
 */
export const createIdvCheck = (personId: string, options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<IdvCheckResponse>(
        { url: `/v1/persons/${personId}/idvchecks`, method: 'post' },
        options
    )
}

/**
 * @summary Get person IDV check
 */
export const getIdvCheck = (
    personId: string,
    checkId: string,
    params: GetIdvCheckParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<IdvCheckResponse>(
        { url: `/v1/persons/${personId}/idvchecks/${checkId}`, method: 'get', params },
        options
    )
}

/**
 * @summary Manually update the personal document check
 */
export const updateIdvCheck = (
    personId: string,
    checkId: string,
    kycCheckRequest: KycCheckRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<IdvCheckResponse>(
        {
            url: `/v1/persons/${personId}/idvchecks/${checkId}`,
            method: 'patch',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: kycCheckRequest,
        },
        options
    )
}

/**
 * @summary Resume the personal document check
 */
export const resumeIdvCheck = (
    personId: string,
    checkId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<IdvCheckResponse>(
        { url: `/v1/persons/${personId}/idvchecks/${checkId}`, method: 'post' },
        options
    )
}

/**
 * @summary Remove a person from the KYC verification scope
 */
export const removeKycPerson1 = (
    personId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>({ url: `/v1/persons/${personId}/kyc`, method: 'delete' }, options)
}

/**
 * @summary Marks this person as in-scope for KYC verification
 */
export const addKycPerson1 = (personId: string, options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<PersonResponseV1>(
        { url: `/v1/persons/${personId}/kyc`, method: 'put' },
        options
    )
}

/**
 * @summary Retrieves the PEP checks of the person
 */
export const getPepChecks = (
    personId: string,
    params: GetPepChecksParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<KycCheck[]>(
        { url: `/v1/persons/${personId}/pepchecks`, method: 'get', params },
        options
    )
}

/**
 * @summary Performs a PEP check
 */
export const createPepCheck = (personId: string, options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<KycCheck>(
        { url: `/v1/persons/${personId}/pepchecks`, method: 'post' },
        options
    )
}

/**
 * @summary Retrieves the details of a PEP check
 */
export const getPepCheck = (
    personId: string,
    checkId: string,
    params: GetPepCheckParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<KycCheck>(
        { url: `/v1/persons/${personId}/pepchecks/${checkId}`, method: 'get', params },
        options
    )
}

/**
 * @summary Flag a PEP check as false positive
 */
export const markPepCheckAsFalsePositive = (
    personId: string,
    checkId: string,
    kycCheckUpdateRequest: KycCheckUpdateRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<KycCheck>(
        {
            url: `/v1/persons/${personId}/pepchecks/${checkId}/falsepositive`,
            method: 'put',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: kycCheckUpdateRequest,
        },
        options
    )
}

/**
 * @summary Performs PEP and SIS checks, as well as all other screenings available.
 */
export const createScreening = (personId: string, options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<KycCheck[]>(
        { url: `/v1/persons/${personId}/screening`, method: 'post' },
        options
    )
}

/**
 * @summary Retrieves the SIS checks of the person
 */
export const getSisChecks = (
    personId: string,
    params: GetSisChecksParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<KycCheck[]>(
        { url: `/v1/persons/${personId}/sischecks`, method: 'get', params },
        options
    )
}

/**
 * @summary Performs a SIS check
 */
export const createSisCheck = (personId: string, options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<KycCheck>(
        { url: `/v1/persons/${personId}/sischecks`, method: 'post' },
        options
    )
}

/**
 * @summary Retrieves the details of a SIS check
 */
export const getSisCheck = (
    personId: string,
    checkId: string,
    params: GetSisCheckParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<KycCheck>(
        { url: `/v1/persons/${personId}/sischecks/${checkId}`, method: 'get', params },
        options
    )
}

/**
 * @summary Flag a SIS check as false positive
 */
export const updateSisCheck = (
    personId: string,
    checkId: string,
    kycCheckUpdateRequest: KycCheckUpdateRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<KycCheck>(
        {
            url: `/v1/persons/${personId}/sischecks/${checkId}/falsepositive`,
            method: 'put',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: kycCheckUpdateRequest,
        },
        options
    )
}

/**
 * @summary Returns the available providers for the given person
 */
export const getAvailableProviders = (
    personId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<AvailableProvidersResponse>(
        { url: `/v1/persons/${personId}/verification-providers`, method: 'get' },
        options
    )
}

/**
 * @summary Performs the KYC policy review on the company
 */
export const performCompanyReview = (
    rootCompanyId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CompanyResponse>(
        { url: `/v1/reviews/companies/${rootCompanyId}`, method: 'post' },
        options
    )
}

/**
 * @summary Retrieves all the documents within this company's scope
 */
export const getAllDocuments = (
    rootCompanyId: string,
    params: GetAllDocumentsParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        { url: `/v1/reviews/companies/${rootCompanyId}/documents`, method: 'get', params },
        options
    )
}

/**
 * @summary Deletes an unknown legal person
 */
export const deleteUnknownLegalPerson = (
    id: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>({ url: `/v1/unknowns/${id}`, method: 'delete' }, options)
}

/**
 * @summary Retrieves the details of an unknown at a given point in time
 */
export const getUnknown = (
    id: string,
    params: GetUnknownParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<UnknownLegalPersonResponse>(
        { url: `/v1/unknowns/${id}`, method: 'get', params },
        options
    )
}

/**
 * @summary Endpoint to remove country search entries in the company cache
 */
export const cleanupCountrySearchCache = (
    countrySearchDeletionRequest: CountrySearchDeletionRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<Unit>(
        {
            url: `/v2/admin/clean-cache`,
            method: 'delete',
            headers: { 'Content-Type': '*/*' },
            data: countrySearchDeletionRequest,
        },
        options
    )
}

/**
 * @summary Endpoint to update search results with null addresses
 */
export const fixSearchAddress = (
    searchAddressFixRequest: SearchAddressFixRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        {
            url: `/v2/admin/fix-address`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: searchAddressFixRequest,
        },
        options
    )
}

/**
 * @summary Endpoint to invalidate proof of funds cache based on currency
 */
export const invalidateProofOfFundsCache = (
    invalidateCacheRequest: InvalidateCacheRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<number>(
        {
            url: `/v2/admin/invalidate-pof-cache`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: invalidateCacheRequest,
        },
        options
    )
}

/**
 * @summary Process Dangling Bank Transaction Events
 */
export const processBankTransactionKafkaDLQ = (options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<unknown>(
        { url: `/v2/admin/process-kafka-dlq/bank-transaction`, method: 'post' },
        options
    )
}

/**
 * @summary Process Dangling deimos company Events
 */
export const processDeimosCompanyKafkaDLQ = (options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<unknown>(
        { url: `/v2/admin/process-kafka-dlq/deimos-company`, method: 'post' },
        options
    )
}

/**
 * @summary Process Dangling PersonVerification Events
 */
export const processPersonVerificationKafkaDLQ = (options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<unknown>(
        { url: `/v2/admin/process-kafka-dlq/person-verification`, method: 'post' },
        options
    )
}

/**
 * @summary Process Dangling Styx Company Events
 */
export const processStyxCompanyKafkaDLQ = (options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<unknown>(
        { url: `/v2/admin/process-kafka-dlq/styx-company`, method: 'post' },
        options
    )
}

/**
 * @summary Process Dangling Telesto Payment Events
 */
export const processTelestoPaymentKafkaDLQ = (options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<unknown>(
        { url: `/v2/admin/process-kafka-dlq/telesto-payment`, method: 'post' },
        options
    )
}

/**
 * @summary Get a list of amount ranges
 */
export const getAmountRanges = (
    params: GetAmountRangesParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<AmountRange[]>(
        { url: `/v2/aml-answers/amount-ranges`, method: 'get', params },
        options
    )
}

/**
 * @summary Get a list of average amount ranges
 */
export const getAverageAmountRanges = (
    params: GetAverageAmountRangesParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<AmountRange[]>(
        { url: `/v2/aml-answers/average-amount-ranges`, method: 'get', params },
        options
    )
}

/**
 * @summary Get answers for a single company
 */
export const getAnswers = (companyId: string, options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<AmlAnswersResponse>(
        { url: `/v2/aml-answers/companies/${companyId}`, method: 'get' },
        options
    )
}

export const upsertAnswers = (
    companyId: string,
    amlAnswersRequest: AmlAnswersRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<AmlAnswersResponse>(
        {
            url: `/v2/aml-answers/companies/${companyId}`,
            method: 'put',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: amlAnswersRequest,
        },
        options
    )
}

/**
 * @summary Get aml documents for a single company
 */
export const getAmlDocuments = (
    companyId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<AmlDocumentResponse[]>(
        { url: `/v2/aml/companies/${companyId}/documents`, method: 'get' },
        options
    )
}

/**
 * @summary create aml document for a company
 */
export const createAmlDocument = (
    companyId: string,
    amlDocumentRequest: AmlDocumentRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<AmlDocumentResponse>(
        {
            url: `/v2/aml/companies/${companyId}/documents`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: amlDocumentRequest,
        },
        options
    )
}

/**
 * @summary create aml document file for a company
 */
export const createAmlDocumentFile = (
    companyId: string,
    documentId: string,
    createAmlDocumentFileBody: CreateAmlDocumentFileBody,
    options: SecondParameter<typeof moonRequest>
) => {
    const formData = new FormData()
    formData.append('file', JSON.stringify(createAmlDocumentFileBody.file))

    return moonRequest<UploadedFileResponseV2>(
        {
            url: `/v2/aml/companies/${companyId}/documents/${documentId}/files`,
            method: 'post',
            headers: { 'Content-Type': 'multipart/form-data' },
            data: formData,
        },
        options
    )
}

/**
 * @summary Get aml documents for a single company
 */
export const getAmlDocumentFile = (
    companyId: string,
    documentId: string,
    fileId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<FileDownloadLinkResponse>(
        {
            url: `/v2/aml/companies/${companyId}/documents/${documentId}/files/${fileId}/link`,
            method: 'get',
        },
        options
    )
}

/**
 * @summary Get aml notes for a single company
 */
export const getAmlNotes = (companyId: string, options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<AmlNoteResponse[]>(
        { url: `/v2/aml/companies/${companyId}/notes`, method: 'get' },
        options
    )
}

/**
 * @summary Create aml note for a company
 */
export const createAmlNote = (
    companyId: string,
    amlNoteRequest: AmlNoteRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<AmlNoteResponse>(
        {
            url: `/v2/aml/companies/${companyId}/notes`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: amlNoteRequest,
        },
        options
    )
}

/**
 * @summary Update aml note for a company
 */
export const updateAmlNote = (
    companyId: string,
    noteId: string,
    amlNoteRequest: AmlNoteRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<AmlNoteResponse>(
        {
            url: `/v2/aml/companies/${companyId}/notes/${noteId}`,
            method: 'patch',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: amlNoteRequest,
        },
        options
    )
}

/**
 * @summary Delete aml document file for a company
 */
export const deleteAmlDocumentFile = (
    globalId: string,
    documentId: string,
    fileId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<void>(
        {
            url: `/v2/aml/companies/${globalId}/documents/${documentId}/files/${fileId}`,
            method: 'delete',
        },
        options
    )
}

/**
 * @summary Register bank verification details
 */
export const createBankVerificationDetails = (
    bankVerificationDetailsPostRequest: BankVerificationDetailsPostRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<BankVerificationDetailsResponse>(
        {
            url: `/v2/bank-verification-details`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: bankVerificationDetailsPostRequest,
        },
        options
    )
}

/**
 * @summary Get bank verification details
 */
export const getBankVerificationDetails = (
    id: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<BankVerificationDetailsResponse>(
        { url: `/v2/bank-verification-details/${id}`, method: 'get' },
        options
    )
}

/**
 * @summary Update bank verification details
 */
export const updateBankVerificationDetails = (
    id: string,
    bankVerificationDetailsPatchRequest: BankVerificationDetailsPatchRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<BankVerificationDetailsResponse>(
        {
            url: `/v2/bank-verification-details/${id}`,
            method: 'patch',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: bankVerificationDetailsPatchRequest,
        },
        options
    )
}

/**
 * @summary Get answers for a single company
 */
export const getAnswers1 = (companyId: string, options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<BillsAmlAnswersResponse>(
        { url: `/v2/bill-invoices-aml-answers/companies/${companyId}`, method: 'get' },
        options
    )
}

/**
 * @summary Create or update answers for a single company
 */
export const upsertAnswers1 = (
    companyId: string,
    billsAmlAnswersRequest: BillsAmlAnswersRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<BillsAmlAnswersResponse>(
        {
            url: `/v2/bill-invoices-aml-answers/companies/${companyId}`,
            method: 'put',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: billsAmlAnswersRequest,
        },
        options
    )
}

/**
 * @summary Get deleted company
 */
export const getDeletedCompanyByRegistryId = (
    params: GetDeletedCompanyByRegistryIdParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<string[]>({ url: `/v2/companies/deleted`, method: 'get', params }, options)
}

/**
 * @summary Retrieves companies based on the given parameters
 */
export const getRootCompanies = (
    params: GetRootCompaniesParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<RootCompaniesResponseV2>(
        { url: `/v2/companies/root-companies`, method: 'get', params },
        options
    )
}

/**
 * @summary Lists the Companies filtered by search parameters
 */
export const searchCompanies1 = (
    params: SearchCompanies1Params,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CursorPaginationDataResponseCompanyResponseV2>(
        { url: `/v2/companies/search`, method: 'get', params },
        options
    )
}

/**
 * @summary Retrieves the basic details of a company
 */
export const getCompany2 = (globalId: string, options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<CompanyResponseV2>(
        { url: `/v2/companies/${globalId}`, method: 'get' },
        options
    )
}

/**
 * @summary Get the admin verification requests for a company
 */
export const getCompanyAdminVerificationRequest = (
    globalId: string,
    params: GetCompanyAdminVerificationRequestParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<AdminVerificationRequestResponse>(
        { url: `/v2/companies/${globalId}/admin-verification-requests`, method: 'get', params },
        options
    )
}

/**
 * @summary Cancel an admin verification request for a company
 */
export const cancelCompanyAdminVerificationRequest = (
    globalId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<AdminVerificationRequestResponse>(
        { url: `/v2/companies/${globalId}/admin-verification-requests/cancel`, method: 'post' },
        options
    )
}

/**
 * @summary Request an admin verification request for a company
 */
export const requestCompanyAdminVerificationRequest = (
    globalId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<AdminVerificationRequestResponse>(
        { url: `/v2/companies/${globalId}/admin-verification-requests/request`, method: 'post' },
        options
    )
}

/**
 * @summary Get the company's adverse media checks
 */
export const getCompanyAdverseMedia1 = (
    globalId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<KycCheck[]>(
        { url: `/v2/companies/${globalId}/adverse-media`, method: 'get' },
        options
    )
}

/**
 * @summary Retrieves all bank verification details for a company
 */
export const getBankVerificationDetailsByCompanyId = (
    globalId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<BankVerificationDetailsResponse[]>(
        { url: `/v2/companies/${globalId}/bank-verification-details`, method: 'get' },
        options
    )
}

/**
 * @summary Get company business explanation
 */
export const getCompanyBusinessExplanation = (
    globalId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CompanyBusinessExplanationResponse>(
        { url: `/v2/companies/${globalId}/business-explanation`, method: 'get' },
        options
    )
}

/**
 * @summary Create or update company business explanation
 */
export const upsertCompanyBusinessExplanation = (
    globalId: string,
    companyBusinessExplanationRequest: CompanyBusinessExplanationRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CompanyBusinessExplanationResponse>(
        {
            url: `/v2/companies/${globalId}/business-explanation`,
            method: 'put',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: companyBusinessExplanationRequest,
        },
        options
    )
}

/**
 * @summary Get company business offering
 */
export const getCompanyBusinessOffering = (
    globalId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<BusinessOffering[]>(
        { url: `/v2/companies/${globalId}/business-offering`, method: 'get' },
        options
    )
}

/**
 * @summary Add company business offering
 */
export const insertCompanyBusinessOffering = (
    globalId: string,
    businessOffering: BusinessOffering,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        {
            url: `/v2/companies/${globalId}/business-offering`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: businessOffering,
        },
        options
    )
}

/**
 * @summary Delete company business offering
 */
export const deleteCompanyBusinessOffering = (
    globalId: string,
    businessOfferingName: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        {
            url: `/v2/companies/${globalId}/business-offering/${businessOfferingName}`,
            method: 'delete',
        },
        options
    )
}

/**
 * @summary Retrieves the details of a deleted company
 */
export const getDeletedCompany1 = (
    globalId: string,
    params: GetDeletedCompany1Params,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CompanyResponseV2>(
        { url: `/v2/companies/${globalId}/closed-company`, method: 'get', params },
        options
    )
}

/**
 * @summary Get all the document tied to a deleted company
 */
export const getClosedCompanyDocuments = (
    globalId: string,
    params: GetClosedCompanyDocumentsParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CompanyDocumentResponse[]>(
        { url: `/v2/companies/${globalId}/closed-company/documents`, method: 'get', params },
        options
    )
}

/**
 * @summary Get the company shareholders for a company id.
 */
export const getCompanyShareholders = (
    globalId: string,
    params: GetCompanyShareholdersParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CompanyStakeholderResponse[]>(
        { url: `/v2/companies/${globalId}/company-shareholders`, method: 'get', params },
        options
    )
}

/**
 * @summary Unassign the company's compliance officer
 */
export const removeCompanyComplianceOfficer = (
    globalId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CompanyResponseV2>(
        { url: `/v2/companies/${globalId}/compliance-officer`, method: 'delete' },
        options
    )
}

/**
 * @summary Updates the company's compliance officer
 */
export const assignCompanyComplianceOfficer = (
    globalId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CompanyResponseV2>(
        { url: `/v2/companies/${globalId}/compliance-officer`, method: 'patch' },
        options
    )
}

/**
 * @summary Get the deleted company's adverse media checks
 */
export const getDeletedCompanyAdverseMedia = (
    globalId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<KycCheck[]>(
        { url: `/v2/companies/${globalId}/deleted/adverse-media`, method: 'get' },
        options
    )
}

/**
 * @summary Get the deleted company's sanctions checks
 */
export const getDeletedCompanySanctions = (
    globalId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<KycCheck[]>(
        { url: `/v2/companies/${globalId}/deleted/sanctions`, method: 'get' },
        options
    )
}

/**
 * @summary Get all the document tied to a company
 */
export const getDocuments2 = (
    globalId: string,
    params: GetDocuments2Params,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CompanyDocumentResponse[]>(
        { url: `/v2/companies/${globalId}/documents`, method: 'get', params },
        options
    )
}

/**
 * @summary Create a company document by providing information about it
 */
export const createDocument2 = (
    globalId: string,
    companyDocumentRequest: CompanyDocumentRequest,
    params: CreateDocument2Params,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CompanyDocumentResponse>(
        {
            url: `/v2/companies/${globalId}/documents`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: companyDocumentRequest,
            params,
        },
        options
    )
}

/**
 * @summary Get a company's document requests
 */
export const getCompanyDocumentRequests = (
    globalId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<RequestedCompanyDocumentResponse[]>(
        { url: `/v2/companies/${globalId}/documents/requests`, method: 'get' },
        options
    )
}

/**
 * @summary Request a company document
 */
export const requestCompanyDocument = (
    globalId: string,
    companyDocumentRequest: CompanyDocumentRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<RequestedCompanyDocumentResponse>(
        {
            url: `/v2/companies/${globalId}/documents/requests`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: companyDocumentRequest,
        },
        options
    )
}

/**
 * @summary Get a company's pending document requests
 */
export const getCompanyPendingDocumentRequests = (
    globalId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<RequestedCompanyDocumentResponse[]>(
        { url: `/v2/companies/${globalId}/documents/requests/pending`, method: 'get' },
        options
    )
}

/**
 * @summary Delete the company document
 */
export const deleteDocument2 = (
    globalId: string,
    documentId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<void>(
        { url: `/v2/companies/${globalId}/documents/${documentId}`, method: 'delete' },
        options
    )
}

/**
 * @summary Get the company document
 */
export const getDocument2 = (
    globalId: string,
    documentId: string,
    params: GetDocument2Params,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CompanyDocumentResponse>(
        { url: `/v2/companies/${globalId}/documents/${documentId}`, method: 'get', params },
        options
    )
}

/**
 * @summary Update the information of a document
 */
export const updateDocument2 = (
    globalId: string,
    documentId: string,
    companyDocumentRequest: CompanyDocumentRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CompanyDocumentResponse>(
        {
            url: `/v2/companies/${globalId}/documents/${documentId}`,
            method: 'patch',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: companyDocumentRequest,
        },
        options
    )
}

/**
 * @summary Replace the company document details
 */
export const replaceDocumentDetails2 = (
    globalId: string,
    documentId: string,
    companyDocumentRequest: CompanyDocumentRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CompanyDocumentResponse>(
        {
            url: `/v2/companies/${globalId}/documents/${documentId}`,
            method: 'put',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: companyDocumentRequest,
        },
        options
    )
}

/**
 * @summary Get the list of files tied to the company document
 */
export const getDocumentFiles2 = (
    globalId: string,
    documentId: string,
    params: GetDocumentFiles2Params,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<UploadedFileResponse[]>(
        { url: `/v2/companies/${globalId}/documents/${documentId}/files`, method: 'get', params },
        options
    )
}

/**
 * @summary Upload a file to a company document
 */
export const createDocumentFile2 = (
    globalId: string,
    documentId: string,
    createDocumentFile2Body: CreateDocumentFile2Body,
    options: SecondParameter<typeof moonRequest>
) => {
    const formData = new FormData()
    formData.append('file', JSON.stringify(createDocumentFile2Body.file))

    return moonRequest<UploadedFileResponse>(
        {
            url: `/v2/companies/${globalId}/documents/${documentId}/files`,
            method: 'post',
            headers: { 'Content-Type': 'multipart/form-data' },
            data: formData,
        },
        options
    )
}

/**
 * @summary Delete the company document file
 */
export const deleteDocumentFile2 = (
    globalId: string,
    documentId: string,
    fileId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        {
            url: `/v2/companies/${globalId}/documents/${documentId}/files/${fileId}`,
            method: 'delete',
        },
        options
    )
}

/**
 * @summary Get one specific file tied to the company document
 */
export const getDocumentFile2 = (
    globalId: string,
    documentId: string,
    fileId: string,
    params: GetDocumentFile2Params,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<UploadedFileResponse>(
        {
            url: `/v2/companies/${globalId}/documents/${documentId}/files/${fileId}`,
            method: 'get',
            params,
        },
        options
    )
}

/**
 * @summary Update the information of a specific file tied to the company document
 */
export const updateDocumentFile2 = (
    globalId: string,
    documentId: string,
    fileId: string,
    uploadedFileRequest: UploadedFileRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<UploadedFileResponse>(
        {
            url: `/v2/companies/${globalId}/documents/${documentId}/files/${fileId}`,
            method: 'patch',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: uploadedFileRequest,
        },
        options
    )
}

/**
 * @summary Update the information of a specific file tied to the company document
 */
export const putUpdateDocumentFile = (
    globalId: string,
    documentId: string,
    fileId: string,
    uploadedFileRequest: UploadedFileRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<UploadedFileResponse>(
        {
            url: `/v2/companies/${globalId}/documents/${documentId}/files/${fileId}`,
            method: 'put',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: uploadedFileRequest,
        },
        options
    )
}

/**
 * @summary Get the download link of the document file
 */
export const getDocumentFileLink2 = (
    globalId: string,
    documentId: string,
    fileId: string,
    params: GetDocumentFileLink2Params,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<FileDownloadLinkResponse>(
        {
            url: `/v2/companies/${globalId}/documents/${documentId}/files/${fileId}/link`,
            method: 'get',
            params,
        },
        options
    )
}

/**
 * @summary Get the metadata of the document file
 */
export const getDocumentFileMetadata2 = (
    globalId: string,
    documentId: string,
    fileId: string,
    params: GetDocumentFileMetadata2Params,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<FileMetadataResponse>(
        {
            url: `/v2/companies/${globalId}/documents/${documentId}/files/${fileId}/metadata`,
            method: 'get',
            params,
        },
        options
    )
}

/**
 * @summary Checks if a company has active recurring monitoring checks of related entities
 */
export const companyHasActiveRecurringMonitoringEntities = (
    globalId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<boolean>(
        {
            url: `/v2/companies/${globalId}/has-active-recurring-monitoring-entities`,
            method: 'get',
        },
        options
    )
}

/**
 * @summary Get company industry codes
 */
export const getCompanyIndustryCodes = (
    globalId: string,
    params: GetCompanyIndustryCodesParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<IndustryCode[]>(
        { url: `/v2/companies/${globalId}/industry-codes`, method: 'get', params },
        options
    )
}

/**
 * @summary Create/Add company industry codes
 */
export const upsertCompanyIndustryCodes = (
    globalId: string,
    industryCode: IndustryCode,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<IndustryCode[]>(
        {
            url: `/v2/companies/${globalId}/industry-codes`,
            method: 'put',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: industryCode,
        },
        options
    )
}

/**
 * @summary Delete manually added company NACE code
 */
export const deleteCompanyIndustryNaceCode = (
    globalId: string,
    naceCode: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        { url: `/v2/companies/${globalId}/industry-codes/nace/${naceCode}`, method: 'delete' },
        options
    )
}

/**
 * @summary Delete one company industry code
 */
export const deleteCompanyIndustryCode = (
    globalId: string,
    companyIndustryCodeId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        {
            url: `/v2/companies/${globalId}/industry-codes/${companyIndustryCodeId}`,
            method: 'delete',
        },
        options
    )
}

/**
 * @summary Retrieves the current KYC status of a company
 */
export const getCompanyKycStatus = (
    globalId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<KycStatusResponse>(
        { url: `/v2/companies/${globalId}/kyc`, method: 'get' },
        options
    )
}

/**
 * @summary Get the KYC verification details for a company
 */
export const getKycVerificationDetails = (
    globalId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<KycVerificationDetailsResponse>(
        { url: `/v2/companies/${globalId}/kyc-verification-details`, method: 'get' },
        options
    )
}

/**
 * @summary Retrieves the KYC checkpoints of a company
 */
export const getCompanyKycCheckpoints = (
    globalId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<KycStatusResponse[]>(
        { url: `/v2/companies/${globalId}/kyc/checkpoints`, method: 'get' },
        options
    )
}

/**
 * @summary Get the company management
 */
export const getManagement = (
    globalId: string,
    params: GetManagementParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CompanyStakeholderResponse[]>(
        { url: `/v2/companies/${globalId}/management`, method: 'get', params },
        options
    )
}

/**
 * @summary Get the countries of manufacturing for a company
 */
export const getCompanyManufacturingCountries = (
    globalId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CompanyManufacturingCountriesResponse>(
        { url: `/v2/companies/${globalId}/manufacturing-countries`, method: 'get' },
        options
    )
}

/**
 * @summary Set the countries of manufacturing for a company
 */
export const setCompanyManufacturingCountries = (
    globalId: string,
    companyManufacturingCountriesRequest: CompanyManufacturingCountriesRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        {
            url: `/v2/companies/${globalId}/manufacturing-countries`,
            method: 'put',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: companyManufacturingCountriesRequest,
        },
        options
    )
}

/**
 * @summary Get company narratives
 */
export const getCompanyNarratives = (
    globalId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<NarrativeResponse[]>(
        { url: `/v2/companies/${globalId}/narrative`, method: 'get' },
        options
    )
}

/**
 * @summary Create company narrative
 */
export const createCompanyNarrative = (
    globalId: string,
    narrativeRequest: NarrativeRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<NarrativeResponse>(
        {
            url: `/v2/companies/${globalId}/narrative`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: narrativeRequest,
        },
        options
    )
}

/**
 * @summary Delete company narrative by narrative id
 */
export const deleteCompanyNarrativeById = (
    globalId: string,
    narrativeId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        { url: `/v2/companies/${globalId}/narrative/${narrativeId}`, method: 'delete' },
        options
    )
}

/**
 * @summary Get company narrative by narrative id
 */
export const getCompanyNarrativeById = (
    globalId: string,
    narrativeId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<NarrativeResponse>(
        { url: `/v2/companies/${globalId}/narrative/${narrativeId}`, method: 'get' },
        options
    )
}

/**
 * @summary Update company narrative by narrative id
 */
export const updateCompanyNarrative = (
    globalId: string,
    narrativeId: string,
    narrativeRequest: NarrativeRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<NarrativeResponse>(
        {
            url: `/v2/companies/${globalId}/narrative/${narrativeId}`,
            method: 'patch',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: narrativeRequest,
        },
        options
    )
}

/**
 * @summary Get the person shareholders for a company id.
 */
export const getPersonShareholders = (
    globalId: string,
    params: GetPersonShareholdersParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CompanyStakeholderResponse[]>(
        { url: `/v2/companies/${globalId}/person-shareholders`, method: 'get', params },
        options
    )
}

/**
 * @summary Get one proof of funds for a company.
 */
export const getProofOfFundsByCompanyId = (
    globalId: string,
    proofOfFundsId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<ProofOfFundsResponse>(
        { url: `/v2/companies/${globalId}/proof-of-funds/${proofOfFundsId}`, method: 'get' },
        options
    )
}

/**
 * @summary Retrieves the risk assessment for a company
 */
export const getRiskAssessment1 = (
    globalId: string,
    params: GetRiskAssessment1Params,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<RiskAssessmentResponse>(
        { url: `/v2/companies/${globalId}/risk`, method: 'get', params },
        options
    )
}

/**
 * @summary Update the risk assessment for a company
 */
export const updateRiskAssessment1 = (
    globalId: string,
    riskAssessmentRequest: RiskAssessmentRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<RiskAssessmentResponse>(
        {
            url: `/v2/companies/${globalId}/risk`,
            method: 'patch',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: riskAssessmentRequest,
        },
        options
    )
}

/**
 * @summary Creates a new risk assessment
 */
export const createRiskAssessment = (
    globalId: string,
    riskAssessmentRequest: RiskAssessmentRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<RiskAssessmentResponse>(
        {
            url: `/v2/companies/${globalId}/risk`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: riskAssessmentRequest,
        },
        options
    )
}

/**
 * @summary Get CRR specific data points for a company
 */
export const fetchRiskData = (globalId: string, options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<CompanyRiskDataPoints>(
        { url: `/v2/companies/${globalId}/risk-data-points`, method: 'get' },
        options
    )
}

/**
 * @summary Update the risk assessment for a B4B company
 */
export const updateB4BRiskAssessment = (
    globalId: string,
    riskAssessmentRequest: RiskAssessmentRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<RiskAssessmentResponse>(
        {
            url: `/v2/companies/${globalId}/risk/b4b`,
            method: 'patch',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: riskAssessmentRequest,
        },
        options
    )
}

/**
 * @summary Retrieves all risk checks associated with an risk assessment of a company enhanced with suggested risk score
 */
export const getRiskChecks1 = (
    globalId: string,
    params: GetRiskChecks1Params,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<RiskCheckResponse[]>(
        { url: `/v2/companies/${globalId}/risk/checks`, method: 'get', params },
        options
    )
}

/**
 * @summary Delete a risk check for a company
 */
export const deleteRiskCheck = (
    globalId: string,
    riskCheckId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        { url: `/v2/companies/${globalId}/risk/checks/${riskCheckId}`, method: 'delete' },
        options
    )
}

/**
 * @summary Update a risk check for a company
 */
export const updateRiskCheck = (
    globalId: string,
    riskCheckId: string,
    riskCheckRequest: RiskCheckRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<RiskCheckResponse>(
        {
            url: `/v2/companies/${globalId}/risk/checks/${riskCheckId}`,
            method: 'patch',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: riskCheckRequest,
        },
        options
    )
}

/**
 * @summary Retrieves the risk assessment for a closed company
 */
export const getClosedCompanyRiskAssessment = (
    globalId: string,
    params: GetClosedCompanyRiskAssessmentParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<RiskAssessmentResponse>(
        { url: `/v2/companies/${globalId}/risk/closed-company`, method: 'get', params },
        options
    )
}

/**
 * @summary Creates a new risk assessment and enhances the returned data with suggested scores from Themis
 */
export const createEnhancedRiskAssessment = (
    globalId: string,
    riskAssessmentRequest: RiskAssessmentRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<RiskAssessmentDataResponse>(
        {
            url: `/v2/companies/${globalId}/risk/enhanced`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: riskAssessmentRequest,
        },
        options
    )
}

/**
 * @summary Reset risk assessment for a company
 */
export const resetRiskAssessment = (
    globalId: string,
    resetRiskAssessmentRequest: ResetRiskAssessmentRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<RiskAssessmentResponse>(
        {
            url: `/v2/companies/${globalId}/risk/reset`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: resetRiskAssessmentRequest,
        },
        options
    )
}

/**
 * @summary Get the risk score for a company
 */
export const getRiskScore = (
    globalId: string,
    params: GetRiskScoreParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<RiskScoreResponse>(
        { url: `/v2/companies/${globalId}/risk/score`, method: 'get', params },
        options
    )
}

/**
 * @summary Get the company's sanctions checks
 */
export const getCompanySanctions1 = (
    globalId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<KycCheck[]>(
        { url: `/v2/companies/${globalId}/sanctions`, method: 'get' },
        options
    )
}

/**
 * @summary Create a KYC screening on the company entity
 */
export const createCompanyScreening1 = (
    globalId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<KycCheck[]>(
        { url: `/v2/companies/${globalId}/screenings`, method: 'post' },
        options
    )
}

/**
 * @summary Get the shareholder audit trail for a company id.
 */
export const fetchShareholderAuditTrail = (
    globalId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<ShareholderAuditEvent[]>(
        { url: `/v2/companies/${globalId}/shareholder-structure/audit`, method: 'get' },
        options
    )
}

/**
 * @summary Create screening for the significant company shareholders of the company
 */
export const screenSignificantCompanyShareholders = (
    globalId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        {
            url: `/v2/companies/${globalId}/significant-company-shareholders-screening`,
            method: 'post',
        },
        options
    )
}

/**
 * @summary Stops recurring monitoring for all related entities of a company
 */
export const stopRecurringMonitoringForAllRelatedEntities = (
    globalId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        { url: `/v2/companies/${globalId}/stop-recurring-monitoring`, method: 'delete' },
        options
    )
}

/**
 * @summary Get the verification flow for a company
 */
export const getCompanyVerificationFlow = (
    globalId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CompanyVerificationFlowDataResponse>(
        { url: `/v2/companies/${globalId}/verification-flow`, method: 'get' },
        options
    )
}

/**
 * @summary Create a verification flow for a company
 */
export const upsertCompanyVerificationFlow = (
    globalId: string,
    companyVerificationFlowRequest: CompanyVerificationFlowRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CompanyVerificationFlowDataResponse>(
        {
            url: `/v2/companies/${globalId}/verification-flow`,
            method: 'put',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: companyVerificationFlowRequest,
        },
        options
    )
}

/**
 * @summary Updates the company information
 */
export const updateCompany1 = (
    id: string,
    companyRequestV2: CompanyRequestV2,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CompanyResponseV2>(
        {
            url: `/v2/companies/${id}`,
            method: 'patch',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: companyRequestV2,
        },
        options
    )
}

/**
 * @summary Get the shareholder structure for a company id.
 */
export const fetchShareholderStructure = (
    id: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<ShareholderNode[]>(
        { url: `/v2/companies/${id}/shareholder-structure`, method: 'get' },
        options
    )
}

/**
 * @summary Lists the B4B companies filtered by search parameters
 */
export const getAllB4BCompanies = (
    params: GetAllB4BCompaniesParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CursorPaginationDataResponseCompanyResponseV2>(
        { url: `/v2/external/companies/b4b-companies`, method: 'get', params },
        options
    )
}

export const getAllB4BDocuments = (
    companyId: string,
    params: GetAllB4BDocumentsParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        { url: `/v2/external/reviews/b4b-companies/${companyId}/documents`, method: 'get', params },
        options
    )
}

/**
 * @summary Updates the billing readiness of a multi entity company
 */
export const setMultiEntityCompanyBilling = (
    globalId: string,
    params: SetMultiEntityCompanyBillingParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        { url: `/v2/grouping/companies/${globalId}`, method: 'post', params },
        options
    )
}

/**
 * @deprecated
 * @summary Get all NACE industry codes
 */
export const getIndustryCodes = (options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<NaceSection[]>({ url: `/v2/industry-codes`, method: 'get' }, options)
}

/**
 * @summary Retrieves the PEP checks of the persons
 */
export const getPepChecks1 = (
    params: GetPepChecks1Params,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<GetPepChecks1>(
        { url: `/v2/kyc-checks/pep-checks`, method: 'get', params },
        options
    )
}

/**
 * @summary Create a manually approved PEP check for a person
 */
export const createManuallyApprovePepCheck = (
    personId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<KycCheck>(
        { url: `/v2/kyc-checks/pep-checks/person/${personId}/manually-approve`, method: 'post' },
        options
    )
}

/**
 * @summary Flag a check as true positive
 */
export const manuallyApprovePepCheck = (
    checkId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<KycCheck>(
        { url: `/v2/kyc-checks/pep-checks/${checkId}/mark-true-positive`, method: 'post' },
        options
    )
}

/**
 * @summary Flag a check as false positive
 */
export const markKycCheckAsFalsePositive = (
    checkId: string,
    kycCheckUpdateRequest: KycCheckUpdateRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<KycCheck>(
        {
            url: `/v2/kyc-checks/${checkId}/falsepositive`,
            method: 'patch',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: kycCheckUpdateRequest,
        },
        options
    )
}

/**
 * @summary Get a kyc information name source for a company
 */
export const getKycInformationNameSource = (
    globalId: string,
    params: GetKycInformationNameSourceParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<KycInformationNameSourceDataResponse>(
        { url: `/v2/kyc-information-name-sources/companies/${globalId}`, method: 'get', params },
        options
    )
}

/**
 * @summary Get the kyc user responses for a company
 */
export const getCompanyKycUserResponses = (
    globalId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<KycUserResponseResponse>(
        { url: `/v2/kyc-user-responses/companies/${globalId}`, method: 'get' },
        options
    )
}

/**
 * @summary Create or Update the kyc user responses for a company
 */
export const upsertCompanyKycUserResponses = (
    globalId: string,
    kycUserResponseRequest: KycUserResponseRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<KycUserResponseResponse>(
        {
            url: `/v2/kyc-user-responses/companies/${globalId}`,
            method: 'put',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: kycUserResponseRequest,
        },
        options
    )
}

/**
 * @summary Get licensing authorities for a country
 */
export const getLicensingAuthorities = (
    country:
        | 'UNDEFINED'
        | 'AC'
        | 'AD'
        | 'AE'
        | 'AF'
        | 'AG'
        | 'AI'
        | 'AL'
        | 'AM'
        | 'AN'
        | 'AO'
        | 'AQ'
        | 'AR'
        | 'AS'
        | 'AT'
        | 'AU'
        | 'AW'
        | 'AX'
        | 'AZ'
        | 'BA'
        | 'BB'
        | 'BD'
        | 'BE'
        | 'BF'
        | 'BG'
        | 'BH'
        | 'BI'
        | 'BJ'
        | 'BL'
        | 'BM'
        | 'BN'
        | 'BO'
        | 'BQ'
        | 'BR'
        | 'BS'
        | 'BT'
        | 'BU'
        | 'BV'
        | 'BW'
        | 'BY'
        | 'BZ'
        | 'CA'
        | 'CC'
        | 'CD'
        | 'CF'
        | 'CG'
        | 'CH'
        | 'CI'
        | 'CK'
        | 'CL'
        | 'CM'
        | 'CN'
        | 'CO'
        | 'CP'
        | 'CR'
        | 'CS'
        | 'CU'
        | 'CV'
        | 'CW'
        | 'CX'
        | 'CY'
        | 'CZ'
        | 'DE'
        | 'DG'
        | 'DJ'
        | 'DK'
        | 'DM'
        | 'DO'
        | 'DZ'
        | 'EA'
        | 'EC'
        | 'EE'
        | 'EG'
        | 'EH'
        | 'ER'
        | 'ES'
        | 'ET'
        | 'EU'
        | 'EZ'
        | 'FI'
        | 'FJ'
        | 'FK'
        | 'FM'
        | 'FO'
        | 'FR'
        | 'FX'
        | 'GA'
        | 'GB'
        | 'GD'
        | 'GE'
        | 'GF'
        | 'GG'
        | 'GH'
        | 'GI'
        | 'GL'
        | 'GM'
        | 'GN'
        | 'GP'
        | 'GQ'
        | 'GR'
        | 'GS'
        | 'GT'
        | 'GU'
        | 'GW'
        | 'GY'
        | 'HK'
        | 'HM'
        | 'HN'
        | 'HR'
        | 'HT'
        | 'HU'
        | 'IC'
        | 'ID'
        | 'IE'
        | 'IL'
        | 'IM'
        | 'IN'
        | 'IO'
        | 'IQ'
        | 'IR'
        | 'IS'
        | 'IT'
        | 'JE'
        | 'JM'
        | 'JO'
        | 'JP'
        | 'KE'
        | 'KG'
        | 'KH'
        | 'KI'
        | 'KM'
        | 'KN'
        | 'KP'
        | 'KR'
        | 'KW'
        | 'KY'
        | 'KZ'
        | 'LA'
        | 'LB'
        | 'LC'
        | 'LI'
        | 'LK'
        | 'LR'
        | 'LS'
        | 'LT'
        | 'LU'
        | 'LV'
        | 'LY'
        | 'MA'
        | 'MC'
        | 'MD'
        | 'ME'
        | 'MF'
        | 'MG'
        | 'MH'
        | 'MK'
        | 'ML'
        | 'MM'
        | 'MN'
        | 'MO'
        | 'MP'
        | 'MQ'
        | 'MR'
        | 'MS'
        | 'MT'
        | 'MU'
        | 'MV'
        | 'MW'
        | 'MX'
        | 'MY'
        | 'MZ'
        | 'NA'
        | 'NC'
        | 'NE'
        | 'NF'
        | 'NG'
        | 'NI'
        | 'NL'
        | 'NO'
        | 'NP'
        | 'NR'
        | 'NT'
        | 'NU'
        | 'NZ'
        | 'OM'
        | 'PA'
        | 'PE'
        | 'PF'
        | 'PG'
        | 'PH'
        | 'PK'
        | 'PL'
        | 'PM'
        | 'PN'
        | 'PR'
        | 'PS'
        | 'PT'
        | 'PW'
        | 'PY'
        | 'QA'
        | 'RE'
        | 'RO'
        | 'RS'
        | 'RU'
        | 'RW'
        | 'SA'
        | 'SB'
        | 'SC'
        | 'SD'
        | 'SE'
        | 'SF'
        | 'SG'
        | 'SH'
        | 'SI'
        | 'SJ'
        | 'SK'
        | 'SL'
        | 'SM'
        | 'SN'
        | 'SO'
        | 'SR'
        | 'SS'
        | 'ST'
        | 'SU'
        | 'SV'
        | 'SX'
        | 'SY'
        | 'SZ'
        | 'TA'
        | 'TC'
        | 'TD'
        | 'TF'
        | 'TG'
        | 'TH'
        | 'TJ'
        | 'TK'
        | 'TL'
        | 'TM'
        | 'TN'
        | 'TO'
        | 'TP'
        | 'TR'
        | 'TT'
        | 'TV'
        | 'TW'
        | 'TZ'
        | 'UA'
        | 'UG'
        | 'UK'
        | 'UM'
        | 'US'
        | 'UY'
        | 'UZ'
        | 'VA'
        | 'VC'
        | 'VE'
        | 'VG'
        | 'VI'
        | 'VN'
        | 'VU'
        | 'WF'
        | 'WS'
        | 'XI'
        | 'XU'
        | 'XK'
        | 'YE'
        | 'YT'
        | 'YU'
        | 'ZA'
        | 'ZM'
        | 'ZR'
        | 'ZW',
    params: GetLicensingAuthoritiesParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DataResponseSetString>(
        { url: `/v2/licensing-authorities/${country}`, method: 'get', params },
        options
    )
}

/**
 * @summary Get closing company offboarding statuses
 */
export const getClosingCompanyOffboardingStatuses = (
    params: GetClosingCompanyOffboardingStatusesParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CompanyOffboardingStatusesResponse>(
        { url: `/v2/offboarding-status`, method: 'get', params },
        options
    )
}

/**
 * @summary Get company offboarding status
 */
export const getCompanyOffboardingStatus = (
    globalId: string,
    params: GetCompanyOffboardingStatusParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CompanyOffboardingStatusResponse>(
        { url: `/v2/offboarding-status/companies/${globalId}`, method: 'get', params },
        options
    )
}

/**
 * @summary Create or update company offboarding status
 */
export const upsertCompanyOffboardingStatus = (
    globalId: string,
    companyOffboardingStatusRequest: CompanyOffboardingStatusRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CompanyOffboardingStatusResponse>(
        {
            url: `/v2/offboarding-status/companies/${globalId}`,
            method: 'put',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: companyOffboardingStatusRequest,
        },
        options
    )
}

/**
 * @summary Delete company offboarding status
 */
export const deleteCompanyOffboardingStatus = (
    id: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>({ url: `/v2/offboarding-status/${id}`, method: 'delete' }, options)
}

/**
 * @summary Creates a new partner based on the user input
 */
export const createPartner = (
    createPartnerRequest: CreatePartnerRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DataResponsePartnerResponse>(
        {
            url: `/v2/partners`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: createPartnerRequest,
        },
        options
    )
}

/**
 * @summary Updates the partner information. Reruns screening if trading or legal name is updated.
 */
export const updatePartner = (
    partnerId: string,
    updatePartnerRequest: UpdatePartnerRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DataResponsePartnerResponse>(
        {
            url: `/v2/partners/${partnerId}`,
            method: 'put',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: updatePartnerRequest,
        },
        options
    )
}

/**
 * @deprecated
 * @summary Returns all verifications for the current user
 */
export const getAuthenticatedPersonVerifications = (
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<PersonVerificationResponse[]>(
        { url: `/v2/person-verification/authenticated`, method: 'get' },
        options
    )
}

/**
 * @deprecated
 * @summary Callback for when user returns from verification provider
 */
export const callback = (id: string, options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<unknown>(
        { url: `/v2/person-verification/callback/${id}`, method: 'get' },
        options
    )
}

/**
 * @deprecated
 */
export const getCompanyNeedsVerification = (
    globalId: string,
    createdAt: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CompanyNeedsVerificationResponse>(
        {
            url: `/v2/person-verification/company/${globalId}/needs-verification/${createdAt}`,
            method: 'get',
        },
        options
    )
}

/**
 * @deprecated
 */
export const getPersonNeedsVerification = (options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<PersonNeedsVerificationResponse>(
        { url: `/v2/person-verification/needs-verification`, method: 'get' },
        options
    )
}

/**
 * @deprecated
 * @summary Returns true/false depending on if a user can retry their person verification
 */
export const getIsRetryable = (options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<boolean>(
        { url: `/v2/person-verification/retryable`, method: 'get' },
        options
    )
}

/**
 * @deprecated
 */
export const startPersonVerification = (
    personVerificationRequest: PersonVerificationRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<PersonVerificationResponse>(
        {
            url: `/v2/person-verification/start`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: personVerificationRequest,
        },
        options
    )
}

/**
 * @deprecated
 * @summary Returns general information about the authenticated person's person verification status
 */
export const getPersonVerificationStatus = (options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<PersonVerificationStatusResponse>(
        { url: `/v2/person-verification/status`, method: 'get' },
        options
    )
}

/**
 * @deprecated
 */
export const getUpdatedPersonVerification = (
    id: string,
    getUpdatedPersonVerificationRequest: GetUpdatedPersonVerificationRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<PersonVerificationResponse>(
        {
            url: `/v2/person-verification/updated/${id}`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: getUpdatedPersonVerificationRequest,
        },
        options
    )
}

/**
 * @deprecated
 */
export const getPersonVerification = (
    id: string,
    params: GetPersonVerificationParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<PersonVerificationResponse>(
        { url: `/v2/person-verification/${id}`, method: 'get', params },
        options
    )
}

/**
 * @deprecated
 */
export const update = (
    id: string,
    falsePositiveRequest: FalsePositiveRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<PersonVerificationResponse>(
        {
            url: `/v2/person-verification/${id}`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: falsePositiveRequest,
        },
        options
    )
}

/**
 * @deprecated
 */
export const finalStateHandler = (id: string, options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<unknown>(
        { url: `/v2/person-verification/${id}/completion-handler`, method: 'post' },
        options
    )
}

/**
 * @deprecated
 */
export const state = (
    id: string,
    updateResultRequest: UpdateResultRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<PersonVerificationResponse>(
        {
            url: `/v2/person-verification/${id}/state`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: updateResultRequest,
        },
        options
    )
}

/**
 * @deprecated
 */
export const sync = (id: string, options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<PersonVerificationResponse>(
        { url: `/v2/person-verification/${id}/sync`, method: 'post' },
        options
    )
}

/**
 * @summary Lists the managed people filtered by search parameters
 */
export const getPersons1 = (
    params: GetPersons1Params,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CursorPaginationDataResponsePersonSearchResponse>(
        { url: `/v2/persons`, method: 'get', params },
        options
    )
}

/**
 * @summary Copy one person's data into another person
 */
export const copyPerson = (
    copyPersonRequest: CopyPersonRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<void>(
        {
            url: `/v2/persons/copy`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: copyPersonRequest,
        },
        options
    )
}

/**
 * @summary Get all KYC check attempts for a given person of a given type
 */
export const getAttempts = (
    personId: string,
    params: GetAttemptsParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<KycCheckAttemptsResponse>(
        { url: `/v2/persons/${personId}/kyc-check-attempts`, method: 'get', params },
        options
    )
}

/**
 * @summary Get the maximum number of KYC check attempts for a given person of a given type
 */
export const getMaximumAttempts = (
    personId: string,
    params: GetMaximumAttemptsParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<KycCheckAttemptsResponse>(
        { url: `/v2/persons/${personId}/maximum-kyc-check-attempts`, method: 'get', params },
        options
    )
}

/**
 * @summary Set the maximum number of KYC check attempts for a given person of a given type
 */
export const setMaximumAttempts = (
    personId: string,
    setMaximumAttemptsBody: number,
    params: SetMaximumAttemptsParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<KycCheckAttemptsResponse>(
        {
            url: `/v2/persons/${personId}/maximum-kyc-check-attempts`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: setMaximumAttemptsBody,
            params,
        },
        options
    )
}

/**
 * @summary Get Proof of Funds by status
 */
export const getProofOfFundsByStatus = (
    params: GetProofOfFundsByStatusParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<ProofOfFundsTotalResponse>(
        { url: `/v2/proof-of-funds`, method: 'get', params },
        options
    )
}

/**
 * @summary Get a list of all blocked sources
 */
export const getAllBlockedSources = (
    params: GetAllBlockedSourcesParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<BlockedSourceResponse[]>(
        { url: `/v2/proof-of-funds/blocked`, method: 'get', params },
        options
    )
}

/**
 * @summary Add a new blocked source to the set
 */
export const createBlockedSource = (
    blockedSourceUpdateRequest: BlockedSourceUpdateRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<BlockedSourceResponse>(
        {
            url: `/v2/proof-of-funds/blocked`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: blockedSourceUpdateRequest,
        },
        options
    )
}

/**
 * @summary Get a list of the active blocked sources
 */
export const getActiveBlockedSources = (
    params: GetActiveBlockedSourcesParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<BlockedSourceResponse[]>(
        { url: `/v2/proof-of-funds/blocked/active`, method: 'get', params },
        options
    )
}

/**
 * @summary Delete blocked source
 */
export const updateBlockedSource = (
    blockedSourceId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<BlockedSourceResponse>(
        { url: `/v2/proof-of-funds/blocked/${blockedSourceId}`, method: 'delete' },
        options
    )
}

/**
 * @summary Get a specific blocked source
 */
export const getBlockedSource = (
    blockedSourceId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<BlockedSourceResponse>(
        { url: `/v2/proof-of-funds/blocked/${blockedSourceId}`, method: 'get' },
        options
    )
}

/**
 * @summary Update blocked source
 */
export const updateBlockedSource1 = (
    blockedSourceId: string,
    blockedSourceUpdateRequest: BlockedSourceUpdateRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<BlockedSourceResponse>(
        {
            url: `/v2/proof-of-funds/blocked/${blockedSourceId}`,
            method: 'patch',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: blockedSourceUpdateRequest,
        },
        options
    )
}

/**
 * @summary Get all proof of funds for a company
 */
export const getProofOfFunds = (
    companyId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<ProofOfFundsResponse[]>(
        { url: `/v2/proof-of-funds/companies/${companyId}`, method: 'get' },
        options
    )
}

/**
 * @summary Create a proof of funds manually
 */
export const createProofOfFunds = (
    companyId: string,
    createProofOfFundsRequest: CreateProofOfFundsRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<ProofOfFundsResponse>(
        {
            url: `/v2/proof-of-funds/companies/${companyId}`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: createProofOfFundsRequest,
        },
        options
    )
}

/**
 * @summary Get proof of funds non-sensitive data for a company matching request filters.
 */
export const getBasicProofOfFunds = (
    companyId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<ProofOfFundsBasicResponse[]>(
        { url: `/v2/proof-of-funds/companies/${companyId}/basic`, method: 'get' },
        options
    )
}

/**
 * @summary Get the loading limit for a company
 */
export const getCompanyLoadingLimit = (
    companyId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CompanyFundsLimitsResponse>(
        { url: `/v2/proof-of-funds/companies/${companyId}/company-limits`, method: 'get' },
        options
    )
}

/**
 * @summary Get approved proof of funds for a company matching iban supplied.
 */
export const getApprovedProofOfFundsForCompany = (
    companyId: string,
    proofOfFundsFilterRequest: ProofOfFundsFilterRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<ProofOfFundsResponse[]>(
        {
            url: `/v2/proof-of-funds/companies/${companyId}/filter`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: proofOfFundsFilterRequest,
        },
        options
    )
}

/**
 * Sender information is going to be processed and validated if present.
 If sender information contains any valid combination of payment details e.g. 
 iban/bic GB sort code + account number etc. - it would a be a part of BankAccountInfo. 
 Otherwise BankAccountInfo will be empty.
 * @summary Get latest approved proof of funds for a company
 */
export const getLatestApproved = (
    companyId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<ProofOfFundsResponse>(
        { url: `/v2/proof-of-funds/companies/${companyId}/latest-approved`, method: 'get' },
        options
    )
}

/**
 * @deprecated
 * @summary Get the funds loading limit for a company from a given source
 */
export const getLoadLimitForSource = (
    companyId: string,
    params: GetLoadLimitForSourceParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<FundsLimitsResponse>(
        { url: `/v2/proof-of-funds/companies/${companyId}/limits`, method: 'get', params },
        options
    )
}

/**
 * @summary Validate whether funds are approved for wallet loads for a company
 */
export const determineSourceApproval = (
    companyId: string,
    params: DetermineSourceApprovalParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<SourceApprovalDecisionResponse>(
        { url: `/v2/proof-of-funds/companies/${companyId}/source-approval`, method: 'get', params },
        options
    )
}

/**
 * @summary Get the configured funds loading limit for a company
 */
export const getStaticLimits = (
    companyId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<StaticFundsLimitResponse>(
        { url: `/v2/proof-of-funds/companies/${companyId}/static-limits`, method: 'get' },
        options
    )
}

/**
 * @summary Get latest proof of funds per source for a company
 */
export const getLatestPerSource = (
    globalId: string,
    status:
        | 'PENDING'
        | 'INVESTIGATING'
        | 'PRE_APPROVED'
        | 'PRE_APPROVED_LEGAL_NAME_CHECK'
        | 'APPROVED'
        | 'REJECTED'
        | 'REJECTED_REFUND',
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<ProofOfFundsResponse[]>(
        {
            url: `/v2/proof-of-funds/companies/${globalId}/latest-per-source/${status}`,
            method: 'get',
        },
        options
    )
}

/**
 * @summary Update a proof of funds
 */
export const updateProofOfFunds = (
    id: string,
    proofOfFundsRequest: ProofOfFundsRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<ProofOfFundsResponse>(
        {
            url: `/v2/proof-of-funds/${id}`,
            method: 'patch',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: proofOfFundsRequest,
        },
        options
    )
}

/**
 * @summary reject and refund a proof of funds
 */
export const rejectRefundProofOfFunds = (
    id: string,
    proofOfFundsRejectRefundRequest: ProofOfFundsRejectRefundRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<ProofOfFundsResponse>(
        {
            url: `/v2/proof-of-funds/${id}/reject-refund`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: proofOfFundsRejectRefundRequest,
        },
        options
    )
}

/**
 * @summary Persist a partner based on the registry data.
 */
export const persistPartner = (
    registryId: string,
    addRegistryPartnerRequest: AddRegistryPartnerRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DataResponsePartnerResponse>(
        {
            url: `/v2/registry/${registryId}/partners`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: addRegistryPartnerRequest,
        },
        options
    )
}

/**
 * @summary Get the revenue information for a company
 */
export const getCompanyRevenue = (
    globalId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CompanyRevenueResponse>(
        { url: `/v2/revenue/companies/${globalId}`, method: 'get' },
        options
    )
}

/**
 * @summary Create or Update the revenue information for a company
 */
export const upsertCompanyRevenue = (
    globalId: string,
    companyRevenueRequest: CompanyRevenueRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CompanyRevenueResponse>(
        {
            url: `/v2/revenue/companies/${globalId}`,
            method: 'put',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: companyRevenueRequest,
        },
        options
    )
}

/**
 * @deprecated
 * @summary Get a list of amount ranges
 */
export const getRevenueRanges = (
    params: GetRevenueRangesParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<AmountRange[]>({ url: `/v2/revenue/ranges`, method: 'get', params }, options)
}

/**
 * @deprecated
 * @summary Get a list of source of revenue
 */
export const getRevenueSources = (options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<string[]>({ url: `/v2/revenue/sources`, method: 'get' }, options)
}

/**
 * @deprecated
 */
export const performCompanyReview1 = (
    companyId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CompanyResponseV2>(
        { url: `/v2/reviews/companies/${companyId}`, method: 'post' },
        options
    )
}

export const getAllDocuments1 = (
    companyId: string,
    params: GetAllDocuments1Params,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        { url: `/v2/reviews/companies/${companyId}/documents`, method: 'get', params },
        options
    )
}

export const refreshCompanyStructure = (
    companyId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CompanyResponseV2>(
        { url: `/v2/reviews/companies/${companyId}/structure`, method: 'post' },
        options
    )
}

/**
 * @summary Retrieves all missing required information for a company to be reviewed, including for its people in scope
 */
export const getMissingKycInformation = (
    globalId: string,
    params: GetMissingKycInformationParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<KycInformation[]>(
        { url: `/v2/reviews/companies/${globalId}/missing-information`, method: 'get', params },
        options
    )
}

/**
 * @summary Sets the visibility state of the Modelo 200 document
 */
export const updateModeloVisibility = (
    globalId: string,
    modeloVisiblityRequest: ModeloVisiblityRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<boolean>(
        {
            url: `/v2/reviews/companies/${globalId}/modelo-200`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: modeloVisiblityRequest,
        },
        options
    )
}

export const getPersonsToReview = (
    globalId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<string[]>(
        { url: `/v2/reviews/companies/${globalId}/persons-to-review`, method: 'get' },
        options
    )
}

/**
 * @summary Retrieves all required information for a company to be reviewed, including for its people in scope
 */
export const getRequiredKycInformation = (
    globalId: string,
    params: GetRequiredKycInformationParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<KycInformation[]>(
        { url: `/v2/reviews/companies/${globalId}/required-information`, method: 'get', params },
        options
    )
}

export const getReviewStatus = (globalId: string, options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<CompanyReviewStatusResponse>(
        { url: `/v2/reviews/companies/${globalId}/status`, method: 'get' },
        options
    )
}

/**
 * @summary Retrieves all submitted verifications for a company
 */
export const getSubmittedVerifications = (
    globalId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<SubmittedVerificationDataResponse>(
        { url: `/v2/reviews/companies/${globalId}/submitted-verifications`, method: 'get' },
        options
    )
}

/**
 * @summary Retrieves all missing required information for a partner to be reviewed.
 */
export const getMissingKypInformation = (
    partnerId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DataResponseSetKypInformation>(
        { url: `/v2/reviews/partners/${partnerId}/missing-information`, method: 'get' },
        options
    )
}

/**
 * @summary Sends an email to the admins of a company to input kyc information
 */
export const sendRequestKycInformation = (
    id: string,
    params: SendRequestKycInformationParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        { url: `/v2/rfi/companies/${id}/send-request-kyc-information`, method: 'post', params },
        options
    )
}

/**
 * @summary Sends an email to the admins of a company to remind them to input kyc information
 */
export const sendRequestKycInformationWarning = (
    id: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        { url: `/v2/rfi/companies/${id}/send-request-kyc-information-warning`, method: 'post' },
        options
    )
}

/**
 * @summary Get all the requests for information for the given person optionally filtered by status
 */
export const getRequestsForInformation = (
    personId: string,
    params: GetRequestsForInformationParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<RequestForInformationResponse[]>(
        { url: `/v2/rfi/persons/${personId}`, method: 'get', params },
        options
    )
}

/**
 * @summary Create a request for information and token for a person
 */
export const createRequestForInformation = (
    personId: string,
    requestForInformationRequest: RequestForInformationRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<RequestForInformationResponse>(
        {
            url: `/v2/rfi/persons/${personId}`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: requestForInformationRequest,
        },
        options
    )
}

/**
 * @summary Get the submitted KYC information for a person
 */
export const getPersonSubmittedKycInformation = (
    personId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<SubmittedKycInformationResponse[]>(
        { url: `/v2/rfi/persons/${personId}/submitted-kyc-information`, method: 'get' },
        options
    )
}

/**
 * @summary Get the request for information of a person
 */
export const getRequestForInformation = (
    id: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<RequestForInformationPersonResponse>(
        { url: `/v2/rfi/${id}`, method: 'get' },
        options
    )
}

/**
 * @summary Get the missing KYC information for a person
 */
export const getRFIMissingKycInformation = (
    id: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<KycInformation[]>(
        { url: `/v2/rfi/${id}/missing-kyc-information`, method: 'get' },
        options
    )
}

/**
 * @summary Updates a person using a request for information token
 */
export const updatePerson1 = (
    id: string,
    personId: string,
    personUpdateRequest: PersonUpdateRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        {
            url: `/v2/rfi/${id}/persons/${personId}`,
            method: 'patch',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: personUpdateRequest,
        },
        options
    )
}

/**
 * @summary Cancel the request for information of a person
 */
export const cancelRequestForInformation = (
    id: string,
    personId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<RequestForInformationCancelResponse>(
        { url: `/v2/rfi/${id}/persons/${personId}/cancel`, method: 'post' },
        options
    )
}

/**
 * @summary Upload a person's document file
 */
export const rfiUploadPersonDocumentFile = (
    id: string,
    personId: string,
    documentId: string,
    rfiUploadPersonDocumentFileBody: RfiUploadPersonDocumentFileBody,
    options: SecondParameter<typeof moonRequest>
) => {
    const formData = new FormData()
    formData.append('file', JSON.stringify(rfiUploadPersonDocumentFileBody.file))

    return moonRequest<unknown>(
        {
            url: `/v2/rfi/${id}/persons/${personId}/documents/${documentId}/files`,
            method: 'post',
            headers: { 'Content-Type': 'multipart/form-data' },
            data: formData,
        },
        options
    )
}

/**
 * @summary Delete a person's document file
 */
export const deleteRFIPersonDocumentFile = (
    id: string,
    personId: string,
    documentId: string,
    fileId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        {
            url: `/v2/rfi/${id}/persons/${personId}/documents/${documentId}/files/${fileId}`,
            method: 'delete',
        },
        options
    )
}

/**
 * @summary Get the required KYC information for a person
 */
export const getRFIRequiredKycInformation = (
    id: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<KycInformation[]>(
        { url: `/v2/rfi/${id}/required-kyc-information`, method: 'get' },
        options
    )
}

/**
 * @summary Perform review on a person
 */
export const performPersonReview = (id: string, options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<unknown>({ url: `/v2/rfi/${id}/review`, method: 'post' }, options)
}

/**
 * @summary Get the submitted KYC information for a person
 */
export const getRFISubmittedKycInformation = (
    id: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<SubmittedKycInformationResponse[]>(
        { url: `/v2/rfi/${id}/submitted-kyc-information`, method: 'get' },
        options
    )
}

export type SearchCompaniesResult = NonNullable<Awaited<ReturnType<typeof searchCompanies>>>
export type GetCompanyResult = NonNullable<Awaited<ReturnType<typeof getCompany>>>
export type GetAndPersistCompanyResult = NonNullable<
    Awaited<ReturnType<typeof getAndPersistCompany>>
>
export type SupportedCountriesResult = NonNullable<Awaited<ReturnType<typeof supportedCountries>>>
export type GetTypesResult = NonNullable<Awaited<ReturnType<typeof getTypes>>>
export type GetCompaniesResult = NonNullable<Awaited<ReturnType<typeof getCompanies>>>
export type CreateCompanyResult = NonNullable<Awaited<ReturnType<typeof createCompany>>>
export type DeleteCompanyResult = NonNullable<Awaited<ReturnType<typeof deleteCompany>>>
export type GetCompany1Result = NonNullable<Awaited<ReturnType<typeof getCompany1>>>
export type UpdateCompanyResult = NonNullable<Awaited<ReturnType<typeof updateCompany>>>
export type GetCompanyAdverseMediaResult = NonNullable<
    Awaited<ReturnType<typeof getCompanyAdverseMedia>>
>
export type GetDeletedCompanyResult = NonNullable<Awaited<ReturnType<typeof getDeletedCompany>>>
export type GetLastOffboardingStatusResult = NonNullable<
    Awaited<ReturnType<typeof getLastOffboardingStatus>>
>
export type AddDirectorResult = NonNullable<Awaited<ReturnType<typeof addDirector>>>
export type RemoveDirectorResult = NonNullable<Awaited<ReturnType<typeof removeDirector>>>
export type GetDocumentsResult = NonNullable<Awaited<ReturnType<typeof getDocuments>>>
export type CreateDocumentResult = NonNullable<Awaited<ReturnType<typeof createDocument>>>
export type DeleteDocumentResult = NonNullable<Awaited<ReturnType<typeof deleteDocument>>>
export type GetDocumentResult = NonNullable<Awaited<ReturnType<typeof getDocument>>>
export type UpdateDocumentResult = NonNullable<Awaited<ReturnType<typeof updateDocument>>>
export type ReplaceDocumentDetailsResult = NonNullable<
    Awaited<ReturnType<typeof replaceDocumentDetails>>
>
export type GetDocumentFilesResult = NonNullable<Awaited<ReturnType<typeof getDocumentFiles>>>
export type CreateDocumentFileResult = NonNullable<Awaited<ReturnType<typeof createDocumentFile>>>
export type DeleteDocumentFileResult = NonNullable<Awaited<ReturnType<typeof deleteDocumentFile>>>
export type GetDocumentFileResult = NonNullable<Awaited<ReturnType<typeof getDocumentFile>>>
export type UpdateDocumentFileResult = NonNullable<Awaited<ReturnType<typeof updateDocumentFile>>>
export type GetDocumentFileLinkResult = NonNullable<Awaited<ReturnType<typeof getDocumentFileLink>>>
export type GetDocumentFileMetadataResult = NonNullable<
    Awaited<ReturnType<typeof getDocumentFileMetadata>>
>
export type GetPreviousNamesResult = NonNullable<Awaited<ReturnType<typeof getPreviousNames>>>
export type GetCompanySanctionsResult = NonNullable<Awaited<ReturnType<typeof getCompanySanctions>>>
export type CreateCompanyScreeningResult = NonNullable<
    Awaited<ReturnType<typeof createCompanyScreening>>
>
export type AddShareholderResult = NonNullable<Awaited<ReturnType<typeof addShareholder>>>
export type RemoveShareholderResult = NonNullable<Awaited<ReturnType<typeof removeShareholder>>>
export type GetUbosResult = NonNullable<Awaited<ReturnType<typeof getUbos>>>
export type GetAdminsResult = NonNullable<Awaited<ReturnType<typeof getAdmins>>>
export type GetKycCheckpointsResult = NonNullable<Awaited<ReturnType<typeof getKycCheckpoints>>>
export type CreateKycCheckpointResult = NonNullable<Awaited<ReturnType<typeof createKycCheckpoint>>>
export type GetKycPersonsResult = NonNullable<Awaited<ReturnType<typeof getKycPersons>>>
export type RemoveKycPersonResult = NonNullable<Awaited<ReturnType<typeof removeKycPerson>>>
export type AddKycPersonResult = NonNullable<Awaited<ReturnType<typeof addKycPerson>>>
export type DeleteRiskAssessmentResult = NonNullable<
    Awaited<ReturnType<typeof deleteRiskAssessment>>
>
export type GetRiskAssessmentResult = NonNullable<Awaited<ReturnType<typeof getRiskAssessment>>>
export type UpdateRiskAssessmentResult = NonNullable<
    Awaited<ReturnType<typeof updateRiskAssessment>>
>
export type GetRiskChecksResult = NonNullable<Awaited<ReturnType<typeof getRiskChecks>>>
export type GetRiskCheckResult = NonNullable<Awaited<ReturnType<typeof getRiskCheck>>>
export type GetRiskAssessmentHistoryResult = NonNullable<
    Awaited<ReturnType<typeof getRiskAssessmentHistory>>
>
export type GetCompanyStakeholdersResult = NonNullable<
    Awaited<ReturnType<typeof getCompanyStakeholders>>
>
export type GetPeopleStakeholdersResult = NonNullable<
    Awaited<ReturnType<typeof getPeopleStakeholders>>
>
export type AllResult = NonNullable<Awaited<ReturnType<typeof all>>>
export type GetPersonsResult = NonNullable<Awaited<ReturnType<typeof getPersons>>>
export type CreatePersonResult = NonNullable<Awaited<ReturnType<typeof createPerson>>>
export type GetAuthenticatedPersonResult = NonNullable<
    Awaited<ReturnType<typeof getAuthenticatedPerson>>
>
export type PatchAuthenticatedPersonResult = NonNullable<
    Awaited<ReturnType<typeof patchAuthenticatedPerson>>
>
export type CreateAuthenticatedPersonResult = NonNullable<
    Awaited<ReturnType<typeof createAuthenticatedPerson>>
>
export type GetAuthenticatedPersonAvailableProvidersResult = NonNullable<
    Awaited<ReturnType<typeof getAuthenticatedPersonAvailableProviders>>
>
export type GetOrCreateAuthenticatedAdminResult = NonNullable<
    Awaited<ReturnType<typeof getOrCreateAuthenticatedAdmin>>
>
export type DeletePersonResult = NonNullable<Awaited<ReturnType<typeof deletePerson>>>
export type GetPersonResult = NonNullable<Awaited<ReturnType<typeof getPerson>>>
export type UpdatePersonResult = NonNullable<Awaited<ReturnType<typeof updatePerson>>>
export type ReplacePersonDetailsResult = NonNullable<
    Awaited<ReturnType<typeof replacePersonDetails>>
>
export type GetDocuments1Result = NonNullable<Awaited<ReturnType<typeof getDocuments1>>>
export type CreateDocument1Result = NonNullable<Awaited<ReturnType<typeof createDocument1>>>
export type DeleteDocument1Result = NonNullable<Awaited<ReturnType<typeof deleteDocument1>>>
export type GetDocument1Result = NonNullable<Awaited<ReturnType<typeof getDocument1>>>
export type UpdateDocument1Result = NonNullable<Awaited<ReturnType<typeof updateDocument1>>>
export type ReplaceDocumentDetails1Result = NonNullable<
    Awaited<ReturnType<typeof replaceDocumentDetails1>>
>
export type GetDocumentFiles1Result = NonNullable<Awaited<ReturnType<typeof getDocumentFiles1>>>
export type CreateDocumentFile1Result = NonNullable<Awaited<ReturnType<typeof createDocumentFile1>>>
export type DeleteDocumentFile1Result = NonNullable<Awaited<ReturnType<typeof deleteDocumentFile1>>>
export type GetDocumentFile1Result = NonNullable<Awaited<ReturnType<typeof getDocumentFile1>>>
export type UpdateDocumentFile1Result = NonNullable<Awaited<ReturnType<typeof updateDocumentFile1>>>
export type GetDocumentFileLink1Result = NonNullable<
    Awaited<ReturnType<typeof getDocumentFileLink1>>
>
export type GetDocumentFileMetadata1Result = NonNullable<
    Awaited<ReturnType<typeof getDocumentFileMetadata1>>
>
export type GetEkycChecksResult = NonNullable<Awaited<ReturnType<typeof getEkycChecks>>>
export type CreateEkycCheckResult = NonNullable<Awaited<ReturnType<typeof createEkycCheck>>>
export type GetEkycCheckResult = NonNullable<Awaited<ReturnType<typeof getEkycCheck>>>
export type UpdateEkycCheckResult = NonNullable<Awaited<ReturnType<typeof updateEkycCheck>>>
export type GetIdvChecksResult = NonNullable<Awaited<ReturnType<typeof getIdvChecks>>>
export type CreateIdvCheckResult = NonNullable<Awaited<ReturnType<typeof createIdvCheck>>>
export type GetIdvCheckResult = NonNullable<Awaited<ReturnType<typeof getIdvCheck>>>
export type UpdateIdvCheckResult = NonNullable<Awaited<ReturnType<typeof updateIdvCheck>>>
export type ResumeIdvCheckResult = NonNullable<Awaited<ReturnType<typeof resumeIdvCheck>>>
export type RemoveKycPerson1Result = NonNullable<Awaited<ReturnType<typeof removeKycPerson1>>>
export type AddKycPerson1Result = NonNullable<Awaited<ReturnType<typeof addKycPerson1>>>
export type GetPepChecksResult = NonNullable<Awaited<ReturnType<typeof getPepChecks>>>
export type CreatePepCheckResult = NonNullable<Awaited<ReturnType<typeof createPepCheck>>>
export type GetPepCheckResult = NonNullable<Awaited<ReturnType<typeof getPepCheck>>>
export type MarkPepCheckAsFalsePositiveResult = NonNullable<
    Awaited<ReturnType<typeof markPepCheckAsFalsePositive>>
>
export type CreateScreeningResult = NonNullable<Awaited<ReturnType<typeof createScreening>>>
export type GetSisChecksResult = NonNullable<Awaited<ReturnType<typeof getSisChecks>>>
export type CreateSisCheckResult = NonNullable<Awaited<ReturnType<typeof createSisCheck>>>
export type GetSisCheckResult = NonNullable<Awaited<ReturnType<typeof getSisCheck>>>
export type UpdateSisCheckResult = NonNullable<Awaited<ReturnType<typeof updateSisCheck>>>
export type GetAvailableProvidersResult = NonNullable<
    Awaited<ReturnType<typeof getAvailableProviders>>
>
export type PerformCompanyReviewResult = NonNullable<
    Awaited<ReturnType<typeof performCompanyReview>>
>
export type GetAllDocumentsResult = NonNullable<Awaited<ReturnType<typeof getAllDocuments>>>
export type DeleteUnknownLegalPersonResult = NonNullable<
    Awaited<ReturnType<typeof deleteUnknownLegalPerson>>
>
export type GetUnknownResult = NonNullable<Awaited<ReturnType<typeof getUnknown>>>
export type CleanupCountrySearchCacheResult = NonNullable<
    Awaited<ReturnType<typeof cleanupCountrySearchCache>>
>
export type FixSearchAddressResult = NonNullable<Awaited<ReturnType<typeof fixSearchAddress>>>
export type InvalidateProofOfFundsCacheResult = NonNullable<
    Awaited<ReturnType<typeof invalidateProofOfFundsCache>>
>
export type ProcessBankTransactionKafkaDLQResult = NonNullable<
    Awaited<ReturnType<typeof processBankTransactionKafkaDLQ>>
>
export type ProcessDeimosCompanyKafkaDLQResult = NonNullable<
    Awaited<ReturnType<typeof processDeimosCompanyKafkaDLQ>>
>
export type ProcessPersonVerificationKafkaDLQResult = NonNullable<
    Awaited<ReturnType<typeof processPersonVerificationKafkaDLQ>>
>
export type ProcessStyxCompanyKafkaDLQResult = NonNullable<
    Awaited<ReturnType<typeof processStyxCompanyKafkaDLQ>>
>
export type ProcessTelestoPaymentKafkaDLQResult = NonNullable<
    Awaited<ReturnType<typeof processTelestoPaymentKafkaDLQ>>
>
export type GetAmountRangesResult = NonNullable<Awaited<ReturnType<typeof getAmountRanges>>>
export type GetAverageAmountRangesResult = NonNullable<
    Awaited<ReturnType<typeof getAverageAmountRanges>>
>
export type GetAnswersResult = NonNullable<Awaited<ReturnType<typeof getAnswers>>>
export type UpsertAnswersResult = NonNullable<Awaited<ReturnType<typeof upsertAnswers>>>
export type GetAmlDocumentsResult = NonNullable<Awaited<ReturnType<typeof getAmlDocuments>>>
export type CreateAmlDocumentResult = NonNullable<Awaited<ReturnType<typeof createAmlDocument>>>
export type CreateAmlDocumentFileResult = NonNullable<
    Awaited<ReturnType<typeof createAmlDocumentFile>>
>
export type GetAmlDocumentFileResult = NonNullable<Awaited<ReturnType<typeof getAmlDocumentFile>>>
export type GetAmlNotesResult = NonNullable<Awaited<ReturnType<typeof getAmlNotes>>>
export type CreateAmlNoteResult = NonNullable<Awaited<ReturnType<typeof createAmlNote>>>
export type UpdateAmlNoteResult = NonNullable<Awaited<ReturnType<typeof updateAmlNote>>>
export type DeleteAmlDocumentFileResult = NonNullable<
    Awaited<ReturnType<typeof deleteAmlDocumentFile>>
>
export type CreateBankVerificationDetailsResult = NonNullable<
    Awaited<ReturnType<typeof createBankVerificationDetails>>
>
export type GetBankVerificationDetailsResult = NonNullable<
    Awaited<ReturnType<typeof getBankVerificationDetails>>
>
export type UpdateBankVerificationDetailsResult = NonNullable<
    Awaited<ReturnType<typeof updateBankVerificationDetails>>
>
export type GetAnswers1Result = NonNullable<Awaited<ReturnType<typeof getAnswers1>>>
export type UpsertAnswers1Result = NonNullable<Awaited<ReturnType<typeof upsertAnswers1>>>
export type GetDeletedCompanyByRegistryIdResult = NonNullable<
    Awaited<ReturnType<typeof getDeletedCompanyByRegistryId>>
>
export type GetRootCompaniesResult = NonNullable<Awaited<ReturnType<typeof getRootCompanies>>>
export type SearchCompanies1Result = NonNullable<Awaited<ReturnType<typeof searchCompanies1>>>
export type GetCompany2Result = NonNullable<Awaited<ReturnType<typeof getCompany2>>>
export type GetCompanyAdminVerificationRequestResult = NonNullable<
    Awaited<ReturnType<typeof getCompanyAdminVerificationRequest>>
>
export type CancelCompanyAdminVerificationRequestResult = NonNullable<
    Awaited<ReturnType<typeof cancelCompanyAdminVerificationRequest>>
>
export type RequestCompanyAdminVerificationRequestResult = NonNullable<
    Awaited<ReturnType<typeof requestCompanyAdminVerificationRequest>>
>
export type GetCompanyAdverseMedia1Result = NonNullable<
    Awaited<ReturnType<typeof getCompanyAdverseMedia1>>
>
export type GetBankVerificationDetailsByCompanyIdResult = NonNullable<
    Awaited<ReturnType<typeof getBankVerificationDetailsByCompanyId>>
>
export type GetCompanyBusinessExplanationResult = NonNullable<
    Awaited<ReturnType<typeof getCompanyBusinessExplanation>>
>
export type UpsertCompanyBusinessExplanationResult = NonNullable<
    Awaited<ReturnType<typeof upsertCompanyBusinessExplanation>>
>
export type GetCompanyBusinessOfferingResult = NonNullable<
    Awaited<ReturnType<typeof getCompanyBusinessOffering>>
>
export type InsertCompanyBusinessOfferingResult = NonNullable<
    Awaited<ReturnType<typeof insertCompanyBusinessOffering>>
>
export type DeleteCompanyBusinessOfferingResult = NonNullable<
    Awaited<ReturnType<typeof deleteCompanyBusinessOffering>>
>
export type GetDeletedCompany1Result = NonNullable<Awaited<ReturnType<typeof getDeletedCompany1>>>
export type GetClosedCompanyDocumentsResult = NonNullable<
    Awaited<ReturnType<typeof getClosedCompanyDocuments>>
>
export type GetCompanyShareholdersResult = NonNullable<
    Awaited<ReturnType<typeof getCompanyShareholders>>
>
export type RemoveCompanyComplianceOfficerResult = NonNullable<
    Awaited<ReturnType<typeof removeCompanyComplianceOfficer>>
>
export type AssignCompanyComplianceOfficerResult = NonNullable<
    Awaited<ReturnType<typeof assignCompanyComplianceOfficer>>
>
export type GetDeletedCompanyAdverseMediaResult = NonNullable<
    Awaited<ReturnType<typeof getDeletedCompanyAdverseMedia>>
>
export type GetDeletedCompanySanctionsResult = NonNullable<
    Awaited<ReturnType<typeof getDeletedCompanySanctions>>
>
export type GetDocuments2Result = NonNullable<Awaited<ReturnType<typeof getDocuments2>>>
export type CreateDocument2Result = NonNullable<Awaited<ReturnType<typeof createDocument2>>>
export type GetCompanyDocumentRequestsResult = NonNullable<
    Awaited<ReturnType<typeof getCompanyDocumentRequests>>
>
export type RequestCompanyDocumentResult = NonNullable<
    Awaited<ReturnType<typeof requestCompanyDocument>>
>
export type GetCompanyPendingDocumentRequestsResult = NonNullable<
    Awaited<ReturnType<typeof getCompanyPendingDocumentRequests>>
>
export type DeleteDocument2Result = NonNullable<Awaited<ReturnType<typeof deleteDocument2>>>
export type GetDocument2Result = NonNullable<Awaited<ReturnType<typeof getDocument2>>>
export type UpdateDocument2Result = NonNullable<Awaited<ReturnType<typeof updateDocument2>>>
export type ReplaceDocumentDetails2Result = NonNullable<
    Awaited<ReturnType<typeof replaceDocumentDetails2>>
>
export type GetDocumentFiles2Result = NonNullable<Awaited<ReturnType<typeof getDocumentFiles2>>>
export type CreateDocumentFile2Result = NonNullable<Awaited<ReturnType<typeof createDocumentFile2>>>
export type DeleteDocumentFile2Result = NonNullable<Awaited<ReturnType<typeof deleteDocumentFile2>>>
export type GetDocumentFile2Result = NonNullable<Awaited<ReturnType<typeof getDocumentFile2>>>
export type UpdateDocumentFile2Result = NonNullable<Awaited<ReturnType<typeof updateDocumentFile2>>>
export type PutUpdateDocumentFileResult = NonNullable<
    Awaited<ReturnType<typeof putUpdateDocumentFile>>
>
export type GetDocumentFileLink2Result = NonNullable<
    Awaited<ReturnType<typeof getDocumentFileLink2>>
>
export type GetDocumentFileMetadata2Result = NonNullable<
    Awaited<ReturnType<typeof getDocumentFileMetadata2>>
>
export type CompanyHasActiveRecurringMonitoringEntitiesResult = NonNullable<
    Awaited<ReturnType<typeof companyHasActiveRecurringMonitoringEntities>>
>
export type GetCompanyIndustryCodesResult = NonNullable<
    Awaited<ReturnType<typeof getCompanyIndustryCodes>>
>
export type UpsertCompanyIndustryCodesResult = NonNullable<
    Awaited<ReturnType<typeof upsertCompanyIndustryCodes>>
>
export type DeleteCompanyIndustryNaceCodeResult = NonNullable<
    Awaited<ReturnType<typeof deleteCompanyIndustryNaceCode>>
>
export type DeleteCompanyIndustryCodeResult = NonNullable<
    Awaited<ReturnType<typeof deleteCompanyIndustryCode>>
>
export type GetCompanyKycStatusResult = NonNullable<Awaited<ReturnType<typeof getCompanyKycStatus>>>
export type GetKycVerificationDetailsResult = NonNullable<
    Awaited<ReturnType<typeof getKycVerificationDetails>>
>
export type GetCompanyKycCheckpointsResult = NonNullable<
    Awaited<ReturnType<typeof getCompanyKycCheckpoints>>
>
export type GetManagementResult = NonNullable<Awaited<ReturnType<typeof getManagement>>>
export type GetCompanyManufacturingCountriesResult = NonNullable<
    Awaited<ReturnType<typeof getCompanyManufacturingCountries>>
>
export type SetCompanyManufacturingCountriesResult = NonNullable<
    Awaited<ReturnType<typeof setCompanyManufacturingCountries>>
>
export type GetCompanyNarrativesResult = NonNullable<
    Awaited<ReturnType<typeof getCompanyNarratives>>
>
export type CreateCompanyNarrativeResult = NonNullable<
    Awaited<ReturnType<typeof createCompanyNarrative>>
>
export type DeleteCompanyNarrativeByIdResult = NonNullable<
    Awaited<ReturnType<typeof deleteCompanyNarrativeById>>
>
export type GetCompanyNarrativeByIdResult = NonNullable<
    Awaited<ReturnType<typeof getCompanyNarrativeById>>
>
export type UpdateCompanyNarrativeResult = NonNullable<
    Awaited<ReturnType<typeof updateCompanyNarrative>>
>
export type GetPersonShareholdersResult = NonNullable<
    Awaited<ReturnType<typeof getPersonShareholders>>
>
export type GetProofOfFundsByCompanyIdResult = NonNullable<
    Awaited<ReturnType<typeof getProofOfFundsByCompanyId>>
>
export type GetRiskAssessment1Result = NonNullable<Awaited<ReturnType<typeof getRiskAssessment1>>>
export type UpdateRiskAssessment1Result = NonNullable<
    Awaited<ReturnType<typeof updateRiskAssessment1>>
>
export type CreateRiskAssessmentResult = NonNullable<
    Awaited<ReturnType<typeof createRiskAssessment>>
>
export type FetchRiskDataResult = NonNullable<Awaited<ReturnType<typeof fetchRiskData>>>
export type UpdateB4BRiskAssessmentResult = NonNullable<
    Awaited<ReturnType<typeof updateB4BRiskAssessment>>
>
export type GetRiskChecks1Result = NonNullable<Awaited<ReturnType<typeof getRiskChecks1>>>
export type DeleteRiskCheckResult = NonNullable<Awaited<ReturnType<typeof deleteRiskCheck>>>
export type UpdateRiskCheckResult = NonNullable<Awaited<ReturnType<typeof updateRiskCheck>>>
export type GetClosedCompanyRiskAssessmentResult = NonNullable<
    Awaited<ReturnType<typeof getClosedCompanyRiskAssessment>>
>
export type CreateEnhancedRiskAssessmentResult = NonNullable<
    Awaited<ReturnType<typeof createEnhancedRiskAssessment>>
>
export type ResetRiskAssessmentResult = NonNullable<Awaited<ReturnType<typeof resetRiskAssessment>>>
export type GetRiskScoreResult = NonNullable<Awaited<ReturnType<typeof getRiskScore>>>
export type GetCompanySanctions1Result = NonNullable<
    Awaited<ReturnType<typeof getCompanySanctions1>>
>
export type CreateCompanyScreening1Result = NonNullable<
    Awaited<ReturnType<typeof createCompanyScreening1>>
>
export type FetchShareholderAuditTrailResult = NonNullable<
    Awaited<ReturnType<typeof fetchShareholderAuditTrail>>
>
export type ScreenSignificantCompanyShareholdersResult = NonNullable<
    Awaited<ReturnType<typeof screenSignificantCompanyShareholders>>
>
export type StopRecurringMonitoringForAllRelatedEntitiesResult = NonNullable<
    Awaited<ReturnType<typeof stopRecurringMonitoringForAllRelatedEntities>>
>
export type GetCompanyVerificationFlowResult = NonNullable<
    Awaited<ReturnType<typeof getCompanyVerificationFlow>>
>
export type UpsertCompanyVerificationFlowResult = NonNullable<
    Awaited<ReturnType<typeof upsertCompanyVerificationFlow>>
>
export type UpdateCompany1Result = NonNullable<Awaited<ReturnType<typeof updateCompany1>>>
export type FetchShareholderStructureResult = NonNullable<
    Awaited<ReturnType<typeof fetchShareholderStructure>>
>
export type GetAllB4BCompaniesResult = NonNullable<Awaited<ReturnType<typeof getAllB4BCompanies>>>
export type GetAllB4BDocumentsResult = NonNullable<Awaited<ReturnType<typeof getAllB4BDocuments>>>
export type SetMultiEntityCompanyBillingResult = NonNullable<
    Awaited<ReturnType<typeof setMultiEntityCompanyBilling>>
>
export type GetIndustryCodesResult = NonNullable<Awaited<ReturnType<typeof getIndustryCodes>>>
export type GetPepChecks1Result = NonNullable<Awaited<ReturnType<typeof getPepChecks1>>>
export type CreateManuallyApprovePepCheckResult = NonNullable<
    Awaited<ReturnType<typeof createManuallyApprovePepCheck>>
>
export type ManuallyApprovePepCheckResult = NonNullable<
    Awaited<ReturnType<typeof manuallyApprovePepCheck>>
>
export type MarkKycCheckAsFalsePositiveResult = NonNullable<
    Awaited<ReturnType<typeof markKycCheckAsFalsePositive>>
>
export type GetKycInformationNameSourceResult = NonNullable<
    Awaited<ReturnType<typeof getKycInformationNameSource>>
>
export type GetCompanyKycUserResponsesResult = NonNullable<
    Awaited<ReturnType<typeof getCompanyKycUserResponses>>
>
export type UpsertCompanyKycUserResponsesResult = NonNullable<
    Awaited<ReturnType<typeof upsertCompanyKycUserResponses>>
>
export type GetLicensingAuthoritiesResult = NonNullable<
    Awaited<ReturnType<typeof getLicensingAuthorities>>
>
export type GetClosingCompanyOffboardingStatusesResult = NonNullable<
    Awaited<ReturnType<typeof getClosingCompanyOffboardingStatuses>>
>
export type GetCompanyOffboardingStatusResult = NonNullable<
    Awaited<ReturnType<typeof getCompanyOffboardingStatus>>
>
export type UpsertCompanyOffboardingStatusResult = NonNullable<
    Awaited<ReturnType<typeof upsertCompanyOffboardingStatus>>
>
export type DeleteCompanyOffboardingStatusResult = NonNullable<
    Awaited<ReturnType<typeof deleteCompanyOffboardingStatus>>
>
export type CreatePartnerResult = NonNullable<Awaited<ReturnType<typeof createPartner>>>
export type UpdatePartnerResult = NonNullable<Awaited<ReturnType<typeof updatePartner>>>
export type GetAuthenticatedPersonVerificationsResult = NonNullable<
    Awaited<ReturnType<typeof getAuthenticatedPersonVerifications>>
>
export type CallbackResult = NonNullable<Awaited<ReturnType<typeof callback>>>
export type GetCompanyNeedsVerificationResult = NonNullable<
    Awaited<ReturnType<typeof getCompanyNeedsVerification>>
>
export type GetPersonNeedsVerificationResult = NonNullable<
    Awaited<ReturnType<typeof getPersonNeedsVerification>>
>
export type GetIsRetryableResult = NonNullable<Awaited<ReturnType<typeof getIsRetryable>>>
export type StartPersonVerificationResult = NonNullable<
    Awaited<ReturnType<typeof startPersonVerification>>
>
export type GetPersonVerificationStatusResult = NonNullable<
    Awaited<ReturnType<typeof getPersonVerificationStatus>>
>
export type GetUpdatedPersonVerificationResult = NonNullable<
    Awaited<ReturnType<typeof getUpdatedPersonVerification>>
>
export type GetPersonVerificationResult = NonNullable<
    Awaited<ReturnType<typeof getPersonVerification>>
>
export type UpdateResult = NonNullable<Awaited<ReturnType<typeof update>>>
export type FinalStateHandlerResult = NonNullable<Awaited<ReturnType<typeof finalStateHandler>>>
export type StateResult = NonNullable<Awaited<ReturnType<typeof state>>>
export type SyncResult = NonNullable<Awaited<ReturnType<typeof sync>>>
export type GetPersons1Result = NonNullable<Awaited<ReturnType<typeof getPersons1>>>
export type CopyPersonResult = NonNullable<Awaited<ReturnType<typeof copyPerson>>>
export type GetAttemptsResult = NonNullable<Awaited<ReturnType<typeof getAttempts>>>
export type GetMaximumAttemptsResult = NonNullable<Awaited<ReturnType<typeof getMaximumAttempts>>>
export type SetMaximumAttemptsResult = NonNullable<Awaited<ReturnType<typeof setMaximumAttempts>>>
export type GetProofOfFundsByStatusResult = NonNullable<
    Awaited<ReturnType<typeof getProofOfFundsByStatus>>
>
export type GetAllBlockedSourcesResult = NonNullable<
    Awaited<ReturnType<typeof getAllBlockedSources>>
>
export type CreateBlockedSourceResult = NonNullable<Awaited<ReturnType<typeof createBlockedSource>>>
export type GetActiveBlockedSourcesResult = NonNullable<
    Awaited<ReturnType<typeof getActiveBlockedSources>>
>
export type UpdateBlockedSourceResult = NonNullable<Awaited<ReturnType<typeof updateBlockedSource>>>
export type GetBlockedSourceResult = NonNullable<Awaited<ReturnType<typeof getBlockedSource>>>
export type UpdateBlockedSource1Result = NonNullable<
    Awaited<ReturnType<typeof updateBlockedSource1>>
>
export type GetProofOfFundsResult = NonNullable<Awaited<ReturnType<typeof getProofOfFunds>>>
export type CreateProofOfFundsResult = NonNullable<Awaited<ReturnType<typeof createProofOfFunds>>>
export type GetBasicProofOfFundsResult = NonNullable<
    Awaited<ReturnType<typeof getBasicProofOfFunds>>
>
export type GetCompanyLoadingLimitResult = NonNullable<
    Awaited<ReturnType<typeof getCompanyLoadingLimit>>
>
export type GetApprovedProofOfFundsForCompanyResult = NonNullable<
    Awaited<ReturnType<typeof getApprovedProofOfFundsForCompany>>
>
export type GetLatestApprovedResult = NonNullable<Awaited<ReturnType<typeof getLatestApproved>>>
export type GetLoadLimitForSourceResult = NonNullable<
    Awaited<ReturnType<typeof getLoadLimitForSource>>
>
export type DetermineSourceApprovalResult = NonNullable<
    Awaited<ReturnType<typeof determineSourceApproval>>
>
export type GetStaticLimitsResult = NonNullable<Awaited<ReturnType<typeof getStaticLimits>>>
export type GetLatestPerSourceResult = NonNullable<Awaited<ReturnType<typeof getLatestPerSource>>>
export type UpdateProofOfFundsResult = NonNullable<Awaited<ReturnType<typeof updateProofOfFunds>>>
export type RejectRefundProofOfFundsResult = NonNullable<
    Awaited<ReturnType<typeof rejectRefundProofOfFunds>>
>
export type PersistPartnerResult = NonNullable<Awaited<ReturnType<typeof persistPartner>>>
export type GetCompanyRevenueResult = NonNullable<Awaited<ReturnType<typeof getCompanyRevenue>>>
export type UpsertCompanyRevenueResult = NonNullable<
    Awaited<ReturnType<typeof upsertCompanyRevenue>>
>
export type GetRevenueRangesResult = NonNullable<Awaited<ReturnType<typeof getRevenueRanges>>>
export type GetRevenueSourcesResult = NonNullable<Awaited<ReturnType<typeof getRevenueSources>>>
export type PerformCompanyReview1Result = NonNullable<
    Awaited<ReturnType<typeof performCompanyReview1>>
>
export type GetAllDocuments1Result = NonNullable<Awaited<ReturnType<typeof getAllDocuments1>>>
export type RefreshCompanyStructureResult = NonNullable<
    Awaited<ReturnType<typeof refreshCompanyStructure>>
>
export type GetMissingKycInformationResult = NonNullable<
    Awaited<ReturnType<typeof getMissingKycInformation>>
>
export type UpdateModeloVisibilityResult = NonNullable<
    Awaited<ReturnType<typeof updateModeloVisibility>>
>
export type GetPersonsToReviewResult = NonNullable<Awaited<ReturnType<typeof getPersonsToReview>>>
export type GetRequiredKycInformationResult = NonNullable<
    Awaited<ReturnType<typeof getRequiredKycInformation>>
>
export type GetReviewStatusResult = NonNullable<Awaited<ReturnType<typeof getReviewStatus>>>
export type GetSubmittedVerificationsResult = NonNullable<
    Awaited<ReturnType<typeof getSubmittedVerifications>>
>
export type GetMissingKypInformationResult = NonNullable<
    Awaited<ReturnType<typeof getMissingKypInformation>>
>
export type SendRequestKycInformationResult = NonNullable<
    Awaited<ReturnType<typeof sendRequestKycInformation>>
>
export type SendRequestKycInformationWarningResult = NonNullable<
    Awaited<ReturnType<typeof sendRequestKycInformationWarning>>
>
export type GetRequestsForInformationResult = NonNullable<
    Awaited<ReturnType<typeof getRequestsForInformation>>
>
export type CreateRequestForInformationResult = NonNullable<
    Awaited<ReturnType<typeof createRequestForInformation>>
>
export type GetPersonSubmittedKycInformationResult = NonNullable<
    Awaited<ReturnType<typeof getPersonSubmittedKycInformation>>
>
export type GetRequestForInformationResult = NonNullable<
    Awaited<ReturnType<typeof getRequestForInformation>>
>
export type GetRFIMissingKycInformationResult = NonNullable<
    Awaited<ReturnType<typeof getRFIMissingKycInformation>>
>
export type UpdatePerson1Result = NonNullable<Awaited<ReturnType<typeof updatePerson1>>>
export type CancelRequestForInformationResult = NonNullable<
    Awaited<ReturnType<typeof cancelRequestForInformation>>
>
export type RfiUploadPersonDocumentFileResult = NonNullable<
    Awaited<ReturnType<typeof rfiUploadPersonDocumentFile>>
>
export type DeleteRFIPersonDocumentFileResult = NonNullable<
    Awaited<ReturnType<typeof deleteRFIPersonDocumentFile>>
>
export type GetRFIRequiredKycInformationResult = NonNullable<
    Awaited<ReturnType<typeof getRFIRequiredKycInformation>>
>
export type PerformPersonReviewResult = NonNullable<Awaited<ReturnType<typeof performPersonReview>>>
export type GetRFISubmittedKycInformationResult = NonNullable<
    Awaited<ReturnType<typeof getRFISubmittedKycInformation>>
>
