import type { CaseOutput } from 'bff/moons/generated/case-manager'
import type { CompanySpendStatusResponseVModelStatus } from 'bff/moons/generated/ersa'

// NOTE: This is not the same as CaseOutputState, as walletBlockedDue is not a valid state in CaseOutputState
export enum OddListTab {
    new = 'new',
    informationReceived = 'informationReceived',
    inProgress = 'inProgress',
    waitingForCustomer = 'waitingForCustomer',
    walletBlockedDue = 'walletBlockedDue',
    walletBlocked = 'walletBlocked',
    blocked = 'blocked',
    offboardingInitiated = 'offboardingInitiated',
    offboardingCompleted = 'offboardingCompleted',
    oddCompleted = 'oddCompleted',
}
export interface ExtendedCaseOutput extends CaseOutput {
    companySpendStatus?: CompanySpendStatusResponseVModelStatus
}
