import React, { FC, useState, ChangeEvent } from 'react'
import { useMatch } from 'react-router-dom'
import { Typography, Select, Tooltip, message, Input } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

import Header from 'components/header/header'
import { deKebabify } from 'utils/strings'

import { inputWidth } from 'theme/tokens'
import type { Company } from 'types/deimos-company'

const { Option } = Select
const { Text } = Typography

interface SearchHeaderSelectProps {
    companies?: Company[]
    onSearch: (query: string) => void
    onSearchChange: (companyId: string) => Promise<void>
}

const customPageTitles = {
    'kyc-processing-hub': 'KYC Processing Hub',
    odd: 'Ongoing Due Diligence',
}

const mapSideBarItemToTitle = (sideBarItem: string) => {
    if (Object.keys(customPageTitles).includes(sideBarItem)) {
        return customPageTitles[sideBarItem as keyof typeof customPageTitles]
    }
    return deKebabify(sideBarItem)
}

export const SearchHeaderSelect: FC<React.PropsWithChildren<SearchHeaderSelectProps>> = ({
    onSearch,
    companies,
    onSearchChange,
}) => {
    const [loading, setLoading] = useState(false)
    const [searchValue, setSearchValue] = useState('')
    const match = useMatch('/compliance/:selectedSidebarItem/*')
    const sideBarItem = match!.params.selectedSidebarItem || ''
    const title = mapSideBarItemToTitle(sideBarItem)

    const handleSearch = (value: string) => {
        setSearchValue(value)
        onSearch(value)
    }

    const handleSearchChange = async (companyId: string) => {
        try {
            setLoading(true)
            await onSearchChange(companyId)
        } catch (e) {
            message.error((e as Error).message)
        } finally {
            setLoading(false)
        }
    }

    const hasHugeName = (name: string) => name.length > 60

    return (
        <Header title={title}>
            <Select
                loading={loading}
                suffixIcon={loading && <LoadingOutlined />}
                value={searchValue || undefined}
                placeholder="Search for a company by name or ID"
                filterOption={false}
                defaultActiveFirstOption={false}
                showSearch
                onSearch={handleSearch}
                onChange={handleSearchChange}
                style={{ width: `${inputWidth.huge}` }}
            >
                {companies?.map(({ id, styxId, name }) => {
                    const companyName = `${name}${
                        !styxId ? ' – No Styx ID found, click to run KYC on this company' : ''
                    }`
                    return (
                        <Option key={id} value={id} style={{ overflow: 'visible' }}>
                            <Text type={!styxId ? 'secondary' : undefined}>
                                {hasHugeName(companyName) ? (
                                    <Tooltip overlay={companyName}>
                                        <span>{companyName}</span>
                                    </Tooltip>
                                ) : (
                                    companyName
                                )}
                            </Text>
                        </Option>
                    )
                })}
            </Select>
        </Header>
    )
}

export const SearchHeaderInput: FC<
    React.PropsWithChildren<{ onSearch: (query: string) => void }>
> = ({ onSearch }) => {
    const [searchValue, setSearchValue] = useState('')
    const match = useMatch('/compliance/:selectedSidebarItem/*')
    const title = deKebabify(match!.params.selectedSidebarItem || '')

    const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        setSearchValue(value)
        onSearch(value)
    }

    return (
        <Header title={title}>
            <Input
                autoFocus
                allowClear
                value={searchValue || undefined}
                placeholder="Search for a company by name or ID"
                onChange={handleSearch}
                style={{ width: `${inputWidth.huge}` }}
            />
        </Header>
    )
}
