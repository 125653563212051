import type { InvestigationOutput } from 'bff/moons/generated/investigation-runner'
import { formatDate, formatTimestamp } from 'packages/dates/dates'
import { Link } from 'react-router-dom'

export const convertToAntdTableData = (data: InvestigationOutput | null) => {
    const columns =
        data?.columns.map((col) => ({
            title: col.label,
            dataIndex: col.id,
            key: col.id,
            ...(col.type === 'STRING' && {
                render: (text: string) => <span>{text}</span>,
                sorter: { multiple: 1 },
            }),
            ...(col.type === 'NUMBER' && {
                render: (text: string) => <span>{text}</span>,
                sorter: { multiple: 1 },
            }),
            ...(col.type === 'DATE' && {
                render: (date: string) => <span>{formatDate(date)}</span>,
                sorter: { multiple: 1 },
            }),
            ...(col.type === 'DATETIME' && {
                render: (datetime: string) => <span>{formatTimestamp(datetime)}</span>,
                sorter: { multiple: 1 },
            }),
            ...(col.type === 'BOOLEAN' && {
                render: (text: boolean) => <span>{text ? 'True' : 'False'}</span>,
                sorter: { multiple: 1 },
            }),
            ...(col.type === 'MONEY' && {
                render: (text: string) => <span>{text}</span>,
                sorter: { multiple: 1 },
            }),
            ...(col.type === 'COMPANY_ID' && {
                render: (companyId: string) => (
                    <Link to={`/customer-success/company-360/${companyId}`}>{companyId}</Link>
                ),
                sorter: { multiple: 1 },
            }),
            ...(col.type === 'EMPLOYEE_ID' && {
                render: (employeeId: string) => (
                    <Link to={`/customer-success/users/${employeeId}`}>{employeeId}</Link>
                ),
                sorter: { multiple: 1 },
            }),
        })) || []

    const rows =
        data?.rows.map((row) => {
            const rowObject: any = {}
            data.columns.forEach((col, index) => {
                rowObject[col.id] = row[index]
            })
            return rowObject
        }) || []

    return {
        columns,
        dataSource: rows,
    }
}
