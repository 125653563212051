import { Button, message, Typography, Checkbox, Form, Input, Tag, Space } from 'antd'
import React, { FC, useState } from 'react'
import type { BusinessExplanation } from '@pleo-io/deimos'
import Spinner from 'components/content-spinner'
import { inputWidth } from 'theme/tokens'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { bff } from 'pages/compliance/bff'
import type { BusinessExplanationSource } from 'pages/compliance/index.bff'
import { exhaustiveCheck } from 'utils/exhaustive-check'

const { TextArea } = Input

const BUSINESS_EXPLANATION_MAX_LENGTH = 400

type FormValues = BusinessExplanation

const BusinessExplanationContainer: FC<React.PropsWithChildren<unknown>> = () => {
    const { id: companyId } = useParams()
    const [form] = Form.useForm()
    const { data: businessExplanation, isFetching: loading } =
        bff.businessExplanation.getBusinessExplanation.useQuery({ companyId })
    const { mutateAsync: updateBusinessExplanation } =
        bff.businessExplanation.updateBusinessExplanation.useMutation()
    const [disableWhileSubmitting, setDisableWhileSubmitting] = useState(false)
    const initialValues: FormValues = {
        explanation: businessExplanation?.explanation ?? '',
        hidden: businessExplanation?.hidden ?? true,
    }

    const updateExplanation = async (values: FormValues) => {
        try {
            setDisableWhileSubmitting(true)
            await updateBusinessExplanation({
                ...values,
                companyId,
            })
            message.info('Successfully saved business explanation')
        } catch (e) {
            message.error(`Failed to save business explanation: ${(e as Error).message}`)
        } finally {
            setDisableWhileSubmitting(false)
        }
    }

    if (!businessExplanation && loading) {
        return <Spinner />
    }

    return (
        <BusinessExplanationForm>
            <Space align="baseline">
                <Title level={5}>Business Explanation</Title>
                {!!businessExplanation?.explanation && !!businessExplanation.explanationSource && (
                    <BusinessExplanationSourceTag source={businessExplanation.explanationSource} />
                )}
            </Space>
            <Form
                form={form}
                initialValues={initialValues}
                layout="vertical"
                onFinish={updateExplanation}
            >
                <Form.Item
                    name="explanation"
                    colon={false}
                    labelAlign="left"
                    style={{
                        flexDirection: 'column',
                    }}
                >
                    <TextArea
                        autoSize={{ minRows: 3 }}
                        showCount
                        maxLength={BUSINESS_EXPLANATION_MAX_LENGTH}
                    />
                </Form.Item>
                <Form.Item name="hidden" valuePropName="checked">
                    <Checkbox data-testid="business-explanation-checkbox">
                        Business explanation is hidden to users
                    </Checkbox>
                </Form.Item>
                <Button htmlType="submit" type="primary" disabled={disableWhileSubmitting}>
                    Save
                </Button>
            </Form>
        </BusinessExplanationForm>
    )
}

const BusinessExplanationSourceTag = ({ source }: { source: BusinessExplanationSource }) => {
    if (source === 'UNKNOWN') {
        return <Tag color="red">Unknown source</Tag>
    }

    if (source === 'USER_INPUT') {
        return <Tag color="gold">User input</Tag>
    }

    if (source === 'DUE_DILIGENCE_AGENT_INPUT') {
        return <Tag color="green">Due diligence agent input</Tag>
    }

    return exhaustiveCheck(source)
}

const BusinessExplanationForm = styled.div`
    width: ${inputWidth.huge};
`

const Title = styled(Typography.Title)`
    margin-top: 0;
`

export default BusinessExplanationContainer
