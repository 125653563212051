import { Skeleton, Space } from 'antd'
import { ActionBar } from './action-bar/action-bar'
import { StatusBar } from './status-bar'
import { bff } from '../bff-hooks'
import { useKycDetailContext } from '../context'

export const Header = () => {
    const { caseId } = useKycDetailContext()
    const { data, isLoading } = bff.header.getHeaderInformation.useQuery({ caseId })

    if (isLoading) {
        return <Skeleton.Node active style={{ width: '100%', height: '72px' }} />
    }

    return (
        <Space direction="vertical">
            <Space
                style={{
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    alignContent: 'center',
                    width: '100%',
                }}
            >
                <h1>{data?.title}</h1>
                <ActionBar />
            </Space>
            <StatusBar />
        </Space>
    )
}
