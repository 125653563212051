import type { ReactNode } from 'react'
import { Breadcrumb, Card } from 'antd'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { PageContentLayout } from 'components/layout-containers'

import { Filters } from 'features/kyc-processing-hub/filters/filters'
import { DataTable } from 'features/kyc-processing-hub/data-table/data-table'
import { removeUrlPaginationParams } from 'features/kyc-processing-hub/filters/utils'
import type { TabName } from 'features/kyc-processing-hub/data-table/constants'

export const KycProcessingHub = () => {
    return (
        <PageContentLayout>
            <Breadcrumbs />
            <Filters />
            <ContainerWithTabs>
                <DataTable />
            </ContainerWithTabs>
        </PageContentLayout>
    )
}

const Breadcrumbs = () => {
    return (
        <Breadcrumb>
            <Breadcrumb.Item>
                <Link to={'/compliance'}>Compliance</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
                <Link to={'/compliance/kyc-processing-hub/ready-for-fdd'}>KYC Processing Hub</Link>
            </Breadcrumb.Item>
        </Breadcrumb>
    )
}

const TABS: { key: TabName; tab: string }[] = [
    { key: 'ready-for-fdd', tab: 'Ready for FDD' },
    { key: 'in-progress', tab: 'In Progress' },
    { key: 'waiting-for-customer', tab: 'Waiting for Customer' },
    { key: 'information-received', tab: 'Information Received' },
    { key: 'fdd-approved', tab: 'FDD Approved' },
    { key: 'fdd-rejected', tab: 'FDD Rejected' },
    { key: 'sign-up-in-progress', tab: 'Sign Up in Progress' },
]

const ContainerWithTabs = ({ children }: { children: ReactNode }) => {
    const navigate = useNavigate()
    const { pathTab } = useParams()

    const navigateToTab = (tabKey: string) => {
        navigate({
            pathname: `/compliance/kyc-processing-hub/${tabKey}`,
            search: removeUrlPaginationParams(window.location.search),
        })
    }

    return (
        <Card
            title="KYC Processing Hub"
            bordered={false}
            tabList={TABS}
            activeTabKey={pathTab}
            onTabChange={navigateToTab}
            styles={{ body: { padding: 0, minHeight: 485 } }}
        >
            {children}
        </Card>
    )
}
