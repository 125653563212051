import { createContext, ReactNode, useContext, useState } from 'react'
import { useParams } from 'react-router-dom'

interface ModalMetadata {
    isOpen: boolean
    open: () => void
    close: () => void
}

interface KycDetailContext {
    caseId: string
    finaliseModal: ModalMetadata
    historyModal: ModalMetadata
}

const KycDetailContext = createContext<KycDetailContext | undefined>(undefined)

export const useKycDetailContext = () => {
    const context = useContext(KycDetailContext)
    if (!context) {
        throw new Error('useKycDetailContext must be used within a KycDetailContextProvider')
    }
    return context
}

export const KycDetailProvider = ({ children }: { children: ReactNode }) => {
    const { caseId } = useParams()
    const [isFinaliseModalOpen, setIsFinaliseModalOpen] = useState(false)
    const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false)

    const finaliseModal = {
        isOpen: isFinaliseModalOpen,
        open: () => setIsFinaliseModalOpen(true),
        close: () => setIsFinaliseModalOpen(false),
    }

    const historyModal = {
        isOpen: isHistoryModalOpen,
        open: () => setIsHistoryModalOpen(true),
        close: () => setIsHistoryModalOpen(false),
    }

    return (
        <KycDetailContext.Provider
            value={{
                caseId,
                finaliseModal,
                historyModal,
            }}
        >
            {children}
        </KycDetailContext.Provider>
    )
}
