import type { FC } from 'react'
import styled from 'styled-components'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { Input, Form, Select } from 'formik-antd'
import { Button } from 'antd'

const { Option } = Select

const validationSchema = Yup.object()
    .shape({
        accountingEntryId: Yup.string().required('Required'),
        note: Yup.string(),
        investigationStatus: Yup.string().required('Required'),
    })
    .required()

type FormValues = Yup.InferType<typeof validationSchema>

interface Props {
    statuses: [string, string][]
    onSubmit: (values: FormValues) => void
}

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
}

const ManuallyFlagAccountingEntryForm: FC<React.PropsWithChildren<Props>> = ({
    statuses,
    onSubmit,
}) => (
    <Formik
        onSubmit={onSubmit}
        initialValues={{
            accountingEntryId: '',
            note: '',
            investigationStatus: 'FRAUD',
        }}
        validationSchema={validationSchema}
    >
        {({ isValid }) => (
            <Form {...layout}>
                <Form.Item name="accountingEntryId" label="Accounting Entry ID">
                    <Input.TextArea
                        data-testid="accountingEntryId-textarea"
                        name="accountingEntryId"
                        placeholder="Accounting Entry ID"
                    />
                </Form.Item>
                <Form.Item name="investigationStatus" label="Destination">
                    <Select
                        name="investigationStatus"
                        placeholder="Select flag destination"
                        data-testid="select"
                    >
                        {statuses.map((status) => (
                            <Option key={status[0]} value={status[0]}>
                                {status[1]}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item name="note" label="Notes">
                    <Input.TextArea name="note" placeholder="Notes" />
                </Form.Item>
                <RowReverse>
                    <Button
                        disabled={!isValid}
                        type="primary"
                        htmlType="submit"
                        data-testid="submit"
                    >
                        Save
                    </Button>
                </RowReverse>
            </Form>
        )}
    </Formik>
)

const RowReverse = styled.div`
    display: flex;
    flex-direction: row-reverse;
`

export default ManuallyFlagAccountingEntryForm
