import React, { useEffect, FC, useMemo } from 'react'
import dayjs from 'packages/dayjs'
import type { ColumnProps } from 'antd/lib/table'
import {
    Card,
    Table,
    Input,
    Typography,
    Tabs,
    Tag,
    Space,
    Modal,
    Button,
    Tooltip,
    notification,
} from 'antd'
import {
    useLocation,
    Link,
    useNavigate,
    Routes,
    Route,
    Outlet,
    Navigate,
    useSearchParams,
} from 'react-router-dom'
import CursorPaginationControls from 'components/cursor-pagination-controls'
import { ContentContainer, PageContentLayout } from 'components/layout-containers'
import { inputWidth } from 'theme/tokens'
import { withTeamErrorHandler } from 'providers/error-handler'
import { Team } from 'utils/teams'
import {
    CheckCircleOutlined,
    CloseCircleOutlined,
    ExclamationCircleOutlined,
} from '@ant-design/icons'
import {
    OrganizationRequest,
    usePendingOrganizationRequestActions,
    useProcessedOrganizationRequestActions,
} from 'services/oberon/organization-request'
import { RelatedByOrganization } from '../company/shared/related-by-organization/relatated-by-organization'
import { useUser } from 'providers/user-context'

const { Text } = Typography

const useFilteredRequestData = ({
    data,
    searchTerm,
}: {
    data?: OrganizationRequest[]
    searchTerm?: string
}) => {
    return useMemo(() => {
        return searchTerm && data?.length
            ? data?.filter((requestAction) =>
                  [
                      requestAction?.organizationName?.toLowerCase() ?? '',
                      requestAction?.id ?? '',
                      requestAction?.organizationId ?? '',
                      requestAction?.companyId ?? '',
                      requestAction?.registryId ?? '',
                  ].some((requestField) => requestField.includes(searchTerm.toLowerCase()))
              )
            : data
    }, [data, searchTerm])
}

const getColorFromStatus = (status: 'PENDING' | 'COMPLETED' | 'DECLINED'): string => {
    switch (status) {
        case 'PENDING':
            return 'processing'
        case 'COMPLETED':
            return 'success'
        case 'DECLINED':
            return 'error'
        default:
            return 'warning'
    }
}

const getColorFromType = (type: 'NEW' | 'ADD' | 'REMOVE'): string => {
    switch (type) {
        case 'NEW':
            return 'warning'
        case 'ADD':
            return 'processing'
        case 'REMOVE':
            return 'error'
        default:
            return 'warning'
    }
}

export const PendingMultiEntityRequests: FC<React.PropsWithChildren<{ searchTerm?: string }>> = ({
    searchTerm,
}) => {
    const [fetchLimit, setFetchLimit] = React.useState<number | undefined>()
    const [api, contextHolder] = notification.useNotification()
    const user = useUser()
    const {
        data,
        isLoadingInitialData,
        pagination,
        fetchFirstPage,
        fetchNextPage,
        fetchPreviousPage,
        isLoadingMore,
        mutation,
        mutate,
    } = usePendingOrganizationRequestActions({ limit: fetchLimit })

    const columns: ColumnProps<OrganizationRequest>[] = [
        {
            title: 'Type',
            dataIndex: 'type',
            render: (_name, record) => (
                <Tag color={getColorFromType(record.type)}>{record.type}</Tag>
            ),
        },
        {
            title: 'Org Name',
            dataIndex: 'organizationName',
            render: (name, record) => (
                <Text copyable={{ text: name }}>{record.organizationName}</Text>
            ),
        },
        {
            title: 'Org ID',
            dataIndex: 'organizationId',
            render: (name, record: OrganizationRequest) =>
                record.organizationId ? (
                    <Space direction="vertical" align="start">
                        <Tooltip
                            color="white"
                            title={
                                <RelatedByOrganization
                                    treeSelectProps={{ size: 'small', bordered: false }}
                                    organizationId={record?.organizationId}
                                    entityId={record?.companyId}
                                />
                            }
                        >
                            <Text copyable={{ text: name }}>{record.organizationId}</Text>
                        </Tooltip>
                    </Space>
                ) : undefined,
        },
        {
            title: 'Company ID',
            dataIndex: 'companyId',
            render: (name, record: OrganizationRequest) =>
                record.companyId ? (
                    <Space direction="vertical" align="start">
                        <Text copyable={{ text: name }}>{record.companyId}</Text>
                        <Link to={`../../companies/${record.companyId}`}>View company</Link>
                    </Space>
                ) : undefined,
        },
        {
            title: 'Registry ID',
            dataIndex: 'registryId',
            render: (name, record: OrganizationRequest) =>
                record.registryId ? (
                    <Space direction="vertical" align="start">
                        <Text copyable={{ text: name }}>{record.registryId}</Text>
                    </Space>
                ) : undefined,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            onCell: (_) => ({ onClick: (e) => e.stopPropagation() }),
            render: (_name, record: OrganizationRequest) => (
                <Tag color={getColorFromStatus(record.status)}>{record.status}</Tag>
            ),
        },
        {
            title: 'Submitted by',
            dataIndex: 'submittedBy',
            render: (_name, record: OrganizationRequest) => record.requesterEmail,
        },
        {
            title: 'Created at',
            dataIndex: 'createdAt',
            render: (_name, record: OrganizationRequest) => dayjs(record.createdAt).format('lll'),
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            render: (_name, record: OrganizationRequest) => (
                <Space>
                    <Button
                        type="primary"
                        icon={<CheckCircleOutlined />}
                        onClick={() =>
                            Modal.confirm({
                                centered: true,
                                icon: <ExclamationCircleOutlined />,
                                content: `Are you sure? This will APPROVE the request to ${
                                    record.type
                                } ${record.companyId ?? record.registryId} from or to the ${
                                    record.organizationName
                                } group`,
                                onOk: async () => {
                                    try {
                                        await mutation.approve(record.id, user.email)
                                        api.info({
                                            message: `Successfully approved`,
                                            description: `Request to ${record.type} ${
                                                record.companyId ?? record.registryId
                                            } from or to the ${record.organizationName} group`,
                                        })
                                        mutate()
                                    } catch (error: any) {
                                        api.error({
                                            message: `Approval failed`,
                                            description: `${error.message}. Please contact teams manage entities for more information about the error.`,
                                        })
                                    }
                                },
                            })
                        }
                    >
                        Approve
                    </Button>
                    <Button
                        danger
                        icon={<CloseCircleOutlined />}
                        onClick={() =>
                            Modal.confirm({
                                centered: true,
                                icon: <ExclamationCircleOutlined />,
                                content: `Are you sure? This will DECLINE the request to ${record.type} ${record.companyId} from or to the ${record.organizationName} group`,
                                onOk: async () => {
                                    await mutation.decline(record.id, user.email)
                                    api.info({
                                        message: `Successfully declined`,
                                        description: `Request to ${record.type} ${
                                            record.companyId ?? record.registryId
                                        } from or to the ${record.organizationName} group`,
                                    })
                                    mutate()
                                },
                            })
                        }
                    >
                        Decline
                    </Button>
                </Space>
            ),
        },
    ]

    const filteredData = useFilteredRequestData({ data, searchTerm })

    return (
        <Card>
            {contextHolder}
            <ContentContainer>
                <Table
                    data-testid="table"
                    dataSource={filteredData}
                    columns={columns}
                    loading={isLoadingInitialData || isLoadingMore}
                    rowKey="id"
                    pagination={false}
                />
                <CursorPaginationControls
                    pagination={{
                        hasPreviousPage: !!pagination?.hasPreviousPage,
                        hasNextPage: !!pagination?.hasNextPage,
                        currentRequestPagination: {
                            ...pagination?.currentRequestPagination,
                        },
                        startCursor: pagination?.startCursor ?? null,
                        endCursor: pagination?.endCursor ?? null,
                    }}
                    limit={fetchLimit ?? pagination?.currentRequestPagination?.limit}
                    onChangeLimit={(limit) => setFetchLimit(limit)}
                    isFetching={isLoadingMore}
                    onNextPage={fetchNextPage}
                    onPrevPage={fetchPreviousPage}
                    onFirstPage={fetchFirstPage}
                />
            </ContentContainer>
        </Card>
    )
}

export const ProcessedMultiEntityRequests: FC<React.PropsWithChildren<{ searchTerm?: string }>> = ({
    searchTerm,
}) => {
    const [fetchLimit, setFetchLimit] = React.useState<number | undefined>()
    const {
        data,
        isLoadingInitialData,
        pagination,
        fetchFirstPage,
        fetchNextPage,
        fetchPreviousPage,
        isLoadingMore,
    } = useProcessedOrganizationRequestActions({ limit: fetchLimit })

    const columns: ColumnProps<OrganizationRequest>[] = [
        {
            title: 'Type',
            dataIndex: 'type',
            render: (_name, record) => (
                <Tag color={getColorFromType(record.type)}>{record.type}</Tag>
            ),
        },
        {
            title: 'Org Name',
            dataIndex: 'organizationName',
            render: (name, record) => (
                <Text copyable={{ text: name }}>{record.organizationName}</Text>
            ),
        },
        {
            title: 'Org ID',
            dataIndex: 'organizationId',
            render: (name, record: OrganizationRequest) =>
                record.organizationId ? (
                    <Space direction="vertical" align="start">
                        <Tooltip
                            title={
                                <RelatedByOrganization
                                    treeSelectProps={{ size: 'small', bordered: false }}
                                    organizationId={record?.organizationId}
                                    entityId={record?.companyId}
                                />
                            }
                        >
                            <Text copyable={{ text: name }}>{record.organizationId}</Text>
                        </Tooltip>
                    </Space>
                ) : undefined,
        },
        {
            title: 'Company ID',
            dataIndex: 'companyId',
            render: (name, record: OrganizationRequest) =>
                record.companyId ? (
                    <Space direction="vertical" align="start">
                        <Text copyable={{ text: name }}>{record.companyId}</Text>
                        <Link to={`../../companies/${record.companyId}`}>View company</Link>
                    </Space>
                ) : undefined,
        },
        {
            title: 'Registry ID',
            dataIndex: 'registryId',
            render: (name, record: OrganizationRequest) =>
                record.registryId ? (
                    <Space direction="vertical" align="start">
                        <Text copyable={{ text: name }}>{record.registryId}</Text>
                    </Space>
                ) : undefined,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            onCell: (_) => ({ onClick: (e) => e.stopPropagation() }),
            render: (_name, record: OrganizationRequest) => (
                <Tag color={getColorFromStatus(record.status)}>{record.status}</Tag>
            ),
        },
        {
            title: 'Submitted by',
            dataIndex: 'submittedBy',
            render: (_name, record: OrganizationRequest) => record.requesterEmail,
        },
        {
            title: 'Created at',
            dataIndex: 'createdAt',
            render: (_name, record: OrganizationRequest) => dayjs(record.createdAt).format('lll'),
        },
    ]

    const filteredData = useFilteredRequestData({ data, searchTerm })

    return (
        <Card>
            <ContentContainer>
                <Table
                    data-testid="table"
                    dataSource={filteredData}
                    columns={columns}
                    loading={isLoadingInitialData || isLoadingMore}
                    rowKey="id"
                    pagination={false}
                />
                <CursorPaginationControls
                    pagination={{
                        hasPreviousPage: !!pagination?.hasPreviousPage,
                        hasNextPage: !!pagination?.hasNextPage,
                        currentRequestPagination: {
                            ...pagination?.currentRequestPagination,
                        },
                        startCursor: pagination?.startCursor ?? null,
                        endCursor: pagination?.endCursor ?? null,
                    }}
                    limit={fetchLimit ?? pagination?.currentRequestPagination?.limit}
                    onChangeLimit={(limit) => setFetchLimit(limit)}
                    isFetching={isLoadingMore}
                    onNextPage={fetchNextPage}
                    onPrevPage={fetchPreviousPage}
                    onFirstPage={fetchFirstPage}
                />
            </ContentContainer>
        </Card>
    )
}

const MultiEntityRequestsContainer = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const [searchParams] = useSearchParams()
    const [searchTerm, setSearchTerm] = React.useState(searchParams.get('requestId') ?? '')
    const [currentTab, setCurrentTab] = React.useState<string>('pending')

    // Set the default path to /pending
    useEffect(() => {
        if (location.pathname.endsWith('multi-entity')) {
            navigate(`${location.pathname}/pending?${location.search}`)
        }
    }, [location.pathname, location.search, navigate])

    const onChangeRequestStatusTabChange = (tabKey: string) => {
        if (location.pathname.endsWith(tabKey)) {
            return
        }
        setCurrentTab(tabKey)
        navigate(`${tabKey}`)
    }

    return (
        <PageContentLayout>
            <Tabs defaultActiveKey="multi-entity">
                <Tabs.TabPane tab="Multi-entity" key={'multi-entity'}>
                    <Tabs defaultActiveKey={currentTab} onChange={onChangeRequestStatusTabChange}>
                        <Tabs.TabPane tab="Pending" key="pending">
                            <Card>
                                <Input
                                    autoFocus
                                    allowClear
                                    value={searchTerm}
                                    placeholder="Filter by requestId, org name, companyId, registryId..."
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    style={{ width: `${inputWidth.huge}` }}
                                />
                            </Card>
                            <PendingMultiEntityRequests searchTerm={searchTerm} key={currentTab} />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Processed" key="processed">
                            <Card>
                                <Input
                                    autoFocus
                                    allowClear
                                    value={searchTerm}
                                    placeholder="Filter by requestId, org name, companyId, registryId..."
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    style={{ width: `${inputWidth.huge}` }}
                                />
                            </Card>
                            <ProcessedMultiEntityRequests
                                searchTerm={searchTerm}
                                key={currentTab}
                            />
                        </Tabs.TabPane>
                    </Tabs>
                </Tabs.TabPane>
            </Tabs>
            <Outlet />
        </PageContentLayout>
    )
}

const IndexRoute = ({ path, index }: { path: string; index: string }) => {
    const location = useLocation()
    const isIndexRoute = location.pathname.endsWith(path)

    if (isIndexRoute) {
        return <Navigate to={`${location.pathname}/${index}`} />
    }

    return null
}

const RequestsPage = () => {
    return (
        <Routes>
            <Route path="/" element={<IndexRoute path="requests" index="multi-entity" />} />
            <Route path="multi-entity" element={<MultiEntityRequestsContainer />}>
                <Route path="pending" element={<></>} />
                <Route path="processed" element={<></>} />
            </Route>
        </Routes>
    )
}

export default withTeamErrorHandler(Team.AdminExperience, RequestsPage)
