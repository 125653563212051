import { Space } from 'antd'
import styled from 'styled-components'

export const NotesContainer = styled(Space)`
    width: 100%;
    max-height: 300px;
    overflow-y: auto;
`

export const NoteItem = styled.div`
    display: flex;
    flex-direction: column;
`
