import { Row, Checkbox, Col } from 'antd'
import type { InvestigationContextOutputs } from 'bff/moons/generated/investigation-runner'
import OutputCheckbox from './output-checkbox'
import { isInvestigationDesiredOutputColumn } from './utils'
import { useMemo } from 'react'
import type { CheckboxChangeEvent } from 'antd/lib/checkbox'

interface OutputListProps {
    outputs: InvestigationContextOutputs
    selectedOutputs: string[]
    saveSelectedOutputs: (selectedOutputs: string[]) => void
}

export default function OutputList({
    outputs,
    selectedOutputs,
    saveSelectedOutputs,
}: OutputListProps) {
    const { checkAll, indeterminate } = useMemo(() => {
        const { checkedCount, totalCount } = Object.values(outputs).reduce(
            (acc, output) => ({
                checkedCount:
                    acc.checkedCount + (selectedOutputs.includes(output.id.toString()) ? 1 : 0),
                totalCount: acc.totalCount + 1,
            }),
            { checkedCount: 0, totalCount: 0 }
        )
        return {
            checkAll: checkedCount === totalCount,
            indeterminate: checkedCount > 0 && checkedCount < totalCount,
        }
    }, [selectedOutputs, outputs])

    const handleCheckboxChange = (key: string, checked: boolean) => {
        const updatedOutputs = checked
            ? [...selectedOutputs, key]
            : selectedOutputs.filter((output) => output !== key)
        saveSelectedOutputs(updatedOutputs)
    }

    const handleSelectAllChange = (e: CheckboxChangeEvent) => {
        const checked = e.target.checked

        const updatedOutputs = checked
            ? Object.values(outputs).map((output) => output.id.toString())
            : []
        saveSelectedOutputs(updatedOutputs)
    }

    return (
        <Row
            data-testid="output-list"
            gutter={[
                { xs: 8, sm: 16, md: 24, lg: 32 },
                { xs: 4, sm: 4, md: 6, lg: 16 },
            ]}
        >
            <Col
                xs={{ flex: '100%' }}
                sm={{ flex: '50%' }}
                md={{ flex: '40%' }}
                lg={{ flex: '30%' }}
                xl={{ flex: '20%' }}
            >
                <Checkbox
                    indeterminate={indeterminate}
                    checked={checkAll}
                    onChange={handleSelectAllChange}
                >
                    Select/Unselect All
                </Checkbox>
            </Col>
            {outputs &&
                Object.values(outputs).map((output) => {
                    if (!isInvestigationDesiredOutputColumn(output)) {
                        return null
                    }

                    return (
                        <OutputCheckbox
                            key={output.id}
                            output={output}
                            checked={selectedOutputs.includes(output.id.toString()) ?? false}
                            onChange={handleCheckboxChange}
                        />
                    )
                })}
        </Row>
    )
}
