import { Button, Card, Col, Divider, Row, Tag, Tooltip } from 'antd'
import { DeleteOutlined, FormOutlined, InfoCircleOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import type { InvestigationContext } from 'bff/moons/generated/investigation-runner'

function mapFields(fields: string[]) {
    return fields
        .slice(0, 5)
        .map((field, index) => (
            <Tag key={index} style={{ marginBottom: 5 }}>
                {field}
            </Tag>
        ))
        .concat(
            fields.length > 5 ? (
                <Tooltip title={fields.slice(5).join(', ')}>
                    <Tag key={fields.length} style={{ marginBottom: 5 }}>
                        +{fields.length - 5}
                    </Tag>
                </Tooltip>
            ) : (
                []
            )
        )
}

export default function ContextCard({
    context,
    isFavorite,
    addFavorite,
    removeFavorite,
}: {
    context: InvestigationContext
    isFavorite?: boolean
    addFavorite: (id: string | undefined) => void
    removeFavorite: (id: string | undefined) => void
}) {
    const navigate = useNavigate()

    const inputKeys = Object.keys(context.inputs)

    const handleExplore = () => {
        navigate(`/customer-success/investigation-portal/${context.id}`)
    }

    function renderFavoriteActions(id: string | undefined) {
        return [
            <Col key="col">
                <Row>
                    <Tooltip title="Remove from favorites">
                        <Button
                            type="default"
                            icon={<DeleteOutlined />}
                            onClick={() => removeFavorite(id)}
                            style={{ width: '10%', marginRight: '5%' }}
                        />
                    </Tooltip>
                    <Button
                        type="primary"
                        key="explore"
                        style={{ width: '80%' }}
                        onClick={handleExplore}
                    >
                        Explore
                    </Button>
                </Row>
            </Col>,
        ]
    }

    function renderDefaultActions(id: string | undefined) {
        return [
            <Col key="col">
                <Row>
                    <Tooltip title="Add to favorites" key="add">
                        <Button
                            type="default"
                            style={{ width: '47.5%', marginRight: '5%' }}
                            onClick={() => addFavorite(id)}
                        >
                            Add to favorites
                        </Button>
                    </Tooltip>
                    <Button
                        type="primary"
                        key="explore"
                        style={{ width: '47.5%' }}
                        onClick={handleExplore}
                    >
                        Explore
                    </Button>
                </Row>
            </Col>,
        ]
    }

    return (
        <StyledCard
            title={
                <>
                    <FormOutlined style={{ fontSize: 24, paddingRight: 12 }} /> {context.label}
                </>
            }
            extra={
                <Tooltip title={`Source table(s): ${context.impactedTables.join(', ')}`}>
                    <InfoCircleOutlined />
                </Tooltip>
            }
            actions={
                isFavorite ? renderFavoriteActions(context?.id) : renderDefaultActions(context?.id)
            }
        >
            <p>{context.description}</p>
            <Divider style={{ marginTop: 12, marginBottom: 12 }} />
            <p style={{ marginBottom: 12, fontWeight: 500 }}>Requestable Information</p>
            <p>{mapFields(inputKeys)}</p>
        </StyledCard>
    )
}

const StyledCard = styled(Card)`
    margin: 24px;
    width: 350px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`
