/**
 * Generated by orval v6.15.0 🍺
 * Do not edit manually.
 * CDD Case Manager API
 * Cdd Case Manager OpenAPI definitions
 * OpenAPI spec version: 9.4.0
 */
import { moonRequest as _moonRequest } from '@pleo-io/bff-moon-clients'

const moonRequest = _moonRequest('cddCaseManager')

export type SearchParams = {
    /**
     * Lower bound of the page of data to return (cannot be used together with [after] or [offset])
     */
    before?: string
    /**
     * Upper bound of the page of data to return (cannot be used together with [before] or [offset])
     */
    after?: string
    /**
     * Offset of the page of data to return (cannot be used together with [before] or [after])
     */
    offset?: number
    /**
     * The maximum amount of items to return
     */
    limit?: number
    /**
     * The keys to sort the results by
     */
    sorting_keys?: string[]
    /**
     * The order to sort the results by. Must be the same length as [sortingKeys]; one order per key
     */
    sorting_order?: PageOrder[]
}

export type GetCompletedCaseChecksParams = {
    /**
     * id of the case to fetch checks for
     */
    case_id: string
}

/**
 * The detailed reason for the case being marked as waiting for customer.
 */
export type WaitingForCustomerSubReason =
    (typeof WaitingForCustomerSubReason)[keyof typeof WaitingForCustomerSubReason]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WaitingForCustomerSubReason = {
    COMPANY_REGISTRATION_CERTIFICATE: 'COMPANY_REGISTRATION_CERTIFICATE',
    BUSINESS_REGISTRY_EXTRACT: 'BUSINESS_REGISTRY_EXTRACT',
    ARTICLES_OF_ASSOCIATION: 'ARTICLES_OF_ASSOCIATION',
    DEED_OF_FOUNDATION: 'DEED_OF_FOUNDATION',
    INCORPORATION_DOCUMENTS: 'INCORPORATION_DOCUMENTS',
    OWNERSHIP_INFORMATION: 'OWNERSHIP_INFORMATION',
    SHAREHOLDER_REGISTER: 'SHAREHOLDER_REGISTER',
    LIST_OF_TRUSTEES: 'LIST_OF_TRUSTEES',
    LIST_OF_DIRECTORS: 'LIST_OF_DIRECTORS',
    BOARD_MEMBERS: 'BOARD_MEMBERS',
    EXECUTIVE_TEAM: 'EXECUTIVE_TEAM',
    BOARD_MEETING_MINUTES: 'BOARD_MEETING_MINUTES',
    PARTNERSHIP_AGREEMENT: 'PARTNERSHIP_AGREEMENT',
    PROOF_OF_BUSINESS: 'PROOF_OF_BUSINESS',
    BUSINESS_EXPLANATION: 'BUSINESS_EXPLANATION',
    PROOF_OF_TRADING_ADDRESS: 'PROOF_OF_TRADING_ADDRESS',
    CBD_LICENSE: 'CBD_LICENSE',
    CERTIFICATE_TO_OPERATE_PRECIOUS_STONES: 'CERTIFICATE_TO_OPERATE_PRECIOUS_STONES',
    GENERAL_CERTIFICATE_TO_OPERATE: 'GENERAL_CERTIFICATE_TO_OPERATE',
    LICENSE_TO_OPERATE_GAMBLING_AND_BETTING: 'LICENSE_TO_OPERATE_GAMBLING_AND_BETTING',
    LICENSE_TO_OPERATE_MINING_AND_PRIVATE_SECURITY:
        'LICENSE_TO_OPERATE_MINING_AND_PRIVATE_SECURITY',
    LICENSE_TO_OPERATE_QUARRYING_AND_EXTRACTION: 'LICENSE_TO_OPERATE_QUARRYING_AND_EXTRACTION',
    AML_POLICY: 'AML_POLICY',
    SOURCE_OF_FUNDS: 'SOURCE_OF_FUNDS',
    FINANCIAL_STATEMENTS: 'FINANCIAL_STATEMENTS',
    TRUST_DEED: 'TRUST_DEED',
    BANK_STATEMENT: 'BANK_STATEMENT',
    PROOF_OF_ID: 'PROOF_OF_ID',
    NATIONAL_ID: 'NATIONAL_ID',
    NATIONALITY: 'NATIONALITY',
    VERIFIED_ADMINISTRATOR: 'VERIFIED_ADMINISTRATOR',
    PROOF_OF_ADDRESS: 'PROOF_OF_ADDRESS',
    RESIDENTIAL_ADDRESS_OF_PERSON: 'RESIDENTIAL_ADDRESS_OF_PERSON',
    COUNTRY_OF_RESIDENCE: 'COUNTRY_OF_RESIDENCE',
    DATE_OF_BIRTH: 'DATE_OF_BIRTH',
    CITIZENSHIP: 'CITIZENSHIP',
    MARRIAGE_OR_NAME_CHANGE_CERTIFICATION: 'MARRIAGE_OR_NAME_CHANGE_CERTIFICATION',
    MARRIAGE_OR_DIVORCE_CERTIFICATE: 'MARRIAGE_OR_DIVORCE_CERTIFICATE',
    MODEL_200: 'MODEL_200',
    KBIS_DOCUMENT: 'KBIS_DOCUMENT',
    OTHER: 'OTHER',
} as const

/**
 * The reason for the case being marked as Waiting for Customer
 */
export type WaitingForCustomerReason =
    (typeof WaitingForCustomerReason)[keyof typeof WaitingForCustomerReason]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WaitingForCustomerReason = {
    COMPANY_INFORMATION_AND_STRUCTURE: 'COMPANY_INFORMATION_AND_STRUCTURE',
    PROOF_OF_BUSINESS_ACTIVITY_AND_LICENSES: 'PROOF_OF_BUSINESS_ACTIVITY_AND_LICENSES',
    IDENTITY_AND_PERSONAL_INFORMATION: 'IDENTITY_AND_PERSONAL_INFORMATION',
    MISCELLANEOUS_AND_OTHER_REQUESTS: 'MISCELLANEOUS_AND_OTHER_REQUESTS',
} as const

/**
 * Request body for updating a note of a CDD case
 */
export interface UpdateNoteRequest {
    /** Note to be updated */
    note: string
}

/**
 * Current State of the case
 */
export type SearchCDDCasesRequestState =
    (typeof SearchCDDCasesRequestState)[keyof typeof SearchCDDCasesRequestState]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SearchCDDCasesRequestState = {
    New: 'New',
    FddApproved: 'FddApproved',
    FddRejected: 'FddRejected',
    InformationReceived: 'InformationReceived',
    InProgress: 'InProgress',
    ReadyForFdd: 'ReadyForFdd',
    WaitingForCustomer: 'WaitingForCustomer',
} as const

/**
 * Company country codes
 */
export type SearchCDDCasesRequestCompanyCountriesItem =
    (typeof SearchCDDCasesRequestCompanyCountriesItem)[keyof typeof SearchCDDCasesRequestCompanyCountriesItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SearchCDDCasesRequestCompanyCountriesItem = {
    UNDEFINED: 'UNDEFINED',
    AC: 'AC',
    AD: 'AD',
    AE: 'AE',
    AF: 'AF',
    AG: 'AG',
    AI: 'AI',
    AL: 'AL',
    AM: 'AM',
    AN: 'AN',
    AO: 'AO',
    AQ: 'AQ',
    AR: 'AR',
    AS: 'AS',
    AT: 'AT',
    AU: 'AU',
    AW: 'AW',
    AX: 'AX',
    AZ: 'AZ',
    BA: 'BA',
    BB: 'BB',
    BD: 'BD',
    BE: 'BE',
    BF: 'BF',
    BG: 'BG',
    BH: 'BH',
    BI: 'BI',
    BJ: 'BJ',
    BL: 'BL',
    BM: 'BM',
    BN: 'BN',
    BO: 'BO',
    BQ: 'BQ',
    BR: 'BR',
    BS: 'BS',
    BT: 'BT',
    BU: 'BU',
    BV: 'BV',
    BW: 'BW',
    BY: 'BY',
    BZ: 'BZ',
    CA: 'CA',
    CC: 'CC',
    CD: 'CD',
    CF: 'CF',
    CG: 'CG',
    CH: 'CH',
    CI: 'CI',
    CK: 'CK',
    CL: 'CL',
    CM: 'CM',
    CN: 'CN',
    CO: 'CO',
    CP: 'CP',
    CR: 'CR',
    CS: 'CS',
    CU: 'CU',
    CV: 'CV',
    CW: 'CW',
    CX: 'CX',
    CY: 'CY',
    CZ: 'CZ',
    DE: 'DE',
    DG: 'DG',
    DJ: 'DJ',
    DK: 'DK',
    DM: 'DM',
    DO: 'DO',
    DZ: 'DZ',
    EA: 'EA',
    EC: 'EC',
    EE: 'EE',
    EG: 'EG',
    EH: 'EH',
    ER: 'ER',
    ES: 'ES',
    ET: 'ET',
    EU: 'EU',
    EZ: 'EZ',
    FI: 'FI',
    FJ: 'FJ',
    FK: 'FK',
    FM: 'FM',
    FO: 'FO',
    FR: 'FR',
    FX: 'FX',
    GA: 'GA',
    GB: 'GB',
    GD: 'GD',
    GE: 'GE',
    GF: 'GF',
    GG: 'GG',
    GH: 'GH',
    GI: 'GI',
    GL: 'GL',
    GM: 'GM',
    GN: 'GN',
    GP: 'GP',
    GQ: 'GQ',
    GR: 'GR',
    GS: 'GS',
    GT: 'GT',
    GU: 'GU',
    GW: 'GW',
    GY: 'GY',
    HK: 'HK',
    HM: 'HM',
    HN: 'HN',
    HR: 'HR',
    HT: 'HT',
    HU: 'HU',
    IC: 'IC',
    ID: 'ID',
    IE: 'IE',
    IL: 'IL',
    IM: 'IM',
    IN: 'IN',
    IO: 'IO',
    IQ: 'IQ',
    IR: 'IR',
    IS: 'IS',
    IT: 'IT',
    JE: 'JE',
    JM: 'JM',
    JO: 'JO',
    JP: 'JP',
    KE: 'KE',
    KG: 'KG',
    KH: 'KH',
    KI: 'KI',
    KM: 'KM',
    KN: 'KN',
    KP: 'KP',
    KR: 'KR',
    KW: 'KW',
    KY: 'KY',
    KZ: 'KZ',
    LA: 'LA',
    LB: 'LB',
    LC: 'LC',
    LI: 'LI',
    LK: 'LK',
    LR: 'LR',
    LS: 'LS',
    LT: 'LT',
    LU: 'LU',
    LV: 'LV',
    LY: 'LY',
    MA: 'MA',
    MC: 'MC',
    MD: 'MD',
    ME: 'ME',
    MF: 'MF',
    MG: 'MG',
    MH: 'MH',
    MK: 'MK',
    ML: 'ML',
    MM: 'MM',
    MN: 'MN',
    MO: 'MO',
    MP: 'MP',
    MQ: 'MQ',
    MR: 'MR',
    MS: 'MS',
    MT: 'MT',
    MU: 'MU',
    MV: 'MV',
    MW: 'MW',
    MX: 'MX',
    MY: 'MY',
    MZ: 'MZ',
    NA: 'NA',
    NC: 'NC',
    NE: 'NE',
    NF: 'NF',
    NG: 'NG',
    NI: 'NI',
    NL: 'NL',
    NO: 'NO',
    NP: 'NP',
    NR: 'NR',
    NT: 'NT',
    NU: 'NU',
    NZ: 'NZ',
    OM: 'OM',
    PA: 'PA',
    PE: 'PE',
    PF: 'PF',
    PG: 'PG',
    PH: 'PH',
    PK: 'PK',
    PL: 'PL',
    PM: 'PM',
    PN: 'PN',
    PR: 'PR',
    PS: 'PS',
    PT: 'PT',
    PW: 'PW',
    PY: 'PY',
    QA: 'QA',
    RE: 'RE',
    RO: 'RO',
    RS: 'RS',
    RU: 'RU',
    RW: 'RW',
    SA: 'SA',
    SB: 'SB',
    SC: 'SC',
    SD: 'SD',
    SE: 'SE',
    SF: 'SF',
    SG: 'SG',
    SH: 'SH',
    SI: 'SI',
    SJ: 'SJ',
    SK: 'SK',
    SL: 'SL',
    SM: 'SM',
    SN: 'SN',
    SO: 'SO',
    SR: 'SR',
    SS: 'SS',
    ST: 'ST',
    SU: 'SU',
    SV: 'SV',
    SX: 'SX',
    SY: 'SY',
    SZ: 'SZ',
    TA: 'TA',
    TC: 'TC',
    TD: 'TD',
    TF: 'TF',
    TG: 'TG',
    TH: 'TH',
    TJ: 'TJ',
    TK: 'TK',
    TL: 'TL',
    TM: 'TM',
    TN: 'TN',
    TO: 'TO',
    TP: 'TP',
    TR: 'TR',
    TT: 'TT',
    TV: 'TV',
    TW: 'TW',
    TZ: 'TZ',
    UA: 'UA',
    UG: 'UG',
    UK: 'UK',
    UM: 'UM',
    US: 'US',
    UY: 'UY',
    UZ: 'UZ',
    VA: 'VA',
    VC: 'VC',
    VE: 'VE',
    VG: 'VG',
    VI: 'VI',
    VN: 'VN',
    VU: 'VU',
    WF: 'WF',
    WS: 'WS',
    XI: 'XI',
    XU: 'XU',
    XK: 'XK',
    YE: 'YE',
    YT: 'YT',
    YU: 'YU',
    ZA: 'ZA',
    ZM: 'ZM',
    ZR: 'ZR',
    ZW: 'ZW',
} as const

/**
 * Request body for creating a new CDD case for a company
 */
export interface SearchCDDCasesRequest {
    /** ID of the Analyst assigned to the case */
    analystId?: string
    /** Company country codes */
    companyCountries?: SearchCDDCasesRequestCompanyCountriesItem[]
    /** UUID of the company the case is for */
    companyId?: string
    /** Lower bound for the creation date of the case */
    createdAtFrom?: string
    /** Upper bound for the creation date of the case */
    createdAtTo?: string
    /** Lower bound for the deletion date of the case */
    deletedAtFrom?: string
    /** Upper bound for the deletion date of the case */
    deletedAtTo?: string
    /** Include deleted cases in the search */
    includeDeleted: boolean
    /** Current State of the case */
    state?: SearchCDDCasesRequestState
    /** Lower bound for the last update date of the case */
    updatedAtFrom?: string
    /** Upper bound for the last update date of the case */
    updatedAtTo?: string
}

/**
 * Status details of the service
 */
export type ResultDetails = { [key: string]: { [key: string]: any } }

/**
 * Health Check Result
 */
export interface Result {
    /** Status details of the service */
    details?: ResultDetails
    error?: ExceptionInfo
    /** Status of the service */
    healthy: boolean
    /** Status description of the service */
    message?: string
    /** Timestamp of the response creation */
    time: string
}

/**
 * The name of the check
 */
export type RemoveCheckRequestCheckName =
    (typeof RemoveCheckRequestCheckName)[keyof typeof RemoveCheckRequestCheckName]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RemoveCheckRequestCheckName = {
    OwnershipStructureReviewed: 'OwnershipStructureReviewed',
    UbosVerified: 'UbosVerified',
    ExecutiveDirectorsVerified: 'ExecutiveDirectorsVerified',
    PepScreened: 'PepScreened',
    SanctionsScreened: 'SanctionsScreened',
    AdverseMediaScreened: 'AdverseMediaScreened',
    RiskAssessmentReviewed: 'RiskAssessmentReviewed',
} as const

/**
 * Request body for removing a completed check
 */
export interface RemoveCheckRequest {
    /** UUID of the case to update the check for */
    caseId: string
    /** The name of the check */
    checkName: RemoveCheckRequestCheckName
}

/**
 * Reason for rejecting a CDD case
 */
export type RejectionReason = (typeof RejectionReason)[keyof typeof RejectionReason]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RejectionReason = {
    INELIGIBLE: 'INELIGIBLE',
    HIGH_RISK_SOHO: 'HIGH_RISK_SOHO',
    ACTIVE_PROPOSAL_FOR_STRIKE_OFF: 'ACTIVE_PROPOSAL_FOR_STRIKE_OFF',
    FRAUDULENT_SIGNUP_OR_DOCUMENTS: 'FRAUDULENT_SIGNUP_OR_DOCUMENTS',
    DISSOLVED_OR_BANKRUPT_COMPANY: 'DISSOLVED_OR_BANKRUPT_COMPANY',
    REJECTED_BY_CRC: 'REJECTED_BY_CRC',
    PROHIBITED_INDUSTRY: 'PROHIBITED_INDUSTRY',
    PROHIBITED_COUNTRY_OF_RESIDENCE_FOR_UBO: 'PROHIBITED_COUNTRY_OF_RESIDENCE_FOR_UBO',
    NOT_REGISTERED_COMPANY: 'NOT_REGISTERED_COMPANY',
    PERSONAL_HOLDING_COMPANY: 'PERSONAL_HOLDING_COMPANY',
    PEP: 'PEP',
    PREVIOUSLY_OFFBOARDED_FOR_FINANCIAL_CRIME: 'PREVIOUSLY_OFFBOARDED_FOR_FINANCIAL_CRIME',
    OUTSIDE_RISK_APPETITE_OTHER: 'OUTSIDE_RISK_APPETITE_OTHER',
    DUPLICATE_SIGNUP: 'DUPLICATE_SIGNUP',
} as const

/**
 * Request body for rejecting a CDD case
 */
export interface RejectFddCaseRequest {
    /** Rejection note */
    caseNote: string
    caseRejectedReason: RejectionReason
}

export type PageOrder = (typeof PageOrder)[keyof typeof PageOrder]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PageOrder = {
    ASC: 'ASC',
    ASC_NULLS_FIRST: 'ASC_NULLS_FIRST',
    ASC_NULLS_LAST: 'ASC_NULLS_LAST',
    DESC: 'DESC',
    DESC_NULLS_FIRST: 'DESC_NULLS_FIRST',
    DESC_NULLS_LAST: 'DESC_NULLS_LAST',
} as const

/**
 * Request to mark a case as waiting for customer.
 */
export interface MarkCaseAsWaitingForCustomerRequest {
    waitingForCustomerReason: WaitingForCustomerReason
    waitingForCustomerSubReason: WaitingForCustomerSubReason
}

/**
 * Request to mark a case as blocked.
 */
export interface MarkCaseAsBlockedRequest {
    blockedReason: BlockedReason
}

/**
 * Error details
 */
export interface ExceptionInfo {
    message?: string
    type: string
}

export interface DataResponseResult {
    data: Result
}

export interface DataResponseListCheckDto {
    data: CheckDto[]
}

export interface DataResponseListCaseEventDto {
    data: CaseEventDto[]
}

export interface DataResponseCheckDto {
    data: CheckDto
}

export interface DataResponseCaseDto {
    data: CaseDto
}

export type CursorPageCurrentRequestInfoParameters = { [key: string]: string[] }

export interface CursorPageCurrentRequestInfo {
    after?: string
    before?: string
    limit?: number
    offset?: number
    parameters: CursorPageCurrentRequestInfoParameters
    sortingKeys?: string[]
    sortingOrder?: PageOrder[]
}

export interface CursorPageInfo {
    currentRequestPagination: CursorPageCurrentRequestInfo
    endCursor?: string
    hasNextPage: boolean
    hasPreviousPage: boolean
    startCursor?: string
    total?: number
}

export interface CursorPaginatedResponseCaseSearchResultDto {
    data: CaseSearchResultDto[]
    pagination: CursorPageInfo
}

/**
 * Request body for creating a new CDD case for a company
 */
export interface CreateCddCaseRequest {
    /** UUID of the company the case is for */
    companyId: string
}

/**
 * The name of the check
 */
export type CompleteCheckRequestCheckName =
    (typeof CompleteCheckRequestCheckName)[keyof typeof CompleteCheckRequestCheckName]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompleteCheckRequestCheckName = {
    OwnershipStructureReviewed: 'OwnershipStructureReviewed',
    UbosVerified: 'UbosVerified',
    ExecutiveDirectorsVerified: 'ExecutiveDirectorsVerified',
    PepScreened: 'PepScreened',
    SanctionsScreened: 'SanctionsScreened',
    AdverseMediaScreened: 'AdverseMediaScreened',
    RiskAssessmentReviewed: 'RiskAssessmentReviewed',
} as const

/**
 * Request body for marking a check as complete
 */
export interface CompleteCheckRequest {
    /** UUID of the case to update the check for */
    caseId: string
    /** The name of the check */
    checkName: CompleteCheckRequestCheckName
}

/**
 * Key of the check that was removed
 */
export type CheckRemovedPayloadDtoAllOfCheckKey =
    (typeof CheckRemovedPayloadDtoAllOfCheckKey)[keyof typeof CheckRemovedPayloadDtoAllOfCheckKey]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CheckRemovedPayloadDtoAllOfCheckKey = {
    OwnershipStructureReviewed: 'OwnershipStructureReviewed',
    UbosVerified: 'UbosVerified',
    ExecutiveDirectorsVerified: 'ExecutiveDirectorsVerified',
    PepScreened: 'PepScreened',
    SanctionsScreened: 'SanctionsScreened',
    AdverseMediaScreened: 'AdverseMediaScreened',
    RiskAssessmentReviewed: 'RiskAssessmentReviewed',
} as const

export type CheckRemovedPayloadDtoAllOf = {
    /** Unique identifier of the analyst who removed the check */
    analystId?: string
    at?: string
    caseId?: string
    /** Key of the check that was removed */
    checkKey?: CheckRemovedPayloadDtoAllOfCheckKey
    companyId?: string
}

/**
 * DTO Representation of a CDD Check Removed Event Payload
 */
export type CheckRemovedPayloadDto = CaseEventPayloadDto & CheckRemovedPayloadDtoAllOf

/**
 * The name of the check
 */
export type CheckDtoName = (typeof CheckDtoName)[keyof typeof CheckDtoName]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CheckDtoName = {
    OwnershipStructureReviewed: 'OwnershipStructureReviewed',
    UbosVerified: 'UbosVerified',
    ExecutiveDirectorsVerified: 'ExecutiveDirectorsVerified',
    PepScreened: 'PepScreened',
    SanctionsScreened: 'SanctionsScreened',
    AdverseMediaScreened: 'AdverseMediaScreened',
    RiskAssessmentReviewed: 'RiskAssessmentReviewed',
} as const

/**
 * DTO Representation of a Completed Check on a CDD Case. Unique combination of name and caseId
 */
export interface CheckDto {
    /** Unique identifier of the case */
    caseId: string
    /** Timestamp the check was completed */
    createdAt: string
    /** Unique identifier of the analyst who marked the check as complete */
    createdByAnalystId: string
    /** Timestamp if the completed check was removed */
    deletedAt?: string
    /** Unique identifier of the analyst if they removed the completed check */
    deletedByAnalystId?: string
    /** The name of the check */
    name: CheckDtoName
}

/**
 * Key of the check that was completed
 */
export type CheckCompletedEventPayloadDtoAllOfCheckKey =
    (typeof CheckCompletedEventPayloadDtoAllOfCheckKey)[keyof typeof CheckCompletedEventPayloadDtoAllOfCheckKey]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CheckCompletedEventPayloadDtoAllOfCheckKey = {
    OwnershipStructureReviewed: 'OwnershipStructureReviewed',
    UbosVerified: 'UbosVerified',
    ExecutiveDirectorsVerified: 'ExecutiveDirectorsVerified',
    PepScreened: 'PepScreened',
    SanctionsScreened: 'SanctionsScreened',
    AdverseMediaScreened: 'AdverseMediaScreened',
    RiskAssessmentReviewed: 'RiskAssessmentReviewed',
} as const

export type CheckCompletedEventPayloadDtoAllOf = {
    /** Unique identifier of the analyst who completed the check */
    analystId?: string
    at?: string
    caseId?: string
    /** Key of the check that was completed */
    checkKey?: CheckCompletedEventPayloadDtoAllOfCheckKey
    companyId?: string
}

/**
 * DTO Representation of a CDD Check Completed Event Payload
 */
export type CheckCompletedEventPayloadDto = CaseEventPayloadDto & CheckCompletedEventPayloadDtoAllOf

/**
 * The state of the case
 */
export type CaseSearchResultDtoState =
    (typeof CaseSearchResultDtoState)[keyof typeof CaseSearchResultDtoState]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CaseSearchResultDtoState = {
    New: 'New',
    ReadyForFdd: 'ReadyForFdd',
    InProgress: 'InProgress',
    WaitingForCustomer: 'WaitingForCustomer',
    InformationReceived: 'InformationReceived',
    FddApproved: 'FddApproved',
    FddRejected: 'FddRejected',
} as const

/**
 * The case company's country
 */
export type CaseSearchResultDtoCompanyCountry =
    (typeof CaseSearchResultDtoCompanyCountry)[keyof typeof CaseSearchResultDtoCompanyCountry]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CaseSearchResultDtoCompanyCountry = {
    UNDEFINED: 'UNDEFINED',
    AC: 'AC',
    AD: 'AD',
    AE: 'AE',
    AF: 'AF',
    AG: 'AG',
    AI: 'AI',
    AL: 'AL',
    AM: 'AM',
    AN: 'AN',
    AO: 'AO',
    AQ: 'AQ',
    AR: 'AR',
    AS: 'AS',
    AT: 'AT',
    AU: 'AU',
    AW: 'AW',
    AX: 'AX',
    AZ: 'AZ',
    BA: 'BA',
    BB: 'BB',
    BD: 'BD',
    BE: 'BE',
    BF: 'BF',
    BG: 'BG',
    BH: 'BH',
    BI: 'BI',
    BJ: 'BJ',
    BL: 'BL',
    BM: 'BM',
    BN: 'BN',
    BO: 'BO',
    BQ: 'BQ',
    BR: 'BR',
    BS: 'BS',
    BT: 'BT',
    BU: 'BU',
    BV: 'BV',
    BW: 'BW',
    BY: 'BY',
    BZ: 'BZ',
    CA: 'CA',
    CC: 'CC',
    CD: 'CD',
    CF: 'CF',
    CG: 'CG',
    CH: 'CH',
    CI: 'CI',
    CK: 'CK',
    CL: 'CL',
    CM: 'CM',
    CN: 'CN',
    CO: 'CO',
    CP: 'CP',
    CR: 'CR',
    CS: 'CS',
    CU: 'CU',
    CV: 'CV',
    CW: 'CW',
    CX: 'CX',
    CY: 'CY',
    CZ: 'CZ',
    DE: 'DE',
    DG: 'DG',
    DJ: 'DJ',
    DK: 'DK',
    DM: 'DM',
    DO: 'DO',
    DZ: 'DZ',
    EA: 'EA',
    EC: 'EC',
    EE: 'EE',
    EG: 'EG',
    EH: 'EH',
    ER: 'ER',
    ES: 'ES',
    ET: 'ET',
    EU: 'EU',
    EZ: 'EZ',
    FI: 'FI',
    FJ: 'FJ',
    FK: 'FK',
    FM: 'FM',
    FO: 'FO',
    FR: 'FR',
    FX: 'FX',
    GA: 'GA',
    GB: 'GB',
    GD: 'GD',
    GE: 'GE',
    GF: 'GF',
    GG: 'GG',
    GH: 'GH',
    GI: 'GI',
    GL: 'GL',
    GM: 'GM',
    GN: 'GN',
    GP: 'GP',
    GQ: 'GQ',
    GR: 'GR',
    GS: 'GS',
    GT: 'GT',
    GU: 'GU',
    GW: 'GW',
    GY: 'GY',
    HK: 'HK',
    HM: 'HM',
    HN: 'HN',
    HR: 'HR',
    HT: 'HT',
    HU: 'HU',
    IC: 'IC',
    ID: 'ID',
    IE: 'IE',
    IL: 'IL',
    IM: 'IM',
    IN: 'IN',
    IO: 'IO',
    IQ: 'IQ',
    IR: 'IR',
    IS: 'IS',
    IT: 'IT',
    JE: 'JE',
    JM: 'JM',
    JO: 'JO',
    JP: 'JP',
    KE: 'KE',
    KG: 'KG',
    KH: 'KH',
    KI: 'KI',
    KM: 'KM',
    KN: 'KN',
    KP: 'KP',
    KR: 'KR',
    KW: 'KW',
    KY: 'KY',
    KZ: 'KZ',
    LA: 'LA',
    LB: 'LB',
    LC: 'LC',
    LI: 'LI',
    LK: 'LK',
    LR: 'LR',
    LS: 'LS',
    LT: 'LT',
    LU: 'LU',
    LV: 'LV',
    LY: 'LY',
    MA: 'MA',
    MC: 'MC',
    MD: 'MD',
    ME: 'ME',
    MF: 'MF',
    MG: 'MG',
    MH: 'MH',
    MK: 'MK',
    ML: 'ML',
    MM: 'MM',
    MN: 'MN',
    MO: 'MO',
    MP: 'MP',
    MQ: 'MQ',
    MR: 'MR',
    MS: 'MS',
    MT: 'MT',
    MU: 'MU',
    MV: 'MV',
    MW: 'MW',
    MX: 'MX',
    MY: 'MY',
    MZ: 'MZ',
    NA: 'NA',
    NC: 'NC',
    NE: 'NE',
    NF: 'NF',
    NG: 'NG',
    NI: 'NI',
    NL: 'NL',
    NO: 'NO',
    NP: 'NP',
    NR: 'NR',
    NT: 'NT',
    NU: 'NU',
    NZ: 'NZ',
    OM: 'OM',
    PA: 'PA',
    PE: 'PE',
    PF: 'PF',
    PG: 'PG',
    PH: 'PH',
    PK: 'PK',
    PL: 'PL',
    PM: 'PM',
    PN: 'PN',
    PR: 'PR',
    PS: 'PS',
    PT: 'PT',
    PW: 'PW',
    PY: 'PY',
    QA: 'QA',
    RE: 'RE',
    RO: 'RO',
    RS: 'RS',
    RU: 'RU',
    RW: 'RW',
    SA: 'SA',
    SB: 'SB',
    SC: 'SC',
    SD: 'SD',
    SE: 'SE',
    SF: 'SF',
    SG: 'SG',
    SH: 'SH',
    SI: 'SI',
    SJ: 'SJ',
    SK: 'SK',
    SL: 'SL',
    SM: 'SM',
    SN: 'SN',
    SO: 'SO',
    SR: 'SR',
    SS: 'SS',
    ST: 'ST',
    SU: 'SU',
    SV: 'SV',
    SX: 'SX',
    SY: 'SY',
    SZ: 'SZ',
    TA: 'TA',
    TC: 'TC',
    TD: 'TD',
    TF: 'TF',
    TG: 'TG',
    TH: 'TH',
    TJ: 'TJ',
    TK: 'TK',
    TL: 'TL',
    TM: 'TM',
    TN: 'TN',
    TO: 'TO',
    TP: 'TP',
    TR: 'TR',
    TT: 'TT',
    TV: 'TV',
    TW: 'TW',
    TZ: 'TZ',
    UA: 'UA',
    UG: 'UG',
    UK: 'UK',
    UM: 'UM',
    US: 'US',
    UY: 'UY',
    UZ: 'UZ',
    VA: 'VA',
    VC: 'VC',
    VE: 'VE',
    VG: 'VG',
    VI: 'VI',
    VN: 'VN',
    VU: 'VU',
    WF: 'WF',
    WS: 'WS',
    XI: 'XI',
    XU: 'XU',
    XK: 'XK',
    YE: 'YE',
    YT: 'YT',
    YU: 'YU',
    ZA: 'ZA',
    ZM: 'ZM',
    ZR: 'ZR',
    ZW: 'ZW',
} as const

/**
 * DTO Representation of a CDD Case Search Result
 */
export interface CaseSearchResultDto {
    analyst?: AnalystDto
    /** Reason for the case being blocked */
    blockedReason?: string
    /** The case company's country */
    companyCountry?: CaseSearchResultDtoCompanyCountry
    /** Unique identifier of the associated company */
    companyId: string
    /** The case company's name */
    companyName?: string
    /** Timestamp when the case was created */
    createdAt: string
    /** Timestamp when the customer was told to submit information by */
    customerDeadline?: string
    /** Unique identifier of the case */
    id: string
    /** Timestamp when the case was last waiting for analyst input */
    lastWaitingForAnalystActionAt?: string
    /** Note associated with the case */
    note?: string
    /** The state of the case */
    state: CaseSearchResultDtoState
    /** Timestamp when the case was last updated */
    updatedAt: string
    /** The reason the case is waiting for the customer */
    waitingForCustomerReason?: string
}

export type CaseRejectedEventPayloadDtoAllOf = {
    /** Unique identifier of the analyst who rejected the case */
    analystId?: string
    at?: string
    caseId?: string
    /** Note associated with the case */
    caseNote?: string
    companyId?: string
    /** Reason the case was rejected */
    reason?: string
}

export type CaseNoteUpdatedEventPayloadDtoAllOf = {
    /** Unique identifier of the analyst who updated the case note */
    analystId?: string
    at?: string
    caseId?: string
    /** Updated note associated with the case */
    caseNote?: string
    companyId?: string
}

/**
 * DTO Representation of a CDD Case Note Updated Event Payload
 */
export type CaseNoteUpdatedEventPayloadDto = CaseEventPayloadDto &
    CaseNoteUpdatedEventPayloadDtoAllOf

export type CaseMarkedAsWaitingForCustomerPayloadDtoAllOf = {
    /** Unique identifier of the analyst who marked the case as waiting for customer */
    analystId?: string
    at?: string
    caseId?: string
    companyId?: string
    /** Timestamp when the customer was told to submit information by */
    customerDeadline?: string
    /** Reason for the case being Waiting For Customer */
    waitingForCustomerReason?: string
    /** More detailed reason for the case being Waiting For Customer */
    waitingForCustomerSubReason?: string
}

/**
 * DTO Representation of a CDD Case Marked As Waiting For Customer Event Payload
 */
export type CaseMarkedAsWaitingForCustomerPayloadDto = CaseEventPayloadDto &
    CaseMarkedAsWaitingForCustomerPayloadDtoAllOf

export type CaseMarkedAsReadyForFDDPayloadDtoAllOf = {
    at?: string
    caseId?: string
    companyId?: string
}

/**
 * DTO Representation of a CDD Case Marked As Ready For FDD Event Payload
 */
export type CaseMarkedAsReadyForFDDPayloadDto = CaseEventPayloadDto &
    CaseMarkedAsReadyForFDDPayloadDtoAllOf

export type CaseMarkedAsInformationReceivedPayloadDtoAllOf = {
    /** Unique identifier of the analyst assigned to the case */
    analystId?: string
    at?: string
    caseId?: string
    companyId?: string
}

/**
 * DTO Representation of a CDD Case Marked As Information Received Event Payload
 */
export type CaseMarkedAsInformationReceivedPayloadDto = CaseEventPayloadDto &
    CaseMarkedAsInformationReceivedPayloadDtoAllOf

export type CaseMarkedAsInProgressPayloadDtoAllOf = {
    /** Unique identifier of the analyst who marked the case as in progress */
    analystId?: string
    at?: string
    caseId?: string
    companyId?: string
}

export type CaseMarkedAsBlockedPayloadDtoAllOf = {
    /** Unique identifier of the analyst who marked the case as blocked */
    analystId?: string
    at?: string
    caseId?: string
    companyId?: string
    /** Reason the case was blocked */
    reason?: string
}

/**
 * DTO Representation of a CDD Case Marked As Blocked Event Payload
 */
export type CaseMarkedAsBlockedPayloadDto = CaseEventPayloadDto & CaseMarkedAsBlockedPayloadDtoAllOf

/**
 * The type of event
 */
export type CaseEventType = (typeof CaseEventType)[keyof typeof CaseEventType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CaseEventType = {
    CASE_APPROVED: 'CASE_APPROVED',
    CASE_ASSIGNED_TO_ANALYST: 'CASE_ASSIGNED_TO_ANALYST',
    CASE_CREATED: 'CASE_CREATED',
    CASE_MARKED_AS_IN_PROGRESS: 'CASE_MARKED_AS_IN_PROGRESS',
    CASE_MARKED_AS_READY_FOR_FDD: 'CASE_MARKED_AS_READY_FOR_FDD',
    CASE_MARKED_AS_WAITING_FOR_CUSTOMER: 'CASE_MARKED_AS_WAITING_FOR_CUSTOMER',
    CASE_REJECTED: 'CASE_REJECTED',
    CHECK_COMPLETED: 'CHECK_COMPLETED',
    CHECK_REMOVED: 'CHECK_REMOVED',
    CASE_MARKED_AS_INFORMATION_RECEIVED: 'CASE_MARKED_AS_INFORMATION_RECEIVED',
    CASE_MARKED_AS_BLOCKED: 'CASE_MARKED_AS_BLOCKED',
    CASE_NOTE_UPDATED: 'CASE_NOTE_UPDATED',
} as const

/**
 * DTO Representation of a CDD Case Approved Event Payload
 */
export interface CaseEventPayloadDto {
    /** Timestamp when the event occurred */
    at: string
    /** Unique identifier of the case */
    caseId: string
    /** Unique identifier of the associated company */
    companyId: string
    eventType: CaseEventType
}

/**
 * DTO Representation of a CDD Case Rejected Event Payload
 */
export type CaseRejectedEventPayloadDto = CaseEventPayloadDto & CaseRejectedEventPayloadDtoAllOf

/**
 * DTO Representation of a CDD Case Marked As In Progress Event Payload
 */
export type CaseMarkedAsInProgressPayloadDto = CaseEventPayloadDto &
    CaseMarkedAsInProgressPayloadDtoAllOf

/**
 * DTO Representation of a CDD Case Event
 */
export interface CaseEventDto {
    /** Unique identifier of the case */
    caseId: string
    /** Timestamp the event was created */
    createdAt: string
    /** Unique identifier of the event */
    eventId: string
    payload: CaseEventPayloadDto
}

/**
 * The state of the case
 */
export type CaseDtoState = (typeof CaseDtoState)[keyof typeof CaseDtoState]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CaseDtoState = {
    New: 'New',
    ReadyForFdd: 'ReadyForFdd',
    InProgress: 'InProgress',
    WaitingForCustomer: 'WaitingForCustomer',
    InformationReceived: 'InformationReceived',
    FddApproved: 'FddApproved',
    FddRejected: 'FddRejected',
} as const

/**
 * DTO Representation of a CDD Case
 */
export interface CaseDto {
    analyst?: AnalystDto
    /** Reason for the case being blocked */
    blockedReason?: string
    /** Unique identifier of the associated company */
    companyId: string
    /** Timestamp when the case was created */
    createdAt: string
    /** Timestamp when the customer was told to submit information by */
    customerDeadline?: string
    /** Unique identifier of the case */
    id: string
    /** Timestamp when the case was last waiting for analyst input */
    lastWaitingForAnalystActionAt?: string
    /** Note associated with the case */
    note?: string
    /** Reason for the case being rejected */
    rejectedReason?: string
    /** The state of the case */
    state: CaseDtoState
    /** Timestamp when the case was last updated */
    updatedAt: string
    /** Reason for the case being Waiting For Customer */
    waitingForCustomerReason?: string
    /** More detailed reason for the case being Waiting For Customer */
    waitingForCustomerSubReason?: string
}

export type CaseCreatedEventPayloadDtoAllOf = {
    at?: string
    caseId?: string
    companyId?: string
}

/**
 * DTO Representation of a CDD Case Created Event Payload
 */
export type CaseCreatedEventPayloadDto = CaseEventPayloadDto & CaseCreatedEventPayloadDtoAllOf

export type CaseAssignedToAnalystPayloadDtoAllOf = {
    /** Unique identifier of the analyst who was assigned the case */
    analystId?: string
    at?: string
    caseId?: string
    companyId?: string
}

/**
 * DTO Representation of a CDD Case Assigned To Analyst Event Payload
 */
export type CaseAssignedToAnalystPayloadDto = CaseEventPayloadDto &
    CaseAssignedToAnalystPayloadDtoAllOf

export type CaseApprovedEventPayloadDtoAllOf = {
    /** Unique identifier of the analyst who approved the case */
    analystId?: string
    at?: string
    caseId?: string
    /** Note associated with the case */
    caseNote?: string
    companyId?: string
}

/**
 * DTO Representation of a CDD Case Approved Event Payload
 */
export type CaseApprovedEventPayloadDto = CaseEventPayloadDto & CaseApprovedEventPayloadDtoAllOf

/**
 * Reason for the case being blocked.
 */
export type BlockedReason = (typeof BlockedReason)[keyof typeof BlockedReason]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BlockedReason = {
    WAITING_ON_BUSINESS_REGISTRY: 'WAITING_ON_BUSINESS_REGISTRY',
    BUG_RAISED_WITH_ENGINEERING: 'BUG_RAISED_WITH_ENGINEERING',
    IDV_PENDING: 'IDV_PENDING',
    CHECKING_WITH_SALES: 'CHECKING_WITH_SALES',
    ESCALATED_TO_EDD: 'ESCALATED_TO_EDD',
    EDD_PEP_MATCH: 'EDD_PEP_MATCH',
    EDD_RISK_COMMITTEE_PENDING: 'EDD_RISK_COMMITTEE_PENDING',
    EDD_REQUIRES_CRC_APPROVAL: 'EDD_REQUIRES_CRC_APPROVAL',
    CHECKING_WITH_FRAMEWORK_TEAM: 'CHECKING_WITH_FRAMEWORK_TEAM',
    EDD_REQUIRES_MLRO_APPROVAL: 'EDD_REQUIRES_MLRO_APPROVAL',
    EDD_REQUIRES_SENIOR_MANAGER_APPROVAL: 'EDD_REQUIRES_SENIOR_MANAGER_APPROVAL',
    HIGH_RISK_SOHO: 'HIGH_RISK_SOHO',
    ESCALATED_TO_TEAM_LEAD: 'ESCALATED_TO_TEAM_LEAD',
} as const

/**
 * Request body for approving FDD on a CDD case
 */
export interface ApproveFddCaseRequest {
    /** Note why case is approved */
    caseNote: string
}

/**
 * Representation of an Analyst working on a case
 */
export interface AnalystDto {
    /** Unique identifier of the analyst */
    id: string
}

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
    config: any,
    args: infer P
) => any
    ? P
    : never

/**
 * Returns the readiness health of the service.It checks the app health and the database connection health
 * @summary Returns the readiness health of the service
 */
export const checkReadiness = (options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<DataResponseResult>({ url: `/health`, method: 'get' }, options)
}

/**
 * Returns the liveness health of the service. It checks the API is responsive
 * @summary Returns the liveness health of the service
 */
export const checkLiveness = (options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<DataResponseResult>({ url: `/health/liveness`, method: 'get' }, options)
}

/**
 * Get the completed checks for a CDD case - Customer Due Diligence KYC for an onboarding company.
 * @summary Get the completed checks for a CDD Case.
 */
export const getCompletedCaseChecks = (
    params: GetCompletedCaseChecksParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DataResponseListCheckDto>(
        { url: `/v1/case-checks`, method: 'get', params },
        options
    )
}

/**
 * Mark a check as completed for a CDD Case.
 * @summary Mark a check completed for a CDD Case.
 */
export const markCheckAsCompletedForCase = (
    completeCheckRequest: CompleteCheckRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DataResponseCheckDto>(
        {
            url: `/v1/case-checks/:complete-check`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: completeCheckRequest,
        },
        options
    )
}

/**
 * Remove that the check was completed for a CDD Case.
 * @summary Remove a completed check for a CDD Case.
 */
export const markCompletedCheckAsRemovedForCase = (
    removeCheckRequest: RemoveCheckRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DataResponseCheckDto>(
        {
            url: `/v1/case-checks/:remove-check`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: removeCheckRequest,
        },
        options
    )
}

/**
 * Fetches the list of performed domain events for a case.
 * @summary Fetch all events for a case.
 */
export const getCaseEvents = (caseId: string, options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<DataResponseListCaseEventDto>(
        { url: `/v1/case-events/${caseId}`, method: 'get' },
        options
    )
}

/**
 * Create a new Case for Customer Due Diligence (CDD) KYC for an onboarding company.
 * @summary Create a new CDD Case.
 */
export const createCase = (
    createCddCaseRequest: CreateCddCaseRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DataResponseCaseDto>(
        {
            url: `/v1/cases`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: createCddCaseRequest,
        },
        options
    )
}

/**
 * Search for CDD cases that match the supplied criteria
 * @summary Search CDD Cases
 */
export const search = (
    searchCDDCasesRequest: SearchCDDCasesRequest,
    params: SearchParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CursorPaginatedResponseCaseSearchResultDto>(
        {
            url: `/v1/cases/:search`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: searchCDDCasesRequest,
            params,
        },
        options
    )
}

/**
 * Fetch a case by its caseId.
 * @summary Get a case by its caseId.
 */
export const getCaseById = (caseId: string, options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<DataResponseCaseDto>({ url: `/v1/cases/${caseId}`, method: 'get' }, options)
}

/**
 * Update the note of a case.
 * @summary Update the note of a case.
 */
export const updateCaseNote = (
    caseId: string,
    updateNoteRequest: UpdateNoteRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DataResponseCaseDto>(
        {
            url: `/v1/cases/${caseId}/note`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: updateNoteRequest,
        },
        options
    )
}

/**
 * Mark a case as having successfully completed the FDD kyc process (Full Due Diligence).
 * @summary Approve FDD for a CDD Case.
 */
export const markCaseAsFddApproved = (
    caseId: string,
    approveFddCaseRequest: ApproveFddCaseRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DataResponseCaseDto>(
        {
            url: `/v1/cases/${caseId}:approve-fdd`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: approveFddCaseRequest,
        },
        options
    )
}

/**
 * Assign a CDD Case to an analyst.
 * @summary Assign a CDD Case.
 */
export const assignCaseToMe = (caseId: string, options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<DataResponseCaseDto>(
        { url: `/v1/cases/${caseId}:assign-to-me`, method: 'post' },
        options
    )
}

/**
 * Indicate that a case is currently being worked on.
 * @summary Mark a case as In Progress.
 */
export const markCaseAsInProgress = (
    caseId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DataResponseCaseDto>(
        { url: `/v1/cases/${caseId}:in-progress`, method: 'post' },
        options
    )
}

/**
 * Indicate that a case received all KYC information requested from customer.
 * @summary Mark a case as Information Received.
 */
export const markCaseAsInformationReceived = (
    caseId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DataResponseCaseDto>(
        { url: `/v1/cases/${caseId}:information-received`, method: 'post' },
        options
    )
}

/**
 * Mark an In Progress case as blocked. This will not change the state of the case, but will add a blocked reason to the case.
 * @summary Mark a case as blocked.
 */
export const markCaseAsBlocked = (
    caseId: string,
    markCaseAsBlockedRequest: MarkCaseAsBlockedRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DataResponseCaseDto>(
        {
            url: `/v1/cases/${caseId}:markAsBlocked`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: markCaseAsBlockedRequest,
        },
        options
    )
}

/**
 * Indicate that a case can now be worked on by an analyst.
 * @summary Mark a case as ready for FDD (Full Due Diligence).
 */
export const markCaseAsReadyForFdd = (
    caseId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DataResponseCaseDto>(
        { url: `/v1/cases/${caseId}:ready-for-fdd`, method: 'post' },
        options
    )
}

/**
 * Mark a case as having failed the FDD kyc process (Full Due Diligence).
 * @summary Reject FDD for a CDD Case.
 */
export const markCaseAsFddRejected = (
    caseId: string,
    rejectFddCaseRequest: RejectFddCaseRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DataResponseCaseDto>(
        {
            url: `/v1/cases/${caseId}:reject-fdd`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: rejectFddCaseRequest,
        },
        options
    )
}

/**
 * Indicate that a case is currently waiting on the customer to submit relevant KYC information.
 * @summary Mark a case as Waiting for Customer.
 */
export const markCaseAsWaitingForCustomer = (
    caseId: string,
    markCaseAsWaitingForCustomerRequest: MarkCaseAsWaitingForCustomerRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DataResponseCaseDto>(
        {
            url: `/v1/cases/${caseId}:waiting-for-customer`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: markCaseAsWaitingForCustomerRequest,
        },
        options
    )
}

export type CheckReadinessResult = NonNullable<Awaited<ReturnType<typeof checkReadiness>>>
export type CheckLivenessResult = NonNullable<Awaited<ReturnType<typeof checkLiveness>>>
export type GetCompletedCaseChecksResult = NonNullable<
    Awaited<ReturnType<typeof getCompletedCaseChecks>>
>
export type MarkCheckAsCompletedForCaseResult = NonNullable<
    Awaited<ReturnType<typeof markCheckAsCompletedForCase>>
>
export type MarkCompletedCheckAsRemovedForCaseResult = NonNullable<
    Awaited<ReturnType<typeof markCompletedCheckAsRemovedForCase>>
>
export type GetCaseEventsResult = NonNullable<Awaited<ReturnType<typeof getCaseEvents>>>
export type CreateCaseResult = NonNullable<Awaited<ReturnType<typeof createCase>>>
export type SearchResult = NonNullable<Awaited<ReturnType<typeof search>>>
export type GetCaseByIdResult = NonNullable<Awaited<ReturnType<typeof getCaseById>>>
export type UpdateCaseNoteResult = NonNullable<Awaited<ReturnType<typeof updateCaseNote>>>
export type MarkCaseAsFddApprovedResult = NonNullable<
    Awaited<ReturnType<typeof markCaseAsFddApproved>>
>
export type AssignCaseToMeResult = NonNullable<Awaited<ReturnType<typeof assignCaseToMe>>>
export type MarkCaseAsInProgressResult = NonNullable<
    Awaited<ReturnType<typeof markCaseAsInProgress>>
>
export type MarkCaseAsInformationReceivedResult = NonNullable<
    Awaited<ReturnType<typeof markCaseAsInformationReceived>>
>
export type MarkCaseAsBlockedResult = NonNullable<Awaited<ReturnType<typeof markCaseAsBlocked>>>
export type MarkCaseAsReadyForFddResult = NonNullable<
    Awaited<ReturnType<typeof markCaseAsReadyForFdd>>
>
export type MarkCaseAsFddRejectedResult = NonNullable<
    Awaited<ReturnType<typeof markCaseAsFddRejected>>
>
export type MarkCaseAsWaitingForCustomerResult = NonNullable<
    Awaited<ReturnType<typeof markCaseAsWaitingForCustomer>>
>
