import {
    AccountBookOutlined,
    ArrowLeftOutlined,
    BankOutlined,
    CheckCircleFilled,
    CloudServerOutlined,
    CopyOutlined,
    CreditCardOutlined,
    DeliveredProcedureOutlined,
    DingtalkOutlined,
    DownOutlined,
    ExclamationCircleFilled,
    ExclamationCircleOutlined,
    InfoCircleOutlined,
    MoneyCollectOutlined,
    SecurityScanOutlined,
    ToTopOutlined,
    UsergroupDeleteOutlined,
    WalletOutlined,
} from '@ant-design/icons'
import type { OffboardingStep, WalletUnloadRequestInfo } from '@pleo-io/deimos'
import {
    CompanyOffboardingCategory,
    CompanyOffboardingReason,
    OffboardingStepName,
} from '@pleo-io/deimos'
import { PageHeader } from '@ant-design/pro-layout'
import {
    Alert,
    Button,
    Card,
    Descriptions,
    Dropdown,
    List,
    Menu,
    message,
    Modal,
    notification,
    Popover,
    Result,
    Space,
    Tag,
    Typography,
} from 'antd'
import { onDeleteOffboarding } from 'components/company-offboarding/cancel-offboarding-confirmation'
import OffboardingForm from 'components/company-offboarding/offboarding-form'
import { categoryTagColor, DisplayOffboardingReason } from 'components/company-offboarding/utils'
import Spinner from 'components/content-spinner'
import { PageContentLayout } from 'components/layout-containers'
import { getEmojiFlag } from 'countries-list'
import React, { FC, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import {
    offboardingStepDescriptions,
    offboardingStepNameDisplay,
    offboardingStepSupportTeam,
    useOffboardingCompanyOverview,
} from 'services/deimos/companies'
import styled from 'styled-components'
import { color, fontSize, spacing } from 'theme/tokens'
import { deSnakify } from 'utils/strings'
import dayjs from 'packages/dayjs'

const { Paragraph, Text } = Typography
const { confirm } = Modal

const iconSize = fontSize.size24

const icons: Record<OffboardingStepName, any> = {
    DEACTIVATE_USERS: <UsergroupDeleteOutlined style={{ fontSize: iconSize }} />,
    CANCEL_SUBSCRIPTION: <MoneyCollectOutlined style={{ fontSize: iconSize }} />,
    FINAL_WALLET_UNLOAD_REQUEST: <WalletOutlined style={{ fontSize: iconSize }} />,
    UNSUBSCRIBE_FROM_ACCOUNTING_SERVICES: <AccountBookOutlined style={{ fontSize: iconSize }} />,
    UNSUBSCRIBE_FROM_KYC_SERVICES: <SecurityScanOutlined style={{ fontSize: iconSize }} />,
    CANCEL_DIRECT_DEBIT_AGREEMENT: <DeliveredProcedureOutlined style={{ fontSize: iconSize }} />,
    DISABLE_AUTO_TOPUP: <ToTopOutlined style={{ fontSize: iconSize }} />,
    DEACTIVATE_WALLET: <WalletOutlined style={{ fontSize: iconSize }} />,
    DEACTIVATE_VIBAN: <CloudServerOutlined style={{ fontSize: iconSize }} />,
    WALLET_IS_EMPTY: <WalletOutlined style={{ fontSize: iconSize }} />,
    CREDIT_PENDING_INVOICES: <BankOutlined style={{ fontSize: iconSize }} />,
    DESTROY_CARDS: <CreditCardOutlined style={{ fontSize: iconSize }} />,
    DISABLE_TRAVELPERK_INTEGRATION: <DingtalkOutlined style={{ fontSize: iconSize }} />,
}

const stepOrder: Array<OffboardingStepName> = [
    OffboardingStepName.CANCEL_SUBSCRIPTION,
    OffboardingStepName.FINAL_WALLET_UNLOAD_REQUEST,
    OffboardingStepName.WALLET_IS_EMPTY,
    OffboardingStepName.CREDIT_PENDING_INVOICES,
    OffboardingStepName.DESTROY_CARDS,
    OffboardingStepName.DEACTIVATE_WALLET,
    OffboardingStepName.DEACTIVATE_VIBAN,
    OffboardingStepName.CANCEL_DIRECT_DEBIT_AGREEMENT,
    OffboardingStepName.DISABLE_AUTO_TOPUP,
    OffboardingStepName.UNSUBSCRIBE_FROM_ACCOUNTING_SERVICES,
    OffboardingStepName.DEACTIVATE_USERS,
    OffboardingStepName.UNSUBSCRIBE_FROM_KYC_SERVICES,
    OffboardingStepName.DISABLE_TRAVELPERK_INTEGRATION,
]

interface Props {
    offboardingStep: OffboardingStep
    loading: boolean
    runStep: () => void
    steps: OffboardingStep[]
}

export const OffboardingTask: FC<React.PropsWithChildren<Props>> = ({
    offboardingStep,
    loading,
    runStep,
    steps,
}) => {
    const walletUnloadRequestCompleted = (): boolean => {
        let isComplete = false
        steps.forEach((step) => {
            if (
                step.stepName === OffboardingStepName.FINAL_WALLET_UNLOAD_REQUEST &&
                step.completed === true
            ) {
                isComplete = true
            }
        })
        return isComplete
    }

    const displayTaskStatus = () => {
        if (offboardingStep.completed) {
            return (
                <Space size={4}>
                    <CheckCircleFilled style={{ color: color.green }} />
                    <Text type="success">Completed</Text>
                </Space>
            )
        }

        if (
            offboardingStep.stepName === OffboardingStepName.WALLET_IS_EMPTY &&
            !offboardingStep.completed &&
            walletUnloadRequestCompleted()
        ) {
            return (
                <Space size={4}>
                    <ExclamationCircleFilled style={{ color: color.gold }} />
                    <Text type="warning">In progress</Text>
                </Space>
            )
        }

        if (!offboardingStep.completed) {
            return (
                <Button
                    type="primary"
                    size="small"
                    onClick={runStep}
                    disabled={!offboardingStep.available || loading}
                >
                    Run
                </Button>
            )
        }
    }

    return (
        <List.Item actions={[displayTaskStatus()]}>
            <List.Item.Meta
                avatar={icons[offboardingStep.stepName]}
                title={offboardingStepNameDisplay[offboardingStep.stepName]}
                description={
                    <DescriptionContainer>
                        {offboardingStepDescriptions[offboardingStep.stepName]}
                    </DescriptionContainer>
                }
            />
        </List.Item>
    )
}

export const OffboardingSteps: FC<
    React.PropsWithChildren<{
        steps: OffboardingStep[]
        isRunningStep: boolean
        runStep: (stepName: OffboardingStepName) => void
    }>
> = ({ steps, isRunningStep, runStep }) => {
    return (
        <>
            {steps
                .sort((a, b) =>
                    stepOrder.indexOf(a.stepName) > stepOrder.indexOf(b.stepName) ? 1 : -1
                )
                .map((offboardingStep: OffboardingStep) => (
                    <OffboardingTask
                        key={offboardingStep.stepName}
                        offboardingStep={offboardingStep}
                        loading={isRunningStep}
                        runStep={() => runStep(offboardingStep.stepName)}
                        steps={steps}
                    />
                ))}
        </>
    )
}

export const OffboardingStepsContainer: FC<
    React.PropsWithChildren<{
        companyId: string
        offboardingReasons: CompanyOffboardingReason[]
        offboardingCategory: CompanyOffboardingCategory | undefined
        offboardingSteps: OffboardingStep[]
        hasOffboardingReasons: Boolean
    }>
> = ({ companyId, offboardingCategory, offboardingSteps, hasOffboardingReasons }) => {
    const [isRunningStep, setIsRunningStep] = useState(false)
    const navigate = useNavigate()
    const goToOffboardingList = () => navigate('/customer-success/offboarding')
    const {
        mutations: { runOffboardingStep, runCloseCompany, runAllAvailableSteps },
    } = useOffboardingCompanyOverview(companyId)

    const runStep = async (stepName: OffboardingStepName) => {
        const loadingMessage = message.loading(
            `Running step: ${offboardingStepNameDisplay[stepName]}`,
            0
        )

        try {
            setIsRunningStep(true)
            await runOffboardingStep(stepName)
            notification.info({
                message: `Successfully ran offboarding step ${offboardingStepNameDisplay[stepName]}`,
            })
        } catch (e) {
            notification.error({
                message: `Failed to run offboarding step ${offboardingStepNameDisplay[stepName]}`,
                description: `${(e as Error).message} - contact team ${
                    offboardingStepSupportTeam[stepName]
                }`,
            })
        } finally {
            setIsRunningStep(false)
            setTimeout(loadingMessage, 0)
        }
    }

    const onRunAllAvailableSteps = async () => {
        const loadingMessage = message.loading(`Running all available steps`, 0)

        try {
            setIsRunningStep(true)
            await runAllAvailableSteps()
            notification.info({
                message: `Successfully ran all available steps`,
            })
        } catch (e) {
            notification.error({
                message: `There was an error while running the available steps`,
                description: (e as Error).message,
            })
        } finally {
            setIsRunningStep(false)
            setTimeout(loadingMessage, 0)
        }
    }

    const onClose = () => {
        confirm({
            centered: true,
            icon: <ExclamationCircleOutlined />,
            title: 'Are you sure?',
            content: 'This action will permanently delete the company.',
            okButtonProps: { id: 'confirm-close', disabled: isRunningStep },
            cancelButtonProps: { disabled: isRunningStep },
            onOk: CloseCompany,
        })
    }

    const areAllStepsCompleted = () => {
        return offboardingSteps.every((step) => {
            return step.completed
        })
    }

    const areStepsAvailable = () => {
        return offboardingSteps.some((step) => {
            return step.available && !step.completed
        })
    }

    const isSilentChurn = () => {
        return offboardingCategory === CompanyOffboardingCategory.SILENT
    }

    const isCloseCompanyButtonAvailable = () => {
        if (areAllStepsCompleted()) {
            if (isSilentChurn()) {
                return true
            } else {
                return hasOffboardingReasons
            }
        } else {
            return false
        }
    }

    const CloseCompany = async () => {
        const loadingMessage = message.loading(`Closing company`, 0)

        try {
            setIsRunningStep(true)
            await runCloseCompany()
            notification.info({
                message: `Successfully closed company`,
            })
            goToOffboardingList()
        } catch (e) {
            notification.error({
                message: `Failed to close company`,
                description: (e as Error).message,
            })
        } finally {
            setIsRunningStep(false)
            setTimeout(loadingMessage, 0)
        }
    }

    return (
        <PageContentLayout>
            <Card
                title="Offboarding tasks"
                extra={
                    <Button
                        data-testid={'runAllBtn'}
                        type="primary"
                        size="small"
                        onClick={onRunAllAvailableSteps}
                        disabled={!areStepsAvailable() || isRunningStep}
                    >
                        Run all
                    </Button>
                }
            >
                <List className="offboarding-steps-list" itemLayout="horizontal">
                    <OffboardingSteps
                        steps={offboardingSteps}
                        isRunningStep={isRunningStep}
                        runStep={runStep}
                    />
                </List>
                <CloseActionContainer>
                    <Space direction="vertical" size="large">
                        {!hasOffboardingReasons && (
                            <Alert
                                message="At least one reason should be added before the company can be closed"
                                type="warning"
                                showIcon
                            />
                        )}
                        <Popover
                            trigger="hover"
                            placement="top"
                            content={
                                'To close a company, offboarding reasons must be selected and all the offboarding steps must be completed'
                            }
                        >
                            <Button
                                data-testid={'closeCompanyBtn'}
                                danger
                                onClick={onClose}
                                disabled={!isCloseCompanyButtonAvailable() || isRunningStep}
                                style={{ display: 'flex', margin: '0 auto' }}
                            >
                                Close company
                            </Button>
                        </Popover>
                    </Space>
                </CloseActionContainer>
            </Card>
        </PageContentLayout>
    )
}

const OffboardingOverview: FC<React.PropsWithChildren<unknown>> = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const goBack = () => navigate(-1)
    const goToOffboardingList = () => navigate('/customer-success/offboarding')
    const {
        data: companyOverview,
        error,
        isValidating,
        mutate: revalidateOffboardingOverview,
    } = useOffboardingCompanyOverview(id)
    const emojiFlag = getEmojiFlag(companyOverview?.company.country || 'GB')
    const [editOffboardingModalActive, setEditOffboardingModal] = useState(false)

    if (!companyOverview && isValidating) {
        return <Spinner />
    }

    if (!companyOverview) {
        return <Result status="500" title="Something went wrong" subTitle={error?.message} />
    }

    const addToEditOffboarding = () => {
        return (
            <Button
                size="small"
                type="link"
                style={{ padding: '0' }}
                onClick={() => setEditOffboardingModal(true)}
                data-testid="add-offboarding-info-button"
            >
                Add
            </Button>
        )
    }

    const showReasons = () => {
        const reasons = companyOverview.company.status?.reasons
        if (reasons === undefined || reasons.length === 0) {
            return addToEditOffboarding()
        }

        return reasons.map((reason: CompanyOffboardingReason) => (
            <Tag key="content-1">{DisplayOffboardingReason[reason]}</Tag>
        ))
    }

    const showCategory = (category: CompanyOffboardingCategory | undefined) => {
        if (!category) return '-'

        return <Tag color={categoryTagColor(category)}>{deSnakify(category)}</Tag>
    }

    const showNotes = () => {
        const notes = companyOverview.company.status?.notes
        if (!notes) return addToEditOffboarding()

        return notes
    }

    const lastWalletUnloadInfo = (lastWalletUnload: WalletUnloadRequestInfo | null) => {
        if (!lastWalletUnload) return '-'

        let accountInfo1 = '-'
        let accountInfo2 = '-'

        switch (lastWalletUnload.accountType) {
            case 'IBAN': {
                accountInfo1 = `IBAN: ${lastWalletUnload.iban}`
                accountInfo2 = `BIC: ${lastWalletUnload.bic}`
                break
            }
            case 'ACCOUNT_NUMBER': {
                accountInfo1 = `Account Number: ${lastWalletUnload.accountNumber}`
                accountInfo2 = `Bank code: ${lastWalletUnload.bankCode}`
                break
            }
            case 'NEM_KONTO_CVR': {
                accountInfo1 = `CVR: ${lastWalletUnload.otherAccountIdentifier}`
                break
            }
        }

        return (
            <Space direction="vertical" size={4}>
                <Text>{accountInfo1}</Text>
                {accountInfo2 && <Text>{accountInfo2}</Text>}
                <Text>
                    Amount:{' '}
                    {lastWalletUnload.amount.value + ' ' + lastWalletUnload.amount.currency ?? '-'}
                </Text>
                <Text>Status: {lastWalletUnload.status ?? '-'}</Text>
                <Text>Bank name: {lastWalletUnload.bankName ?? '-'}</Text>
                <Text>Created at: {dayjs(lastWalletUnload.createdAt).format('lll') ?? '-'}</Text>
                <Text>Created by: {lastWalletUnload.createdBy ?? '-'}</Text>
                <Text>Updated at: {dayjs(lastWalletUnload.updatedAt).format('lll') ?? '-'}</Text>
                <Text>Updated by: {lastWalletUnload.updatedBy ?? '-'}</Text>
            </Space>
        )
    }

    const showLastWalletUnload = () => {
        const lastWalletUnload = companyOverview.lastWalletUnload
        if (!lastWalletUnload) return '-'

        return (
            <Popover
                trigger="hover"
                placement="right"
                content={lastWalletUnloadInfo(lastWalletUnload)}
            >
                <InfoCircleOutlined style={{ margin: 'auto 0' }} />
            </Popover>
        )
    }

    const confirmCancelOffboarding = () => {
        confirm({
            centered: true,
            icon: <ExclamationCircleOutlined />,
            title: 'Cancel offboarding?',
            content: `Are you sure you want to cancel the offboarding of ${companyOverview.company.name}`,
            okButtonProps: { id: 'confirm-offboarding' },
            onOk: async () => {
                await onDeleteOffboarding(companyOverview.company.id)
                goToOffboardingList()
            },
        })
    }

    const closeOffboardingModal = () => {
        setEditOffboardingModal(false)
        revalidateOffboardingOverview()
    }

    return (
        <>
            <PageHeader
                ghost={false}
                onBack={goBack}
                backIcon={<ArrowLeftOutlined />}
                title={
                    <Space>
                        {emojiFlag}
                        <Text copyable={{ icon: [<CopyIcon key="copy-icon" />] }}>
                            {companyOverview?.company.name}
                        </Text>
                    </Space>
                }
                tags={<Tag color="processing">Offboarding</Tag>}
                extra={
                    <Dropdown
                        overlay={
                            <Menu>
                                <Menu.Item
                                    key="1"
                                    onClick={() =>
                                        setEditOffboardingModal(!editOffboardingModalActive)
                                    }
                                >
                                    Edit offboarding
                                </Menu.Item>
                                <Menu.Item
                                    key="2"
                                    onClick={() => confirmCancelOffboarding()}
                                    danger={true}
                                >
                                    Cancel offboarding
                                </Menu.Item>
                            </Menu>
                        }
                    >
                        <Button data-testid="actions-button">
                            Actions <DownOutlined />
                        </Button>
                    </Dropdown>
                }
            >
                <Descriptions column={7} size="small">
                    <Descriptions.Item label="Go to" span={2}>
                        <Link to={`/customer-success/companies/${companyOverview.company.id}`}>
                            Company page
                        </Link>
                    </Descriptions.Item>
                    <Descriptions.Item label="Category" span={2}>
                        {showCategory(companyOverview.company.status?.category)}
                    </Descriptions.Item>
                    <Descriptions.Item label="Users" span={1}>
                        <Popover
                            content={
                                <Space direction="vertical" size={4}>
                                    <Text>
                                        Employees: {companyOverview.employeesSummary.member}
                                    </Text>
                                    <Text>Admins: {companyOverview.employeesSummary.owner}</Text>
                                </Space>
                            }
                        >
                            <Text>
                                {companyOverview.employeesSummary.member +
                                    companyOverview.employeesSummary.owner}
                            </Text>
                        </Popover>
                    </Descriptions.Item>
                    <Descriptions.Item label="Initiated by" span={2}>
                        {companyOverview.company?.status?.initiatedBy}
                    </Descriptions.Item>
                    <Descriptions.Item label="Company ID" span={2}>
                        <Paragraph
                            copyable
                            ellipsis={{ rows: 1, expandable: true, symbol: 'more' }}
                            style={{ maxWidth: '90%' }}
                        >
                            {companyOverview.company.id}
                        </Paragraph>
                    </Descriptions.Item>
                    <Descriptions.Item label="Reasons" span={2}>
                        {showReasons()}
                    </Descriptions.Item>
                    <Descriptions.Item label="Bookkeepers" span={1}>
                        <Popover
                            content={
                                <Space direction="vertical" size={4}>
                                    <Text>
                                        Basic: {companyOverview.employeesSummary.bookkeeperBasic}
                                    </Text>
                                    <Text>
                                        Extended:{' '}
                                        {companyOverview.employeesSummary.bookkeeperExtended}
                                    </Text>
                                    <Text>
                                        Partner:{' '}
                                        {companyOverview.employeesSummary.bookkeeperManaged}
                                    </Text>
                                </Space>
                            }
                        >
                            <Text>
                                {companyOverview.employeesSummary.bookkeeperBasic +
                                    companyOverview.employeesSummary.bookkeeperExtended +
                                    companyOverview.employeesSummary.bookkeeperManaged}
                            </Text>
                        </Popover>
                    </Descriptions.Item>
                    <Descriptions.Item label="Initiated at" span={2}>
                        {dayjs(companyOverview.company?.status?.initiatedAt || new Date()).format(
                            'lll'
                        )}
                    </Descriptions.Item>
                    <Descriptions.Item label="Last wallet unload" span={2}>
                        {showLastWalletUnload()}
                    </Descriptions.Item>
                    <Descriptions.Item label="Notes" span={2}>
                        <Paragraph
                            copyable
                            ellipsis={{ rows: 2, expandable: true, symbol: 'more' }}
                        >
                            {showNotes()}
                        </Paragraph>
                    </Descriptions.Item>
                </Descriptions>
            </PageHeader>
            <OffboardingStepsContainer
                companyId={companyOverview.company.id}
                offboardingReasons={companyOverview.company.status?.reasons ?? []}
                offboardingCategory={companyOverview.company.status?.category}
                offboardingSteps={Array.from(companyOverview.offboardingSteps)}
                hasOffboardingReasons={companyOverview.company.status?.hasReasons ?? false}
            />
            <Modal
                title="Edit offboarding"
                open={editOffboardingModalActive}
                onCancel={() => setEditOffboardingModal(false)}
                footer={null}
                width={'auto'}
                centered
            >
                <OffboardingForm
                    companyId={companyOverview.company.id}
                    closeModal={() => closeOffboardingModal()}
                    offboardingStatus={companyOverview.company?.status}
                    revalidateCompany={revalidateOffboardingOverview}
                />
            </Modal>
        </>
    )
}

const CopyIcon = styled(CopyOutlined)`
    font-size: ${fontSize.size14};
    display: flex;
    justify-content: 'center';
`

const CloseActionContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    border-top: 1px solid ${color.border};
    padding-top: ${spacing.space24};
`

const DescriptionContainer = styled.div`
    margin-right: ${spacing.space80};
`

export default OffboardingOverview
