import type { KycChecksResponse } from '../index.bff'

export interface CheckItemProps {
    check: KycChecksResponse['checks'][0]
    disabled: boolean
}

export const CARD_STYLES = {
    card: { marginBottom: '16px' },
    body: { padding: '8px 24px', height: '288px' },
    actions: { padding: '0 16px' },
}
