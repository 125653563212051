import { Card, Space, Tooltip, Typography, Input, Button, Divider, message } from 'antd'
import { useKycDetailContext } from '../context'
import { bff } from '../bff-hooks'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { useState } from 'react'
import { NoteItem } from './note-item'
import { NotesContainer } from './styles'
import Spinner from 'components/content-spinner'

export const Notes = () => {
    const { caseId } = useKycDetailContext()
    const [newNote, setNewNote] = useState('')
    const { data, isLoading } = bff.kycNotes.getNotes.useQuery({ caseId })

    const { mutateAsync: createNote, isLoading: isCreatingNote } =
        bff.kycNotes.createNote.useMutation({
            onSuccess: () => {
                message.success('Note added')
                setNewNote('')
            },
            onError: (error) => {
                message.error(error instanceof Error ? error.message : 'Failed to add note')
            },
        })

    const handleCreateNote = async () => {
        const trimmedNote = newNote.trim()
        if (!trimmedNote) return

        try {
            await createNote({
                caseId,
                note: trimmedNote,
            })
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error('Failed to create note:', error)
        }
    }

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if ((e.metaKey || e.ctrlKey) && e.key === 'Enter') {
            handleCreateNote()
        }
    }

    return (
        <Card
            size="small"
            title="Notes"
            extra={
                <Tooltip title="Synced with AML Notes on the company page">
                    <QuestionCircleOutlined />
                </Tooltip>
            }
            style={{ marginBottom: '1rem' }}
        >
            <Space direction="vertical" style={{ width: '100%' }}>
                <Input.TextArea
                    placeholder="Add a note"
                    style={{ width: '100%', marginBottom: '0.5rem' }}
                    rows={4}
                    value={newNote}
                    onChange={(e) => setNewNote(e.target.value)}
                    onKeyDown={handleKeyDown}
                    data-testid="note-input"
                />
                <Space style={{ width: '100%', justifyContent: 'space-between' }}>
                    <Typography.Text type="secondary" style={{ fontSize: '0.75rem' }}>
                        Cmd/Ctrl + Enter
                    </Typography.Text>
                    <Button
                        type="primary"
                        loading={isCreatingNote}
                        onClick={handleCreateNote}
                        disabled={!newNote.trim() || isCreatingNote}
                        data-testid="add-note-button"
                    >
                        Add note
                    </Button>
                </Space>
            </Space>
            <Divider />
            <NotesContainer direction="vertical" size="middle">
                {isLoading ? (
                    <Spinner />
                ) : (
                    data?.notes.map((note) => <NoteItem key={note.id} note={note} />)
                )}
            </NotesContainer>
        </Card>
    )
}
