import { Route, Routes } from 'react-router-dom'
import ProtectedRoute from 'router/protected-route'

import Home from './components/home'
import ContextDetail from './components/context-detail'

export default function InvestigationPortal() {
    return (
        <Routes>
            <Route element={<ProtectedRoute />}>
                <Route path="/" element={<Home />} />
                <Route path="/:id" element={<ContextDetail />} />
            </Route>
        </Routes>
    )
}
