import qs from 'qs'
import { useEffect, useState } from 'react'
import { PageOrder } from '@pleo-io/deimos'
import useSWR from 'swr'

import { dionysusRequest } from 'services/request'
import type { PaginatedResponseV2 } from 'types/cursor-pagination-v2'
import type {
    CreateDemoAccountRequest,
    DemoAccountModel,
    SortableDemoAccountProperty,
    DemoAccountResponse,
    EmployeeResponse,
    GetPrecreatedDemoAccountRequest,
    CompanyProfileResponse,
    DemoAccountJobResponse,
} from 'types/demo-accounts'
import { useCursorPaginationV2 } from 'utils/use-cursor-pagination-v2'
import type {
    CompanyProfile,
    DemoProfileListItem,
    CreateDemoAccountFromCompanyProfileRequest,
} from 'bff/moons/generated/dionysus'

const BASE_URL = 'rest/v1/demo-accounts'

export function getDionysus(route: string) {
    return dionysusRequest()
        .get(route)
        .then((res) => res.json())
}

export function postDionysus(route: string, body?: Record<string, any>) {
    return dionysusRequest().post(route, {
        json: body,
    })
}

export function putDionysus(route: string, body?: Record<string, any>) {
    return dionysusRequest().put(route, {
        json: body,
    })
}

export const createDemoAccountAsync = async (
    createDemoAccountRequest: CreateDemoAccountRequest
): Promise<DemoAccountJobResponse> =>
    await postDionysus(BASE_URL, { ...createDemoAccountRequest, asynchronousCreation: true }).then(
        (res) => res.json()
    )

export const getPreCreatedDemoAccount = async (
    createDemoAccountRequest: GetPrecreatedDemoAccountRequest
): Promise<DemoAccountResponse> =>
    await postDionysus(`${BASE_URL}/pre-created`, createDemoAccountRequest).then((res) =>
        res.json()
    )

export const createDemoProfile = async (
    createProfileRequest: CreateDemoAccountFromCompanyProfileRequest
): Promise<CompanyProfileResponse> =>
    await postDionysus(`rest/v1/company-profile/demo-account`, createProfileRequest).then((res) =>
        res.json()
    )

export const getAdmin = (employees: EmployeeResponse[]) =>
    employees.find(({ role }) => role.toLowerCase() === 'owner')

interface CreateDemoAccountProps {
    request: CreateDemoAccountRequest
}

export const useCreateDemoAccount = () => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<Error | null>(null)

    const scheduleCreateDemoAccountJob = async ({ request }: CreateDemoAccountProps) => {
        setLoading(true)
        try {
            return await createDemoAccountAsync(request)
        } catch (e: any) {
            setError(e)
        } finally {
            setLoading(false)
        }
    }

    return {
        scheduleCreateDemoAccountJob,
        loading,
        error,
    }
}

export const defaultLimit = 10
export const defaultSortedProperty: SortableDemoAccountProperty = 'CREATED_AT'
export const defaultPageOrder: PageOrder = PageOrder.DESC

interface UseDemoAccountsProps {
    requesterEmail?: string | null
    companyName?: string | null
    companyId?: string | null
}

export const useDemoAccounts = ({
    requesterEmail,
    companyName,
    companyId,
}: UseDemoAccountsProps) => {
    const [demoAccounts, setDemoAccounts] = useState<DemoAccountModel[]>([])

    const { paginationRequest, setCurrentPagination, ...paginationMutations } =
        useCursorPaginationV2<SortableDemoAccountProperty>({
            sorting_keys: [defaultSortedProperty],
            sorting_order: [defaultPageOrder],
            limit: defaultLimit,
        })

    const requestBody = {
        requesterEmail: requesterEmail ?? null,
        companyName: companyName ?? null,
        companyId: companyId ?? null,
    }

    const fetcher = (url: string) => postDionysus(url, requestBody).then((res) => res.json())

    const response = useSWR<PaginatedResponseV2<DemoAccountModel, SortableDemoAccountProperty>>(
        `rest/v1/demo-accounts:search?${qs.stringify(paginationRequest, { arrayFormat: 'repeat' })}`,
        fetcher
    )

    const pagination = response.data?.pagination
    const data = response.data?.data

    useEffect(() => {
        if (pagination) {
            setCurrentPagination(pagination)
        }
    }, [pagination, setCurrentPagination])

    useEffect(() => {
        if (data) {
            setDemoAccounts(data)
        }
    }, [data])

    const updateExpiresAt = async (id: string, expiresAt: string | null) => {
        await putDionysus(`${BASE_URL}/expired/${id}`, { expiresAt })
        response.mutate()
    }

    return {
        ...response,
        ...paginationMutations,
        mutations: { updateExpiresAt },
        demoAccounts,
        pagination,
    }
}

export const useDemoAccount = (demoAccountId: string | null) =>
    useSWR<DemoAccountResponse, Error>(
        demoAccountId ? `${BASE_URL}/${demoAccountId}` : null,
        getDionysus
    )

// Stubs methods API has been developed in https://linear.app/pleo/issue/TYR-227/find-best-place-for-hosting-profile-json
export const useGetDemoProfiles = () =>
    useSWR<DemoProfileListItem[], Error>(`rest/v1/demo-profiles`, getDionysus)

export const getDemoProfile = async (profileId: string): Promise<CompanyProfile> =>
    await getDionysus(`rest/v1/demo-profiles/${profileId}`)
