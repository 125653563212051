import { useParams } from 'react-router-dom'
import { COLUMNS, TABS, ColumnName, TabName } from './constants'

const HIDDEN_COLUMNS_BY_TAB: Record<TabName, ColumnName[]> = {
    [TABS.SIGN_UP_IN_PROGRESS]: [
        COLUMNS.ASSIGNEE,
        COLUMNS.STP_CHECKS,
        COLUMNS.BLOCKED_REASON,
        COLUMNS.CUSTOMER_WAIT_TIME,
    ],
    [TABS.READY_FOR_FDD]: [COLUMNS.ASSIGNEE, COLUMNS.BLOCKED_REASON],
    [TABS.IN_PROGRESS]: [COLUMNS.STP_CHECKS],
    [TABS.WAITING_FOR_CUSTOMER]: [COLUMNS.STP_CHECKS, COLUMNS.BLOCKED_REASON],
    [TABS.INFORMATION_RECEIVED]: [COLUMNS.STP_CHECKS, COLUMNS.BLOCKED_REASON],
    [TABS.FDD_APPROVED]: [COLUMNS.STP_CHECKS, COLUMNS.BLOCKED_REASON, COLUMNS.CUSTOMER_WAIT_TIME],
    [TABS.FDD_REJECTED]: [COLUMNS.STP_CHECKS, COLUMNS.BLOCKED_REASON, COLUMNS.CUSTOMER_WAIT_TIME],
}

export const useColumns = () => {
    const { pathTab } = useParams()

    const shouldHideColumn = (column: ColumnName): boolean => {
        if (!pathTab) return false
        return HIDDEN_COLUMNS_BY_TAB[pathTab as TabName]?.includes(column) ?? false
    }

    return { shouldHideColumn } as const
}
