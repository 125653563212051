import React, { FC, useState } from 'react'
import { PageHeader } from '@ant-design/pro-layout'
import { Result, Descriptions, Tag, Button, Modal, Input } from 'antd'
import styled from 'styled-components'
import { useCrrFactors, useRiskVersions } from 'services/themis/customer-risk-rating'
import Spinner from 'components/content-spinner'
import CrrFactor from './crr-factor'
import { spacing } from 'theme/tokens'
import { useParams, useNavigate } from 'react-router-dom'
import { ArrowLeftOutlined } from '@ant-design/icons'

const Grid = styled.div`
    margin: ${spacing.space24};
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(700px, 1fr));
    gap: ${spacing.space24};
`
export const CrrConfiguration: FC<React.PropsWithChildren<unknown>> = () => {
    const { id: versionId } = useParams()
    const { data: riskVersion, mutations } = useRiskVersions(versionId)
    const { factors, updateFactor, error } = useCrrFactors(versionId)
    const [isCreateCopyModalVisible, setIsCreateCopyModalVisible] = useState(false)
    const [isRenameVersionModalVisible, setIsRenameVersionModalVisible] = useState(false)
    const [newVersionName, setNewVersionName] = useState('')
    const navigate = useNavigate()

    const MIN_VERSION_NAME_LENGTH = 1
    const MAX_VERSION_NAME_LENGTH = 10

    if (error) {
        return <Result title="Error retrieving risk factors" />
    }

    if (!factors?.length || !riskVersion) {
        return <Spinner />
    }

    const handleConfirmCreateNewVersion = () => {
        if (
            newVersionName.length > MIN_VERSION_NAME_LENGTH &&
            newVersionName.length < MAX_VERSION_NAME_LENGTH
        ) {
            mutations.createNewVersion(newVersionName)
            setIsCreateCopyModalVisible(false)
        }
    }

    const handleRenameVersion = () => {
        if (
            newVersionName.length > MIN_VERSION_NAME_LENGTH &&
            newVersionName.length < MAX_VERSION_NAME_LENGTH
        ) {
            mutations.renameVersion(riskVersion?.id, newVersionName)
            setIsRenameVersionModalVisible(false)
        }
    }

    const headerActionOptions = () => {
        if (riskVersion?.isActive) {
            return <Button onClick={() => setIsCreateCopyModalVisible(true)}>Make a copy</Button>
        } else {
            return (
                <>
                    <Button onClick={() => setIsRenameVersionModalVisible(true)}>
                        Rename version
                    </Button>
                    <Button
                        type="primary"
                        onClick={() => mutations.makeActiveVersion(riskVersion?.id)}
                    >
                        Make active
                    </Button>
                </>
            )
        }
    }

    const versionStatus = () => {
        if (riskVersion?.isActive) {
            return <Tag color="blue">Active</Tag>
        } else {
            return <Tag>Inactive</Tag>
        }
    }

    return (
        <>
            <PageHeader
                title="CRR configuration"
                ghost={false}
                style={{ position: 'sticky', top: 0 }}
                extra={headerActionOptions()}
                onBack={() => navigate(-1)}
                backIcon={<ArrowLeftOutlined />}
            >
                <Descriptions column={3}>
                    <Descriptions.Item label="Version">
                        {riskVersion?.description}
                    </Descriptions.Item>
                    <Descriptions.Item label="Status">{versionStatus()}</Descriptions.Item>
                    <Descriptions.Item label="Factors displayed">
                        {factors?.length}
                    </Descriptions.Item>
                </Descriptions>
            </PageHeader>
            <Grid>
                {factors?.map((item) => (
                    <CrrFactor
                        key={item.id}
                        factorSettings={item}
                        updateFactor={updateFactor}
                        isActiveVersion={riskVersion?.isActive!}
                    />
                ))}
            </Grid>
            <Modal
                title="Make a new copy of the active factor configuration"
                open={isCreateCopyModalVisible}
                onOk={handleConfirmCreateNewVersion}
                onCancel={() => setIsCreateCopyModalVisible(false)}
            >
                <Input
                    placeholder="Provide a name for the new copy"
                    onChange={(e) => setNewVersionName(e.target.value)}
                    minLength={1}
                />
            </Modal>
            <Modal
                title="Rename the current version"
                open={isRenameVersionModalVisible}
                onOk={handleRenameVersion}
                onCancel={() => setIsRenameVersionModalVisible(false)}
            >
                <Input
                    placeholder="Provide a new description"
                    onChange={(e) => setNewVersionName(e.target.value)}
                    minLength={1}
                />
            </Modal>
        </>
    )
}

export default CrrConfiguration
