import { Card, Select, message } from 'antd'
import { BlockedReason } from 'bff/moons/generated/cdd-case-manager'
import { useKycDetailContext } from '../context'
import { bff } from '../bff-hooks'

export const blockedReasonOptions = [
    { label: 'Waiting on Business registry', value: BlockedReason.WAITING_ON_BUSINESS_REGISTRY },
    { label: 'Bug (raised with engineering)', value: BlockedReason.BUG_RAISED_WITH_ENGINEERING },
    { label: 'IDV pending', value: BlockedReason.IDV_PENDING },
    { label: 'Checking with sales', value: BlockedReason.CHECKING_WITH_SALES },
    { label: 'Escalated to EDD', value: BlockedReason.ESCALATED_TO_EDD },
    { label: 'EDD - PEP match', value: BlockedReason.EDD_PEP_MATCH },
    { label: 'EDD - Risk Commitee pending', value: BlockedReason.EDD_RISK_COMMITTEE_PENDING },
    { label: 'EDD - Requires CRC approval', value: BlockedReason.EDD_REQUIRES_CRC_APPROVAL },
    { label: 'Checking with framework team', value: BlockedReason.CHECKING_WITH_FRAMEWORK_TEAM },
    { label: 'EDD - Requires MLRO approval', value: BlockedReason.EDD_REQUIRES_MLRO_APPROVAL },
    {
        label: 'EDD - Requires senior manager approval',
        value: BlockedReason.EDD_REQUIRES_SENIOR_MANAGER_APPROVAL,
    },
    {
        label: 'High Risk SOHO',
        value: BlockedReason.HIGH_RISK_SOHO,
    },
    {
        label: 'Escalated to team lead',
        value: BlockedReason.ESCALATED_TO_TEAM_LEAD,
    },
]

export const CannotProceed = () => {
    const { caseId } = useKycDetailContext()
    const { data, isLoading } = bff.cannotProceed.getBlockedReason.useQuery({ caseId })

    const { mutateAsync: setBlockedReason, isLoading: isUpdatingBlockedReason } =
        bff.cannotProceed.setBlockedReason.useMutation({
            onError: () => {
                message.error('Failed to set blocked reason')
            },
            onSuccess: () => {
                message.success('Blocked reason updated')
            },
        })

    const { mutateAsync: markCaseAsInProgress, isLoading: isClearingBlockedReason } =
        bff.cannotProceed.markCaseAsInProgress.useMutation()

    const handleSelect = async (option?: { label: string; value: BlockedReason }) => {
        if (!option) return
        await setBlockedReason({ caseId, blockedReason: option.value })
    }

    const handleClear = async () => {
        await markCaseAsInProgress({ caseId })
    }

    const isDisabled = !data?.isEditable

    return (
        <Card size="small" title="Cannot Proceed reason" style={{ marginBottom: '1rem' }}>
            <Select
                value={blockedReasonOptions.find((opt) => opt.value === data?.blockedReason)}
                onChange={handleSelect}
                labelInValue
                loading={isLoading || isUpdatingBlockedReason || isClearingBlockedReason}
                disabled={isDisabled || isUpdatingBlockedReason || isClearingBlockedReason}
                style={{ width: '100%', marginTop: 8 }}
                placeholder="Select a reason"
                options={blockedReasonOptions}
                dropdownStyle={{ width: '300px' }}
                allowClear
                onClear={handleClear}
            />
        </Card>
    )
}
