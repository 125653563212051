import { useState } from 'react'
import { message, Select, Modal } from 'antd'
import { CaseDtoState } from 'bff/moons/generated/cdd-case-manager'
import { bff } from '../../bff-hooks'
import { useKycDetailContext } from '../../context'
import { WaitingForCustomerTransitionModal } from './waiting-for-customer-modal'
import { FIXED_OPTIONS, SELECTABLE_OPTIONS, ALL_OPTIONS } from './constants'

export const CaseStateSelect = () => {
    const { caseId } = useKycDetailContext()
    const { data, isLoading } = bff.actionBar.getCaseState.useQuery({ caseId })
    const [isWaitingForCustomerModalOpen, setIsWaitingForCustomerModalOpen] = useState(false)
    const { mutateAsync: updateCaseState, isLoading: isUpdatingState } =
        bff.actionBar.updateCaseState.useMutation()

    const isInUneditableState =
        data?.state && FIXED_OPTIONS.map((option) => option.value).includes(data?.state)

    const handleWaitingForCustomerTransition = () => {
        setIsWaitingForCustomerModalOpen(true)
    }

    const confirmInProgressTransition = () => {
        return new Promise<boolean>((resolve) => {
            if (data?.hasAnalyst) {
                resolve(true)
                return
            }

            Modal.confirm({
                title: 'Assign Case',
                content:
                    'This case has no assignee. You will be assigned to this case and it will be marked as In Progress.',
                okText: 'Proceed',
                cancelText: 'Cancel',
                onOk: () => resolve(true),
                onCancel: () => resolve(false),
            })
        })
    }

    const handleSelect = async (state: CaseDtoState) => {
        try {
            if (state === CaseDtoState.WaitingForCustomer) {
                handleWaitingForCustomerTransition()
                return
            }

            if (state === CaseDtoState.InProgress) {
                const shouldProceed = await confirmInProgressTransition()
                if (!shouldProceed) return
            }

            await updateCaseState({ caseId, state })
            message.success('Case state updated successfully')
        } catch (error) {
            message.error('Failed to update case state')
        }
    }

    return (
        <>
            <Select
                disabled={isInUneditableState || isLoading || isUpdatingState}
                options={SELECTABLE_OPTIONS}
                loading={isLoading || isUpdatingState}
                labelInValue
                value={ALL_OPTIONS.find((option) => option.value === data?.state)}
                style={{ width: 200 }}
                onChange={(option) => {
                    handleSelect(option.value)
                }}
            />
            <WaitingForCustomerTransitionModal
                open={isWaitingForCustomerModalOpen}
                onClose={() => setIsWaitingForCustomerModalOpen(false)}
                onSubmit={async (reason, subReason) => {
                    try {
                        await updateCaseState({
                            caseId,
                            state: CaseDtoState.WaitingForCustomer,
                            waitingForCustomerReason: reason || undefined,
                            waitingForCustomerSubReason: subReason || undefined,
                        })
                        setIsWaitingForCustomerModalOpen(false)
                    } catch (error) {
                        message.error('Failed to update case state')
                    }
                }}
            />
        </>
    )
}
