import type { BulkUpdatePlanParams, PlanResponse } from 'bff/moons/generated/janus-v1'
import { difference } from 'lodash'
import { LimitLabels } from '../enums'
import { preparePlanForUpdate } from '../plan-table/utils'
import { bff } from '../../bff'

export type PlanDifferences = {
    name: string
    country: string
    featuresAdded: string[]
    featuresRemoved: string[]
    addonsAdded: string[]
    addonsRemoved: string[]
    limits: string
    priceGeneration: string
}

const filterForChangedPlans = (payload: BulkUpdatePlanParams[]) => (plan: PlanResponse) =>
    payload.findIndex(
        ({ planType, country, priceGeneration }) =>
            plan.type === planType &&
            plan.country === country &&
            plan.priceGeneration === priceGeneration
    ) >= 0

const getIsNotNull = <T,>(value: T): value is Exclude<T, null> => value !== null

const useGetDifferencesForPlans = (
    payload: BulkUpdatePlanParams[],
    plans: PlanResponse[]
): {
    planDifferences: PlanDifferences[]
    isLoading: boolean
} => {
    const { data: entitlements = [], isLoading } = bff.features.getFeatures.useQuery()

    const getFeatureNameFromID = (featureID: string): string => {
        const [entitlement] = entitlements.filter((feature) => feature?.id === featureID)
        return entitlement?.name ?? ''
    }

    const differences = plans?.filter(filterForChangedPlans(payload))?.map((plan) => {
        const basePlan = preparePlanForUpdate(plan, [], [], {})
        const [payloadPlan] = payload.filter(
            ({ planType, country, priceGeneration }) =>
                basePlan.planType === planType &&
                basePlan.country === country &&
                basePlan.priceGeneration === priceGeneration
        )

        const featuresAddedIds = difference(payloadPlan?.featureIds, basePlan?.featureIds)
        const featuresRemovedIds = difference(basePlan?.featureIds, payloadPlan?.featureIds)
        const addonsAddedIds = difference(payloadPlan?.addonIds, basePlan?.addonIds)
        const addonsRemovedIds = difference(basePlan?.addonIds, payloadPlan?.addonIds)

        const featuresAdded = featuresAddedIds.map(getFeatureNameFromID)
        const featuresRemoved = featuresRemovedIds.map(getFeatureNameFromID)
        const addonsAdded = addonsAddedIds.map(getFeatureNameFromID)
        const addonsRemoved = addonsRemovedIds.map(getFeatureNameFromID)

        const basePlanLimit = plan.limits.maxAllowedEmployees
        const payloadLimit = payloadPlan.limits.maxAllowedEmployees
        const hasLimit = basePlanLimit || payloadLimit
        const hasLimitChanged = basePlanLimit !== payloadLimit
        const limits = hasLimitChanged
            ? `${LimitLabels.MaxAllowedEmployees}: ${
                  basePlanLimit ? `${basePlanLimit} -> ` : ' '
              }${payloadLimit}`
            : ''

        if (
            !(
                featuresAdded.length ||
                featuresRemoved.length ||
                addonsAdded.length ||
                addonsRemoved.length
            )
        ) {
            return null
        }

        return {
            name: plan.name,
            country: plan.country,
            featuresAdded,
            featuresRemoved,
            addonsAdded,
            addonsRemoved,
            limits: hasLimit ? limits : '',
            priceGeneration: plan.priceGeneration,
        }
    })

    const filtered = differences.filter(getIsNotNull)

    return {
        planDifferences: filtered,
        isLoading,
    }
}

export default useGetDifferencesForPlans
