import { FC, useEffect } from 'react'
import { useParams, useNavigate, useSearchParams } from 'react-router-dom'

import { PageHeader } from '@ant-design/pro-layout'

import { Button, Descriptions, Result, Typography } from 'antd'
import { useGetUser } from 'services/auth/users'
import Spinner from 'components/content-spinner'
import dayjs from 'packages/dayjs'
import { DeactivateEmployee } from './components/deactivateEmployee'
import { getUserDisplayRoleByEmployeeRole } from 'utils/employees'
import type { EmployeeRole } from 'types/employee'
import { bff } from './bff-hooks'
import { ArrowLeftOutlined } from '@ant-design/icons'

const { Paragraph, Text, Link } = Typography

const EmployeePage: FC = () => {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const goBack = () => navigate(-1)
    const { id: employeeId } = useParams()
    const { data: employee, error: employeeError } = bff.employeeOrphan.getEmployee.useQuery({
        employeeId,
    })
    const { data: user } = useGetUser(employee?.userId)

    const isEmployeeLoading = !employee && !employeeError

    useEffect(() => {
        // if kerberos user exists - redirecting to the full employee view
        if (user) {
            navigate(`/customer-success/users/${user.id}/${employeeId}`, { replace: true })
        }
    }, [user, employeeId, searchParams, navigate])

    const onCompanyClick = (companyId: string) =>
        navigate(`/customer-success/companies/${companyId}`)

    if (isEmployeeLoading) {
        return <Spinner />
    }

    if (employeeError) {
        return (
            <Result
                status="500"
                title="Something went wrong"
                subTitle="We had trouble fetching the employee"
                extra={
                    <Button type="primary" onClick={goBack}>
                        Go back
                    </Button>
                }
            />
        )
    }

    return (
        <PageHeader
            ghost={false}
            onBack={goBack}
            backIcon={<ArrowLeftOutlined />}
            title={
                <Text>
                    {employee?.firstName} {employee?.lastName}
                </Text>
            }
            extra={<DeactivateEmployee employeeId={employeeId} />}
        >
            <Descriptions size="small">
                <Descriptions.Item label="Created">
                    {employee && dayjs(employee?.createdAt).format('lll')}
                </Descriptions.Item>
                <Descriptions.Item label="Email">
                    <Text copyable>{employee && employee.email}</Text>
                </Descriptions.Item>

                <Descriptions.Item label="Phone no.">
                    {employee && (
                        <Text copyable={!!employee?.phone}>{employee?.phone ?? 'None'}</Text>
                    )}
                </Descriptions.Item>
            </Descriptions>
            <Descriptions size="small">
                <Descriptions.Item label="Role">
                    {employee?.role
                        ? getUserDisplayRoleByEmployeeRole(employee?.role as EmployeeRole) ?? '-'
                        : '-'}
                </Descriptions.Item>
                {employee?.companyId && (
                    <Descriptions.Item label="Company">
                        <Link onClick={() => onCompanyClick(employee?.companyId!)}>
                            {employee?.companyName}
                        </Link>
                    </Descriptions.Item>
                )}
                {employee?.partnerId && (
                    <Descriptions.Item label="Partner ID">
                        <Paragraph
                            copyable
                            ellipsis={{ rows: 1, expandable: true, symbol: 'more' }}
                            style={{ margin: 0, maxWidth: 160 }}
                        >
                            {employee.partnerId}
                        </Paragraph>
                    </Descriptions.Item>
                )}
                <Descriptions.Item label="User ID">
                    {employee?.userId ? (
                        <Paragraph
                            copyable
                            ellipsis={{ rows: 1, expandable: true, symbol: 'more' }}
                            style={{ margin: 0, maxWidth: 160 }}
                        >
                            {employee?.userId}
                        </Paragraph>
                    ) : (
                        '-'
                    )}
                </Descriptions.Item>
                <Descriptions.Item label="Employee ID">
                    <Paragraph
                        copyable
                        ellipsis={{ rows: 1, expandable: true, symbol: 'more' }}
                        style={{ margin: 0, maxWidth: 160 }}
                    >
                        {employeeId}
                    </Paragraph>
                </Descriptions.Item>
            </Descriptions>
            <Descriptions size="small">
                <Descriptions.Item label="Card Access">
                    <Text>{employee?.hasCardAccess ? 'True' : 'False'}</Text>
                </Descriptions.Item>
                {employee?.isSpendingEntity && (
                    <Descriptions.Item label="Spending Entity Employee">
                        <Text>True</Text>
                    </Descriptions.Item>
                )}
            </Descriptions>
        </PageHeader>
    )
}

export default EmployeePage
