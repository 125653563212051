import { Button, Card, Popconfirm } from 'antd'
import { useContextDetailContext } from '../../context'

export const ContextSubmit = () => {
    const {
        runInvestigation,
        investigationOutputLoading,
        showTooManyColumnsWarning,
        setShowTooManyColumnsWarning,
    } = useContextDetailContext()

    const handleConfirmPopconfirm = () => {
        setShowTooManyColumnsWarning(false)
        runInvestigation()
    }

    return (
        <Card
            title="Submit"
            style={{
                marginTop: 24,
            }}
        >
            <Popconfirm
                title="Long query warning"
                description={
                    <>
                        Are you sure you want to run a query returning more than 10 columns? <br />
                        We recommend selecting only the columns you need.
                    </>
                }
                onConfirm={handleConfirmPopconfirm}
                onCancel={() => setShowTooManyColumnsWarning(false)}
                okText="Yes"
                cancelText="No"
                open={showTooManyColumnsWarning}
            >
                <Button
                    style={{ float: 'right' }}
                    type="primary"
                    disabled={investigationOutputLoading}
                    loading={investigationOutputLoading}
                    htmlType="submit"
                >
                    Run investigation
                </Button>
            </Popconfirm>
        </Card>
    )
}
