import { DatePicker } from 'antd'
import dayjs from 'packages/dayjs'
import { getUrlMethods, useFilterState } from 'packages/filters/use-filter-state'
import { formattedDateOrNull } from './utils'

const defaultValue = null
const paramName = 'createdAtFrom'
const { getUrlValue, setUrlValue } = getUrlMethods(paramName, defaultValue)

export function useCreatedAtFromState() {
    return useFilterState(getUrlValue, setUrlValue, defaultValue)
}
type CreatedAtFromProps = {
    filter: ReturnType<typeof useCreatedAtFromState>
    onChange: () => void
}

export function CreatedAtFrom({ filter, onChange }: CreatedAtFromProps) {
    const handleChange = (value: dayjs.Dayjs | null) => {
        filter.directApply(formattedDateOrNull(value))
        onChange()
    }

    return (
        <DatePicker
            style={{ width: '100%' }}
            value={dayjs(filter.interimValue).isValid() ? dayjs(filter.interimValue) : null}
            onChange={handleChange}
        />
    )
}
