import type { FC } from 'react'
import { Button, Dropdown, MenuProps, Modal, message } from 'antd'
import {
    BankOutlined,
    CreditCardOutlined,
    DeleteOutlined,
    ExclamationCircleOutlined,
    MoneyCollectOutlined,
    MoreOutlined,
} from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { demoAccountsBff } from '../../bff/demo-accounts-bff'
import type { DemoAccount } from '../../bff/index.bff'
import type { Company } from 'bff/moons/generated/dionysus'

interface Props {
    demoAccount: DemoAccount
    selectedCompany: Company
}

const invoiceEnabledCountries = ['DE', 'GB', 'ES', 'NL', 'FR']

export const DemoAccountMiscellaneousActionsDropdown: FC<Props> = ({
    demoAccount,
    selectedCompany,
}) => {
    const { mutateAsync: loadWallet, isLoading: isLoadingWallet } =
        demoAccountsBff.singleAccount.loadWallet.useMutation()
    const { mutateAsync: createInvoices, isLoading: isLoadingCreateInvoices } =
        demoAccountsBff.singleAccount.createInvoices.useMutation()
    const { mutateAsync: repairVirtualCards, isLoading: isLoadingRepairVirtualCards } =
        demoAccountsBff.singleAccount.repairVirtualCards.useMutation()
    const { mutateAsync: deleteDemoAccount, isLoading: isLoadingDeleteDemoAccount } =
        demoAccountsBff.singleAccount.deleteDemoAccount.useMutation()

    const loading =
        isLoadingWallet ||
        isLoadingCreateInvoices ||
        isLoadingRepairVirtualCards ||
        isLoadingDeleteDemoAccount

    const navigate = useNavigate()

    const items: MenuProps['items'] = [
        {
            key: 'load_wallet',
            label: 'Load wallet',
            icon: <BankOutlined />,
            onClick: async () => {
                const amount = Number(prompt('How much would you like to load the wallet with?'))

                if (!amount) {
                    return
                }

                const result = await loadWallet({
                    companyId: selectedCompany.id,
                    amount,
                })

                if (result.success) {
                    message.success(`Loaded ${amount} ${result.response.currency}`)
                } else {
                    message.error('Failed to load wallet: ' + result.error)
                }
            },
        },
        {
            key: 'repair_virtual_cards',
            label: 'Repair virtual cards',
            icon: <CreditCardOutlined />,
            onClick: async () => {
                const result = await repairVirtualCards({ companyId: selectedCompany.id })

                if (result.success) {
                    message.success('Repaired virtual cards')
                } else {
                    message.error('Failed to repair virtual cards: ' + result.error)
                }
            },
        },
        {
            key: 'delete_demo_account',
            label: 'Delete demo account',
            icon: <DeleteOutlined />,
            onClick: () =>
                Modal.confirm({
                    centered: true,
                    icon: <ExclamationCircleOutlined />,
                    title: 'Are you sure?',
                    content: 'This will delete the demo account.',
                    onOk: async () => {
                        const result = await deleteDemoAccount({ demoAccountId: demoAccount.id })
                        if (result.success) {
                            message.success('Deleted demo account')
                            navigate('/product/demo-accounts')
                        } else {
                            message.error('Failed to delete demo account: ' + result.error)
                        }
                    },
                }),
        },
    ]

    if (invoiceEnabledCountries.includes(selectedCompany.country)) {
        items.push({
            key: 'generate_invoices',
            label: 'Generate invoices',
            icon: <MoneyCollectOutlined />,
            onClick: async () => {
                const result = await createInvoices({ companyId: selectedCompany.id })

                if (result.success) {
                    message.success(`Generated ${result.response.totalCreated} invoices`)
                } else {
                    message.error('Failed to generate invoices: ' + result.error)
                }
            },
        })
    }

    return (
        <Dropdown menu={{ items }} trigger={['hover']} disabled={loading}>
            <Button disabled={loading} loading={loading} icon={<MoreOutlined />}>
                More
            </Button>
        </Dropdown>
    )
}
