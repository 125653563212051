import { InvestigationInputBaseObjectType } from 'bff/moons/generated/investigation-runner'

type InputTypeMap = {
    [key in InvestigationInputBaseObjectType]: string
}

export const mapInputTypesToAntdTypes: InputTypeMap = {
    [InvestigationInputBaseObjectType.UUID]: 'Input',
    [InvestigationInputBaseObjectType.STRING]: 'Input',
    [InvestigationInputBaseObjectType.ENUM]: 'Select',
    [InvestigationInputBaseObjectType.RANGE_NUMBER]: 'InputNumber',
    [InvestigationInputBaseObjectType.RANGE_DATE]: 'RangePicker',
    [InvestigationInputBaseObjectType.DATE]: 'DatePicker',
    [InvestigationInputBaseObjectType.NUMBER]: 'InputNumber',
    [InvestigationInputBaseObjectType.BOOLEAN]: 'Checkbox',
    [InvestigationInputBaseObjectType.DATETIME]: 'DatePicker',
}
