/**
 * Generated by orval v6.15.0 🍺
 * Do not edit manually.
 * Ariel Client
 * OpenAPI spec version: 1.0.0
 */
import { moonRequest as _moonRequest } from '@pleo-io/bff-moon-clients'

const moonRequest = _moonRequest('ariel')

export type RetrieveLatestPendingScaRequestForCards1Params = {
    'card-ids'?: string[]
}

export type RetrievePendingScaRequestsForCardsParams = {
    'card-ids'?: string[]
}

export type GetCasesCategoryItem = (typeof GetCasesCategoryItem)[keyof typeof GetCasesCategoryItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCasesCategoryItem = {
    CARD_BALANCE_ALTERATION: 'CARD_BALANCE_ALTERATION',
    WALLET_BALANCE_ALTERATION: 'WALLET_BALANCE_ALTERATION',
    AML: 'AML',
    CARD_FRAUD: 'CARD_FRAUD',
    MANUAL_KEY_ENTRY: 'MANUAL_KEY_ENTRY',
    CHIP_AND_SIGNATURE: 'CHIP_AND_SIGNATURE',
    COMPLIANCE: 'COMPLIANCE',
    BILL_FRAUD: 'BILL_FRAUD',
    PERSONAL_TRANSFER_FRAUD: 'PERSONAL_TRANSFER_FRAUD',
    EMPLOYEE_BANK_TRANSFER_FRAUD: 'EMPLOYEE_BANK_TRANSFER_FRAUD',
    WALLET_WITHDRAWAL_FRAUD: 'WALLET_WITHDRAWAL_FRAUD',
    DIRECT_DEBIT_FRAUD: 'DIRECT_DEBIT_FRAUD',
} as const

export type GetCasesStatusItem = (typeof GetCasesStatusItem)[keyof typeof GetCasesStatusItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCasesStatusItem = {
    NEW: 'NEW',
    FALSE_POSITIVE: 'FALSE_POSITIVE',
    REQ_INVESTIGATION: 'REQ_INVESTIGATION',
    FRAUD: 'FRAUD',
    NOT_REQUIRED: 'NOT_REQUIRED',
} as const

export type GetCasesParams = {
    from?: string
    to?: string
    status?: GetCasesStatusItem[]
    category?: GetCasesCategoryItem[]
    limit?: number
    offset?: number
}

export type UpdateFraudStatus1Status =
    (typeof UpdateFraudStatus1Status)[keyof typeof UpdateFraudStatus1Status]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UpdateFraudStatus1Status = {
    NEW: 'NEW',
    FALSE_POSITIVE: 'FALSE_POSITIVE',
    REQ_INVESTIGATION: 'REQ_INVESTIGATION',
    FRAUD: 'FRAUD',
    NOT_REQUIRED: 'NOT_REQUIRED',
} as const

export type UpdateFraudStatus1Params = {
    status?: UpdateFraudStatus1Status
}

export type DesignateAsFraud1Params = {
    companyId?: string
}

export type DesignateAsFraud2Params = {
    companyId?: string
}

export type GetDetectedCardFrauds1Status =
    (typeof GetDetectedCardFrauds1Status)[keyof typeof GetDetectedCardFrauds1Status]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetDetectedCardFrauds1Status = {
    NEW: 'NEW',
    FALSE_POSITIVE: 'FALSE_POSITIVE',
    REQ_INVESTIGATION: 'REQ_INVESTIGATION',
    FRAUD: 'FRAUD',
    NOT_REQUIRED: 'NOT_REQUIRED',
} as const

export type GetDetectedCardFrauds1Params = {
    from?: string
    to?: string
    cardId?: string
    companyId?: string
    status?: GetDetectedCardFrauds1Status
}

export type GetDetectedBillFrauds1Status =
    (typeof GetDetectedBillFrauds1Status)[keyof typeof GetDetectedBillFrauds1Status]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetDetectedBillFrauds1Status = {
    NEW: 'NEW',
    FALSE_POSITIVE: 'FALSE_POSITIVE',
    REQ_INVESTIGATION: 'REQ_INVESTIGATION',
    FRAUD: 'FRAUD',
    NOT_REQUIRED: 'NOT_REQUIRED',
} as const

export type GetDetectedBillFrauds1Params = {
    from?: string
    to?: string
    employeeId?: string
    departmentId?: string
    companyId?: string
    status?: GetDetectedBillFrauds1Status
}

export type GetDetectedAmlFrauds1Status =
    (typeof GetDetectedAmlFrauds1Status)[keyof typeof GetDetectedAmlFrauds1Status]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetDetectedAmlFrauds1Status = {
    NEW: 'NEW',
    FALSE_POSITIVE: 'FALSE_POSITIVE',
    REQ_INVESTIGATION: 'REQ_INVESTIGATION',
    FRAUD: 'FRAUD',
    NOT_REQUIRED: 'NOT_REQUIRED',
} as const

export type GetDetectedAmlFrauds1Params = {
    from?: string
    to?: string
    cardId?: string
    companyId?: string
    status?: GetDetectedAmlFrauds1Status
}

export type GetDetectedFrauds1FamilyItem =
    (typeof GetDetectedFrauds1FamilyItem)[keyof typeof GetDetectedFrauds1FamilyItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetDetectedFrauds1FamilyItem = {
    CARD: 'CARD',
    BILL: 'BILL',
    PERSONAL_TRANSFER: 'PERSONAL_TRANSFER',
    WALLET_WITHDRAWAL: 'WALLET_WITHDRAWAL',
    EMPLOYEE_BANK_TRANSFER: 'EMPLOYEE_BANK_TRANSFER',
} as const

export type GetDetectedFrauds1CategoryItem =
    (typeof GetDetectedFrauds1CategoryItem)[keyof typeof GetDetectedFrauds1CategoryItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetDetectedFrauds1CategoryItem = {
    CARD_BALANCE_ALTERATION: 'CARD_BALANCE_ALTERATION',
    WALLET_BALANCE_ALTERATION: 'WALLET_BALANCE_ALTERATION',
    AML: 'AML',
    CARD_FRAUD: 'CARD_FRAUD',
    MANUAL_KEY_ENTRY: 'MANUAL_KEY_ENTRY',
    CHIP_AND_SIGNATURE: 'CHIP_AND_SIGNATURE',
    COMPLIANCE: 'COMPLIANCE',
    BILL_FRAUD: 'BILL_FRAUD',
    PERSONAL_TRANSFER_FRAUD: 'PERSONAL_TRANSFER_FRAUD',
    EMPLOYEE_BANK_TRANSFER_FRAUD: 'EMPLOYEE_BANK_TRANSFER_FRAUD',
    WALLET_WITHDRAWAL_FRAUD: 'WALLET_WITHDRAWAL_FRAUD',
    DIRECT_DEBIT_FRAUD: 'DIRECT_DEBIT_FRAUD',
} as const

export type GetDetectedFrauds1StatusItem =
    (typeof GetDetectedFrauds1StatusItem)[keyof typeof GetDetectedFrauds1StatusItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetDetectedFrauds1StatusItem = {
    NEW: 'NEW',
    FALSE_POSITIVE: 'FALSE_POSITIVE',
    REQ_INVESTIGATION: 'REQ_INVESTIGATION',
    FRAUD: 'FRAUD',
    NOT_REQUIRED: 'NOT_REQUIRED',
} as const

export type GetDetectedFrauds1Params = {
    from?: string
    to?: string
    employeeId?: string
    departmentId?: string
    cardId?: string
    companyId?: string
    status?: GetDetectedFrauds1StatusItem[]
    category?: GetDetectedFrauds1CategoryItem[]
    family?: GetDetectedFrauds1FamilyItem[]
}

export type AnalyseParams = {
    from?: string
    to?: string
    changedOnly?: boolean
}

export type IsFraudParams = {
    persist?: boolean
    realtime?: boolean
}

export type RetrieveLatestPendingScaRequestForCardsParams = {
    'card-ids'?: string[]
}

export type UpdateFraudStatusStatus =
    (typeof UpdateFraudStatusStatus)[keyof typeof UpdateFraudStatusStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UpdateFraudStatusStatus = {
    NEW: 'NEW',
    FALSE_POSITIVE: 'FALSE_POSITIVE',
    REQ_INVESTIGATION: 'REQ_INVESTIGATION',
    FRAUD: 'FRAUD',
    NOT_REQUIRED: 'NOT_REQUIRED',
} as const

export type UpdateFraudStatusParams = {
    status?: UpdateFraudStatusStatus
}

export type GetDetectedCardFraudsStatus =
    (typeof GetDetectedCardFraudsStatus)[keyof typeof GetDetectedCardFraudsStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetDetectedCardFraudsStatus = {
    NEW: 'NEW',
    FALSE_POSITIVE: 'FALSE_POSITIVE',
    REQ_INVESTIGATION: 'REQ_INVESTIGATION',
    FRAUD: 'FRAUD',
    NOT_REQUIRED: 'NOT_REQUIRED',
} as const

export type GetDetectedCardFraudsParams = {
    from?: string
    to?: string
    cardId?: string
    companyId?: string
    status?: GetDetectedCardFraudsStatus
}

export type GetDetectedBillFraudsStatus =
    (typeof GetDetectedBillFraudsStatus)[keyof typeof GetDetectedBillFraudsStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetDetectedBillFraudsStatus = {
    NEW: 'NEW',
    FALSE_POSITIVE: 'FALSE_POSITIVE',
    REQ_INVESTIGATION: 'REQ_INVESTIGATION',
    FRAUD: 'FRAUD',
    NOT_REQUIRED: 'NOT_REQUIRED',
} as const

export type GetDetectedBillFraudsParams = {
    from?: string
    to?: string
    employeeId?: string
    departmentId?: string
    companyId?: string
    status?: GetDetectedBillFraudsStatus
}

export type GetDetectedAmlFraudsStatus =
    (typeof GetDetectedAmlFraudsStatus)[keyof typeof GetDetectedAmlFraudsStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetDetectedAmlFraudsStatus = {
    NEW: 'NEW',
    FALSE_POSITIVE: 'FALSE_POSITIVE',
    REQ_INVESTIGATION: 'REQ_INVESTIGATION',
    FRAUD: 'FRAUD',
    NOT_REQUIRED: 'NOT_REQUIRED',
} as const

export type GetDetectedAmlFraudsParams = {
    from?: string
    to?: string
    cardId?: string
    companyId?: string
    status?: GetDetectedAmlFraudsStatus
}

export type GetDetectedFraudsFamilyItem =
    (typeof GetDetectedFraudsFamilyItem)[keyof typeof GetDetectedFraudsFamilyItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetDetectedFraudsFamilyItem = {
    CARD: 'CARD',
    BILL: 'BILL',
    PERSONAL_TRANSFER: 'PERSONAL_TRANSFER',
    WALLET_WITHDRAWAL: 'WALLET_WITHDRAWAL',
    EMPLOYEE_BANK_TRANSFER: 'EMPLOYEE_BANK_TRANSFER',
} as const

export type GetDetectedFraudsCategoryItem =
    (typeof GetDetectedFraudsCategoryItem)[keyof typeof GetDetectedFraudsCategoryItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetDetectedFraudsCategoryItem = {
    CARD_BALANCE_ALTERATION: 'CARD_BALANCE_ALTERATION',
    WALLET_BALANCE_ALTERATION: 'WALLET_BALANCE_ALTERATION',
    AML: 'AML',
    CARD_FRAUD: 'CARD_FRAUD',
    MANUAL_KEY_ENTRY: 'MANUAL_KEY_ENTRY',
    CHIP_AND_SIGNATURE: 'CHIP_AND_SIGNATURE',
    COMPLIANCE: 'COMPLIANCE',
    BILL_FRAUD: 'BILL_FRAUD',
    PERSONAL_TRANSFER_FRAUD: 'PERSONAL_TRANSFER_FRAUD',
    EMPLOYEE_BANK_TRANSFER_FRAUD: 'EMPLOYEE_BANK_TRANSFER_FRAUD',
    WALLET_WITHDRAWAL_FRAUD: 'WALLET_WITHDRAWAL_FRAUD',
    DIRECT_DEBIT_FRAUD: 'DIRECT_DEBIT_FRAUD',
} as const

export type GetDetectedFraudsStatusItem =
    (typeof GetDetectedFraudsStatusItem)[keyof typeof GetDetectedFraudsStatusItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetDetectedFraudsStatusItem = {
    NEW: 'NEW',
    FALSE_POSITIVE: 'FALSE_POSITIVE',
    REQ_INVESTIGATION: 'REQ_INVESTIGATION',
    FRAUD: 'FRAUD',
    NOT_REQUIRED: 'NOT_REQUIRED',
} as const

export type GetDetectedFraudsParams = {
    from?: string
    to?: string
    employeeId?: string
    departmentId?: string
    cardId?: string
    companyId?: string
    status?: GetDetectedFraudsStatusItem[]
    category?: GetDetectedFraudsCategoryItem[]
    family?: GetDetectedFraudsFamilyItem[]
}

export type BatchApproveMerchants1Params = {
    from?: string
    to?: string
}

export type BatchApproveMerchantsParams = {
    from?: string
    to?: string
}

export type JobBackfillCompleteTriggeredRules = { [key: string]: any }

export interface WhitelistCardRequest {
    cardId: string
}

export interface TrustedBeneficiaryStatusResponseModel {
    approved: boolean
}

export interface TriggeredRuleWithScore {
    ruleName: string
    score?: number
}

export interface TransactionWithFrauds {
    fraudResults: FraudResult[]
    transactionId: string
}

export type TransactionVirtualWalletProgram =
    (typeof TransactionVirtualWalletProgram)[keyof typeof TransactionVirtualWalletProgram]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransactionVirtualWalletProgram = {
    MASTERS_PASS: 'MASTERS_PASS',
    APPLE_PAY: 'APPLE_PAY',
    GOOGLE_PAY: 'GOOGLE_PAY',
    SAMSUNG_PAY: 'SAMSUNG_PAY',
    MERCHANT_TOKENIZATION_PROGRAM: 'MERCHANT_TOKENIZATION_PROGRAM',
} as const

export type TransactionType = (typeof TransactionType)[keyof typeof TransactionType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransactionType = {
    AUTHORIZATION: 'AUTHORIZATION',
    AUTHORIZATION_REQUEST: 'AUTHORIZATION_REQUEST',
    AUTHORIZATION_REVERSAL: 'AUTHORIZATION_REVERSAL',
    AUTHORIZATION_REVERSAL_REQUEST: 'AUTHORIZATION_REVERSAL_REQUEST',
    AUTHORIZATION_INCREASE: 'AUTHORIZATION_INCREASE',
    AUTHORIZATION_INCREASE_REQUEST: 'AUTHORIZATION_INCREASE_REQUEST',
    BALANCE_ADJUSTMENT: 'BALANCE_ADJUSTMENT',
    INTERNAL_BALANCE_ADJUSTMENT: 'INTERNAL_BALANCE_ADJUSTMENT',
    CHARGE_BACK: 'CHARGE_BACK',
    CHARGE_BACK_NON_CREDIT: 'CHARGE_BACK_NON_CREDIT',
    CHARGE_BACK_REVERSAL: 'CHARGE_BACK_REVERSAL',
    PAYMENT: 'PAYMENT',
    LOAD: 'LOAD',
    UNLOAD: 'UNLOAD',
    SWEEP: 'SWEEP',
    PRESENTMENT: 'PRESENTMENT',
    SECOND_PRESENTMENT: 'SECOND_PRESENTMENT',
    EXPIRY: 'EXPIRY',
} as const

export type TransactionStatus = (typeof TransactionStatus)[keyof typeof TransactionStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransactionStatus = {
    APPROVED: 'APPROVED',
    DECLINED: 'DECLINED',
    CLEARED: 'CLEARED',
    SETTLED: 'SETTLED',
    REFUNDED: 'REFUNDED',
    REVERSED: 'REVERSED',
} as const

export type TransactionSca = (typeof TransactionSca)[keyof typeof TransactionSca]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransactionSca = {
    FULLY_AUTHENTICATED: 'FULLY_AUTHENTICATED',
    NON_AUTHENTICATED_SECURITY_TRANSACTIONS_AT_3DS_CAPABLE_MERCHANT:
        'NON_AUTHENTICATED_SECURITY_TRANSACTIONS_AT_3DS_CAPABLE_MERCHANT',
    NON_AUTHENTICATED_SECURITY_SUBMISSIONS: 'NON_AUTHENTICATED_SECURITY_SUBMISSIONS',
    NON_SECURE_SUBMISSIONS: 'NON_SECURE_SUBMISSIONS',
    NON_AUTHENTICATED_SECURE_TRANSACTION: 'NON_AUTHENTICATED_SECURE_TRANSACTION',
} as const

export type TransactionReasonCode =
    (typeof TransactionReasonCode)[keyof typeof TransactionReasonCode]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransactionReasonCode = {
    AMOUNT_SAME: 'AMOUNT_SAME',
    AMOUNT_DIFFERS: 'AMOUNT_DIFFERS',
    PARTIAL_AMOUNT_MULTI_CLEARING: 'PARTIAL_AMOUNT_MULTI_CLEARING',
    PARTIAL_AMOUNT_FINAL_CLEARING: 'PARTIAL_AMOUNT_FINAL_CLEARING',
} as const

export type TransactionReason = (typeof TransactionReason)[keyof typeof TransactionReason]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransactionReason = {
    ALL_GOOD: 'ALL_GOOD',
    REFER_TO_CARD_ISSUER: 'REFER_TO_CARD_ISSUER',
    REFER_TO_CARD_ISSUER_SPECIAL_CONDITION: 'REFER_TO_CARD_ISSUER_SPECIAL_CONDITION',
    INVALID_MERCHANT: 'INVALID_MERCHANT',
    PICK_UP_CARD: 'PICK_UP_CARD',
    DO_NOT_HONOUR: 'DO_NOT_HONOUR',
    ERROR: 'ERROR',
    PICK_UP_CARD_SPECIAL_CONDITION: 'PICK_UP_CARD_SPECIAL_CONDITION',
    HONOR_WITH_IDENTIFICATION: 'HONOR_WITH_IDENTIFICATION',
    REQUEST_IN_PROGRESS: 'REQUEST_IN_PROGRESS',
    PARTIAL_APPROVAL: 'PARTIAL_APPROVAL',
    APPROVED_VIP: 'APPROVED_VIP',
    INVALID_TRANSACTION: 'INVALID_TRANSACTION',
    INVALID_AMOUNT: 'INVALID_AMOUNT',
    INVALID_CARD_NUMBER: 'INVALID_CARD_NUMBER',
    NO_SUCH_ISSUER: 'NO_SUCH_ISSUER',
    CUSTOMER_CANCELLATION: 'CUSTOMER_CANCELLATION',
    RE_ENTER_TRANSACTION: 'RE_ENTER_TRANSACTION',
    NO_ACTION_TAKEN: 'NO_ACTION_TAKEN',
    NO_SUCH_RECORD: 'NO_SUCH_RECORD',
    DUPLICATE_RECORD_UPDATE: 'DUPLICATE_RECORD_UPDATE',
    FILE_UPDATE_FIELD_EDIT_ERROR: 'FILE_UPDATE_FIELD_EDIT_ERROR',
    FILE_LOCKED_OUT_WHILE_UPDATE: 'FILE_LOCKED_OUT_WHILE_UPDATE',
    FILE_UPDATE_ERROR: 'FILE_UPDATE_ERROR',
    FORMAT_ERROR: 'FORMAT_ERROR',
    COMPLETED_PARTIALLY: 'COMPLETED_PARTIALLY',
    SUSPECT_FRAUD: 'SUSPECT_FRAUD',
    REQUESTED_FUNCTION_NOT_SUPPORTED: 'REQUESTED_FUNCTION_NOT_SUPPORTED',
    LOST_CARD_PICK_UP: 'LOST_CARD_PICK_UP',
    STOLEN_CARD: 'STOLEN_CARD',
    INSUFFICIENT_FUNDS: 'INSUFFICIENT_FUNDS',
    NO_CHECKING_ACCOUNT: 'NO_CHECKING_ACCOUNT',
    NO_SAVINGS_ACCOUNT: 'NO_SAVINGS_ACCOUNT',
    EXPIRED_CARD: 'EXPIRED_CARD',
    INCORRECT_PIN: 'INCORRECT_PIN',
    NO_CARD_RECORD: 'NO_CARD_RECORD',
    CARDHOLDER_NOT_PERMITTED: 'CARDHOLDER_NOT_PERMITTED',
    EXCEEDS_AMOUNT_LIMIT: 'EXCEEDS_AMOUNT_LIMIT',
    RESTRICTED_CARD: 'RESTRICTED_CARD',
    SECURITY_VIOLATION: 'SECURITY_VIOLATION',
    EXCEEDS_FREQUENCY_LIMIT: 'EXCEEDS_FREQUENCY_LIMIT',
    RESPONSE_TIMEOUT: 'RESPONSE_TIMEOUT',
    INVALID_TRANSACTION_CONTACT_CARD_ISSUER: 'INVALID_TRANSACTION_CONTACT_CARD_ISSUER',
    DECLINE_PIN_NOT_CHANGED: 'DECLINE_PIN_NOT_CHANGED',
    PIN_TRIES_EXCEEDED: 'PIN_TRIES_EXCEEDED',
    WRONG_PIN_NUMBER_OF_PIN_TRIES_EXCEEDED: 'WRONG_PIN_NUMBER_OF_PIN_TRIES_EXCEEDED',
    INCONSISTENT_WITH_ORIGINAL: 'INCONSISTENT_WITH_ORIGINAL',
    NO_ACCOUNT: 'NO_ACCOUNT',
    NETWORK_ERROR: 'NETWORK_ERROR',
    FOREIGN_NETWORK_ERROR: 'FOREIGN_NETWORK_ERROR',
    IEM_TIMEOUT: 'IEM_TIMEOUT',
    TRANSACTION_FAILED: 'TRANSACTION_FAILED',
    PRE_AUTHORIZATION_TIME_TOO_GREAT: 'PRE_AUTHORIZATION_TIME_TOO_GREAT',
    NOT_DECLINED_ZERO_AMOUNT: 'NOT_DECLINED_ZERO_AMOUNT',
    CANNOT_VERIFY_PIN: 'CANNOT_VERIFY_PIN',
    CRYPTOGRAPHIC_FAILURE: 'CRYPTOGRAPHIC_FAILURE',
    ISSUER_UNAVAILABLE: 'ISSUER_UNAVAILABLE',
    INVALID_RECEIVING_INSTITUTION_ID: 'INVALID_RECEIVING_INSTITUTION_ID',
    TRANSACTION_VIOLATES_LAW: 'TRANSACTION_VIOLATES_LAW',
    DUPLICATE_TRANSACTION: 'DUPLICATE_TRANSACTION',
    RECONCILE_ERROR: 'RECONCILE_ERROR',
    SYSTEM_MALFUNCTION: 'SYSTEM_MALFUNCTION',
} as const

export type TransactionPurchaseType =
    (typeof TransactionPurchaseType)[keyof typeof TransactionPurchaseType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransactionPurchaseType = {
    ATM: 'ATM',
    ONLINE: 'ONLINE',
    IN_STORE: 'IN_STORE',
} as const

export type TransactionProvider = (typeof TransactionProvider)[keyof typeof TransactionProvider]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransactionProvider = {
    GPS: 'GPS',
    ENFUCE: 'ENFUCE',
} as const

export type TransactionOther = { [key: string]: string }

export type TransactionIngestor = (typeof TransactionIngestor)[keyof typeof TransactionIngestor]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransactionIngestor = {
    ENDOR: 'ENDOR',
    CARD_PURCHASE_INGESTOR: 'CARD_PURCHASE_INGESTOR',
} as const

export type TransactionBeta = { [key: string]: { [key: string]: any } }

export type TransactionAcquirerScaExemptionsItem =
    (typeof TransactionAcquirerScaExemptionsItem)[keyof typeof TransactionAcquirerScaExemptionsItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransactionAcquirerScaExemptionsItem = {
    SECURE_CORPORATE_PAYMENT: 'SECURE_CORPORATE_PAYMENT',
    TRUSTED_MERCHANT: 'TRUSTED_MERCHANT',
    ACQUIRER_LOW_FRAUD_AND_TRANSACTION_RISK_ANALYSIS:
        'ACQUIRER_LOW_FRAUD_AND_TRANSACTION_RISK_ANALYSIS',
    LOW_VALUE_PAYMENT: 'LOW_VALUE_PAYMENT',
    MERCHANT_INITIATED_TRANSACTION: 'MERCHANT_INITIATED_TRANSACTION',
    RECURRING_PAYMENT: 'RECURRING_PAYMENT',
    STRONG_CUSTOMER_AUTHENTICATION: 'STRONG_CUSTOMER_AUTHENTICATION',
    AUTHENTICATION_OUTAGE_EXCEPTION: 'AUTHENTICATION_OUTAGE_EXCEPTION',
} as const

export type TransactionAcquirerCountry =
    (typeof TransactionAcquirerCountry)[keyof typeof TransactionAcquirerCountry]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransactionAcquirerCountry = {
    UNDEFINED: 'UNDEFINED',
    AC: 'AC',
    AD: 'AD',
    AE: 'AE',
    AF: 'AF',
    AG: 'AG',
    AI: 'AI',
    AL: 'AL',
    AM: 'AM',
    AN: 'AN',
    AO: 'AO',
    AQ: 'AQ',
    AR: 'AR',
    AS: 'AS',
    AT: 'AT',
    AU: 'AU',
    AW: 'AW',
    AX: 'AX',
    AZ: 'AZ',
    BA: 'BA',
    BB: 'BB',
    BD: 'BD',
    BE: 'BE',
    BF: 'BF',
    BG: 'BG',
    BH: 'BH',
    BI: 'BI',
    BJ: 'BJ',
    BL: 'BL',
    BM: 'BM',
    BN: 'BN',
    BO: 'BO',
    BQ: 'BQ',
    BR: 'BR',
    BS: 'BS',
    BT: 'BT',
    BU: 'BU',
    BV: 'BV',
    BW: 'BW',
    BY: 'BY',
    BZ: 'BZ',
    CA: 'CA',
    CC: 'CC',
    CD: 'CD',
    CF: 'CF',
    CG: 'CG',
    CH: 'CH',
    CI: 'CI',
    CK: 'CK',
    CL: 'CL',
    CM: 'CM',
    CN: 'CN',
    CO: 'CO',
    CP: 'CP',
    CR: 'CR',
    CS: 'CS',
    CU: 'CU',
    CV: 'CV',
    CW: 'CW',
    CX: 'CX',
    CY: 'CY',
    CZ: 'CZ',
    DE: 'DE',
    DG: 'DG',
    DJ: 'DJ',
    DK: 'DK',
    DM: 'DM',
    DO: 'DO',
    DZ: 'DZ',
    EA: 'EA',
    EC: 'EC',
    EE: 'EE',
    EG: 'EG',
    EH: 'EH',
    ER: 'ER',
    ES: 'ES',
    ET: 'ET',
    EU: 'EU',
    EZ: 'EZ',
    FI: 'FI',
    FJ: 'FJ',
    FK: 'FK',
    FM: 'FM',
    FO: 'FO',
    FR: 'FR',
    FX: 'FX',
    GA: 'GA',
    GB: 'GB',
    GD: 'GD',
    GE: 'GE',
    GF: 'GF',
    GG: 'GG',
    GH: 'GH',
    GI: 'GI',
    GL: 'GL',
    GM: 'GM',
    GN: 'GN',
    GP: 'GP',
    GQ: 'GQ',
    GR: 'GR',
    GS: 'GS',
    GT: 'GT',
    GU: 'GU',
    GW: 'GW',
    GY: 'GY',
    HK: 'HK',
    HM: 'HM',
    HN: 'HN',
    HR: 'HR',
    HT: 'HT',
    HU: 'HU',
    IC: 'IC',
    ID: 'ID',
    IE: 'IE',
    IL: 'IL',
    IM: 'IM',
    IN: 'IN',
    IO: 'IO',
    IQ: 'IQ',
    IR: 'IR',
    IS: 'IS',
    IT: 'IT',
    JE: 'JE',
    JM: 'JM',
    JO: 'JO',
    JP: 'JP',
    KE: 'KE',
    KG: 'KG',
    KH: 'KH',
    KI: 'KI',
    KM: 'KM',
    KN: 'KN',
    KP: 'KP',
    KR: 'KR',
    KW: 'KW',
    KY: 'KY',
    KZ: 'KZ',
    LA: 'LA',
    LB: 'LB',
    LC: 'LC',
    LI: 'LI',
    LK: 'LK',
    LR: 'LR',
    LS: 'LS',
    LT: 'LT',
    LU: 'LU',
    LV: 'LV',
    LY: 'LY',
    MA: 'MA',
    MC: 'MC',
    MD: 'MD',
    ME: 'ME',
    MF: 'MF',
    MG: 'MG',
    MH: 'MH',
    MK: 'MK',
    ML: 'ML',
    MM: 'MM',
    MN: 'MN',
    MO: 'MO',
    MP: 'MP',
    MQ: 'MQ',
    MR: 'MR',
    MS: 'MS',
    MT: 'MT',
    MU: 'MU',
    MV: 'MV',
    MW: 'MW',
    MX: 'MX',
    MY: 'MY',
    MZ: 'MZ',
    NA: 'NA',
    NC: 'NC',
    NE: 'NE',
    NF: 'NF',
    NG: 'NG',
    NI: 'NI',
    NL: 'NL',
    NO: 'NO',
    NP: 'NP',
    NR: 'NR',
    NT: 'NT',
    NU: 'NU',
    NZ: 'NZ',
    OM: 'OM',
    PA: 'PA',
    PE: 'PE',
    PF: 'PF',
    PG: 'PG',
    PH: 'PH',
    PK: 'PK',
    PL: 'PL',
    PM: 'PM',
    PN: 'PN',
    PR: 'PR',
    PS: 'PS',
    PT: 'PT',
    PW: 'PW',
    PY: 'PY',
    QA: 'QA',
    RE: 'RE',
    RO: 'RO',
    RS: 'RS',
    RU: 'RU',
    RW: 'RW',
    SA: 'SA',
    SB: 'SB',
    SC: 'SC',
    SD: 'SD',
    SE: 'SE',
    SF: 'SF',
    SG: 'SG',
    SH: 'SH',
    SI: 'SI',
    SJ: 'SJ',
    SK: 'SK',
    SL: 'SL',
    SM: 'SM',
    SN: 'SN',
    SO: 'SO',
    SR: 'SR',
    SS: 'SS',
    ST: 'ST',
    SU: 'SU',
    SV: 'SV',
    SX: 'SX',
    SY: 'SY',
    SZ: 'SZ',
    TA: 'TA',
    TC: 'TC',
    TD: 'TD',
    TF: 'TF',
    TG: 'TG',
    TH: 'TH',
    TJ: 'TJ',
    TK: 'TK',
    TL: 'TL',
    TM: 'TM',
    TN: 'TN',
    TO: 'TO',
    TP: 'TP',
    TR: 'TR',
    TT: 'TT',
    TV: 'TV',
    TW: 'TW',
    TZ: 'TZ',
    UA: 'UA',
    UG: 'UG',
    UK: 'UK',
    UM: 'UM',
    US: 'US',
    UY: 'UY',
    UZ: 'UZ',
    VA: 'VA',
    VC: 'VC',
    VE: 'VE',
    VG: 'VG',
    VI: 'VI',
    VN: 'VN',
    VU: 'VU',
    WF: 'WF',
    WS: 'WS',
    XI: 'XI',
    XU: 'XU',
    XK: 'XK',
    YE: 'YE',
    YT: 'YT',
    YU: 'YU',
    ZA: 'ZA',
    ZM: 'ZM',
    ZR: 'ZR',
    ZW: 'ZW',
} as const

export interface Transaction {
    /** Enum of ONE, TWO and UNKNOWN. OpenAPI is unfortunately not able to correctly name the model */
    '3dsVersion'?: string
    acquirerCountry?: TransactionAcquirerCountry
    acquirerScaExemptions?: TransactionAcquirerScaExemptionsItem[]
    amount?: Money
    authExpirationInMinutes?: number
    authPairingHash?: string
    beta?: TransactionBeta
    bill?: Money
    cardId?: string
    cardPresent?: boolean
    cardholder?: Cardholder
    cardholderBillingAmount?: Money
    clearingFileId?: string
    companyId?: string
    correctedAmount?: Money
    correctedCardholderBillingAmount?: Money
    customIdentifier?: CustomIdentifier
    dispute?: boolean
    eSecurityLevel?: ElectronicCommerceSecurityLevel
    fees?: Fee[]
    homeAmount?: Money
    id?: string
    idempotencyKey?: string
    ingestor?: TransactionIngestor
    interchange?: Money
    interchangeData?: InterchangeData
    isDispute?: boolean
    link?: string
    mastercard?: Mastercard
    merchant?: Merchant
    originalId?: string
    other?: TransactionOther
    performed?: string
    pos?: Pos
    provider?: TransactionProvider
    providerId?: string
    purchaseType?: TransactionPurchaseType
    reason?: TransactionReason
    reasonCode?: TransactionReasonCode
    received?: string
    recurringTraceId?: string
    refund?: boolean
    sca?: TransactionSca
    settlementAmount?: Money
    source?: Source
    status?: TransactionStatus
    target?: Target
    type?: TransactionType
    virtualWalletProgram?: TransactionVirtualWalletProgram
}

/**
 * Enum of ONE, TWO and UNKNOWN. OpenAPI is unfortunately not able to correctly name the model
 */
export type ThreeDSVersion = string

export type TerminalType = (typeof TerminalType)[keyof typeof TerminalType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TerminalType = {
    AUTOMATED_DISPENSING_MACHINE: 'AUTOMATED_DISPENSING_MACHINE',
    SELF_SERVICE_TERMINAL: 'SELF_SERVICE_TERMINAL',
    LIMITED_AMOUNT_TERMINAL: 'LIMITED_AMOUNT_TERMINAL',
    IN_FLIGHT_TERMINAL: 'IN_FLIGHT_TERMINAL',
    ELECTRONIC_COMMERCE: 'ELECTRONIC_COMMERCE',
    TRANSPONDER: 'TRANSPONDER',
    MOBILE_POS: 'MOBILE_POS',
} as const

export type TerminalOwner = (typeof TerminalOwner)[keyof typeof TerminalOwner]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TerminalOwner = {
    MERCHANT: 'MERCHANT',
    CARD_HOLDER: 'CARD_HOLDER',
} as const

export type TerminalLocation = (typeof TerminalLocation)[keyof typeof TerminalLocation]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TerminalLocation = {
    CARD_ACCEPTOR_FACILITY: 'CARD_ACCEPTOR_FACILITY',
    REMOTE_FACILITY: 'REMOTE_FACILITY',
} as const

export interface Terminal {
    attended?: boolean
    cardCaptureCapabilities?: boolean
    location?: TerminalLocation
    owner?: TerminalOwner
    type?: TerminalType
}

export type TargetType = (typeof TargetType)[keyof typeof TargetType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TargetType = {
    CARD: 'CARD',
    WALLET: 'WALLET',
    EMPLOYEE: 'EMPLOYEE',
} as const

export interface Target {
    cardId?: string
    companyId?: string
    employeeId?: string
    id?: string
    type?: TargetType
    walletId?: string
}

export interface StrictCompanyMerchantBlocklistResponse {
    companyId: string
    deleted?: string
    exactEntry: boolean
    id: string
    merchantId?: string
    merchantNameRegex?: string
    notes?: string
    updated?: string
}

export interface StrictCompanyMerchantBlocklistRequest {
    companyId: string
    exactEntry: boolean
    id?: string
    merchantId?: string
    merchantNameRegex?: string
    notes?: string
}

export type SourceType = (typeof SourceType)[keyof typeof SourceType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SourceType = {
    BANK_TRANSFER: 'BANK_TRANSFER',
    HEAD_OFFICE: 'HEAD_OFFICE',
} as const

export interface Source {
    id?: string
    type?: SourceType
}

export type ScaTransactionModelType =
    (typeof ScaTransactionModelType)[keyof typeof ScaTransactionModelType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ScaTransactionModelType = {
    PAYMENT: 'PAYMENT',
    RECURRING: 'RECURRING',
} as const

export interface ScaTransactionModel {
    cardId: string
    maskedPan: string
    timestamp: string
    type: ScaTransactionModelType
    value?: Money
}

export interface ScaMerchantModel {
    country?: string
    description?: string
    name?: string
    url?: string
}

export interface ScaRequestResponseModel {
    merchant: ScaMerchantModel
    requestId: string
    transaction: ScaTransactionModel
}

export type RuleResultStatus = (typeof RuleResultStatus)[keyof typeof RuleResultStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RuleResultStatus = {
    FULLY_TRIGGERED: 'FULLY_TRIGGERED',
    PARTIALLY_TRIGGERED: 'PARTIALLY_TRIGGERED',
    NOT_TRIGGERED: 'NOT_TRIGGERED',
    FAILURE: 'FAILURE',
} as const

export interface RuleInfo {
    description: string
    name: string
}

export interface RuleResult {
    info: RuleInfo
    score: number
    status: RuleResultStatus
}

export type RuleFraudResultCategory =
    (typeof RuleFraudResultCategory)[keyof typeof RuleFraudResultCategory]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RuleFraudResultCategory = {
    CARD_BALANCE_ALTERATION: 'CARD_BALANCE_ALTERATION',
    WALLET_BALANCE_ALTERATION: 'WALLET_BALANCE_ALTERATION',
    AML: 'AML',
    CARD_FRAUD: 'CARD_FRAUD',
    MANUAL_KEY_ENTRY: 'MANUAL_KEY_ENTRY',
    CHIP_AND_SIGNATURE: 'CHIP_AND_SIGNATURE',
    COMPLIANCE: 'COMPLIANCE',
    BILL_FRAUD: 'BILL_FRAUD',
    PERSONAL_TRANSFER_FRAUD: 'PERSONAL_TRANSFER_FRAUD',
    EMPLOYEE_BANK_TRANSFER_FRAUD: 'EMPLOYEE_BANK_TRANSFER_FRAUD',
    WALLET_WITHDRAWAL_FRAUD: 'WALLET_WITHDRAWAL_FRAUD',
    DIRECT_DEBIT_FRAUD: 'DIRECT_DEBIT_FRAUD',
} as const

export interface RuleFraudResult {
    category: RuleFraudResultCategory
    failure: boolean
    fraud: boolean
    info: RuleInfo
    score?: number
}

export interface PostalAddress {
    addressLine1?: string
    addressLine2?: string
    country?: string
    locality?: string
    postalCode?: string
    region?: string
}

export type PosCardInputMethod = (typeof PosCardInputMethod)[keyof typeof PosCardInputMethod]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PosCardInputMethod = {
    UNSPECIFIED: 'UNSPECIFIED',
    MANUAL: 'MANUAL',
    MAGNETIC_STRIPE: 'MAGNETIC_STRIPE',
    MAGNETIC_STRIPE_AUTO_ENTRY: 'MAGNETIC_STRIPE_AUTO_ENTRY',
    BARCODE: 'BARCODE',
    OCR: 'OCR',
    EMV_CONTACT: 'EMV_CONTACT',
    PAN_KEY_ENTRY: 'PAN_KEY_ENTRY',
    EMV_CONTACTLESS: 'EMV_CONTACTLESS',
    E_COMMERCE: 'E_COMMERCE',
    E_COMMERCE_WITH_EMV_CRYPTOGRAM: 'E_COMMERCE_WITH_EMV_CRYPTOGRAM',
    CONTACTLESS_MAGNETIC_STRIPE: 'CONTACTLESS_MAGNETIC_STRIPE',
    ACCOUNT_DATA_ON_FILE: 'ACCOUNT_DATA_ON_FILE',
    KEY_ENTERED_BY_ACQUIRER: 'KEY_ENTERED_BY_ACQUIRER',
    MICR_READER: 'MICR_READER',
    QR_CODE: 'QR_CODE',
} as const

export interface Pos {
    cardInputMethod?: PosCardInputMethod
}

export type PersonalTransferTransactionType =
    (typeof PersonalTransferTransactionType)[keyof typeof PersonalTransferTransactionType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PersonalTransferTransactionType = {
    REIMBURSEMENT: 'REIMBURSEMENT',
    REPAYMENT: 'REPAYMENT',
} as const

export type PersonalTransferTransactionStatus =
    (typeof PersonalTransferTransactionStatus)[keyof typeof PersonalTransferTransactionStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PersonalTransferTransactionStatus = {
    REQUESTED: 'REQUESTED',
    AUTHORIZED: 'AUTHORIZED',
    SETTLED: 'SETTLED',
    FAILED: 'FAILED',
} as const

export type PersonalTransferTransactionCompanyKycScore =
    (typeof PersonalTransferTransactionCompanyKycScore)[keyof typeof PersonalTransferTransactionCompanyKycScore]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PersonalTransferTransactionCompanyKycScore = {
    LOW: 'LOW',
    MEDIUM_LOW: 'MEDIUM_LOW',
    MEDIUM: 'MEDIUM',
    MEDIUM_HIGH: 'MEDIUM_HIGH',
    HIGH: 'HIGH',
    PASS: 'PASS',
    FAIL: 'FAIL',
    NOT_ASSIGNED: 'NOT_ASSIGNED',
} as const

export interface PersonalTransferTransaction {
    amount?: number
    amountEur?: number
    authorizedAt?: string
    cardFingerprint?: string
    cardIssuerCountry?: string
    companyId?: string
    companyKycScore?: PersonalTransferTransactionCompanyKycScore
    createdDate?: string
    currency?: string
    employeeId?: string
    failedAt?: string
    id?: string
    personalTransferCreatedAt?: string
    personalTransferUpdatedAt?: string
    requestedAt?: string
    settledAt?: string
    status?: PersonalTransferTransactionStatus
    type?: PersonalTransferTransactionType
    updatedDate?: string
}

export interface Money {
    currency?: string
    value?: number
}

export type MkeMerchantStatus = (typeof MkeMerchantStatus)[keyof typeof MkeMerchantStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MkeMerchantStatus = {
    WHITELISTED: 'WHITELISTED',
    INVESTIGATING: 'INVESTIGATING',
    BLACKLISTED: 'BLACKLISTED',
} as const

export interface MkeMerchant {
    createdDate?: string
    id: string
    investigating: boolean
    mcc?: string
    name?: string
    postalAddress: PostalAddress
    status: MkeMerchantStatus
    whitelistedCards: string[]
}

export interface MerchantBlacklistResponse {
    deleted?: string
    exactEntry: boolean
    id: string
    merchantId?: string
    notes?: string
    regex?: string
    threshold?: number
    updated?: string
}

export interface MerchantBlacklistRequest {
    exactEntry: boolean
    id?: string
    merchantId?: string
    notes?: string
    regex?: string
    threshold?: number
}

export interface Merchant {
    address?: PostalAddress
    contactInformation?: string
    id?: string
    mcc?: string
    name?: string
    otherName?: string
    taxId?: string
    telephoneNumber?: string
    url?: string
}

export type MastercardVirtualWalletProgram =
    (typeof MastercardVirtualWalletProgram)[keyof typeof MastercardVirtualWalletProgram]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MastercardVirtualWalletProgram = {
    MASTERS_PASS: 'MASTERS_PASS',
    APPLE_PAY: 'APPLE_PAY',
    GOOGLE_PAY: 'GOOGLE_PAY',
    SAMSUNG_PAY: 'SAMSUNG_PAY',
    MERCHANT_TOKENIZATION_PROGRAM: 'MERCHANT_TOKENIZATION_PROGRAM',
} as const

export interface Mastercard {
    de22?: De22
    de38?: De38
    de61?: De61
    virtualWalletProgram?: MastercardVirtualWalletProgram
}

export type ManualFraudRequestInvestigationStatus =
    (typeof ManualFraudRequestInvestigationStatus)[keyof typeof ManualFraudRequestInvestigationStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ManualFraudRequestInvestigationStatus = {
    NEW: 'NEW',
    FALSE_POSITIVE: 'FALSE_POSITIVE',
    REQ_INVESTIGATION: 'REQ_INVESTIGATION',
    FRAUD: 'FRAUD',
    NOT_REQUIRED: 'NOT_REQUIRED',
} as const

export type ManualFraudRequestCategory =
    (typeof ManualFraudRequestCategory)[keyof typeof ManualFraudRequestCategory]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ManualFraudRequestCategory = {
    CARD_BALANCE_ALTERATION: 'CARD_BALANCE_ALTERATION',
    WALLET_BALANCE_ALTERATION: 'WALLET_BALANCE_ALTERATION',
    AML: 'AML',
    CARD_FRAUD: 'CARD_FRAUD',
    MANUAL_KEY_ENTRY: 'MANUAL_KEY_ENTRY',
    CHIP_AND_SIGNATURE: 'CHIP_AND_SIGNATURE',
    COMPLIANCE: 'COMPLIANCE',
    BILL_FRAUD: 'BILL_FRAUD',
    PERSONAL_TRANSFER_FRAUD: 'PERSONAL_TRANSFER_FRAUD',
    EMPLOYEE_BANK_TRANSFER_FRAUD: 'EMPLOYEE_BANK_TRANSFER_FRAUD',
    WALLET_WITHDRAWAL_FRAUD: 'WALLET_WITHDRAWAL_FRAUD',
    DIRECT_DEBIT_FRAUD: 'DIRECT_DEBIT_FRAUD',
} as const

export interface ManualFraudRequest {
    category: ManualFraudRequestCategory
    investigationStatus?: ManualFraudRequestInvestigationStatus
    notes?: string
}

export type ManualExpenseRequestInvestigationStatus =
    (typeof ManualExpenseRequestInvestigationStatus)[keyof typeof ManualExpenseRequestInvestigationStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ManualExpenseRequestInvestigationStatus = {
    NEW: 'NEW',
    FALSE_POSITIVE: 'FALSE_POSITIVE',
    REQ_INVESTIGATION: 'REQ_INVESTIGATION',
    FRAUD: 'FRAUD',
    NOT_REQUIRED: 'NOT_REQUIRED',
} as const

export interface ManualExpenseRequest {
    investigationStatus?: ManualExpenseRequestInvestigationStatus
    notes?: string
}

export interface InvestigatingRequest {
    investigating: boolean
}

export interface InterchangeData {
    amountBillingCurrency: Money
    amountSettlementCurrency: Money
    amountTransactionCurrency: Money
}

export interface HandleScaRequestModel {
    callbackUrl: string
    customerNumber: string
    merchant?: ScaMerchantModel
    requestId: string
    transaction: ScaTransactionModel
}

export interface GroupedCase {
    caseCount: number
    companyId?: string
    mostRecentCaseDate: string
}

export type FraudResultInvestigationStatus =
    (typeof FraudResultInvestigationStatus)[keyof typeof FraudResultInvestigationStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FraudResultInvestigationStatus = {
    NEW: 'NEW',
    FALSE_POSITIVE: 'FALSE_POSITIVE',
    REQ_INVESTIGATION: 'REQ_INVESTIGATION',
    FRAUD: 'FRAUD',
    NOT_REQUIRED: 'NOT_REQUIRED',
} as const

export type FraudResultCategory = (typeof FraudResultCategory)[keyof typeof FraudResultCategory]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FraudResultCategory = {
    CARD_BALANCE_ALTERATION: 'CARD_BALANCE_ALTERATION',
    WALLET_BALANCE_ALTERATION: 'WALLET_BALANCE_ALTERATION',
    AML: 'AML',
    CARD_FRAUD: 'CARD_FRAUD',
    MANUAL_KEY_ENTRY: 'MANUAL_KEY_ENTRY',
    CHIP_AND_SIGNATURE: 'CHIP_AND_SIGNATURE',
    COMPLIANCE: 'COMPLIANCE',
    BILL_FRAUD: 'BILL_FRAUD',
    PERSONAL_TRANSFER_FRAUD: 'PERSONAL_TRANSFER_FRAUD',
    EMPLOYEE_BANK_TRANSFER_FRAUD: 'EMPLOYEE_BANK_TRANSFER_FRAUD',
    WALLET_WITHDRAWAL_FRAUD: 'WALLET_WITHDRAWAL_FRAUD',
    DIRECT_DEBIT_FRAUD: 'DIRECT_DEBIT_FRAUD',
} as const

export interface FraudResult {
    category: FraudResultCategory
    investigationStatus?: FraudResultInvestigationStatus
    ruleResults: RuleResult[]
}

export type FraudTransactionFamily =
    (typeof FraudTransactionFamily)[keyof typeof FraudTransactionFamily]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FraudTransactionFamily = {
    CARD: 'CARD',
    BILL: 'BILL',
    PERSONAL_TRANSFER: 'PERSONAL_TRANSFER',
    WALLET_WITHDRAWAL: 'WALLET_WITHDRAWAL',
    EMPLOYEE_BANK_TRANSFER: 'EMPLOYEE_BANK_TRANSFER',
} as const

export interface Fraud {
    category?: string
    companyId?: string
    createdDate?: string
    fraudId?: string
    investigationOfficer?: string
    investigationStatus?: string
    notes?: string
    transactionFamily?: FraudTransactionFamily
    transactionId?: string
    updatedDate?: string
}

export type FeeType = (typeof FeeType)[keyof typeof FeeType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FeeType = {
    DOMESTIC: 'DOMESTIC',
    NON_DOMESTIC: 'NON_DOMESTIC',
    FX: 'FX',
    OTHER: 'OTHER',
    UNKNOWN: 'UNKNOWN',
} as const

export interface Fee {
    fee?: Money
    type?: FeeType
}

export type EmployeeBankTransferTransactionStatus =
    (typeof EmployeeBankTransferTransactionStatus)[keyof typeof EmployeeBankTransferTransactionStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EmployeeBankTransferTransactionStatus = {
    REQUESTED: 'REQUESTED',
    AUTHORIZED: 'AUTHORIZED',
    SCREENED: 'SCREENED',
    PENDING_SCREENING_REVIEW: 'PENDING_SCREENING_REVIEW',
    INITIATED: 'INITIATED',
    FAILED: 'FAILED',
    SENT: 'SENT',
    SETTLED: 'SETTLED',
    PAYMENT_RETURNED: 'PAYMENT_RETURNED',
    REFUNDED: 'REFUNDED',
} as const

export type EmployeeBankTransferTransactionCompanyKycScore =
    (typeof EmployeeBankTransferTransactionCompanyKycScore)[keyof typeof EmployeeBankTransferTransactionCompanyKycScore]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EmployeeBankTransferTransactionCompanyKycScore = {
    LOW: 'LOW',
    MEDIUM_LOW: 'MEDIUM_LOW',
    MEDIUM: 'MEDIUM',
    MEDIUM_HIGH: 'MEDIUM_HIGH',
    HIGH: 'HIGH',
    PASS: 'PASS',
    FAIL: 'FAIL',
    NOT_ASSIGNED: 'NOT_ASSIGNED',
} as const

export interface EmployeeBankTransferTransaction {
    accountNumber?: string
    amount?: number
    amountEur?: number
    bankCode?: string
    bic?: string
    companyId?: string
    companyKycScore?: EmployeeBankTransferTransactionCompanyKycScore
    countryCode?: string
    createdDate?: string
    currency?: string
    employeeBankTransferCreatedAt?: string
    employeeBankTransferUpdatedAt?: string
    employeeId?: string
    failedAt?: string
    iban?: string
    id?: string
    refundedAt?: string
    requestedAt?: string
    settledAt?: string
    status?: EmployeeBankTransferTransactionStatus
    updatedDate?: string
    version?: number
}

export type ElectronicCommerceSecurityLevelUcafCollection =
    (typeof ElectronicCommerceSecurityLevelUcafCollection)[keyof typeof ElectronicCommerceSecurityLevelUcafCollection]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ElectronicCommerceSecurityLevelUcafCollection = {
    UNUSED: 'UNUSED',
    SUPPORTED: 'SUPPORTED',
    ENABLED: 'ENABLED',
    ENABLED_PROCESSED: 'ENABLED_PROCESSED',
    RESERVED_FUTURE: 'RESERVED_FUTURE',
    ISSUER_RISK: 'ISSUER_RISK',
    MERCHANT_RISK: 'MERCHANT_RISK',
    PARTIAL_OR_RECURRING: 'PARTIAL_OR_RECURRING',
    UNKNOWN: 'UNKNOWN',
} as const

export type ElectronicCommerceSecurityLevelSecurityProtocol =
    (typeof ElectronicCommerceSecurityLevelSecurityProtocol)[keyof typeof ElectronicCommerceSecurityLevelSecurityProtocol]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ElectronicCommerceSecurityLevelSecurityProtocol = {
    RESERVED: 'RESERVED',
    CHANNEL: 'CHANNEL',
    NONE: 'NONE',
    UNKNOWN: 'UNKNOWN',
} as const

export type ElectronicCommerceSecurityLevelCardholderAuthentication =
    (typeof ElectronicCommerceSecurityLevelCardholderAuthentication)[keyof typeof ElectronicCommerceSecurityLevelCardholderAuthentication]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ElectronicCommerceSecurityLevelCardholderAuthentication = {
    CERT_UNUSED: 'CERT_UNUSED',
    MASTERPASS: 'MASTERPASS',
    DSR_PAYMENT: 'DSR_PAYMENT',
    UNKNOWN: 'UNKNOWN',
} as const

export interface ElectronicCommerceSecurityLevel {
    cardholderAuthentication?: ElectronicCommerceSecurityLevelCardholderAuthentication
    securityProtocol?: ElectronicCommerceSecurityLevelSecurityProtocol
    ucafCollection?: ElectronicCommerceSecurityLevelUcafCollection
}

export interface DisabledRulesEntry {
    cardId: string
    expiresAt?: string
    id: string
    merchantId?: string
    merchantName?: string
    ruleName?: string
}

export type DetectedFraudTransactionFamily =
    (typeof DetectedFraudTransactionFamily)[keyof typeof DetectedFraudTransactionFamily]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DetectedFraudTransactionFamily = {
    CARD: 'CARD',
    BILL: 'BILL',
    PERSONAL_TRANSFER: 'PERSONAL_TRANSFER',
    WALLET_WITHDRAWAL: 'WALLET_WITHDRAWAL',
    EMPLOYEE_BANK_TRANSFER: 'EMPLOYEE_BANK_TRANSFER',
} as const

export interface DetectedFraud {
    categories?: string[]
    companyId?: string
    createdAt?: string
    id: string
    investigationOfficer?: string
    investigationStatus?: string
    notes?: string
    transactionFamily: DetectedFraudTransactionFamily
    transactionId?: string
    triggeredRules?: TriggeredRuleWithScore[]
    updatedAt?: string
}

export type De61TransactionType = (typeof De61TransactionType)[keyof typeof De61TransactionType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const De61TransactionType = {
    MAIL: 'MAIL',
    PHONE: 'PHONE',
    RECURRING: 'RECURRING',
    ELECTRONIC: 'ELECTRONIC',
} as const

export type De61TransactionStatus =
    (typeof De61TransactionStatus)[keyof typeof De61TransactionStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const De61TransactionStatus = {
    NORMAL_REQUEST: 'NORMAL_REQUEST',
    SECURECODE: 'SECURECODE',
    ATM_INSTALLMENT: 'ATM_INSTALLMENT',
    PREAUTHORIZATION: 'PREAUTHORIZATION',
    ATC_UPDATE: 'ATC_UPDATE',
    INQUIRY: 'INQUIRY',
    NOTIFICATION: 'NOTIFICATION',
} as const

export type De61TransactionSecurity =
    (typeof De61TransactionSecurity)[keyof typeof De61TransactionSecurity]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const De61TransactionSecurity = {
    NO_CONCERN: 'NO_CONCERN',
    SUSPECTED_FRAUD: 'SUSPECTED_FRAUD',
    ID_VERIFIED: 'ID_VERIFIED',
} as const

export interface De61Transaction {
    duration?: De61TransactionDuration
    security?: De61TransactionSecurity
    status?: De61TransactionStatus
    type?: De61TransactionType
}

export type De61TransactionDurationUnitsItemDuration = {
    isNegative?: boolean
    isPositive?: boolean
    isZero?: boolean
    nano?: number
    seconds?: number
}

export type De61TransactionDurationUnitsItem = {
    duration?: De61TransactionDurationUnitsItemDuration
    isDateBased?: boolean
    isDurationEstimated?: boolean
    isTimeBased?: boolean
}

export type De61TransactionDurationChronology = {
    calendarType?: string
    id?: string
    isIsoBased?: boolean
}

export type De61TransactionDuration = {
    chronology?: De61TransactionDurationChronology
    days?: number
    isNegative?: boolean
    isZero?: boolean
    months?: number
    units?: De61TransactionDurationUnitsItem[]
    years?: number
}

export interface De61Cardholder {
    authentication?: Authentication
    authenticationCapabilities?: AuthenticationCapabilities
    present?: boolean
}

export interface De61Card {
    inputCapabilities?: CardInputCapabilities
    outputCapabilities?: CardOutputCapabilities
    present?: boolean
}

export type De61CountryCode = (typeof De61CountryCode)[keyof typeof De61CountryCode]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const De61CountryCode = {
    UNDEFINED: 'UNDEFINED',
    AC: 'AC',
    AD: 'AD',
    AE: 'AE',
    AF: 'AF',
    AG: 'AG',
    AI: 'AI',
    AL: 'AL',
    AM: 'AM',
    AN: 'AN',
    AO: 'AO',
    AQ: 'AQ',
    AR: 'AR',
    AS: 'AS',
    AT: 'AT',
    AU: 'AU',
    AW: 'AW',
    AX: 'AX',
    AZ: 'AZ',
    BA: 'BA',
    BB: 'BB',
    BD: 'BD',
    BE: 'BE',
    BF: 'BF',
    BG: 'BG',
    BH: 'BH',
    BI: 'BI',
    BJ: 'BJ',
    BL: 'BL',
    BM: 'BM',
    BN: 'BN',
    BO: 'BO',
    BQ: 'BQ',
    BR: 'BR',
    BS: 'BS',
    BT: 'BT',
    BU: 'BU',
    BV: 'BV',
    BW: 'BW',
    BY: 'BY',
    BZ: 'BZ',
    CA: 'CA',
    CC: 'CC',
    CD: 'CD',
    CF: 'CF',
    CG: 'CG',
    CH: 'CH',
    CI: 'CI',
    CK: 'CK',
    CL: 'CL',
    CM: 'CM',
    CN: 'CN',
    CO: 'CO',
    CP: 'CP',
    CR: 'CR',
    CS: 'CS',
    CU: 'CU',
    CV: 'CV',
    CW: 'CW',
    CX: 'CX',
    CY: 'CY',
    CZ: 'CZ',
    DE: 'DE',
    DG: 'DG',
    DJ: 'DJ',
    DK: 'DK',
    DM: 'DM',
    DO: 'DO',
    DZ: 'DZ',
    EA: 'EA',
    EC: 'EC',
    EE: 'EE',
    EG: 'EG',
    EH: 'EH',
    ER: 'ER',
    ES: 'ES',
    ET: 'ET',
    EU: 'EU',
    EZ: 'EZ',
    FI: 'FI',
    FJ: 'FJ',
    FK: 'FK',
    FM: 'FM',
    FO: 'FO',
    FR: 'FR',
    FX: 'FX',
    GA: 'GA',
    GB: 'GB',
    GD: 'GD',
    GE: 'GE',
    GF: 'GF',
    GG: 'GG',
    GH: 'GH',
    GI: 'GI',
    GL: 'GL',
    GM: 'GM',
    GN: 'GN',
    GP: 'GP',
    GQ: 'GQ',
    GR: 'GR',
    GS: 'GS',
    GT: 'GT',
    GU: 'GU',
    GW: 'GW',
    GY: 'GY',
    HK: 'HK',
    HM: 'HM',
    HN: 'HN',
    HR: 'HR',
    HT: 'HT',
    HU: 'HU',
    IC: 'IC',
    ID: 'ID',
    IE: 'IE',
    IL: 'IL',
    IM: 'IM',
    IN: 'IN',
    IO: 'IO',
    IQ: 'IQ',
    IR: 'IR',
    IS: 'IS',
    IT: 'IT',
    JE: 'JE',
    JM: 'JM',
    JO: 'JO',
    JP: 'JP',
    KE: 'KE',
    KG: 'KG',
    KH: 'KH',
    KI: 'KI',
    KM: 'KM',
    KN: 'KN',
    KP: 'KP',
    KR: 'KR',
    KW: 'KW',
    KY: 'KY',
    KZ: 'KZ',
    LA: 'LA',
    LB: 'LB',
    LC: 'LC',
    LI: 'LI',
    LK: 'LK',
    LR: 'LR',
    LS: 'LS',
    LT: 'LT',
    LU: 'LU',
    LV: 'LV',
    LY: 'LY',
    MA: 'MA',
    MC: 'MC',
    MD: 'MD',
    ME: 'ME',
    MF: 'MF',
    MG: 'MG',
    MH: 'MH',
    MK: 'MK',
    ML: 'ML',
    MM: 'MM',
    MN: 'MN',
    MO: 'MO',
    MP: 'MP',
    MQ: 'MQ',
    MR: 'MR',
    MS: 'MS',
    MT: 'MT',
    MU: 'MU',
    MV: 'MV',
    MW: 'MW',
    MX: 'MX',
    MY: 'MY',
    MZ: 'MZ',
    NA: 'NA',
    NC: 'NC',
    NE: 'NE',
    NF: 'NF',
    NG: 'NG',
    NI: 'NI',
    NL: 'NL',
    NO: 'NO',
    NP: 'NP',
    NR: 'NR',
    NT: 'NT',
    NU: 'NU',
    NZ: 'NZ',
    OM: 'OM',
    PA: 'PA',
    PE: 'PE',
    PF: 'PF',
    PG: 'PG',
    PH: 'PH',
    PK: 'PK',
    PL: 'PL',
    PM: 'PM',
    PN: 'PN',
    PR: 'PR',
    PS: 'PS',
    PT: 'PT',
    PW: 'PW',
    PY: 'PY',
    QA: 'QA',
    RE: 'RE',
    RO: 'RO',
    RS: 'RS',
    RU: 'RU',
    RW: 'RW',
    SA: 'SA',
    SB: 'SB',
    SC: 'SC',
    SD: 'SD',
    SE: 'SE',
    SF: 'SF',
    SG: 'SG',
    SH: 'SH',
    SI: 'SI',
    SJ: 'SJ',
    SK: 'SK',
    SL: 'SL',
    SM: 'SM',
    SN: 'SN',
    SO: 'SO',
    SR: 'SR',
    SS: 'SS',
    ST: 'ST',
    SU: 'SU',
    SV: 'SV',
    SX: 'SX',
    SY: 'SY',
    SZ: 'SZ',
    TA: 'TA',
    TC: 'TC',
    TD: 'TD',
    TF: 'TF',
    TG: 'TG',
    TH: 'TH',
    TJ: 'TJ',
    TK: 'TK',
    TL: 'TL',
    TM: 'TM',
    TN: 'TN',
    TO: 'TO',
    TP: 'TP',
    TR: 'TR',
    TT: 'TT',
    TV: 'TV',
    TW: 'TW',
    TZ: 'TZ',
    UA: 'UA',
    UG: 'UG',
    UK: 'UK',
    UM: 'UM',
    US: 'US',
    UY: 'UY',
    UZ: 'UZ',
    VA: 'VA',
    VC: 'VC',
    VE: 'VE',
    VG: 'VG',
    VI: 'VI',
    VN: 'VN',
    VU: 'VU',
    WF: 'WF',
    WS: 'WS',
    XI: 'XI',
    XU: 'XU',
    XK: 'XK',
    YE: 'YE',
    YT: 'YT',
    YU: 'YU',
    ZA: 'ZA',
    ZM: 'ZM',
    ZR: 'ZR',
    ZW: 'ZW',
} as const

export interface De61 {
    card?: De61Card
    cardholder?: De61Cardholder
    countryCode?: De61CountryCode
    postalCode?: string
    reference?: string
    terminal?: Terminal
    transaction?: De61Transaction
}

export interface De38 {
    reference?: string
}

export type De22PanEntryMethod = (typeof De22PanEntryMethod)[keyof typeof De22PanEntryMethod]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const De22PanEntryMethod = {
    MANUAL_KEY_ENTRY: 'MANUAL_KEY_ENTRY',
} as const

export interface De22 {
    panEntryMethod?: De22PanEntryMethod
    reference?: string
}

export interface DataResponseFraud {
    data: Fraud
}

export type CustomIdentifierType = (typeof CustomIdentifierType)[keyof typeof CustomIdentifierType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CustomIdentifierType = {
    CUSORD: 'CUSORD',
    CUSTPO: 'CUSTPO',
    FOLNUM: 'FOLNUM',
    INVACT: 'INVACT',
    OTHER1: 'OTHER1',
    PMTREF: 'PMTREF',
    PRCHID: 'PRCHID',
    RECLOC: 'RECLOC',
    RESNUM: 'RESNUM',
    RNTAGR: 'RNTAGR',
    SUPINV: 'SUPINV',
    SUPORD: 'SUPORD',
    TKTNUM: 'TKTNUM',
    TRANID: 'TRANID',
    TRKNUM: 'TRKNUM',
    UNKNOWN: 'UNKNOWN',
} as const

export interface CustomIdentifier {
    detail?: string
    type?: CustomIdentifierType
}

export interface CoroutineContext {
    [key: string]: any
}

export interface ContinuationObject {
    context: CoroutineContext
}

export type CardholderAuthenticationMethod =
    (typeof CardholderAuthenticationMethod)[keyof typeof CardholderAuthenticationMethod]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CardholderAuthenticationMethod = {
    NOT_AUTHENTICATED: 'NOT_AUTHENTICATED',
    PIN: 'PIN',
    ELECTRONIC_SIGNATURE_ANALYSIS: 'ELECTRONIC_SIGNATURE_ANALYSIS',
    BIOMETRICS: 'BIOMETRICS',
    BIOGRAPHIC: 'BIOGRAPHIC',
    MANUAL_SIGNATURE_VERIFICATION: 'MANUAL_SIGNATURE_VERIFICATION',
    OTHER_MANUAL_VERIFICATION: 'OTHER_MANUAL_VERIFICATION',
    UNKNOWN: 'UNKNOWN',
    WITH_3D_SECURE: 'WITH_3D_SECURE',
} as const

export type CardholderAuthenticationEntity =
    (typeof CardholderAuthenticationEntity)[keyof typeof CardholderAuthenticationEntity]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CardholderAuthenticationEntity = {
    NOT_AUTHENTICATED: 'NOT_AUTHENTICATED',
    CHIP_CARD: 'CHIP_CARD',
    CARD_ACCEPTANCE_DEVICE: 'CARD_ACCEPTANCE_DEVICE',
    AUTHORISING_AGENT: 'AUTHORISING_AGENT',
    MERCHANT: 'MERCHANT',
    OTHER: 'OTHER',
    CARDHOLDER_DEVICE: 'CARDHOLDER_DEVICE',
    UNKNOWN: 'UNKNOWN',
} as const

export interface CardholderAuthentication {
    entity: CardholderAuthenticationEntity
    method: CardholderAuthenticationMethod
}

export interface Cardholder {
    authentications?: CardholderAuthentication[]
}

export interface CardOutputCapabilities {
    icc?: boolean
    msw?: boolean
}

export interface CardInputCapabilities {
    barcode?: boolean
    contactlessIcc?: boolean
    contactlessMsr?: boolean
    icc?: boolean
    msr?: boolean
    ocr?: boolean
    pke?: boolean
}

export type BillTransactionStatus =
    (typeof BillTransactionStatus)[keyof typeof BillTransactionStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BillTransactionStatus = {
    RECEIVED: 'RECEIVED',
    PROCESSING_PARSING: 'PROCESSING_PARSING',
    PARSED: 'PARSED',
    MISSING_INFORMATION: 'MISSING_INFORMATION',
    PENDING_SUPPLIER_VERIFICATION: 'PENDING_SUPPLIER_VERIFICATION',
    PENDING_APPROVAL: 'PENDING_APPROVAL',
    READY: 'READY',
    SCHEDULED: 'SCHEDULED',
    PREPARING: 'PREPARING',
    PAYMENT_IN_PROGRESS: 'PAYMENT_IN_PROGRESS',
    PAID: 'PAID',
    MARKED_AS_PAID: 'MARKED_AS_PAID',
    REJECTED_BY_USER: 'REJECTED_BY_USER',
    VOIDED_BY_USER: 'VOIDED_BY_USER',
    FAILED_INTERNAL_VALIDATION: 'FAILED_INTERNAL_VALIDATION',
    FAILED_PAYMENT: 'FAILED_PAYMENT',
    FAILED: 'FAILED',
} as const

export type BillTransactionCompanyKycScore =
    (typeof BillTransactionCompanyKycScore)[keyof typeof BillTransactionCompanyKycScore]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BillTransactionCompanyKycScore = {
    LOW: 'LOW',
    MEDIUM_LOW: 'MEDIUM_LOW',
    MEDIUM: 'MEDIUM',
    MEDIUM_HIGH: 'MEDIUM_HIGH',
    HIGH: 'HIGH',
    PASS: 'PASS',
    FAIL: 'FAIL',
    NOT_ASSIGNED: 'NOT_ASSIGNED',
} as const

export interface BillTransaction {
    billAmount?: number
    billAmountEur?: number
    billCreated?: string
    billCurrency?: string
    billEmitted?: string
    companyId?: string
    companyKycScore?: BillTransactionCompanyKycScore
    createdDate?: string
    departmentId?: string
    due?: string
    emailId?: string
    emailSource?: string
    employeeId?: string
    failed?: string
    id?: string
    paid?: string
    projectedAmount?: number
    projectedAmountEur?: number
    projectedCurrency?: string
    scheduled?: string
    status?: BillTransactionStatus
    supplierBankCountry?: string
    supplierCountry?: string
    supplierId?: string
    updatedDate?: string
}

export interface AuthenticationCapabilities {
    biographic?: boolean
    biometrics?: boolean
    pin?: boolean
    signature?: boolean
}

export type AuthenticationMethod = (typeof AuthenticationMethod)[keyof typeof AuthenticationMethod]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AuthenticationMethod = {
    PIN: 'PIN',
    SIGNATURE: 'SIGNATURE',
    BIOMETRICS: 'BIOMETRICS',
    BIOGRAPHIC: 'BIOGRAPHIC',
} as const

export type AuthenticationEntity = (typeof AuthenticationEntity)[keyof typeof AuthenticationEntity]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AuthenticationEntity = {
    ICC: 'ICC',
    TERMINAL: 'TERMINAL',
    AGENT: 'AGENT',
    MERCHANT: 'MERCHANT',
    OTHER: 'OTHER',
} as const

export interface Authentication {
    entity?: AuthenticationEntity
    method?: AuthenticationMethod
}

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
    config: any,
    args: infer P
) => any
    ? P
    : never

/**
 * @summary Publish the complete set of triggered rules to the EBC topic
 */
export const jobBackfillCompleteTriggeredRules = (
    continuationObject: ContinuationObject,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<JobBackfillCompleteTriggeredRules>(
        {
            url: `/admin/ebc-backfill/triggered-rules`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: continuationObject,
        },
        options
    )
}

/**
 * @summary Reprocess messages in the billStatusChange DLQ
 */
export const processBillStatusChangeKafkaSqsDlq = (
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        { url: `/admin/kafka-dlq/bill-status-change/process-sqs`, method: 'post' },
        options
    )
}

/**
 * @summary Reprocess messages in the cardReimbursement DLQ
 */
export const processCardReimbursementKafkaSqsDlq = (
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        { url: `/admin/kafka-dlq/card-reimbursement/process-sqs`, method: 'post' },
        options
    )
}

/**
 * @summary Reprocess messages in the employeeBankTransferStatusChange DLQ
 */
export const processEmployeeBankTransferStatusChangeKafkaSqsDlq = (
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        {
            url: `/admin/kafka-dlq/employee-bank-transfer-status-change/process-sqs`,
            method: 'post',
        },
        options
    )
}

/**
 * @summary Reprocess messages in the receiptEvent DLQ
 */
export const processReceiptEventKafkaSqsDlq = (options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<unknown>(
        { url: `/admin/kafka-dlq/receipt-event/process-sqs`, method: 'post' },
        options
    )
}

/**
 * @summary Reprocess messages in the transaction DLQ
 */
export const processTransactionKafkaSqsDlq = (options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<unknown>(
        { url: `/admin/kafka-dlq/transaction/process-sqs`, method: 'post' },
        options
    )
}

/**
 * @summary Reprocess messages in the walletLoad DLQ
 */
export const processWalletLoadKafkaSqsDlq = (options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<unknown>(
        { url: `/admin/kafka-dlq/wallet-load/process-sqs`, method: 'post' },
        options
    )
}

/**
 * @summary Reprocess messages in the walletWithdrawal DLQ
 */
export const processWalletWithdrawalKafkaSqsDlq = (
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        { url: `/admin/kafka-dlq/wallet-withdrawal/process-sqs`, method: 'post' },
        options
    )
}

/**
 * @summary List AML related rules with parameters. (Including: AML, Bills, Pocket and WalletWithdrawals)
 */
export const getAmlRelatedRules = (options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<string>(
        { url: `/admin/report/aml-bills-pocket-rules`, method: 'get' },
        options
    )
}

/**
 * @summary List all AML Rules with parameters
 */
export const getAmlRules = (options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<string>({ url: `/admin/report/aml-rules`, method: 'get' }, options)
}

/**
 * @summary List all Bills Rules with parameters
 */
export const getBillsRules = (options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<string>({ url: `/admin/report/bills-rules`, method: 'get' }, options)
}

/**
 * @summary List all Compliance Rules with parameters
 */
export const getComplianceRules = (options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<string>({ url: `/admin/report/compliance-rules`, method: 'get' }, options)
}

/**
 * @summary List all Fraud Rules with parameters
 */
export const getFraudRules = (options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<string>({ url: `/admin/report/fraud-rules`, method: 'get' }, options)
}

/**
 * @summary List all Pocket Rules with parameters
 */
export const getPocketRules = (options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<string>({ url: `/admin/report/pocket-rules`, method: 'get' }, options)
}

/**
 * @summary List all Wallet Withdrawal Rules with parameters
 */
export const getWalletWithdrawalRules = (options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<string>(
        { url: `/admin/report/wallet-withdrawal-rules`, method: 'get' },
        options
    )
}

/**
 * @summary Approves historic merchants as trusted beneficiaries for all companies
 */
export const batchApproveMerchants = (
    params: BatchApproveMerchantsParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        { url: `/companies/trusted-beneficiaries/batch-approve`, method: 'post', params },
        options
    )
}

/**
 * @summary Approves historic merchants as trusted beneficiaries for a company
 */
export const batchApproveMerchants1 = (
    companyId: string,
    params: BatchApproveMerchants1Params,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        {
            url: `/companies/${companyId}/trusted-beneficiaries/batch-approve`,
            method: 'post',
            params,
        },
        options
    )
}

/**
 * @summary Retrieves whether a company has consent for trusted beneficiaries
 */
export const isCompanyApproved = (
    companyId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<TrustedBeneficiaryStatusResponseModel>(
        { url: `/companies/${companyId}/trusted-beneficiaries/consent`, method: 'get' },
        options
    )
}

/**
 * @summary Provides a consent for a company to use trusted beneficiaries
 */
export const approveCompany = (companyId: string, options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<unknown>(
        { url: `/companies/${companyId}/trusted-beneficiaries/consent`, method: 'post' },
        options
    )
}

/**
 * @summary Retrieves whether a company has consent for trusted beneficiaries
 */
export const isCompanyApproved1 = (
    companyId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<TrustedBeneficiaryStatusResponseModel>(
        { url: `/exposed/companies/${companyId}/trusted-beneficiaries/consent`, method: 'get' },
        options
    )
}

/**
 * @summary Provides a consent for a company to use trusted beneficiaries
 */
export const approveCompany1 = (
    companyId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        { url: `/exposed/companies/${companyId}/trusted-beneficiaries/consent`, method: 'post' },
        options
    )
}

/**
 * @summary Manually open a fraud case for a card family accounting entry
 */
export const exposedOpenFraudCaseForAccountingEntry = (
    accountingEntryId: string,
    manualExpenseRequest: ManualExpenseRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DataResponseFraud>(
        {
            url: `/exposed/frauds/accountingEntries/${accountingEntryId}`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: manualExpenseRequest,
        },
        options
    )
}

/**
 * @summary Retrieve the merchant blacklist
 */
export const getMerchantBlacklist = (options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<MerchantBlacklistResponse[]>(
        { url: `/exposed/frauds/blacklist`, method: 'get' },
        options
    )
}

/**
 * @summary Add an item to the merchant blacklist
 */
export const addMerchantBlacklistItem = (
    merchantBlacklistRequest: MerchantBlacklistRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<MerchantBlacklistResponse>(
        {
            url: `/exposed/frauds/blacklist`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: merchantBlacklistRequest,
        },
        options
    )
}

/**
 * @summary Remove a merchant blacklist item
 */
export const removeMerchantBlacklistItem = (
    id: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        { url: `/exposed/frauds/blacklist/${id}`, method: 'delete' },
        options
    )
}

/**
 * @summary Update a merchant blacklist item
 */
export const updateMerchantBlacklistItem = (
    id: string,
    merchantBlacklistRequest: MerchantBlacklistRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<MerchantBlacklistResponse>(
        {
            url: `/exposed/frauds/blacklist/${id}`,
            method: 'put',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: merchantBlacklistRequest,
        },
        options
    )
}

/**
 * @summary Returns detected frauds
 */
export const getDetectedFrauds = (
    params: GetDetectedFraudsParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DetectedFraud[]>(
        { url: `/exposed/frauds/detected`, method: 'get', params },
        options
    )
}

/**
 * @summary Update a detected fraud
 */
export const updateDetectedFraud = (
    detectedFraud: DetectedFraud,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DetectedFraud>(
        {
            url: `/exposed/frauds/detected`,
            method: 'put',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: detectedFraud,
        },
        options
    )
}

/**
 * @summary Returns detected AML frauds
 */
export const getDetectedAmlFrauds = (
    params: GetDetectedAmlFraudsParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DetectedFraud[]>(
        { url: `/exposed/frauds/detected/aml`, method: 'get', params },
        options
    )
}

/**
 * @summary Returns detected bill frauds
 */
export const getDetectedBillFrauds = (
    params: GetDetectedBillFraudsParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DetectedFraud[]>(
        { url: `/exposed/frauds/detected/bill`, method: 'get', params },
        options
    )
}

/**
 * @summary Returns detected card frauds
 */
export const getDetectedCardFrauds = (
    params: GetDetectedCardFraudsParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DetectedFraud[]>(
        { url: `/exposed/frauds/detected/card`, method: 'get', params },
        options
    )
}

/**
 * @summary Retrieve the list of MKE merchants
 */
export const getMkeMerchants = (options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<MkeMerchant[]>(
        { url: `/exposed/frauds/mke/merchants`, method: 'get' },
        options
    )
}

/**
 * @summary Retrieve the details of a MKE merchant
 */
export const getMkeMerchant = (id: string, options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<MkeMerchant>(
        { url: `/exposed/frauds/mke/merchants/${id}`, method: 'get' },
        options
    )
}

/**
 * @summary Update the details of a MKE merchant
 */
export const updateMkeMerchantInvestigating = (
    id: string,
    investigatingRequest: InvestigatingRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<MkeMerchant>(
        {
            url: `/exposed/frauds/mke/merchants/${id}`,
            method: 'put',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: investigatingRequest,
        },
        options
    )
}

/**
 * @summary Add a card to the merchant's whitelisted card list
 */
export const addWhitelistedCardToMkeMerchant = (
    id: string,
    whitelistCardRequest: WhitelistCardRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<MkeMerchant>(
        {
            url: `/exposed/frauds/mke/merchants/${id}/whitelistedcards`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: whitelistCardRequest,
        },
        options
    )
}

/**
 * @summary Get all active rule overrides for a given card
 */
export const getTemporaryWhitelistedForCard = (
    id: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DisabledRulesEntry[]>(
        { url: `/exposed/frauds/overrides/card/${id}`, method: 'get' },
        options
    )
}

/**
 * @summary Add temporary override rules for the card and merchant of this transactionId
 */
export const addTemporaryWhitelistedCardToMerchant = (
    id: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DisabledRulesEntry[]>(
        { url: `/exposed/frauds/overrides/transaction/${id}`, method: 'post' },
        options
    )
}

/**
 * @summary Manually designate transaction with family as fraudulent
 */
export const designateAsFraud = (
    family: 'CARD' | 'BILL' | 'PERSONAL_TRANSFER' | 'WALLET_WITHDRAWAL' | 'EMPLOYEE_BANK_TRANSFER',
    id: string,
    manualFraudRequest: ManualFraudRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<Fraud>(
        {
            url: `/exposed/frauds/transactions/${family}/${id}/frauds`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: manualFraudRequest,
        },
        options
    )
}

/**
 * @summary Update the fraud investigation status
 */
export const updateFraudStatus = (
    id: string,
    params: UpdateFraudStatusParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DetectedFraud>(
        { url: `/exposed/frauds/${id}/status`, method: 'put', params },
        options
    )
}

/**
 * @summary List AML related rules with parameters. (Including: AML, Bills, Pocket and WalletWithdrawals)
 */
export const getAmlRelatedRules1 = (options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<string>(
        { url: `/exposed/report/aml-bills-pocket-rules`, method: 'get' },
        options
    )
}

/**
 * @summary List all Fraud Rules with parameters
 */
export const getFraudRules1 = (options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<string>({ url: `/exposed/report/fraud-rules`, method: 'get' }, options)
}

/**
 * @summary Retrieve latest pending SCA request for the given cards
 */
export const retrieveLatestPendingScaRequestForCards = (
    params: RetrieveLatestPendingScaRequestForCardsParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<ScaRequestResponseModel>(
        { url: `/exposed/sca/requests/latest`, method: 'get', params },
        options
    )
}

/**
 * @summary Invoke authentication procedure based on the Approve SCA response from the client
 */
export const approveAuthenticationFlow = (
    requestId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        { url: `/exposed/sca/requests/${requestId}/approve`, method: 'post' },
        options
    )
}

/**
 * @summary Invoke authentication procedure based on the Decline SCA response from the client
 */
export const declineAuthenticationFlow = (
    requestId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        { url: `/exposed/sca/requests/${requestId}/decline`, method: 'post' },
        options
    )
}

/**
 * @summary Predicts if a transaction is a fraud
 */
export const isFraud = (
    transaction: Transaction,
    params: IsFraudParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<RuleFraudResult[]>(
        {
            url: `/frauds`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: transaction,
            params,
        },
        options
    )
}

/**
 * @summary Manually open a fraud case for a card family accounting entry
 */
export const openFraudCaseForAccountingEntry = (
    accountingEntryId: string,
    manualExpenseRequest: ManualExpenseRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DataResponseFraud>(
        {
            url: `/frauds/accountingEntries/${accountingEntryId}`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: manualExpenseRequest,
        },
        options
    )
}

/**
 * @summary Run new fraud analysis on transactions.
 */
export const analyse = (params: AnalyseParams, options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<TransactionWithFrauds[]>(
        { url: `/frauds/analyse`, method: 'get', params },
        options
    )
}

/**
 * @summary Stores a bill-transaction and analyses it for fraud
 */
export const addBillTransaction = (
    billTransaction: BillTransaction,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<RuleFraudResult[]>(
        {
            url: `/frauds/bills`,
            method: 'put',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: billTransaction,
        },
        options
    )
}

/**
 * @summary Predicts if a bill-transaction is a fraud
 */
export const isBillFraud = (
    billTransaction: BillTransaction,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<RuleFraudResult[]>(
        {
            url: `/frauds/bills/isFraud`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: billTransaction,
        },
        options
    )
}

/**
 * @summary Retrieve the merchant blacklist
 */
export const getMerchantBlacklist1 = (options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<MerchantBlacklistResponse[]>(
        { url: `/frauds/blacklist`, method: 'get' },
        options
    )
}

/**
 * @summary Add an item to the merchant blacklist
 */
export const addMerchantBlacklistItem1 = (
    merchantBlacklistRequest: MerchantBlacklistRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<MerchantBlacklistResponse>(
        {
            url: `/frauds/blacklist`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: merchantBlacklistRequest,
        },
        options
    )
}

/**
 * @summary Remove a merchant blacklist item
 */
export const removeMerchantBlacklistItem1 = (
    id: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>({ url: `/frauds/blacklist/${id}`, method: 'delete' }, options)
}

/**
 * @summary Update a merchant blacklist item
 */
export const updateMerchantBlacklistItem1 = (
    id: string,
    merchantBlacklistRequest: MerchantBlacklistRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<MerchantBlacklistResponse>(
        {
            url: `/frauds/blacklist/${id}`,
            method: 'put',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: merchantBlacklistRequest,
        },
        options
    )
}

/**
 * @summary Retrieve the list containing all merchant blocklists for each company.
 */
export const getAllCompanyMerchantBlocklist = (options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<StrictCompanyMerchantBlocklistResponse[]>(
        { url: `/frauds/company-blocklist`, method: 'get' },
        options
    )
}

/**
 * @summary Add an item to the company blocklisted merchant
 */
export const addCompanyMerchantBlocklistItem = (
    strictCompanyMerchantBlocklistRequest: StrictCompanyMerchantBlocklistRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<StrictCompanyMerchantBlocklistResponse>(
        {
            url: `/frauds/company-blocklist`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: strictCompanyMerchantBlocklistRequest,
        },
        options
    )
}

/**
 * @summary Remove a company blocklisted merchant item
 */
export const removeCompanyMerchantBlocklistItem = (
    id: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        { url: `/frauds/company-blocklist/${id}`, method: 'delete' },
        options
    )
}

/**
 * @summary Update a company blocklisted merchant item
 */
export const updateCompanyMerchantBlocklistItem = (
    id: string,
    strictCompanyMerchantBlocklistRequest: StrictCompanyMerchantBlocklistRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<StrictCompanyMerchantBlocklistResponse>(
        {
            url: `/frauds/company-blocklist/${id}`,
            method: 'put',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: strictCompanyMerchantBlocklistRequest,
        },
        options
    )
}

/**
 * @summary Returns detected frauds
 */
export const getDetectedFrauds1 = (
    params: GetDetectedFrauds1Params,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DetectedFraud[]>({ url: `/frauds/detected`, method: 'get', params }, options)
}

/**
 * @summary Update a detected fraud
 */
export const updateDetectedFraud1 = (
    detectedFraud: DetectedFraud,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DetectedFraud>(
        {
            url: `/frauds/detected`,
            method: 'put',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: detectedFraud,
        },
        options
    )
}

/**
 * @summary Returns detected AML frauds
 */
export const getDetectedAmlFrauds1 = (
    params: GetDetectedAmlFrauds1Params,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DetectedFraud[]>(
        { url: `/frauds/detected/aml`, method: 'get', params },
        options
    )
}

/**
 * @summary Returns detected bill frauds
 */
export const getDetectedBillFrauds1 = (
    params: GetDetectedBillFrauds1Params,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DetectedFraud[]>(
        { url: `/frauds/detected/bill`, method: 'get', params },
        options
    )
}

/**
 * @summary Returns detected card frauds
 */
export const getDetectedCardFrauds1 = (
    params: GetDetectedCardFrauds1Params,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DetectedFraud[]>(
        { url: `/frauds/detected/card`, method: 'get', params },
        options
    )
}

/**
 * @summary Stores a employee-bank-transfer-transaction and analyses it for fraud
 */
export const addEmployeeBankTransferTransaction = (
    employeeBankTransferTransaction: EmployeeBankTransferTransaction,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<RuleFraudResult[]>(
        {
            url: `/frauds/employee-bank-transfers`,
            method: 'put',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: employeeBankTransferTransaction,
        },
        options
    )
}

/**
 * @summary Predicts if a employee-bank-transfer-transaction is a fraud
 */
export const isEmployeeBankTransferFraud = (
    employeeBankTransferTransaction: EmployeeBankTransferTransaction,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<RuleFraudResult[]>(
        {
            url: `/frauds/employee-bank-transfers/isFraud`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: employeeBankTransferTransaction,
        },
        options
    )
}

/**
 * @summary Retrieve the list of MKE merchants
 */
export const getMkeMerchants1 = (options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<MkeMerchant[]>({ url: `/frauds/mke/merchants`, method: 'get' }, options)
}

/**
 * @summary Retrieve the details of a MKE merchant
 */
export const getMkeMerchant1 = (id: string, options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<MkeMerchant>({ url: `/frauds/mke/merchants/${id}`, method: 'get' }, options)
}

/**
 * @summary Update the details of a MKE merchant
 */
export const updateMkeMerchantInvestigating1 = (
    id: string,
    investigatingRequest: InvestigatingRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<MkeMerchant>(
        {
            url: `/frauds/mke/merchants/${id}`,
            method: 'put',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: investigatingRequest,
        },
        options
    )
}

/**
 * @summary Add a card to the merchant's whitelisted card list
 */
export const addWhitelistedCardToMkeMerchant1 = (
    id: string,
    whitelistCardRequest: WhitelistCardRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<MkeMerchant>(
        {
            url: `/frauds/mke/merchants/${id}/whitelistedcards`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: whitelistCardRequest,
        },
        options
    )
}

/**
 * @summary Get all active rule overrides for a given card
 */
export const getTemporaryWhitelistedForCard1 = (
    id: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DisabledRulesEntry[]>(
        { url: `/frauds/overrides/card/${id}`, method: 'get' },
        options
    )
}

/**
 * @summary Add temporary override rules for the card and merchant of this transactionId
 */
export const addTemporaryWhitelistedCardToMerchant1 = (
    id: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DisabledRulesEntry[]>(
        { url: `/frauds/overrides/transaction/${id}`, method: 'post' },
        options
    )
}

/**
 * @summary Stores a personal-transfer-transaction and analyses it for fraud
 */
export const addPersonalTransferTransaction = (
    personalTransferTransaction: PersonalTransferTransaction,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<RuleFraudResult[]>(
        {
            url: `/frauds/personal-transfers`,
            method: 'put',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: personalTransferTransaction,
        },
        options
    )
}

/**
 * @summary Predicts if a personal-transfer-transaction is a fraud
 */
export const isPersonalTransferFraud = (
    personalTransferTransaction: PersonalTransferTransaction,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<RuleFraudResult[]>(
        {
            url: `/frauds/personal-transfers/isFraud`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: personalTransferTransaction,
        },
        options
    )
}

/**
 * @summary Manually designate transaction with family as fraudulent
 */
export const designateAsFraud2 = (
    family: 'CARD' | 'BILL' | 'PERSONAL_TRANSFER' | 'WALLET_WITHDRAWAL' | 'EMPLOYEE_BANK_TRANSFER',
    id: string,
    manualFraudRequest: ManualFraudRequest,
    params: DesignateAsFraud2Params,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<Fraud>(
        {
            url: `/frauds/transactions/${family}/${id}/frauds`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: manualFraudRequest,
            params,
        },
        options
    )
}

/**
 * @deprecated
 * @summary Manually designate transaction as fraudulent
 */
export const designateAsFraud1 = (
    id: string,
    manualFraudRequest: ManualFraudRequest,
    params: DesignateAsFraud1Params,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<Fraud>(
        {
            url: `/frauds/transactions/${id}/frauds`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: manualFraudRequest,
            params,
        },
        options
    )
}

/**
 * @summary Update the fraud investigation status
 */
export const updateFraudStatus1 = (
    id: string,
    params: UpdateFraudStatus1Params,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DetectedFraud>(
        { url: `/frauds/${id}/status`, method: 'put', params },
        options
    )
}

/**
 * @summary Gets the grouped cases
 */
export const getCases = (params: GetCasesParams, options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<GroupedCase[]>({ url: `/v1/cases/grouped`, method: 'get', params }, options)
}

/**
 * @summary Retrieve pending SCA requests for the given cards
 */
export const retrievePendingScaRequestsForCards = (
    params: RetrievePendingScaRequestsForCardsParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<ScaRequestResponseModel[]>(
        { url: `/v1/sca/requests`, method: 'get', params },
        options
    )
}

/**
 * @summary Handle new SCA request
 */
export const handleNewScaRequest = (
    handleScaRequestModel: HandleScaRequestModel,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        {
            url: `/v1/sca/requests`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: handleScaRequestModel,
        },
        options
    )
}

/**
 * @summary Retrieve latest pending SCA request for the given cards
 */
export const retrieveLatestPendingScaRequestForCards1 = (
    params: RetrieveLatestPendingScaRequestForCards1Params,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<ScaRequestResponseModel>(
        { url: `/v1/sca/requests/latest`, method: 'get', params },
        options
    )
}

/**
 * @summary Expire all SCA requests that are due
 */
export const expireDueScaRequests = (options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<unknown>(
        { url: `/v1/sca/requests/process-expired`, method: 'post' },
        options
    )
}

/**
 * @summary Invoke authentication procedure based on the Approve SCA response from the client
 */
export const approveAuthenticationFlow1 = (
    requestId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        { url: `/v1/sca/requests/${requestId}/approve`, method: 'post' },
        options
    )
}

/**
 * @summary Invoke authentication procedure based on the Decline SCA response from the client
 */
export const declineAuthenticationFlow1 = (
    requestId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        { url: `/v1/sca/requests/${requestId}/decline`, method: 'post' },
        options
    )
}

export type JobBackfillCompleteTriggeredRulesResult = NonNullable<
    Awaited<ReturnType<typeof jobBackfillCompleteTriggeredRules>>
>
export type ProcessBillStatusChangeKafkaSqsDlqResult = NonNullable<
    Awaited<ReturnType<typeof processBillStatusChangeKafkaSqsDlq>>
>
export type ProcessCardReimbursementKafkaSqsDlqResult = NonNullable<
    Awaited<ReturnType<typeof processCardReimbursementKafkaSqsDlq>>
>
export type ProcessEmployeeBankTransferStatusChangeKafkaSqsDlqResult = NonNullable<
    Awaited<ReturnType<typeof processEmployeeBankTransferStatusChangeKafkaSqsDlq>>
>
export type ProcessReceiptEventKafkaSqsDlqResult = NonNullable<
    Awaited<ReturnType<typeof processReceiptEventKafkaSqsDlq>>
>
export type ProcessTransactionKafkaSqsDlqResult = NonNullable<
    Awaited<ReturnType<typeof processTransactionKafkaSqsDlq>>
>
export type ProcessWalletLoadKafkaSqsDlqResult = NonNullable<
    Awaited<ReturnType<typeof processWalletLoadKafkaSqsDlq>>
>
export type ProcessWalletWithdrawalKafkaSqsDlqResult = NonNullable<
    Awaited<ReturnType<typeof processWalletWithdrawalKafkaSqsDlq>>
>
export type GetAmlRelatedRulesResult = NonNullable<Awaited<ReturnType<typeof getAmlRelatedRules>>>
export type GetAmlRulesResult = NonNullable<Awaited<ReturnType<typeof getAmlRules>>>
export type GetBillsRulesResult = NonNullable<Awaited<ReturnType<typeof getBillsRules>>>
export type GetComplianceRulesResult = NonNullable<Awaited<ReturnType<typeof getComplianceRules>>>
export type GetFraudRulesResult = NonNullable<Awaited<ReturnType<typeof getFraudRules>>>
export type GetPocketRulesResult = NonNullable<Awaited<ReturnType<typeof getPocketRules>>>
export type GetWalletWithdrawalRulesResult = NonNullable<
    Awaited<ReturnType<typeof getWalletWithdrawalRules>>
>
export type BatchApproveMerchantsResult = NonNullable<
    Awaited<ReturnType<typeof batchApproveMerchants>>
>
export type BatchApproveMerchants1Result = NonNullable<
    Awaited<ReturnType<typeof batchApproveMerchants1>>
>
export type IsCompanyApprovedResult = NonNullable<Awaited<ReturnType<typeof isCompanyApproved>>>
export type ApproveCompanyResult = NonNullable<Awaited<ReturnType<typeof approveCompany>>>
export type IsCompanyApproved1Result = NonNullable<Awaited<ReturnType<typeof isCompanyApproved1>>>
export type ApproveCompany1Result = NonNullable<Awaited<ReturnType<typeof approveCompany1>>>
export type ExposedOpenFraudCaseForAccountingEntryResult = NonNullable<
    Awaited<ReturnType<typeof exposedOpenFraudCaseForAccountingEntry>>
>
export type GetMerchantBlacklistResult = NonNullable<
    Awaited<ReturnType<typeof getMerchantBlacklist>>
>
export type AddMerchantBlacklistItemResult = NonNullable<
    Awaited<ReturnType<typeof addMerchantBlacklistItem>>
>
export type RemoveMerchantBlacklistItemResult = NonNullable<
    Awaited<ReturnType<typeof removeMerchantBlacklistItem>>
>
export type UpdateMerchantBlacklistItemResult = NonNullable<
    Awaited<ReturnType<typeof updateMerchantBlacklistItem>>
>
export type GetDetectedFraudsResult = NonNullable<Awaited<ReturnType<typeof getDetectedFrauds>>>
export type UpdateDetectedFraudResult = NonNullable<Awaited<ReturnType<typeof updateDetectedFraud>>>
export type GetDetectedAmlFraudsResult = NonNullable<
    Awaited<ReturnType<typeof getDetectedAmlFrauds>>
>
export type GetDetectedBillFraudsResult = NonNullable<
    Awaited<ReturnType<typeof getDetectedBillFrauds>>
>
export type GetDetectedCardFraudsResult = NonNullable<
    Awaited<ReturnType<typeof getDetectedCardFrauds>>
>
export type GetMkeMerchantsResult = NonNullable<Awaited<ReturnType<typeof getMkeMerchants>>>
export type GetMkeMerchantResult = NonNullable<Awaited<ReturnType<typeof getMkeMerchant>>>
export type UpdateMkeMerchantInvestigatingResult = NonNullable<
    Awaited<ReturnType<typeof updateMkeMerchantInvestigating>>
>
export type AddWhitelistedCardToMkeMerchantResult = NonNullable<
    Awaited<ReturnType<typeof addWhitelistedCardToMkeMerchant>>
>
export type GetTemporaryWhitelistedForCardResult = NonNullable<
    Awaited<ReturnType<typeof getTemporaryWhitelistedForCard>>
>
export type AddTemporaryWhitelistedCardToMerchantResult = NonNullable<
    Awaited<ReturnType<typeof addTemporaryWhitelistedCardToMerchant>>
>
export type DesignateAsFraudResult = NonNullable<Awaited<ReturnType<typeof designateAsFraud>>>
export type UpdateFraudStatusResult = NonNullable<Awaited<ReturnType<typeof updateFraudStatus>>>
export type GetAmlRelatedRules1Result = NonNullable<Awaited<ReturnType<typeof getAmlRelatedRules1>>>
export type GetFraudRules1Result = NonNullable<Awaited<ReturnType<typeof getFraudRules1>>>
export type RetrieveLatestPendingScaRequestForCardsResult = NonNullable<
    Awaited<ReturnType<typeof retrieveLatestPendingScaRequestForCards>>
>
export type ApproveAuthenticationFlowResult = NonNullable<
    Awaited<ReturnType<typeof approveAuthenticationFlow>>
>
export type DeclineAuthenticationFlowResult = NonNullable<
    Awaited<ReturnType<typeof declineAuthenticationFlow>>
>
export type IsFraudResult = NonNullable<Awaited<ReturnType<typeof isFraud>>>
export type OpenFraudCaseForAccountingEntryResult = NonNullable<
    Awaited<ReturnType<typeof openFraudCaseForAccountingEntry>>
>
export type AnalyseResult = NonNullable<Awaited<ReturnType<typeof analyse>>>
export type AddBillTransactionResult = NonNullable<Awaited<ReturnType<typeof addBillTransaction>>>
export type IsBillFraudResult = NonNullable<Awaited<ReturnType<typeof isBillFraud>>>
export type GetMerchantBlacklist1Result = NonNullable<
    Awaited<ReturnType<typeof getMerchantBlacklist1>>
>
export type AddMerchantBlacklistItem1Result = NonNullable<
    Awaited<ReturnType<typeof addMerchantBlacklistItem1>>
>
export type RemoveMerchantBlacklistItem1Result = NonNullable<
    Awaited<ReturnType<typeof removeMerchantBlacklistItem1>>
>
export type UpdateMerchantBlacklistItem1Result = NonNullable<
    Awaited<ReturnType<typeof updateMerchantBlacklistItem1>>
>
export type GetAllCompanyMerchantBlocklistResult = NonNullable<
    Awaited<ReturnType<typeof getAllCompanyMerchantBlocklist>>
>
export type AddCompanyMerchantBlocklistItemResult = NonNullable<
    Awaited<ReturnType<typeof addCompanyMerchantBlocklistItem>>
>
export type RemoveCompanyMerchantBlocklistItemResult = NonNullable<
    Awaited<ReturnType<typeof removeCompanyMerchantBlocklistItem>>
>
export type UpdateCompanyMerchantBlocklistItemResult = NonNullable<
    Awaited<ReturnType<typeof updateCompanyMerchantBlocklistItem>>
>
export type GetDetectedFrauds1Result = NonNullable<Awaited<ReturnType<typeof getDetectedFrauds1>>>
export type UpdateDetectedFraud1Result = NonNullable<
    Awaited<ReturnType<typeof updateDetectedFraud1>>
>
export type GetDetectedAmlFrauds1Result = NonNullable<
    Awaited<ReturnType<typeof getDetectedAmlFrauds1>>
>
export type GetDetectedBillFrauds1Result = NonNullable<
    Awaited<ReturnType<typeof getDetectedBillFrauds1>>
>
export type GetDetectedCardFrauds1Result = NonNullable<
    Awaited<ReturnType<typeof getDetectedCardFrauds1>>
>
export type AddEmployeeBankTransferTransactionResult = NonNullable<
    Awaited<ReturnType<typeof addEmployeeBankTransferTransaction>>
>
export type IsEmployeeBankTransferFraudResult = NonNullable<
    Awaited<ReturnType<typeof isEmployeeBankTransferFraud>>
>
export type GetMkeMerchants1Result = NonNullable<Awaited<ReturnType<typeof getMkeMerchants1>>>
export type GetMkeMerchant1Result = NonNullable<Awaited<ReturnType<typeof getMkeMerchant1>>>
export type UpdateMkeMerchantInvestigating1Result = NonNullable<
    Awaited<ReturnType<typeof updateMkeMerchantInvestigating1>>
>
export type AddWhitelistedCardToMkeMerchant1Result = NonNullable<
    Awaited<ReturnType<typeof addWhitelistedCardToMkeMerchant1>>
>
export type GetTemporaryWhitelistedForCard1Result = NonNullable<
    Awaited<ReturnType<typeof getTemporaryWhitelistedForCard1>>
>
export type AddTemporaryWhitelistedCardToMerchant1Result = NonNullable<
    Awaited<ReturnType<typeof addTemporaryWhitelistedCardToMerchant1>>
>
export type AddPersonalTransferTransactionResult = NonNullable<
    Awaited<ReturnType<typeof addPersonalTransferTransaction>>
>
export type IsPersonalTransferFraudResult = NonNullable<
    Awaited<ReturnType<typeof isPersonalTransferFraud>>
>
export type DesignateAsFraud2Result = NonNullable<Awaited<ReturnType<typeof designateAsFraud2>>>
export type DesignateAsFraud1Result = NonNullable<Awaited<ReturnType<typeof designateAsFraud1>>>
export type UpdateFraudStatus1Result = NonNullable<Awaited<ReturnType<typeof updateFraudStatus1>>>
export type GetCasesResult = NonNullable<Awaited<ReturnType<typeof getCases>>>
export type RetrievePendingScaRequestsForCardsResult = NonNullable<
    Awaited<ReturnType<typeof retrievePendingScaRequestsForCards>>
>
export type HandleNewScaRequestResult = NonNullable<Awaited<ReturnType<typeof handleNewScaRequest>>>
export type RetrieveLatestPendingScaRequestForCards1Result = NonNullable<
    Awaited<ReturnType<typeof retrieveLatestPendingScaRequestForCards1>>
>
export type ExpireDueScaRequestsResult = NonNullable<
    Awaited<ReturnType<typeof expireDueScaRequests>>
>
export type ApproveAuthenticationFlow1Result = NonNullable<
    Awaited<ReturnType<typeof approveAuthenticationFlow1>>
>
export type DeclineAuthenticationFlow1Result = NonNullable<
    Awaited<ReturnType<typeof declineAuthenticationFlow1>>
>
