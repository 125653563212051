import { countries } from 'countries-list'
import type { Alpha2Code } from 'types/countries'
import nationalities from 'i18n-nationality'
import en from 'i18n-nationality/langs/en.json'

nationalities.registerLocale(en)

export function getCountryCode(countryName?: string): string | null {
    for (const code in countries) {
        if (countries[code as Alpha2Code].name === countryName) {
            return code
        }
    }
    return null
}

export function getCountryName(countryCode?: string): string | null {
    return countryCode ? countries[countryCode as Alpha2Code]?.name ?? null : null
}

export function getCountryDetailsFromCodeOrNationality(countryCodeOrNationality: string) {
    const isCode = countryCodeOrNationality.length === 2
    const countryCode = isCode
        ? countryCodeOrNationality
        : nationalities.getAlpha2Code(countryCodeOrNationality, 'en')
    const countryName = getCountryName(countryCode) ?? ''
    const nationality = isCode ? nationalities.getName(countryCode, 'en') : countryCodeOrNationality

    return { countryCode, countryName, nationality }
}

export const useCountriesList = () => {
    return {
        multipleCountries: [
            {
                label: 'UK & Ireland',
                values: ['GB', 'IE'],
            },
            {
                label: 'Nordic & Baltic',
                values: ['DK', 'SE', 'FI', 'NO', 'EE'],
            },
            {
                label: 'Central & Southern Europe',
                values: ['DE', 'ES', 'AT', 'FR', 'IT', 'PT', 'BE', 'NL', 'LU'],
            },
        ],
        singleCountries: [
            { label: 'Austria', values: ['AT'] },
            { label: 'Denmark', values: ['DK'] },
            { label: 'Finland', values: ['FI'] },
            { label: 'France', values: ['FR'] },
            { label: 'Germany', values: ['DE'] },
            { label: 'Ireland', values: ['IE'] },
            { label: 'Spain', values: ['ES'] },
            { label: 'Sweden', values: ['SE'] },
            { label: 'United Kingdom', values: ['GB'] },
            { label: 'Estonia', values: ['EE'] },
            { label: 'Netherlands', values: ['NL'] },
            { label: 'Belgium', values: ['BE'] },
            { label: 'Luxembourg', values: ['LU'] },
            { label: 'Portugal', values: ['PT'] },
            { label: 'Italy', values: ['IT'] },
            { label: 'Norway', values: ['NO'] },
            { label: 'United States', values: ['US'] },
        ],
    }
}
