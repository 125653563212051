import { useState } from 'react'

import { Checkbox, Form, Modal } from 'antd'

import type { InvestigationInputBaseObject } from 'bff/moons/generated/investigation-runner'
import { useContextDetailContext } from '../../context'

type SelectInputsModalTypes = {
    inputs: Record<string, any>
    onCancel: () => void
    onOk: (selectedInputs: string[]) => void
}

export const SelectInputsModal = ({ inputs, onCancel, onOk }: SelectInputsModalTypes) => {
    const { selectedInputs } = useContextDetailContext()
    const [selectedTemporaryInputs, setSelectedTemporaryInputs] = useState(selectedInputs)

    const handleRemoveInput = (id: string) => {
        setSelectedTemporaryInputs(selectedTemporaryInputs.filter((i) => i !== id))
    }

    const handleSetInput = (id: string) => {
        setSelectedTemporaryInputs([...selectedTemporaryInputs, id])
    }

    return (
        <Modal
            open
            title="Select Inputs"
            onOk={() => onOk(selectedTemporaryInputs)}
            onCancel={() => onCancel()}
            width={800}
            data-testid="select-inputs-modal"
        >
            {Object.values(inputs).map((input: InvestigationInputBaseObject) => (
                <Form.Item
                    key={input.id}
                    style={{
                        display: 'inline-block',
                        width: 'calc(33% - 8px)',
                        padding: 0,
                        margin: 0,
                    }}
                >
                    <Checkbox
                        value={input.id}
                        onChange={(e) => {
                            if (e.target.checked) {
                                handleSetInput(input.id)
                            } else {
                                handleRemoveInput(input.id)
                            }
                        }}
                        checked={selectedTemporaryInputs.includes(input.id)}
                        disabled={input.required}
                    >
                        {input.label}
                    </Checkbox>
                </Form.Item>
            ))}
        </Modal>
    )
}
