/**
 * Generated by orval v6.15.0 🍺
 * Do not edit manually.
 * Investigation Runner API
 * Investigation Runner OpenAPI definitions
 * OpenAPI spec version: 6.6.0
 */
import { moonRequest as _moonRequest } from '@pleo-io/bff-moon-clients'

const moonRequest = _moonRequest('investigationRunner')

export type UUIDInput = InvestigationInputBaseObject

export type StringInput = InvestigationInputBaseObject

export type StringDesiredOutputColumn = InvestigationDesiredOutputColumn

export type SortingMetadataDirection =
    (typeof SortingMetadataDirection)[keyof typeof SortingMetadataDirection]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SortingMetadataDirection = {
    ASC: 'ASC',
    DESC: 'DESC',
} as const

export type SortingMetadataColumnId =
    (typeof SortingMetadataColumnId)[keyof typeof SortingMetadataColumnId]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SortingMetadataColumnId = {
    ACCOUNTING_ENTRY_ID: 'ACCOUNTING_ENTRY_ID',
    LINK_ID: 'LINK_ID',
    PERFORMED_AT: 'PERFORMED_AT',
    SETTLED_AT: 'SETTLED_AT',
    USER_ID: 'USER_ID',
    EMPLOYEE_ID: 'EMPLOYEE_ID',
    EMPLOYEE_EMAIL: 'EMPLOYEE_EMAIL',
    COMPANY_ID: 'COMPANY_ID',
    COMPANY_NAME: 'COMPANY_NAME',
    TRANSACTION_ID: 'TRANSACTION_ID',
    TRANSACTION_STATUS: 'TRANSACTION_STATUS',
    TRANSACTION_AMOUNT_VALUE: 'TRANSACTION_AMOUNT_VALUE',
    TRANSACTION_AMOUNT_CURRENCY: 'TRANSACTION_AMOUNT_CURRENCY',
    BILLED_AMOUNT_VALUE: 'BILLED_AMOUNT_VALUE',
    BILLED_AMOUNT_CURRENCY: 'BILLED_AMOUNT_CURRENCY',
    MCC: 'MCC',
    PARTY_ADDRESS: 'PARTY_ADDRESS',
    PARTY_NAME: 'PARTY_NAME',
    CARD_ID: 'CARD_ID',
    CARD_STATUS: 'CARD_STATUS',
    CARD_TYPE: 'CARD_TYPE',
    CARD_PAN: 'CARD_PAN',
    CHECK_ENGINE_RESULT: 'CHECK_ENGINE_RESULT',
    ATM_BLOCK_LEVEL: 'ATM_BLOCK_LEVEL',
    TRANSACTION_REASON: 'TRANSACTION_REASON',
    TRANSACTION_STATE: 'TRANSACTION_STATE',
    ACCOUNT_ID: 'ACCOUNT_ID',
    WALLET_ID: 'WALLET_ID',
    INVOICE_ID: 'INVOICE_ID',
    BILL_INVOICE_STATUS: 'BILL_INVOICE_STATUS',
    ACCOUNTING_ENTRY_STATUS: 'ACCOUNTING_ENTRY_STATUS',
    TRANSACTION_FAMILY: 'TRANSACTION_FAMILY',
    TRANSACTION_SUB_FAMILY: 'TRANSACTION_SUB_FAMILY',
    SNAPSHOT_AT: 'SNAPSHOT_AT',
    FEES: 'FEES',
    MASTERCARD: 'MASTERCARD',
    POS_CARD_INPUT_METHOD: 'POS_CARD_INPUT_METHOD',
} as const

/**
 * The field IDs by which to sort the output
 */
export interface SortingMetadata {
    columnId: SortingMetadataColumnId
    direction: SortingMetadataDirection
}

export type ResultErrorSuppressedItemStackTraceItem = {
    classLoaderName?: string
    className?: string
    fileName?: string
    isNativeMethod?: boolean
    lineNumber?: number
    methodName?: string
    moduleName?: string
    moduleVersion?: string
}

export type ResultErrorSuppressedItem = {
    localizedMessage?: string
    message?: string
    stackTrace?: ResultErrorSuppressedItemStackTraceItem[]
}

export type ResultErrorStackTraceItem = {
    classLoaderName?: string
    className?: string
    fileName?: string
    isNativeMethod?: boolean
    lineNumber?: number
    methodName?: string
    moduleName?: string
    moduleVersion?: string
}

export type ResultErrorCauseStackTraceItem = {
    classLoaderName?: string
    className?: string
    fileName?: string
    isNativeMethod?: boolean
    lineNumber?: number
    methodName?: string
    moduleName?: string
    moduleVersion?: string
}

export type ResultErrorCause = {
    localizedMessage?: string
    message?: string
    stackTrace?: ResultErrorCauseStackTraceItem[]
}

/**
 * Error information if the health check failed
 */
export type ResultError = {
    cause?: ResultErrorCause
    localizedMessage?: string
    message?: string
    stackTrace?: ResultErrorStackTraceItem[]
    suppressed?: ResultErrorSuppressedItem[]
}

/**
 * Details of the health check
 */
export type ResultDetails = { [key: string]: { [key: string]: any } }

/**
 * Result of a health check
 */
export interface Result {
    /** Details of the health check */
    details?: ResultDetails
    /** Error information if the health check failed */
    error?: ResultError
    /** Indicates if the service is healthy */
    healthy: boolean
    /** Message providing additional information about the health check */
    message?: string
    /** Timestamp of the health check */
    time: string
}

/**
 * The pagination details of the output
 */
export interface PaginationDetails {
    /** The current page number */
    currentPage: number
    /** The limit of the current page */
    limit: number
    /** The offset of the current page */
    offset: number
    /** The number of rows per page */
    rowsPerPage: number
    /** The total number of pages */
    totalPages: number
    /** The total number of rows in the output */
    totalRows: number
}

/**
 * The type of the output
 */
export type OutputColumnMetadataType =
    (typeof OutputColumnMetadataType)[keyof typeof OutputColumnMetadataType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OutputColumnMetadataType = {
    STRING: 'STRING',
    NUMBER: 'NUMBER',
    BOOLEAN: 'BOOLEAN',
    DATE: 'DATE',
    DATETIME: 'DATETIME',
    MONEY: 'MONEY',
    COMPANY_ID: 'COMPANY_ID',
    EMPLOYEE_ID: 'EMPLOYEE_ID',
} as const

/**
 * The ID of the output
 */
export type OutputColumnMetadataId =
    (typeof OutputColumnMetadataId)[keyof typeof OutputColumnMetadataId]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OutputColumnMetadataId = {
    ACCOUNTING_ENTRY_ID: 'ACCOUNTING_ENTRY_ID',
    LINK_ID: 'LINK_ID',
    PERFORMED_AT: 'PERFORMED_AT',
    SETTLED_AT: 'SETTLED_AT',
    USER_ID: 'USER_ID',
    EMPLOYEE_ID: 'EMPLOYEE_ID',
    EMPLOYEE_EMAIL: 'EMPLOYEE_EMAIL',
    COMPANY_ID: 'COMPANY_ID',
    COMPANY_NAME: 'COMPANY_NAME',
    TRANSACTION_ID: 'TRANSACTION_ID',
    TRANSACTION_STATUS: 'TRANSACTION_STATUS',
    TRANSACTION_AMOUNT_VALUE: 'TRANSACTION_AMOUNT_VALUE',
    TRANSACTION_AMOUNT_CURRENCY: 'TRANSACTION_AMOUNT_CURRENCY',
    BILLED_AMOUNT_VALUE: 'BILLED_AMOUNT_VALUE',
    BILLED_AMOUNT_CURRENCY: 'BILLED_AMOUNT_CURRENCY',
    MCC: 'MCC',
    PARTY_ADDRESS: 'PARTY_ADDRESS',
    PARTY_NAME: 'PARTY_NAME',
    CARD_ID: 'CARD_ID',
    CARD_STATUS: 'CARD_STATUS',
    CARD_TYPE: 'CARD_TYPE',
    CARD_PAN: 'CARD_PAN',
    CHECK_ENGINE_RESULT: 'CHECK_ENGINE_RESULT',
    ATM_BLOCK_LEVEL: 'ATM_BLOCK_LEVEL',
    TRANSACTION_REASON: 'TRANSACTION_REASON',
    TRANSACTION_STATE: 'TRANSACTION_STATE',
    ACCOUNT_ID: 'ACCOUNT_ID',
    WALLET_ID: 'WALLET_ID',
    INVOICE_ID: 'INVOICE_ID',
    BILL_INVOICE_STATUS: 'BILL_INVOICE_STATUS',
    ACCOUNTING_ENTRY_STATUS: 'ACCOUNTING_ENTRY_STATUS',
    TRANSACTION_FAMILY: 'TRANSACTION_FAMILY',
    TRANSACTION_SUB_FAMILY: 'TRANSACTION_SUB_FAMILY',
    SNAPSHOT_AT: 'SNAPSHOT_AT',
    FEES: 'FEES',
    MASTERCARD: 'MASTERCARD',
    POS_CARD_INPUT_METHOD: 'POS_CARD_INPUT_METHOD',
} as const

/**
 * The columns of the output
 */
export interface OutputColumnMetadata {
    /** The ID of the output */
    id: OutputColumnMetadataId
    /** The label of the output */
    label: string
    /** The ordinal of the output */
    ordinal: number
    /** The type of the output */
    type: OutputColumnMetadataType
}

export type NumberRangeInput = InvestigationInputBaseObject

export type NumberDesiredOutputColumn = InvestigationDesiredOutputColumn

export type MoneyDesiredOutputColumn = InvestigationDesiredOutputColumn

export interface InvestigationQueryInput {
    /** The field IDs requested back */
    desiredOutputIds: string[]
    /** The field IDs by which to sort the output */
    desiredSorting?: SortingMetadata[]
    /** The field IDs of the filters used for the investigation */
    inputs: InputIdValuePair[]
    /** The amount of items to be returned per query. (Default: 20) */
    limit: number
    /** The offset of the first item to be returned. (Default: 0) */
    offset: number
}

export interface InvestigationOutput {
    /** The columns of the output */
    columns: OutputColumnMetadata[]
    pagination: PaginationDetails
    /** The rows of the output */
    rows: string[][]
}

export type InvestigationInputBaseObjectType =
    (typeof InvestigationInputBaseObjectType)[keyof typeof InvestigationInputBaseObjectType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InvestigationInputBaseObjectType = {
    STRING: 'STRING',
    UUID: 'UUID',
    NUMBER: 'NUMBER',
    BOOLEAN: 'BOOLEAN',
    DATE: 'DATE',
    DATETIME: 'DATETIME',
    ENUM: 'ENUM',
    RANGE_DATE: 'RANGE_DATE',
    RANGE_NUMBER: 'RANGE_NUMBER',
} as const

export type InvestigationInputBaseObjectId =
    (typeof InvestigationInputBaseObjectId)[keyof typeof InvestigationInputBaseObjectId]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InvestigationInputBaseObjectId = {
    ACCOUNTING_ENTRY_ID: 'ACCOUNTING_ENTRY_ID',
    FAMILY: 'FAMILY',
    SUB_FAMILY: 'SUB_FAMILY',
    COMPANY_ID: 'COMPANY_ID',
    COMPANY_NAME: 'COMPANY_NAME',
    ACCOUNT_ID: 'ACCOUNT_ID',
    EMPLOYEE_ID: 'EMPLOYEE_ID',
    USER_ID: 'USER_ID',
    EMPLOYEE_EMAIL: 'EMPLOYEE_EMAIL',
    LINK_ID: 'LINK_ID',
    TRANSACTION_ID: 'TRANSACTION_ID',
    TRANSACTION_STATUS: 'TRANSACTION_STATUS',
    TRANSACTION_AMOUNT_VALUE: 'TRANSACTION_AMOUNT_VALUE',
    TRANSACTION_AMOUNT_CURRENCY: 'TRANSACTION_AMOUNT_CURRENCY',
    INVOICE_ID: 'INVOICE_ID',
    INVOICE_STATUS: 'INVOICE_STATUS',
    INVOICE_AMOUNT_VALUE: 'INVOICE_AMOUNT_VALUE',
    INVOICE_AMOUNT_CURRENCY: 'INVOICE_AMOUNT_CURRENCY',
    PARTY_NAME: 'PARTY_NAME',
    PARTY_ADDRESS: 'PARTY_ADDRESS',
    CARD_ID: 'CARD_ID',
    CARD_STATUS: 'CARD_STATUS',
    CARD_TYPE: 'CARD_TYPE',
    PERFORMED_AT: 'PERFORMED_AT',
    SETTLED_AT: 'SETTLED_AT',
    POS_CARD_INPUT_METHOD: 'POS_CARD_INPUT_METHOD',
} as const

export interface InvestigationInputBaseObject {
    defaultValue?: string
    hidden?: boolean
    id: InvestigationInputBaseObjectId
    isDefault: boolean
    label: string
    possibleValues?: string[]
    required?: boolean
    type: InvestigationInputBaseObjectType
}

export type NumberInput = InvestigationInputBaseObject

export type InvestigationDesiredOutputColumnType =
    (typeof InvestigationDesiredOutputColumnType)[keyof typeof InvestigationDesiredOutputColumnType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InvestigationDesiredOutputColumnType = {
    STRING: 'STRING',
    NUMBER: 'NUMBER',
    BOOLEAN: 'BOOLEAN',
    DATE: 'DATE',
    DATETIME: 'DATETIME',
    EMPLOYEE_ID: 'EMPLOYEE_ID',
    COMPANY_ID: 'COMPANY_ID',
    MONEY: 'MONEY',
    CUSTOM_TRANSACTIONS_CHECK: 'CUSTOM_TRANSACTIONS_CHECK',
} as const

export type InvestigationDesiredOutputColumnId =
    (typeof InvestigationDesiredOutputColumnId)[keyof typeof InvestigationDesiredOutputColumnId]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InvestigationDesiredOutputColumnId = {
    ACCOUNTING_ENTRY_ID: 'ACCOUNTING_ENTRY_ID',
    LINK_ID: 'LINK_ID',
    PERFORMED_AT: 'PERFORMED_AT',
    SETTLED_AT: 'SETTLED_AT',
    USER_ID: 'USER_ID',
    EMPLOYEE_ID: 'EMPLOYEE_ID',
    EMPLOYEE_EMAIL: 'EMPLOYEE_EMAIL',
    COMPANY_ID: 'COMPANY_ID',
    COMPANY_NAME: 'COMPANY_NAME',
    TRANSACTION_ID: 'TRANSACTION_ID',
    TRANSACTION_STATUS: 'TRANSACTION_STATUS',
    TRANSACTION_AMOUNT_VALUE: 'TRANSACTION_AMOUNT_VALUE',
    TRANSACTION_AMOUNT_CURRENCY: 'TRANSACTION_AMOUNT_CURRENCY',
    BILLED_AMOUNT_VALUE: 'BILLED_AMOUNT_VALUE',
    BILLED_AMOUNT_CURRENCY: 'BILLED_AMOUNT_CURRENCY',
    MCC: 'MCC',
    PARTY_ADDRESS: 'PARTY_ADDRESS',
    PARTY_NAME: 'PARTY_NAME',
    CARD_ID: 'CARD_ID',
    CARD_STATUS: 'CARD_STATUS',
    CARD_TYPE: 'CARD_TYPE',
    CARD_PAN: 'CARD_PAN',
    CHECK_ENGINE_RESULT: 'CHECK_ENGINE_RESULT',
    ATM_BLOCK_LEVEL: 'ATM_BLOCK_LEVEL',
    TRANSACTION_REASON: 'TRANSACTION_REASON',
    TRANSACTION_STATE: 'TRANSACTION_STATE',
    ACCOUNT_ID: 'ACCOUNT_ID',
    WALLET_ID: 'WALLET_ID',
    INVOICE_ID: 'INVOICE_ID',
    BILL_INVOICE_STATUS: 'BILL_INVOICE_STATUS',
    ACCOUNTING_ENTRY_STATUS: 'ACCOUNTING_ENTRY_STATUS',
    TRANSACTION_FAMILY: 'TRANSACTION_FAMILY',
    TRANSACTION_SUB_FAMILY: 'TRANSACTION_SUB_FAMILY',
    SNAPSHOT_AT: 'SNAPSHOT_AT',
    FEES: 'FEES',
    MASTERCARD: 'MASTERCARD',
    POS_CARD_INPUT_METHOD: 'POS_CARD_INPUT_METHOD',
} as const

export interface InvestigationDesiredOutputColumn {
    hidden?: boolean
    id: InvestigationDesiredOutputColumnId
    isDefault: boolean
    label: string
    type: InvestigationDesiredOutputColumnType
}

export type InvestigationContextOutputs = { [key: string]: InvestigationDesiredOutputColumn }

export type InvestigationContextInputs = { [key: string]: InvestigationInputBaseObject }

export type InvestigationContextId =
    (typeof InvestigationContextId)[keyof typeof InvestigationContextId]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InvestigationContextId = {
    TRANSACTIONS: 'TRANSACTIONS',
} as const

export interface InvestigationContext {
    description: string
    id: InvestigationContextId
    impactedTables: string[]
    inputs: InvestigationContextInputs
    label: string
    outputs: InvestigationContextOutputs
    permissionTag?: string
}

/**
 * The field IDs of the filters used for the investigation
 */
export interface InputIdValuePair {
    /** The ID of the input */
    inputId: string
    /** The value of the input */
    value: string
}

export type EnumInput = InvestigationInputBaseObject

export type DateTimeInput = InvestigationInputBaseObject

export type DateTimeDesiredOutputColumn = InvestigationDesiredOutputColumn

export type DateRangeInput = InvestigationInputBaseObject

export type DateInput = InvestigationInputBaseObject

export type DateDesiredOutputColumn = InvestigationDesiredOutputColumn

export interface DataResponseResult {
    data: Result
}

export interface DataResponseListInvestigationContext {
    data: InvestigationContext[]
}

export interface DataResponseInvestigationOutput {
    data: InvestigationOutput
}

export type CustomCheckDesiredOutputColumn = InvestigationDesiredOutputColumn

export type BooleanInput = InvestigationInputBaseObject

export type BooleanDesiredOutputColumn = InvestigationDesiredOutputColumn

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
    config: any,
    args: infer P
) => any
    ? P
    : never

/**
 * Returns the readiness health of the service
 */
export const checkReadiness = (options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<DataResponseResult>({ url: `/health`, method: 'get' }, options)
}

/**
 * Returns the liveness health of the service
 */
export const checkLiveness = (options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<DataResponseResult>({ url: `/health/liveness`, method: 'get' }, options)
}

/**
 * Returns all available investigation contexts
 * @summary Fetch investigation contexts
 */
export const getAllContexts = (options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<DataResponseListInvestigationContext>(
        { url: `/v1/investigation/context`, method: 'get' },
        options
    )
}

/**
 * Exports the investigation results to a CSV file
 * @summary Export investigation results to CSV
 */
export const exportInvestigationResults = (
    outputType: 'CSV',
    contextId: string,
    investigationQueryInput: InvestigationQueryInput,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<void>(
        {
            url: `/v1/investigation/export/${outputType}/${contextId}`,
            method: 'get',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
        },
        options
    )
}

/**
 * Runs an investigation with the given context ID and input.
 * @summary Run an investigation
 */
export const runInvestigation = (
    contextId: string,
    investigationQueryInput: InvestigationQueryInput,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DataResponseInvestigationOutput>(
        {
            url: `/v1/investigation/run/${contextId}`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: investigationQueryInput,
        },
        options
    )
}

export type CheckReadinessResult = NonNullable<Awaited<ReturnType<typeof checkReadiness>>>
export type CheckLivenessResult = NonNullable<Awaited<ReturnType<typeof checkLiveness>>>
export type GetAllContextsResult = NonNullable<Awaited<ReturnType<typeof getAllContexts>>>
export type ExportInvestigationResultsResult = NonNullable<
    Awaited<ReturnType<typeof exportInvestigationResults>>
>
export type RunInvestigationResult = NonNullable<Awaited<ReturnType<typeof runInvestigation>>>
