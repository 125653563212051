import { Button, Col, Flex, message, Modal, Row, Select, Table, Typography } from 'antd'
import { ActionsContainer } from 'components/layout-containers'
import { useState } from 'react'
import { bff } from '../bff'
import { ExclamationCircleOutlined, LoadingOutlined, UsergroupAddOutlined } from '@ant-design/icons'

const { Text, Link } = Typography
const { confirm } = Modal

export const ClientsCell = ({
    partnerId,
    team,
}: {
    partnerId: string
    team: {
        id: string
        name: string
        companyIds: string[]
    }
}) => {
    const [showClients, setShowClients] = useState(false)

    const { data: clients, isLoading } = bff.partnerTeamClients.getTeamClients.useQuery(
        {
            partnerId,
            teamId: team.id,
        },
        {
            enabled: showClients,
        }
    )

    const addableClients = clients?.addableClients
    const teamClients = clients?.clients

    return (
        <ActionsContainer>
            <Button type="link" onClick={() => setShowClients(true)}>
                {team.companyIds.length === 0 ? 'None' : `${team.companyIds.length} clients`}
            </Button>

            <Modal
                title={
                    <Row justify="space-between">
                        <Col span={16}>
                            <Text ellipsis>Clients for {team.name}</Text>
                        </Col>
                        <Col pull={1}>
                            <AddTeamClient
                                teamId={team.id}
                                addableClients={addableClients}
                                isLoading={isLoading}
                            />
                        </Col>
                    </Row>
                }
                open={showClients}
                onCancel={() => setShowClients(false)}
                footer={null}
                width={1200}
                centered
                destroyOnClose
            >
                <ClientsTable teamId={team.id} clients={teamClients} isLoading={isLoading} />
            </Modal>
        </ActionsContainer>
    )
}

const ClientsTable = ({
    teamId,
    clients,
    isLoading,
}: {
    teamId: string
    clients: { companyId: string; name: string }[] | undefined
    isLoading: boolean
}) => {
    return (
        <Table
            loading={isLoading}
            dataSource={clients}
            columns={[
                {
                    title: 'Company ID',
                    dataIndex: 'companyId',
                    render: (companyId) => <Text copyable>{companyId}</Text>,
                },
                {
                    title: 'Name',
                    dataIndex: 'name',
                    render: (name, { companyId }) => (
                        <Link href={`/customer-success/companies/${companyId}`}>{name}</Link>
                    ),
                },
                {
                    title: 'Remove',
                    render: (_, client) => <RemoveTeamClient client={client} teamId={teamId} />,
                },
            ]}
        />
    )
}

const AddTeamClient = ({
    teamId,
    addableClients,
    isLoading,
}: {
    teamId: string
    addableClients: { companyId: string; name: string }[] | undefined
    isLoading: boolean
}) => {
    const [companyId, setCompanyId] = useState<string | undefined>()

    const { mutateAsync: addClient, isLoading: isAdding } =
        bff.partnerTeamClients.addClient.useMutation({
            onSuccess({ data: { companyId: addedCompanyId } }) {
                setCompanyId(undefined)
                const { name } =
                    addableClients?.find((client) => client.companyId === addedCompanyId) ?? {}
                message.success(`Client "${name}" added to team`)
            },
        })

    return (
        <Flex align={'middle'} gap={8}>
            <Select
                allowClear
                showSearch
                aria-label="Search client to add"
                style={{ width: 220 }}
                placeholder="Search clients"
                notFoundContent="No un-added client found. Try another search"
                optionFilterProp="label"
                filterOption={(input, option) =>
                    (option?.label?.toLowerCase() ?? '').includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '')
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? '').toLowerCase())
                }
                loading={isLoading}
                options={addableClients?.map((client) => ({
                    label: client.name,
                    value: client.companyId,
                }))}
                onClear={() => setCompanyId(undefined)}
                onSelect={(value) => setCompanyId(value)}
            />
            <Button
                aria-label="Add client"
                type="primary"
                disabled={!companyId}
                loading={isAdding}
                onClick={async () => {
                    if (!companyId) return
                    await addClient({ teamId, companyId })
                }}
                icon={isAdding ? <LoadingOutlined /> : <UsergroupAddOutlined />}
            >
                Add
            </Button>
        </Flex>
    )
}

const RemoveTeamClient = ({
    client,
    teamId,
}: {
    client: { companyId: string; name: string }
    teamId: string
}) => {
    const { mutateAsync: removeClient } = bff.partnerTeamClients.removeClient.useMutation({
        onSuccess() {
            message.success(`Client "${client.name}" removed from team`)
        },
    })

    return (
        <ActionsContainer>
            <Button
                type="link"
                danger
                aria-label={`Remove ${client.name}`}
                onClick={() => {
                    confirm({
                        icon: <ExclamationCircleOutlined />,
                        title: `Are you sure you want to remove "${client.name}" from this team?`,
                        content:
                            'All members will lose access to this client, unless they are given access either directly or through another team.',
                        okText: 'Remove client',
                        okType: 'danger',
                        async onOk() {
                            await removeClient({ teamId, companyId: client.companyId })
                        },
                        width: 500,
                    })
                }}
            >
                Remove
            </Button>
        </ActionsContainer>
    )
}
