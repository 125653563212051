import { useParams } from 'react-router-dom'

import { ContextDetailProvider } from '../context'
import ContextHeader from './context-header'
import { ContextDataTable } from './context-data-table'
import { ContextForm } from './context-form/context-form'

export default function ContextDetail() {
    const { id } = useParams()

    return (
        <ContextDetailProvider>
            <ContextHeader id={id} />
            <ContextForm />
            <ContextDataTable />
        </ContextDetailProvider>
    )
}
