import { Button, Card, Checkbox, Spin, Tooltip, Typography, message } from 'antd'
import styled from 'styled-components'
import { CARD_STYLES } from './types'
import { useState } from 'react'

import { bff } from '../bff-hooks'
import Spinner from 'components/content-spinner'
import { useKycDetailContext } from '../context'
import type { KycChecksResponse } from '../index.bff'
import type { CheckboxChangeEvent } from 'antd/es/checkbox'

export const CheckList = () => {
    const { caseId, finaliseModal } = useKycDetailContext()
    const { data, isLoading, error } = bff.kycChecks.getCompletedChecks.useQuery({ caseId })

    if (error) {
        return (
            <Card size="small" style={CARD_STYLES.card}>
                <Typography.Text type="danger">Failed to load checks</Typography.Text>
            </Card>
        )
    }

    const disabled = !data?.isEditable || isLoading

    return (
        <Card
            title="Checks"
            size="small"
            style={CARD_STYLES.card}
            styles={{
                body: CARD_STYLES.body,
                actions: CARD_STYLES.actions,
            }}
            actions={[
                <Button
                    key="finalise"
                    onClick={() => finaliseModal.open()}
                    disabled={disabled}
                    type="primary"
                    block
                >
                    Finalise FDD
                </Button>,
            ]}
        >
            <Tooltip
                title={
                    disabled
                        ? 'Case must be In Progress and assigned to a user to update checks'
                        : ''
                }
                placement="bottom"
            >
                <Spin spinning={isLoading} indicator={<Spinner />} style={{ width: '100%' }}>
                    {data?.checks.map((check) => (
                        <CheckItem key={check.key} check={check} disabled={disabled} />
                    ))}
                </Spin>
            </Tooltip>
        </Card>
    )
}

export const CheckItem = ({
    check,
    disabled,
}: {
    check: KycChecksResponse['checks'][0]
    disabled: boolean
}) => {
    const { caseId } = useKycDetailContext()
    const [isChecked, setIsChecked] = useState(check.completed)
    const { mutateAsync, isLoading } = bff.kycChecks.updateCheck.useMutation({
        onError: () => {
            setIsChecked(!isChecked)
            message.error(`Failed to update ${check.name}`)
        },
    })

    const handleCheckChange = async (e: CheckboxChangeEvent) => {
        const newValue = e.target.checked
        setIsChecked(newValue)
        await mutateAsync({
            caseId,
            checkKey: check.key,
            value: newValue,
        })
    }

    return (
        <CheckItemWrapper>
            <Checkbox
                disabled={disabled || isLoading}
                checked={isChecked}
                onChange={handleCheckChange}
            >
                <Typography.Text>{check.name}</Typography.Text>
            </Checkbox>
        </CheckItemWrapper>
    )
}

const CheckItemWrapper = styled.div`
    display: flex;
    align-items: center;
    padding: 8px 0;

    &:not(:last-child) {
        /* stylelint-disable-next-line declaration-property-value-allowed-list */
        border-bottom: 1px solid #f0f0f0;
    }
`
