import { useSearchParams } from 'react-router-dom'

interface PaginationState {
    current: number
    pageSize: number
}

interface PaginationReturn {
    pagination: PaginationState
    set: (pagination: PaginationState) => void
    clear: () => void
}

export const DEFAULT_PAGE_SIZE = 10
export const DEFAULT_PAGE = 1

export const usePagination = (): PaginationReturn => {
    const [searchParams, setSearchParams] = useSearchParams()

    const current = Number(searchParams.get('page')) || DEFAULT_PAGE
    const pageSize = Number(searchParams.get('pageSize')) || DEFAULT_PAGE_SIZE

    const set = (pagination: PaginationState) => {
        const currentParams = new URLSearchParams(window.location.search)
        currentParams.set('page', pagination.current.toString())
        currentParams.set('pageSize', pagination.pageSize.toString())
        setSearchParams(currentParams)
    }

    const clear = () => {
        const currentParams = new URLSearchParams(window.location.search)
        currentParams.set('page', DEFAULT_PAGE.toString())
        setSearchParams(currentParams)
    }

    return {
        pagination: { current, pageSize },
        set,
        clear,
    }
}
