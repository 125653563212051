import type { FC } from 'react'
import { Table, Typography, Input, Space, Alert } from 'antd'
import { indexBy, prop } from 'ramda'
import dayjs from 'packages/dayjs'

import { deSnakify } from 'utils/strings'
import * as styx from 'types/styx'
import { useRiskAssessment } from 'services/deimos/styx-company/styx-company'
import { riskFactorNamesMap } from 'pages/compliance/company/risk-assessment/risk-check-form-v2/columns'
import type { RiskCheckData, RiskScore } from 'types/styx'

const { TextArea } = Input
const { Text } = Typography

interface Props {
    currentRiskScore: RiskScore
    dataSource: styx.RiskCheckData[]
}

export const RiskCheckForm: FC<React.PropsWithChildren<Props>> = ({
    currentRiskScore,
    dataSource,
}) => {
    return (
        <Space direction="vertical">
            <Alert
                message="Important"
                description="This risk assessment check form is no longer in use. To convert this to the new version of the form, please refresh the company structure in the Overview tab and then come back to this risk assessment tab."
                type="info"
                showIcon
            />
            <Table
                title={() => (
                    <>
                        <Text>Current risk score: </Text>{' '}
                        <Text strong>{deSnakify(currentRiskScore)}</Text>
                    </>
                )}
                dataSource={dataSource}
                pagination={false}
                rowKey="riskType"
                columns={[
                    {
                        title: 'Risk type',
                        dataIndex: 'riskType',
                        render: (riskType) => (
                            <Text>
                                {riskFactorNamesMap[riskType as keyof typeof riskFactorNamesMap] ||
                                    deSnakify(riskType)}
                            </Text>
                        ),
                    },
                    {
                        title: 'Level of risk',
                        dataIndex: 'score',
                        render: (score) =>
                            score ? <Text>{deSnakify(score)}</Text> : <Text>-</Text>,
                    },
                    {
                        title: 'Notes',
                        dataIndex: 'notes',
                        render: (notes) => {
                            return (
                                <TextArea
                                    value={notes}
                                    autoSize={{ maxRows: 5 }}
                                    disabled={true}
                                    placeholder="No note to display"
                                />
                            )
                        },
                    },
                    {
                        title: 'Owner',
                        dataIndex: 'ownerName',
                        render: (ownerName) =>
                            ownerName ? <Text>{ownerName}</Text> : <Text>-</Text>,
                    },
                    {
                        title: 'Check date',
                        dataIndex: 'checkDate',
                    },
                ]}
            />
        </Space>
    )
}

const RiskAssessmentFormContainer: FC<React.PropsWithChildren<unknown>> = () => {
    const { riskAssessment } = useRiskAssessment()

    const currentRiskScore = riskAssessment?.score ?? styx.RiskScore.NOT_ASSIGNED
    const riskTypes = Object.values(styx.RiskTypes)
    const riskCheckByType = indexBy(prop('riskType'), riskAssessment?.riskChecks ?? [])

    const dataSource: RiskCheckData[] = riskTypes.map((type) => ({
        riskType: type as styx.RiskTypes,
        score: riskCheckByType[type]?.score ?? undefined,
        notes: riskCheckByType[type]?.notes ?? '',
        ownerName: riskCheckByType[type]?.ownerName ?? '',
        hasExistingCheck: !!riskCheckByType[type],
        id: riskCheckByType[type]?.id ?? '',
        checkDate: dayjs(riskCheckByType[type]?.checkDate ?? undefined).format('lll'),
    }))

    return (
        <RiskCheckForm
            data-testid="risk-check-form-v1"
            key={riskAssessment?.assessmentDate}
            dataSource={dataSource}
            currentRiskScore={currentRiskScore}
        />
    )
}

export default RiskAssessmentFormContainer
