import { ArrowUpOutlined, FieldTimeOutlined } from '@ant-design/icons'
import type { Company360Overview } from 'bff/moons/generated/pandora-v1'
import { Card, Skeleton, Space, Statistic, Tooltip, Typography } from 'antd'
import dayjs from 'packages/dayjs'
import type { FC } from 'react'
import styled from 'styled-components'
import { formatMoneyIntl } from 'utils/money'
import { deSnakify } from 'utils/strings'
import { formatDate } from 'packages/dates/dates'
import { bff } from '../bff'
import { useParams } from 'react-router-dom'
import type { Money } from 'bff/moons/generated/ariel'

interface CommonProps {
    loading: boolean
}

const HeaderWrapper = styled('div')`
    margin: 0 24px 16px 0;
`

const { Text } = Typography

interface LastThreeMonthValueProps extends CommonProps {
    lastThreeMonthValue?: Money
}

const LastThreeMonthValue: FC<LastThreeMonthValueProps> = ({ loading, lastThreeMonthValue }) => {
    return (
        <Space direction="vertical" data-testid="monthly-recurring-revenue">
            <Statistic
                loading={loading}
                title={`Average for last 3 invoices: ${
                    lastThreeMonthValue?.currency ? `(${lastThreeMonthValue?.currency})` : ''
                }`}
                value={formatMoneyIntl(lastThreeMonthValue, { isMinorUnits: true }) || 'Unknown'}
            />
        </Space>
    )
}

interface UserCountProps extends CommonProps {
    usersDetails: Company360Overview['usersDetails']
}

const UserCount: FC<UserCountProps> = ({ loading, usersDetails }) => {
    return (
        <Space direction="vertical" data-testid="users">
            <Statistic
                loading={loading}
                title="Users"
                value={(usersDetails?.realUsersCount || 0) + (usersDetails?.adminCount || 0)}
                suffix={'/ ' + usersDetails?.billedUsersCount}
            />
            <Text type="success" data-testid="users-secondary">
                {usersDetails && (
                    <>
                        <ArrowUpOutlined />
                        {` ${usersDetails?.recentUserMovementCount} users`}{' '}
                        <Text type="secondary">
                            {dayjs(usersDetails?.recentMovementDate).fromNow()}
                        </Text>
                    </>
                )}
            </Text>
        </Space>
    )
}

interface WalletProps extends CommonProps {
    wallet?: Company360Overview['wallet']
}

const Wallet: FC<WalletProps> = ({ loading, wallet }) => {
    const { id: companyId } = useParams()
    const hasLastLoad = wallet?.lastLoadAmount
    const { data: companySettings, isLoading: isLoadingCompanySettings } =
        bff.headerStatistics.getWalletSettings.useQuery({ companyId: companyId, params: {} })

    return (
        <Space direction="vertical" data-testid="wallet-balance">
            <Statistic
                loading={loading}
                title={`Wallet balance ${
                    wallet?.balance?.currency ? `(${wallet?.balance?.currency})` : ''
                }`}
                value={formatMoneyIntl(wallet?.balance, { hideCurrency: true }) || ''}
            />
            {hasLastLoad && (
                <Text
                    data-testid="wallet-balance-secondary"
                    type="secondary"
                >{`Last loaded ${wallet?.lastLoadAmount?.value?.toLocaleString()} ${
                    wallet?.balance?.currency
                } ${dayjs(wallet?.lastLoadDate).fromNow()}`}</Text>
            )}
            <Text type="secondary">
                {isLoadingCompanySettings ? (
                    <Skeleton />
                ) : (
                    <Text>
                        {companySettings?.autoTopupStatus &&
                            `Auto top-up is ${deSnakify(
                                companySettings?.autoTopupStatus
                            ).toLowerCase()}`}{' '}
                        {companySettings?.autoTopUpStatusUpdatedAt && (
                            <Tooltip
                                title={`Last updated: ${formatDate(
                                    companySettings.autoTopUpStatusUpdatedAt
                                )}`}
                            >
                                <FieldTimeOutlined />
                            </Tooltip>
                        )}
                    </Text>
                )}
            </Text>
        </Space>
    )
}

const SpendChange = () => {
    const { id: companyId } = useParams()
    const { data, isLoading } = bff.headerStatistics.getCompanySpend.useQuery({ companyId })

    return (
        <Space direction="vertical" data-testid="spend-change">
            <Statistic
                loading={isLoading}
                title={`Spend this month ${
                    data?.currentMonthSpend?.currency
                        ? `(${data?.currentMonthSpend?.currency})`
                        : ''
                }`}
                value={
                    formatMoneyIntl(data?.currentMonthSpend, {
                        isMinorUnits: true,
                        hideCurrency: true,
                    }) || '-'
                }
            />
            <Text type="secondary" data-testid="spend-change-secondary">
                {data?.lastMonthSpend.currency
                    ? `${formatMoneyIntl(data?.lastMonthSpend, { isMinorUnits: true })} last month`
                    : ''}
            </Text>
        </Space>
    )
}

const gridStyle: React.CSSProperties = {
    width: '25%',
}

interface HeaderStatisticsProps extends CommonProps {
    customer?: Company360Overview
    lastThreeInvoicesValue?: Money
}

export const HeaderStatistics: FC<HeaderStatisticsProps> = ({
    loading,
    customer,
    lastThreeInvoicesValue,
}) => {
    return (
        <HeaderWrapper>
            <Card>
                <Card.Grid style={gridStyle} hoverable={false}>
                    <Wallet loading={loading} wallet={customer?.wallet} />
                </Card.Grid>
                <Card.Grid style={gridStyle} hoverable={false}>
                    <LastThreeMonthValue
                        loading={loading}
                        lastThreeMonthValue={lastThreeInvoicesValue}
                    />
                </Card.Grid>
                <Card.Grid style={gridStyle} hoverable={false}>
                    <UserCount loading={loading} usersDetails={customer?.usersDetails} />
                </Card.Grid>
                <Card.Grid style={gridStyle} hoverable={false}>
                    <SpendChange />
                </Card.Grid>
            </Card>
        </HeaderWrapper>
    )
}
