import { Table, Tag, Tooltip, Typography } from 'antd'
import { parse } from 'qs'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { bff } from '../bff-hooks'

import { useEffect, useState } from 'react'
import { debounce } from 'lodash'
import { formatTimestamp } from 'packages/dates/dates'
import dayjs from 'dayjs'
import { usePagination } from './use-pagination'
import styled from 'styled-components'
import { useColumns } from './use-columns'
import { COLUMNS } from './constants'
import { StpStatusTag } from '../header/status-bar'
import { blockedReasonOptions } from '../cannot-proceed/cannot-proceed'
import { calculateWeekdayHours } from '../utils'
import { QuestionCircleOutlined } from '@ant-design/icons'

const useFilterParams = () => {
    const [params] = useSearchParams()
    const [parsedParams, setParsedParams] = useState(parse(params.toString()))

    useEffect(() => {
        const handler = debounce(() => {
            setParsedParams(parse(params.toString()))
        }, 300)

        handler()

        return () => {
            handler.cancel()
        }
    }, [params])

    return parsedParams
}

export const DataTable = () => {
    const parsedParams = useFilterParams()
    const { pathTab } = useParams()
    const navigate = useNavigate()
    const { pagination, set: setPagination } = usePagination()
    const { shouldHideColumn } = useColumns()

    const { data, isLoading } = bff.dataTable.searchCddCases.useQuery({
        urlParams: parsedParams,
        pathTab: pathTab,
        fetchStpChecks: !shouldHideColumn(COLUMNS.STP_CHECKS),
    })

    return (
        <TableStyleProvider>
            <Table
                size="small"
                loading={isLoading}
                pagination={{
                    ...pagination,
                    total: data?.pagination?.total,
                    style: {
                        marginRight: '24px',
                    },
                    showTotal: (total) => `${total} cases`,
                    onChange: (page, pageSize) => setPagination({ current: page, pageSize }),
                }}
                onRow={(record) => ({
                    style: { cursor: 'pointer' },
                    onClick: (e) => {
                        if (e.metaKey || e.ctrlKey) {
                            window.open(`/compliance/kyc-processing-hub/case/${record.id}`)
                        } else {
                            navigate(`/compliance/kyc-processing-hub/case/${record.id}`)
                        }
                    },
                })}
                columns={[
                    {
                        title: 'Company',
                        dataIndex: 'companyName',
                        key: 'companyName',
                        render: (companyName, record) => (
                            <Tooltip
                                title={record.companyId}
                                placement="right"
                                overlayStyle={{ maxWidth: '100%' }}
                            >
                                {companyName}
                            </Tooltip>
                        ),
                        onCell: () => ({
                            style: { paddingLeft: '24px' },
                        }),
                        onHeaderCell: () => ({
                            style: { paddingLeft: '24px', borderRadius: '0' },
                        }),
                        width: '30%',
                    },
                    {
                        title: 'Cannot Proceed Reason',
                        dataIndex: COLUMNS.BLOCKED_REASON,
                        key: COLUMNS.BLOCKED_REASON,
                        render: (blockedReason) =>
                            blockedReason ? (
                                <Tag color="warning">
                                    {blockedReasonOptions.find(
                                        (option) => option.value === blockedReason
                                    )?.label ?? blockedReason}
                                </Tag>
                            ) : null,
                        width: '20%',
                        hidden: shouldHideColumn(COLUMNS.BLOCKED_REASON),
                    },
                    {
                        title: 'Assignee',
                        dataIndex: 'assignee',
                        key: 'assignee',
                        render: (assignee) => <Tag>{assignee}</Tag>,
                        width: '20%',
                        hidden: shouldHideColumn(COLUMNS.ASSIGNEE),
                    },
                    {
                        title: 'STP Checks',
                        dataIndex: 'hasStpRun',
                        key: 'hasStpRun',
                        render: (stpChecks: 'done' | 'notRun' | undefined) => (
                            <StpStatusTag hasStpRun={stpChecks} />
                        ),
                        width: '20%',
                        hidden: shouldHideColumn(COLUMNS.STP_CHECKS),
                    },
                    {
                        title: 'Updated',
                        dataIndex: 'updatedAt',
                        key: 'updatedAt',
                        width: '10%',
                        render: (updatedAt) => (
                            <Tooltip title={formatTimestamp(updatedAt)}>
                                {dayjs(updatedAt).fromNow()}
                            </Tooltip>
                        ),
                    },
                    {
                        title: 'Created',
                        dataIndex: 'createdAt',
                        key: 'createdAt',
                        width: '10%',
                        render: (createdAt) => (
                            <Tooltip title={formatTimestamp(createdAt)}>
                                {dayjs(createdAt).fromNow()}
                            </Tooltip>
                        ),
                    },
                    {
                        title: (
                            <Tooltip title="Weekday hours since case was last Ready for FDD or Information Received.">
                                <Typography.Text>Customer Wait Time</Typography.Text>
                                <QuestionCircleOutlined style={{ marginLeft: '4px' }} />
                            </Tooltip>
                        ),
                        dataIndex: COLUMNS.CUSTOMER_WAIT_TIME,
                        key: COLUMNS.CUSTOMER_WAIT_TIME,
                        width: '15%',
                        render: (customerWaitTime) => (
                            <Tooltip
                                title={
                                    customerWaitTime &&
                                    `Waiting since ${formatTimestamp(customerWaitTime)}`
                                }
                            >
                                {calculateWeekdayHours(customerWaitTime)}
                            </Tooltip>
                        ),
                        onHeaderCell: () => ({
                            style: { paddingRight: '24px', borderRadius: '0' },
                        }),
                        hidden: shouldHideColumn(COLUMNS.CUSTOMER_WAIT_TIME),
                    },
                ]}
                dataSource={data?.data}
            />
        </TableStyleProvider>
    )
}

const TableStyleProvider = styled.div`
    .ant-table-wrapper {
        min-height: 429px;
    }

    .ant-table {
        min-height: 429px;
    }
`
