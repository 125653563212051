import { RejectionReason } from 'bff/moons/generated/cdd-case-manager'
import { formatRejectionReason } from '../utils'

export type FddStatus = 'approved' | 'rejected'

export interface FinaliseFormData {
    fddStatus: FddStatus | undefined
    rejectionReason: RejectionReason | undefined
    comment: string | undefined
}

export const FDD_STATUS_OPTIONS = [
    { label: 'Approved', value: 'approved' },
    { label: 'Rejected', value: 'rejected' },
]

export const REJECTION_REASON_OPTIONS = Object.values(RejectionReason).map((reason) => ({
    label: formatRejectionReason(reason),
    value: reason,
}))
