import { useState } from 'react'
import { PageOrder, SearchCasesStateItem } from 'bff/moons/generated/case-manager'
import { bff } from './bff-hooks'
import dayjs from 'dayjs'
import { OddListTab } from 'features/odd/types'
import {
    FilterParamType,
    ReminderStatusType,
    SearchParamType,
    tabNameToCasesStateMapper,
} from './types'

export const DEFAULT_PAGE_SIZE = 10

export const mapStateToTab = (state: SearchCasesStateItem[]) => {
    if (!state) return OddListTab.new

    const stateKey = Object.keys(tabNameToCasesStateMapper).find((key) =>
        tabNameToCasesStateMapper[key].includes(state[0])
    )

    return stateKey || OddListTab.new
}

export const getSorting = () => ({
    [SearchParamType.sorting_keys]: ['due_date'],
    [SearchParamType.sorting_order]: [PageOrder.ASC],
})

export interface FilterParams {
    [FilterParamType.country]?: string
    [FilterParamType.state]?: SearchCasesStateItem[]
    [FilterParamType.dueDateFrom]?: string
    [FilterParamType.dueDateTo]?: string
    [FilterParamType.lastStateChangeAtFrom]?: string
    [FilterParamType.lastStateChangeAtTo]?: string
    [FilterParamType.assignedAtFrom]?: string
    [FilterParamType.assignedAtTo]?: string
    [FilterParamType.assigneeId]?: string
    [FilterParamType.lastAssigneeId]?: string
    [FilterParamType.entityId]?: string
    [FilterParamType.unassigned_only]?: boolean
    [FilterParamType.companyName]?: string
    [FilterParamType.previousRiskScore]?: string
    [FilterParamType.preselectTimeRange]?: string
    [FilterParamType.waitStatus]?: string
    [FilterParamType.includeBlocked]?: boolean
    [FilterParamType.onlyBlocked]?: boolean
}

const getUrlParams = () => {
    const urlSearchParams = new URLSearchParams(window.location.search)

    return {
        countryUrlParam: urlSearchParams.get(FilterParamType.country) || undefined,
        stateUrlParam:
            urlSearchParams.get(FilterParamType.state) ||
            tabNameToCasesStateMapper['new'].join(','),
        dueDateFromUrlParam: urlSearchParams.get(FilterParamType.dueDateFrom) || undefined,
        dueDateToUrlParam: urlSearchParams.get(FilterParamType.dueDateTo) || undefined,
        lastStateChangeAtFromUrlParam:
            urlSearchParams.get(FilterParamType.lastStateChangeAtFrom) || undefined,
        lastStateChangeAtToUrlParam:
            urlSearchParams.get(FilterParamType.lastStateChangeAtTo) || undefined,
        assignedAtFromUrlParam: urlSearchParams.get(FilterParamType.assignedAtFrom) || undefined,
        assignedAtToUrlParam: urlSearchParams.get(FilterParamType.assignedAtTo) || undefined,
        assigneeIdUrlParam: urlSearchParams.get(FilterParamType.assigneeId) || undefined,
        lastAssigneeIdUrlParam: urlSearchParams.get(FilterParamType.lastAssigneeId) || undefined,
        entityIdUrlParam: urlSearchParams.get(FilterParamType.entityId) || undefined,
        unassignedOnlyUrlParam:
            urlSearchParams.get(FilterParamType.unassigned_only) === 'true' ? true : undefined,
        companyNameUrlParam: urlSearchParams.get(FilterParamType.companyName) || undefined,
        previousRiskScoreUrlParam:
            urlSearchParams.get(FilterParamType.previousRiskScore) || undefined,
        preselectTimeRangeUrlParam:
            urlSearchParams.get(FilterParamType.preselectTimeRange) || undefined,
        waitStatusUrlParam: urlSearchParams.get(FilterParamType.waitStatus) || undefined,
        includeBlockedUrlParam:
            urlSearchParams.get(FilterParamType.includeBlocked) === 'true' ? true : undefined,
        onlyBlockedUrlParam:
            urlSearchParams.get(FilterParamType.onlyBlocked) === 'true' ? true : undefined,
    }
}

export const mapUrlParamsToSearchParams = () => {
    const {
        countryUrlParam,
        stateUrlParam,
        dueDateFromUrlParam,
        dueDateToUrlParam,
        lastStateChangeAtFromUrlParam,
        lastStateChangeAtToUrlParam,
        assignedAtFromUrlParam,
        assignedAtToUrlParam,
        assigneeIdUrlParam,
        lastAssigneeIdUrlParam,
        entityIdUrlParam,
        unassignedOnlyUrlParam,
        companyNameUrlParam,
        previousRiskScoreUrlParam,
        preselectTimeRangeUrlParam,
        waitStatusUrlParam,
        includeBlockedUrlParam,
        onlyBlockedUrlParam,
    } = getUrlParams()

    const hasMetadataInUrl = companyNameUrlParam || previousRiskScoreUrlParam || waitStatusUrlParam

    return {
        [SearchParamType.metadata]: hasMetadataInUrl
            ? JSON.stringify({
                  companyName: companyNameUrlParam,
                  previousRiskScore: previousRiskScoreUrlParam,
                  waitStatus: waitStatusUrlParam,
              })
            : undefined,
        [SearchParamType.country_code]: countryUrlParam?.split(',') || undefined,
        [SearchParamType.assigneeId]: assigneeIdUrlParam,
        [SearchParamType.lastAssigneeId]: lastAssigneeIdUrlParam,
        [SearchParamType.entityId]: entityIdUrlParam,
        [SearchParamType.state]: stateUrlParam?.split(',') as SearchCasesStateItem[],
        [SearchParamType.include_deleted]: false,
        [SearchParamType.unassigned_only]: unassignedOnlyUrlParam,
        [SearchParamType.offset]: undefined,
        [SearchParamType.limit]: DEFAULT_PAGE_SIZE,
        [SearchParamType.preselectTimeRange]: preselectTimeRangeUrlParam,
        [SearchParamType.due_from]: dueDateFromUrlParam
            ? dayjs.utc(dueDateFromUrlParam)?.startOf('D').toISOString()
            : undefined,
        [SearchParamType.due_to]: dueDateToUrlParam
            ? dayjs.utc(dueDateToUrlParam)?.endOf('D').toISOString()
            : undefined,
        [SearchParamType.last_state_change_at_from]: lastStateChangeAtFromUrlParam
            ? dayjs.utc(lastStateChangeAtFromUrlParam)?.startOf('D').toISOString()
            : undefined,
        [SearchParamType.last_state_change_at_to]: lastStateChangeAtToUrlParam
            ? dayjs.utc(lastStateChangeAtToUrlParam)?.endOf('D').toISOString()
            : undefined,
        [SearchParamType.assigned_at_from]: assignedAtFromUrlParam
            ? dayjs.utc(assignedAtFromUrlParam)?.startOf('D').toISOString()
            : undefined,
        [SearchParamType.assigned_at_to]: assignedAtToUrlParam
            ? dayjs.utc(assignedAtToUrlParam)?.endOf('D').toISOString()
            : undefined,
        [SearchParamType.includeBlocked]: includeBlockedUrlParam,
        [SearchParamType.onlyBlocked]: onlyBlockedUrlParam,
    }
}

export const checkBlocked = (includeBlocked?: boolean, onlyBlocked?: boolean) => {
    if (onlyBlocked === true) return true
    if (includeBlocked === true) return undefined
    return false
}

export const useFilters = () => {
    const {
        countryUrlParam,
        stateUrlParam,
        dueDateFromUrlParam,
        dueDateToUrlParam,
        lastStateChangeAtFromUrlParam,
        lastStateChangeAtToUrlParam,
        assignedAtFromUrlParam,
        assignedAtToUrlParam,
        assigneeIdUrlParam,
        lastAssigneeIdUrlParam,
        entityIdUrlParam,
        unassignedOnlyUrlParam,
        companyNameUrlParam,
        previousRiskScoreUrlParam,
        preselectTimeRangeUrlParam,
        waitStatusUrlParam,
        includeBlockedUrlParam,
        onlyBlockedUrlParam,
    } = getUrlParams()

    const [activeTab, setActiveTab] = useState(
        mapStateToTab(
            (stateUrlParam?.split(',') as SearchCasesStateItem[]) || SearchCasesStateItem.NEW
        )
    )

    const [searchParams, setSearchParams] = useState({
        ...mapUrlParamsToSearchParams(),
        ...getSorting(),
    })

    const { data, isFetching } = bff.oddListPage.searchOddList.useQuery(
        {
            ...searchParams,
            blocked: checkBlocked(includeBlockedUrlParam, onlyBlockedUrlParam),
        },
        {
            refetchOnWindowFocus: false,
        }
    )

    const updateSearchParams = (params: Record<string, any>) =>
        setSearchParams((prev) => ({ ...prev, ...params }))

    const updateUrl = (params: FilterParams) => {
        const currentParams = {
            [FilterParamType.country]: countryUrlParam?.split(','),
            [FilterParamType.state]: stateUrlParam?.split(',') as SearchCasesStateItem[],
            [FilterParamType.dueDateFrom]: dueDateFromUrlParam,
            [FilterParamType.dueDateTo]: dueDateToUrlParam,
            [FilterParamType.lastStateChangeAtFrom]: lastStateChangeAtFromUrlParam,
            [FilterParamType.lastStateChangeAtTo]: lastStateChangeAtToUrlParam,
            [FilterParamType.assignedAtFrom]: assignedAtFromUrlParam,
            [FilterParamType.assignedAtTo]: assignedAtToUrlParam,
            [FilterParamType.assigneeId]: assigneeIdUrlParam,
            [FilterParamType.lastAssigneeId]: lastAssigneeIdUrlParam,
            [FilterParamType.entityId]: entityIdUrlParam,
            [FilterParamType.unassigned_only]: unassignedOnlyUrlParam,
            [FilterParamType.companyName]: companyNameUrlParam,
            [FilterParamType.previousRiskScore]: previousRiskScoreUrlParam,
            [FilterParamType.preselectTimeRange]: preselectTimeRangeUrlParam,
            [FilterParamType.waitStatus]: waitStatusUrlParam,
            [FilterParamType.includeBlocked]: includeBlockedUrlParam,
            [FilterParamType.onlyBlocked]: onlyBlockedUrlParam,
        }

        const newParams = {
            ...currentParams,
            ...params,
        }

        const newSearchParams = new URLSearchParams()

        Object.keys(newParams).forEach((key) => {
            const typedKey = key as keyof FilterParams
            if (newParams[typedKey] && Array.isArray(newParams[typedKey])) {
                newSearchParams.set(key, (newParams[typedKey] as string[]).join(','))
            } else if (newParams[typedKey]) newSearchParams.set(key, newParams[typedKey] as string)
        })

        window.history.pushState({}, '', `?${newSearchParams.toString()}`)
    }

    const clearParams = () => {
        const clearSearchParams = new URLSearchParams()

        Object.keys(FilterParamType).forEach((key) => {
            // Exception for waitStatus because of custom wallet blocked due tab behavior
            if (key !== FilterParamType.waitStatus) {
                clearSearchParams.delete(key)
            } else {
                if (activeTab !== OddListTab.walletBlockedDue) {
                    clearSearchParams.delete(FilterParamType.waitStatus)
                } else {
                    clearSearchParams.set(
                        FilterParamType.waitStatus,
                        ReminderStatusType.WALLET_BLOCKED_DUE
                    )
                }
            }
        })

        clearSearchParams.set(FilterParamType.state, tabNameToCasesStateMapper[activeTab].join(','))

        window.history.pushState({}, '', `?${clearSearchParams.toString()}`)

        updateSearchParams({
            [SearchParamType.metadata]:
                activeTab === OddListTab.walletBlockedDue
                    ? JSON.stringify({ waitStatus: ReminderStatusType.WALLET_BLOCKED_DUE })
                    : undefined,
            [SearchParamType.state]: tabNameToCasesStateMapper[activeTab],
            [SearchParamType.unassigned_only]: undefined,
            [SearchParamType.assigneeId]: undefined,
            [SearchParamType.lastAssigneeId]: undefined,
            [SearchParamType.entityId]: undefined,
            [SearchParamType.offset]: undefined,
            [SearchParamType.limit]: DEFAULT_PAGE_SIZE,
            [SearchParamType.due_from]: undefined,
            [SearchParamType.due_to]: undefined,
            [SearchParamType.last_state_change_at_from]: undefined,
            [SearchParamType.last_state_change_at_to]: undefined,
            [SearchParamType.assigned_at_from]: undefined,
            [SearchParamType.assigned_at_to]: undefined,
            [SearchParamType.country_code]: undefined,
            [SearchParamType.includeBlocked]: true,
        })
    }

    const updateActiveTab = (key: OddListTab) => {
        updateUrl({
            [FilterParamType.state]: tabNameToCasesStateMapper[key].map((status) => status),
            [FilterParamType.dueDateFrom]: key === OddListTab.new ? undefined : dueDateFromUrlParam,
            [FilterParamType.dueDateTo]:
                key === OddListTab.new ? dayjs().format('YYYY-MM-DD') : dueDateToUrlParam,
            [FilterParamType.preselectTimeRange]:
                key === OddListTab.new ? 'overdue' : preselectTimeRangeUrlParam,
            [FilterParamType.waitStatus]:
                key === OddListTab.walletBlockedDue
                    ? ReminderStatusType.WALLET_BLOCKED_DUE
                    : undefined,
            [FilterParamType.onlyBlocked]: key === OddListTab.blocked ? true : undefined,
        })
        updateSearchParams({
            [SearchParamType.state]: tabNameToCasesStateMapper[key].map((status) => status),
            [SearchParamType.due_from]: key === OddListTab.new ? undefined : searchParams?.due_from,
            [SearchParamType.due_to]:
                key === OddListTab.new ? dayjs().toISOString() : searchParams?.due_to,
            [SearchParamType.offset]: undefined,
            [SearchParamType.limit]: DEFAULT_PAGE_SIZE,
            [SearchParamType.onlyBlocked]: key === OddListTab.blocked ? 'true' : undefined,
            [SearchParamType.metadata]:
                key === OddListTab.walletBlockedDue
                    ? JSON.stringify({
                          ...JSON.parse(searchParams?.metadata || '{}'),
                          waitStatus: ReminderStatusType.WALLET_BLOCKED_DUE,
                      })
                    : JSON.stringify({
                          ...JSON.parse(searchParams?.metadata || '{}'),
                          waitStatus: undefined,
                      }),
        })
        setActiveTab(key)
    }

    return {
        filters: {
            [FilterParamType.country]: countryUrlParam,
            [FilterParamType.state]: stateUrlParam?.split(',') as SearchCasesStateItem[],
            [FilterParamType.dueDateTo]: dueDateToUrlParam,
            [FilterParamType.dueDateFrom]: dueDateFromUrlParam,
            [FilterParamType.lastStateChangeAtFrom]: lastStateChangeAtFromUrlParam,
            [FilterParamType.lastStateChangeAtTo]: lastStateChangeAtToUrlParam,
            [FilterParamType.assignedAtFrom]: assignedAtFromUrlParam,
            [FilterParamType.assignedAtTo]: assignedAtToUrlParam,
            [FilterParamType.assigneeId]: assigneeIdUrlParam,
            [FilterParamType.lastAssigneeId]: lastAssigneeIdUrlParam,
            [FilterParamType.entityId]: entityIdUrlParam,
            [FilterParamType.unassigned_only]: unassignedOnlyUrlParam,
            [FilterParamType.companyName]: companyNameUrlParam,
            [FilterParamType.previousRiskScore]: previousRiskScoreUrlParam,
            [FilterParamType.preselectTimeRange]: preselectTimeRangeUrlParam,
            [FilterParamType.waitStatus]: waitStatusUrlParam,
            [FilterParamType.includeBlocked]: includeBlockedUrlParam,
            [FilterParamType.onlyBlocked]: onlyBlockedUrlParam,
        },
        data,
        isFetching,
        activeTab,
        updateSearchParams,
        updateUrl,
        clearParams,
        updateActiveTab,
    }
}
