import { useEffect, useState } from 'react'
import { Alert, Button, Card, Input, message, Modal } from 'antd'

import { useUser } from 'providers/user-context'
import {
    useDemoAccounts,
    defaultLimit,
    useCreateDemoAccount,
    useDemoAccount,
} from 'services/dionysus/demo-accounts'
import CursorPaginationControls from 'components/cursor-pagination-controls'
import { ContentContainer, PageContentLayout } from 'components/layout-containers'

import ListDemoAccounts from './list-demo-accounts/list-demo-accounts'
import GenerateDemo from './generate-demo/generate-demo'
import { formValuesToCreateDemoAccountRequest } from './helpers'
import type { FormValuesExtended } from './generate-demo/generate-demo-form'
import config from 'config'
import styled from 'styled-components'
import { DemoAccountModal } from './single-demo-account/components/demo-account-modal'
import { useNavigate } from 'react-router-dom'
import { isUuid } from 'utils/is-uuid'
import { useDebounce } from 'utils/use-debounce'
import { SearchOutlined } from '@ant-design/icons'
import { useColorScheme } from 'hooks/use-color-scheme'
import type { DemoAccountJobResponse } from '../../../types/demo-accounts'

const TESTFLIGHT_URL = config.testFlight?.appUrl
const WEB_APP_LOGIN_URL = config.demoWebApp.url

const DemoAccounts = () => {
    const { shouldUseDarkMode } = useColorScheme()
    const [activeModalDemoAccountId, setActiveModalDemoAccountId] = useState<string | null>(null)
    const [searchString, setSearchString] = useState('')
    const [requesterEmailSearchString, setRequesterEmailSearchString] = useState<string | null>(
        null
    )
    const [companyNameSearchString, setCompanyNameSearchString] = useState<string | null>(null)
    const [companyIdSearchString, setCompanyIdSearchString] = useState<string | null>(null)
    const [createDemoAccountJobResponses, setCreateDemoAccountJobResponses] = useState<
        DemoAccountJobResponse[]
    >([])

    useEffect(() => {
        if (searchString.length) {
            if (isUuid(searchString)) {
                setCompanyIdSearchString(searchString)
                setCompanyNameSearchString(null)
                setRequesterEmailSearchString(null)
            } else if (
                ['@pleo.io', '@ext.pleo.io', '@pleo.dev'].some((domain) =>
                    searchString.includes(domain)
                )
            ) {
                setCompanyIdSearchString(null)
                setCompanyNameSearchString(null)
                setRequesterEmailSearchString(searchString)
            } else {
                setCompanyIdSearchString(null)
                setCompanyNameSearchString(searchString)
                setRequesterEmailSearchString(null)
            }
        } else {
            setRequesterEmailSearchString(null)
            setCompanyNameSearchString(null)
            setCompanyIdSearchString(null)
        }
    }, [searchString])

    const navigate = useNavigate()

    const { email: userEmail } = useUser()

    const {
        scheduleCreateDemoAccountJob,
        loading: loadingCreateDemoAccount,
        error: createDemoAccountJobError,
    } = useCreateDemoAccount()

    const {
        demoAccounts,
        nextPage,
        previousPage,
        updateLimit,
        pagination,
        updateSorting,
        firstPage,
        mutate: updateDemoAccountsList,
        mutations,
        isValidating,
    } = useDemoAccounts({
        requesterEmail: companyIdSearchString ? null : requesterEmailSearchString ?? userEmail,
        companyName: companyNameSearchString,
        companyId: companyIdSearchString,
    })

    const { data: demoAccount } = useDemoAccount(activeModalDemoAccountId)

    const debouncedSearchString = useDebounce(searchString, 300)

    useEffect(() => {
        updateDemoAccountsList().catch((e) => {
            message.error('Something went fetching demo accounts: ' + e.message)
        })
    }, [debouncedSearchString, updateDemoAccountsList])

    const limit = pagination?.currentRequestPagination.limit ?? defaultLimit

    const onChangeLimit = (newLimit: number) => updateLimit(newLimit)

    const onSubmitExtended = async (values: FormValuesExtended) => {
        if (userEmail) {
            const createDemoAccountRequest = formValuesToCreateDemoAccountRequest(values, userEmail)

            const response = await scheduleCreateDemoAccountJob({
                request: createDemoAccountRequest,
            })

            if (response) {
                setCreateDemoAccountJobResponses((prev) => [...prev, response])
            }
        }
    }
    const onViewCompany = (demoAccountId: string, companyId: string) => {
        if (config.demoAccounts.useSingleAccountModal) {
            setActiveModalDemoAccountId(demoAccountId)
        } else {
            navigate(`${demoAccountId}/companies/${companyId}`)
        }
    }

    const onCancelModal = () => setActiveModalDemoAccountId(null)

    const isSharedAccount = Boolean(userEmail?.includes('backoffice.'))

    return (
        <>
            <PageContentLayout>
                {createDemoAccountJobResponses.map((it, i) => (
                    <Alert
                        key={i}
                        showIcon
                        closable
                        message={`Successfully scheduled demo account creation.`}
                        description={
                            isSharedAccount
                                ? `Your account should show up in the list below in a couple of minutes. If it doesn't, or if you have any questions, please reach out to #ask-demo-experience with job id ${it.jobId}`
                                : `You will receive login details on Slack in a couple of minutes. If you have any questions, please reach out to #ask-demo-experience with job id ${it.jobId}`
                        }
                        type="success"
                    />
                ))}
                {createDemoAccountJobError ? (
                    <Alert
                        showIcon
                        closable
                        message={`Something went wrong when creating the demo account.`}
                        description={`Please try again a bit later or reach out to #ask-demo-experience. Error message: ${createDemoAccountJobError}`}
                        type="error"
                    />
                ) : null}
                <Card title="Generate Demo Account">
                    <GenerateDemo
                        loading={loadingCreateDemoAccount}
                        onSubmitExtended={onSubmitExtended}
                    />
                </Card>
                <Card
                    title="Demo Accounts"
                    extra={
                        <Row>
                            <StyledSearchInput
                                prefix={
                                    <SearchOutlined
                                        style={{
                                            color: shouldUseDarkMode
                                                ? 'rgba(255, 255, 255, 0.25)'
                                                : 'rgba(0,0,0,.25)',
                                        }}
                                    />
                                }
                                placeholder="Company name/id, or requester email"
                                value={searchString}
                                onChange={(e) => setSearchString(e.target.value)}
                            />
                            <Button type="primary" key="login-button">
                                <a
                                    href={WEB_APP_LOGIN_URL}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Login to Web demo
                                </a>
                            </Button>
                            {TESTFLIGHT_URL ? (
                                <Button type="primary" key="login-button">
                                    <a
                                        href={TESTFLIGHT_URL}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Login to Mobile App demo
                                    </a>
                                </Button>
                            ) : null}
                        </Row>
                    }
                >
                    <ContentContainer>
                        <ListDemoAccounts
                            updateExpiresAt={mutations.updateExpiresAt}
                            loading={isValidating}
                            demoAccounts={demoAccounts}
                            updateSorting={updateSorting}
                            onViewCompany={onViewCompany}
                        />
                        {pagination && (
                            <CursorPaginationControls
                                isFetching={!demoAccounts}
                                pagination={pagination}
                                onChangeLimit={onChangeLimit}
                                limit={limit}
                                onNextPage={nextPage}
                                onPrevPage={previousPage}
                                onFirstPage={firstPage}
                            />
                        )}
                    </ContentContainer>
                </Card>
            </PageContentLayout>
            <Modal
                open={Boolean(activeModalDemoAccountId)}
                onCancel={onCancelModal}
                footer={false}
                width={800}
                centered
            >
                <DemoAccountModal demoAccount={demoAccount} />
            </Modal>
        </>
    )
}

export default DemoAccounts

const StyledSearchInput = styled(Input)`
    width: 300px;
`

const Row = styled.div`
    display: flex;
    gap: 0.5rem;
`
