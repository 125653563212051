import { CopyOutlined, FormOutlined } from '@ant-design/icons'
import { Breadcrumb, Button, Card, message, Space, Typography } from 'antd'
import { Link } from 'react-router-dom'

const { Title } = Typography

function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLocaleLowerCase()
}

function copyUrl() {
    const url = window.location.href
    navigator.clipboard.writeText(url)
    message.success('URL copied to clipboard')
}

export default function ContextHeader({ id }: { id: string }) {
    return (
        <>
            <Card>
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to={'/customer-success/investigation-portal'}>
                            Investigation Portal
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>{capitalizeFirstLetter(id)}</Breadcrumb.Item>
                </Breadcrumb>
                <br />
                <Space
                    direction="horizontal"
                    size={0}
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                    <Title level={3} style={{ margin: 0 }}>
                        <FormOutlined /> {capitalizeFirstLetter(id)}
                    </Title>
                    <Button type="default" onClick={() => copyUrl()}>
                        <CopyOutlined /> Copy URL
                    </Button>
                </Space>
            </Card>
        </>
    )
}
