import React, { useState } from 'react'
import {
    Modal,
    Space,
    Input,
    Alert,
    Button,
    Typography,
    Timeline,
    message,
    Steps,
    Tag,
    Row,
    Col,
    List,
    Result,
} from 'antd'
import {
    AlertOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
    CommentOutlined,
    ExclamationCircleOutlined,
    FieldTimeOutlined,
    HourglassOutlined,
    InfoCircleOutlined,
    MailOutlined,
} from '@ant-design/icons'

import { bff } from './bff-hooks'
import { useOddDetailContext } from './context-detail'
import { useOddListContext } from './context-list'
import { formatDate, formatTimestamp } from 'packages/dates/dates'
import type { CaseAuditEventType } from 'bff/moons/generated/case-manager'
import { Link } from 'react-router-dom'

const { Text } = Typography

const MIN_COMMENT_LENGTH_CHARACTERS = 30

export const ConclusionModal = () => {
    const {
        caseId,
        conclusionModal: { isOpen, close },
    } = useOddDetailContext()
    const [comment, setComment] = useState('')
    const { mutateAsync: setCaseAsConcluded, isLoading } =
        bff.modals.setCaseAsConcluded.useMutation()

    const handleConclude = async () => {
        await setCaseAsConcluded({ caseId, comment })
        close()
    }

    const canConclude = comment.length >= MIN_COMMENT_LENGTH_CHARACTERS

    return (
        <Modal
            open={isOpen}
            onCancel={close}
            onOk={handleConclude}
            title="Conclude this ODD case?"
            okText={'Conclude'}
            okButtonProps={{ disabled: !canConclude, loading: isLoading }}
            destroyOnClose
        >
            <Space direction="vertical" style={{ width: '100%' }}>
                <label htmlFor="conclusion-comment">
                    <Text>Add a comment (required)</Text>
                </label>
                <Input.TextArea
                    id="conclusion-comment"
                    placeholder="Add a comment"
                    minLength={MIN_COMMENT_LENGTH_CHARACTERS}
                    showCount
                    style={{ marginBottom: '1rem' }}
                    onChange={(e) => setComment(e.target.value)}
                />
            </Space>
        </Modal>
    )
}

export const OffboardingModal = () => {
    const {
        caseId,
        companyId,
        offboardingModal: { isOpen, close },
    } = useOddDetailContext()
    const [comment, setComment] = useState('')
    const { data: caseStateData } = bff.modals.getCaseState.useQuery({ caseId })
    const { data: offboardingData } = bff.modals.getOffboardingModalData.useQuery({
        companyId,
        caseId,
    })

    const {
        mutateAsync: setCaseAsOffboardingInitiated,
        isLoading: isSetCaseAsOffboardingInitiatedLoading,
    } = bff.modals.setCaseAsOffboardingInitiated.useMutation()

    const { mutateAsync: setCaseAsOffboarded, isLoading } =
        bff.modals.setCaseAsOffboarded.useMutation()

    const handleOffboardingInitiated = async () => {
        await setCaseAsOffboardingInitiated({ caseId })
    }

    const handleSubmit = async () => {
        await setCaseAsOffboarded({ caseId, comment })
        close()
    }

    const offboardingSteps = {
        WAITING_FOR_CUSTOMER: 0,
        WALLET_BLOCKED: 2,
        OFFBOARDING_INITIATED: 3,
        OFFBOARDING_COMPLETED: 4,
    }

    const currentOffboardingIndex =
        offboardingSteps[caseStateData?.state as keyof typeof offboardingSteps]

    return (
        <Modal
            open={isOpen}
            onCancel={close}
            onOk={handleSubmit}
            title="Offboarding"
            okText={'Complete offboarding'}
            okButtonProps={{
                loading: isLoading,
                disabled:
                    comment.length < MIN_COMMENT_LENGTH_CHARACTERS || currentOffboardingIndex !== 3,
            }}
        >
            <Steps
                direction="vertical"
                size="small"
                current={currentOffboardingIndex}
                items={[
                    {
                        title: 'Waiting for customer',
                        description: (
                            <>
                                <p>
                                    This case has been flagged as missing customer information. An
                                    information request was sent to company admins on{' '}
                                    {formatTimestamp(offboardingData?.waitingForCustomer?.since)}.
                                    If customer information is not provided, the following actions
                                    will occur:
                                </p>
                                <ul style={{ padding: 0, margin: 0 }}>
                                    <li>
                                        <strong>
                                            ~{' '}
                                            {formatDate(
                                                offboardingData?.waitingForCustomer?.firstReminder
                                            )}
                                            :
                                        </strong>{' '}
                                        <span>First reminder to submit information</span>
                                    </li>
                                    <li>
                                        <strong>
                                            ~{' '}
                                            {formatDate(
                                                offboardingData?.waitingForCustomer?.secondReminder
                                            )}
                                            :
                                        </strong>{' '}
                                        <span>Second reminder to submit information</span>
                                    </li>
                                    <li>
                                        <strong>
                                            ~{' '}
                                            {formatDate(
                                                offboardingData?.waitingForCustomer?.walledBlocked
                                            )}
                                            :
                                        </strong>{' '}
                                        <span>Customer wallet will be automatically blocked</span>
                                    </li>
                                </ul>
                            </>
                        ),
                    },
                    {
                        title: 'Wallet blocked',
                        description: (
                            <>
                                <p>
                                    After the case has been waiting for customer information for 15
                                    days, the wallet will be automatically blocked.
                                </p>
                                <p>
                                    Wallet Status:{' '}
                                    <Tag
                                        color={
                                            offboardingData?.spendStatus === 'BLOCKED'
                                                ? 'success'
                                                : 'warning'
                                        }
                                    >
                                        {offboardingData?.spendStatus}
                                    </Tag>
                                </p>
                            </>
                        ),
                    },
                    {
                        title: 'Offboarding initiated',
                        description: (
                            <>
                                {currentOffboardingIndex < 3 ? (
                                    <Space direction="vertical">
                                        <p>
                                            Click below to indicate that offboarding has been
                                            initiated for this customer.
                                        </p>
                                        <Button
                                            disabled={caseStateData?.state !== 'WALLET_BLOCKED'}
                                            onClick={handleOffboardingInitiated}
                                            loading={isSetCaseAsOffboardingInitiatedLoading}
                                            type="primary"
                                        >
                                            Initiate offboarding
                                        </Button>
                                    </Space>
                                ) : (
                                    <p>
                                        Offboarding was initiated for this customer on{' '}
                                        {formatTimestamp(
                                            offboardingData?.offboardingInitiated?.since
                                        )}
                                        .
                                    </p>
                                )}
                            </>
                        ),
                    },
                    {
                        title: 'Complete offboarding',
                        description: (
                            <>
                                {offboardingData?.offboardingInitiated?.canCompleteOffboarding ? (
                                    <>
                                        <p>
                                            Two months has passed since offboarding was initiated.
                                            The case can now be completed by marking the offboarding
                                            as complete. Please explain why this company is being
                                            offboarded. 30 character minimum.
                                        </p>
                                        <br />
                                        <Text>
                                            <Text strong>Comment</Text> (min 30 characters)
                                        </Text>
                                        <Input.TextArea
                                            placeholder="Add a comment"
                                            minLength={MIN_COMMENT_LENGTH_CHARACTERS}
                                            showCount
                                            style={{
                                                marginBottom: '1rem',
                                            }}
                                            onChange={(e) => setComment(e.target.value)}
                                        />
                                    </>
                                ) : (
                                    <p>
                                        You must wait until 2 months after offboarding was iniated
                                        before offboarding can be marked as complete.
                                    </p>
                                )}
                            </>
                        ),
                    },
                ]}
            />
        </Modal>
    )
}

export const SendRequestInformationModal = () => {
    const {
        caseId,
        sendRequestInformationModal: { isOpen, close },
    } = useOddDetailContext()

    const { data: caseStateData } = bff.modals.getCaseState.useQuery({ caseId })
    const { mutateAsync: requestInformation, isLoading } =
        bff.modals.sendRequestInformation.useMutation()

    const handleSubmit = async () => {
        try {
            await requestInformation({ caseId })
            message.success('Successfully requested missing information from company admins.')
        } catch (error) {
            message.error('Failed to request missing information from company admins.')
        }
        close()
    }

    const canRequest =
        caseStateData && ['IN_PROGRESS', 'INFORMATION_RECEIVED'].includes(caseStateData?.state)
    const isDisabled = isLoading || !canRequest

    return (
        <Modal
            open={isOpen}
            onCancel={close}
            onOk={handleSubmit}
            title={'Request Missing KYC Information?'}
            okText={'Send'}
            okButtonProps={{
                loading: isLoading,
                disabled: isDisabled,
            }}
        >
            <Space direction="vertical" style={{ width: '100%' }}>
                <Text>Notify all company admins to request the Missing KYC Information?</Text>
                <Text style={{ fontSize: '0.875em' }} type="secondary">
                    Admins will be redirected to upload their documents in the Pleo app.
                </Text>

                <Alert
                    type="info"
                    icon={<FieldTimeOutlined />}
                    showIcon={true}
                    description={<Text>Automatic reminders will be sent after 7 and 14 days.</Text>}
                />
                {!canRequest && (
                    <Alert
                        type="warning"
                        icon={<ExclamationCircleOutlined />}
                        showIcon={true}
                        description={
                            <Text>
                                Can only request information when a case is assigned and has state
                                "In Progress" or "Information Received"!
                            </Text>
                        }
                    />
                )}
            </Space>
        </Modal>
    )
}

export const DelayNotificationsModal = () => {
    const {
        caseId,
        delayNotificationsModal: { isOpen, close },
    } = useOddDetailContext()

    const { data: caseStateData } = bff.modals.getCaseState.useQuery({ caseId })
    const { mutateAsync: delayNotifications, isLoading } =
        bff.modals.delayNotifications.useMutation()

    const handleSubmit = async () => {
        try {
            await delayNotifications({ caseId })
            message.success(
                'Successfully delayed wallet block and notifications to company admins by 7 days.'
            )
        } catch (error) {
            message.error('Failed to delay wallet block and notifications to company admins.')
        }
        close()
    }

    const canRequest = caseStateData && ['WAITING_FOR_CUSTOMER'].includes(caseStateData?.state)
    const isDisabled = isLoading || !canRequest

    return (
        <Modal
            open={isOpen}
            onCancel={close}
            onOk={handleSubmit}
            title={'Delay customer notifications?'}
            okText={'Send'}
            okButtonProps={{
                loading: isLoading,
                disabled: isDisabled,
            }}
        >
            <Space direction="vertical" style={{ width: '100%' }}>
                <Text>Should this company have more time to gather missing documents?</Text>
                <Alert
                    type="info"
                    icon={<HourglassOutlined />}
                    showIcon={true}
                    description={
                        <Text>
                            Upcoming automated reminders to company admins will be delayed by 1
                            week.
                        </Text>
                    }
                />
                <Alert
                    type="info"
                    icon={<CloseCircleOutlined />}
                    showIcon={true}
                    description={
                        <Text>The automatic wallet block will be delayed by 1 week as well.</Text>
                    }
                />
                {!canRequest && (
                    <Alert
                        type="warning"
                        icon={<ExclamationCircleOutlined />}
                        showIcon={true}
                        description={
                            <Text>This case currently has no open requests for information!</Text>
                        }
                    />
                )}
            </Space>
        </Modal>
    )
}

export const ExtendWalletBlockDeadline = () => {
    const {
        caseId,
        companyId,
        extendWalletBlockDeadlineModal: { isOpen, close },
    } = useOddDetailContext()
    const { data: caseState } = bff.modals.getCaseState.useQuery({ caseId })
    const { mutateAsync: extendWalletBlockDeadline, isLoading } =
        bff.modals.extendWalletBlockDeadline.useMutation()
    const [showConfirmation, setShowConfirmation] = useState(false)

    const resetAndClose = () => {
        setShowConfirmation(false)
        close()
    }

    const handleSubmit = async () => {
        if (showConfirmation) {
            close()
            return
        }
        try {
            await extendWalletBlockDeadline({ caseId })
            setShowConfirmation(true)
        } catch (error) {
            message.error('Failed to extend wallet block deadline.')
        }
    }

    const canRequest = ['WALLET_BLOCKED', 'WAITING_FOR_CUSTOMER'].includes(caseState?.state || '')
    const isDisabled = isLoading || !canRequest

    return (
        <Modal
            open={isOpen}
            onCancel={resetAndClose}
            onClose={resetAndClose}
            onOk={handleSubmit}
            title={'Extend Wallet Block Deadline?'}
            footer={
                <Button
                    type="primary"
                    onClick={handleSubmit}
                    disabled={isDisabled}
                    loading={isLoading}
                >
                    {showConfirmation ? 'Done' : 'Extend Deadline'}
                </Button>
            }
        >
            <Space direction="vertical" style={{ width: '100%' }}>
                {!showConfirmation && (
                    <>
                        <List
                            header={<Text strong>What will happen?</Text>}
                            dataSource={[
                                'A reminder to provide the requested information will be sent to the company admins.',
                                'The deadline for the wallet block will be extended by 14 days.',
                                'The case status will be updated to "Waiting for Customer."',
                            ]}
                            renderItem={(item) => <List.Item>{item}</List.Item>}
                        />
                        {!canRequest && (
                            <Text type="warning">
                                <InfoCircleOutlined /> The deadline can only be extended when the
                                case is in the 'Wallet Blocked' state.
                            </Text>
                        )}
                    </>
                )}
                {showConfirmation && (
                    <Result
                        status="success"
                        title="Wallet Block deadline extended"
                        subTitle="The deadline for the wallet block has been extended by 14 days and the customer has been requested to provide missing information."
                        extra={
                            <Alert
                                type="warning"
                                description={
                                    <Space direction="vertical">
                                        <Text>
                                            If the company's wallet is blocked, it must be manually
                                            unblocked from the Company page.
                                        </Text>
                                        <Link
                                            style={{ marginTop: '1rem' }}
                                            target="_blank"
                                            to={`/compliance/companies/${companyId}`}
                                        >
                                            Go to Company page
                                        </Link>
                                    </Space>
                                }
                            />
                        }
                    />
                )}
            </Space>
        </Modal>
    )
}

export const ManuallyInitiateOffboardingModal = () => {
    const {
        caseId,
        manuallyInitiateOffboardingModal: { isOpen, close },
    } = useOddDetailContext()
    const { mutateAsync: manuallyInitiateOffboarding, isLoading } =
        bff.modals.manuallyInitiateOffboarding.useMutation()

    const handleSubmit = async () => {
        try {
            await manuallyInitiateOffboarding({ caseId })
            close()
        } catch (error) {
            message.error('Failed to mark case as offboarding initiated.')
        }
    }

    const isDisabled = isLoading

    return (
        <Modal
            open={isOpen}
            onCancel={close}
            onClose={close}
            onOk={handleSubmit}
            title={'Update case state to Offboarding Initiated?'}
            footer={
                <Button
                    type="primary"
                    onClick={handleSubmit}
                    disabled={isDisabled}
                    loading={isLoading}
                >
                    Initiate Offboarding
                </Button>
            }
        >
            <List
                header={<Text strong>What will happen?</Text>}
                dataSource={[
                    'This action cannot be undone.',
                    'The case status will be updated to "Offboarding Initiated"',
                ]}
                renderItem={(item) => <List.Item>{item}</List.Item>}
            />
        </Modal>
    )
}

export const HistoryModal = () => {
    const {
        caseId,
        historyModal: { isOpen, close },
    } = useOddDetailContext()
    const { data } = bff.modals.getCaseTimeline.useQuery({ caseId })

    const timelineItems = data?.timeline.map((event) => ({
        ...mapEventType(event.eventType),
        children: (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Text style={{ fontSize: '0.875em' }} type="secondary">
                    {formatTimestamp(event.by.at)} - {event.by.firstName} {event.by.lastName}
                </Text>
                <Text strong>{prettifyEventType(event.eventType)}</Text>
                <Text style={{ fontSize: '0.875em' }}>{event.description}</Text>
            </div>
        ),
    }))

    return (
        <Modal open={isOpen} onCancel={close} title="Case History" footer={null}>
            <Timeline items={timelineItems} style={{ marginTop: '16px' }} />
        </Modal>
    )
}

export const OrgModal = () => {
    const {
        caseId,
        orgModal: { isOpen, close },
    } = useOddDetailContext()
    const { data } = bff.modals.getMultiEntityOrganzaizationCaseData.useQuery({ caseId })

    const companyItems = data?.map((company) => {
        if (!company?.data?.[0]?.id) {
            return null
        }

        return (
            <Row key={company?.data?.[0]?.id}>
                <Col span={12} style={{ padding: '5px' }}>
                    <Text>{company.companyName}</Text>
                </Col>
                <Col span={8} style={{ padding: '5px' }}>
                    <Text>Due: {formatDate(company?.data?.[0]?.dueDate)}</Text>
                </Col>
                <Col span={4}>
                    <Button
                        type="link"
                        onClick={() => {
                            close()
                            window.open(`/compliance/odd/${company?.data?.[0]?.id}`)
                        }}
                    >
                        View
                    </Button>
                </Col>
            </Row>
        )
    })

    return (
        <Modal open={isOpen} onCancel={close} title="ODD cases for this organization" footer={null}>
            {companyItems}
        </Modal>
    )
}

export const NotesModal = () => {
    const {
        caseId,
        notesModal: { isOpen, close },
    } = useOddDetailContext()
    const { data } = bff.notes.getNotesData.useQuery({ caseId })
    const [localNotes, setLocalNotes] = useState('')
    const { mutateAsync: updateNotes } = bff.notes.updateNotes.useMutation()

    return (
        <Modal open={isOpen} onCancel={close} title="Notes" footer={null}>
            <Input.TextArea
                defaultValue={data?.notes}
                placeholder="Add a note"
                onChange={(e) => setLocalNotes(e.target.value)}
                showCount
                style={{ marginBottom: '1rem' }}
                rows={6}
            />
            <Button
                type="primary"
                onClick={() => {
                    updateNotes({ caseId, notes: localNotes })
                    close()
                }}
            >
                Save
            </Button>
        </Modal>
    )
}

export const WalletBlockedModal = () => {
    const {
        caseId,
        walletBlockedModal: { isOpen, close },
    } = useOddDetailContext()
    const { mutateAsync: sendWalletBlockedEmail, isLoading } =
        bff.modals.sendWalletBlocked.useMutation()

    const handleSubmit = async () => {
        try {
            await sendWalletBlockedEmail({ caseId })
            message.success('Successfully sent wallet blocked email.')
        } catch (error) {
            message.error('Failed to send wallet blocked email.')
        }
        close()
    }

    return (
        <Modal
            open={isOpen}
            onCancel={close}
            onOk={handleSubmit}
            title={'Send blocked wallet email?'}
            okText={'Send'}
            okButtonProps={{
                loading: isLoading,
            }}
        >
            <Space direction="vertical" style={{ width: '100%' }}>
                <Text>Notify the company admins that their wallet has been blocked?</Text>
                <Alert
                    type="info"
                    icon={<FieldTimeOutlined />}
                    showIcon={true}
                    description={<Text>Admins may contact Pleo to unblock their wallet.</Text>}
                />
            </Space>
        </Modal>
    )
}

export const CompanyOffboardedModal = () => {
    const {
        companyOffboardedModal: { isOpen, close, caseId },
    } = useOddListContext()

    const { mutateAsync: setCaseAsOffboarded, isLoading } =
        bff.modals.setCaseAsOffboarded.useMutation()

    const [closeComment, setCloseComment] = useState('')

    const handleSubmit = async () => {
        await setCaseAsOffboarded({ caseId, comment: closeComment })
        close()
    }

    return (
        <Modal
            open={isOpen}
            onCancel={() => close()}
            onOk={handleSubmit}
            title={'Set as offboarding completed?'}
            okText={'Confirm'}
            okButtonProps={{
                loading: isLoading,
                disabled: isLoading || closeComment.length < MIN_COMMENT_LENGTH_CHARACTERS,
            }}
        >
            <Space direction="vertical" style={{ width: '100%' }}>
                <Text>Are you sure you want to mark this case as "Offboarding Completed"?</Text>
                <Alert
                    type="warning"
                    icon={<AlertOutlined />}
                    showIcon={true}
                    description={<Text>This action is irreversible.</Text>}
                />
                <Input.TextArea
                    placeholder="Add a comment"
                    showCount
                    style={{ marginBottom: '1rem' }}
                    required
                    onChange={(e) => setCloseComment(e.target.value)}
                />
            </Space>
        </Modal>
    )
}

export const CompanyOffboardedInitiatedModal = () => {
    const {
        companyOffboardedInitiatedModal: { isOpen, close, caseId },
    } = useOddListContext()

    const { mutateAsync: setCaseAsOffboarded, isLoading } =
        bff.modals.setCaseAsOffboardingInitiated.useMutation()

    const handleSubmit = async () => {
        await setCaseAsOffboarded({ caseId })
        close()
    }

    return (
        <Modal
            open={isOpen}
            onCancel={() => close()}
            onOk={handleSubmit}
            title={'Set as offboarding initiated?'}
            okText={'Confirm'}
            okButtonProps={{
                loading: isLoading,
                disabled: isLoading,
            }}
        >
            <Space direction="vertical" style={{ width: '100%' }}>
                <Text>Are you sure you want to mark this case as "Offboarding Initiated"?</Text>
                <Alert
                    type="warning"
                    icon={<AlertOutlined />}
                    showIcon={true}
                    description={<Text>This action is irreversible.</Text>}
                />
            </Space>
        </Modal>
    )
}

const mapEventType = (eventType: CaseAuditEventType): { color: string; dot?: React.ReactNode } => {
    switch (eventType) {
        case 'CHECK_DELETED':
            return {
                color: 'red',
                dot: <CloseCircleOutlined />,
            }
        case 'CHECK_COMPLETED':
        case 'UPDATED_STATE_TO_ODD_COMPLETED':
        case 'UPDATED_STATE_TO_OFFBOARDING_COMPLETED':
            return {
                color: 'green',
                dot: <CheckCircleOutlined />,
            }
        case 'UPDATED_CLOSED_NOTES':
            return {
                color: 'grey',
                dot: <CommentOutlined />,
            }
        case 'UPDATED_STATE_TO_WAITING_FOR_CUSTOMER':
            return {
                color: 'orange',
                dot: <HourglassOutlined />,
            }
        case 'COMMUNICATION_SCHEDULE_REQUEST_INFORMATION':
        case 'COMMUNICATION_REQUEST_INFORMATION_REMINDER_1':
        case 'COMMUNICATION_REQUEST_INFORMATION_REMINDER_2':
        case 'COMMUNICATION_WALLET_BLOCKED':
        case 'COMMUNICATION_ODD_COMPLETED':
            return {
                color: 'grey',
                dot: <MailOutlined />,
            }
        case 'COMMUNICATION_DELAY_EMAIL_SCHEDULE':
            return {
                color: 'grey',
                dot: <HourglassOutlined />,
            }
        case 'COMMUNICATION_CANCEL_SCHEDULE_REQUEST_INFORMATION':
        case 'COMMUNICATION_CANCEL_WALLET_BLOCKED':
            return {
                color: 'grey',
                dot: <CloseCircleOutlined />,
            }
        default:
            return {
                color: 'gray',
            }
    }
}

const prettifyEventType = (string: CaseAuditEventType) => {
    const acronyms = ['ODD']

    return string
        .split('_')
        .map((word) =>
            acronyms.includes(word)
                ? word
                : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        .join(' ')
}
