export const COLUMNS = {
    COMPANY: 'companyName',
    ASSIGNEE: 'assignee',
    STP_CHECKS: 'stpChecks',
    UPDATED_AT: 'updatedAt',
    CREATED_AT: 'createdAt',
    BLOCKED_REASON: 'blockedReason',
    CUSTOMER_WAIT_TIME: 'lastWaitingForAnalystActionAt',
} as const

export const TABS = {
    SIGN_UP_IN_PROGRESS: 'sign-up-in-progress',
    READY_FOR_FDD: 'ready-for-fdd',
    IN_PROGRESS: 'in-progress',
    WAITING_FOR_CUSTOMER: 'waiting-for-customer',
    INFORMATION_RECEIVED: 'information-received',
    FDD_APPROVED: 'fdd-approved',
    FDD_REJECTED: 'fdd-rejected',
} as const

export type ColumnName = (typeof COLUMNS)[keyof typeof COLUMNS]
export type TabName = (typeof TABS)[keyof typeof TABS]
